import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class SpecialistPhysicianAction extends Component {
    state = {
        commentData: '',loader: false, htmlText: '', id: 0, companyId: 0, patientId: 0, investigationRadiologyData: '', investigationLabData: '', prescriptionData: '', planData: '', patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [], findings: [],appointmentTimes: [],
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: 0, msg: '', error: '',
        presentingComplaints: '', reviewProgress: '', pastMedicalHistory: '', currentMedication: '', allergyHistory: '', familySocialHistory: '', investigationData: '',
        examinationNotes:'' ,jaundiceChoice:'' ,anemiaChoice:'' ,clubbingChoice:'' ,cyanosisChoice:'' ,oedemaChoice:'' ,lymphadenopathyChoice:'' ,cnsChoice:'' ,cnsText:'' ,skinChoice:'' ,skinText:'' ,rsChoice:'' ,rsText:'' ,adbChoice:'' ,adbText:'' ,gusChoice:'' ,gusText:'' ,mssChoice:'' ,mssText:'' ,cvsChoice:'' ,cvsText:'' ,examinationOther:'' ,
        hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
        presentingComplaintsT: true, hReview: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, familySocialHistoryT: false,
        history: true, examination: false, investigation: false, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false
    }
    componentDidMount() {

    }
    openSpecialistPhysicianAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           }
        this.setState({
            commentData: '',htmlText: '', id: 0, companyId: 0, patientId: 0, investigationRadiologyData: '', investigationLabData: '', prescriptionData: '', planData: '', patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [], findings: [],appointmentTimes: [],
            fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: visitId, msg: '', error: '',
            presentingComplaints: '', reviewProgress: '', pastMedicalHistory: '', currentMedication: '', allergyHistory: '', familySocialHistory: '', investigationData: '',
            examinationNotes:'' ,jaundiceChoice:'' ,anemiaChoice:'' ,clubbingChoice:'' ,cyanosisChoice:'' ,oedemaChoice:'' ,lymphadenopathyChoice:'' ,cnsChoice:'' ,cnsText:'' ,skinChoice:'' ,skinText:'' ,rsChoice:'' ,rsText:'' ,adbChoice:'' ,adbText:'' ,gusChoice:'' ,gusText:'' ,mssChoice:'' ,mssText:'' ,cvsChoice:'' ,cvsText:'' ,examinationOther:'' ,
            hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
            presentingComplaintsT: true, hReview: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, familySocialHistoryT: false,
            history: true, examination: false, investigation: false, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false
        })
        axios.get(localStorage.getItem('host') + '/patientmanagement/physician/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for(var j in res.data.vitalColors)
                    {
                    res.data.vitalColors[j].temperature = res.data.vitalColors[j].temperature === 0?"":res.data.vitalColors[j].temperature
                    res.data.vitalColors[j].pulse = res.data.vitalColors[j].pulse === 0?"":res.data.vitalColors[j].pulse
                    res.data.vitalColors[j].glucometer = res.data.vitalColors[j].glucometer === 0?"":res.data.vitalColors[j].glucometer
                    res.data.vitalColors[j].spO2 = res.data.vitalColors[j].spO2 === 0?"":res.data.vitalColors[j].spO2
                    res.data.vitalColors[j].diastolic = res.data.vitalColors[j].diastolic === 0?"":res.data.vitalColors[j].diastolic
                    res.data.vitalColors[j].systolic = res.data.vitalColors[j].systolic === 0?"":res.data.vitalColors[j].systolic
                    res.data.vitalColors[j].respRate = res.data.vitalColors[j].respRate === 0?"":res.data.vitalColors[j].respRate
                    }
                    res.data.weight = res.data.weight === 0?"":res.data.weight
                    res.data.height = res.data.height === 0?"":res.data.height
                    res.data.headCircumference = res.data.headCircumference === 0?"":res.data.headCircumference
                    res.data.muac = res.data.muac === 0?"":res.data.muac
                    res.data.bmi = res.data.bmi === 0?"":res.data.bmi
                    res.data.waistHeightRatio = res.data.waistHeightRatio === 0?"":res.data.waistHeightRatio
                this.setState({
                    commentData: res.data.commentData, id: res.data.id, companyId: res.data.companyId, patientId: res.data.patientId, investigationRadiologyData: res.data.investigationRadiologyData, investigationLabData: res.data.investigationLabData, prescriptionData: res.data.prescriptionData, planData: res.data.planData, diagnosisText: res.data.diagnosisText, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, referrals: res.data.referrals, findings: res.data.findings, msg: msg,appointmentTimes: res.data.appointmentTimes,
            /* HISTORY  */    presentingComplaints: res.data.history.presentingComplaints, reviewProgress: res.data.history.reviewProgress , pastMedicalHistory: res.data.history.pastMedicalHistory, currentMedication: res.data.history.currentMedication, allergyHistory: res.data.history.allergyHistory, familySocialHistory: res.data.history.familySocialHistory,
            /* EXAMINATION */ examinationNotes:res.data.examination.notes ,jaundiceChoice:res.data.examination.jaundiceChoice ,anemiaChoice:res.data.examination.anemiaChoice ,clubbingChoice:res.data.examination.clubbingChoice ,cyanosisChoice:res.data.examination.cyanosisChoice ,oedemaChoice:res.data.examination.oedemaChoice ,lymphadenopathyChoice:res.data.examination.lymphadenopathyChoice ,cnsChoice:res.data.examination.cnsChoice ,cnsText:res.data.examination.cnsText ,skinChoice:res.data.examination.skinChoice ,skinText:res.data.examination.skinText ,rsChoice:res.data.examination.rsChoice ,rsText:res.data.examination.rsText ,adbChoice:res.data.examination.adbChoice ,adbText:res.data.examination.adbText ,gusChoice:res.data.examination.gusChoice ,gusText:res.data.examination.gusText ,mssChoice:res.data.examination.mssChoice ,mssText:res.data.examination.mssText ,cvsChoice:res.data.examination.cvsChoice ,cvsText:res.data.examination.cvsText ,examinationOther:res.data.examination.other ,
                    hBA1C: res.data.hBA1C, cholesterol: res.data.cholesterol, otherName: res.data.otherName, otherValue: res.data.otherValue, allergies: res.data.allergies, results: res.data.results, investigationData: res.data.investigationData,
                    patientDiagnosis: res.data.patientDiagnosis, fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors, notes: res.data.notes, referralLetters: res.data.referralLetters, managementData: res.data.managementData, historyVisits: res.data.historyVisits
                });
                setTimeout(() => { this.setLoader(false)  }, 2000);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {
            commentData: this.state.commentData, patientId: this.state.patientId, visitId: this.state.visitId, history: { presentingComplaints: this.state.presentingComplaints, reviewProgress: this.state.reviewProgress, pastMedicalHistory: this.state.pastMedicalHistory, currentMedication: this.state.currentMedication, allergyHistory: this.state.allergyHistory, familySocialHistory: this.state.familySocialHistory }
            , examination: {notes:this.state.examinationNotes ,jaundiceChoice:this.state.jaundiceChoice ,anemiaChoice:this.state.anemiaChoice ,clubbingChoice:this.state.clubbingChoice ,cyanosisChoice:this.state.cyanosisChoice ,oedemaChoice:this.state.oedemaChoice ,lymphadenopathyChoice:this.state.lymphadenopathyChoice ,cnsChoice:this.state.cnsChoice ,cnsText:this.state.cnsText ,skinChoice:this.state.skinChoice ,skinText:this.state.skinText ,rsChoice:this.state.rsChoice ,rsText:this.state.rsText ,adbChoice:this.state.adbChoice ,adbText:this.state.adbText ,gusChoice:this.state.gusChoice ,gusText:this.state.gusText ,mssChoice:this.state.mssChoice ,mssText:this.state.mssText ,cvsChoice:this.state.cvsChoice ,cvsText:this.state.cvsText ,other:this.state.examinationOther }
            , investigationRadiologyData: this.state.investigationRadiologyData, investigationLabData: this.state.investigationLabData, investigationData: this.state.investigationData, prescriptionData: this.state.prescriptionData, planData: this.state.planData, diagnosisText: this.state.diagnosisText, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, referrals: this.state.referrals, findings: this.state.findings, patientDiagnosis: this.state.patientDiagnosis
        }
        axios.post(localStorage.getItem('host') + '/patientmanagement/physiciansave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.getList()
                document.getElementById('topDiv').scrollIntoView()
                this.openSpecialistPhysicianAction(this.state.visitId, res.data )
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('specialistPhysicianAction').style = 'display:none'
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                var waistHeightRatio = 0;
                value = value > 0 && value < 250 ? value : 0
                if (value > 0 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                if (value > 0 && this.state.waist > 0) {
                    waistHeightRatio = Number((this.state.waist / value).toFixed("2"))
                }
                this.setState({ height: value, bmi: bmi, waistHeightRatio: waistHeightRatio })
                break;
            default:
                waistHeightRatio = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    waistHeightRatio = Number((value / this.state.height).toFixed("2"))
                }
                this.setState({ waist: value, waistHeightRatio: waistHeightRatio })
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : 0;
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 120 ? value : 0;
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 30 ? value : 0;
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 140 ? value : 0;
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : 0;
                break;
            default:
                list[index].spO2 = value > 0 && value < 100 ? value : 0;
                break;

        }
        this.setState({ vitals: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature: 0, pulse: 0, glucometer: 0, systolic: 0, diastolic: 0, spO2: 0 });
        this.setState({ vitals: list })
    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/physiciansummaryview/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/physiciansummaryviewconsolidated/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showTreatmentRecord(patientId) {

        this.setState({ treatmentRecords: [] })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/physiciantreatmentlistview/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ treatmentRecords: res.data });
                document.getElementById('treatmentRecord').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }

    treatmentClosePopup() {
        document.getElementById('treatmentRecord').style = 'display:none'
    }
    viewResults() {
        document.getElementById('resultPopup').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension });
        document.getElementById('showFileNurseAction').style = 'display:block'

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    toggleTabs(name) {
        switch (name) {
            case "examination":
                this.setState({ history: false, examination: true, investigation: false, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false })
                break
            case "investigation":
                this.setState({ history: false, examination: false, investigation: true, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false })
                break
            case "treatment":
                this.setState({ history: false, examination: false, investigation: false, plan: false, comment: false, treatment: true, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false })
                break
            case "plan":
                this.setState({ history: false, examination: false, investigation: false, plan: true, comment: false, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false })
                break
            case "referral":
                this.setState({ history: false, examination: false, investigation: false, plan: false, comment: false, treatment: false, referral: true, medicalCertificate: false, review: false, diagnosisICD10: false })
                break
            case "medicalCertificate":
                this.setState({ history: false, examination: false, investigation: false, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: true, review: false, diagnosisICD10: false })
                break
            case "review":
                this.setState({ history: false, examination: false, investigation: false, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: false, review: true, diagnosisICD10: false })
                break
            case "diagnosisICD10":
                this.setState({ history: false, examination: false, investigation: false, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: true })
                break
            case "comment":
                    this.setState({ history: false, examination: false, investigation: false, plan: false, comment: true, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false })
                    break
            default:
                this.setState({ history: true, examination: false, investigation: false, plan: false, comment: false, treatment: false, referral: false, medicalCertificate: false, review: false, diagnosisICD10: false })
                break

        }
    }
    addReviewTable(reviewDate, reviewTime) {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: reviewDate, reviewTime: reviewTime, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }

    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addRehabilitationTable() {
        let list = this.state.rehabilitations.slice();
        list.push({ id: 0, type: '', remarks: '', activeStatus: 1 });
        this.setState({ rehabilitations: list });
    }
    addInvestigationTable() {
        let list = this.state.investigations.slice();
        list.push({ id: 0, name: '', activeStatus: 1 });
        this.setState({ investigations: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addTreatmentTable() {
        let list = this.state.treatments.slice();
        list.push({ id: 0, dosage: '', drugName: '', formulationName: '', frequencyName: '', routeName: '', durationName: '', repeats: '', activeStatus: 1 });
        this.setState({ treatments: list });
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
    deleteRehabilitation(index) {
        let list = this.state.rehabilitations.slice();
        list[index].activeStatus = 0
        this.setState({ rehabilitations: list });
    }
    deleteInvestigation(index) {
        let list = this.state.investigations.slice();
        list[index].activeStatus = 0
        this.setState({ investigations: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteTreatment(index) {
        let list = this.state.treatments.slice();
        list[index].activeStatus = 0
        this.setState({ treatments: list });
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setRehabilitation(index, name, value) {
        let list = this.state.rehabilitations.slice();
        switch (name) {
            case "type":
                list[index].type = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ rehabilitations: list });
    }
    setInvestigation(index, value) {
        let list = this.state.investigations.slice();
        for (var i in this.state.services) {
            if (this.state.services[i].value === value) {
                list[index].name = value
                list[index].speciality = this.state.services[i].speciality
            }
        }
        this.setState({ investigations: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setTreatment(index, name, value) {
        let list = this.state.treatments.slice();
        switch (name) {
            case "dosage":
                list[index].dosage = value
                break;
            case "drugName":
                list[index].drugName = value
                break;
            case "formulationName":
                list[index].formulationName = value
                break;
            case "frequencyName":
                list[index].frequencyName = value
                break;
            case "routeName":
                list[index].routeName = value
                break;
            case "durationName":
                list[index].durationName = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ treatments: list });
    }

    toggleHistoryTabs(name) {
        switch (name) {
            case "familySocialHistoryT":
                this.setState({ presentingComplaintsT: false, hReview: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, familySocialHistoryT: true })
                break
            case "pastMedicalHistoryT":
                this.setState({ presentingComplaintsT: false, hReview: false, pastMedicalHistoryT: true, currentMedicationT: false, allergyHistoryT: false, familySocialHistoryT: false })
                break
            case "currentMedicationT":
                this.setState({ presentingComplaintsT: false, hReview: false, pastMedicalHistoryT: false, currentMedicationT: true, allergyHistoryT: false, familySocialHistoryT: false })
                break
            case "allergyHistoryT":
                this.setState({ presentingComplaintsT: false, hReview: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: true, familySocialHistoryT: false })
                break
            case "hReview":
                this.setState({ presentingComplaintsT: false, hReview: true, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: true, familySocialHistoryT: false })
                break
            default:
                this.setState({ presentingComplaintsT: true, hReview: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, familySocialHistoryT: false })
                break
                
        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    updateAfterSave(visitId, results) {
        if (results.length > 0) {
            this.setState({ results: results })
        }
        else {
            axios.get(localStorage.getItem('host') + '/patientmanagement/nursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors });
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }
    }
    setLoader(loader)
      {
       this.setState({loader: loader})
      }
      getReviewTime(reviewTime)
         {
          var time = ''
          for(var j in this.state.appointmentTimes)
          {
           if(Number(reviewTime) === Number(this.state.appointmentTimes[j].id))
              {
               time = this.state.appointmentTimes[j].name
              }
          }
          return time
         }   
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="specialistPhysicianAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">SpecialistPhysician Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div classNamspecialistPhysiciane="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="treatmentRecord" class="page-popup page-popup-overlay"><div class="card" >
                    <div class="card-header   bg-info">Treatment Record</div>
                    <div class="card-body" >
                        {this.state.treatmentRecords.map((item, index) => (
                            <div class="row" key={index} >
                                <div class="col-xs-12 col-md-12 col-lg-12">
                                    <div class="card border-success">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col"><span className="fw-bold">Date:</span> {item.treatmentDateTime}</div>
                                                <div className="col"><span className="fw-bold"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped" >
                                        <tr>
                                            <th>Drug and Instruction</th>
                                        </tr>
                                        <tbody>
                                            {item.treatments.map((treatment, i) => (
                                                <tr key={i} >
                                                    <td>{treatment.drugNameAndInstruction}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        <div class="col" align="center" ><input class="btn btn-primary" type='button' onClick={() => this.treatmentClosePopup()} value="Close" /></div>
                    </div>
                </div>
                </div>
                <div id="resultPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>Treatment Date</th>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                        <td>{item.visitDate}</td>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                        <iframe title="File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'PHYSICIAN', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        {this.state.id > 0 && (<div ><span className="linkGreen" onClick={() => this.props.getPrintOutURL(this.state.visitId, 'PHYSICIAN', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        {this.state.allergies && (<div className="card card-red">
                            <div className="card-header text-dark">
                                Allergies
                            </div>
                            <div className="card-body">
                                <span>{this.state.allergies}</span>
                            </div>
                        </div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}

                        {this.state.oldPatient && (<div className="card  card-green">
                            <div className="card-header  text-dark">Old Records</div>
                            <div className="card-body">
                                <ul>
                                    <li><span className="link" onClick={() => this.showOldNurse(this.state.oldPatient)}>View Old Emr</span></li>
                                    <li><span className="link" onClick={() => this.showOldResults(this.state.oldPatient)}>View Old Results</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.results.length > 0 && (<li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>)}
                                    <li><span className="link" onClick={() => this.showTreatmentRecord(this.state.patientId)}>View Prescription Records</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Weight (Kg):</span> {this.state.weight}</div>
                                    <div className="col"><span className="fw-bold">Height (cm):</span> {this.state.height}</div>
                                    <div className="col"><span className="fw-bold">Waist (cm):</span> {this.state.waist}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">BMI:</span> {this.state.bmi}</div>
                                    <div className="col"><span className="fw-bold">Waist Height Ratio:</span> {this.state.waistHeightRatio}</div>
                                    <div className="col"><span className="fw-bold">Notes:</span> <span >{this.state.nurseNotes}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-info">
                            <div className="card-body">
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Bp</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>SpO2 (%)</th>
                                        <th>Resp Rate</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitalColors.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.vitalTime}</td>
                                                <td style={{ color: item.temperatureColor }}>{item.temperature}</td>
                                                <td><span style={{ color: item.systolicColor }}>{item.systolic}</span>/<span style={{ color: item.diastolicColor }}>{item.diastolic}</span></td>
                                                <td style={{ color: item.pulseColor }}>{item.pulse}</td>
                                                <td style={{ color: item.glucometerColor }}>{item.glucometer}</td>
                                                <td style={{ color: item.spO2Color }}>{item.spO2}</td>
                                                <td style={{ color: item.respRateColor }}>{item.respRate}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><div className="input-group">
                                        <span className="input-group-text">Impression</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                            <li className="nav-item"><span className={this.state.examination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examination") }}>Examination</span></li>
                            <li className="nav-item"><span className={this.state.investigation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("investigation") }}>Investigations</span></li>
                            <li className="nav-item"><span className={this.state.plan ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("plan") }}>Plan</span></li>
                            <li className="nav-item"><span className={this.state.comment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("comment") }}>Comment</span></li>
                           <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                            <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                            <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                            <li className="nav-item"><span className={this.state.diagnosisICD10 ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("diagnosisICD10") }}>Diagnosis ICD10</span></li>
                            <li className="nav-item"><span className={this.state.treatment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatment") }}>Prescription</span></li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        History
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.presentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaintsT") }}>PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.hReview ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("hReview") }}>REVIEW / PROGRESS</span></li>
                                            <li className="nav-item"><span className={this.state.pastMedicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pastMedicalHistoryT") }}>PAST MEDICAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.currentMedicationT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("currentMedicationT") }}>CURRENT MEDICATION</span></li>
                                            <li className="nav-item"><span className={this.state.allergyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("allergyHistoryT") }}>ALLERGY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.familySocialHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("familySocialHistoryT") }}>FAMILY AND SOCIAL HISTORY</span></li>
                                        </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.presentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.presentingComplaints} onInput={e => { this.setState({ presentingComplaints: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.hReview ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.reviewProgress} onInput={e => { this.setState({ reviewProgress: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.pastMedicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.pastMedicalHistory} onInput={e => { this.setState({ pastMedicalHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.currentMedicationT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.currentMedication} onInput={e => { this.setState({ currentMedication: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.allergyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.allergyHistory} onInput={e => { this.setState({ allergyHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.familySocialHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.familySocialHistory} onInput={e => { this.setState({ familySocialHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={this.state.examination ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Physical and General Examination
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text"> General</span> <input type="text" value={this.state.examinationNotes} onChange={e => this.setState({ examinationNotes: e.target.value })} className="form-control" /></div></div>
                                           </div>
                                     { localStorage.getItem('username') !== 'pchinwada' &&<div>    
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.jaundiceChoiceEx === "1"} onChange={e => this.setState({ jaundiceChoiceEx: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> JAUNDICE</span></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.anemiaChoice === "1"} onChange={e => this.setState({ anemiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> ANEMIA</span></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.clubbingChoice === "1"} onChange={e => this.setState({ clubbingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> CLUBBING </span></div></div>
                                            </div>
                                        <div className="row">
                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.cyanosisChoice === "1"} onChange={e => this.setState({ cyanosisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> CYANOSIS</span></div></div>
                                         <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.oedemaChoice === "1"} onChange={e => this.setState({ oedemaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> OEDEMA </span></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lymphadenopathyChoice === "1"} onChange={e => this.setState({ lymphadenopathyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">LYMPHADENOPATHY </span></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.cnsChoice === "1"} onChange={e => this.setState({ cnsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">CNS</span><input type="text" value={this.state.cnsText} onInput={e => this.setState({ cnsText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.skinChoice === "1"} onChange={e => this.setState({ skinChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">SKIN</span> <input type="text" value={this.state.skinText} onInput={e => this.setState({ skinText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rsChoice === "1"} onChange={e => this.setState({ rsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> RS</span><input type="text" value={this.state.rsText} onInput={e => this.setState({ rsText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.adbChoice === "1"} onChange={e => this.setState({ adbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">ABD</span><input type="text" value={this.state.adbText} onInput={e => this.setState({ adbText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.gusChoice === "1"} onChange={e => this.setState({ gusChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">GUS</span> <input type="text" value={this.state.gusText} onInput={e => this.setState({ gusText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.mssChoice === "1"} onChange={e => this.setState({ mssChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> MSS</span><input type="text" value={this.state.mssText} onInput={e => this.setState({ mssText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.cvsChoice === "1"} onChange={e => this.setState({ cvsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">CVS</span><input type="text" value={this.state.cvsText} onInput={e => this.setState({ cvsText: e.target.value })} className="form-control" /></div></div>
                                        <div className="col"></div>
                                        <div className="col"></div>
                                           </div>
                                           </div>}
                                        <div className="row">
                                        <div className="col" align="right"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.examinationOther} onChange={e => this.setState({ examinationOther: e.target.value })} className="form-control" rows="5" ></textarea></div></div>
                                            </div>   
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Upload Findings [15MB]</span> <input type="file" accept=".pdf" onChange={e => { this.setState({ file: e.target.files[0] }); this.doValidate(); }} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Findings Text </span> <textarea name="resultText" onChange={e => { this.setState({ resultText: e.target.value }); this.doValidate(); }} className="form-control" ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            {this.state.showSubmit === true ? (<div className="col" align="center"><input type="button" onClick={() => this.save()} value="Save" className="btn btn-success" /></div>) : (<div></div>)}
                                        </div>
                                        <br />
                                        <table className="table table-striped">
                                            <tr>
                                                <th>Action</th>
                                                <th>File</th>
                                                <th>Findings</th>
                                            </tr>
                                            <tbody>
                                                {this.state.findings.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span className="link-red" onClick={() => this.deleteResult(item.id, item.fileStorageId, item.fileExtension)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                        {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                                        <td>{item.remarks}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></div>
                            </div>
                            <div className={this.state.management ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Management</div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Detail</span>
                                                <textarea className="form-control" value={this.state.managementData} onInput={e => this.setState({ managementData: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg">   
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        
                                                    <td>{item.reviewDate}</td>
                                                    <td> {this.getReviewTime(item.reviewTime)} </td>
                                                        <td><textarea className="form-control" value={item.remarks} onInput={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.props.showCalenderReview()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                                                                                <td><input type="text" className="form-control" value={item.startDate} onChange={e => { this.setMedicalCertificate(index, "startDate", e.target.value ) }} /> 
                                                                                                                      <MyDatePicker  value={item.startDate} maxDate={item.endDate} onChange={(d)=> {  this.setMedicalCertificate(index, "startDate", d);  }} />
                                                                                                                </td>
                                                                                                                <td><input type="text" className="form-control" value={item.endDate} onChange={e => { this.setMedicalCertificate(index, "endDate", e.target.value ) }} /> 
                                                                                                                     <MyDatePicker  value={item.endDate} minDate={item.startDate} onChange={(d)=> {  this.setMedicalCertificate(index, "endDate",  d);  }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.plan ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Plan</div>
                                    <div className="card-body">
                                        <div className="col"><div className="input-group">
                                            <textarea className="form-control" value={this.state.planData} onInput={e => { this.setState({ planData: e.target.value }) }} rows="10"></textarea> </div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.comment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Comment</div>
                                    <div className="card-body">
                                        <div className="col"><div className="input-group">
                                            <textarea className="form-control" value={this.state.commentData} onInput={e => { this.setState({ commentData: e.target.value }) }} rows="10"></textarea> </div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Prescription</div>
                                    <div className="card-body">
                                        <div className="col"><div className="input-group">
                                            <textarea className="form-control" value={this.state.prescriptionData} onInput={e => { this.setState({ prescriptionData: e.target.value }) }} rows="10" ></textarea> </div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.investigation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Investigation</div>
                                    <div className="card-body">
                                        { localStorage.getItem('username') === 'maboreke' ?(
                                    <div className="row">
                                            <div className="col">
                                            <textarea className="form-control" value={this.state.investigationData} onInput={e => { this.setState({ investigationData: e.target.value }) }} rows="10"></textarea> 
                                            </div>
                                            </div>):(
                                        <div className="row">
                                            <div className="col"><div className="input-group">
                                            <span className="input-group-text">Radiology</span>  <textarea className="form-control" value={this.state.investigationRadiologyData} onInput={e => { this.setState({ investigationRadiologyData: e.target.value }) }} rows="10"></textarea> </div>
                                            </div>
                                            <div className="col"><div className="input-group">
                                            <span className="input-group-text">Laboratory</span>   <textarea className="form-control" value={this.state.investigationLabData} onInput={e => { this.setState({ investigationLabData: e.target.value }) }} rows="10"></textarea> </div>
                                            </div>
                                        </div>)}
                                    </div></div>
                            </div>
                            <div className={this.state.diagnosisICD10 ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                <div className="card-header  text-dark">
                                    Diagnosis
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12 col-lg-12">
                                            <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                            <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                        </div>
                                        </div>
                                        </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                        <option value="">Choose Diagnosis</option>
                                      {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                    <table className="table">
                                        <tr>
                                            <th>Description</th>
                                            <th>Active Status</th>
                                        </tr>
                                        {this.state.patientDiagnosis.map((item, index) =>  (item.activeStatus === 1 &&(
                                            <tr key={index}>
                                                <td>{item.description}</td>
                                                <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            </tr>)))}
                                    </table>
                                </div>
                            </div>
                            </div>
                        </div>

                       
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                            <div className="col" align="right"><button onClick={() => this.props.openNurseAction(this.state.visitId)} className="btn btn-primary">Edit Nurse Details</button></div>
                            <div className="col" align="left"><button onClick={() => this.props.getResultAction(this.state.visitId)} className="btn btn-primary">Upload Results</button></div>
                        </div>
                    </div>
                </div>


            </div >
        </div >
        </div >)

    }
}      