import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class CasualtyAction extends Component {
    state = {
        visitStatus:'', loader: false, htmlText: '', id: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], drugHistoryList: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], procedures: [], managementLists: [], showUrinalysis: false, appointmentTimes: [],
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, drugAllergy: '', muac: 0, bmi: 0.0, otherAllergy: '', nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: 0, msg: '', error: '',
        ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '',
        historyData: '', tbChoice: '', tbText: '', asthmaChoice: '', asthmaText: '', diabetesChoice: '', diabetesText: '', hypertensionChoice: '', hypertensionText: '', myocardialChoice: '', myocardialText: '', migraineChoice: '', migraineText: '', epilepsyChoice: '', epilepsyText: '', skinDiseaseChoice: '', skinDiseaseText: '', pregnantChoice: '', pregnantText: '', retroviralChoice: '', retroviralText: '', historyOther: '',
        coughChoice: '', coughText: '', coryzaChoice: '', coryzaText: '', sobChoice: '', sobText: '', sneazingChoice: '', sneazingText: '', phelgmChoice: '', phelgmText: '', sputumChoice: '', sputumText: '', haemoptysisChoice: '', haemoptysisText: '', wheezingChoice: '', wheezingText: '', nasalChoice: '', nasalText: '', soreChoice: '', soreText: '', chestPainChoice: '', chestPainText: '', respitoryNotes: '',
        nauseaChoice: '', nauseaText: '', vomitingChoice: '', vomitingText: '', diarrhoeaChoice: '', diarrhoeaText: '', abdominialChoice: '', abdominialText: '', flatulanceChoice: '', flatulanceText: '', flatulentChoice: '', flatulentText: '', waterChoice: '', waterText: '', constipationChoice: '', constipationText: '', prChoice: '', prText: '', wtChoice: '', wtText: '', melenaChoice: '', melenaText: '', jaundiceChoice: '', jaundiceText: '', loaChoice: '', loaText: '', lowChoice: '', lowText: '', gitNotes: '',
        dysuriaChoice: '', dysuriaText: '', frequencyChoice: '', frequencyText: '', haematuriaChoice: '', haematuriaText: '', weakChoice: '', weakText: '', strainingChoice: '', strainingText: '', urineChoice: '', urineText: '', urethalChoice: '', urethalText: '', pvChoice: '', pvText: '', nocturiaChoice: '', nocturiaText: '', genitalChoice: '', genitalText: '', lossChoice: '', lossText: '', erectileChoice: '', erectileText: '', gusNotes: '',
        dysaruniaChoice: '', dysaruniaText: '', dysmemorrhoeaChoice: '', dysmemorrhoeaText: '', imbChoice: '', imbText: '', contactChoice: '', contactText: '', menorrhagiaChoice: '', menorrhagiaText: '', lapChoice: '', lapText: '', backacheChoice: '', backackeText: '', subfertilityChoice: '', subfertilityText: '', hotChoice: '', hotText: '', lnmpChoice: '', lnmpText: '', gynaeNotes: '',
        orthopneaChoice: '', orthopneaText: '', pndChoice: '', pndText: '', easyChoice: '', easyText: '', palpitationsChoice: '', palpitationsText: '', synacopeChoice: '', synacopeText: '', legSwellingChoice: '', legSwellingText: '', chestPaincvsChoice: '', chestPaincvsText: '', calfSwellingChoice: '', calfSwellingText: '', pinkFrothChoice: '', pinkFrothText: '', gelatinousChoice: '', gelatinousText: '', cvsNotes: '',
        headacheChoice: '', headacheText: '', paraesthesiaChoice: '', paraesthesiaText: '', dizzinessChoice: '', dizzinessText: '', backackeChoice: '', backacheText: '', photophibiaChoice: '', photophibiaText: '', neckstiffnessChoice: '', neckstiffnessText: '', fittingChoice: '', fittingText: '', paresisChoice: '', paresisText: '', paralysisChoice: '', paralysisText: '', insomniaChoice: '', insomniaText: '', hypersomniaChoice: '', hypersomniaText: '', abnChoice: '', abnText: '', cnsNotes: '',
        feverChoice: '', feverText: '', sweatingChoice: '', sweatingText: '', chillsChoice: '', chillsText: '', rigorsChoice: '', rigorsText: '', fatigueChoice: '', fatigueText: '', myalgiaChoice: '', myalgiaText: '', constitutionalNotes: '',
        bleedingChoice: '', bleedingText: '', bruisingChoice: '', bruisingText: '', swellingChoice: '', swellingText: '', deformityChoice: '', deformityText: '', mssNotes: '',
        rashChoice: '', rashText: '', itchynessChoice: '', itchynessText: '', blistersChoice: '', blistersText: '', lumpsChoice: '', lumpsText: '', ulcersChoice: '', ulcersText: '', swellingdemaChoice: '', swellingdemaText: '', dematologyNotes: '',
        medicalConditionsChoice: '', medicalConditionsText: '', surgicalHistoryChoice: '', surgicalHistoryText: '', drugAllergiesChoice: '', drugAllergiesText: '', otherAllergiesChoice: '', otherAllergiesText: '', otherDoctorsChoice: '', otherDoctorsText: '', contraceptionChoice: '', contraceptionText: '', alcoholChoice: '', alcoholText: '', smokingChoice: '', smokingText: '', chronicChoice: '', chronicText: '', modeofDeliveryChoice: '', modeofDeliveryText: '', birthWeightChoice: '', birthWeightText: '', pediatricianChoice: '', pediatricianText: '', congentialChoice: '', congentialText: '', deliveryComplicationChoice: '', deliveryComplicationText: '', pregnancyChoice: '', pregnancyText: '', developmentChoice: '', developmentText: '', circumcisionChoice: '', circumcisionText: '', other: '',
        appearanceChoice: '', appearanceText: '', behaviourChoice: '', behaviourText: '', dehydrationChoice: '', dehydrationText: '', wastingChoice: '', wastingText: '', hirsutismChoice: '', hirsutismText: '', thyroidChoice: '', thyroidText: '', palpableChoice: '', palpableText: '', clubbingChoice: '', clubbingText: '', pallorChoice: '', pallorText: '', icterusChoice: '', icterusText: '', cyanosisChoice: '', cyanosisText: '', edemaChoice: '', edemaText: '', lossphyChoice: '', lossphyText: '', herniaChoice: '', herniaText: '', examinationNotes: '',
        
        hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
        presentingComplaints: true, heent: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false,
        history: true, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false
    }
    componentDidMount() {

    }
    openCasualtyAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           } 
        this.setState({
            visitStatus:'', htmlText: '', id: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], drugHistoryList: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], procedures: [], managementLists: [], showUrinalysis: false, appointmentTimes: [],
            fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, drugAllergy: '', muac: 0, bmi: 0.0, otherAllergy: '', nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: visitId, msg: '', error: '',
            ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '',
            historyData: '', tbChoice: '', tbText: '', asthmaChoice: '', asthmaText: '', diabetesChoice: '', diabetesText: '', hypertensionChoice: '', hypertensionText: '', myocardialChoice: '', myocardialText: '', migraineChoice: '', migraineText: '', epilepsyChoice: '', epilepsyText: '', skinDiseaseChoice: '', skinDiseaseText: '', pregnantChoice: '', pregnantText: '', retroviralChoice: '', retroviralText: '', historyOther: '',
            heentData: '', coughChoice: '', coughText: '', coryzaChoice: '', coryzaText: '', sobChoice: '', sobText: '', sneazingChoice: '', sneazingText: '', phelgmChoice: '', phelgmText: '', sputumChoice: '', sputumText: '', haemoptysisChoice: '', haemoptysisText: '', wheezingChoice: '', wheezingText: '', nasalChoice: '', nasalText: '', soreChoice: '', soreText: '', chestPainChoice: '', chestPainText: '', respitoryNotes: '',
            nauseaChoice: '', nauseaText: '', vomitingChoice: '', vomitingText: '', diarrhoeaChoice: '', diarrhoeaText: '', abdominialChoice: '', abdominialText: '', flatulanceChoice: '', flatulanceText: '', flatulentChoice: '', flatulentText: '', waterChoice: '', waterText: '', constipationChoice: '', constipationText: '', prChoice: '', prText: '', wtChoice: '', wtText: '', melenaChoice: '', melenaText: '', jaundiceChoice: '', jaundiceText: '', loaChoice: '', loaText: '', lowChoice: '', lowText: '', gitNotes: '',
            dysuriaChoice: '', dysuriaText: '', frequencyChoice: '', frequencyText: '', haematuriaChoice: '', haematuriaText: '', weakChoice: '', weakText: '', strainingChoice: '', strainingText: '', urineChoice: '', urineText: '', urethalChoice: '', urethalText: '', pvChoice: '', pvText: '', nocturiaChoice: '', nocturiaText: '', genitalChoice: '', genitalText: '', lossChoice: '', lossText: '', erectileChoice: '', erectileText: '', gusNotes: '',
            dysaruniaChoice: '', dysaruniaText: '', dysmemorrhoeaChoice: '', dysmemorrhoeaText: '', imbChoice: '', imbText: '', contactChoice: '', contactText: '', menorrhagiaChoice: '', menorrhagiaText: '', lapChoice: '', lapText: '', backacheChoice: '', backackeText: '', subfertilityChoice: '', subfertilityText: '', hotChoice: '', hotText: '', lnmpChoice: '', lnmpText: '', gynaeNotes: '',
            orthopneaChoice: '', orthopneaText: '', pndChoice: '', pndText: '', easyChoice: '', easyText: '', palpitationsChoice: '', palpitationsText: '', synacopeChoice: '', synacopeText: '', legSwellingChoice: '', legSwellingText: '', chestPaincvsChoice: '', chestPaincvsText: '', calfSwellingChoice: '', calfSwellingText: '', pinkFrothChoice: '', pinkFrothText: '', gelatinousChoice: '', gelatinousText: '', cvsNotes: '',
            headacheChoice: '', headacheText: '', paraesthesiaChoice: '', paraesthesiaText: '', dizzinessChoice: '', dizzinessText: '', backackeChoice: '', backacheText: '', photophibiaChoice: '', photophibiaText: '', neckstiffnessChoice: '', neckstiffnessText: '', fittingChoice: '', fittingText: '', paresisChoice: '', paresisText: '', paralysisChoice: '', paralysisText: '', insomniaChoice: '', insomniaText: '', hypersomniaChoice: '', hypersomniaText: '', abnChoice: '', abnText: '', cnsNotes: '',
            feverChoice: '', feverText: '', sweatingChoice: '', sweatingText: '', chillsChoice: '', chillsText: '', rigorsChoice: '', rigorsText: '', fatigueChoice: '', fatigueText: '', myalgiaChoice: '', myalgiaText: '', constitutionalNotes: '',
            bleedingChoice: '', bleedingText: '', bruisingChoice: '', bruisingText: '', swellingChoice: '', swellingText: '', deformityChoice: '', deformityText: '', mssNotes: '',
            rashChoice: '', rashText: '', itchynessChoice: '', itchynessText: '', blistersChoice: '', blistersText: '', lumpsChoice: '', lumpsText: '', ulcersChoice: '', ulcersText: '', swellingdemaChoice: '', swellingdemaText: '', dematologyNotes: '',
            medicalConditionsChoice: '', medicalConditionsText: '', surgicalHistoryChoice: '', surgicalHistoryText: '', drugAllergiesChoice: '', drugAllergiesText: '', otherAllergiesChoice: '', otherAllergiesText: '', otherDoctorsChoice: '', otherDoctorsText: '', contraceptionChoice: '', contraceptionText: '', alcoholChoice: '', alcoholText: '', smokingChoice: '', smokingText: '', chronicChoice: '', chronicText: '', modeofDeliveryChoice: '', modeofDeliveryText: '', birthWeightChoice: '', birthWeightText: '', pediatricianChoice: '', pediatricianText: '', congentialChoice: '', congentialText: '', deliveryComplicationChoice: '', deliveryComplicationText: '', pregnancyChoice: '', pregnancyText: '', developmentChoice: '', developmentText: '', circumcisionChoice: '', circumcisionText: '', other: '',
            appearanceChoice: '', appearanceText: '', behaviourChoice: '', behaviourText: '', dehydrationChoice: '', dehydrationText: '', wastingChoice: '', wastingText: '', hirsutismChoice: '', hirsutismText: '', thyroidChoice: '', thyroidText: '', palpableChoice: '', palpableText: '', clubbingChoice: '', clubbingText: '', pallorChoice: '', pallorText: '', icterusChoice: '', icterusText: '', cyanosisChoice: '', cyanosisText: '', edemaChoice: '', edemaText: '', lossphyChoice: '', lossphyText: '', herniaChoice: '', herniaText: '', examinationNotes: '',
            hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
            presentingComplaints: true, heent: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false,
            patientHistory: false, history: true, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false
        })
        axios.get(localStorage.getItem('host') + '/hospital/casualty/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for(var j in res.data.vitalColors)
                    {
                    res.data.vitalColors[j].temperature = res.data.vitalColors[j].temperature === 0?"":res.data.vitalColors[j].temperature
                    res.data.vitalColors[j].pulse = res.data.vitalColors[j].pulse === 0?"":res.data.vitalColors[j].pulse
                    res.data.vitalColors[j].glucometer = res.data.vitalColors[j].glucometer === 0?"":res.data.vitalColors[j].glucometer
                    res.data.vitalColors[j].spO2 = res.data.vitalColors[j].spO2 === 0?"":res.data.vitalColors[j].spO2
                    res.data.vitalColors[j].diastolic = res.data.vitalColors[j].diastolic === 0?"":res.data.vitalColors[j].diastolic
                    res.data.vitalColors[j].systolic = res.data.vitalColors[j].systolic === 0?"":res.data.vitalColors[j].systolic
                    res.data.vitalColors[j].gsc = res.data.vitalColors[j].gsc === 0?"":res.data.vitalColors[j].gsc
                    }
                    res.data.weight = res.data.weight === 0?"":res.data.weight
                    res.data.height = res.data.height === 0?"":res.data.height
                    res.data.bmi = res.data.bmi === 0?"":res.data.bmi
                this.setState({
                    visitStatus: res.data.visitStatus, id: res.data.id, companyId: res.data.companyId, patientId: res.data.patientId, diagnosisText: res.data.diagnosisText, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, referrals: res.data.referrals, treatments: res.data.treatments, drugs: res.data.drugs, frequencys: res.data.frequencys, routes: res.data.routes, durations: res.data.durations, formulations: res.data.formulations, services: res.data.services, investigations: res.data.investigations, msg: msg , showUrinalysis: res.data.showUrinalysis, appointmentTimes: res.data.appointmentTimes,
                    ph: res.data.ph, ketones: res.data.ketones, sg: res.data.sg, proteins: res.data.proteins, leucocytes: res.data.leucocytes, urobilinogen: res.data.urobilinogen, blood: res.data.blood, nitrate: res.data.nitrate, glucose: res.data.glucose, otherUrineAnaysis: res.data.otherUrineAnaysis,
            /* HISTORY  */  historyData: res.data.history.historyData, tbChoice: res.data.history.tbChoice, tbText: res.data.history.tbText, asthmaChoice: res.data.history.asthmaChoice, asthmaText: res.data.history.asthmaText, diabetesChoice: res.data.history.diabetesChoice, diabetesText: res.data.history.diabetesText, hypertensionChoice: res.data.history.hypertensionChoice, hypertensionText: res.data.history.hypertensionText, myocardialChoice: res.data.history.myocardialChoice, myocardialText: res.data.history.myocardialText, migraineChoice: res.data.history.migraineChoice, migraineText: res.data.history.migraineText, epilepsyChoice: res.data.history.epilepsyChoice, epilepsyText: res.data.history.epilepsyText, skinDiseaseChoice: res.data.history.skinDiseaseChoice, skinDiseaseText: res.data.history.skinDiseaseText, pregnantChoice: res.data.history.pregnantChoice, pregnantText: res.data.history.pregnantText, retroviralChoice: res.data.history.retroviralChoice, retroviralText: res.data.history.retroviralText, historyOther: res.data.history.historyOther,  
            coughChoice: res.data.history.coughChoice, coughText: res.data.history.coughText, coryzaChoice: res.data.history.coryzaChoice, coryzaText: res.data.history.coryzaText, sobChoice: res.data.history.sobChoice, sobText: res.data.history.sobText, sneazingChoice: res.data.history.sneazingChoice, sneazingText: res.data.history.sneazingText, phelgmChoice: res.data.history.phelgmChoice, phelgmText: res.data.history.phelgmText, sputumChoice: res.data.history.sputumChoice, sputumText: res.data.history.sputumText, haemoptysisChoice: res.data.history.haemoptysisChoice, haemoptysisText: res.data.history.haemoptysisText, wheezingChoice: res.data.history.wheezingChoice, wheezingText: res.data.history.wheezingText, nasalChoice: res.data.history.nasalChoice, nasalText: res.data.history.nasalText, soreChoice: res.data.history.soreChoice, soreText: res.data.history.soreText, chestPainChoice: res.data.history.chestPainChoice, chestPainText: res.data.history.chestPainText, respitoryNotes: res.data.history.respitoryNotes,
            nauseaChoice: res.data.history.nauseaChoice, nauseaText: res.data.history.nauseaText, vomitingChoice: res.data.history.vomitingChoice, vomitingText: res.data.history.vomitingText, diarrhoeaChoice: res.data.history.diarrhoeaChoice, diarrhoeaText: res.data.history.diarrhoeaText, abdominialChoice: res.data.history.abdominialChoice, abdominialText: res.data.history.abdominialText, flatulanceChoice: res.data.history.flatulanceChoice, flatulanceText: res.data.history.flatulanceText, flatulentChoice: res.data.history.flatulentChoice, flatulentText: res.data.history.flatulentText, waterChoice: res.data.history.waterChoice, waterText: res.data.history.waterText, constipationChoice: res.data.history.constipationChoice, constipationText: res.data.history.constipationText, prChoice: res.data.history.prChoice, prText: res.data.history.prText, wtChoice: res.data.history.wtChoice, wtText: res.data.history.wtText, melenaChoice: res.data.history.melenaChoice, melenaText: res.data.history.melenaText, jaundiceChoice: res.data.history.jaundiceChoice, jaundiceText: res.data.history.jaundiceText, loaChoice: res.data.history.loaChoice, loaText: res.data.history.loaText, lowChoice: res.data.history.lowChoice, lowText: res.data.history.lowText, gitNotes: res.data.history.gitNotes,
            dysuriaChoice: res.data.history.dysuriaChoice, dysuriaText: res.data.history.dysuriaText, frequencyChoice: res.data.history.frequencyChoice, frequencyText: res.data.history.frequencyText, haematuriaChoice: res.data.history.haematuriaChoice, haematuriaText: res.data.history.haematuriaText, weakChoice: res.data.history.weakChoice, weakText: res.data.history.weakText, strainingChoice: res.data.history.strainingChoice, strainingText: res.data.history.strainingText, urineChoice: res.data.history.urineChoice, urineText: res.data.history.urineText, urethalChoice: res.data.history.urethalChoice, urethalText: res.data.history.urethalText, pvChoice: res.data.history.pvChoice, pvText: res.data.history.pvText, nocturiaChoice: res.data.history.nocturiaChoice, nocturiaText: res.data.history.nocturiaText, genitalChoice: res.data.history.genitalChoice, genitalText: res.data.history.genitalText, lossChoice: res.data.history.lossChoice, lossText: res.data.history.lossText, erectileChoice: res.data.history.erectileChoice, erectileText: res.data.history.erectileText, gusNotes: res.data.history.gusNotes,
            dysaruniaChoice: res.data.history.dysaruniaChoice, dysaruniaText: res.data.history.dysaruniaText, dysmemorrhoeaChoice: res.data.history.dysmemorrhoeaChoice, dysmemorrhoeaText: res.data.history.dysmemorrhoeaText, imbChoice: res.data.history.imbChoice, imbText: res.data.history.imbText, contactChoice: res.data.history.contactChoice, contactText: res.data.history.contactText, menorrhagiaChoice: res.data.history.menorrhagiaChoice, menorrhagiaText: res.data.history.menorrhagiaText, lapChoice: res.data.history.lapChoice, lapText: res.data.history.lapText, backacheChoice: res.data.history.backacheChoice, backackeText: res.data.history.backackeText, subfertilityChoice: res.data.history.subfertilityChoice, subfertilityText: res.data.history.subfertilityText, hotChoice: res.data.history.hotChoice, hotText: res.data.history.hotText, lnmpChoice: res.data.history.lnmpChoice, lnmpText: res.data.history.lnmpText, gynaeNotes: res.data.history.gynaeNotes,
            orthopneaChoice: res.data.history.orthopneaChoice, orthopneaText: res.data.history.orthopneaText, pndChoice: res.data.history.pndChoice, pndText: res.data.history.pndText, easyChoice: res.data.history.easyChoice, easyText: res.data.history.easyText, palpitationsChoice: res.data.history.palpitationsChoice, palpitationsText: res.data.history.palpitationsText, synacopeChoice: res.data.history.synacopeChoice, synacopeText: res.data.history.synacopeText, legSwellingChoice: res.data.history.legSwellingChoice, legSwellingText: res.data.history.legSwellingText, chestPaincvsChoice: res.data.history.chestPaincvsChoice, chestPaincvsText: res.data.history.chestPaincvsText, calfSwellingChoice: res.data.history.calfSwellingChoice, calfSwellingText: res.data.history.calfSwellingText, pinkFrothChoice: res.data.history.pinkFrothChoice, pinkFrothText: res.data.history.pinkFrothText, gelatinousChoice: res.data.history.gelatinousChoice, gelatinousText: res.data.history.gelatinousText, cvsNotes: res.data.history.cvsNotes,
            headacheChoice: res.data.history.headacheChoice, headacheText: res.data.history.headacheText, paraesthesiaChoice: res.data.history.paraesthesiaChoice, paraesthesiaText: res.data.history.paraesthesiaText, dizzinessChoice: res.data.history.dizzinessChoice, dizzinessText: res.data.history.dizzinessText, backackeChoice: res.data.history.backackeChoice, backacheText: res.data.history.backacheText, photophibiaChoice: res.data.history.photophibiaChoice, photophibiaText: res.data.history.photophibiaText, neckstiffnessChoice: res.data.history.neckstiffnessChoice, neckstiffnessText: res.data.history.neckstiffnessText, fittingChoice: res.data.history.fittingChoice, fittingText: res.data.history.fittingText, paresisChoice: res.data.history.paresisChoice, paresisText: res.data.history.paresisText, paralysisChoice: res.data.history.paralysisChoice, paralysisText: res.data.history.paralysisText, insomniaChoice: res.data.history.insomniaChoice, insomniaText: res.data.history.insomniaText, hypersomniaChoice: res.data.history.hypersomniaChoice, hypersomniaText: res.data.history.hypersomniaText, abnChoice: res.data.history.abnChoice, abnText: res.data.history.abnText, cnsNotes: res.data.history.cnsNotes,
            feverChoice: res.data.history.feverChoice, feverText: res.data.history.feverText, sweatingChoice: res.data.history.sweatingChoice, sweatingText: res.data.history.sweatingText, chillsChoice: res.data.history.chillsChoice, chillsText: res.data.history.chillsText, rigorsChoice: res.data.history.rigorsChoice, rigorsText: res.data.history.rigorsText, fatigueChoice: res.data.history.fatigueChoice, fatigueText: res.data.history.fatigueText, myalgiaChoice: res.data.history.myalgiaChoice, myalgiaText: res.data.history.myalgiaText, constitutionalNotes: res.data.history.constitutionalNotes,
            bleedingChoice: res.data.history.bleedingChoice, bleedingText: res.data.history.bleedingText, bruisingChoice: res.data.history.bruisingChoice, bruisingText: res.data.history.bruisingText, swellingChoice: res.data.history.swellingChoice, swellingText: res.data.history.swellingText, deformityChoice: res.data.history.deformityChoice, deformityText: res.data.history.deformityText, mssNotes: res.data.history.mssNotes,
            rashChoice: res.data.history.rashChoice, rashText: res.data.history.rashText, itchynessChoice: res.data.history.itchynessChoice, itchynessText: res.data.history.itchynessText, blistersChoice: res.data.history.blistersChoice, blistersText: res.data.history.blistersText, lumpsChoice: res.data.history.lumpsChoice, lumpsText: res.data.history.lumpsText, ulcersChoice: res.data.history.ulcersChoice, ulcersText: res.data.history.ulcersText, swellingdemaChoice: res.data.history.swellingdemaChoice, swellingdemaText: res.data.history.swellingdemaText, dematologyNotes: res.data.history.dematologyNotes,
         /* EXAMINATION */  appearanceChoice: res.data.examination.appearanceChoice, appearanceText: res.data.examination.appearanceText, behaviourChoice: res.data.examination.behaviourChoice, behaviourText: res.data.examination.behaviourText, dehydrationChoice: res.data.examination.dehydrationChoice, dehydrationText: res.data.examination.dehydrationText, wastingChoice: res.data.examination.wastingChoice, wastingText: res.data.examination.wastingText, hirsutismChoice: res.data.examination.hirsutismChoice, hirsutismText: res.data.examination.hirsutismText, thyroidChoice: res.data.examination.thyroidChoice, thyroidText: res.data.examination.thyroidText, palpableChoice: res.data.examination.palpableChoice, palpableText: res.data.examination.palpableText, clubbingChoice: res.data.examination.clubbingChoice, clubbingText: res.data.examination.clubbingText, pallorChoice: res.data.examination.pallorChoice, pallorText: res.data.examination.pallorText, icterusChoice: res.data.examination.icterusChoice, icterusText: res.data.examination.icterusText, cyanosisChoice: res.data.examination.cyanosisChoice, cyanosisText: res.data.examination.cyanosisText, edemaChoice: res.data.examination.edemaChoice, edemaText: res.data.examination.edemaText, lossphyChoice: res.data.examination.lossphyChoice, lossphyText: res.data.examination.lossphyText, herniaChoice: res.data.examination.herniaChoice, herniaText: res.data.examination.herniaText, examinationNotes: res.data.examination.examinationNotes,
                    fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, drugAllergy: res.data.drugAllergy, bmi: res.data.bmi, otherAllergy: res.data.otherAllergy, nurseNotes: res.data.nurseNotes, bedDetails: res.data.bedDetails, vitalColors: res.data.vitalColors, notes: res.data.notes, referralLetters: res.data.referralLetters, managementLists: res.data.managementLists, historyVisits: res.data.historyVisits, drugHistoryList: res.data.drugHistoryList, patientDiagnosis: res.data.patientDiagnosis
                });
                setTimeout(() => { this.setLoader(false)  }, 2000);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {
                visitStatus: this.state.visitStatus, patientId: this.state.patientId, visitId: this.state.visitId, managementData: this.state.managementData, 
                history: {historyData: this.state.historyData, tbChoice: this.state.tbChoice, tbText: this.state.tbText, asthmaChoice: this.state.asthmaChoice, asthmaText: this.state.asthmaText, diabetesChoice: this.state.diabetesChoice, diabetesText: this.state.diabetesText, hypertensionChoice: this.state.hypertensionChoice, hypertensionText: this.state.hypertensionText, myocardialChoice: this.state.myocardialChoice, myocardialText: this.state.myocardialText, migraineChoice: this.state.migraineChoice, migraineText: this.state.migraineText, epilepsyChoice: this.state.epilepsyChoice, epilepsyText: this.state.epilepsyText, skinDiseaseChoice: this.state.skinDiseaseChoice, skinDiseaseText: this.state.skinDiseaseText, pregnantChoice: this.state.pregnantChoice, pregnantText: this.state.pregnantText, retroviralChoice: this.state.retroviralChoice, retroviralText: this.state.retroviralText, historyOther: this.state.historyOther,
                    coughChoice: this.state.coughChoice, coughText: this.state.coughText, coryzaChoice: this.state.coryzaChoice, coryzaText: this.state.coryzaText, sobChoice: this.state.sobChoice, sobText: this.state.sobText, sneazingChoice: this.state.sneazingChoice, sneazingText: this.state.sneazingText, phelgmChoice: this.state.phelgmChoice, phelgmText: this.state.phelgmText, sputumChoice: this.state.sputumChoice, sputumText: this.state.sputumText, haemoptysisChoice: this.state.haemoptysisChoice, haemoptysisText: this.state.haemoptysisText, wheezingChoice: this.state.wheezingChoice, wheezingText: this.state.wheezingText, nasalChoice: this.state.nasalChoice, nasalText: this.state.nasalText, soreChoice: this.state.soreChoice, soreText: this.state.soreText, chestPainChoice: this.state.chestPainChoice, chestPainText: this.state.chestPainText, respitoryNotes: this.state.respitoryNotes,
                    nauseaChoice: this.state.nauseaChoice, nauseaText: this.state.nauseaText, vomitingChoice: this.state.vomitingChoice, vomitingText: this.state.vomitingText, diarrhoeaChoice: this.state.diarrhoeaChoice, diarrhoeaText: this.state.diarrhoeaText, abdominialChoice: this.state.abdominialChoice, abdominialText: this.state.abdominialText, flatulanceChoice: this.state.flatulanceChoice, flatulanceText: this.state.flatulanceText, flatulentChoice: this.state.flatulentChoice, flatulentText: this.state.flatulentText, waterChoice: this.state.waterChoice, waterText: this.state.waterText, constipationChoice: this.state.constipationChoice, constipationText: this.state.constipationText, prChoice: this.state.prChoice, prText: this.state.prText, wtChoice: this.state.wtChoice, wtText: this.state.wtText, melenaChoice: this.state.melenaChoice, melenaText: this.state.melenaText, jaundiceChoice: this.state.jaundiceChoice, jaundiceText: this.state.jaundiceText, loaChoice: this.state.loaChoice, loaText: this.state.loaText, lowChoice: this.state.lowChoice, lowText: this.state.lowText, gitNotes: this.state.gitNotes,
                    dysuriaChoice: this.state.dysuriaChoice, dysuriaText: this.state.dysuriaText, frequencyChoice: this.state.frequencyChoice, frequencyText: this.state.frequencyText, haematuriaChoice: this.state.haematuriaChoice, haematuriaText: this.state.haematuriaText, weakChoice: this.state.weakChoice, weakText: this.state.weakText, strainingChoice: this.state.strainingChoice, strainingText: this.state.strainingText, urineChoice: this.state.urineChoice, urineText: this.state.urineText, urethalChoice: this.state.urethalChoice, urethalText: this.state.urethalText, pvChoice: this.state.pvChoice, pvText: this.state.pvText, nocturiaChoice: this.state.nocturiaChoice, nocturiaText: this.state.nocturiaText, genitalChoice: this.state.genitalChoice, genitalText: this.state.genitalText, lossChoice: this.state.lossChoice, lossText: this.state.lossText, erectileChoice: this.state.erectileChoice, erectileText: this.state.erectileText, gusNotes: this.state.gusNotes,
                    dysaruniaChoice: this.state.dysaruniaChoice, dysaruniaText: this.state.dysaruniaText, dysmemorrhoeaChoice: this.state.dysmemorrhoeaChoice, dysmemorrhoeaText: this.state.dysmemorrhoeaText, imbChoice: this.state.imbChoice, imbText: this.state.imbText, contactChoice: this.state.contactChoice, contactText: this.state.contactText, menorrhagiaChoice: this.state.menorrhagiaChoice, menorrhagiaText: this.state.menorrhagiaText, lapChoice: this.state.lapChoice, lapText: this.state.lapText, backacheChoice: this.state.backacheChoice, backackeText: this.state.backackeText, subfertilityChoice: this.state.subfertilityChoice, subfertilityText: this.state.subfertilityText, hotChoice: this.state.hotChoice, hotText: this.state.hotText, lnmpChoice: this.state.lnmpChoice, lnmpText: this.state.lnmpText, gynaeNotes: this.state.gynaeNotes,
                    orthopneaChoice: this.state.orthopneaChoice, orthopneaText: this.state.orthopneaText, pndChoice: this.state.pndChoice, pndText: this.state.pndText, easyChoice: this.state.easyChoice, easyText: this.state.easyText, palpitationsChoice: this.state.palpitationsChoice, palpitationsText: this.state.palpitationsText, synacopeChoice: this.state.synacopeChoice, synacopeText: this.state.synacopeText, legSwellingChoice: this.state.legSwellingChoice, legSwellingText: this.state.legSwellingText, chestPaincvsChoice: this.state.chestPaincvsChoice, chestPaincvsText: this.state.chestPaincvsText, calfSwellingChoice: this.state.calfSwellingChoice, calfSwellingText: this.state.calfSwellingText, pinkFrothChoice: this.state.pinkFrothChoice, pinkFrothText: this.state.pinkFrothText, gelatinousChoice: this.state.gelatinousChoice, gelatinousText: this.state.gelatinousText, cvsNotes: this.state.cvsNotes,
                    headacheChoice: this.state.headacheChoice, headacheText: this.state.headacheText, paraesthesiaChoice: this.state.paraesthesiaChoice, paraesthesiaText: this.state.paraesthesiaText, dizzinessChoice: this.state.dizzinessChoice, dizzinessText: this.state.dizzinessText, backackeChoice: this.state.backackeChoice, backacheText: this.state.backacheText, photophibiaChoice: this.state.photophibiaChoice, photophibiaText: this.state.photophibiaText, neckstiffnessChoice: this.state.neckstiffnessChoice, neckstiffnessText: this.state.neckstiffnessText, fittingChoice: this.state.fittingChoice, fittingText: this.state.fittingText, paresisChoice: this.state.paresisChoice, paresisText: this.state.paresisText, paralysisChoice: this.state.paralysisChoice, paralysisText: this.state.paralysisText, insomniaChoice: this.state.insomniaChoice, insomniaText: this.state.insomniaText, hypersomniaChoice: this.state.hypersomniaChoice, hypersomniaText: this.state.hypersomniaText, abnChoice: this.state.abnChoice, abnText: this.state.abnText, cnsNotes: this.state.cnsNotes,
                    feverChoice: this.state.feverChoice, feverText: this.state.feverText, sweatingChoice: this.state.sweatingChoice, sweatingText: this.state.sweatingText, chillsChoice: this.state.chillsChoice, chillsText: this.state.chillsText, rigorsChoice: this.state.rigorsChoice, rigorsText: this.state.rigorsText, fatigueChoice: this.state.fatigueChoice, fatigueText: this.state.fatigueText, myalgiaChoice: this.state.myalgiaChoice, myalgiaText: this.state.myalgiaText, constitutionalNotes: this.state.constitutionalNotes,
                    bleedingChoice: this.state.bleedingChoice, bleedingText: this.state.bleedingText, bruisingChoice: this.state.bruisingChoice, bruisingText: this.state.bruisingText, swellingChoice: this.state.swellingChoice, swellingText: this.state.swellingText, deformityChoice: this.state.deformityChoice, deformityText: this.state.deformityText, mssNotes: this.state.mssNotes,
                    rashChoice: this.state.rashChoice, rashText: this.state.rashText, itchynessChoice: this.state.itchynessChoice, itchynessText: this.state.itchynessText, blistersChoice: this.state.blistersChoice, blistersText: this.state.blistersText, lumpsChoice: this.state.lumpsChoice, lumpsText: this.state.lumpsText, ulcersChoice: this.state.ulcersChoice, ulcersText: this.state.ulcersText, swellingdemaChoice: this.state.swellingdemaChoice, swellingdemaText: this.state.swellingdemaText, dematologyNotes: this.state.dematologyNotes}
              , examination: { appearanceChoice: this.state.appearanceChoice, appearanceText: this.state.appearanceText, behaviourChoice: this.state.behaviourChoice, behaviourText: this.state.behaviourText, dehydrationChoice: this.state.dehydrationChoice, dehydrationText: this.state.dehydrationText, wastingChoice: this.state.wastingChoice, wastingText: this.state.wastingText, hirsutismChoice: this.state.hirsutismChoice, hirsutismText: this.state.hirsutismText, thyroidChoice: this.state.thyroidChoice, thyroidText: this.state.thyroidText, palpableChoice: this.state.palpableChoice, palpableText: this.state.palpableText, clubbingChoice: this.state.clubbingChoice, clubbingText: this.state.clubbingText, pallorChoice: this.state.pallorChoice, pallorText: this.state.pallorText, icterusChoice: this.state.icterusChoice, icterusText: this.state.icterusText, cyanosisChoice: this.state.cyanosisChoice, cyanosisText: this.state.cyanosisText, edemaChoice: this.state.edemaChoice, edemaText: this.state.edemaText, lossphyChoice: this.state.lossphyChoice, lossphyText: this.state.lossphyText, herniaChoice: this.state.herniaChoice, herniaText: this.state.herniaText, notes: this.state.examinationNotes}
           
            , patientDiagnosis: this.state.patientDiagnosis, diagnosisText: this.state.diagnosisText, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, referrals: this.state.referrals, treatments: this.state.treatments, investigations: this.state.investigations, managementLists: this.state.managementLists, drugHistoryList: this.state.drugHistoryList
        }
        axios.post(localStorage.getItem('host') + '/hospital/casualtysave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.getList()
                document.getElementById('topDiv').scrollIntoView()
                this.openCasualtyAction(this.state.visitId,  res.data )
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('casualtyAction').style = 'display:none'
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                var otherAllergy = 0;
                value = value > 0 && value < 250 ? value : 0
                if (value > 0 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                if (value > 0 && this.state.waist > 0) {
                    otherAllergy = Number((this.state.waist / value).toFixed("2"))
                }
                this.setState({ height: value, bmi: bmi, otherAllergy: otherAllergy })
                break;
            default:
                otherAllergy = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    otherAllergy = Number((value / this.state.height).toFixed("2"))
                }
                this.setState({ waist: value, otherAllergy: otherAllergy })
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : 0;
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 120 ? value : 0;
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 30 ? value : 0;
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 140 ? value : 0;
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : 0;
                break;
            default:
                list[index].spO2 = value > 0 && value < 100 ? value : 0;
                break;

        }
        this.setState({ vitals: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature: 0, pulse: 0, glucometer: 0, systolic: 0, diastolic: 0, spO2: 0 });
        this.setState({ vitals: list })
    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/hospital/casualtysummaryview/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/hospital/casualtysummaryviewconsolidated/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showTreatmentRecord(patientId) {

        this.setState({ treatmentRecords: [] })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/hospitala/treatmentlistview/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ treatmentRecords: res.data });
                document.getElementById('treatmentRecord').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }

    treatmentClosePopup() {
        document.getElementById('treatmentRecord').style = 'display:none'
    }
    viewResults() {
        document.getElementById('resultPopup').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension });
        document.getElementById('showFileNurseAction').style = 'display:block'
        //'data:application/pdf;base64,' +

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    toggleTabs(name) {
        switch (name) {
            case "patientMedicalHistory":
                this.setState({ history: false, patientMedicalHistory: true, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false })
                break
            case "examination":
                this.setState({ history: false, patientMedicalHistory: false, examination: true, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false, heent: true, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "investigation":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: true, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false })
                break
            case "treatment":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: false, treatment: true, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false })
                break
            case "emergency":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: true, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false })
                break
            case "referral":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: false, referral: true, medicalCertificate: false, review: false, management: false, drugHistory: false })
                break
            case "medicalCertificate":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: true, review: false, management: false, drugHistory: false })
                break
            case "review":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: true, management: false, drugHistory: false })
                break
            case "management":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: true, drugHistory: false })
                break
            case "drugHistory":
                this.setState({ history: false, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: true })
                break
            default:
                this.setState({ history: true, patientMedicalHistory: false, examination: false, investigation: false, treatment: false, emergency: false, referral: false, medicalCertificate: false, review: false, management: false, drugHistory: false })
                break

        }
    }
    addReviewTable(reviewDate, reviewTime) {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: reviewDate, reviewTime: reviewTime, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }


    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addInvestigationTable() {
        let list = this.state.investigations.slice();
        list.push({ id: 0, name: '', activeStatus: 1 });
        this.setState({ investigations: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addTreatmentTable() {
        let list = this.state.treatments.slice();
        list.push({ id: 0, dosage: '', drugName: '', formulationName: '', frequencyName: '', routeName: '', durationName: '', repeats: '', activeStatus: 1 });
        this.setState({ treatments: list });
    }
    addDrugHistory() {
        let list = this.state.drugHistoryList.slice();
        list.push('');
        this.setState({ drugHistoryList: list });
    }
    addManagement() {
        let list = this.state.managementLists.slice();
        const now = new Date();
        list.push({ id: 0, time: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), procedure:'' });
        this.setState({ managementLists: list })
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
    deleteInvestigation(index) {
        let list = this.state.investigations.slice();
        list[index].activeStatus = 0
        this.setState({ investigations: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteTreatment(index) {
        let list = this.state.treatments.slice();
        list[index].activeStatus = 0
        this.setState({ treatments: list });
    }
    deleteDrugHistory(index)
    {
        let list = this.state.drugHistoryList.slice();
        list.splice(index, 1); 
        this.setState({ drugHistoryList: list });  
    }
    deleteManagement(index)
    {
        let list = this.state.managementLists.slice();
        list.splice(index, 1); 
        this.setState({ managementLists: list });  
    }
    setDrugHistory(index, value) {
        let list = this.state.drugHistoryList.slice();
                list[index] = value
        this.setState({ drugHistoryList: list });
    }

    setManagement(index, name, value) {
        let list = this.state.managementLists.slice();
        switch (name) {
            case "time":
                list[index].time = value
                break;
            default:
                list[index].procedure = value
                break;
        }
        this.setState({ managementLists: list });
    }

    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setInvestigation(index, value) {
        let list = this.state.investigations.slice();
        for (var i in this.state.services) {
            if (this.state.services[i].value === value) {
                list[index].name = value
                list[index].speciality = this.state.services[i].speciality
            }
        }
        this.setState({ investigations: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setTreatment(index, name, value) {
        let list = this.state.treatments.slice();
        switch (name) {
            case "dosage":
                list[index].dosage = value
                break;
            case "drugName":
                list[index].drugName = value
                break;
            case "formulationName":
                list[index].formulationName = value
                break;
            case "frequencyName":
                list[index].frequencyName = value
                break;
            case "routeName":
                list[index].routeName = value
                break;
            case "durationName":
                list[index].durationName = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ treatments: list });
    }

    toggleHistoryTabs(name) {
        switch (name) {
            case "presentingComplaints":
                this.setState({ presentingComplaints: true, respiratory: false, heent: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "heent":
                this.setState({ presentingComplaints: false, respiratory: false, heent: true, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "git":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: true, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "gus":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: false, gus: true, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "gynae":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: false, gus: false, gynae: true, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "cvs":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: false, gus: false, gynae: false, cvs: true, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "cns":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: false, gus: false, gynae: false, cvs: false, cns: true, constitutional: false, mss: false, dermatology: false })
                break
            case "constitutional":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: true, mss: false, dermatology: false })
                break
            case "mss":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: true, dermatology: false })
                break
            case "dermatology":
                this.setState({ presentingComplaints: false, respiratory: false, heent: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: true })
                break
            default:
                this.setState({ presentingComplaints: false, respiratory: true, heent: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break

        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    updateAfterSave(visitId, results)
     {
     if(results.length > 0)
        {
        this.setState({results: results})
        }
      else
        {
            axios.get(localStorage.getItem('host') + '/patientmanagement/nursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, drugAllergy: res.data.drugAllergy, muac: res.data.muac, bmi: res.data.bmi, otherAllergy: res.data.otherAllergy, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors});
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }  
     }
     setLoader(loader)
       {
        this.setState({loader: loader})
       }
       getReviewTime(reviewTime)
          {
           var time = ''
           for(var j in this.state.appointmentTimes)
           {
            if(Number(reviewTime) === Number(this.state.appointmentTimes[j].id))
               {
                time = this.state.appointmentTimes[j].name
               }
           }
           return time
          }   


    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="casualtyAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Casualty Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="treatmentRecord" class="page-popup page-popup-overlay"><div class="card" >
                    <div class="card-header   bg-info">Treatment Record</div>
                    <div class="card-body" >
                        {this.state.treatmentRecords.map((item, index) => (
                            <div class="row" key={index} >
                                <div class="col-xs-12 col-md-12 col-lg-12">
                                    <div class="card border-success">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col"><span className="fw-bold">Date:</span> {item.treatmentDateTime}</div>
                                                <div className="col"><span className="fw-bold"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped" >
                                        <tr>
                                            <th>Drug</th>
                                            <th>Dosage</th>
                                            <th>Frequency</th>
                                            <th>Route</th>
                                            <th>Duration</th>
                                            <th>Formulation</th>
                                            <th>Repeats</th>
                                        </tr>
                                        <tbody>
                                            {item.treatments.map((treatment, i) => (
                                                <tr key={i} >
                                                    <td>{treatment.drugName}</td>
                                                    <td>{treatment.dosage}</td>
                                                    <td>{treatment.frequencyName}</td>
                                                    <td>{treatment.routeName}</td>
                                                    <td> {treatment.durationName}</td>
                                                    <td>{treatment.formulationName}</td>
                                                    <td>{treatment.repeats}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        <div class="col" align="center" ><input class="btn btn-primary" type='button' onClick={() => this.treatmentClosePopup()} value="Close" /></div>
                    </div>
                </div>
                </div>
                <div id="resultPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>Treatment Date</th>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.visitDate}</td>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'DOCTOR', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        {this.state.allergies && (<div className="card card-red">
                            <div className="card-header text-dark">
                                Allergies
                            </div>
                            <div className="card-body">
                                <span>{this.state.allergies}</span>
                            </div>
                        </div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card card-green">
                            <div className="card-header  text-dark">Patient History</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewPatientHistory(this.state.patientId)}>Summary</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                            <ul className="nav nav-pills  d-flex flex-column" role="tablist">
                            <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                            <li className="nav-item"><span className={this.state.patientMedicalHistory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("patientMedicalHistory") }}>Past Medical History</span></li>
                            <li className="nav-item"><span className={this.state.drugHistory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("drugHistory") }}>Drug History</span></li>
                            <li className="nav-item"><span className={this.state.examination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examination") }}>Examination</span></li>
                            <li className="nav-item"><span className={this.state.emergency ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("emergency") }}>Emergency Examination</span></li>
                            <li className="nav-item"><span className={this.state.investigation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("investigation") }}>Investigations</span></li>
                            <li className="nav-item"><span className={this.state.management ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("management") }}>Management/ Procedure</span></li>
                           <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                            <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                            <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                            <li className="nav-item"><span className={this.state.treatment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatment") }}>Prescription</span></li>
                                  </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body"> 
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    {(this.state.results.length > 0) && (<li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>)}
                                    <li><span className="link" onClick={() => this.showTreatmentRecord(this.state.patientId)}>View Prescription Records</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Weight (Kg):</span> {this.state.weight}</div>
                                    <div className="col"><span className="fw-bold">Height (cm):</span> {this.state.height}</div>
                                    <div className="col"><span className="fw-bold">BMI:</span> {this.state.bmi}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Drug Allergies:</span> {this.state.drugAllergy}</div>
                                    <div className="col"><span className="fw-bold">Other Allergies:</span> {this.state.otherAllergy}</div>
                                    <div className="col"><span className="fw-bold">Bed Details:</span> <span >{this.state.bedDetails}</span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Notes:</span> <span >{this.state.nurseNotes}</span></div>
                                </div>
                            </div>
                        </div>
                       { this.state.showUrinalysis &&(<div className="card card-blue"><div className="card-header">Urine Analysis</div><div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Ph:</span> {this.state.ph}</div>
                                    <div className="col"><span className="fw-bold">Ketones:</span> {this.state.ketones}</div>
                                    <div className="col"><span className="fw-bold">Sg:</span> {this.state.sg}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Proteins:</span> {this.state.proteins}</div>
                                    <div className="col"><span className="fw-bold">Leucocytes:</span> {this.state.leucocytes}</div>
                                    <div className="col"><span className="fw-bold">Urobilinogen:</span> {this.state.urobilinogen}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Blood:</span> {this.state.blood}</div>
                                    <div className="col"><span className="fw-bold">Nitrate:</span> {this.state.nitrate}</div>
                                    <div className="col"><span className="fw-bold">Glucose:</span> {this.state.glucose}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Other:</span> {this.state.otherUrineAnaysis}</div>
                                </div>
                                </div></div>)}
                        <div className="card border-success">
                            <div className="card-body">
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Bp</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>SpO2 (%)</th>
                                        <th>GCS / 15</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitalColors.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.vitalTime}</td>
                                                <td style={{ color: item.temperatureColor }}>{item.temperature}</td>
                                                <td><span style={{ color: item.systolicColor }}>{item.systolic}</span>/<span style={{ color: item.diastolicColor }}>{item.diastolic}</span></td>
                                                <td style={{ color: item.pulseColor }}>{item.pulse}</td>
                                                <td style={{ color: item.glucometerColor }}>{item.glucometer}</td>
                                                <td style={{ color: item.spO2Color }}>{item.spO2}</td>
                                                <td style={{ color: item.gcsColor }}>{item.gcs}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                        <div className="row">
                                        <div className="col"><div className="input-group">
                                            <span className="input-group-text">Problem</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea> </div>
                                        </div>
                                    </div>
                                    </div>
                        </div>
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                        </div>
                        <br />
                        
                        <div className="tab-content" id="myTabContent">

                        <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                    <ul className="nav nav-tabs">
                                    <li className="nav-item"><span className={this.state.presentingComplaints ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaints") }}>Presenting Complaints</span></li>
                                            <li className="nav-item"><span className={this.state.respiratory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("respiratory") }}>RESPIRATORY</span></li>
                                            <li className="nav-item"><span className={this.state.git ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("git") }}>GIT</span></li>
                                            <li className="nav-item"><span className={this.state.gus ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("gus") }}>GUS</span></li>
                                            <li className="nav-item"><span className={this.state.gynae ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("gynae") }}>GYNAE/OBS</span></li>
                                            <li className="nav-item"><span className={this.state.cvs ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("cvs") }}>CVS</span></li>
                                            <li className="nav-item"><span className={this.state.cns ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("cns") }}>CNS</span></li>
                                            <li className="nav-item"><span className={this.state.constitutional ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("constitutional") }}>CONSTITUTIONAL</span></li>
                                            <li className="nav-item"><span className={this.state.mss ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("mss") }}>MSS</span></li>
                                            <li className="nav-item"><span className={this.state.dermatology ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("dermatology") }}>DERMATOLOGY</span></li>
                                        </ul>
                                        <br />
                                        <div className="tab-content">
                                        <div className={this.state.presentingComplaints ? "tab-pane fade show active" : "tab-pane fade"}>
                                        <div className="row">
                                            <div className="col"><div className="input-group">
                                                <textarea className="form-control" value={this.state.historyData} onChange={e => this.setState({ historyData: e.target.value })} ></textarea></div></div>
                                        </div>
                                            </div>
                                            <div className={this.state.respiratory ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.coughChoice === "1"} onChange={e => this.setState({ coughChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Cough </span><input type="text" value={this.state.coughText} onChange={e => this.setState({ coughText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.coryzaChoice === "1"} onChange={e => this.setState({ coryzaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Coryza </span> <input type="text" value={this.state.coryzaText} onChange={e => this.setState({ coryzaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sobChoice === "1"} onChange={e => this.setState({ sobChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> SOB </span> <input type="text" value={this.state.sobText} onChange={e => this.setState({ sobText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sneazingChoice === "1"} onChange={e => this.setState({ sneazingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sneezing </span><input type="text" value={this.state.sneazingText} onChange={e => this.setState({ sneazingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.phelgmChoice === "1"} onChange={e => this.setState({ phelgmChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Phelgm</span> <input type="text" value={this.state.phelgmText} onChange={e => this.setState({ phelgmText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sputumChoice === "1"} onChange={e => this.setState({ sputumChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Sputum </span><input type="text" value={this.state.sputumText} onChange={e => this.setState({ sputumText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.haemoptysisChoice === "1"} onChange={e => this.setState({ haemoptysisChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Haemoptysis </span> <input type="text" value={this.state.haemoptysisText} onChange={e => this.setState({ haemoptysisText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.wheezingChoice === "1"} onChange={e => this.setState({ wheezingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Wheezing </span><input type="text" value={this.state.wheezingText} onChange={e => this.setState({ wheezingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.nasalChoice === "1"} onChange={e => this.setState({ nasalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Nasal Congestion</span>  <input type="text" value={this.state.nasalText} onChange={e => this.setState({ nasalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.soreChoice === "1"} onChange={e => this.setState({ soreChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sore Throat</span> <input type="text" value={this.state.soreText} onChange={e => this.setState({ soreText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.chestPainChoice === "1"} onChange={e => this.setState({ chestPainChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Chest Pain</span> <input type="text" value={this.state.chestPainText} onChange={e => this.setState({ chestPainText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other</span> <textarea value={this.state.respitoryNotes} onChange={e => this.setState({ respitoryNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.git ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.nauseaChoice === "1"} onChange={e => this.setState({ nauseaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Nausea </span> <input type="text" value={this.state.nauseaText} onChange={e => this.setState({ nauseaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.vomitingChoice === "1"} onChange={e => this.setState({ vomitingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Vomiting  </span> <input type="text" value={this.state.vomitingText} onChange={e => this.setState({ vomitingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.diarrhoeaChoice === "1"} onChange={e => this.setState({ diarrhoeaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Diarrhoea</span>  <input type="text" value={this.state.diarrhoeaText} onChange={e => this.setState({ diarrhoeaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.abdominialChoice === "1"} onChange={e => this.setState({ abdominialChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Abdominial Pain </span>  <input type="text" value={this.state.abdominialText} onChange={e => this.setState({ abdominialText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.flatulanceChoice === "1"} onChange={e => this.setState({ flatulanceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Flatulance Lower </span> <input type="text" value={this.state.flatulanceText} onChange={e => this.setState({ flatulanceText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.flatulentChoice === "1"} onChange={e => this.setState({ flatulentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Flatulent Dyspepsia</span>  <input type="text" value={this.state.flatulentText} onChange={e => this.setState({ flatulentText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.waterChoice === "1"} onChange={e => this.setState({ waterChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Water Brush</span>  <input type="text" value={this.state.waterText} onChange={e => this.setState({ waterText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.constipationChoice === "1"} onChange={e => this.setState({ constipationChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Constipation</span>   <input type="text" value={this.state.constipationText} onChange={e => this.setState({ constipationText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.prChoice === "1"} onChange={e => this.setState({ prChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pr Bleeding </span> <input type="text" value={this.state.prText} onChange={e => this.setState({ prText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.wtChoice === "1"} onChange={e => this.setState({ wtChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Wt Gain </span> <input type="text" value={this.state.wtText} onChange={e => this.setState({ wtText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.melenaChoice === "1"} onChange={e => this.setState({ melenaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Melena </span>  <input type="text" value={this.state.melenaText} onChange={e => this.setState({ melenaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.jaundiceChoice === "1"} onChange={e => this.setState({ jaundiceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Jaundice</span>  <input type="text" value={this.state.jaundiceText} onChange={e => this.setState({ jaundiceText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.loaChoice === "1"} onChange={e => this.setState({ loaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LOA</span>  <input type="text" value={this.state.loaText} onChange={e => this.setState({ loaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lowChoice === "1"} onChange={e => this.setState({ lowChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LOW</span>  <input type="text" value={this.state.lowText} onChange={e => this.setState({ lowText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span> <textarea value={this.state.gitNotes} onChange={e => this.setState({ gitNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.gus ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.dysuriaChoice === "1"} onChange={e => this.setState({ dysuriaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dysuria </span><input type="text" value={this.state.dysuriaText} onChange={e => this.setState({ dysuriaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.frequencyChoice === "1"} onChange={e => this.setState({ frequencyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Frequency </span> <input type="text" value={this.state.frequencyText} onChange={e => this.setState({ frequencyText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.haematuriaChoice === "1"} onChange={e => this.setState({ haematuriaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Haematuria </span> <input type="text" value={this.state.haematuriaText} onChange={e => this.setState({ haematuriaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.weakChoice === "1"} onChange={e => this.setState({ weakChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Weak Stream </span>  <input type="text" value={this.state.weakText} onChange={e => this.setState({ weakText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.strainingChoice === "1"} onChange={e => this.setState({ strainingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Straining </span> <input type="text" value={this.state.strainingText} onChange={e => this.setState({ strainingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.urineChoice === "1"} onChange={e => this.setState({ urineChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Urine Retention </span>  <input type="text" value={this.state.urineText} onChange={e => this.setState({ urineText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.urethalChoice === "1"} onChange={e => this.setState({ urethalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Urethral D/C  </span> <input type="text" value={this.state.urethalText} onChange={e => this.setState({ urethalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.pvChoice === "1"} onChange={e => this.setState({ pvChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">PV D/C  </span><input type="text" value={this.state.pvText} onChange={e => this.setState({ pvText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.nocturiaChoice === "1"} onChange={e => this.setState({ nocturiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Nocturia  </span> <input type="text" value={this.state.nocturiaText} onChange={e => this.setState({ nocturiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.genitalChoice === "1"} onChange={e => this.setState({ genitalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Genital Sores </span> <input type="text" value={this.state.genitalText} onChange={e => this.setState({ genitalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lossChoice === "1"} onChange={e => this.setState({ lossChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Loss Of Libido </span>  <input type="text" value={this.state.lossText} onChange={e => this.setState({ lossText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.erectileChoice === "1"} onChange={e => this.setState({ erectileChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Erectille Dysfunction </span> <input type="text" value={this.state.erectileText} onChange={e => this.setState({ erectileText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other  </span><textarea value={this.state.gusNotes} onChange={e => this.setState({ gusNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.gynae ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.dysaruniaChoice === "1"} onChange={e => this.setState({ dysaruniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dysarunia </span><input type="text" value={this.state.dysaruniaText} onChange={e => this.setState({ dysaruniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.dysmemorrhoeaChoice === "1"} onChange={e => this.setState({ dysmemorrhoeaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Dysmemorrhoea </span><input type="text" value={this.state.dysmemorrhoeaText} onChange={e => this.setState({ dysmemorrhoeaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.imbChoice === "1"} onChange={e => this.setState({ imbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">IMB  </span><input type="text" value={this.state.imbText} onChange={e => this.setState({ imbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.contactChoice === "1"} onChange={e => this.setState({ contactChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Contact Bleeding  </span><input type="text" value={this.state.contactText} onChange={e => this.setState({ contactText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.menorrhagiaChoice === "1"} onChange={e => this.setState({ menorrhagiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Menorrhagia </span> <input type="text" value={this.state.menorrhagiaText} onChange={e => this.setState({ menorrhagiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lapChoice === "1"} onChange={e => this.setState({ lapChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LAP  </span> <input type="text" value={this.state.lapText} onChange={e => this.setState({ lapText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.backacheChoice === "1"} onChange={e => this.setState({ backacheChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache  </span> <input type="text" value={this.state.backackeText} onChange={e => this.setState({ backackeText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.subfertilityChoice === "1"} onChange={e => this.setState({ subfertilityChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Subfertility </span> <input type="text" value={this.state.subfertilityText} onChange={e => this.setState({ subfertilityText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.hotChoice === "1"} onChange={e => this.setState({ hotChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hot Flashes  </span><input type="text" value={this.state.hotText} onChange={e => this.setState({ hotText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lnmpChoice === "1"} onChange={e => this.setState({ lnmpChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LNMP </span>  <input type="text" value={this.state.lnmpText} onChange={e => this.setState({ lnmpText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.gynaeNotes} onChange={e => this.setState({ gynaeNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.cvs ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.orthopneaChoice === "1"} onChange={e => this.setState({ orthopneaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Orthopnea</span> <input type="text" value={this.state.orthopneaText} onChange={e => this.setState({ orthopneaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.pndChoice === "1"} onChange={e => this.setState({ pndChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">PND </span><input type="text" value={this.state.pndText} onChange={e => this.setState({ pndText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.easyChoice === "1"} onChange={e => this.setState({ easyChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Easy Fatigability</span> <input type="text" value={this.state.easyText} onChange={e => this.setState({ easyText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.palpitationsChoice === "1"} onChange={e => this.setState({ palpitationsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Palpitations</span>  <input type="text" value={this.state.palpitationsText} onChange={e => this.setState({ palpitationsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.synacopeChoice === "1"} onChange={e => this.setState({ synacopeChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Syncope</span>  <input type="text" value={this.state.synacopeText} onChange={e => this.setState({ synacopeText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.legSwellingChoice === "1"} onChange={e => this.setState({ legSwellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Leg Swelling</span> <input type="text" value={this.state.legSwellingText} onChange={e => this.setState({ legSwellingText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.chestPaincvsChoice === "1"} onChange={e => this.setState({ chestPaincvsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Central Chest Pain </span><input type="text" value={this.state.chestPaincvsText} onChange={e => this.setState({ chestPaincvsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.calfSwellingChoice === "1"} onChange={e => this.setState({ calfSwellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Calf Swelling</span> <input type="text" value={this.state.calfSwellingText} onChange={e => this.setState({ calfSwellingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.pinkFrothChoice === "1"} onChange={e => this.setState({ pinkFrothChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pink Froth</span> <input type="text" value={this.state.pinkFrothText} onChange={e => this.setState({ pinkFrothText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.gelatinousChoice === "1"} onChange={e => this.setState({ gelatinousChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Gelatinous Sputum</span> <input type="text" value={this.state.gelatinousText} onChange={e => this.setState({ gelatinousText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other</span>  <textarea value={this.state.cvsNotes} onChange={e => this.setState({ cvsNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.cns ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.headacheChoice === "1"} onChange={e => this.setState({ headacheChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Headache</span><input type="text" value={this.state.headacheText} onChange={e => this.setState({ headacheText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.paraesthesiaChoice === "1"} onChange={e => this.setState({ paraesthesiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paraesthesia</span><input type="text" value={this.state.paraesthesiaText} onChange={e => this.setState({ paraesthesiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.dizzinessChoice === "1"} onChange={e => this.setState({ dizzinessChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dizziness</span><input type="text" value={this.state.dizzinessText} onChange={e => this.setState({ dizzinessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.backackeChoice === "1"} onChange={e => this.setState({ backackeChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache</span><input type="text" value={this.state.backacheText} onChange={e => this.setState({ backacheText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.photophibiaChoice === "1"} onChange={e => this.setState({ photophibiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Photophobia</span><input type="text" value={this.state.photophibiaText} onChange={e => this.setState({ photophibiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.neckstiffnessChoice === "1"} onChange={e => this.setState({ neckstiffnessChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Neck Stiffness</span>  <input type="text" value={this.state.neckstiffnessText} onChange={e => this.setState({ neckstiffnessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fittingChoice === "1"} onChange={e => this.setState({ fittingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Fitting</span><input type="text" value={this.state.fittingText} onChange={e => this.setState({ fittingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.paresisChoice === "1"} onChange={e => this.setState({ paresisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paresis </span><input type="text" value={this.state.paresisText} onChange={e => this.setState({ paresisText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.paralysisChoice === "1"} onChange={e => this.setState({ paralysisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paralysis </span><input type="text" value={this.state.paralysisText} onChange={e => this.setState({ paralysisText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.insomniaChoice === "1"} onChange={e => this.setState({ insomniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Insomnia</span> <input type="text" value={this.state.insomniaText} onChange={e => this.setState({ insomniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.hypersomniaChoice === "1"} onChange={e => this.setState({ hypersomniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hypersomnia</span><input type="text" value={this.state.hypersomniaText} onChange={e => this.setState({ hypersomniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.abnChoice === "1"} onChange={e => this.setState({ abnChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> AbN Gait</span> <input type="text" value={this.state.abnText} onChange={e => this.setState({ abnText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.cnsNotes} onChange={e => this.setState({ cnsNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.constitutional ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.feverChoice === "1"} onChange={e => this.setState({ feverChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Fever</span><input type="text" value={this.state.feverText} onChange={e => this.setState({ feverText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sweatingChoice === "1"} onChange={e => this.setState({ sweatingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sweating</span><input type="text" value={this.state.sweatingText} onChange={e => this.setState({ sweatingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.chillsChoice === "1"} onChange={e => this.setState({ chillsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Chills</span><input type="text" value={this.state.chillsText} onChange={e => this.setState({ chillsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rigorsChoice === "1"} onChange={e => this.setState({ rigorsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Rigors </span> <input type="text" value={this.state.rigorsText} onChange={e => this.setState({ rigorsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fatigueChoice === "1"} onChange={e => this.setState({ fatigueChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Fatigue </span><input type="text" value={this.state.fatigueText} onChange={e => this.setState({ fatigueText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.myalgiaChoice === "1"} onChange={e => this.setState({ myalgiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Myalgia</span> <input type="text" value={this.state.myalgiaText} onChange={e => this.setState({ myalgiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.constitutionalNotes} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.mss ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bleedingChoice === "1"} onChange={e => this.setState({ bleedingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Bleeding</span> <input type="text" value={this.state.bleedingText} onChange={e => this.setState({ bleedingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bruisingChoice === "1"} onChange={e => this.setState({ bruisingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Bruising</span> <input type="text" value={this.state.bruisingText} onChange={e => this.setState({ bruisingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.swellingChoice === "1"} onChange={e => this.setState({ swellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Swelling</span> <input type="text" value={this.state.swellingText} onChange={e => this.setState({ swellingText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.deformityChoice === "1"} onChange={e => this.setState({ deformityChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Deformity</span><input type="text" value={this.state.deformityText} onChange={e => this.setState({ deformityText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span> <textarea value={this.state.mssNotes} onChange={e => this.setState({ mssNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.dermatology ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rashChoice === "1"} onChange={e => this.setState({ rashChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Rash </span><input type="text" value={this.state.rashText} onChange={e => this.setState({ rashText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.itchynessChoice === "1"} onChange={e => this.setState({ itchynessChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Itchyness</span> <input type="text" value={this.state.itchynessText} onChange={e => this.setState({ itchynessText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.blistersChoice === "1"} onChange={e => this.setState({ blistersChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Blisters</span> <input type="text" value={this.state.blistersText} onChange={e => this.setState({ blistersText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lumpsChoice === "1"} onChange={e => this.setState({ lumpsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Lumps</span>  <input type="text" value={this.state.lumpsText} onChange={e => this.setState({ lumpsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.ulcersChoice === "1"} onChange={e => this.setState({ ulcersChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Ulcers </span><input type="text" value={this.state.ulcersText} onChange={e => this.setState({ ulcersText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.swellingdemaChoice === "1"} onChange={e => this.setState({ swellingdemaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Swelling</span> <input type="text" value={this.state.swellingdemaText} onChange={e => this.setState({ swellingdemaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.dematologyNotes} onChange={e => this.setState({ dematologyNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div className={this.state.patientMedicalHistory ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Patient Medical History
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.tbChoice === "1"} onChange={e => this.setState({ tbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TB</span> <input type="text" value={this.state.tbText} onChange={e => this.setState({ tbText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.asthmaChoice === "1"} onChange={e => this.setState({ asthmaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Asthma </span> <input type="text" value={this.state.asthmaText} onChange={e => this.setState({ asthmaText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.diabetesChoice === "1"} onChange={e => this.setState({ diabetesChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Diebetes </span><input type="text" value={this.state.diabetesText} onChange={e => this.setState({ diabetesText: e.target.value })} className="form-control" /></div></div>

                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.hypertensionChoice === "1"} onChange={e => this.setState({ hypertensionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertension/CCF/CVA</span> <input type="text" value={this.state.hypertensionText} onChange={e => this.setState({ hypertensionText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.myocardialChoice === "1"} onChange={e => this.setState({ myocardialChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Myocardial Infarction </span><input type="text" value={this.state.myocardialText} onChange={e => this.setState({ myocardialText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.migraineChoice === "1"} onChange={e => this.setState({ migraineChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Migraine</span> <input type="text" value={this.state.migraineText} onChange={e => this.setState({ migraineText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.epilepsyChoice === "1"} onChange={e => this.setState({ epilepsyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Epilepsy</span>  <input type="text" value={this.state.epilepsyText} onChange={e => this.setState({ epilepsyText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.skinDiseaseChoice === "1"} onChange={e => this.setState({ skinDiseaseChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Skin Disease/Eczema </span><input type="text" value={this.state.skinDiseaseText} onChange={e => this.setState({ skinDiseaseText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.pregnantChoice === "1"} onChange={e => this.setState({ pregnantChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Pregnant</span> <input type="text" value={this.state.pregnantText} onChange={e => this.setState({ pregnantText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.retroviralChoice === "1"} onChange={e => this.setState({ retroviralChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Retroviral Illness</span> <input type="text" value={this.state.retroviralText} onChange={e => this.setState({ retroviralText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Information</span>  <textarea value={this.state.other} className="form-control" onChange={e => this.setState({ other: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.examination ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Examination
                                    </div>
                                    <div className="card-body">
                                    <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.appearanceChoice === "1"} onChange={e => this.setState({ appearanceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Appearance</span> <input type="text" value={this.state.appearanceText} onChange={e => this.setState({ appearanceText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.behaviourChoice === "1"} onChange={e => this.setState({ behaviourChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Behavior</span><input type="text" value={this.state.behaviourText} onChange={e => this.setState({ behaviourText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.dehydrationChoice === "1"} onChange={e => this.setState({ dehydrationChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Dehydration</span><input type="text" value={this.state.dehydrationText} onChange={e => this.setState({ dehydrationText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.wastingChoice === "1"} onChange={e => this.setState({ wastingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Wasting </span><input type="text" value={this.state.wastingText} onChange={e => this.setState({ wastingText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.hirsutismChoice === "1"} onChange={e => this.setState({ hirsutismChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hirsutism </span><input type="text" value={this.state.hirsutismText} onChange={e => this.setState({ hirsutismText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.thyroidChoice === "1"} onChange={e => this.setState({ thyroidChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thyroid Enlargement </span><input type="text" value={this.state.thyroidText} onChange={e => this.setState({ thyroidText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.palpableChoice === "1"} onChange={e => this.setState({ palpableChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Palpable Lymph Nodes</span><input type="text" value={this.state.palpableText} onChange={e => this.setState({ palpableText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.clubbingChoice === "1"} onChange={e => this.setState({ clubbingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Clubbing</span> <input type="text" value={this.state.clubbingText} onChange={e => this.setState({ clubbingText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.pallorChoice === "1"} onChange={e => this.setState({ pallorChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pallor</span><input type="text" value={this.state.pallorText} onChange={e => this.setState({ pallorText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.icterusChoice === "1"} onChange={e => this.setState({ icterusChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Icterus</span><input type="text" value={this.state.icterusText} onChange={e => this.setState({ icterusText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.cyanosisChoice === "1"} onChange={e => this.setState({ cyanosisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Cyanosis</span> <input type="text" value={this.state.cyanosisText} onChange={e => this.setState({ cyanosisText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.edemaChoice === "1"} onChange={e => this.setState({ edemaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Edema</span><input type="text" value={this.state.edemaText} onChange={e => this.setState({ edemaText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lossphyChoice === "1"} onChange={e => this.setState({ lossphyChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Loss  of Weight </span><input type="text" value={this.state.lossphyText} onChange={e => this.setState({ lossphyText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.herniaChoice === "1"} onChange={e => this.setState({ herniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hernia</span><input type="text" value={this.state.herniaText} onChange={e => this.setState({ herniaText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.examinationNotes} onChange={e => this.setState({ examinationNotes: e.target.value })} className="form-control" rows="5" ></textarea></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={this.state.emergency ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Emergency</div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Airway and C spine</span>
                                                <textarea className="form-control" value={this.state.airway} onChange={e => this.setState({ airway: e.target.value })} ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Breathing</span>
                                                <textarea className="form-control" value={this.state.breathing} onChange={e => this.setState({ breathing: e.target.value })} ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Circulation</span>
                                                <textarea className="form-control" value={this.state.circulation} onChange={e => this.setState({ circulation: e.target.value })} ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Disability</span>
                                                <textarea className="form-control" value={this.state.disability} onChange={e => this.setState({ disability: e.target.value })} ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Exposure</span>
                                                <textarea className="form-control" value={this.state.exposure} onChange={e => this.setState({ exposure: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div>


                            <div className={this.state.management ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Management</div>
                                    <div className="card-body">
                                    <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Patient Status</span>
                                                <select className="form-control" value={this.state.visitStatus} onChange={e => this.setState({ visitStatus: e.target.value })} >
                                                    <option value="">Choose Status</option>
                                                    <option value="ADMISSION">Admission</option>
                                                    <option value="OBSERVATION">Observation</option>
                                                    <option value="NURSE_ORDERS">Nurse Orders</option>
                                                    <option value="COMPLETED">Completed</option>
                                                    </select></div></div>
                                        </div> 
                                        <div className="table-responsive-lg">   
                                    <table className="table" id="table">
                                            <tr>
                                                <th>Time</th>
                                                <th>Procedure</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.managementLists.map((item, index) =>  (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.time} onChange={e => { this.setManagement(index, 'time', e.target.value); }} /></td>
                                                        <td><input type="text" list={'procedureList' + index} className="form-control" value={item.procedure} onChange={e => { this.setManagement(index, 'procedure', e.target.value); }} />
                                                            <datalist id={'procedureList' + index}>
                                                                {this.state.procedures.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist></td>
                                                        <td><span className="link-red" onClick={() => this.deleteManagement(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addManagement()} className="btn btn-primary" >Insert New Procedure Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                    <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                          <td>{item.reviewDate}</td>
                                                    <td> {this.getReviewTime(item.reviewTime)} </td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.props.showCalenderReview()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                                                                                                                                                                                                                                                        <td><input type="text" className="form-control" value={item.startDate} onChange={e => { this.setMedicalCertificate(index, "startDate", e.target.value ) }} /> 
                                                                                                                                                                                                                                                                                              <MyDatePicker  value={item.startDate} maxDate={item.endDate} onChange={(d)=> {  this.setMedicalCertificate(index, "startDate", d);  }} />
                                                                                                                                                                                                                                                                                        </td>
                                                                                                                                                                                                                                                                                        <td><input type="text" className="form-control" value={item.endDate} onChange={e => { this.setMedicalCertificate(index, "endDate", e.target.value ) }} /> 
                                                                                                                                                                                                                                                                                             <MyDatePicker  value={item.endDate} minDate={item.startDate} onChange={(d)=> {  this.setMedicalCertificate(index, "endDate",  d);  }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                      </div>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Route</th>
                                                <th>Frequency</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'drugList' + index} className="form-control" value={item.drugName} onChange={e => { this.setTreatment(index, 'drugName', e.target.value); }} />
                                                            <datalist id={'drugList' + index}>
                                                                {this.state.drugs.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.dosage} onChange={e => { this.setTreatment(index, 'dosage', e.target.value); }} /></td>
                                                        <td><input type="text" list={'routeList' + index} className="form-control" value={item.routeName} onChange={e => { this.setTreatment(index, 'routeName', e.target.value); }} />
                                                            <datalist id={'routeList' + index}>
                                                                {this.state.routes.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'frequencyList' + index} className="form-control" value={item.frequencyName} onChange={e => { this.setTreatment(index, 'frequencyName', e.target.value); }} />
                                                            <datalist id={'frequencyList' + index}>
                                                                {this.state.frequencys.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'durationList' + index} className="form-control" value={item.durationName} onChange={e => { this.setTreatment(index, 'durationName', e.target.value); }} />
                                                            <datalist id={'durationList' + index}>
                                                                {this.state.durations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'formulationList' + index} className="form-control" value={item.formulationName} onChange={e => { this.setTreatment(index, 'formulationName', e.target.value); }} />
                                                            <datalist id={'formulationList' + index}>
                                                                {this.state.formulations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.repeats} onChange={e => { this.setTreatment(index, 'repeats', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteTreatment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addTreatmentTable()} className="btn btn-primary" >Insert New Treatment Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.investigation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Investigation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.investigations.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><Select className="form-control" value={this.state.services.filter(({ value }) => value === item.name)} onChange={e => { this.setInvestigation(index, e.value); }} options={this.state.services} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteInvestigation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addInvestigationTable()} className="btn btn-primary" >Insert New Investigation Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.drugHistory ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Drug History</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.drugHistoryList.map((item, index) =>  (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'drugList' + index} className="form-control" value={item} onChange={e => { this.setDrugHistory(index, e.target.value); }} />
                                                            <datalist id={'drugList' + index}>
                                                                {this.state.drugs.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist></td>
                                                        <td><span className="link-red" onClick={() => this.deleteDrugHistory(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addDrugHistory()} className="btn btn-primary" >Insert New Drug Line</button>
                                    </div></div>
                            </div>
                        </div>

                        <div >
                            <div className="card card-blue">
                                <div className="card-header  text-dark">
                                    Diagnosis
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12 col-lg-12">
                                            <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                            
                                            <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                        </div>
                                        </div>
                                        </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                        <option value="">Choose Diagnosis</option>
                                       {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                    <table className="table">
                                        <tr>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                        {this.state.patientDiagnosis.map((item, index) =>  (item.activeStatus === 1 &&(
                                            <tr key={index}>
                                                <td>{item.description}</td>
                                                <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            </tr>)))}
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
             
               
            </div >
        </div >
        </div >)

    }
}      