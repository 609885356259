import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import AllocateAdvancedReceipt from './allocationadvancedreceipt'
import CancelAdvancedRceipt from './canceladvancedreceipt'
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class AdvancedReceiptList extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.setMsg = this.setMsg.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.allocateAdvancedRceiptRef = React.createRef()
    this.cancelAdvancedRceiptRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '',  startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
    getAllocateAdvancedRceipt(advancedReceipt) {
        this.setState({loader: true})
        this.allocateAdvancedRceiptRef.current.getAllocateAdvancedRceipt(advancedReceipt)
        document.getElementById('allocationAdvancedReceiptAction').style = 'display:block' 
    }
    openCancelReceipt(advancedReceipt)
    {
        this.setState({loader: true})
        this.cancelAdvancedRceiptRef.current.getCancelAdvancedRceipt(advancedReceipt)
        document.getElementById('advancedReceiptCancelAction').style = 'display:block' 
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/bill/advancedreceiptlist/'+localStorage.getItem('roleId'), data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    setMsg(msg)
    {
        this.setState({msg: msg})  
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                     
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                         <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                             <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                             <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                                 <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <button className="btn btn-success" onClick={() => this.getList()} ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Done By</th>
                                    <th>Date</th>
                                    <th>Payment Type</th>
                                    <th>Paid Amount</th>
                                    <th>Available Amount</th>
                                    <th>Used Amount</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} >
                                        <td >
                                        <span className="link" onClick={() => this.getPrintOut(item.id, 'ADVANCED_RECEIPT', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                          { item.activeStatus === 1 && item.showCancel  === true?(<span className="link-red" onClick={() => this.openCancelReceipt(item)} title="Cancel Receipt" ><i class="bi bi-x-square-fill"></i></span>):(<span></span>)}
                                          { item.activeStatus === 1 && item.availableAmount > 0?(<span className="link" onClick={() => this.getAllocateAdvancedRceipt(item)} title="Allocate Receipt" ><i class="bi bi-front"></i></span>):(<span></span>)}
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.createdByUser}</td>
                                        <td>{item.creationDateTime}</td>
                                        <td>{item.paymentType}</td>
                                        <td align="right">{this.currencyFormat(item.paidAmount)}</td>
                                        <td align="right">{this.currencyFormat(item.availableAmount)}</td>
                                        <td align="right">{this.currencyFormat(item.usedAmount)}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                        <AllocateAdvancedReceipt ref={this.allocateAdvancedRceiptRef} setLoader={this.setLoader} setMsg={this.setMsg} getList={this.getList} />
                        <CancelAdvancedRceipt ref={this.cancelAdvancedRceiptRef} setLoader={this.setLoader} setMsg={this.setMsg} getList={this.getList} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    