import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class GynaeAction extends Component {
      state = { fileData: '', results: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', waistHeightRatio: '', nurseNotes: '', patientDiagnosis: [], diagnosisText: [], vitals: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: 0, msg: '', error: '',
        presentingComplaintsT: false, currentMedicationT: false, allergyHistoryT: false,  obstetricHistoryT: true, gynaecologicalHistoryT: false, gus: false, mentalHealthT: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false,
  /*History  */ frequencyExact: '', volumeExact: '', twins: '', diabetes: '', hypertension: '', colorectalCancerChoice: '',colorectalCancerText: '', ovarianCancerChoice: '',ovarianCancerText: '', uterineCancerChoice: '',uterineCancerText: '',breastCancerChoice: '',breastCancerText: '', otherCancer: '',mentalHealth: '', presentingComplaints:  '', currentMedication:  '',  allergyHistory:  '',  pregnancyHistorys:  [],  miscarriageEctopics:  [],  lnmp:  '',  menarche:  '', durationOfFlow:  '', regularity:  '', irregularBleeding:  '', cycleLength:  '',  frequency:  '', volume:  '', dysmemorrhoea:  '', dysmemorrhoeaNotes: '', pms:  '', pmsNotes: '', contraceptives:  [], dyspareunia:  '', dyspareuniaNotes: '', dyspareuniaYes:  '', dyspareuniaYesNotes: '', cervicalCancerScreenings:  [], breastCancerScreenings: [], menopauses: [], violence:  '', otherViolence:  ''
  , alcoholChoice:  '', smokesChoice:  '', drugsChoice:  '', churchChoice:  '', bloodTransfusionChoice:  '', alcoholText:  '', smokesText:  '', drugsText:  '', churchText:  '', bloodTransfusionText:  '', partnerAlcoholChoice:  '', partnerSmokesChoice:  '', partnerDrugsChoice:  '', partnerChurchChoice:  '', partnerAlcoholText:  '', partnerSmokesText:  '', partnerDrugsText:  '', partnerChurchText:  '', diabetesChoice:  '', epilepsyChoice:  '', asthmaChoice:  '', rheumaticFeverChoice:  '', thyroidDisorderChoice:  '', hypertensionChoice:  '', diabetesText:  '', epilepsyText:  '', asthmaText:  '', rheumaticFeverText:  '', thyroidDisorderText:  '', hypertensionText:  '', pastMedicalNotes:  '', surgeries:  [], previousHospitalAdmissions:  [], para: '', gravida: '', edd: '', ega: '', v: ''}
    openNurseAction(visitId) {
        this.setState({ fileData: '', results: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', waistHeightRatio: '', nurseNotes: '', patientDiagnosis: [], diagnosisText: [], vitals: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: visitId, msg: '', error: '' ,
            presentingComplaintsT: false, currentMedicationT: false, allergyHistoryT: false,  obstetricHistoryT: true, gynaecologicalHistoryT: false, gus: false, mentalHealthT: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false,
              /*History  */ frequencyExact: '', volumeExact: '', twins: '', diabetes: '', hypertension: '', colorectalCancerChoice: '',colorectalCancerText: '', ovarianCancerChoice: '',ovarianCancerText: '', uterineCancerChoice: '',uterineCancerText: '',breastCancerChoice: '',breastCancerText: '', otherCancer: '' ,mentalHealth: '', presentingComplaints:  '', currentMedication:  '',  allergyHistory:  '',  pregnancyHistorys:  [],  miscarriageEctopics:  [],  lnmp:  '',  menarche:  '', durationOfFlow:  '', regularity:  '', irregularBleeding:  '', cycleLength:  '',  frequency:  '', volume:  '', dysmemorrhoea:  '', dysmemorrhoeaNotes: '', pms:  '', pmsNotes: '', contraceptives:  [], dyspareunia:  '', dyspareuniaNotes: '', dyspareuniaYes:  '', dyspareuniaYesNotes: '', cervicalCancerScreenings:  [], breastCancerScreenings: [], menopauses: [], violence:  ''
              , otherViolence:  '', alcoholChoice:  '', smokesChoice:  '', drugsChoice:  '', churchChoice:  '', bloodTransfusionChoice:  '', alcoholText:  '', smokesText:  '', drugsText:  '', churchText:  '', bloodTransfusionText:  '', partnerAlcoholChoice:  '', partnerSmokesChoice:  '', partnerDrugsChoice:  '', partnerChurchChoice:  '', partnerAlcoholText:  '', partnerSmokesText:  '', partnerDrugsText:  '', partnerChurchText:  '', diabetesChoice:  '', epilepsyChoice:  '', asthmaChoice:  '', rheumaticFeverChoice:  '', thyroidDisorderChoice:  '', hypertensionChoice:  '', diabetesText:  '', epilepsyText:  '', asthmaText:  '', rheumaticFeverText:  '', thyroidDisorderText:  '', hypertensionText:  '', pastMedicalNotes:  '', surgeries:  [], previousHospitalAdmissions:  [], para: '', gravida: '', edd: '', ega: '', pregTest: ''})
              axios.get(localStorage.getItem('host') + '/patientmanagement/gynae/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                res.data.height = res.data.height === 0?"":res.data.height
                let hist = res.data.history
                this.setState({ results: res.data.results, fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, patientDiagnosis: res.data.patientDiagnosis, diagnosisText: res.data.diagnosisText, vitals: res.data.vitals, notes: res.data.notes, referralLetters: res.data.referralLetters, oldPatient: res.data.oldPatient, managementData: res.data.managementData, historyVisits: res.data.historyVisits,
                 frequencyExact: hist.frequencyExact, volumeExact: hist.volumeExact,twins: hist.twins, diabetes: hist.diabetes, hypertension: hist.hypertension, colorectalCancerChoice: hist.colorectalCancerChoice,colorectalCancerText: hist.colorectalCancerText, ovarianCancerChoice: hist.ovarianCancerChoice,ovarianCancerText: hist.ovarianCancerText, uterineCancerChoice: hist.uterineCancerChoice,uterineCancerText: hist.uterineCancerText,breastCancerChoice: hist.breastCancerChoice,breastCancerText: hist.breastCancerText , otherCancer: hist.otherCancer,mentalHealth: hist.mentalHealth, para: hist.para, gravida: hist.gravida, edd: hist.edd, ega: hist.ega, pregTest: hist.pregTest, presentingComplaints: hist.presentingComplaints, currentMedication:  hist.currentMedication,  allergyHistory:  hist.allergyHistory,  pregnancyHistorys:  hist.pregnancyHistorys
                 ,  miscarriageEctopics:  hist.miscarriageEctopics,  lnmp:  hist.lnmp,  menarche:  hist.menarche, durationOfFlow:  hist.durationOfFlow, regularity:  hist.regularity, irregularBleeding:  hist.irregularBleeding, cycleLength:  hist.cycleLength,  frequency:  hist.frequency, volume:  hist.volume, dysmemorrhoea:  hist.dysmemorrhoea, dysmemorrhoeaNotes: hist.dysmemorrhoeaNotes, pms:  hist.pms, pmsNotes: hist.pmsNotes, contraceptives:  hist.contraceptives, dyspareunia:  hist.dyspareunia, dyspareuniaNotes: hist.dyspareuniaNotes, dyspareuniaYes:  hist.dyspareuniaYes, dyspareuniaYesNotes: hist.dyspareuniaYesNotes, cervicalCancerScreenings:  hist.cervicalCancerScreenings, breastCancerScreenings: hist.breastCancerScreenings, menopauses: hist.menopauses, violence:  hist.violence, otherViolence:  hist.otherViolence, alcoholChoice:  hist.alcoholChoice
                 , smokesChoice:  hist.smokesChoice, drugsChoice:  hist.drugsChoice, churchChoice:  hist.churchChoice, bloodTransfusionChoice:  hist.bloodTransfusionChoice, alcoholText:  hist.alcoholText, smokesText:  hist.smokesText, drugsText:  hist.drugsText, churchText:  hist.churchText, bloodTransfusionText:  hist.bloodTransfusionText, partnerAlcoholChoice:  hist.partnerAlcoholChoice, partnerSmokesChoice:  hist.partnerSmokesChoice, partnerDrugsChoice:  hist.partnerDrugsChoice, partnerChurchChoice:  hist.partnerChurchChoice, partnerAlcoholText:  hist.partnerAlcoholText, partnerSmokesText:  hist.partnerSmokesText, partnerDrugsText:  hist.partnerDrugsText, partnerChurchText:  hist.partnerChurchText, diabetesChoice:  hist.diabetesChoice, epilepsyChoice:  hist.epilepsyChoice, asthmaChoice:  hist.asthmaChoice, rheumaticFeverChoice:  hist.rheumaticFeverChoice,
                  thyroidDisorderChoice:  hist.thyroidDisorderChoice, hypertensionChoice:  hist.hypertensionChoice, diabetesText:  hist.diabetesText, epilepsyText:  hist.epilepsyText, asthmaText:  hist.asthmaText, rheumaticFeverText:  hist.rheumaticFeverText, thyroidDisorderText:  hist.thyroidDisorderText, hypertensionText:  hist.hypertensionText, pastMedicalNotes:  hist.pastMedicalNotes, surgeries:  hist.surgeries, previousHospitalAdmissions:  hist.previousHospitalAdmissions})
                     this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        var data = { visitId: this.state.visitId, weight: this.state.weight, height: this.state.height, waist: this.state.waist, headCircumference: this.state.headCircumference, muac: this.state.muac, bmi: this.state.bmi, waistHeightRatio: this.state.waistHeightRatio, nurseNotes: this.state.nurseNotes, vitals: this.state.vitals
            , history: { frequencyExact: this.state.frequencyExact, volumeExact: this.state.volumeExact,twins: this.state.twins, diabetes: this.state.diabetes, hypertension: this.state.hypertension, colorectalCancerChoice: this.state.colorectalCancerChoice,colorectalCancerText: this.state.colorectalCancerText, ovarianCancerChoice: this.state.ovarianCancerChoice,
                ovarianCancerText: this.state.ovarianCancerText, uterineCancerChoice: this.state.uterineCancerChoice,uterineCancerText: this.state.uterineCancerText,breastCancerChoice: this.state.breastCancerChoice,breastCancerText: this.state.breastCancerText, otherCancer: this.state.otherCancer , mentalHealth: this.state.mentalHealth, para: this.state.para, gravida: this.state.gravida,
             edd: this.state.edd, ega: this.state.ega, pregTest: this.state.pregTest, presentingComplaints: this.state.presentingComplaints, currentMedication:  this.state.currentMedication,  allergyHistory:  this.state.allergyHistory,  pregnancyHistorys:  this.state.pregnancyHistorys,  miscarriageEctopics:  this.state.miscarriageEctopics,  lnmp:  this.state.lnmp,  menarche:  this.state.menarche, 
             durationOfFlow:  this.state.durationOfFlow, regularity:  this.state.regularity, irregularBleeding:  this.state.irregularBleeding, cycleLength:  this.state.cycleLength,  frequency:  this.state.frequency, volume:  this.state.volume, dysmemorrhoea:  this.state.dysmemorrhoea, dysmemorrhoeaNotes: this.state.dysmemorrhoeaNotes, pms:  this.state.pms, pmsNotes: this.state.pmsNotes, contraceptives:  this.state.contraceptives, 
             dyspareunia:  this.state.dyspareunia, dyspareuniaNotes: this.state.dyspareuniaNotes, dyspareuniaYes:  this.state.dyspareuniaYes, dyspareuniaYesNotes: this.state.dyspareuniaYesNotes, cervicalCancerScreenings:  this.state.cervicalCancerScreenings, breastCancerScreenings: this.state.breastCancerScreenings, menopauses: this.state.menopauses, violence:  this.state.violence, otherViolence:  this.state.otherViolence, 
             alcoholChoice:  this.state.alcoholChoice, smokesChoice:  this.state.smokesChoice, drugsChoice:  this.state.drugsChoice, churchChoice:  this.state.churchChoice, bloodTransfusionChoice:  this.state.bloodTransfusionChoice, alcoholText:  this.state.alcoholText, smokesText:  this.state.smokesText, drugsText:  this.state.drugsText, churchText:  this.state.churchText, bloodTransfusionText:  this.state.bloodTransfusionText, partnerAlcoholChoice:  this.state.partnerAlcoholChoice,
              partnerSmokesChoice:  this.state.partnerSmokesChoice, partnerDrugsChoice:  this.state.partnerDrugsChoice, partnerChurchChoice:  this.state.partnerChurchChoice, partnerAlcoholText:  this.state.partnerAlcoholText, partnerSmokesText:  this.state.partnerSmokesText, partnerDrugsText:  this.state.partnerDrugsText, partnerChurchText:  this.state.partnerChurchText, diabetesChoice:  this.state.diabetesChoice, epilepsyChoice:  this.state.epilepsyChoice, asthmaChoice:  this.state.asthmaChoice, 
              rheumaticFeverChoice:  this.state.rheumaticFeverChoice, thyroidDisorderChoice:  this.state.thyroidDisorderChoice, hypertensionChoice:  this.state.hypertensionChoice, diabetesText:  this.state.diabetesText, epilepsyText:  this.state.epilepsyText, asthmaText:  this.state.asthmaText, rheumaticFeverText:  this.state.rheumaticFeverText, thyroidDisorderText:  this.state.thyroidDisorderText, hypertensionText:  this.state.hypertensionText, pastMedicalNotes:  this.state.pastMedicalNotes, surgeries:  this.state.surgeries, previousHospitalAdmissions:  this.state.previousHospitalAdmissions}
            }
        axios.post(localStorage.getItem('host') + '/patientmanagement/gynaesave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                window.scrollTo(0, 0);
                this.props.updateAfterSave(this.state.visitId, [])
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('nurseAction').style = 'display:none'
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 250 ? value : 0
                if (value > 0 && this.state.height > 40) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                var waistHeightRatio = 0;
                value = value > 0 && value < 250 ? value : 0
                if (value > 40 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                if (value > 0 && this.state.waist > 0) {
                    waistHeightRatio = Number((this.state.waist / value).toFixed("2"))
                }
                
                this.setState({ height: value, bmi: bmi, waistHeightRatio: waistHeightRatio })
                break;
            default:
                waistHeightRatio = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    waistHeightRatio = Number((value / this.state.height).toFixed("2"))
                }
                this.setState({ waist: value, waistHeightRatio: waistHeightRatio })
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : '';
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 180 ? value : '';
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 50 ? value : '';
                break;
            case "respRate":
                list[index].respRate = value > 0 && value < 100 ? value : '';
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 300 ? value : '';
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : '';
                break;
            default:
                list[index].spO2 = value > 0 && value < 101 ? value : '';
                break;

        }
        this.setState({ vitals: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature:'', pulse: '', glucometer:'', systolic: '', diastolic:'', spO2: '' });
        this.setState({ vitals: list })
    }
    showNurseHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/nursehistory/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension});
         document.getElementById('showFileNurseAction1').style = 'display:block'

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction1').style = 'display:none'
    }
    showOldNurse(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                //   this.setState({ htmlText: res.data, popupName: 'Nurse History'});
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    viewResults() {
        document.getElementById('resultPopup1').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup1').style = 'display:none'
    }
    deleteRow(index)
    {
        let list = this.state.vitals.slice();
        list.splice(index, 1); 
        this.setState({ vitals: list });
    }
    toggleHistoryTabs(name) {
        switch (name) {
            case "mentalHealthT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: true })
                break
            case "gus":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: true, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "gynae":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: true, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "cvs":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: true, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "socialHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: true, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "familyHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: true, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "surgicalHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: true, medicalHistoryT: false, mentalHealthT: false })
                break
            case "medicalHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: true, mentalHealthT: false })
                break
            case "gynaecologicalHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: true, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "obstetricHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: true, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "pastMedicalHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:true ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "currentMedicationT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:true ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            case "allergyHistoryT":
                this.setState({ presentingComplaintsT:false ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:true ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break
            default:
                this.setState({ presentingComplaintsT:true ,pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, gus: false, gynae: false, cvs: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break

        }
    }

    addPregnancyHistoryTable()
    {
       let list = this.state.pregnancyHistorys.slice();
       list.push({ id: 0, dob: '', gender: '', modeOfDelivery: '', modNotes: '', antenatalComplications: '', deliveryComplications: '', postpartumComplications: '', outcome: '', deliveryAge: '' });
       this.setState({ pregnancyHistorys: list });
    }
    deletePregnancyHistoryRow(index)
     {
        let list = this.state.pregnancyHistorys.slice();
        list.splice(index, 1); 
        this.setState({ pregnancyHistorys: list }) 
     }
    setPregnancyHistory(index, name, value) {
       let list = this.state.pregnancyHistorys.slice();
       switch (name) {
           case "dob":
               list[index].dob = value
               break;
           case "gender":
               list[index].gender = value
               break;
           case "modeOfDelivery":
               list[index].modeOfDelivery = value
               break;
           case "modNotes":
               list[index].modNotes = value
               break;
           case "antenatalComplications":
               list[index].antenatalComplications = value
               break;
           case "deliveryComplications":
               list[index].deliveryComplications = value
               break;
           case "postpartumComplications":
               list[index].postpartumComplications = value
               break;
           case "outcome":
               list[index].outcome = value
               break;
           case "deliveryAge":
               list[index].deliveryAge = value
               break;
           case "antenatalComplicationsRemarks":
               list[index].antenatalComplicationsRemarks = value
               break;
           case "outcomeRemarks":
               list[index].outcomeRemarks = value
               break;
           case "deliveryAgeRemarks":
               list[index].deliveryAgeRemarks = value
               break;
           default:
               list[index].repeats = value
               break;
       }
       this.setState({ pregnancyHistorys: list });
   }
    addContraceptivesTable()
    {
       let list = this.state.contraceptives.slice();
       list.push({ id: 0, name: '', startDate: '', endDate: '', notes: '' });
       this.setState({ contraceptives: list });
    }
    deleteContraceptivesRow(index)
     {
        let list = this.state.contraceptives.slice();
        list.splice(index, 1); 
        this.setState({ contraceptives: list }) 
     }
    setContraceptive(index, name, value) {
       let list = this.state.contraceptives.slice();
       switch (name) {
           case "name":
               list[index].name = value
               break;
           case "startDate":
               list[index].startDate = value
               break;
           case "endDate":
               list[index].endDate = value
               break;
           default:
               list[index].notes = value
               break;
       }
       this.setState({ contraceptives: list });
   }
    addCervicalCancerScreeningTable()
    {
       let list = this.state.cervicalCancerScreenings.slice();
       list.push({ id: 0, year: '', type: '', result: '' });
       this.setState({ cervicalCancerScreenings: list });
    }
    deleteCervicalCancerScreeningRow(index)
     {
        let list = this.state.cervicalCancerScreenings.slice();
        list.splice(index, 1); 
        this.setState({ cervicalCancerScreenings: list }) 
     }
    setCervicalCancerScreening(index, name, value) {
       let list = this.state.cervicalCancerScreenings.slice();
       switch (name) {
           case "year":
               list[index].year = value
               break;
           case "month":
               list[index].month = value
               break;
           case "type":
               list[index].type = value
               break;
           case "result":
               list[index].result = value
               break;
           default:
               list[index].repeats = value
               break;
       }
       this.setState({ cervicalCancerScreenings: list });
   }
    addBreastCancerScreeningTable()
    {
       let list = this.state.breastCancerScreenings.slice();
       list.push({ id: 0, year: '', month: '', selfExam: '', clinicalExam: '', mammogram: '', breastUltrasoundScan: '' });
       this.setState({ breastCancerScreenings: list });
    }
    deleteBreastCancerScreeningRow(index)
     {
        let list = this.state.breastCancerScreenings.slice();
        list.splice(index, 1); 
        this.setState({ breastCancerScreenings: list }) 
     }
    setBreastCancerScreening(index, name, value) {
       let list = this.state.breastCancerScreenings.slice();
       switch (name) {
           case "year":
               list[index].year = value
               break;
           case "month":
               list[index].month = value
               break;
           case "selfExam":
               list[index].selfExam = value
               break;
           case "clinicalExam":
               list[index].clinicalExam = value
               break;
           case "mammogram":
               list[index].mammogram = value
               break;
           case "breastUltrasoundScan":
               list[index].breastUltrasoundScan = value
               break;
           case "selfExamRemarks":
               list[index].selfExamRemarks = value
               break;
           case "clinicalExamRemarks":
               list[index].clinicalExamRemarks = value
               break;
           case "breastUltrasoundScanRemarks":
               list[index].breastUltrasoundScanRemarks = value
               break;
           case "mammogramRemarks":
               list[index].mammogramRemarks = value
               break;
           default:
               list[index].repeats = value
               break;
       }
       this.setState({ breastCancerScreenings: list });
   }
    addMenopauseTable()
    {
       let list = this.state.menopauses.slice();
       list.push({ id: 0, symptoms: '', other: '', activeStatus: 1 });
       this.setState({ menopauses: list });
    }
    deleteMenopauseRow(index)
     {
        let list = this.state.menopauses.slice();
        list.splice(index, 1); 
        this.setState({ menopauses: list }) 
     }
    setMenopause(index, name, value) {
       let list = this.state.menopauses.slice();
       switch (name) {
           case "symptoms":
               list[index].symptoms = value
               break;
           case "other":
               list[index].other = value
               break;
           default:
               list[index].repeats = value
               break;
       }
       this.setState({ menopauses: list });
   }
    addSurgeriesTable()
    {
       let list = this.state.surgeries.slice();
       list.push({ id: 0, year: '', surgery: '', type: '', hospital: '', surgeon: '', complications:'' });
       this.setState({ surgeries: list });
    }
    deleteSurgeriesRow(index)
     {
        let list = this.state.surgeries.slice();
        list.splice(index, 1); 
        this.setState({ surgeries: list }) 
     }
    setSurgery(index, name, value) {
       let list = this.state.surgeries.slice();
       switch (name) {
           case "year":
               list[index].year = value
               break;
           case "surgery":
               list[index].surgery = value
               break;
           case "type":
               list[index].type = value
               break;
           case "hospital":
               list[index].hospital = value
               break;
           case "surgeon":
               list[index].surgeon = value
               break;
           case "complications":
               list[index].complications = value
               break;
           default:
               list[index].repeats = value
               break;
       }
       this.setState({ surgeries: list });
   }
    addMiscarriageEctopicTable()
    {
       let list = this.state.miscarriageEctopics.slice();
       list.push({ id: 0, year: '', type: '', ectopicManagement: '', medicalManagementDrugUsed: '', evacuation: '', molarPregnancyType: '', molarManagement: '', notes: '' });
       this.setState({ miscarriageEctopics: list });
    }
    deleteMiscarriageEctopicRow(index)
     {
        let list = this.state.miscarriageEctopics.slice();
        list.splice(index, 1); 
        this.setState({ miscarriageEctopics: list }) 
     }
    setMiscarriageEctopic(index, name, value) {
       let list = this.state.miscarriageEctopics.slice();
       switch (name) {
           case "year":
               list[index].year = value
               break;
           case "type":
               list[index].type = value
               break;
           case "ectopicManagement":
               list[index].ectopicManagement = value
               break;
           case "ectopicManagementRemarks":
               list[index].ectopicManagementRemarks = value
               break;
           case "medicalManagementDrugUsed":
               list[index].medicalManagementDrugUsed = value
               break;
           case "evacuation":
               list[index].evacuation = value
               break;
           case "molarPregnancyType":
               list[index].molarPregnancyType = value
               break;
           case "molarManagement":
               list[index].molarManagement = value
               break;
           case "notes":
               list[index].notes = value
               break;
           default:
               list[index].repeats = value
               break;
       }
       this.setState({ miscarriageEctopics: list });
   }
   addPreviousHospitalAdmission()
   {
      let list = this.state.previousHospitalAdmissions.slice();
      list.push({ id: 0, year: '', reason: '', activeStatus: 1 });
      this.setState({ previousHospitalAdmissions: list });
   }
   deletePreviousHospitalAdmissionRow(index)
    {
       let list = this.state.previousHospitalAdmissions.slice();
       list.splice(index, 1); 
       this.setState({ previousHospitalAdmissions: list }) 
    }
   setPreviousHospitalAdmission(index, name, value) {
      let list = this.state.previousHospitalAdmissions.slice();
      switch (name) {
          case "year":
              list[index].year = value
              break;
          case "reason":
              list[index].reason = value
              break;
          default:
              list[index].repeats = value
              break;
      }
      this.setState({ previousHospitalAdmissions: list });
  }
  calculateEDDEGA(stringDate, pregTest)  
    {
     var edd = new Date(stringDate);
     edd.setMonth(edd.getMonth() + 9);
     edd.setDate(edd.getDate() + 7);
     console.log(edd)
     var diff = (this.startOfWeek(new Date()) - this.startOfWeek(new Date(stringDate)));
     var divisor = (24 * 60 * 60 * 1000);
     var val = diff / divisor
    // var decimal = val - Math.floor(val)
     var intVal = Math.trunc(val);
     console.log(val+' ' +intVal)
     var day =  intVal % 7
     console.log((intVal % 7)+' ' +intVal)
     var weeks = Math.trunc(val / 7);  //Math.ceil()
     if(pregTest === "Indeterminate" || pregTest === "Positive")
     {
     this.setState({edd: edd.getFullYear()+'-'+ this.checkZero(edd.getMonth() + 1)+'-'+ this.checkZero(edd.getDay()), ega: 'Weeks '+weeks+' Days '+day})
     }
    else
      {
         this.setState({edd: '', ega: ''}) 
      } 
    }   
    checkZero(num) {
     return (num + "").length === 1 ? "0" + num : num;
 }     
 startOfWeek(dt) {
     const weekday = dt.getDay();
     return new Date(dt.getTime() - Math.abs(0 - weekday) * (24 * 60 * 60 * 1000));
 }    
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="nurseAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info">Nurse Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="resultPopup1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="PDF File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.showNurseHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}

                        {this.state.oldPatient && (<div className="card  card-green">
                            <div className="card-header  text-dark">Old Records</div>
                            <div className="card-body">
                                <ul>
                                    <li><span className="link" onClick={() => this.showOldNurse(this.state.oldPatient)}>View Old Emr</span></li>
                                    <li><span className="link" onClick={() => this.showOldResults(this.state.oldPatient)}>View Old Results</span></li>
                                </ul>
                            </div>
                        </div>)}
                        {this.state.results.length > 0 && (<div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    <li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes [15MB]</div>
                            <div className="card-body">
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Referral Letter [15MB]</div>
                            <div className="card-body">
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('REFERRAL_LETTERS', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.referralLetters.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Referral Letters</div>
                            <div className="card-body">
                                <table >
                                    {this.state.referralLetters.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'REFERRAL_LETTERS')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                            </div>
                        </div>
                        <div id="110"></div>
                        <br />
                        <div className="card  card-blue">
                            <div className="card-header">
                                Nurse Details
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"> <div className="input-group"><span className="input-group-text">Weight (Kg)</span>
                                        <div><input type="text" className="form-control" value={this.state.weight} onChange={e => this.setNurseDetails("weight", e.target.value)} /></div>
                                    </div></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Notes</span><textarea className="form-control" value={this.state.nurseNotes} onChange={e => this.setState({ nurseNotes: e.target.value })}></textarea></div></div>
                                </div>
                            </div>
                        </div>
                        <div className="card  card-blue">
                            <div className="card-header">
                                Vitals
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col" align="right"><input type="button" onClick={() => this.addVital()} value="Insert Line" className="btn btn-success" /></div>

                                </div>
                                <br />
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>Systolic</th>
                                        <th>Diastolic</th>
                                        <th>SpO2 (%)</th>
                                        <th>Resp Rate</th>
                                        <th>Action</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitals.map((item, index) => (
                                            <tr key={index}>
                                                <td><input type="text" className="form-control" value={item.vitalTime} onChange={e => { this.setVitalValue(index, 'vitalTime', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.temperature} onChange={e => { this.setVitalValue(index, 'temperature', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.pulse} onChange={e => { this.setVitalValue(index, 'pulse', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.glucometer} onChange={e => { this.setVitalValue(index, 'glucometer', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.systolic} onChange={e => { this.setVitalValue(index, 'systolic', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.diastolic} onChange={e => { this.setVitalValue(index, 'diastolic', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.spO2} onChange={e => { this.setVitalValue(index, 'spO2', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.respRate} onChange={e => { this.setVitalValue(index, 'respRate', e.target.value); }} /></td>
                                                <td ><span className="link-red" onClick={() => this.deleteRow(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="card card-blue"><div className="card-header">Urine Analysis</div><div className="card-body">
                                <div className="row">
                                    <div className="col"><ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.obstetricHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("obstetricHistoryT") }}>Obstetric History</span></li>
                                            <li className="nav-item"><span className={this.state.gynaecologicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("gynaecologicalHistoryT") }}>Gynaecological History</span></li>
                                            <li className="nav-item"><span className={this.state.pastMedicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pastMedicalHistoryT") }}>Past Medical Surgical History</span></li>
                                            <li className="nav-item"><span className={this.state.familyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("familyHistoryT") }}>Family History</span></li>
                                             <li className="nav-item"><span className={this.state.socialHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("socialHistoryT") }}>Social History</span></li>
                                            <li className="nav-item"><span className={this.state.currentMedicationT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("currentMedicationT") }}>Current Medication</span></li>
                                            <li className="nav-item"><span className={this.state.allergyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("allergyHistoryT") }}>Allergy History</span></li>
                                        <li className="nav-item"><span className={this.state.presentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaintsT") }}>Presenting Complaints</span></li>
                                              </ul></div>
                                    </div>
                                 <div className="row">
                                    <div className="col">

                                                                                <div className="tab-content">
                                                                                    <div className={this.state.presentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group">
                                                                                                <textarea className="form-control" value={this.state.presentingComplaints} onChange={e => { this.setState({ presentingComplaints: e.target.value }) }} ></textarea> </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={this.state.currentMedicationT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                                                        <div className="row">
                                                                                            <div ><div className="input-group">
                                                                                               <textarea className="form-control" value={this.state.currentMedication} onChange={e => { this.setState({ currentMedication: e.target.value }) }} ></textarea> </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={this.state.allergyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group">
                                                                                             <textarea className="form-control" value={this.state.allergyHistory} onChange={e => { this.setState({ allergyHistory: e.target.value }) }} ></textarea> </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={this.state.familyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                                                    <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Twins</span><input type="text" value={this.state.twins} onChange={e => this.setState({ twins: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Diabetes</span><input type="text" value={this.state.diabetes} onChange={e => this.setState({ diabetes: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Hypertension</span><input type="text" value={this.state.hypertension} onChange={e => this.setState({ hypertension: e.target.value })} className="form-control" /></div></div>
                                                                                        </div>
                                                                                     <div className="card card-green">
                                                                                     <div className="card-header"> Gynaecological Malignancy</div>
                                                                                        <div className="card-body">
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.breastCancerChoice === "1"} onChange={e => this.setState({ breastCancerChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Breast cancer</span><input type="text" value={this.state.breastCancerText} onChange={e => this.setState({ breastCancerText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.uterineCancerChoice === "1"} onChange={e => this.setState({ uterineCancerChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Uterine cancer</span><input type="text" value={this.state.uterineCancerText} onChange={e => this.setState({ uterineCancerText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.ovarianCancerChoice === "1"} onChange={e => this.setState({ ovarianCancerChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Ovarian cancer</span><input type="text" value={this.state.ovarianCancerText} onChange={e => this.setState({ ovarianCancerText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.colorectalCancerChoice === "1"} onChange={e => this.setState({ colorectalCancerChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Colorectal cancer</span><input type="text" value={this.state.colorectalCancerText} onChange={e => this.setState({ colorectalCancerText: e.target.value })} className="form-control" /></div></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                        <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.otherCancer} onChange={e => this.setState({ otherCancer: e.target.value })} className="form-control"   ></textarea></div></div>
                                                                                      
                                                                                        </div>
                                                                                        </div></div>
                                                                                 </div>
                                                                                    <div className={this.state.obstetricHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                        
                                                                                    <div className="card card-green">
                                                                                        <div className="card-header">General Info</div>
                                                                                        <div className="card-body">
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Para</span><input type="text" value={this.state.para} onChange={e => this.setState({ para: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Gravida</span><input type="text" value={this.state.gravida} onChange={e => this.setState({ gravida: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Preg Test</span><select className="form-control" value={this.state.pregTest} onChange={e =>{ this.setState({ pregTest: e.target.value }); this.calculateEDDEGA(this.state.lnmp, e.target.value)}} >
                                                                                    <option value="">Choose</option>
                                                                                    <option value="Positive">Positive</option>
                                                                                    <option value="Negative">Negative</option>
                                                                                    <option value="Indeterminate">Indeterminate</option>
                                                                                    </select></div></div>
                                                                                           </div>
                                                                                        </div></div>
                                                                                    <div className="card card-green">
                                                                                        <div className="card-header">Pregnancy History</div>
                                                                                        <div className="card-body">
                                                                                        <div className="table-responsive-lg"> 
                                                                                       <table className="table table-striped">
                                                                                           <tr>
                                                                                               <th>#</th>
                                                                                               <th>DOB</th>
                                                                                               <th>Gender</th>
                                                                                               <th>Mode of Delivery</th>
                                                                                               <th>MOD Notes</th>
                                                                                               <th>Antenatal Complications</th>
                                                                                               <th>Delivery Complications</th>
                                                                                               <th>Postpartum Complications</th>
                                                                                               <th>Outcome</th>
                                                                                               <th>Delivery Age</th>
                                                                                               <th></th>
                                                                                           </tr>
                                                                                           <tbody>
                                                                                           {this.state.pregnancyHistorys.map((item, index) => (
                                                                                               <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                   <td><input type="text" className="form-control" value={item.dob} onChange={e => { this.setPregnancyHistory(index, 'dob', e.target.value); }} /></td>
                                                                                                   <td><select className="form-control" value={item.gender} onChange={e => { this.setPregnancyHistory(index, 'gender', e.target.value); }}>
                                                                                                   <option value="">Choose</option>
                                                                                                       <option value="Male">Male</option>
                                                                                                       <option value="Female">Female</option>
                                                                                                       <option value="Other">Other</option>
                                                                                                       </select></td>
                                                                                                   <td><select className="form-control" value={item.modeOfDelivery} onChange={e => { this.setPregnancyHistory(index, 'modeOfDelivery', e.target.value); }}>
                                                                                                   <option value="">Choose</option>
                                                                                                       <option value="NVD">NVD</option>
                                                                                                       <option value="NVD Episiotomy">NVD Episiotomy</option>
                                                                                                       <option value="NVD Tear">NVD Tear</option>
                                                                                                       <option value="Assisted VD - Vacuum">Assisted VD - Vacuum</option>
                                                                                                       <option value="Assisted VD - FORCEPs">Assisted VD - FORCEPs</option>
                                                                                                       <option value="LSCS Elective">LSCS Elective</option>
                                                                                                       <option value="LSCS Emergency">LSCS Emergency</option>
                                                                                                       </select></td>
                                                                                                   <td><input type="text" className="form-control" value={item.modNotes} onChange={e => { this.setPregnancyHistory(index, 'modNotes', e.target.value); }} /></td>
                                                                                                   <td><select className="form-control" value={item.antenatalComplications} onChange={e => { this.setPregnancyHistory(index, 'antenatalComplications', e.target.value); }}>
                                                                                                   <option value="">Choose</option>
                                                                                                       <option value="PIH">PIH</option>
                                                                                                       <option value="GDM">GDM</option>
                                                                                                       <option value="Rhesus negative">Rhesus negative</option>
                                                                                                       <option value="FGR">FGR</option>
                                                                                                       <option value="PTB">PTB</option>
                                                                                                       <option value="Other">Other </option>
                                                                                                       </select><input type="text" className="form-control" value={item.antenatalComplicationsRemarks} onChange={e => { this.setPregnancyHistory(index, 'antenatalComplicationsRemarks', e.target.value); }} /></td>
                                                                                                   <td><input type="text" className="form-control" value={item.deliveryComplications} onChange={e => { this.setPregnancyHistory(index, 'deliveryComplications', e.target.value); }} /></td>
                                                                                                   <td><input type="text" className="form-control" value={item.postpartumComplications} onChange={e => { this.setPregnancyHistory(index, 'postpartumComplications', e.target.value); }} /></td>
                                                                                                   <td><select className="form-control" value={item.outcome} onChange={e => { this.setPregnancyHistory(index, 'outcome', e.target.value); }}>
                                                                                                   <option value="">Choose</option>
                                                                                                       <option value="Alive and Well">Alive & Well</option>
                                                                                                       <option value="Alive But">Alive But</option>
                                                                                                       <option value="Still Born">Still Born</option>
                                                                                                       <option value="ENND">ENND</option>
                                                                                                       <option value="LNND">LNND</option>
                                                                                                       </select><input type="text" className="form-control" value={item.outcomeRemarks} onChange={e => { this.setPregnancyHistory(index, 'outcomeRemarks', e.target.value); }} /></td>
                                                                                                   <td><select className="form-control" value={item.deliveryAge} onChange={e => { this.setPregnancyHistory(index, 'deliveryAge', e.target.value); }}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="Pre-term">Pre-term</option>
                                                                                                       <option value="Term">Term</option>
                                                                                                       <option value="Post-term">Post-term</option>
                                                                                                       </select><input type="text" className="form-control" value={item.deliveryAgeRemarks} onChange={e => { this.setPregnancyHistory(index, 'deliveryAgeRemarks', e.target.value); }} /></td> 
                                                                                                       <td ><span className="link-red" onClick={() => this.deletePregnancyHistoryRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                               </tr> ))}
                                                                                           </tbody>
                                                                                       </table>
                                                                                       </div>
                                                                                        <button onClick={() => this.addPregnancyHistoryTable()} className="btn btn-primary" >Insert New Pregnancy History Line</button>
                                                                                       </div></div>
                                                                                       <div className="card card-green">
                                                                                        <div className="card-header">Miscarriage / Ectopic</div>
                                                                                        <div className="card-body">
                                                                                        <div className="table-responsive-lg"> 
                                                                                       <table className="table table-striped">
                                                                                           <tr>
                                                                                               <th>#</th>
                                                                                               <th>Year</th>
                                                                                               <th>Type</th>
                                                                                               <th>Ectopic Management</th>
                                                                                               <th>Medical Management Drug Used</th>
                                                                                               <th>Evacuation</th>
                                                                                               <th>Molar Pregnancy Type</th>
                                                                                               <th>Molar Management</th>
                                                                                               <th>Notes</th>
                                                                                               <th></th>
                                                                                           </tr>
                                                                                           <tbody>
                                                                                           {this.state.miscarriageEctopics.map((item, index) => (
                                                                                               <tr key={index}>
                                                                                               <td>{index + 1}</td>
                                                                                                   <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setMiscarriageEctopic(index, 'year', e.target.value); }} /></td>
                                                                                                   <td><select className="form-control" value={item.type} onChange={e => { this.setMiscarriageEctopic(index, 'type', e.target.value); }}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="Miscarriage">Miscarriage</option>
                                                                                                       <option value="Ectopic">Ectopic</option>
                                                                                                       <option value="Molar">Molar</option>
                                                                                                       </select></td>
                                                                                                   <td><select className="form-control" value={item.ectopicManagement} onChange={e => { this.setMiscarriageEctopic(index, 'ectopicManagement', e.target.value); }}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="Medical">Medical</option>
                                                                                                       <option value="Salpingotomy - laparoscopic">Salpingectomy - laparoscopic</option>
                                                                                                       <option value="Salpingotomy - laparotomy">Salpingectomy - laparotomy</option>
                                                                                                       <option value="Salpingotomy">Salpingotomy - laparoscopic</option>
                                                                                                       <option value="Salpingotomy">Salpingotomy - laparotomy</option>
                                                                                                       <option value="Salpingotomy">Salpingostomy - laparoscopic</option>
                                                                                                       <option value="Salpingotomy">Salpingostomy - laparotomy</option>
                                                                                                       <option value="Other">Other</option>
                                                                                                       </select><input type="text" className="form-control" value={item.ectopicManagementRemarks} onChange={e => { this.setMiscarriageEctopic(index, 'ectopicManagementRemarks', e.target.value); }} /></td>
                                                                                                   <td><input type="text" className="form-control" value={item.medicalManagementDrugUsed} onChange={e => { this.setMiscarriageEctopic(index, 'medicalManagementDrugUsed', e.target.value); }} /></td>
                                                                                                   <td><select className="form-control" value={item.evacuation} onChange={e => { this.setMiscarriageEctopic(index, 'evacuation', e.target.value); }}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="D and C">D & C</option>
                                                                                                       <option value="MVA">MVA</option>
                                                                                                       <option value="Suction">Suction </option>
                                                                                                       <option value="Hysteroscopic">Hysteroscopic</option>
                                                                                                       </select></td>
                                                                                                   <td><select className="form-control" value={item.molarPregnancyType} onChange={e => { this.setMiscarriageEctopic(index, 'molarPregnancyType', e.target.value); }}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="Complete">Complete</option>
                                                                                                       <option value="Incomplete">Incomplete</option>
                                                                                                       <option value="GTD">GTD </option>
                                                                                                       </select></td>
                                                                                                   <td><select className="form-control" value={item.molarManagement} onChange={e => { this.setMiscarriageEctopic(index, 'molarManagement', e.target.value); }}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="Medical">Medical</option>
                                                                                                       <option value="Surgical">Surgical</option>
                                                                                                       <option value="Other">Other</option>
                                                                                                       </select></td>
                                                                                                   <td><input type="text" className="form-control" value={item.notes} onChange={e => { this.setMiscarriageEctopic(index, 'notes', e.target.value); }} /></td>
                                                                                                       <td ><span className="link-red" onClick={() => this.deleteMiscarriageEctopicRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                               </tr>))}
                                                                                           </tbody>
                                                                                       </table>
                                                                                       </div>
                                                                                        <button onClick={() => this.addMiscarriageEctopicTable()} className="btn btn-primary" >Insert New Miscarriage / Ectopic Line</button>
                                                                                       </div></div>
                                                                                    </div>
                                        
                                                                                    <div className={this.state.gynaecologicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                                                    <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text"> LNMP </span>  <input type="text" className="form-control" value={this.state.lnmp} onChange={e => {this.setState({ lnmp: e.target.value }); this.calculateEDDEGA(e.target.value, this.state.pregTest)}}  />
                                                                                            <MyDatePicker onChange={(d)=> {
                                                                                                this.setState({lnmp: d}); this.calculateEDDEGA(d, this.state.pregTest)
                                                                                            }} />
                                                                                                </div></div>
                                                                                                <div className="col"></div>
                                                                                                <div className="col"><div className="input-group"><span className="input-group-text">EDD</span><input type="text" value={this.state.edd} onChange={e => this.setState({ edd: e.target.value })} className="form-control" /> <MyDatePicker onChange={(d)=> {
                                                                                               this.setState({edd: d})
                                                                                            }} /></div></div>
                                                                                            <div className="col"></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                        <div className="col"><div className="input-group"><span className="input-group-text">Menarche  </span> <input type="text" value={this.state.menarche} onChange={e => this.setState({ menarche: e.target.value })} className="form-control" /></div></div>
                                                                                       <div className="col"></div>
                                                                                        <div className="col"><div className="input-group"><span className="input-group-text">EGA</span><input type="text" value={this.state.ega} onChange={e => this.setState({ ega: e.target.value })} className="form-control" /></div></div>
                                                                                        <div className="col"></div>
                                                                                        </div>
                                                                                        <div className="card card-green">
                                                                                        <div className="card-header">Period</div>
                                                                                        <div className="card-body">
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Duration of Flow</span>  <input type="text" value={this.state.durationOfFlow} onChange={e => this.setState({ durationOfFlow: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Regularity  </span> <select className="form-control" value={this.state.regularity} onChange={e => this.setState({ regularity: e.target.value })}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="Regular">Regular</option>
                                                                                                       <option value="Irregular">Irregular </option>
                                                                                                       </select></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Irregular Bleeding </span><select className="form-control" value={this.state.irregularBleeding} onChange={e => this.setState({ irregularBleeding: e.target.value })}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="18 to 25 yrs: cycle length variance less than 9 days">18 to 25 yrs: cycle length variance &lt; 9 days</option>
                                                                                                       <option value="26 to 41 yrs: cycle length variance less than 7 days">26 to 41 yrs: cycle length variance &lt; 7 days</option>
                                                                                                       <option value="42 to 45 yrs: cycle length variance less than 9 days">42 to 45 yrs: cycle length variance &lt; 9 days</option>
                                                                                                       </select></div></div>
                                                                                        </div>       
                                                                                        <div className="row">
                                                                                             <div className="col"><div className="input-group"><span className="input-group-text"> Frequency </span><select className="form-control" value={this.state.frequency} onChange={e => this.setState({ frequency: e.target.value })}>
                                                                                                       <option value="">Choose</option>
                                                                                                       <option value="Absent">Absent</option>
                                                                                                       <option value="Infrequent greater than 38 days">Infrequent {'>'} 38 days</option>
                                                                                                       <option value="Normal 24-38">Normal 24-38</option>
                                                                                                       <option value="Frequent  less than 24">Frequent  &lt;  24</option>
                                                                                                       </select><input type="text" value={this.state.frequencyExact} onChange={e => this.setState({ frequencyExact: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"></div>
                                                                                            <div className="col"></div>
                                                                                        </div>       
                                                                                            </div>
                                                                                         </div>   
                                                                                                                                 
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text"> Volume </span><select className="form-control" value={this.state.volume} onChange={e => this.setState({ volume: e.target.value })}>
                                                                                            <option value="">Choose</option>
                                                                                                        <option value="Heavy">Heavy</option>
                                                                                                       <option value="Normal">Normal</option>
                                                                                                       <option value="Light">Light</option>
                                                                                                       </select><input type="text" value={this.state.volumeExact} onChange={e => this.setState({ volumeExact: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Dysmemorrhoea  </span> <select className="form-control" value={this.state.dysmemorrhoea} onChange={e => this.setState({ dysmemorrhoea: e.target.value })}>
                                                                                            <option value="">Choose</option>
                                                                                              <option value="Mild">Mild</option>
                                                                                                       <option value="Mild and takes Meds">Mild & takes Meds</option>
                                                                                                       <option value="Moderate">Moderate</option>
                                                                                                       <option value="Moderate and takes Meds">Moderate & takes Meds</option> 
                                                                                                       <option value="Severe">Severe</option>
                                                                                                       <option value="Severe and takes Meds">Severe & takes Meds</option>
                                                                                                       <option value="No">No</option>
                                                                                                       </select><input type="text" value={this.state.dysmemorrhoeaNotes} onChange={e => this.setState({ dysmemorrhoeaNotes: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">PMS</span> <select className="form-control" value={this.state.pms} onChange={e => this.setState({ pms: e.target.value })}>
                                                                                            <option value="">Choose</option>
                                                                                             <option value="Yes">Yes</option>
                                                                                                       <option value="No">No</option>
                                                                                                       </select><input type="text" value={this.state.pmsNotes} onChange={e => this.setState({ pmsNotes: e.target.value })} className="form-control" /></div></div>
                                                                                        </div>    
                                                                                        <div className="card card-green">
                                                                                        <div className="card-header">Contraceptives</div>
                                                                                        <div className="card-body">
                                                                                        <div className="table-responsive-lg"> 
                                                                                        <table className="table table-striped">
                                                                                          <tr>
                                                                                              <th>Name</th>
                                                                                              <th>Start Date</th>
                                                                                              <th>End Date</th>
                                                                                              <td>Notes</td>
                                                                                              <th></th>
                                                                                              </tr>  
                                                                                            <tbody>
                                                                                            {this.state.contraceptives.map((item, index) => (
                                                                                            <tr key={index}>
                                                                                                <td><input type="text" className="form-control" value={item.name} onChange={e => { this.setContraceptive(index, 'name', e.target.value); }} /></td>
                                                                                                <td><input type="text" className="form-control" value={item.startDate} onChange={e => { this.setContraceptive(index, 'startDate', e.target.value); }} /></td>
                                                                                                <td><input type="text" className="form-control" value={item.endDate} onChange={e => { this.setContraceptive(index, 'endDate', e.target.value); }} /></td>
                                                                                                <td><input type="text" className="form-control" value={item.notes} onChange={e => { this.setContraceptive(index, 'notes', e.target.value); }} /></td>
                                                                                                       <td ><span className="link-red" onClick={() => this.deleteContraceptivesRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                                </tr> 
                                                                                                 ))}
                                                                                            </tbody>  
                                                                                            </table>  
                                                                                            </div>
                                                                                            <button onClick={() => this.addContraceptivesTable()} className="btn btn-primary" >Insert New Contraceptive Line</button> 
                                                                                            </div> </div>     
                                                                                            <div className="card card-green">
                                                                                        <div className="card-header">Sexual Health</div>
                                                                                        <div className="card-body">                                
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Dyspareunia</span><select className="form-control" value={this.state.dyspareunia} onChange={e => this.setState({ dyspareunia: e.target.value })}>
                                                                                            <option value="">Choose</option>
                                                                                                      <option value="Yes">Yes</option>
                                                                                                       <option value="No">No </option>
                                                                                                       </select><input type="text" className="form-control" value={this.state.dyspareuniaNotes} onChange={e => this.setState({ dyspareuniaNotes: e.target.value })} /> </div></div>                                                   
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Dyspareunia - Yes</span> <select className="form-control" value={this.state.dyspareuniaYes} onChange={e => this.setState({ dyspareuniaYes: e.target.value })}>
                                                                                            <option value="">Choose</option>
                                                                                                       <option value="Superficial">Superficial</option>
                                                                                                       <option value="Deep">Deep</option>
                                                                                                       <option value="Other">Other</option>
                                                                                                       </select><input type="text" className="form-control" value={this.state.dyspareuniaYesNotes} onChange={e => this.setState({ dyspareuniaYesNotes: e.target.value })} /> </div></div>
                                                                                        </div>
                                                                                        </div></div>
                                                                                            <div className="card card-green">
                                                                                        <div className="card-header">Cervical Cancer Screening</div>
                                                                                        <div className="card-body">
                                                                                        <div className="table-responsive-lg"> 
                                                                                        <table className="table table-striped">
                                                                                          <tr>
                                                                                              <th>Year</th>
                                                                                            <th>Month</th>
                                                                                              <th>Type</th>
                                                                                              <th>Result</th>
                                                                                              <th></th>
                                                                                              </tr>  
                                                                                            <tbody>
                                                                                            {this.state.cervicalCancerScreenings.map((item, index) => (
                                                                                            <tr>
                                                                                                <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setCervicalCancerScreening(index, 'year', e.target.value); }} /></td>
                                                                                                 <td><input type="text" className="form-control" value={item.month} onChange={e => { this.setCervicalCancerScreening(index, 'month', e.target.value); }} /></td>
                                                                                               <td><select className="form-control" value={item.type} onChange={e => { this.setCervicalCancerScreening(index, 'type', e.target.value); }}>
                                                                                                <option value="">Choose</option>
                                                                                                <option value="VIAC">VIAC</option>
                                                                                                       <option value="PAP">PAP</option>
                                                                                                       <option value="LBC">LBC</option>
                                                                                                       <option value="HPV">HPV</option>
                                                                                                       <option value="LBC or HPV">LBC / HPV</option>
                                                                                                    </select></td>
                                                                                                <td><input type="text" className="form-control" value={item.result} onChange={e => { this.setCervicalCancerScreening(index, 'result', e.target.value); }} /></td>
                                                                                                       <td ><span className="link-red" onClick={() => this.deleteCervicalCancerScreeningRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                                </tr> 
                                                                                                 ))}
                                                                                            </tbody>  
                                                                                            </table>   
                                                                                            </div>
                                                                                            <button onClick={() => this.addCervicalCancerScreeningTable()} className="btn btn-primary" >Insert New Cervical Cancer Screening Line</button> 
                                                                                            </div> </div>
                                                                                            <div className="card card-green">
                                                                                        <div className="card-header">Breast Cancer Screening</div>
                                                                                        <div className="card-body">
                                                                                        <div className="table-responsive-lg"> 
                                                                                        <table className="table table-striped">
                                                                                          <tr>
                                                                                            <th>Year</th>
                                                                                            <th>Month</th>
                                                                                              <th>Self Exam</th>
                                                                                              <th>Clinical Exam</th>
                                                                                              <th>Mammogram</th>
                                                                                              <th>Breast Ultrasound Scan</th>
                                                                                              <th></th>
                                                                                              </tr>  
                                                                                            <tbody>
                                                                                            {this.state.breastCancerScreenings.map((item, index) => (
                                                                                            <tr key={index}>
                                                                                                <td><input type="text" className="form-control"  value={item.year} onChange={e => { this.setBreastCancerScreening(index, 'year', e.target.value); }}/></td>
                                                                                                <td><input type="text" className="form-control" value={item.month} onChange={e => { this.setBreastCancerScreening(index, 'month', e.target.value); }} /></td>
                                                                                                <td><div className="input-group"><select className="form-control" value={item.selfExam} onChange={e => { this.setBreastCancerScreening(index, 'selfExam', e.target.value); }}>
                                                                                                <option value="">Choose</option>
                                                                                                <option value="Yes">Yes</option>
                                                                                                       <option value="No">No</option>
                                                                                                    </select><input type="text" className="form-control" value={item.selfExamRemarks} onChange={e => { this.setBreastCancerScreening(index, 'selfExamRemarks', e.target.value); }} /></div></td>
                                                                                                
                                                                                                    <td><div className="input-group"><select className="form-control" value={item.clinicalExam} onChange={e => { this.setBreastCancerScreening(index, 'clinicalExam', e.target.value); }}>
                                                                                                    <option value="">Choose</option>
                                                                                                <option value="Yes">Yes</option>
                                                                                                       <option value="No">No</option>
                                                                                                    </select><input type="text" className="form-control" value={item.clinicalExamRemarks} onChange={e => { this.setBreastCancerScreening(index, 'clinicalExamRemarks', e.target.value); }} /></div></td>
                                                                                                
                                                                                                    <td><div className="input-group"><select className="form-control" value={item.mammogram} onChange={e => { this.setBreastCancerScreening(index, 'mammogram', e.target.value); }}>
                                                                                                    <option value="">Choose</option>
                                                                                                <option value="Yes">Yes</option>
                                                                                                       <option value="No">No</option>
                                                                                                    </select><input type="text" className="form-control" value={item.mammogramRemarks} onChange={e => { this.setBreastCancerScreening(index, 'mammogramRemarks', e.target.value); }} /></div></td>
                                        
                                                                                                <td><div className="input-group"><select className="form-control" value={item.breastUltrasoundScan} onChange={e => { this.setBreastCancerScreening(index, 'breastUltrasoundScan', e.target.value); }}>
                                                                                                <option value="">Choose</option>
                                                                                                <option value="Yes">Yes</option>
                                                                                                       <option value="No">No</option>
                                                                                                    </select><input type="text" className="form-control" value={item.breastUltrasoundScanRemarks} onChange={e => { this.setBreastCancerScreening(index, 'breastUltrasoundScanRemarks', e.target.value); }} /></div></td>
                                                                                                       <td ><span className="link-red" onClick={() => this.deleteBreastCancerScreeningRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                                </tr> 
                                                                                                 ))}
                                                                                            </tbody>  
                                                                                            </table>   
                                                                                            </div>
                                                                                            <button onClick={() => this.addBreastCancerScreeningTable()} className="btn btn-primary" >Insert New Breast Cancer Screening Line</button> 
                                                                                            </div> </div> 
                                                                                            <div className="card card-green">
                                                                                        <div className="card-header">Menopause</div>
                                                                                        <div className="card-body">
                                                                                        <div className="table-responsive-lg"> 
                                                                                        <table className="table table-striped">
                                                                                          <tr>
                                                                                              <th>Symptoms</th>
                                                                                              <th>Other</th>
                                                                                              <th></th>
                                                                                              </tr>  
                                                                                            <tbody>
                                                                                            {this.state.menopauses.map((item, index) => (
                                                                                            <tr>
                                                                                                <td><select className="form-control" value={item.symptoms} onChange={e => { this.setMenopause(index, 'symptoms', e.target.value); }}>
                                                                                                <option value="">Choose</option>
                                                                                                <option value="Hot Flushes">Hot Flushes</option>
                                                                                                       <option value="Genitourinary Syndrome">Genitourinary Syndrome</option>
                                                                                                       <option value="Reduced Libido">Reduced Libido </option>
                                                                                                       <option value="Other">Other</option>
                                                                                                    </select></td>
                                                                                                <td><input type="text" className="form-control" value={item.other} onChange={e => { this.setMenopause(index, 'other', e.target.value); }} /></td>
                                                                                                       <td ><span className="link-red" onClick={() => this.deleteMenopauseRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                                </tr> 
                                                                                                 ))}
                                                                                            </tbody>  
                                                                                            </table>   
                                                                                            </div>
                                                                                            <button onClick={() => this.addMenopauseTable()} className="btn btn-primary" >Insert New Menopause Line</button> 
                                                                                            </div> </div>   
                                                                                            <div className="card card-green">
                                                                                        <div className="card-header">Intimate Partner Violence</div>
                                                                                        <div className="card-body">                              
                                                                                        <div className="row">
                                                                                                   <div className="col"><div className="input-group"><span className="input-group-text"> Violence</span> <select className="form-control" value={this.state.violence} onChange={e => this.setState({ violence: e.target.value })}>
                                                                                            <option value="Yes">Yes</option>
                                                                                                       <option value="No">No</option>
                                                                                                       </select> </div></div>
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text"> Other Information</span><input type="text" className="form-control" value={this.state.otherViolence} onChange={e => this.setState({ otherViolence: e.target.value })} /> </div></div>                                                   
                                                                                            <div className="col"></div>
                                                                                        </div>
                                                                                    </div> </div>
                                          <div className="card card-green">
                                                                                        <div className="card-header">Mental Health</div>
                                                                                        <div className="card-body"> 
                                                                                       <div className="row">
                                                                                            <div className="col"><div className="input-group">
                                                                                             <textarea className="form-control" value={this.state.mentalHealth} onChange={e => { this.setState({ mentalHealth: e.target.value }) }} ></textarea> </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        </div> </div>
                                                                                    </div>
                                                                                    <div className={this.state.socialHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                                                    <div className="card card-green">
                                                                                        <div className="card-header">Patient</div>
                                                                                        <div className="card-body">
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.alcoholChoice === "1"} onChange={e => this.setState({ alcoholChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Alcohol</span><input type="text" value={this.state.alcoholText} onChange={e => this.setState({ alcoholText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.smokesChoice === "1"} onChange={e => this.setState({ smokesChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Smokes</span><input type="text" value={this.state.smokesText} onChange={e => this.setState({ smokesText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.drugsChoice === "1"} onChange={e => this.setState({ drugsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Drugs</span><input type="text" value={this.state.drugsText} onChange={e => this.setState({ drugsText: e.target.value })} className="form-control" /></div></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.churchChoice === "1"} onChange={e => this.setState({ churchChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Church</span><input type="text" value={this.state.churchText} onChange={e => this.setState({ churchText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bloodTransfusionChoice === "1"} onChange={e => this.setState({ bloodTransfusionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Blood Transfusion</span><input type="text" value={this.state.bloodTransfusionText} onChange={e => this.setState({ bloodTransfusionText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"></div>
                                                                                        </div>
                                                                                        </div></div>
                                                                                        <div className="card card-green">
                                                                                        <div className="card-header">Partner</div>
                                                                                        <div className="card-body">
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerAlcoholChoice === "1"} onChange={e => this.setState({ partnerAlcoholChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Alcohol</span><input type="text" value={this.state.partnerAlcoholText} onChange={e => this.setState({ partnerAlcoholText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerSmokesChoice === "1"} onChange={e => this.setState({ partnerSmokesChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Smokes</span><input type="text" value={this.state.partnerSmokesText} onChange={e => this.setState({ partnerSmokesText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerDrugsChoice === "1"} onChange={e => this.setState({ partnerDrugsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Drugs</span><input type="text" value={this.state.partnerDrugsText} onChange={e => this.setState({ partnerDrugsText: e.target.value })} className="form-control" /></div></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerChurchChoice === "1"} onChange={e => this.setState({ partnerChurchChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Church</span><input type="text" value={this.state.partnerChurchText} onChange={e => this.setState({ partnerChurchText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"></div>
                                                                                            <div className="col"></div>
                                                                                        </div>
                                                                                        </div></div>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.socialHistoryTNotes} onChange={e => this.setState({ socialHistoryTNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={this.state.pastMedicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.diabetesChoice === "1"} onChange={e => this.setState({ diabetesChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Diabetes </span><input type="text" value={this.state.diabetesText} onChange={e => this.setState({ diabetesText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.epilepsyChoice === "1"} onChange={e => this.setState({ epilepsyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Epilepsy</span> <input type="text" value={this.state.epilepsyText} onChange={e => this.setState({ epilepsyText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.asthmaChoice === "1"} onChange={e => this.setState({ asthmaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Asthma</span> <input type="text" value={this.state.asthmaText} onChange={e => this.setState({ asthmaText: e.target.value })} className="form-control" /></div></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rheumaticFeverChoice === "1"} onChange={e => this.setState({ rheumaticFeverChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Rheumatic Fever</span>  <input type="text" value={this.state.rheumaticFeverText} onChange={e => this.setState({ rheumaticFeverText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.thyroidDisorderChoice === "1"} onChange={e => this.setState({ thyroidDisorderChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thyroid Disorder </span><input type="text" value={this.state.thyroidDisorderText} onChange={e => this.setState({ thyroidDisorderText: e.target.value })} className="form-control" /></div></div>
                                                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.hypertensionChoice === "1"} onChange={e => this.setState({ hypertensionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertension</span> <input type="text" value={this.state.hypertensionText} onChange={e => this.setState({ hypertensionText: e.target.value })} className="form-control" /></div></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.pastMedicalNotes} onChange={e => this.setState({ pastMedicalNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                                                        </div>
                                                                                        <div className="card card-green">
                                                                                        <div className="card-header">Surgeries Done</div>
                                                                                        <div className="card-body">
                                                                                        <div className="table-responsive-lg"> 
                                                                                       <table className="table table-striped">
                                                                                           <tr>
                                                                                               <th>Year</th>
                                                                                               <th>Surgery</th>
                                                                                               <th>Type</th>
                                                                                               <th>Hospital</th>
                                                                                               <th>Surgeon</th>
                                                                                               <th>Complications</th>
                                                                                               <th></th>
                                                                                           </tr>
                                                                                            {this.state.surgeries.map((item, index) => (
                                                                                           <tr key={index}>
                                                                                           <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setSurgery(index, 'year', e.target.value); }} /></td>
                                                                                           <td><input type="text" className="form-control" value={item.surgery} onChange={e => { this.setSurgery(index, 'surgery', e.target.value); }} /></td>
                                                                                           <td><input type="text" className="form-control" value={item.type} onChange={e => { this.setSurgery(index, 'type', e.target.value); }} /></td>
                                                                                           <td><input type="text" className="form-control" value={item.hospital} onChange={e => { this.setSurgery(index, 'hospital', e.target.value); }} /></td>
                                                                                           <td><input type="text" className="form-control" value={item.surgeon} onChange={e => { this.setSurgery(index, 'surgeon', e.target.value); }} /></td> 
                                                                                           <td><input type="text" className="form-control" value={item.complications} onChange={e => { this.setSurgery(index, 'complications', e.target.value); }} /></td>
                                                                                                       <td ><span className="link-red" onClick={() => this.deleteSurgeriesRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                           </tr>
                                                                                            ))}
                                                                                           </table>
                                                                                           </div>
                                                                                            <button onClick={() => this.addSurgeriesTable()} className="btn btn-primary" >Insert New Surgeries Done Line</button> 
                                                                                           </div></div>
                                                                                           <div className="card card-green">
                                                                                        <div className="card-header">Previous Hospital Admissions</div>
                                                                                        <div className="card-body">
                                                                                       <table className="table table-striped">
                                                                                           <tr>
                                                                                               <th>Year</th>
                                                                                               <th>Reason</th>
                                                                                               <th></th>
                                                                                           </tr>
                                                                                           {this.state.previousHospitalAdmissions.map((item, index) => (
                                                                                           <tr key={index}>
                                                                                           <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setPreviousHospitalAdmission(index, 'year', e.target.value); }} /></td>
                                                                                           <td><input type="text" className="form-control" value={item.reason} onChange={e => { this.setPreviousHospitalAdmission(index, 'reason', e.target.value); }} /></td>
                                                                                                       <td ><span className="link-red" onClick={() => this.deletePreviousHospitalAdmissionRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                                                           </tr>
                                                                                            ))}
                                                                                           </table>
                                                                                           <button onClick={() => this.addPreviousHospitalAdmission()} className="btn btn-primary" >Insert New Previous Hospital Admissions Line</button> 
                                                                                           </div></div>
                                                                                    </div>
                                                                                </div>
                                    </div>
                                 </div>
                                </div></div>
                        {this.state.managementData && (<div >
                            <div className="card  card-blue">
                                <div className="card-header">Management</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span >{this.state.managementData}</span></div>
                                    </div>
                                </div></div>
                        </div>) }
                        {this.state.diagnosisText !== "" && (<div >
                            <div className="card  card-blue">
                                <div className="card-header">Diagnosis Text</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span >{this.state.diagnosisText}</span></div>
                                    </div>
                                </div></div>
                        </div>)}
                        {this.state.patientDiagnosis.length > 0 && (<div >
                            <div className="card  card-blue">
                                <div className="card-header">Diagnosis ICD 10</div>
                                <div className="card-body">
                                <table className="table">
                                        <tr>
                                            <th>Code</th>
                                            <th>Description</th>
                                        </tr>
                                        {this.state.patientDiagnosis.map((item, index) =>  (item.activeStatus === 1 &&(
                                            <tr key={index}>
                                            <td>{item.code}</td>
                                                <td>{item.description}</td>
                                             </tr>)))}
                                    </table>
                                </div></div>
                        </div>)}
                    </div>
                </div>
                <div className="row">
                    <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                    <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      