import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Allocation extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.setMsg = this.setMsg.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.allocateAdvancedRceiptRef = React.createRef()
    this.cancelReceiptRef = React.createRef()
    this.refundActionRef = React.createRef();
    }
    state = { id: 0, firstname: '', surname: '',  startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [] , showSubmit: false, fullname: '', number: '', paidAmount: 0, billId: 0, advancedReceiptId: 0, rate: 0, cancelReason: '', cancelReasonError: '' }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname,dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/bill/allocationlist/'+localStorage.getItem('roleId'), data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    setMsg(msg)
    {
        this.setState({msg: msg})  
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
      processDateTime(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        const todayH = checkZero(now.getHours());
        const todayMM = checkZero(now.getMinutes());
        const todayS = checkZero(now.getSeconds());
        var date1 = todayY + '-' + todayM + '-' + todayD + ' ' + todayH + ':' + todayMM + ':' + todayS;
        console.log(date1)
        return date1;


    }
    openCancelAllocation(a)
      {
        this.setState({ id: a.id, fullname: a.fullname, number: a.number, paidAmount: a.paidAmount, billId: a.billId, advancedReceiptId: a.advancedReceiptId, rate: a.rate, cancelReason: '', cancelReasonError: '', msg: '', error: ''})
        document.getElementById('cancelAction').style = 'display:block'
        this.doValidate()
      }
       
      closeCancelPopup() {
        document.getElementById('cancelAction').style = 'display:none'
    }  
    doValidate()
    {
      setTimeout(() => {
          this.validate();
      }, 1000);   
    }
    validate()
      {
        
        var error = false;
        if (this.state.cancelReason === null || this.state.cancelReason === "") {
            this.setState({ cancelReasonError: "Cancel Reason cannot be empty" })
            error = true
        }
        else if (this.state.cancelReason.length <  4) {
                this.setState({ cancelReasonError: "Cancel Reason cannot be less than 3 characters" })
                error = true
            }
        else {
            this.setState({ cancelReasonError: "" })
        }
        if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }  
      } 
      save() {
          var error = "";
          if (error === "") {
              this.setLoader(true)
              var data = {id: this.state.id, billId: this.state.billId, advancedReceiptId: this.state.advancedReceiptId, cancelReason: this.state.cancelReason, allocatedAmount: this.state.paidAmount, rate: this.state.rate}
              axios.post(localStorage.getItem('host') + "/bill/cancelallocation", data, {
                  headers: {
                      'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              })
                  .then(res => {
                      this.setState({msg: res.data, loader: false});
                      this.closeCancelPopup();
                      this.getList()
                  })
          } else {
              this.setState({ error: error })
          }
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">

                    <div id="cancelAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Cancel Action</div>
            <div className="card-body" >
                <div className="card border-success">
                    <div className="card-body">
                        {divMsg}
                        <div className="row">
                            <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div className="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                            <div className="col"><span className="fw-bold">Paid Amount:</span> {this.currencyFormat(this.state.paidAmount)}</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col"><div className="input-group has-validation"><span className="input-group-text">Reason:</span>
                                <textarea className={this.state.cancelReasonError !== "" ? "form-control is-invalid" : "form-control"} cols="5" value={this.state.cancelReason} onChange={e => {this.setState({ cancelReason: e.target.value }); this.doValidate()}} ></textarea>
                                <div className="invalid-feedback">{this.state.cancelReasonError}</div></div> </div>
                </div>
                <div className="row">
                    <div className="col" align="center">{this.state.showSubmit === true? (<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div className="col" align="center"><button onClick={() => this.closeCancelPopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>
                       {divMsg}
                     
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                         <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                             <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                             <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                               <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <button className="btn btn-success" onClick={() => this.getList()}><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Bill Number</th>
                                    <th>Bill Date</th>
                                    <th>Bill Status</th>
                                    <th>Bill Type</th>
                                    <th>Done By</th>
                                    <th>Date</th>
                                    <th>Paid Amount</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} >
                                        <td >
                                        { item.activeStatus === 1 ?(<span className="link" onClick={() => this.getPrintOut(item.id, 'ALLOCATION', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>):(<span></span>)}
                                            { item.activeStatus === 1 && item.showCancel  === true?(<span className="link-red" onClick={() => this.openCancelAllocation(item)} title="Cancel Receipt" ><i className="bi bi-x-square-fill"></i></span>):(<span></span>)}
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.billNumber}</td>
                                        <td>{item.billDate}</td>
                                        <td>{item.billStatus}</td>
                                        <td>{item.billType}</td>
                                        <td>{item.createdByUser}</td>
                                        <td>{item.allocationDateTime}</td>
                                        <td align="right">{this.currencyFormat(item.paidAmount)}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    