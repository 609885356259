import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PaginationEle from "../pagination";


export default class Reports extends Component {
    state = { list: [], id: 0, name: '', sqlText: '', titles: '', activeStatus: 1, reportFilters: [], reportSheets: [], reportTotals: [], menus: [], menu: '', msg: '', error: '' , columns: [
        {
            label: 'Action',
            field: 'action'
        },
        {
            label: 'Name',
            field: 'name'
        },
        {
            label: 'Id',
            field: 'id'
        },
        {
            label: 'Status',
            field: 'stringStatus'
        }
    ]}
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', sqlText: '', titles: '', activeStatus: 1, msg: '', reportFilters: [], reportSheets: [], reportTotals: [] })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/report/getreportbyid/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, sqlText: res.data.sqlText, titles: res.data.titles, activeStatus: res.data.activeStatus, reportFilters: res.data.reportFilters, reportSheets: res.data.reportSheets, reportTotals: res.data.reportTotals });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#reportAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#reportAction').style = 'display:none'
        console.log('......close');
    }
    saveReport() {
        var data = { id: this.state.id, name: this.state.name, sqlText: this.state.sqlText, titles: this.state.titles, activeStatus: this.state.activeStatus, reportFilters: this.state.reportFilters, reportSheets: this.state.reportSheets, reportTotals: this.state.reportTotals }
        axios.post(localStorage.getItem('host') + '/report/savereport', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/report/getreportlist', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Report" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    addFilter() {
        let list = this.state.reportFilters.slice();
        list.push({ id: 0, reportFilterType: '', parameterMapper: '', label1: '', label2: '', columnName: '', selectSql: '', selectColumn: '', required: 0, activeStatus: 1 });
        this.setState({ reportFilters: list })
    }
    addTotal() {
        let list = this.state.reportTotals.slice();
        list.push({ id: 0, name: '', dataType: '', index: '', multiplyBy: '', activeStatus: 1 });
        this.setState({ reportTotals: list })
    }
    setRowValue(index, e, name) {
        let list = this.state.reportFilters.slice();
        switch (name) {
         case "columnName":
                        list[index].columnName = e.target.value;
                        break;
         case "label1":
                        list[index].label1 = e.target.value;
                        break;
         case "label2":
                        list[index].label2 = e.target.value;
                        break;
         case "reportFilterType":
                        list[index].reportFilterType = e.target.value;
                        break;
         case "parameterMapper":
                        list[index].parameterMapper = e.target.value;
                        break;
         case "selectSql":
                        list[index].selectSql = e.target.value;
                        break;
         case "selectColumn":
                        list[index].selectColumn = e.target.value;
                        break;
         case "required":
                        list[index].required = e.target.value;
                        break;
         case "activeStatus":
                        list[index].activeStatus = e.target.value;
                        break;
                 default:
                         console.log("default")
                          break;

                      }       
        this.setState({ reportFilters: list })
      }
      setRowValueTotal(index, e, name) {
          let list = this.state.reportTotals.slice();
          switch (name) {
           case "name":
                          list[index].name = e.target.value;
                          break;
           case "dataType":
                          list[index].dataType = e.target.value;
                          break;
           case "index":
                          list[index].index = e.target.value;
                          break;
           case "multiplyBy":
                          list[index].multiplyBy = e.target.value;
                          break;
           case "activeStatus":
                          list[index].activeStatus = e.target.value;
                          break;
                   default:
                           console.log("default")
                            break;
  
                        }       
          this.setState({ reportTotals: list })
        }
        addSheet() {
            let list = this.state.reportSheets.slice();
            list.push({ id: 0, name: '', columnName: '', sqlText: '', title: '', activeStatus: 1 });
            this.setState({ reportSheets: list })
        }
        setRowValueSheet(index, e, name) {
            let list = this.state.reportSheets.slice();
            switch (name) {
             case "name":
                            list[index].name = e.target.value;
                            break;
             case "columnName":
                            list[index].columnName = e.target.value;
                            break;
             case "sqlText":
                            list[index].sqlText = e.target.value;
                            break;
             case "title":
                            list[index].title = e.target.value;
                            break;
             case "activeStatus":
                            list[index].activeStatus = e.target.value;
                            break;
                     default:
                             console.log("default")
                              break;
    
                          }       
            this.setState({ reportSheets: list })
          }
render() {
   
    let divMsg = ''
    if (this.state.msg) {
        divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
    }
    return (
        <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
            <Top />

            <div className="container-fluid">
                    <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                    {divMsg}
                     <PaginationEle titles={this.state.columns} rows= {this.state.list} />
                    <div className="page-popup page-popup-overlay" id="reportAction">
                        <form  >
                            <div className="card">
                                <div className="card-header bg-info">
                                    Report Action {this.props.idFromParent}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <label>Name</label>
                                            <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} />
                                        </div>
                                        <div className="col">
                                            <label>Status</label>
                                            <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                <option value="1" >Active</option>
                                                <option value="0" >Deactivate</option>
                                            </select>
                                        </div></div>
                                    <div className="row">
                                        <div className="col">
                                            <label>Titles</label>
                                            <textarea className="form-control" value={this.state.titles} onChange={e => { this.setState({ titles: e.target.value }) }} ></textarea>
                                        </div>
                                        <div className="col">
                                            <label>SQL</label>
                                            <textarea className="form-control" value={this.state.sqlText} onChange={e => { this.setState({ sqlText: e.target.value }) }} ></textarea>
                                        </div></div>
                                    <div className="card">
                                        <div className="card-header">Filters</div>
                                        <div className="card-body">                                        <table style={{ width: 100 + '%' }} >
                                            <tr>
                                                <th>Column Name</th>
                                                <th>Label 1</th>
                                                <th>Label 2</th>
                                                <th>Filter Type</th>
                                                <th>Parameter</th>
                                                <th>Select Sql</th>
                                                <th>Select Column</th>
                                                <th>Required</th>
                                                <th>Status</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={8}> </td>
                                                <td><input type="button" className="btn btn-success" value="Insert Filter" onClick={() => { this.addFilter() }} /></td>
                                            </tr>
                                            {this.state.reportFilters.map((reportFilter, index) => (
                                                <tr>
                                                    <td><input type="text" className="form-control" value={reportFilter.columnName} onChange={e => { this.setRowValue(index, e, "columnName")  }} /></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.label1} onChange={e => { this.setRowValue(index, e, "label1")  }} /></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.label2} onChange={e => {this.setRowValue(index, e, "label2")  }} /></td>
                                                    <td><select className="form-control" value={reportFilter.reportFilterType} onChange={e => { this.setRowValue(index, e, "reportFilterType") }}  >
                                                        <option value="">Choose</option>
                                                        <option value="BOOLEAN" >BOOLEAN</option>
                                                        <option value="DATE_RANGE" >DATE RANGE</option>
                                                        <option value="NUMERIC" >NUMERIC</option>
                                                        <option value="SELECT_STATEMENT" >SELECT STATEMENT</option>
                                                        <option value="SINGLE_DATE" >SINGLE DATE</option>
                                                        <option value="STRING_LIKE" >STRING LIKE</option>
                                                        <option value="REPLACE_SINGLE_DATE" >REPLACE SINGLE DATE</option>
                                                    </select></td>
                                                    <td><select className="form-control" value={reportFilter.parameterMapper} onChange={e => { this.setRowValue(index, e, "parameterMapper")  }}  >
                                                        <option value="">Choose</option>
                                                        <option value="parameter1" >Parameter 1</option>
                                                        <option value="parameter2" >Parameter 2</option>
                                                        <option value="parameter3" >Parameter 3</option>
                                                        <option value="parameter4" >Parameter 4</option>
                                                        <option value="parameter5" >Parameter 5</option>
                                                        <option value="parameter6" >Parameter 6</option>
                                                    </select></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.selectSql} onChange={e => { this.setRowValue(index, e, "selectSql")  }} /></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.selectColumn} onChange={e => { this.setRowValue(index, e, "selectColumn")  }} /></td>
                                                    <td><select className="form-control" value={reportFilter.required} onChange={e => { this.setRowValue(index, e, "required")  }}  >
                                                        <option value="1" >Yes</option>
                                                        <option value="0" >No</option>
                                                    </select></td>
                                                    <td><select className="form-control" value={reportFilter.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus")  }}  >
                                                        <option value="1" >Active</option>
                                                        <option value="0" >Disable</option>
                                                    </select></td>
                                                </tr>
                                            ))}
                                        </table>   </div></div>
                                    <div className="card">
                                        <div className="card-header">Sheets</div>
                                        <div className="card-body">                                        <table style={{ width: 100 + '%' }} >
                                            <tr>
                                                <th>Name</th>
                                                <th>Column</th>
                                                <th>SQL</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}> </td>
                                                <td><input type="button" className="btn btn-success" value="Insert Sheet" onClick={() => { this.addSheet() }} /></td>
                                            </tr>
                                            {this.state.reportSheets.map((item, index) => (
                                                <tr>
                                                    <td><input type="text" className="form-control" value={item.name} onChange={e => { this.setRowValueSheet(index, e, "name")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.columnName} onChange={e => { this.setRowValueSheet(index, e, "columnName")  }} /></td>
                                                    <td><textarea className="form-control" value={item.sqlText} onChange={e => { this.setRowValueSheet(index, e, "sqlText")  }} ></textarea></td>
                                                    <td><input type="text" className="form-control" value={item.title} onChange={e => { this.setRowValueSheet(index, e, "title")  }} /></td>
                                                      <td><select className="form-control" value={item.activeStatus} onChange={e => { this.setRowValueSheet(index, e, "activeStatus")  }}  >
                                                        <option value="1" >Active</option>
                                                        <option value="0" >Disable</option>
                                                    </select></td>
                                                </tr>
                                            ))}
                                        </table>   </div></div>
                                    <div className="card">
                                        <div className="card-header">Filters</div>
                                        <div className="card-body">                                        <table style={{ width: 100 + '%' }} >
                                            <tr>
                                                <th>Name</th>
                                                <th>Data Type</th>
                                                <th>Index</th>
                                                <th>Multiply</th>
                                                <th>Status</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={8}> </td>
                                                <td><input type="button" className="btn btn-success" value="Insert Total" onClick={() => { this.addTotal() }} /></td>
                                            </tr>
                                            {this.state.reportTotals.map((reportTotal, index) => (
                                                <tr>
                                                    <td><input type="text" className="form-control" value={reportTotal.name} onChange={e => { this.setRowValueTotal(index, e, "name")  }} /></td>
                                                    <td><select className="form-control" value={reportTotal.dataType} onChange={e => { this.setRowValueTotal(index, e, "dataType") }}  >
                                                        <option value="INT" >INT</option>
                                                        <option value="DOUBLE" >DOUBLE</option>
                                                    </select></td>
                                                    <td><input type="text" className="form-control" value={reportTotal.index} onChange={e => { this.setRowValueTotal(index, e, "index")  }} /></td>
                                                    <td><input type="text" className="form-control" value={reportTotal.multiplyBy} onChange={e => { this.setRowValueTotal(index, e, "multiplyBy")  }} /></td>
                                                      <td><select className="form-control" value={reportTotal.activeStatus} onChange={e => { this.setRowValueTotal(index, e, "activeStatus")  }}  >
                                                        <option value="1" >Active</option>
                                                        <option value="0" >Disable</option>
                                                    </select></td>
                                                </tr>
                                            ))}
                                        </table>   </div></div>
                                    <div className="row">
                                        <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveReport()} value="Save" /></div>
                                        <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                    </div>
                                </div></div>
                        </form>
                    </div>
                </div>
            </div></div>
    )

}
}    