import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import MyDatePicker from "../mydatepicker";



const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());

function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class InvestRequestsList extends Component {
    
    state = { id: 0, patientName: '',  startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , reason: '', companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [], patientId: 0, reloadPatientList: false, duplicateCount: 0 , msg: ''}
    
    getInvestigationList()
    {
        document.getElementById('investRequestsListAction').style = 'display:block'
        axios.get(localStorage.getItem('host') + '/auth/getcompanyactiveinvestigationlist/'+localStorage.getItem("companyId"), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                let companyId = 0
                if(res.data.length === 1)
                    {
                    companyId = res.data[0].id
                    }
                this.setState({clusterCompanys: res.data, companyId: companyId});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    getList() {
        this.props.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, startdate: this.state.startDate, enddate: this.state.endDate, companyId: this.state.companyId, currentCompanyId: localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/patient/getinvestigationrequests', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('investRequestsListAction').style = 'display:none'
    }
    openQuotationAction(quotationId, patientId) {
        this.props.openQuotationAction(quotationId, patientId);
    }
    cancelQuotation(number, quotationId) {
        this.setState({number: number, id: quotationId})
        document.getElementById('cancelQuotationAction').style = 'display:block' 
    }
    sendCancelQuotation() {
        axios.get(localStorage.getItem('host') + '/patient/cancelquotation/' + this.state.id+'?reason='+this.state.reason, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.props.setLoader(false)
                this.closeCancelPopup()
                this.getQuotationList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    closeCancelPopup() {
        document.getElementById('cancelQuotationAction').style = 'display:none'
    } 
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div id="investRequestsListAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header  bg-info">Invest Requests List</div>
                    <div className="card-body" >
                {divMsg}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                           <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                                                               <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                                                              <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                                                               <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" value={this.state.companyId} onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                              <option value="">Choose</option>
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Fullname</th>
                                    <th>Gender</th>
                                    <th>Date Of Birth</th>
                                    <th>Date</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((i, index) => (
                                    <tr key={index}  style={{ backgroundColor: i.color }}>
                                      <td >
                                            <span className="link" onClick={() =>{ this.props.openVisitAction(0, i.patientId, '', i.id); this.close() }} title="Create Visit" ><i className="bi bi-file-earmark-fill"></i></span>
                                         
                                                </td>
                                        <td>{i.fullname}</td>
                                        <td>{i.gender}</td>
                                        <td>{i.dateOfBirth}</td>
                                        <td>{i.dateTime}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-lg-12" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close Window (Esc)</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}      