import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PaginationEle from "../pagination";


export default class Roles extends Component {
    state = { list: [], id: 0, name: '', activeStatus: 1, roleMenus: [], menus: [], locations: [], roleLocations: [], menu: '', dashboard: '', msg: '', error: '', location: '',columns: [

        {
            label: 'Action',
            field: 'action'
        },
        {
            label: 'Name',
            field: 'name'
        },
        {
            label: 'Dashboard',
            field: 'dashboard'
        },
        {
            label: 'Status',
            field: 'stringStatus'
        }
    ] }
        constructor(props) {
            super(props);
           this.paginationEleRef = React.createRef()
        }
    componentDidMount() {
        this.getList()
        
    }
    roleAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', activeStatus: 1, msg: '', roleMenus: [], locations: [], roleLocations: [] , menus:[]})
            axios.get(localStorage.getItem('host') + '/auth/roleaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, dashboard: res.data.dashboard, activeStatus: res.data.activeStatus, roleMenus: res.data.roleMenus, menus: res.data.menus, locations: res.data.locations, roleLocations: res.data.roleLocations });
                }, err => {
                    console.log(".....")
                }
            )
        document.querySelector('#roleAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#roleAction').style = 'display:none'
        console.log('......close');
    }
    saveRole() {
        var data = { id: this.state.id, name: this.state.name, dashboard: this.state.dashboard, activeStatus: this.state.activeStatus, roleMenus: this.state.roleMenus, roleLocations: this.state.roleLocations }
        axios.post(localStorage.getItem('host') + '/auth/roleform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/auth/role', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Role" id={res.data[index].id} onClick={e => this.roleAction(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.paginationEleRef.current.update(res.data)
            }, err => {
                console.log(".....")
            }
        )
    }
    addMenu() {
        if (this.state.menu) {
            let list = this.state.roleMenus.slice();
            console.log(JSON.stringify(this.state.menus[this.state.menu]))
            list.push({ id: 0, menuName: this.state.menus[this.state.menu].name, menuId: this.state.menus[this.state.menu].id, activeStatus: 1 });
            this.setState({ roleMenus: list })
        }
    }
    addLocation() {
        if (this.state.location) {
            let list = this.state.roleLocations.slice();
            console.log(JSON.stringify(this.state.locations[this.state.location]))
            list.push({ id: 0, locationName: this.state.locations[this.state.location].name, locationId: this.state.locations[this.state.location].id, activeStatus: 1 });
            this.setState({ roleLocations: list })
        }
    }
    setRowValue(index, e, name) {
        let list = this.state.roleMenus.slice();
        switch (name) {
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ roleMenus: list })
    }
    setRowValue1(index, e, name) {
        let list = this.state.roleLocations.slice();
        switch (name) {
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ roleLocations: list })
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add Role" ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                         <PaginationEle ref={this.paginationEleRef} titles={this.state.columns}  />
                        <div className="page-popup page-popup-overlay" id="roleAction">
                            <form  >
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Role Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Name</span>
                                                    <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Status</span>
                                                    <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                        <option value="1" >Active</option>
                                                        <option value="0" >Deactivate</option>
                                                    </select></div>
                                            </div></div>
                                            <div className="row">
                                                <div className="col">  <div className="input-group"><span className="input-group-text">Dashboard</span>
                                               <select className="form-control" value={this.state.dashboard} onChange={e => { this.setState({ dashboard: e.target.value }) }}  >
                                                        <option value="DEFAULT" >DEFAULT</option>
                                                        <option value="STOCK" >STOCK</option>
                                                    </select></div></div>
                                                <div className="col"></div>
                                            </div>
                                        <div className="card card-green">
                                            <div className="card-header">Menus</div>
                                            <div className="card-body">                                       
                                             <table className="table table-striped" >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                </tr>
                                                <tr>
                                                    <td> <select className="form-control" value={this.state.menu} onChange={e => { this.setState({ menu: e.target.value }) }}  >
                                                        <option value="0">Choose Menu</option>
                                                        {this.state.menus.map((menu, index) => (
                                                            <option key={index} value={index} >{menu.name}</option>
                                                        ))}
                                                    </select></td>
                                                    <td><button type="button" className="btn btn-success" title="Insert Role" onClick={() => { this.addMenu() }}  ><i className="bi bi-plus-square"></i></button></td>
                                                </tr>
                                                <tbody>
                                                    {this.state.roleMenus.map((roleMenu, index) => (
                                                        <tr>
                                                            <td>{roleMenu.menuName}</td>
                                                            <td><select className="form-control" value={roleMenu.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select></td>
                                                        </tr>
                                                    ))}   </tbody>
                                            </table>   </div></div>
                                        <div className="card card-green">
                                            <div className="card-header">Location</div>
                                            <div className="card-body">                                       
                                             <table className="table table-striped" >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                </tr>
                                                <tr>
                                                    <td> <select className="form-control" value={this.state.location} onChange={e => { this.setState({ location: e.target.value }) }}  >
                                                        <option value="0">Choose Location</option>
                                                        {this.state.locations.map((menu, index) => (
                                                            <option key={index} value={index} >{menu.name}</option>
                                                        ))}
                                                    </select></td>
                                                    <td><button type="button" className="btn btn-success" title="Insert Role" onClick={() => { this.addLocation() }}  ><i className="bi bi-plus-square"></i></button></td>
                                                </tr>
                                                <tbody>
                                                    {this.state.roleLocations.map((item, index) => (
                                                        <tr>
                                                            <td>{item.locationName}</td>
                                                            <td><select className="form-control" value={item.activeStatus} onChange={e => { this.setRowValue1(index, e, "activeStatus") }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select></td>
                                                        </tr>
                                                    ))}   </tbody>
                                            </table>   </div></div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveRole()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                            </form>
                        </div>
                    </div>
                </div></div>
        )

    }
}    