import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PaginationEle from "../pagination";


export default class Service extends Component {
    state = { id: 0, name: '', printName: '', tariffCode: '', serviceType: '', serviceClass: '', sampleTypeId: '', departmentId: '', activeStatus: 1, list: [], serviceTypes: [], sampleTypes: [], serviceClasss: [], departments: [], serviceItems: [],serviceParameters: [], parameters: [] , columns: [

        {
            label: 'Action',
            field: 'action'
        },
        {
            label: 'Name',
            field: 'name'
        }, {
            label: 'Tariff Code',
            field: 'tariffCode'
        }, {
            label: 'Service Type',
            field: 'serviceType'
        }, {
            label: 'Service Class',
            field: 'serviceClass'
        },
        {
            label: 'Action Status',
            field: 'stringStatus'
        }
    ]}
        constructor(props) {
            super(props);
           this.paginationEleRef = React.createRef()
        }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', printName: '', tariffCode: '', serviceType: '', serviceClass: '', sampleTypeId: '', departmentId: '', activeStatus: 1,serviceParameters: [], parameters: [] })
                   axios.get(localStorage.getItem('host') + '/master/serviceaction/' + id + '/' + localStorage.getItem('companyId')+'/' + localStorage.getItem('speciality'), {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, printName: res.data.printName, tariffCode: res.data.tariffCode, serviceType: res.data.serviceType, serviceClass: res.data.serviceClass, sampleTypeId: res.data.sampleTypeId, departmentId: res.data.departmentId, activeStatus: res.data.activeStatus, serviceTypes: res.data.serviceTypes, sampleTypes: res.data.sampleTypes, serviceClasss: res.data.serviceClasss, departments: res.data.departments, serviceItems: res.data.serviceItems,serviceParameters: res.data.serviceParameters, parameters: res.data.parameters });
                }, err => {
                    console.log(".....")
                }
            )
        
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, printName: this.state.printName, tariffCode: this.state.tariffCode, serviceType: this.state.serviceType, serviceClass: this.state.serviceClass, sampleTypeId: this.state.sampleTypeId, departmentId: this.state.departmentId, name: this.state.name, speciality: localStorage.getItem('speciality'), serviceItems: this.state.serviceItems,serviceParameters: this.state.serviceParameters }
        axios.post(localStorage.getItem('host') + '/master/serviceform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    add()
      {
        let list = this.state.serviceItems.slice();
        list.push({id:0, serviceId: '', serviceOrder: '', quantity: 1, activeStatus: 1});
        this.setState({serviceItems:list })
      }
    setRowValue(index, e, name) {
        let list = this.state.serviceItems.slice();
        switch (name) {
         case "serviceId":
                        list[index].serviceId = e.target.value;
                        break;
         case "quantity":
                        list[index].quantity = e.target.value;
                        break;
         case "serviceOrder":
                        list[index].serviceOrder = e.target.value;
                        break;
         case "activeStatus":
                        list[index].activeStatus = e.target.value;
                        break;
               default:
                       console.log("default")
                        break;
                      }       
        this.setState({ serviceItems: list })
      }

    addParam()
    {
      let list = this.state.serviceParameters.slice();
      list.push({id:0, parameterId: '', serviceOrder: '',  activeStatus: 1});
      this.setState({serviceParameters:list })
    }
  setParaRowValue(index, e, name) {
      let list = this.state.serviceParameters.slice();
      switch (name) {
       case "parameterId":
                      list[index].parameterId = e.target.value;
                      break;
       case "serviceOrder":
                      list[index].serviceOrder = e.target.value;
                      break;
       case "activeStatus":
                      list[index].activeStatus = e.target.value;
                      break;
             default:
                     console.log("default")
                      break;
                    }       
      this.setState({ serviceParameters: list })
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/service/' + localStorage.getItem('speciality'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.paginationEleRef.current.update(res.data)
            }, err => {
                console.log(".....")
            }
        )
    }


    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                         <PaginationEle ref={this.paginationEleRef} titles={this.state.columns}  />
                        <div className="page-popup page-popup-overlay" id="action">
                            <form  >
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Service Type</span>
                                                <select className="form-control" value={this.state.serviceType} onChange={e => this.setState({ serviceType: e.target.value })}  >
                                                    <option value="0">Choose Service Type</option>
                                                    {this.state.serviceTypes.map((serviceType, index) => (
                                                        <option key={index} value={serviceType} >{serviceType}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Tariff Code</span>
                                                <input type="text" className="form-control" value={this.state.tariffCode} onChange={e => { this.setState({ tariffCode: e.target.value }) }} /></div>
                                                </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Service Class</span>
                                                <select className="form-control" value={this.state.serviceClass} onChange={e => this.setState({ serviceClass: e.target.value })}  >
                                                    <option value="0">Choose Service Class</option>
                                                    {this.state.serviceClasss.map((serviceClass, index) => (
                                                        <option key={index} value={serviceClass} >{serviceClass}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Sample Type</span>
                                                <select className="form-control" value={this.state.sampleTypeId} onChange={e => this.setState({ sampleTypeId: e.target.value })}  >
                                                    <option value="0">Choose Sample Type</option>
                                                    {this.state.sampleTypes.map((sampleType, index) => (
                                                        <option key={index} value={sampleType.id} >{sampleType.name}</option>
                                                    ))}
                                                </select></div>
                                                </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Department</span>
                                                <select className="form-control" value={this.state.departmentId} onChange={e => this.setState({ departmentId: e.target.value })}  >
                                                    <option value="0">Choose Department</option>
                                                    {this.state.departments.map((department, index) => (
                                                        <option key={index} value={department.id} >{department.name}</option>
                                                    ))}
                                                </select></div>
                                                </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Print Name</span>
                                             <input type="text" className="form-control" value={this.state.printName} onChange={e => { this.setState({ printName: e.target.value }) }} /></div></div>
                                        </div> 
                                        <div className="card card-green">
                                            <div className="card-header">Service</div>
                                            <div className="card-body"> 
                                            <button type="button" className="btn btn-success" title="Insert Role" onClick={() => { this.add() }}  ><i className="bi bi-plus-square"></i></button>                                       
                                            <table className="table table-striped" >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Quantity</th>
                                                    <th>Order</th>
                                                    <th>Status</th>
                                                </tr>
                                                <tbody>  
                                                {this.state.serviceItems.map((serviceItem, index) => (
                                                    <tr>
                                                        <td><select className="form-control" value={serviceItem.serviceId} onChange={e => { this.setRowValue(index, e, "serviceId") }}  >
                                                            <option value="0">Choose Service</option>
                                                            {this.state.list.map((service, index) => (
                                                                <option key={index} value={service.id} >{service.name}</option>
                                                            ))}
                                                        </select></td>
                                                        <td><input type="text" className="form-control" value={serviceItem.quantity} onChange={e => { this.setRowValue(index, e, "quantity") }} /></td>
                                                        <td><input type="text" className="form-control" value={serviceItem.serviceOrder} onChange={e => { this.setRowValue(index, e, "serviceOrder") }} /></td>
                                                        <td><select className="form-control" value={serviceItem.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                                            <option value="1" >Active</option>
                                                            <option value="0" >Disable</option>
                                                        </select></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>   </div></div>
                                        <div className="card card-green">
                                            <div className="card-header">Parameters</div>
                                            <div className="card-body"> 
                                            <button type="button" className="btn btn-success" title="Insert Role" onClick={() => { this.addParam() }}  ><i className="bi bi-plus-square"></i></button>                                       
                                            <table className="table table-striped" >
                                                <tr>
                                                    <th>Parameter</th>
                                                    <th>Order</th>
                                                    <th>Status</th>
                                                </tr>
                                                <tbody>  
                                                {this.state.serviceParameters.map((serviceParameter, index) => (
                                                    <tr>
                                                        <td><select className="form-control" value={serviceParameter.parameterId} onChange={e => { this.setParaRowValue(index, e, "parameterId") }}  >
                                                            <option value="0">Choose Parameter</option>
                                                            {this.state.parameters.map((para, index) => (
                                                                <option key={index} value={para.id} >{para.name}</option>
                                                            ))}
                                                        </select></td>
                                                        <td><input type="text" className="form-control" value={serviceParameter.serviceOrder} onChange={e => { this.setParaRowValue(index, e, "serviceOrder") }} /></td>
                                                        <td><select className="form-control" value={serviceParameter.activeStatus} onChange={e => { this.setParaRowValue(index, e, "activeStatus") }}  >
                                                            <option value="1" >Active</option>
                                                            <option value="0" >Disable</option>
                                                        </select></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>   </div></div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                            </form>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    