import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import fileDownload from 'js-file-download'
import MyDateTimePicker from "../mydatetimepicker";


const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Export extends Component {

    state = { loader: false, id: 0, exportName:'', exportType: '', rows: 0, filters: [], list: [], name: '', dataList:[], dataResult: '', startDate: date1+' 00:00:00', endDate: date1+' 23:59:59' }

    componentDidMount() {

        this.getList()
    }
    getList() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = Number(params.get('id'));

        axios.get(localStorage.getItem('host') + '/interchange/getexportview/' + id+'/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({  filters: res.data.efilters, id: id, exportName: res.data.name, exportType: res.data.exportType });
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    getExportData() {
        this.setLoader(true)
        axios.post(localStorage.getItem('host') + '/interchange/getexportdata/'+localStorage.getItem('companyId'), this.getParameterData(""), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if(res.data.error)
                   {
                    this.setState({error: res.data.error})
                   }
                else
                   {   
                    this.setState({dataList: res.data.dataList, dataResult: res.data.dataResult})
                   }
                   this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
                this.setLoader(false)
            }
        )
    }
    download(exportType) {
        axios.post(localStorage.getItem('host') + '/report/exportreportdata', this.getParameterData(exportType), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )

    }
    getParameterData(exportType) {
        var parameter1 = "";
        var parameter2 = "";
        var parameter3 = "";
        var parameter4 = "";
        var parameter5 = "";
        var parameter6 = "";
        var parameter7 = "";
        if (document.getElementById("parameter1") instanceof Object && document.getElementById("parameter1") !== undefined) {
            parameter1 = document.getElementById("parameter1").value;
        }
        if (document.getElementById("parameter2") instanceof Object && document.getElementById("parameter2") !== undefined) {
            parameter2 = document.getElementById("parameter2").value;
        }
        if (document.getElementById("parameter3") instanceof Object && document.getElementById("parameter3") !== undefined) {
            parameter3 = document.getElementById("parameter3").value;
        }
        if (document.getElementById("parameter4") instanceof Object && document.getElementById("parameter4") !== undefined) {
            parameter4 = document.getElementById("parameter4").value;
        }
        if (document.getElementById("parameter5") instanceof Object && document.getElementById("parameter5") !== undefined) {
            parameter5 = document.getElementById("parameter5").value;
        }
        if (document.getElementById("parameter6") instanceof Object && document.getElementById("parameter6") !== undefined) {
            parameter6 = document.getElementById("parameter6").value;
        }
        if (document.getElementById("parameter7") instanceof Object && document.getElementById("parameter7") !== undefined) {
            parameter7 = document.getElementById("parameter7").value;
        }
        return { companyId: localStorage.getItem('companyId'), roleId: localStorage.getItem('roleId'), userId: localStorage.getItem('userId'), speciality: localStorage.getItem('speciality'),  parameter1: parameter1, parameter2: parameter2, parameter3: parameter3, parameter4: parameter4, parameter5: parameter5, parameter6: parameter6, parameter7: parameter7 , id: this.state.id, exportType: exportType }
    }
    viewDownloadList() {
        this.getScheduleList()
        setInterval(() => {
            this.getScheduleList();
        }, 1000 * 20)
        document.querySelector('#downloadAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#downloadAction').style = 'display:none'
        console.log('......close');
    }
    downloadExcel() {
       
       axios.get(localStorage.getItem('host') + '/interchange/exportexcel/' + this.state.id+'/'+this.state.exportName+'/'+this.state.dataResult+'/'+localStorage.getItem('companyId'), { responseType: 'blob', 
       headers: {
           'Authorization': `Bearer ${localStorage.getItem('token')}`
       }})
      .then((res) => {
        fileDownload(res.data, this.state.exportName+".xlsx")
      })
    }
    downloadPdf() {
       
       axios.get(localStorage.getItem('host') + '/interchange/pdfview/' + this.state.id+'/'+this.state.exportName+'/'+this.state.dataResult+'/'+localStorage.getItem('companyId'), { responseType: 'blob', 
       headers: {
           'Authorization': `Bearer ${localStorage.getItem('token')}`
       }})
      .then((res) => {
        fileDownload(res.data, this.state.exportName+".pdf")
      })
    }
    downloadCsv() {
       
       axios.get(localStorage.getItem('host') + '/interchange/exportcvs/' + this.state.id+'/'+this.state.exportName+'/'+this.state.dataResult+'/'+localStorage.getItem('companyId'), { responseType: 'blob', 
       headers: {
           'Authorization': `Bearer ${localStorage.getItem('token')}`
       }})
      .then((res) => {
        fileDownload(res.data, this.state.exportName+".csv")
      })
    }
    getScheduleList() {
        console.log("fetching .......")
        axios.get(localStorage.getItem('host') + '/report/getreportschedulelist', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    processDates(startDate, endDate)
       {
        const now = new Date(startDate);
        const now1 = new Date(endDate);
        var date1 = now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate())+' '+checkZero(now.getHours())+':'+checkZero(now.getMinutes())+':'+checkZero(now.getMinutes());
        var date2 = now1.getFullYear() + '-' + checkZero(now1.getMonth() + 1) + '-' + checkZero(now1.getDate())+' '+checkZero(now1.getHours())+':'+checkZero(now1.getMinutes())+':'+checkZero(now1.getMinutes());
        return date1+','+date2 ;
       } 
       setLoader(loader)
       {
        this.setState({loader: loader})
       }
    render() {
        let download = ''
        if (this.state.dataList > 0) {
            download = (<div className='row'><div className="col"><span className="link" style={{backgroundColor: '#36BEEE', color: '#FFF', fontSize: 2+'em', paddingTop: 10}} title="Download Excel" onClick={() => { this.downloadExcel(); }} ><i class="bi bi-file-earmark-spreadsheet-fill"></i></span></div>
                <div className="col"><span className="link" style={{backgroundColor: '#36BEEE', color: '#FFF', fontSize: 2+'em', paddingTop: 10}} title="Download Pdf" onClick={() => { this.downloadPdf(); }} ><i class="bi bi-file-earmark-pdf-fill"></i></span></div>
                <div className="col"><span className="link" style={{backgroundColor: '#36BEEE', color: '#FFF', fontSize: 2+'em', paddingTop: 10}} title="Download Csv" onClick={() => { this.downloadCsv(); }} ><i class="bi bi-filetype-csv"></i></span></div>
             </div>)
        }
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />

                <div className="container-fluid">
                        <div className="row">
                        <div className="col"><h3 className="text-dark">Export</h3></div>
                         </div>
                       <div className="card">
                        <div className='row'>
                       
                            {this.state.filters.map((filter, index) => (
                                <div className="col" key={index}  >
                                   
                                 { filter.filterType === 'DATE_RANGE' ? 
                                ( <div className="row"><div className="col"> 
                                  <div className="input-group"><span className="input-group-text" dangerouslySetInnerHTML={{ __html: filter.label1 }}></span> 
                                                                       <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                    <MyDateTimePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d}); document.getElementById(filter.parameterName).value = d+','+this.state.endDate   }} />
                                                                    </div></div>
                                                                    <div className="col"> 
                                                                    <div className="input-group"><span className="input-group-text" dangerouslySetInnerHTML={{ __html: filter.label2 }}></span>    <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                   <MyDateTimePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> { this.setState({endDate: d}); document.getElementById(filter.parameterName).value = this.state.startDate+','+d }} /></div>
                                                                   <div dangerouslySetInnerHTML={{ __html: filter.inputField }} />
                                 </div></div>):  <div className="input-group"> <span className="input-group-text" dangerouslySetInnerHTML={{ __html: filter.label }}></span><div dangerouslySetInnerHTML={{ __html: filter.inputField }} /></div>}
                                 </div>

                            ))}
                            <div className="col"><button className="btn btn-success" onClick={() => this.getExportData()} ><i class="bi bi-search"></i></button></div>
                        </div>
                        </div> 
                        <br />
                        {download}
                        {divMsg}
                       {loaderDiv}
                    </div>
                </div></div>
        )

    }
}    