import React from "react"
import { Navigate, useLocation } from "react-router-dom"
const PrivateElement = ({ children }) => {
    let location = useLocation()
   // console.log("location ........."+JSON.stringify(location))
    let links = localStorage.getItem('links')?localStorage.getItem('links'):"/dashboard"
    return localStorage.getItem('token')? ( links.includes(location.pathname)?
      children: <Navigate to="/dashboard" state={{ from: location }} />
    ) : (
      <Navigate to="/" state={{ from: location }} />
    )
  }
  
  export default PrivateElement