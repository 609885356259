import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import MyDatePicker from "../mydatepicker";
const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
let date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
// disabled={this.state.appointmentTimeId > 0 && item.id !==  this.state.appointmentTimeId? true:null}
export default class AppointmentAction extends Component {
 
    state = { id: 0, firstname: '', firstnameError: '', surname: '', surnameError: '', gender: '', consultantId: 0, genderError: '', dateOfBirthError: '', appointmentDate: date1, appointmentDateError: '', companyId: 0, companyIdError: '', patientId: 0, appointmentTimeId: '', appointmentTimeName:'', appointmentTimeIdError: '', cellNumber: '', cellNumberError: '', whatsappNumber: '', whatsappNumberError: '', activeStatus: 1, appointmentTimes: [], companys: [], genders: [], showSubmit: false, appointmentTypeId: 0, appointmentTypes: [], consultantIdError: '', consultants: [] }
   
    openAppointmentAction(appointmentId, patientId, companyId, consultantId, appointmentTimeId, appointmentDate) {
      
        this.setState({ id: 0, consultantId: consultantId, firstname: '', firstnameError: '', surname: '', surnameError: '', gender: '', genderError: '', dateOfBirthError: '', appointmentDate: date1, appointmentDateError: '', companyId: companyId, companyIdError: '', patientId: patientId, appointmentTimeId: appointmentTimeId, appointmentTimeName: '', appointmentTimeIdError: '', cellNumber: '', whatsappNumber: '', whatsappNumberError: '', cellNumberError: '', activeStatus: 1, appointmentTimes: [], companys: [], genders: [], showSubmit: false, msg: '', error: '', appointmentTypeId: 0, appointmentTypes: [] })
        axios.get(localStorage.getItem('host') + '/patient/getappointment/' + appointmentId + '/' + patientId + '/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => { 
                var appointmentTimes = []
                var appointmentTypes = []
                var consultants = []
                if(companyId > 0)
                    {
                        for(var i in res.data.companys)
                        {
                        if(res.data.companys[i].id === Number(companyId)) 
                        {
                          appointmentTimes  = res.data.companys[i].appointmentTimes   
                          appointmentTypes = res.data.companys[i].appointmentTypes
                          consultants = res.data.companys[i].consultants
                        }
    
                        }
                        
                    }
                this.setState({ id: res.data.id, firstname: res.data.firstname, surname: res.data.surname, companyId: (appointmentId === 0 && companyId !== ""?companyId:res.data.companyId), gender: res.data.gender, appointmentDate: (appointmentId === 0 && appointmentDate !== ""?appointmentDate:res.data.appointmentDate), patientId: res.data.patientId, appointmentTimeId: (appointmentId === 0 && appointmentTimeId > 0?appointmentTimeId:res.data.appointmentTimeId), cellNumber: res.data.cellNumber,  companys: res.data.companys, genders: res.data.genders, appointmentTimes:appointmentTimes, consultants: consultants, appointmentTypeId: res.data.appointmentTypeId, appointmentTypes:appointmentTypes, whatsappNumber: res.data.whatsappNumber  });
             
                this.doValidate()
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    doValidate()
      {
        setTimeout(() => {
            this.validatePatient();
        }, 1000);   
      }
    validatePatient() {
       
        var error = false;
        if (this.state.firstname === null) {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            error = true
        }
        else if (this.state.firstname === "") {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.firstname)))
            {
            this.setState({ firstnameError: "Firstname should contain characters only and no spaces"})
            error = true
            }
        else {
            this.setState({ firstnameError: "" })
        }

        if (this.state.surname === null) {
            this.setState({ surnameError: "Surname cannot be empty" })
            error = true
        }
        else if (this.state.surname === "") {
            this.setState({ surnameError: "Surname cannot be empty" })
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.surname)))
            {
            this.setState({ surnameError: "Surname should contain characters only and no spaces"})
            error = true
            }
        else {
            this.setState({ surnameError: "" })
        }

        
        if (this.state.gender === null) {
            this.setState({ genderError: "Gender cannot be empty" })
            error = true
        }
        else if (this.state.gender === "") {
            this.setState({ genderError: "Gender cannot be empty" })
            error = true
        }
        else {
            this.setState({ genderError: "" })
        } 

            
        if (this.state.appointmentDate === null || this.state.appointmentDate === "") {
            this.setState({ appointmentDateError: "Appointment Date cannot be empty" })
            error = true
        }
        else if(!(/^(\d{4})-(\d{2})-(\d{2})$/.test(this.state.appointmentDate)))
            { 
            this.setState({ appointmentDateError: "Appointment Date wrong format"})
            error = true
            }
        else {
            this.setState({ appointmentDateError: "" })
        }

        if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" })
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" })
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }


        if (this.state.appointmentTimeId === 0) {
            this.setState({ appointmentTimeIdError: "Appointment Time cannot be empty" })
            error = true
        }
        else if (this.state.appointmentTimeId === "") {
            this.setState({ appointmentTimeIdError: "Appointment Time cannot be empty" })
            error = true
        }
        else {
            this.setState({ appointmentTimeIdError: "" })
        }

        

        if (this.state.cellNumber === null) {
            this.setState({ cellNumberError: "Mobile number cannot be empty" })
            error = true
        }
        else if(!(/^(2637)\d{8}$/.test(this.state.cellNumber)))
            {
            this.setState({ cellNumberError: "Please numbers only should not start with zero like 263772747211"})
            error = true
            }
        else if (this.state.cellNumber === "") {
            this.setState({ cellNumberError: "Mobile number cannot be empty" })
            error = true
        }
        else {
            this.setState({ cellNumberError: "" })
        }


         if(!(/^(2637)\d{8}$/.test(this.state.whatsappNumber)) && this.state.whatsappNumber !== "" && this.state.whatsappNumber !== null)
            {
            this.setState({ whatsappNumberError: "Whatsapp, please numbers only should not start with zero like 263772747211"})
            error = true
            }
        else {
            this.setState({ whatsappNumberError: "" })
        }
        console.log(" error"+error)
     
        if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }   
    }
    setRecordType(index, e) {
        let list = this.state.patientRecords.slice();
        list[index].recordType = e.target.value;
        this.setState({ patientRecords: list })
    }
    save() {
        this.props.setLoader(true)
        var data = { id: this.state.id, firstname: this.state.firstname, surname: this.state.surname, gender: this.state.gender, consultantId: this.state.consultantId, appointmentDate: this.state.appointmentDate, patientId: this.state.patientId, appointmentTimeId: this.state.appointmentTimeId, cellNumber: this.state.cellNumber, companyId: this.state.companyId, activeStatus: 1, appointmentTypeId: this.state.appointmentTypeId, remarks: this.state.remarks, whatsappNumber: this.state.whatsappNumber}
        axios.post(localStorage.getItem('host') + '/patient/appointmentsave',data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                if(res.data.error)
                {
                  this.setState({ msg: res.data });  
                }
                else
                {
                  this.props.setMessage(res.data.msg)  
                  this.closePopup();
                }
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('appointmentAction').style = 'display:none'
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
        

      }
      getAppointmentTimes(id)
       {
        this.setState({appointmentTimes: [], companyId: id, appointmentTimeId: '', appointmentTypes: [], appointmentTypeId: 0})
        if(id !== "")  
          { 
                  for(var i in this.state.companys)
                      {
                      if(this.state.companys[i].id === Number(id)) 
                      {
                          this.setState({appointmentTimes: this.state.companys[i].appointmentTimes, appointmentTypes: this.state.companys[i].appointmentTypes, consultants: this.state.companys[i].consultants})   
                      }
  
                      }
              }
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="appointmentAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info">Appointment Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Firstname </span>
                        <input type="text" className={this.state.firstnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.firstname} onChange={(e) => {this.setState({ firstname: e.target.value }); this.doValidate()}} />
                        <div className="invalid-feedback">{this.state.firstnameError}</div></div></div>
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Surname</span>
                        <input type="text" className={this.state.surnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.surname} onChange={(e) => {this.setState({ surname: e.target.value }); this.doValidate()}} />
                        <div className="invalid-feedback">{this.state.surnameError}</div></div></div>
                </div>
                <div className="row">
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Gender </span> <select className={this.state.genderError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.gender} onChange={e => { this.setState({ gender: e.target.value }); this.doValidate()} } >
                        <option value="">Choose Gender</option>
                        {this.state.genders.map((item, index) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </select> <div className="invalid-feedback">{this.state.genderError}</div></div></div>
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Type </span> <select className="form-control" value={this.state.appointmentTypeId} onChange={e => { this.setState({ appointmentTypeId: e.target.value }); this.doValidate()} } >
                        <option value="">Choose Type</option>
                        {this.state.appointmentTypes.map((item, index) => (
                            <option value={item.id} key={index}>{item.name}</option>
                        ))}
                    </select> </div></div>
                </div>
                <div className="row">
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Company </span> <select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={(e) => {this.getAppointmentTimes(e.target.value ); this.doValidate()}}>
                        <option value="">Choose Company</option>
                        {this.state.companys.map((item, index) => (
                            <option value={item.id} key={index}>{item.name}</option>
                        ))}
                    </select> <div className="invalid-feedback">{this.state.companyIdError}</div></div></div>
                    <div className="col"><div className="input-group has-validation">
                        <span className="input-group-text">Appointment Date (yyyy-mm-dd)</span>
                         <input type="text" className={this.state.appointmentDateError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.appointmentDate} onChange={e => { this.setState({ appointmentDate: e.target.value }) }} /> 
                                                              <MyDatePicker  value={this.state.appointmentDate} maxDate={ date1} onChange={(d)=> {  this.setState({appointmentDate: d}); }} />
                        <div className="invalid-feedback">{this.state.appointmentDateError}</div></div></div>
                </div>
                { (this.state.consultantId === 0 || this.state.consultantId === '') &&
                <div className="row">
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Consultant </span> <select className={this.state.consultantIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.consultantId} onChange={(e) => {this.setState({ consultantId: e.target.value }); this.doValidate()}}>
                        <option value="">All Consultants</option>
                        {this.state.consultants.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select> <div className="invalid-feedback">{this.state.consultantIdError}</div></div></div>
                    <div className="col"></div>
                </div>}
                <div className="row">
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Time </span> <select className={this.state.appointmentTimeIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.appointmentTimeId} onChange={(e) => {this.setState({ appointmentTimeId: e.target.value }); this.doValidate()}}>
                        <option value="">Choose Time</option>
                        {this.state.appointmentTimes.map((item, index) => (
                            <option value={item.id} key={index} disabled={this.state.appointmentTimeId > 0  && this.state.id === 0 && item.id !==  this.state.appointmentTimeId? true:null}>{item.name}</option>
                        ))}
                    </select> <div className="invalid-feedback">{this.state.appointmentTimeIdError}</div></div></div>
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Mobile Number (2637XXXXXXXX)</span>
                        <input type="text" className={this.state.cellNumberError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.cellNumber} onChange={(e) => {this.setState({ cellNumber: e.target.value }); this.doValidate()}} />
                        <div className="invalid-feedback">{this.state.cellNumberError}</div></div></div>
                </div>
                <div className="row">
                    <div className="col"><div className="input-group">
                        <span className="input-group-text">Remarks</span>
                        <textarea className="form-control" value={this.state.remarks} onChange={e => { this.setState({ remarks: e.target.value }); this.doValidate()} } ></textarea>
                    </div></div>
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Whatsapp Number (2637XXXXXXXX)</span>
                        <input type="text" className={this.state.whatsappNumberError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.whatsappNumber} onChange={(e) => {this.setState({ whatsappNumber: e.target.value }); this.doValidate()}} />
                        <div className="invalid-feedback">{this.state.whatsappNumberError}</div></div></div>
                </div>
                <div className="row">
                    <div className="col-lg-6" align="center">{this.state.showSubmit === true ? (<button onClick={() => this.save()} className="btn btn-primary">Save</button>) : (<span></span>)}</div>
                    <div className="col-lg-6" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close Window (Esc)</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      