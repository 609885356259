import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDateTimePicker from "../mydatetimepicker";

const now = new Date();
var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class QuotationAction extends Component {
     state = { billType: '', clusterCompanys: [], billCategorys: [], users: [], quotationItems: [], companys: [], genders: [], locations: [], showSubmit: false, currencys: []
       , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, patientInsurances: [], 
       companyId: '', companyIdError: '', billCategory: '', billCategoryError: '', consultantName: '', consultantNameError: '', patientName: '', patientNameError: ''
        , patientInsuranceId: '', patientInsuranceIdError: '', specialContractId: '', specialContractIdError: '', currencyId: '', currencyIdError: '', treatmentDateTime: date1, treatmentDateTimeError: ''
    }
    openQuotationAction() {
        document.getElementById('quotationAction').style = 'display:block'
        this.setState({ id: 0, firstname: '', firstnameError: '', surname: '', surnameError: '', gender: '', genderError: '', dateOfBirth: '1970-01-01', dateOfBirthError: '', quotationDate: date1, quotationDateError: '', companyId: 0, companyIdError: '',  quotationTimeId: '', quotationTimeIdError: '', cellNumber: '', cellNumberError: '', activeStatus: 1, quotationTimes: [], companys: [], genders: [], showSubmit: false, msg: '', error: '' })
        axios.get(localStorage.getItem('host') + '/patient/quotationaction/'+ localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ clusterCompanys: res.data.clusterCompanys, billCategorys: res.data.billCategorys, users: [], quotationItems: [], companys: [], genders: [], locations: [], currencys: []
                    , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, treatmentDateTime: date1  });
                this.validate()
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    doValidate()
      {
        setTimeout(() => {
            this.validate();
        }, 1000);   
      }
      validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }

        if (this.state.billCategory === null) {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else if (this.state.billCategory === "") {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else {
            this.setState({ billCategoryError: "" })
        }


        if (this.state.consultantName === null) {
            this.setState({ consultantNameError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantName === 0) {
            this.setState({ consultantNameError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantName === "") {
            this.setState({ consultantNameError: "Consultant cannot be empty" })
            error = true
        }
        else {
            this.setState({ consultantNameError: "" })
        }
        if (this.state.billCategory === 'CASH') {
            if (this.state.currencyId === null) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === 0) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === "") {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else {
                this.setState({ currencyIdError: "" })
            }
        }
        if (this.state.billCategory === 'INSURANCE') {
            if (this.state.patientInsuranceId === null) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === 0) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === "") {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else {
                this.setState({ patientInsuranceIdError: "" })
            }
        }
        if (this.state.billCategory === 'SPECIAL_CONTRACT') {
            if (this.state.specialContractId === null) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === 0) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === "") {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else {
                this.setState({ specialContractIdError: "" })
            }
        }
             if (this.state.patientName === null) {
                this.setState({ patientNameError: "Patient Name cannot be empty" })
                error = true
            }
            else if (this.state.patientName === 0) {
                this.setState({ patientNameError: "Patient Name cannot be empty" })
                error = true
            }
            else if (this.state.patientName === "") {
                this.setState({ patientNameError: "Patient Name cannot be empty" })
                error = true
            }
            else {
                this.setState({ patientNameError: "" })
            }
        var quotationItems = this.state.quotationItems;
        if (quotationItems === null) {
            this.setState({ quotationItemsError: "Quotation Items cannot be empty" })
            error = true
        }
        else if (quotationItems.length === 0) {
            this.setState({ quotationItemsError: "Quotation Items cannot be empty" })
            error = true
        }
        else {
            
             for(var i in quotationItems)
                {
                 if(quotationItems[i].allowEdit)
                   {
                    if (quotationItems[i].unitCost === null) {
                        quotationItems[i].unitCostError =  "Unit Cost cannot be empty"
                        error = true
                    }
                    else if (quotationItems[i].unitCost === "") {
                        quotationItems[i].unitCostError =  "Unit Cost cannot be empty"
                        error = true
                    }
                    else if (quotationItems[i].unitCost > quotationItems[i].max) {
                        quotationItems[i].unitCostError =  "Unit Cost cannot be greater than "+quotationItems[i].max
                        error = true
                    }
                    else if (quotationItems[i].unitCost < quotationItems[i].min) {
                        quotationItems[i].unitCostError =  "Unit Cost cannot be less than "+quotationItems[i].min
                        error = true
                    }
                    else {
                        quotationItems[i].unitCostError =  "" 
                    }


                   }
              this.setState({ quotationItemsError: "", quotationItems: quotationItems })   
                }
        }
        console.log(" error" + error)
        if(this.state.allowSave === false)
          {
            this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
          }
        else  if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    setRecordType(index, e) {
        let list = this.state.patientRecords.slice();
        list[index].recordType = e.target.value;
        this.setState({ patientRecords: list })
    }
    save() {
        this.props.setLoader(true)
        var quotationItems = this.state.quotationItems
        for(var i in this.state.quotationItems)
           {
            quotationItems[i].startDate = this.processDateTime(quotationItems[i].startDate)
            quotationItems[i].endDate = this.processDateTime(quotationItems[i].endDate)
           }
        var data = { patientName: this.state.patientName, quotationItems: quotationItems, billType: this.state.billType, treatmentDateTime: this.state.treatmentDateTime, consultantName: this.state.consultantName
        , companyId: this.state.companyId, currencyId: this.state.currencyId, rate: this.state.rate
        ,  cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable}
        axios.post(localStorage.getItem('host') + '/patient/quotationsave',data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                if(res.data.error)
                {
                  this.setState({ error: res.data });  
                }
                else
                {  
                  this.closePopup();
                  this.props.getQuotationList(res.data.msg)
                }
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('quotationAction').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }

    addServices()
       {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
        if(this.state.serviceCostId.value > 0)
           {  console.log('......... service cost id '+this.state.serviceCostId.value) 
            const now = new Date();
            var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
            let list = this.state.quotationItems.slice();
            var s = '';
            for (var i in this.state.services) {
                if (this.state.services[i].id === Number(this.state.serviceCostId.value)) {
                    s = this.state.services[i];
                }
            }
            if(this.state.cashOnly === 1 || this.state.cashOnly === "1")
               {
                s.cashOnly = 1;
               }
            list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
            , tariffCode: s.tariffCode, startDate: date1, endDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: (Number(s.fixedCost) - this.getMargin(s)), max: (Number(s.fixedCost) + this.getMargin(s)), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0, unitCostError: ''});
                for (var index in s.services)
                {
                    var ss = s.services[index];
                    list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                        , tariffCode: ss.tariffCode, startDate: date1, endDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, min: (Number(ss.fixedCost) - this.getMargin(ss)), max: (Number(ss.fixedCost) + this.getMargin(ss)), cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0, unitCostError: ''});
                }
            this.setState({ quotationItems: list, cashOnly: 0 });
            this.calcBill(list)
            this.doValidate()
           }
       }  
    getMargin(service)
       {
           return Math.ceil(service.fixedCost * (service.margin / 100));
       }   
    setBillItem(name, index, value)   
       {
        var list = this.state.quotationItems;   
       switch(name)
          {    
             case "startDate":
                list[index].startDate = value
                break;
            case "endDate":
                list[index].endDate = value
                break;   
            case "quantity":
                list[index].quantity = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "unitCost":
                list[index].unitCost = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "instruction":
                list[index].instruction = value
                break;     
            case "authoAmount":
                list[index].authoAmount = value 
                this.calcQuantity(index, list[index].quantity)
                break;  
             default: 
                     console.log("....")
                     break;   
          }
      this.setState({ quotationItems: list })    
      this.doValidate() 
       }
    calcBill(list)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            var authoAmount = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                authoAmount += Number(list[index].authoAmount);
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            if (this.state.authorisationNumber !== null && this.state.authorisationNumber !== '' && this.state.authorisationAmount > 0)
            {
                shortfallPayable = Number(insurancePayable) - Number(this.state.authorisationAmount);
                insurancePayable = this.state.authorisationAmount;
            }
            if(authoAmount > 0)
             {
                shortfallPayable = Number(insurancePayable) - Number(authoAmount);
                insurancePayable = authoAmount;
             }
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable})
        }
        calcQuantity(index, value)
        {
            var list = this.state.quotationItems;  
            list[index].cost = Number(list[index].unitCost) * Number(value);
            list[index].totalCost = list[index].cost;
            this.setState({ quotationItems: list })  
            this.calcBill(list);
        }
        setQuotation(name, value) {
            switch (name) {
                case "companyId":
                    for (var i in this.state.clusterCompanys) {
                        if (Number(this.state.clusterCompanys[i].id) === Number(value)) {
                            var companyId = this.state.clusterCompanys[i].id;
                            var users = this.state.clusterCompanys[i].users;
                            var patientInsurances = this.state.clusterCompanys[i].patientInsurances;
                            var institutions = this.state.clusterCompanys[i].institutions;
                            var currencys = this.state.clusterCompanys[i].currencys;
                            var speciality = this.state.clusterCompanys[i].speciality;
                            var locations = this.state.clusterCompanys[i].locations;
                            this.setState({error: '', companyId: companyId, users: users, patientInsurances: patientInsurances, institutions: institutions, currencys: currencys, speciality: speciality, locations: locations, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0, billCategory: '', consultantName: 0, visitType: '', patientName: '' })
                        }
                    }
                    break;
                case "billCategory":
                    this.setState({ billCategory: value, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0 })
                    break;
                case "consultantName":
                    this.setState({ consultantName: value })
                    break;
                case "treatmentDateTime":
                    this.setState({ treatmentDateTime: this.processDateTime(value) })
                    break;
                case "patientInsuranceId":
                    for (var j in this.state.patientInsurances) {
                        if (this.state.patientInsurances[j].id === Number(value)) {
                            var patientInsuranceId = this.state.patientInsurances[j].id;
                            var institutionId = this.state.patientInsurances[j].institutionId;
                            var currencyId = this.state.patientInsurances[j].currencyId;
                            var rate = this.state.patientInsurances[j].rate;
                            var billType = 'INSURANCE - '+this.state.patientInsurances[j].name+" - "+this.state.patientInsurances[j].currencyName
                            this.setState({ patientInsuranceId: patientInsuranceId, institutionId: institutionId, currencyId: currencyId, rate: rate, billType: billType  })
                            this.getServices(institutionId, rate)
                        }
                    }
                    break;
                case "specialContractId":
                    for (var v in this.state.institutions) {
                        if (this.state.institutions[v].id === Number(value)) {
                            var specialContractId = this.state.institutions[v].id;
                            institutionId = this.state.institutions[v].id;
                            currencyId = this.state.institutions[v].currencyId;
                            rate = this.state.institutions[v].rate;
                            billType = 'CONTRACT - '+this.state.institutions[v].name+" - "+this.state.institutions[v].currencyName
                            this.setState({ specialContractId: specialContractId, institutionId: institutionId, currencyId: currencyId, rate: rate, billType: billType  })
                            this.getServices(institutionId, rate)
                        }
                    }
                    break;
                case "currencyId":
                    for (var b in this.state.currencys) {
                        if (this.state.currencys[b].id === Number(value)) {
                             currencyId = this.state.currencys[b].id;
                             institutionId = 0;
                             rate = this.state.currencys[b].rate;
                             billType = 'CASH - '+this.state.currencys[b].name
                            this.setState({ currencyId: currencyId, institutionId: 0, rate: rate, billType: billType })
                            this.getServices( 0, rate)
                        }
                    }
                    break;
                case "patientName":
                    this.setState({ patientName: value })
                    break;
                 default: 
                         console.log("....")
                         break;   
            }
        }
        getServices(institutionId, rate)
        {
         this.setState({services: [], options: []})   
         console.log('..... i am '+institutionId+'/'+rate+'/'+this.state.companyId)
         if(institutionId >= 0 && rate > 0 && this.state.companyId > 0)
            { console.log('..... i am ')
              axios.get(localStorage.getItem('host') + '/patient/servicecostlist/' + this.state.companyId + '/' + this.state.billCategory + '/' + institutionId+'/'+rate, {
                  headers: {
                      'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              }).then(
                  res => {
                      var options = [];
                      for(var i in res.data)
                          {
                              options.push({value: res.data[i].id, label: res.data[i].name})
                          } 
                      this.setState({services: res.data, options: options});
                  }, err => {
                       this.setState({error: err+""})
                      this.props.setLoader(false)
                  }
              )
      
            }
        }   
        hideEntry(index)
         {
            let list = this.state.quotationItems.slice();
            list.splice(index, 1); 
            this.setState({ quotationItems: list }); 
            this.calcBill(list);
         } 
         getProductItems() {
             this.setState({ productItems: [] });
             this.props.setLoader(true)
             var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, name: this.state.name, rate: this.state.rate, billCategory: this.state.billCategory}
             axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitems', data, {
                 headers: {
                     'Authorization': `Bearer ${localStorage.getItem('token')}`
                 }
             }).then(
                 res => {
                  var options = [];
                  for(var i in res.data)
                      {
                          options.push({value: res.data[i].id, label: res.data[i].name+" ["+res.data[i].quantity+"]"})
                      } 
                  this.setState({productItems: res.data, productOptions: options});
                  this.props.setLoader(false)
                 }, err => {
                     console.log(".....")
                     this.setState({error: err+""})
                     this.props.setLoader(false)
                 }
             )
         }
      addProductItem()
         {
          if(this.state.productItemId.value > 0)
             {  console.log('......... service cost id '+this.state.productItemId.value) 
              const now = new Date();
              var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
              let list = this.state.quotationItems.slice();
              var s = '';
              for (var i in this.state.productItems) {
                  if (this.state.productItems[i].id === Number(this.state.productItemId.value)) {
                      s = this.state.productItems[i];
                  }
              }
           if(this.state.quantity <= s.quantity)
               {   
              list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: s.serviceClass, productType:s.type
              , tariffCode: s.tariffCode, startDate: date1, endDate: date1, quantity: this.state.quantity, unitCost: s.sellingPrice, min: (Number(s.sellingPrice) - this.getMargin(s)), max: (Number(s.sellingPrice) + this.getMargin(s)), cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, unitCostError: ''});
              this.setState({ quotationItems: list });
              this.calcBill(list)
              this.doValidate()
               }
             else
                {
                  window.scrollTo({top: 0, behavior: 'smooth'})
                  this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
                }  
             }
         } 
         processDateTime(date)
           {
             const now = new Date(date);
             const todayY = now.getFullYear();
             const todayM = checkZero(now.getMonth() + 1);
             const todayD = checkZero(now.getDate());
             var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
             console.log(date1)
            return date1;
           }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="quotationAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info">Quotation Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setQuotation("companyId", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Company</option>
                                                        {this.state.clusterCompanys.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.companyName}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.companyIdError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Bill Category</span><select className={this.state.billCategoryError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.billCategory} onChange={e => { this.setQuotation("billCategory", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Bill Category</option>
                                                        {this.state.billCategorys.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.billCategoryError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Consultant</span><select className={this.state.consultantNameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.consultantName} onChange={e => { this.setQuotation("consultantName", e.target.value); this.doValidate() }}>
                                                        <option value="">Choose Consultant</option>
                                                        {this.state.users.map((item, index) => (
                                                            <option key={index} value={item.name} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.consultantNameError}</div>
                                                </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">{this.state.billCategory === "INSURANCE" ? (<div className="input-group has-validation">
                                                        <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.patientInsuranceId} onChange={e => { this.setQuotation("patientInsuranceId", e.target.value); this.doValidate() }}>
                                                            <option value="0">Choose {this.state.billCategory}</option>
                                                            {this.state.patientInsurances.map((item, index) => (
                                                                <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                            ))}
                                                        </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "SPECIAL_CONTRACT" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span> <select className={this.state.specialContractIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.specialContractId} onChange={e => { this.setQuotation("specialContractId", e.target.value); this.doValidate() }} >
                                                                <option value="0">Choose Special Contract</option>
                                                                {this.state.institutions.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.specialContractIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "CASH" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.currencyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.currencyId} onChange={e => { this.setQuotation("currencyId", e.target.value); this.doValidate() }}>
                                                                <option value="0">Choose {this.state.billCategory}</option>
                                                                {this.state.currencys.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.currencyIdError}</div></div>) : (<span></span>)}
                                                    </div>
                                                    <div className="col" align="right"> <div className="input-group"><span className="input-group-text">Patient Name</span>
                                                 <input type="text" className={this.state.patientNameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.patientName} onChange={e => { this.setState({patientName: e.target.value}); this.doValidate() }} /><div className="invalid-feedback">{this.state.patientNameError}</div> </div></div>
                                                    <div className="col" align="right"><div className="input-group"><span className="input-group-text">Date</span>
                                                            <input type="text" className={this.state.treatmentDateTimeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.treatmentDateTime} onChange={e => {this.setQuotation("treatmentDateTime", e.target.value); this.doValidate()  }} /> 
                                                                                                                <MyDateTimePicker  value={this.state.treatmentDateTime} maxDate={ date1} onChange={(d)=> { this.setVisit("treatmentDateTime", d); this.doValidate()  }} />
                                                        <div className="invalid-feedback">{this.state.treatmentDateTimeError}</div> </div></div>
                                            </div>
                                           
                                            <div className="card"><div className="card-body">
                                            <div className="row">
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Service</span>
                                                    <Select
                                                    value={this.state.serviceCostId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ serviceCostId: e, cashOnly: 0 })}
                                                    options={this.state.options}
                                                /></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" onChange={e => this.setState({ cashOnly: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left"><input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" /></div>
                                            </div>
                                            </div></div>
                                            <br />
                                            <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><input type="button" className="btn btn-success" onClick={() => this.getProductItems()} value="Search" /></div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Barcode</span><input type="text" className="form-control" readOnly={this.state.locationId === ''?"readOnly":""} value={this.state.barcode} onChange={e => { this.setState({barcode: e.target.value}); this.doProductItemFromBarCode(e.target.value) }} /></div></div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" /></div>
                                            <div className="col"></div>
                                        </div>
                                        </div></div>
                                            <br />
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Line</th>
                                                    <th>Service</th>
                                                    <th>Tariff Code</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Cost</th>
                                                    <th>Autho Amount</th>
                                                    <th>Total Cost</th>
                                                    <th>Active Status</th>
                                                </tr>
                                                <tbody>
                                                {this.state.quotationItems.map((billItem, index) => (
                                                    billItem.activeStatus === 1?(<tr >
                                                        <td >{index + 1}</td>
                                                        <td >{billItem.name}</td>
                                                        <td>{billItem.tariffCode}</td>
                                                   <td> <input type="text" className="form-control" value={billItem.billStartDate} onChange={e => {this.setBillItem("billStartDate", index, e.target.value ) }} /> 
                                                                                                                                                                                                                        <MyDateTimePicker  value={billItem.billStartDate} maxDate={billItem.billEndDate} onChange={(d)=> {  this.setBillItem("billStartDate", index, d);  }} /></td>
                                                                                                                                                                                                                       <td> <input type="text" className="form-control" value={billItem.billEndDate} onChange={e => { this.setBillItem("billEndDate", index,  e.target.value ) }} /> 
                                                                                                                                                                                                                        <MyDateTimePicker  value={billItem.billEndDate} minDate={billItem.billStartDate} onChange={(d)=> {  this.setBillItem("billEndDate", index,  d);  }} /></td>  
                                                                                                                                                                                                                              {billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.billItemType === "PRODUCT"?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                                        {billItem.allowEdit === 1 ?(<td><div className="input-group has-validation"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                                        <td>{this.currencyFormat(billItem.cost)}</td>
                                                        <td><input type="text" className="form-control" value={billItem.authoAmount} onChange={e => this.setBillItem("authoAmount", index, e.target.value)} /></td>
                                                        <td>{this.currencyFormat(billItem.totalCost)}</td>
                                                        <td><input type="button" className="btn btn-danger" value="Delete" onClick={() => this.hideEntry(index)} /></td>
                                                    </tr>):(
                                                        <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    )
                                                ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.quotationItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.quotationItemsError}</div> </div></div>
                                            <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                </tbody>

                                            </table>





               
                <div className="row">
                    <div className="col-lg-6" align="center">{this.state.showSubmit === true ? (<button onClick={() => this.save()} className="btn btn-primary">Save</button>) : (<span></span>)}</div>
                    <div className="col-lg-6" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close Window (Esc)</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      