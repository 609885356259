import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import GpAction from './gpaction'
import NurseAction from '../nurse/nurseaction'
import ResultAction from "../nurse/resultaction";
import CalendarView from "../patient/calendarconsultant";
import CalendarReview from "./calendarreview";
import Whatsapp from "./whatsapp";
import Email from "./email";
import BlockCalendar from "./blockcalendar";
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class GpList extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getResultAction = this.getResultAction.bind(this)
    this.openNurseAction = this.openNurseAction.bind(this)
    this.getPrintOut = this.getPrintOut.bind(this)
    this.updateAfterSave = this.updateAfterSave.bind(this)
    this.showCalenderReview = this.showCalenderReview.bind(this)
    this.setCalenderReview = this.setCalenderReview.bind(this)
    this.getWhatsapp = this.getWhatsapp.bind(this)
    this.getEmail = this.getEmail.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.gpActionRef = React.createRef()
    this.resultActionRef = React.createRef()    
    this.nurseActionRef = React.createRef()
    this.calendarViewRef = React.createRef()
    this.calendarReviewRef = React.createRef()
    this.whatsappRef = React.createRef()
    this.emailRef = React.createRef()
    this.calendarBlockRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , visitType: 'OUTPATIENT', companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    componentDidMount() {
        this.getList()  
        setInterval(() => {
            this.getListSilent();
        }, 1000 * 20)
    }
    getListSilent() {
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, visitType: this.state.visitType, companyId:  localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/patientmanagement/consultantlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    } 
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, visitType: this.state.visitType, companyId:  localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/patientmanagement/consultantlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openGpAction(visitId)
    {
    this.gpActionRef.current.openGpAction(visitId, '')
    document.getElementById('gpAction').style = 'display:block'   
    }
    getResultAction(visitId)
     {
        this.setState({loader: true})
        this.resultActionRef.current.getResultAction(visitId)
        document.getElementById('resultAction').style = 'display:block'     
     }
     openNurseAction(visitId)
     {
     this.nurseActionRef.current.openNurseAction(visitId)
     document.getElementById('nurseAction').style = 'display:block'   
     }
     updateAfterSave(visitId, results)
     {
        this.gpActionRef.current.updateAfterSave(visitId, results)
     } 
     processDate(date)
       {
         const now = new Date(date);
         const todayY = now.getFullYear();
         const todayM = checkZero(now.getMonth() + 1);
         const todayD = checkZero(now.getDate());
         var date1 = todayY + '-' + todayM + '-' + todayD;
         console.log(date1)
        return date1;
       }
       showCalenderReview()
       {
          this.calendarReviewRef.current.calendar()
       }
       setCalenderReview(reviewDate, reviewTime)
       {
          document.getElementById('calendarReviewAction').style = 'display:none'
          this.gpActionRef.current.addReviewTable(reviewDate, reviewTime)
       }
       getWhatsapp(id, type)
       {
         this.setState({loader: true})  
         this.whatsappRef.current.getWhatsapp(id, type)
         document.getElementById('whatsappAction').style = 'display:block' 
       }  
       getEmail(id, type)
       {
         this.setState({loader: true})  
         this.emailRef.current.getEmail(id, type)
         document.getElementById('emailAction').style = 'display:block' 
       }  
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                        <div className="row">
                        <div className="col"><button onClick={() => this.calendarViewRef.current.calendar(false)} className="btn btn-success">Calendar</button></div>
                        <div className="col"><button onClick={() => this.calendarBlockRef.current.open()} className="btn btn-success">Block Calendar</button></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                           <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                               <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                              <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                               <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                                                                                                               <div className="col"><div className="input-group"><span className="input-group-text">Visit Type</span><select className="form-control" value={this.state.visitType} onChange={(e) => this.setState({ visitType: e.target.value })}  >
                                                    <option  value="OUTPATIENT" >OUTPATIENT</option>
                                                    <option  value="INPATIENT" >INPATIENT</option>
                                            </select>  </div></div>
                                                                              <div className="col">    <button className="btn btn-success" onClick={() => this.getList()} ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div className="table-responsive-lg"> 
                        <table className="table table-hover">
                        <thead className="table-light">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Date</th>
                                    <th>Bill Category</th>
                                    <th>Consultant</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} className={item.color}>
                                        <td >
                                            <span className="link" onClick={() => this.openGpAction(item.id)} title="Edit"><i className="bi bi-pencil-square"></i></span>
                                            {(item.visitStatus === "COMPLETED" ||  item.visitStatus === "NURSE_ORDERS") && (<span className="link" onClick={() => this.getPrintOut(item.id, 'GP', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>)}
                                            {(item.showWhatsapp === 1 && (item.visitStatus === "COMPLETED" || item.visitStatus === "NURSE_ORDERS")) && ( <span className="link" onClick={() => this.getWhatsapp(item.id, 'GP')} title="Send Whatsapp" ><i className="bi bi-whatsapp"></i></span>)}
                                            {(item.showEmail === 1 && (item.visitStatus === "COMPLETED" || item.visitStatus === "NURSE_ORDERS")) && ( <span className="link" onClick={() => this.getEmail(item.id, 'GP')} title="Send Email" ><i className="bi bi-envelope"></i></span>)}
                                           </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.treatmentDateTime}</td>
                                        <td>{item.billCategory}</td>
                                        <td>{item.consultant}</td>
                                        <td>{item.visitType}</td>
                                        <td>{item.visitStatus}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <GpAction ref={this.gpActionRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} getResultAction={this.getResultAction} openNurseAction={this.openNurseAction} getList={this.getList} showCalenderReview={this.showCalenderReview} getWhatsapp={this.getWhatsapp} getEmail={this.getEmail} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} /> 
                        <NurseAction ref={this.nurseActionRef}  setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} />
                        <ResultAction ref={this.resultActionRef} setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} />
                        <CalendarView ref={this.calendarViewRef} setLoader={this.setLoader}  />
                        <CalendarReview ref={this.calendarReviewRef} setLoader={this.setLoader} setCalenderReview={this.setCalenderReview} />
                        <BlockCalendar ref={this.calendarBlockRef} setLoader={this.setLoader}  />
                        <Whatsapp ref={this.whatsappRef} setLoader={this.setLoader} /> 
                        <Email ref={this.emailRef} setLoader={this.setLoader} /> 
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    