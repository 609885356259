import React, {Component} from 'react';



export default class PaginationEle extends Component {
state={titles: [], rows: [], pageLimit: 10, currentPage: 1, totalRows: 0, startPage: 0, endPage: 0, pages: 0, pageZone: 1, rowList:[], name: ''}

 update(rows)
    {
      const rowList = rows.filter(item => {
        // Convert all values of the contact object to a string, join     them, convert the string to lowercase and return the contact object if it includes the searchValue
          return Object.values(item)
            .join('')
            .toLowerCase()
            .includes(this.state.name.toLowerCase());
        });
            this.setState({ rowList, rows})
    }
getPageItem()
{
 let content  = []
 let pages  = Math.ceil(this.state.rowList.length /this.state.pageLimit)+1
 let pageMax = pages > (this.state.pageLimit  * this.state.pageZone)? (this.state.pageLimit  * this.state.pageZone)+1: pages
 for (let i = (this.state.pageZone === 1 ? 1 : (this.state.pageLimit * (this.state.pageZone - 1)) + 1); i < pageMax; i++) {
    content.push(<li  className={this.state.currentPage === i ?'page-item active':'page-item'}><span className="page-link" onClick={()=> this.setCurrentPage(i)}>{i}</span></li>) 
    }
return content
}
setCurrentPage(currentPage)
{
 
     console.log(currentPage)
    let pageZone = Math.ceil(currentPage / this.state.pageLimit) 
  //  pageZone = pageZone === 1 ? 1 : (this.state.pageLimit * (pageZone - 1)) + 1 
    console.log('.....pageZone'+pageZone)
    console.log('.....max'+Math.ceil(this.state.rows.length /this.state.pageLimit))
    this.setState({currentPage,pageZone})
}
filter(name)
   {
    const rowList = this.state.rows.filter(item => {
        // Convert all values of the contact object to a string, join     them, convert the string to lowercase and return the contact object if it includes the searchValue
          return Object.values(item)
            .join('')
            .toLowerCase()
            .includes(name.toLowerCase());
        });
     this.setState({rowList, currentPage: 1, name})
   }
    render() {
        return (

            <div className="card">
       <div className="card-body">
<div className="row">
    <div className="col"><div className="input-group"><span className="input-group-text">Search</span><input type="text" className="form-control" onKeyUp={(e) => this.filter(e.target.value)}  /></div></div>
    <div className="col"></div>
    <div className="col"><div className="input-group"><span className="input-group-text">Page Size</span><select className="form-control" onChange={(e) => {this.setState({ pageLimit: e.target.value });  }} value={this.state.pageLimit}>
    <option value="10">10</option>
    <option value="30">30</option>
    <option value="50">50</option>
        </select></div></div>
</div>
   <table className="table table-striped table-hover">
    <thead>
        <tr>
        {this.props.titles.map((item, index) => (
            <th key={index}>{item.label}</th>
        ))}
        </tr>
    </thead>
    <tbody>
    {this.state.rowList.slice((this.state.currentPage * this.state.pageLimit)- this.state.pageLimit , this.state.currentPage * this.state.pageLimit).map((item, index) => (
        <tr key={index}>
         {this.props.titles.map((field, index) => (
            <td>{item[field.field]}</td>
             ))}
        </tr>
        ))}
    </tbody>
   </table>

   <div className="row">
    <div className="col">Showing {(this.state.currentPage * this.state.pageLimit)- this.state.pageLimit +1} to {this.state.currentPage * this.state.pageLimit} of {this.state.rowList.length} entries</div>
   </div>
  <nav >
  <ul className="pagination">
    <li className={this.state.currentPage === 1 ?'page-item disabled':'page-item'}>
      <span className="page-link link" onClick={()=> this.setCurrentPage(this.state.currentPage - 1)}>
        <span aria-hidden="true">&laquo;</span>
      </span>
    </li>
   { this.getPageItem()}
    <li className={this.state.currentPage ===  Math.ceil(this.state.rows.length /this.state.pageLimit) ?'page-item disabled':'page-item'}>
      <span class="page-link"  onClick={()=> this.setCurrentPage(this.state.currentPage + 1)}>
        <span aria-hidden="true">&raquo;</span>
      </span>
    </li>
  </ul>
</nav>
  </div>
</div>
        )
    }


}