import './index.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss'
import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
//import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
/*

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/

import { createRoot } from 'react-dom/client';
//const container = document.getElementById('app');
//const root = createRoot(container); // createRoot(container!) if you use TypeScript
//root.render(<App  />);

createRoot(
  document.getElementById("root"),
)
.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
