import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import MyDatePicker from "../mydatepicker";


const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class CalendarView extends Component {
  
     state={number: '', id : '', reason: '', titles:[], timeTables: [], dates: [], keys: [], startDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), companyId: localStorage.getItem('companyId'), consultantId: 0, showConsultants: false, consultants: []}
    calendar(showConsultants)
    {    
     document.getElementById('calendarViewAction').style = 'display:block' 
     axios.get(localStorage.getItem('host') + '/auth/getconsultants/' + localStorage.getItem('companyId'), {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ consultants: res.data, showConsultants: showConsultants });
            this.getTimeTableList()
        }, err => {
            this.setState({ error: err + "" })
            this.props.setLoader(false)
        }
    )    
     
    }  
    getTimeTableList() {
        this.props.setLoader(true)
        this.setState({ list: [], msg: '' });
        axios.get(localStorage.getItem('host') + '/patient/getcalendar/'+ localStorage.getItem('companyId')+'/'+this.state.startDate+"/"+this.state.consultantId,  {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ titles: res.data.titles, dates: res.data.dates, timeTables: res.data.timeTables, keys: res.data.keys });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    closePopup() {
        document.getElementById('calendarViewAction').style = 'display:none'
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        return (<div id="calendarViewAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Calendar Action</div>
            <div className="card-body" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> 
                                                                                           <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                           <MyDatePicker  value={this.state.startDate}  onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                             <div className="col">{ this.state.showConsultants === true && <div className="input-group"><span className="input-group-text">Consultants</span><select className="form-control" onChange={(e) => this.setState({ consultantId: e.target.value })} value={this.state.consultantId} >
                                                <option value="0">All Consultants</option>
                                                {this.state.consultants.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div>}</div>
                                            <div className="col"><button className="btn btn-success" onClick={() => this.getTimeTableList()} ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        { this.state.keys.length > 0 &&(
                 <div className="row">
                 <div className="col">
                 <ul className="list-group list-group-horizontal">
                        {this.state.keys.map((item, index) => (
                        <li className="list-group-item" key={index} >{item.key} <i style={{color: item.value}} class="bi bi-square-fill"></i></li>
                        ))}
                    </ul>
                    </div>
                    </div>
                 )
                 }       
                 <table className="table table-striped" >
                    <thead>
                        <tr>
                            <th>Time</th>
                            {this.state.titles.map((item, index) => (
                            <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                {this.state.timeTables.map((item, index) => (
                        <tr key={index}>
                            <th>{item.time}</th>
                           {item.entry1 === ""?<td ></td> :<td  style={{backgroundColor: item.color1}} dangerouslySetInnerHTML={{ __html:item.entry1}}></td>}
                           {item.entry2 === ""?<td  ></td> : <td  style={{backgroundColor: item.color2}} dangerouslySetInnerHTML={{ __html:item.entry2}}></td>}
                           {item.entry3 === ""?<td  ></td> : <td  style={{backgroundColor: item.color3}} dangerouslySetInnerHTML={{ __html:item.entry3}}></td>}
                           {item.entry4 === ""?<td  ></td> : <td  style={{backgroundColor: item.color4}} dangerouslySetInnerHTML={{ __html:item.entry4}}></td>}
                           {item.entry5 === ""?<td  ></td> : <td  style={{backgroundColor: item.color5}} dangerouslySetInnerHTML={{ __html:item.entry5}}></td>}
                           {item.entry6 === ""?<td  ></td> : <td  style={{backgroundColor: item.color6}} dangerouslySetInnerHTML={{ __html:item.entry6}}></td>}
                           {item.entry7 === ""?<td  ></td> : <td  style={{backgroundColor: item.color7}} dangerouslySetInnerHTML={{ __html:item.entry7}}></td>}
                        </tr>
                         ))}
                    </tbody>
                 </table>
                <div className="row">
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    