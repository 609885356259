import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import Receipt from '../patient/receipt'
import RateAction from './rateaction'
import PaidAction from './paidaction'
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class Bill extends Component {
    constructor(props) {
        super(props);
    this.getPrintOut = this.getPrintOut.bind(this)    
    this.setLoader = this.setLoader.bind(this)
    this.setMsg = this.setMsg.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.receiptRef = React.createRef()
    this.rateActionRef = React.createRef()
    this.paidActionRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '',  startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [], comment: '', billId: 0 }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
    getAllocateAdvancedRceipt(advancedReceipt) {
        this.setState({loader: true})
        this.allocateAdvancedRceiptRef.current.getAllocateAdvancedRceipt(advancedReceipt)
        document.getElementById('allocationAdvancedReceiptAction').style = 'display:block' 
    }
    showForm(id) {
        this.setState({ id: 0, name: '', activeStatus: 1 })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/appointmenttimeaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    sendUpdateComment()
      {
        this.setLoader(true)
        axios.post(localStorage.getItem('host') + '/bill/updatecomment', {billId: this.state.billId, comment: this.state.comment}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.setLoader(false)
                this.closeUpdatePopup()
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
      }
    closeUpdatePopup() {
        this.setState({billId: 0, comment: ''})
        document.getElementById('updateCommentAction').style = 'display:none'
        console.log('......close');
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/bill/billlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    allowVisitSave(patientId, companyId)
    {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patient/allowvisitsave/' + patientId+'/'+companyId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data});
                this.setLoader(false)
            }, err => {
                console.log(".....")
            }
        )

    }
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
      openPaidAction(bill)
    {
      this.setState({loader: true})
      this.paidActionRef.current.getPaidAction(bill)
      document.getElementById('paidAction').style = 'display:block'       
    }
    openRateAction(bill)
    {
      this.setState({loader: true})
      this.rateActionRef.current.getRateAction(bill)
      document.getElementById('rateAction').style = 'display:block'       
    }
    openReceiptAction(visitId, visitType, page)
      {
        this.setState({loader: true})
        this.receiptRef.current.openReceiptAction(visitId, visitType, page)
        document.getElementById('receiptAction').style = 'display:block'       
      }
      openCommentAction(id, comment)
      {
       this.setState({billId: id, comment: comment})
       document.getElementById('updateCommentAction').style = 'display:block'
      }  
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    setMsg(msg)
    {
        this.setState({msg: msg})  
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                     
                    <div id="updateCommentAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Update Comment</div>
                            <div className="card-body" >
                           <div className="row">
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Comment</span>
                                                            <textarea className="form-control" value={this.state.comment} onChange={e => { this.setState({ comment: e.target.value }) }} ></textarea></div> </div>       
                                            </div>  
                                <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.sendUpdateComment()} className="btn btn-primary">Update</button></div> 
                                <div className="col" align="center" ><button onClick={() => this.closeUpdatePopup()} className="btn btn-primary">Close</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                        <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                            <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                           <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                            <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                            <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <button className="btn btn-success" onClick={() => this.getList()} ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Category</th>
                                    <th>Payable</th>
                                    <th>Paid</th>
                                    <th>Owing</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} >
                                        <td >
                                        {item.billStatus !== 'CANCELLED'?(<span className="link" onClick={() => this.getPrintOut(item.id, 'BILLING', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>):(<span></span>)}
                                            {item.billStatus !== 'CANCELLED' && item.receiptOwingAmount > 0 ? (<span className="link" onClick={() => this.openReceiptAction(item.id, item.billType, 'BILL')} title="Receipt Bill"><i class="bi bi-coin"></i></span>) : (<span></span>)}
                                          { item.billStatus !== 'CANCELLED' && item.receiptOwingAmount > 0 ?(<span className="link" onClick={() => this.openPaidAction(item)} title="Paid Action" ><i class="bi bi-cash-coin"></i></span>):(<span></span>)}
                                          { item.billStatus !== 'CANCELLED' && (<span className="link" onClick={() => this.openRateAction(item)} title="Rate Action" ><i class="bi bi-currency-exchange"></i></span>)}
                                          { item.billStatus !== 'CANCELLED' && (<span className="link" onClick={() => this.allowVisitSave(item.patientId, item.companyId)} title="Allow Visit Save" ><i class="bi bi-journal-check"></i></span>)} 
                                          { item.billStatus !== 'CANCELLED' && (<span className="link" onClick={() => this.openCommentAction(item.id, item.comment)} title="Update Comment" ><i class="bi bi-chat-left-text-fill"></i></span>)}
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.billingStartDateTime}</td>
                                        <td>{item.billCategory}</td>
                                        <td align="right">{this.currencyFormat(item.totalPayable)}</td>
                                        <td align="right">{this.currencyFormat(item.totalPaid)}</td>
                                        <td align="right">{this.currencyFormat(item.totalOwing)}</td>
                                        <td>{item.billType}</td>
                                        <td>{item.billStatus}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                        <Receipt ref={this.receiptRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} getList={this.getList} />
                        <RateAction ref={this.rateActionRef}  setLoader={this.setLoader}  setMsg={this.setMsg} getList={this.getList} />
                        <PaidAction ref={this.paidActionRef}  setLoader={this.setLoader}  setMsg={this.setMsg} getList={this.getList} />
                        {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    