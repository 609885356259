import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PaginationEle from "../pagination";


export default class Doctor extends Component {
    state = { id: 0, fullname: '', ahfoz: '', address: '', email: '', username: '', mobile: '', activeStatus: 1, list: [], duplicateRecord: '', duplicateRecords: [],  doctorRecords: [], columns: [

        {
            label: 'Action',
            field: 'action'
        },
        {
            label: 'Name',
            field: 'fullname'
        },
        {
            label: 'Usename',
            field: 'username'
        },                
        {
            label: 'Ahfoz',
            field: 'ahfoz'
        },
        {
            label: 'Action Status',
            field: 'stringStatus'
        }
    ]}
        constructor(props) {
            super(props);
           this.paginationEleRef = React.createRef()
        }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, fullname: '', address: '', email: '', mobile: '', ahfoz: '', activeStatus: 1})
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/doctoraction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                     this.setState({ fullname: res.data.fullname, username: res.data.username, id: res.data.id, ahfoz: res.data.ahfoz, address: res.data.address, email: res.data.email, mobile: res.data.mobile, activeStatus: res.data.activeStatus});
                     }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, username: this.state.username, ahfoz: this.state.ahfoz, address: this.state.address, email: this.state.email, mobile: this.state.mobile, fullname: this.state.fullname}
        axios.post(localStorage.getItem('host') + '/master/doctorform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/doctor' , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Doctor" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.paginationEleRef.current.update(res.data)
            }, err => {
                console.log(".....")
            }
        )
    }

    openMergeRecords() {
        this.setState({loader: true, doctorRecords: []})
        axios.get(localStorage.getItem('host') + '/master/getduplicaterecords', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ duplicateRecords: res.data });
                this.setLoader(false)
                document.getElementById('duplicateRecordAction').style = 'display:block'
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )  
        
    }
    getDuplicateRecords(item)
    {
        this.setLoader(true)
        this.setState({doctorRecords: [], duplicateRecord: item})
        axios.post(localStorage.getItem('host') + '/master/getdoctorrecords', JSON.parse(item), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                this.setState({ doctorRecords: res.data });
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    setRecordType(index, e)
    {
        let list = this.state.doctorRecords.slice();
        list[index].recordType = e.target.value;
        this.setState({ doctorRecords: list })
    }
    saveDuplicate()
    {
        this.setLoader(true)
          
            axios.post(localStorage.getItem('host') + '/master/savedoctorrecords', this.state.doctorRecords, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setLoader(false)
                    console.log(res.data)
                    this.setState({ msg: res.data });          
                    setTimeout(() => {
                        this.closeDuplicatePopup();
                        this.getList()
                    }, 3000);
                }, err => {
                    console.log(".....")
                     this.setState({error: err+""})
                this.setLoader(false)
                }
            )

    }
    closeDuplicatePopup()
    {
        document.getElementById('duplicateRecordAction').style = 'display:none'  
    } 
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col"><button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button></div>
                            <div className="col"><button onClick={() => this.openMergeRecords()} className="btn btn-primary">Duplicate Records </button></div>
                        </div>
                        
                        {divMsg}
                        <PaginationEle ref={this.paginationEleRef} titles={this.state.columns}  />
                        <div id="duplicateRecordAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Merge Duplicate Records</div>
            <div id="duplicateRecordMsg"></div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col"><div className="input-group">
                        <span className="input-group-text">Duplicate Record to Merge</span>
                        <select className="form-control" value={this.state.duplicateRecord} onChange={(e)=>this.getDuplicateRecords(e.target.value)}>
                        <option value="">Choose Duplicate Record</option>
                        {this.state.duplicateRecords.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{item.fullname } {item.ahfoz }</option>
                        ))}
                    </select></div></div>
                    <div className="col"></div>
                </div>
                <div >
                    <table className="table table-striped">
                        <tr>
                            <th>Record Type</th>
                            <th>Id</th>
                            <th>Fullname</th>
                            <th>Ahfoz</th>
                        </tr>
                        {this.state.doctorRecords.map((item, index) => (
                          <tbody> 
                        <tr >
                            <td><select className="form-control" value={item.recordType} onChange={e =>this.setRecordType(index, e)} >
                            <option value="">Choose</option>
                                <option value="PRIMARY">PRIMARY</option>
                                <option value="DUPLICATE">DUPLICATE</option>
                            </select></td>
                            <td>{ item.id }</td>
                            <td>{ item.fullname }</td>
                            <td>{ item.ahfoz }</td>
                        </tr>
                        </tbody> 
                         ))}
                    </table>
                </div>
                <div className="row">
                    <div className="col-lg-6" align="center"><button onClick={()=>this.saveDuplicate()} className="btn btn-primary">Merge Records</button></div>
                    <div className="col-lg-6" align="center"><button onClick={()=>this.closeDuplicatePopup()} className="btn btn-primary">Close Window (Esc)</button></div>
                </div>
            </div>
        </div>
        </div>
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action 
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Fullname</span>
                                                <input type="text" className="form-control" value={this.state.fullname} onChange={e => { this.setState({ fullname: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e =>  this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Ahfoz</span>
                                                <input type="text" className="form-control" value={this.state.ahfoz} onChange={e => { this.setState({ ahfoz: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Address</span>
                                                <input type="text" className="form-control" value={this.state.address} onChange={e => { this.setState({ address: e.target.value }) }} /></div>
                                           </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Email</span>
                                                <input type="text" className="form-control" value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Mobile</span>
                                                <input type="text" className="form-control" value={this.state.mobile} onChange={e => { this.setState({ mobile: e.target.value }) }} /></div>
                                           </div>
                                        </div>


                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Username</span>
                                                <input type="text" className="form-control" value={this.state.username} onChange={e => { this.setState({ username: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"></div>
                                            </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    