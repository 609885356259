import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import ScriptBillAction from "./scriptbill";
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());

function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class ScriptBillList extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.scriptbillActionRef = React.createRef()
    }
    state = { id: 0,firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD, companyId: 0, list: [], clusterCompanys: [], locationId: '', locations: [], allow: true }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getemrsources/'+localStorage.getItem('companyId')+'/PRESCRIPTION', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                let id = 0
                if(res.data.length === 1)
                {
                 id=   res.data[0].id
                }
                this.setState({clusterCompanys: res.data, companyId: id});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
   startAutoList()
   {
    setInterval(() => {
        this.getListSilent();
    }, 1000 * 20)
   } 
    action(e) {
        var id = e.target.id;
        console.log("......." + id + " .... " + e.target.id)
        this.showForm(id);
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname,dateRange: this.state.startDate+","+this.state.endDate, companyId:  this.state.companyId }
        axios.post(localStorage.getItem('host') + '/is/prescriptionlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getListSilent() {
        var data = { firstname: this.state.firstname, surname: this.state.surname,dateRange: this.state.startDate+","+this.state.endDate, companyId:  this.state.companyId }
        axios.post(localStorage.getItem('host') + '/is/prescriptionlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openAction(visitId)
    {
    this.scriptbillActionRef.current.openAction(visitId)
    document.getElementById('scriptbillAction').style = 'display:block'   
    }
     processDate(date)
       {
         const now = new Date(date);
         const todayY = now.getFullYear();
         const todayM = checkZero(now.getMonth() + 1);
         const todayD = checkZero(now.getDate());
         var date1 = todayY + '-' + todayM + '-' + todayD;
         console.log(date1)
        return date1;
       }
    showAllowDisallow()
       {
        axios.get(localStorage.getItem('host') + '/is/allowlocation/' + localStorage.getItem('companyId')+'/'+localStorage.getItem('roleId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ locationId: res.data.locationId, locations: res.data.locations , allow: res.data.locationId === 0});
                document.getElementById('allowLocationAction').style = 'display:block'  
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
        
       }  
    closeAllowPopup()
       {
        document.getElementById('allowLocationAction').style = 'display:none' 
       } 
    save()
       {
        this.setLoader(true)
        var data = { locationId: this.state.locationId, id: localStorage.getItem('companyId')}
        axios.post(localStorage.getItem('host') + '/is/allowlocationsave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                this.setState({ msg: res.data}); 
                this.closeAllowPopup()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
       }  
       delete()
          {
           axios.get(localStorage.getItem('host') + '/is/deleteallowlocation/' + localStorage.getItem('companyId'), {
               headers: {
                   'Authorization': `Bearer ${localStorage.getItem('token')}`
               }
           }).then(
               res => {
                this.setState({ msg: res.data});
                this.closeAllowPopup()
                   this.setLoader(false)
               }, err => {
                   console.log(".....")
                   this.setState({ error: err })
                   this.setLoader(false)
               }
           )
           
          }    
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                       <div id="allowLocationAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Allow/ Disallow Stock Location Action</div>
            <div className="card-body" >
                <div className="row">
                <div className="col"><div className="input-group has-validation"><span className="input-group-text">Reason:</span>
                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div> </div>
                                <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col" align="center">{this.state.allow === true? (<span> { this.state.locationId > 0 && <button onClick={() => this.save()} className="btn btn-primary" >Allow</button>}</span>) : (<button onClick={() => this.delete()} className="btn btn-primary" >Disallow</button>)}</div>
                    <div className="col" align="center"><button onClick={() => this.closeAllowPopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>
                        <button className="btn btn-primary" onClick={()=> this.showAllowDisallow()}>Allow/ Disallow Stock Location</button>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                               <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                   <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                  <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                   <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                              <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" value={this.state.companyId} onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                            <option value="0" >Choose</option>
                                            {this.state.clusterCompanys.map((item, index) => (
                                                <option value={item.id} key={index} >{item.name}</option>
                                            ))}
                                        </select>  </div></div>
                                            <div className="col">    <button className="btn btn-success" onClick={() => this.getList()}  ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                        <table className="table table-hover">
                        <thead className="table-light">
                                <tr>
                                <th>Action</th>
                                <th>Patient Name</th>
                                <th>Gender</th>
                                <th>Date of Birth</th>
                                <th>Date</th>
                                <th>Category</th>
                                <th>Reception</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} className={item.color}>
                                        <td >
                                            <span className="link" onClick={() => this.openAction(item.visitId)} title="Edit"><i className="bi bi-pencil-square"></i></span>
                                            <span className="link" onClick={() => this.getPrintOut(item.billId, 'BILLING', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                        </td>
                                        <td>{item.fullname}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.dateTime}</td>
                                        <td>{item.billCategory}</td>
                                        <td>{item.opdPharmacy}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <ScriptBillAction ref={this.scriptbillActionRef}  setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} getList={this.getList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    