import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class NeurosurgeryAction extends Component {
    state = {
        loader: false,htmlText: '', id: 0, referringDoctorName: '', companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], rehabilitations: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], dateLists: [], referralLetters: [],appointmentTimes: [],
        feedbackDoctor: '', feedbackHistory: '', feedbackExamination: '', feedbackReviewConsult: '', feedbackInvestigations: '', feedbackDiagnosis: '', feedbackPrescriptions: '', 
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], oldPatient: '', planData: '', chronicData: '', historyVisits: [], visitId: 0, msg: '', error: '', diagnosis: '',
        familyHistory: '', socialHistory: '', systemicReview: '', presentingComplaintList: [], historyOfPresentingComplaints: '', pastMedicalHistory: '', currentMedication: '', allergyHistory: '',
        coughChoice: '', coughText: '', coryzaChoice: '', coryzaText: '', sobChoice: '', sobText: '', sneazingChoice: '', sneazingText: '', phelgmChoice: '', phelgmText: '', sputumChoice: '', sputumText: '', haemoptysisChoice: '', haemoptysisText: '', wheezingChoice: '', wheezingText: '', nasalChoice: '', nasalText: '', soreChoice: '', soreText: '', chestPainChoice: '', chestPainText: '', respitoryNotes: '',
        nauseaChoice: '', nauseaText: '', vomitingChoice: '', vomitingText: '', diarrhoeaChoice: '', diarrhoeaText: '', abdominialChoice: '', abdominialText: '', flatulanceChoice: '', flatulanceText: '', flatulentChoice: '', flatulentText: '', waterChoice: '', waterText: '', constipationChoice: '', constipationText: '', prChoice: '', prText: '', wtChoice: '', wtText: '', melenaChoice: '', melenaText: '', jaundiceChoice: '', jaundiceText: '', loaChoice: '', loaText: '', lowChoice: '', lowText: '', gitNotes: '',
        dysuriaChoice: '', dysuriaText: '', frequencyChoice: '', frequencyText: '', haematuriaChoice: '', haematuriaText: '', weakChoice: '', weakText: '', strainingChoice: '', strainingText: '', urineChoice: '', urineText: '', urethalChoice: '', urethalText: '', pvChoice: '', pvText: '', nocturiaChoice: '', nocturiaText: '', genitalChoice: '', genitalText: '', lossChoice: '', lossText: '', erectileChoice: '', erectileText: '', gusNotes: '',
        dysaruniaChoice: '', dysaruniaText: '', dysmemorrhoeaChoice: '', dysmemorrhoeaText: '', imbChoice: '', imbText: '', contactChoice: '', contactText: '', menorrhagiaChoice: '', menorrhagiaText: '', lapChoice: '', lapText: '', backacheChoice: '', backackeText: '', subfertilityChoice: '', subfertilityText: '', hotChoice: '', hotText: '', lnmpChoice: '', lnmpText: '', gynaeNotes: '',
        orthopneaChoice: '', orthopneaText: '', pndChoice: '', pndText: '', easyChoice: '', easyText: '', palpitationsChoice: '', palpitationsText: '', synacopeChoice: '', synacopeText: '', legSwellingChoice: '', legSwellingText: '', chestPaincvsChoice: '', chestPaincvsText: '', calfSwellingChoice: '', calfSwellingText: '', pinkFrothChoice: '', pinkFrothText: '', gelatinousChoice: '', gelatinousText: '', cvsNotes: '',
        headacheChoice: '', headacheText: '', paraesthesiaChoice: '', paraesthesiaText: '', dizzinessChoice: '', dizzinessText: '', backackeChoice: '', backacheText: '', photophibiaChoice: '', photophibiaText: '', neckstiffnessChoice: '', neckstiffnessText: '', fittingChoice: '', fittingText: '', paresisChoice: '', paresisText: '', paralysisChoice: '', paralysisText: '', insomniaChoice: '', insomniaText: '', hypersomniaChoice: '', hypersomniaText: '', abnChoice: '', abnText: '', cnsNotes: '',
        feverChoice: '', feverText: '', sweatingChoice: '', sweatingText: '', chillsChoice: '', chillsText: '', rigorsChoice: '', rigorsText: '', fatigueChoice: '', fatigueText: '', myalgiaChoice: '', myalgiaText: '', constitutionalNotes: '',
        bleedingChoice: '', bleedingText: '', bruisingChoice: '', bruisingText: '', swellingChoice: '', swellingText: '', deformityChoice: '', deformityText: '', mssNotes: '',
        rashChoice: '', rashText: '', itchynessChoice: '', itchynessText: '', blistersChoice: '', blistersText: '', lumpsChoice: '', lumpsText: '', ulcersChoice: '', ulcersText: '', swellingdemaChoice: '', swellingdemaText: '', dematologyNotes: '',
        /* Extra History */ 
        painText: '', durationText: '', timingText: '', aggravatedByText: '', relievedByText: '', headacheLeftChoice: '', headacheLeftText: '', headacheRightChoice: '', headacheRightText: '', holocranialChoice: '', holocranialText: '', frontalChoice: '', frontalText: '', temporalChoice: '', temporalText: '', occipitalChoice: '', occipitalText: '', periorbitalChoice: '', periorbitalText: '', confusionChoice: '', confusionText: '', 
        changeinCharacterChoice: '', changeinCharacterText: '', weaknessChoice: '', weaknessText: '', hemiparesisLeftChoice: '', hemiparesisLeftText: '', hemiparesisRightChoice: '', hemiparesisRightText: '', weaknessHemiplegiaChoice: '', weaknessHemiplegiaText: '', hemiplegiaLeftChoice: '', hemiplegiaLeftText: '', hemiplegiaRightChoice: '', hemiplegiaRightText: '', paraparesisChoice: '', paraparesisText: '', paraplegiaChoice: '', paraplegiaText: '', quadriparesisChoice: '', quadriparesisText: '', 
        quadriplegiaChoice: '', quadriplegiaText: '', monoparesisChoice: '', monoparesisText: '', monoparesisLeftChoice: '', monoparesisLeftText: '', monoparesisRightChoice: '', monoparesisRightText: '', monoparesisUpperLimbChoice: '', monoparesisUpperLimbText: '', monoparesisLowerLimbChoice: '', monoparesisLowerLimbText: '', seizuresChoice: '', seizuresText: '', seizuresAuraChoice: '', seizuresAuraText: '', auraVisualChoice: '', auraVisualText: '', auraOlfactoryChoice: '', auraOlfactoryText: '', auraAutonomicChoice: '', 
        auraAutonomicText: '', thoracicChoice: '', thoracicText: '', lumbarChoice: '', lumbarText: '', lowerBackacheChoice: '', lowerBackacheText: '', mechanicalChoice: '', mechanicalText: '', sciaticaChoice: '', sciaticaText: '', sciaticaLeftChoice: '', sciaticaLeftText: '', sciaticaRightChoice: '', sciaticaRightText: '', cervicalPainChoice: '', cervicalPainText: '', interScapularPainChoice: '', interScapularPainText: '', paraesthesiaUpperLimbChoice: '', 
        paraesthesiaUpperLimbText: '', paraesthesiaLowerLimbChoice: '', paraesthesiaLowerLimbText: '', lossOfBalanceChoice: '', lossOfBalanceText: '', neurogenicClaudicationChoice: '', neurogenicClaudicationText: '', radiculapathyChoice: '', radiculapathyText: '', radiculapathyLeftChoice: '', radiculapathyLeftText: '', radiculapathyLeftC2Choice: '', radiculapathyLeftC2Text: '', radiculapathyLeftC3Choice: '', radiculapathyLeftC3Text: '', radiculapathyLeftC4Choice: '', radiculapathyLeftC4Text: '', radiculapathyLeftC5Choice: '', 
        radiculapathyLeftC5Text: '', radiculapathyLeftC6Choice: '', radiculapathyLeftC6Text: '', radiculapathyLeftC7Choice: '', radiculapathyLeftC7Text: '', radiculapathyLeftC8Choice: '', radiculapathyLeftC8Text: '', radiculapathyLeftT1Choice: '', radiculapathyLeftT1Text: '', radiculapathyLeftT2Choice: '', radiculapathyLeftT2Text: '', radiculapathyLeftT3Choice: '', radiculapathyLeftT3Text: '', radiculapathyLeftT4Choice: '', radiculapathyLeftT4Text: '', radiculapathyLeftT5Choice: '', radiculapathyLeftT5Text: '', radiculapathyLeftT6Choice: '', 
        radiculapathyLeftT6Text: '', radiculapathyLeftT7Choice: '', radiculapathyLeftT7Text: '', radiculapathyLeftT8Choice: '', radiculapathyLeftT8Text: '', radiculapathyLeftT9Choice: '', radiculapathyLeftT9Text: '', radiculapathyLeftT10Choice: '', radiculapathyLeftT10Text: '', radiculapathyLeftT11Choice: '', radiculapathyLeftT11Text: '', radiculapathyLeftT12Choice: '', radiculapathyLeftT12Text: '', radiculapathyLeftL1Choice: '', radiculapathyLeftL1Text: '', radiculapathyLeftL2Choice: '', radiculapathyLeftL2Text: '', radiculapathyLeftL3Choice: '', 
        radiculapathyLeftL3Text: '', radiculapathyLeftL4Choice: '', radiculapathyLeftL4Text: '', radiculapathyLeftL5Choice: '', radiculapathyLeftL5Text: '', radiculapathyLeftS1Choice: '', radiculapathyLeftS1Text: '', radiculapathyLeftS3Choice: '', radiculapathyLeftS3Text: '', radiculapathyLeftS45Choice: '', radiculapathyLeftS45Text: '', radiculapathyRightChoice: '', radiculapathyRightText: '', radiculapathyRightC2Choice: '', radiculapathyRightC2Text: '', radiculapathyRightC3Choice: '', radiculapathyRightC3Text: '', radiculapathyRightC4Choice: '', 
        radiculapathyRightC4Text: '', radiculapathyRightC5Choice: '', radiculapathyRightC5Text: '', radiculapathyRightC6Choice: '', radiculapathyRightC6Text: '', radiculapathyRightC7Choice: '', radiculapathyRightC7Text: '', radiculapathyRightC8Choice: '', radiculapathyRightC8Text: '', radiculapathyRightT1Choice: '', radiculapathyRightT1Text: '', radiculapathyRightT2Choice: '', radiculapathyRightT2Text: '', radiculapathyRightT3Choice: '', radiculapathyRightT3Text: '', radiculapathyRightT4Choice: '', radiculapathyRightT4Text: '', 
        radiculapathyRightT5Choice: '', radiculapathyRightT5Text: '', radiculapathyRightT6Choice: '', radiculapathyRightT6Text: '', radiculapathyRightT7Choice: '', radiculapathyRightT7Text: '', radiculapathyRightT8Choice: '', radiculapathyRightT8Text: '', radiculapathyRightT9Choice: '', radiculapathyRightT9Text: '', radiculapathyRightT10Choice: '', radiculapathyRightT10Text: '', radiculapathyRightT11Choice: '', radiculapathyRightT11Text: '', radiculapathyRightT12Choice: '', radiculapathyRightT12Text: '', radiculapathyRightL1Choice: '', 
        radiculapathyRightL1Text: '', radiculapathyRightL2Choice: '', radiculapathyRightL2Text: '', radiculapathyRightL3Choice: '', radiculapathyRightL3Text: '', radiculapathyRightL4Choice: '', radiculapathyRightL4Text: '', radiculapathyRightL5Choice: '', radiculapathyRightL5Text: '', radiculapathyRightS1Choice: '', radiculapathyRightS1Text: '', radiculapathyRightS3Choice: '', radiculapathyRightS3Text: '', radiculapathyRightS45Choice: '', radiculapathyRightS45Text: '',
        /*EXAMINATION */
        eyeText: '' ,motorText: '' ,verbalText: '' ,gcsText: '' ,dementiaChoice: '' ,dementiaText: '' ,tandemChoice: '' ,tandemText: '' ,dysdiadochokinesiaChoice: '' ,dysdiadochokinesiaText: '' ,rombergChoice: '' ,rombergText: '' ,rombergPositiveChoice: '' ,rombergPostiveText: '' ,rombergNegativeChoice: '' ,rombergNegativeText: '' ,ataxiaChoice: '' ,ataxiaText: '' ,ataxiaTruncalChoice: '' ,ataxiaTruncalText: '' ,ataxiaAppendicularChoice: '' ,ataxiaAppendicularText: '' ,ataxiaGlobalChoice: '' ,ataxiaGlobalText: '' ,nystagmusChoice: '' ,
        nystagmusText: '' ,dysphasiaChoice: '' ,dysphasiaText: '' ,dysphasiaExpressiveChoice: '' ,dysphasiaExpressiveText: '' ,dysphasiaReceptiveChoice: '' ,dysphasiaReceptiveText: '' ,dysphasiaGlobalChoice: '' ,dysphasiaGlobalText: '' ,abuliaChoice: '' ,abuliaText: '' ,astereognosiaChoice: '' ,astereognosiaText: '' ,akineticChoice: '' ,akineticText: '' ,hemianopsiaChoice: '' ,hemianopsiaText: '' ,hemianopsiaRightChoice: '' ,hemianopsiaRightText: '' ,hemianopsiaLeftChoice: '' ,hemianopsiaLeftText: '' ,hemianopsiaBitemporalChoice: '' ,
        hemianopsiaBitemporalText: '' ,hemianopsiaTemporalChoice: '' ,hemianopsiaTemporalText: '' ,hemianopsiaNasalChoice: '' ,hemianopsiaNasalText: '' ,cranialChoice: '' ,cranialText: '' ,cranialLeftChoice: '' ,cranialLeftText: '' ,olfactoryLeftChoice: '' ,olfactoryLeftText: '' ,opticLeftChoice: '' ,opticLeftText: '' ,oculomotorLeftChoice: '' ,oculomotorLeftText: '' ,trochlearLeftChoice: '' ,trochlearLeftText: '' ,trigeminalLeftChoice: '' ,trigeminalLeftText: '' ,abducentLeftChoice: '' ,abducentLeftText: '' ,facialLeftChoice: '' ,
        facialLeftText: '' ,vestibulocochlearLeftChoice: '' ,vestibulocochlearLeftText: '' ,glossopharyngealLeftChoice: '' ,glossopharyngealLeftText: '' ,vagusLeftChoice: '' ,vagusLeftText: '' ,accessoryLeftChoice: '' ,accessoryLeftText: '' ,hypoglossalLeftChoice: '' ,hypoglossalLeftText: '' ,cranialRightChoice: '' ,cranialRightText: '' ,olfactoryRightChoice: '' ,olfactoryRightText: '' ,opticRightChoice: '' ,opticRightText: '' ,oculomotorRightChoice: '' ,oculomotorRightText: '' ,trochlearRightChoice: '' ,trochlearRightText: '' ,
        trigeminalRightChoice: '' ,trigeminalRightText: '' ,abducentRightChoice: '' ,abducentRightText: '' ,facialRightChoice: '' ,facialRightText: '' ,vestibulocochlearRightChoice: '' ,vestibulocochlearRightText: '' ,glossopharyngealRightChoice: '' ,glossopharyngealRightText: '' ,vagusRightChoice: '' ,vagusRightText: '' ,accessoryRightChoice: '' ,accessoryRightText: '' ,hypoglossalRightChoice: '' ,hypoglossalRightText: '' ,spineRangeChoice: '' ,spineRangeText: '' ,spineRangeCervicalChoice: '' ,spineRangeCervicalText: '' ,
        spineRangeCervicalFlexionChoice: '' ,spineRangeCervicalFlexionText: '' ,spineRangeCervicalFlexionReducedChoice: '' ,spineRangeCervicalFlexionReducedText: '' ,spineRangeCervicalFlexionPainfulChoice: '' ,spineRangeCervicalFlexionPainfulText: '' ,spineRangeCervicalExtensionChoice: '' ,spineRangeCervicalExtensionText: '' ,spineRangeCervicalExtensionReducedChoice: '' ,spineRangeCervicalExtensionReducedText: '' ,spineRangeCervicalExtensionPainfulChoice: '' ,spineRangeCervicalExtensionPainfulText: '' ,spineRangeCervicalLateralBendingChoice: '' ,
        spineRangeCervicalLateralBendingText: '' ,spineRangeCervicalLateralBendingReducedChoice: '' ,spineRangeCervicalLateralBendingReducedText: '' ,spineRangeCervicalLateralBendingPainfulChoice: '' ,spineRangeCervicalLateralBendingPainfulText: '' ,spineRangeCervicalRotationChoice: '' ,spineRangeCervicalRotationText: '' ,spineRangeCervicalRotationReducedChoice: '' ,spineRangeCervicalRotationReducedText: '' ,spineRangeCervicalRotationPainfulChoice: '' ,spineRangeCervicalRotationPainfulText: '' ,spineRangeThoracicChoice: '' ,
        spineRangeThoracicText: '' ,spineRangeThoracicFlexionChoice: '' ,spineRangeThoracicFlexionText: '' ,spineRangeThoracicFlexionReducedChoice: '' ,spineRangeThoracicFlexionReducedText: '' ,spineRangeThoracicFlexionPainfulChoice: '' ,spineRangeThoracicFlexionPainfulText: '' ,spineRangeThoracicExtensionChoice: '' ,spineRangeThoracicExtensionText: '' ,spineRangeThoracicExtensionReducedChoice: '' ,spineRangeThoracicExtensionReducedText: '' ,spineRangeThoracicExtensionPainfulChoice: '' ,spineRangeThoracicExtensionPainfulText: '' ,
        spineRangeThoracicLateralBendingChoice: '' ,spineRangeThoracicLateralBendingText: '' ,spineRangeThoracicLateralBendingReducedChoice: '' ,spineRangeThoracicLateralBendingReducedText: '' ,spineRangeThoracicLateralBendingPainfulChoice: '' ,spineRangeThoracicLateralBendingPainfulText: '' ,spineRangeThoracicRotationChoice: '' ,spineRangeThoracicRotationText: '' ,spineRangeThoracicRotationReducedChoice: '' ,spineRangeThoracicRotationReducedText: '' ,spineRangeThoracicRotationPainfulChoice: '' ,spineRangeThoracicRotationPainfulText: '' ,
        spineRangeLumbarChoice: '' ,spineRangeLumbarText: '' ,spineRangeLumbarFlexionChoice: '' ,spineRangeLumbarFlexionText: '' ,spineRangeLumbarFlexionReducedChoice: '' ,spineRangeLumbarFlexionReducedText: '' ,spineRangeLumbarFlexionPainfulChoice: '' ,spineRangeLumbarFlexionPainfulText: '' ,spineRangeLumbarExtensionChoice: '' ,spineRangeLumbarExtensionText: '' ,spineRangeLumbarExtensionReducedChoice: '' ,spineRangeLumbarExtensionReducedText: '' ,spineRangeLumbarExtensionPainfulChoice: '' ,spineRangeLumbarExtensionPainfulText: '' ,
        spineRangeLumbarLateralBendingChoice: '' ,spineRangeLumbarLateralBendingText: '' ,spineRangeLumbarLateralBendingReducedChoice: '' ,spineRangeLumbarLateralBendingReducedText: '' ,spineRangeLumbarLateralBendingPainfulChoice: '' ,spineRangeLumbarLateralBendingPainfulText: '' ,spineRangeLumbarRotationChoice: '' ,spineRangeLumbarRotationText: '' ,spineRangeLumbarRotationReducedChoice: '' ,spineRangeLumbarRotationReducedText: '' ,spineRangeLumbarRotationPainfulChoice: '' ,spineRangeLumbarRotationPainfulText: '' ,spineTendernessChoice: '' ,
        spineTendernessText: '' ,spineTendernessCervicalChoice: '' ,spineTendernessCervicalText: '' ,spineTendernessThoracicChoicespineTendernessThoracicText: '' ,spineTendernessThoracolumbarChoice: '' ,spineTendernessThoracolumbarText: '' ,spineTendernessLumbarChoice: '' ,spineTendernessLumbarText: '' ,spineTendernessLumbosacralChoice: '' ,spineTendernessLumbosacralText: '' ,spineTendernessSacroiliacChoice: '' ,spineTendernessSacroiliacText: '' ,trendelenbergChoice: '' ,trendelenbergText: '' ,trendelenbergLeftChoice: '' ,trendelenbergLeftText: '' ,
        trendelenbergLeftPresentChoice: '' ,trendelenbergLeftPresentText: '' ,trendelenbergLeftNormalChoice: '' ,trendelenbergLeftNormalText: '' ,trendelenbergRightChoice: '' ,trendelenbergRightText: '' ,trendelenbergRightPresentChoice: '' ,trendelenbergRightPresentText: '' ,trendelenbergRightNormalChoice: '' ,trendelenbergRightNormalText: '' ,standOnTipToeChoice: '' ,standOnTipToeText: '' ,standOnTipToeLeftChoice: '' ,standOnTipToeLeftText: '' ,standOnTipToeLeftWeakChoice: '' ,standOnTipToeLeftWeakText: '' ,standOnTipToeLeftNormalChoice: '' ,
        standOnTipToeLeftNormalText: '' ,standOnTipToeRightChoice: '' ,standOnTipToeRightText: '' ,standOnTipToeRightWeakChoice: '' ,standOnTipToeRightWeakText: '' ,standOnTipToeRightNormalChoice: '' ,standOnTipToeRightNormalText: '' ,spurlingTestChoice: '' ,spurlingTestText: '' ,spurlingTestLeftChoice: '' ,spurlingTestLeftText: '' ,spurlingTestLeftPresentChoice: '' ,spurlingTestLeftPresentText: '' ,spurlingTestLeftAbsentChoice: '' ,spurlingTestLeftAbsentText: '' ,spurlingTestRightChoice: '' ,spurlingTestRightText: '' ,spurlingTestRightPresentChoice: '' ,
        spurlingTestRightPresentText: '' ,spurlingTestRightAbsentChoice: '' ,spurlingTestRightAbsentText: '' ,abductionReliefChoice: '' ,abductionReliefText: '' ,abductionReliefLeftChoice: '' ,abductionReliefLeftText: '' ,abductionReliefLeftPresentChoice: '' ,abductionReliefLeftPresentText: '' ,abductionReliefLeftAbsentChoice: '' ,abductionReliefLeftAbsentText: '' ,abductionReliefRightChoice: '' ,abductionReliefRightText: '' ,abductionReliefRightPresentChoice: '' ,abductionReliefRightPresentText: '' ,abductionReliefRightAbsentChoice: '' ,
        abductionReliefRightAbsentText: '' ,straightLegRaisingChoice: '' ,straightLegRaisingText: '' ,straightLegRaisingLeftChoice: '' ,straightLegRaisingLeftText: '' ,straightLegRaisingLeftPresentChoice: '' ,straightLegRaisingLeftPresentText: '' ,straightLegRaisingLeftAbsentChoice: '' ,straightLegRaisingLeftAbsentText: '' ,straightLegRaisingRightChoice: '' ,straightLegRaisingRightText: '' ,straightLegRaisingRightPresentChoice: '' ,straightLegRaisingRightPresentText: '' ,straightLegRaisingRightAbsentChoice: '' ,straightLegRaisingRightAbsentText: '' ,
        reverseStraightLegChoice: '' ,reverseStraightLegText: '' ,reverseStraightLegLeftChoice: '' ,reverseStraightLegLeftText: '' ,reverseStraightLegLeftPresentChoice: '' ,reverseStraightLegLeftPresentText: '' ,reverseStraightLegLeftAbsentChoice: '' ,reverseStraightLegLeftAbsentText: '' ,reverseStraightLegRightChoice: '' ,reverseStraightLegRightText: '' ,reverseStraightLegRightPresentChoice: '' ,reverseStraightLegRightPresentText: '' ,reverseStraightLegRightAbsentChoice: '' ,reverseStraightLegRightAbsentText: '' ,toneChoice: '' ,toneText: '' ,
        toneLeftUpperLimbChoice: '' ,toneLeftUpperLimbText: '' ,toneLeftUpperLimbHypertoniaChoice: '' ,toneLeftUpperLimbHypertoniaText: '' ,toneLeftUpperLimbHypotoniaChoice: '' ,toneLeftUpperLimbHypotoniaText: '' ,toneLeftUpperLimbAtoniaChoice: '' ,toneLeftUpperLimbAtoniaText: '' ,toneRightUpperLimbChoice: '' ,toneRightUpperLimbText: '' ,toneRightUpperLimbHypertoniaChoice: '' ,toneRightUpperLimbHypertoniaText: '' ,toneRightUpperLimbHypotoniaChoice: '' ,toneRightUpperLimbHypotoniaText: '' ,toneRightUpperLimbAtoniaChoice: '' ,toneRightUpperLimbAtoniaText: '' ,
        toneLeftLowerLimbChoice: '' ,toneLeftLowerLimbText: '' ,toneLeftLowerLimbHypertoniaChoice: '' ,toneLeftLowerLimbHypertoniaText: '' ,toneLeftLowerLimbHypotoniaChoice: '' ,toneLeftLowerLimbHypotoniaText: '' ,toneLeftLowerLimbAtoniaChoice: '' ,toneLeftLowerLimbAtoniaText: '' ,toneRightLowerLimbChoice: '' ,toneRightLowerLimbText: '' ,toneRightLowerLimbHypertoniaChoice: '' ,toneRightLowerLimbHypertoniaText: '' ,toneRightLowerLimbHypotoniaChoice: '' ,toneRightLowerLimbHypotoniaText: '' ,toneRightLowerLimbAtoniaChoice: '' ,toneRightLowerLimbAtoniaText: '' ,
        toneGlobalChoice: '' ,toneGlobalText: '' ,toneGlobalHypertoniaChoice: '' ,toneGlobalHypertoniaText: '' ,toneGlobalHypotoniaChoice: '' ,toneGlobalHypotoniaText: '' ,toneGlobalAbsentChoice: '' ,toneGlobalAbsentText: '' ,powerChoice: '' ,powerText: '' ,powerLeftUpperLimbChoice: '' ,powerLeftUpperLimbText: '' ,powerLeftUpperLimbC5Choice: '' ,powerLeftUpperLimbC5Text: '' ,powerLeftUpperLimbC6Choice: '' ,powerLeftUpperLimbC6Text: '' ,powerLeftUpperLimbC7Choice: '' ,powerLeftUpperLimbC7Text: '' ,powerLeftUpperLimbC8Choice: '' ,powerLeftUpperLimbC8Text: '' ,
        powerLeftUpperLimbT1Choice: '' ,powerLeftUpperLimbT1Text: '' ,powerRightUpperLimbChoice: '' ,powerRightUpperLimbText: '' ,powerRightUpperLimbC5Choice: '' ,powerRightUpperLimbC5Text: '' ,powerRightUpperLimbC6Choice: '' ,powerRightUpperLimbC6Text: '' ,powerRightUpperLimbC7Choice: '' ,powerRightUpperLimbC7Text: '' ,powerRightUpperLimbC8Choice: '' ,powerRightUpperLimbC8Text: '' ,powerRightUpperLimbT1Choice: '' ,powerRightUpperLimbT1Text: '' ,powerLeftLowerLimbChoice: '' ,powerLeftLowerLimbText: '' ,powerLeftLowerLimbC5Choice: '' ,powerLeftLowerLimbC5Text: '' ,
        powerLeftLowerLimbC6Choice: '' ,powerLeftLowerLimbC6Text: '' ,powerLeftLowerLimbC7Choice: '' ,powerLeftLowerLimbC7Text: '' ,powerLeftLowerLimbC8Choice: '' ,powerLeftLowerLimbC8Text: '' ,powerLeftLowerLimbT1Choice: '' ,powerLeftLowerLimbT1Text: '' ,powerRightLowerLimbChoice: '' ,powerRightLowerLimbText: '' ,powerRightLowerLimbC5Choice: '' ,powerRightLowerLimbC5Text: '' ,powerRightLowerLimbC6Choice: '' ,powerRightLowerLimbC6Text: '' ,powerRightLowerLimbC7Choice: '' ,powerRightLowerLimbC7Text: '' ,powerRightLowerLimbC8Choice: '' ,powerRightLowerLimbC8Text: '' ,
        powerRightLowerLimbT1Choice: '' ,powerRightLowerLimbT1Text: '' ,wastingChoice: '' ,wastingText: '' ,wastingLeftUpperLimbChoice: '' ,wastingLeftUpperLimbText: '' ,wastingRightUpperLimbChoice: '' ,wastingRightUpperLimbText: '' ,wastingLeftLowerLimbChoice: '' ,wastingLeftLowerLimbText: '' ,wastingRightLowerLimbChoice: '' ,wastingRightLowerLimbText: '' ,fasciculationsChoice: '' ,fasciculationsText: '' ,fasciculationsLeftUpperLimbChoice: '' ,fasciculationsLeftUpperLimbText: '' ,fasciculationsRightUpperLimbChoice: '' ,fasciculationsRightUpperLimbText: '' ,
        fasciculationsLeftLowerLimbChoice: '' ,fasciculationsLeftLowerLimbText: '' ,fasciculationsRightLowerLimbChoice: '' ,fasciculationsRightLowerLimbText: '' ,deepTendonChoice: '' ,deepTendonText: '' ,deepTendonLeftBicepsChoice: '' ,deepTendonLeftBicepsText: '' ,deepTendonLeftBicepsHyperChoice: '' ,deepTendonLeftBicepsHyperText: '' ,deepTendonLeftBicepsHypoChoice: '' ,deepTendonLeftBicepsHypoText: '' ,deepTendonLeftBicepsAChoice: '' ,deepTendonLeftBicepsAText: '' ,deepTendonRightBicepsChoice: '' ,deepTendonRightBicepsText: '' ,deepTendonRightBicepsHyperChoice: '' ,
        deepTendonRightBicepsHyperText: '' ,deepTendonRightBicepsHypoChoice: '' ,deepTendonRightBicepsHypoText: '' ,deepTendonRightBicepsAChoice: '' ,deepTendonRightBicepsAText: '' ,deepTendonLeftTricepsChoice: '' ,deepTendonLeftTricepsText: '' ,deepTendonLeftTricepsHyperChoice: '' ,deepTendonLeftTricepsHyperText: '' ,deepTendonLeftTricepsHypoChoice: '' ,deepTendonLeftTricepsHypoText: '' ,deepTendonLeftTricepsAChoice: '' ,deepTendonLeftTricepsAText: '' ,deepTendonRightTricepsChoice: '' ,deepTendonRightTricepsText: '' ,deepTendonRightTricepsHyperChoice: '' ,
        deepTendonRightTricepsHyperText: '' ,deepTendonRightTricepsHypoChoice: '' ,deepTendonRightTricepsHypoText: '' ,deepTendonRightTricepsAChoice: '' ,deepTendonRightTricepsAText: '' ,deepTendonInvertedRadialChoice: '' ,deepTendonInvertedRadialText: '' ,deepTendonInvertedRadialLeftChoice: '' ,deepTendonInvertedRadialLeftText: '' ,deepTendonInvertedRadialRightChoice: '' ,deepTendonInvertedRadialRightText: '' ,deepTendonLeftKneeChoice: '' ,deepTendonLeftKneeText: '' ,deepTendonLeftKneeHyperChoice: '' ,deepTendonLeftKneeHyperText: '' ,deepTendonLeftKneeHypoChoice: '' ,
        deepTendonLeftKneeHypoText: '' ,deepTendonLeftKneeAChoice: '' ,deepTendonLeftKneeAText: '' ,deepTendonLeftKneePendularChoice: '' ,deepTendonLeftKneePendularText: '' ,deepTendonRightKneeChoice: '' ,deepTendonRightKneeText: '' ,deepTendonRightKneeHyperChoice: '' ,deepTendonRightKneeHyperText: '' ,deepTendonRightKneeHypoChoice: '' ,deepTendonRightKneeHypoText: '' ,deepTendonRightKneeAChoice: '' ,deepTendonRightKneeAText: '' ,deepTendonRightKneePendularChoice: '' ,deepTendonRightKneePendularText: '' ,deepTendonLeftAnkleChoice: '' ,deepTendonLeftAnkleText: '' ,
        deepTendonLeftAnkleHyperChoice: '' ,deepTendonLeftAnkleHyperText: '' ,deepTendonLeftAnkleHypoChoice: '' ,deepTendonLeftAnkleHypoText: '' ,deepTendonLeftAnkleAChoice: '' ,deepTendonLeftAnkleAText: '' ,deepTendonRightAnkleChoice: '' ,deepTendonRightAnkleText: '' ,deepTendonRightAnkleHyperChoice: '' ,deepTendonRightAnkleHyperText: '' ,deepTendonRightAnkleHypoChoice: '' ,deepTendonRightAnkleHypoText: '' ,deepTendonRightAnkleAChoice: '' ,deepTendonRightAnkleAText: '' ,hoffmansChoice: '' ,hoffmansText: '' ,hoffmansLeftChoice: '' ,hoffmansLeftText: '' ,
        hoffmansRightChoice: '' ,hoffmansRightText: '' ,ankleClonusChoice: '' ,ankleClonusText: '' ,ankleClonusLeftChoice: '' ,ankleClonusLeftText: '' ,ankleClonusRightChoice: '' ,ankleClonusRightText: '' ,babinskiChoice: '' ,babinskiText: '' ,babinskiLeftChoice: '' ,babinskiLeftText: '' ,babinskiLeftUpGoingChoice: '' ,babinskiLeftUpGoingText: '' ,babinskiLeftDownGoingChoice: '' ,babinskiLeftDownGoingText: '' ,babinskiLeftEquivocalChoice: '' ,babinskiLeftEquivocalText: '' ,babinskiRightChoice: '' ,babinskiRightText: '' ,babinskiRightUpGoingChoice: '' ,babinskiRightUpGoingText: '' ,
        babinskiRightDownGoingChoice: '' ,babinskiRightDownGoingText: '' ,babinskiRightEquivocalChoice: '' ,babinskiRightEquivocalText: '' ,bulbocavernosusChoice: '' ,bulbocavernosusText: '' ,bulbocavernosusPresentChoice: '' ,bulbocavernosusPresentText: '' ,bulbocavernosusAbsentChoice: '' ,bulbocavernosusAbsentText: '' ,beevorsChoice: '' ,beevorsText: '' ,beevorsPresentChoice: '' ,beevorsPresentText: '' ,beevorsAbsentChoice: '' ,beevorsAbsentText: '' ,rombergTestChoice: '' ,rombergTestText: '' ,rombergTestAbsentChoice: '' ,rombergTestAbsentText: '' ,rombergTestPresentChoice: '' ,
        rombergTestPresentText: '' ,rombergTestPresentLeftChoice: '' ,rombergTestPresentLeftText: '' ,rombergTestPresentRightChoice: '' ,rombergTestPresentRightText: '' ,motorLevelChoice: '' ,motorLevelText: '' ,motorLevelLeftChoice: '' ,motorLevelLeftText: '' ,motorLevelLeftC5Choice: '' ,motorLevelLeftC5Text: '' ,motorLevelLeftC6Choice: '' ,motorLevelLeftC6Text: '' ,motorLevelLeftC7Choice: '' ,motorLevelLeftC7Text: '' ,motorLevelLeftC8Choice: '' ,motorLevelLeftC8Text: '' ,motorLevelLeftT1Choice: '' ,motorLevelLeftT1Text: '' ,motorLevelLeftL2Choice: '' ,motorLevelLeftL2Text: '' ,
        motorLevelLeftL3Choice: '' ,motorLevelLeftL3Text: '' ,motorLevelLeftL4Choice: '' ,motorLevelLeftL4Text: '' ,motorLevelLeftL5Choice: '' ,motorLevelLeftL5Text: '' ,motorLevelLeftS1Choice: '' ,motorLevelLeftS1Text: '' ,motorLevelRightChoice: '' ,motorLevelRightText: '' ,motorLevelRightC5Choice: '' ,motorLevelRightC5Text: '' ,motorLevelRightC6Choice: '' ,motorLevelRightC6Text: '' ,motorLevelRightC7Choice: '' ,motorLevelRightC7Text: '' ,motorLevelRightC8Choice: '' ,motorLevelRightC8Text: '' ,motorLevelRightT1Choice: '' ,motorLevelRightT1Text: '' ,motorLevelRightL2Choice: '' ,
        motorLevelRightL2Text: '' ,motorLevelRightL3Choice: '' ,motorLevelRightL3Text: '' ,motorLevelRightL4Choice: '' ,motorLevelRightL4Text: '' ,motorLevelRightL5Choice: '' ,motorLevelRightL5Text: '' ,motorLevelRightS1Choice: '' ,motorLevelRightS1Text: '' ,sensoryLevelChoice: '' ,sensoryLevelText: '' ,sensoryLevelLeftChoice: '' ,sensoryLevelLeftText: '' ,sensoryLevelLeftC2Choice: '' ,sensoryLevelLeftC2Text: '' ,sensoryLevelLeftC3Choice: '' ,sensoryLevelLeftC3Text: '' ,sensoryLevelLeftC4Choice: '' ,sensoryLevelLeftC4Text: '' ,sensoryLevelLeftC5Choice: '' ,sensoryLevelLeftC5Text: '' ,
        sensoryLevelLeftC6Choice: '' ,sensoryLevelLeftC6Text: '' ,sensoryLevelLeftC7Choice: '' ,sensoryLevelLeftC7Text: '' ,sensoryLevelLeftC8Choice: '' ,sensoryLevelLeftC8Text: '' ,sensoryLevelLeftT1Choice: '' ,sensoryLevelLeftT1Text: '' ,sensoryLevelLeftT2Choice: '' ,sensoryLevelLeftT2Text: '' ,sensoryLevelLeftT3Choice: '' ,sensoryLevelLeftT3Text: '' ,sensoryLevelLeftT4Choice: '' ,sensoryLevelLeftT4Text: '' ,sensoryLevelLeftT5Choice: '' ,sensoryLevelLeftT5Text: '' ,sensoryLevelLeftT6Choice: '' ,sensoryLevelLeftT6Text: '' ,sensoryLevelLeftT7Choice: '' ,sensoryLevelLeftT7Text: '' ,
        sensoryLevelLeftT8Choice: '' ,sensoryLevelLeftT8Text: '' ,sensoryLevelLeftT9Choice: '' ,sensoryLevelLeftT9Text: '' ,sensoryLevelLeftT10Choice: '' ,sensoryLevelLeftT10Text: '' ,sensoryLevelLeftT11Choice: '' ,sensoryLevelLeftT11Text: '' ,sensoryLevelLeftT12Choice: '' ,sensoryLevelLeftT12Text: '' ,sensoryLevelLeftL1Choice: '' ,sensoryLevelLeftL1Text: '' ,sensoryLevelLeftL2Choice: '' ,sensoryLevelLeftL2Text: '' ,sensoryLevelLeftL3Choice: '' ,sensoryLevelLeftL3Text: '' ,sensoryLevelLeftL4Choice: '' ,sensoryLevelLeftL4Text: '' ,sensoryLevelLeftL5Choice: '' ,sensoryLevelLeftL5Text: '' ,
        sensoryLevelLeftS1Choice: '' ,sensoryLevelLeftS1Text: '' ,sensoryLevelLeftS3Choice: '' ,sensoryLevelLeftS3Text: '' ,sensoryLevelLeftS45Choice: '' ,sensoryLevelLeftS45Text: '' ,sensoryLevelRightChoice: '' ,sensoryLevelRightText: '' ,sensoryLevelRightC2Choice: '' ,sensoryLevelRightC2Text: '' ,sensoryLevelRightC3Choice: '' ,sensoryLevelRightC3Text: '' ,sensoryLevelRightC4Choice: '' ,sensoryLevelRightC4Text: '' ,sensoryLevelRightC5Choice: '' ,sensoryLevelRightC5Text: '' ,sensoryLevelRightC6Choice: '' ,sensoryLevelRightC6Text: '' ,sensoryLevelRightC7Choice: '' ,
        sensoryLevelRightC7Text: '' ,sensoryLevelRightC8Choice: '' ,sensoryLevelRightC8Text: '' ,sensoryLevelRightT1Choice: '' ,sensoryLevelRightT1Text: '' ,sensoryLevelRightT2Choice: '' ,sensoryLevelRightT2Text: '' ,sensoryLevelRightT3Choice: '' ,sensoryLevelRightT3Text: '' ,sensoryLevelRightT4Choice: '' ,sensoryLevelRightT4Text: '' ,sensoryLevelRightT5Choice: '' ,sensoryLevelRightT5Text: '' ,sensoryLevelRightT6Choice: '' ,sensoryLevelRightT6Text: '' ,sensoryLevelRightT7Choice: '' ,sensoryLevelRightT7Text: '' ,sensoryLevelRightT8Choice: '' ,sensoryLevelRightT8Text: '' ,
        sensoryLevelRightT9Choice: '' ,sensoryLevelRightT9Text: '' ,sensoryLevelRightT10Choice: '' ,sensoryLevelRightT10Text: '' ,sensoryLevelRightT11Choice: '' ,sensoryLevelRightT11Text: '' ,sensoryLevelRightT12Choice: '' ,sensoryLevelRightT12Text: '' ,sensoryLevelRightL1Choice: '' ,sensoryLevelRightL1Text: '' ,sensoryLevelRightL2Choice: '' ,sensoryLevelRightL2Text: '' ,sensoryLevelRightL3Choice: '' ,sensoryLevelRightL3Text: '' ,sensoryLevelRightL4Choice: '' ,sensoryLevelRightL4Text: '' ,sensoryLevelRightL5Choice: '' ,sensoryLevelRightL5Text: '' ,sensoryLevelRightS1Choice: '' ,
        sensoryLevelRightS1Text: '' ,sensoryLevelRightS3Choice: '' ,sensoryLevelRightS3Text: '' ,sensoryLevelRightS45Choice: '' ,sensoryLevelRightS45Text: '' ,neurologicalLevelChoice: '' ,neurologicalLevelText: '' ,neurologicalLevelC2Choice: '' ,neurologicalLevelC2Text: '' ,neurologicalLevelC3Choice: '' ,neurologicalLevelC3Text: '' ,neurologicalLevelC4Choice: '' ,neurologicalLevelC4Text: '' ,neurologicalLevelC5Choice: '' ,neurologicalLevelC5Text: '' ,neurologicalLevelC6Choice: '' ,neurologicalLevelC6Text: '' ,neurologicalLevelC7Choice: '' ,neurologicalLevelC7Text: '' ,
        neurologicalLevelC8Choice: '' ,neurologicalLevelC8Text: '' ,neurologicalLevelT1Choice: '' ,neurologicalLevelT1Text: '' ,neurologicalLevelT2Choice: '' ,neurologicalLevelT2Text: '' ,neurologicalLevelT3Text: '' ,neurologicalLevelT4Choice: '' ,neurologicalLevelT4Text: '' ,neurologicalLevelT5Choice: '' ,neurologicalLevelT5Text: '' ,neurologicalLevelT6Choice: '' ,neurologicalLevelT6Text: '' ,neurologicalLevelT7Choice: '' ,neurologicalLevelT7Text: '' ,neurologicalLevelT8Choice: '' ,neurologicalLevelT8Text: '' ,neurologicalLevelT9Choice: '' ,neurologicalLevelT9Text: '' ,
        neurologicalLevelT10Choice: '' ,neurologicalLevelT10Text: '' ,neurologicalLevelT11Choice: '' ,neurologicalLevelT11Text: '' ,neurologicalLevelT12Choice: '' ,neurologicalLevelT12Text: '' ,neurologicalLevelL1Choice: '' ,neurologicalLevelL1Text: '' ,neurologicalLevelL2Choice: '' ,neurologicalLevelL2Text: '' ,neurologicalLevelL3Choice: '' ,neurologicalLevelL3Text: '' ,neurologicalLevelL4Choice: '' ,neurologicalLevelL4Text: '' ,neurologicalLevelL5Choice: '' ,neurologicalLevelL5Text: '' ,neurologicalLevelS1Choice: '' ,neurologicalLevelS1Text: '' ,neurologicalLevelS3Choice: '' ,
        neurologicalLevelS3Text: '' ,neurologicalLevelS45Choice: '' ,neurologicalLevelS45Text: '' ,
        hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
        historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: true, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false,
        diagnosisTab: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false
    }
    componentDidMount() {

    }
    openNeurosurgeryAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           }
        this.setState({
            htmlText: '', id: 0, referringDoctorName: '', companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], rehabilitations: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], dateLists:[], referralLetters: [],appointmentTimes: [],
            feedbackHistory: '', feedbackExamination: '', feedbackReviewConsult: '', feedbackInvestigations: '', feedbackDiagnosis: '', feedbackPrescriptions: '', feedbackDoctor:'', 
            fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], oldPatient: '', planData: '', chronicData: '', historyVisits: [], visitId: visitId, msg: '', error: '',
            familyHistory: '', socialHistory: '', systemicReview: '', presentingComplaintList: [], historyOfPresentingComplaints: '', pastMedicalHistory: '', currentMedication: '', allergyHistory: '',
            coughChoice: '', coughText: '', coryzaChoice: '', coryzaText: '', sobChoice: '', sobText: '', sneazingChoice: '', sneazingText: '', phelgmChoice: '', phelgmText: '', sputumChoice: '', sputumText: '', haemoptysisChoice: '', haemoptysisText: '', wheezingChoice: '', wheezingText: '', nasalChoice: '', nasalText: '', soreChoice: '', soreText: '', chestPainChoice: '', chestPainText: '', respitoryNotes: '',
            nauseaChoice: '', nauseaText: '', vomitingChoice: '', vomitingText: '', diarrhoeaChoice: '', diarrhoeaText: '', abdominialChoice: '', abdominialText: '', flatulanceChoice: '', flatulanceText: '', flatulentChoice: '', flatulentText: '', waterChoice: '', waterText: '', constipationChoice: '', constipationText: '', prChoice: '', prText: '', wtChoice: '', wtText: '', melenaChoice: '', melenaText: '', jaundiceChoice: '', jaundiceText: '', loaChoice: '', loaText: '', lowChoice: '', lowText: '', gitNotes: '',
            dysuriaChoice: '', dysuriaText: '', frequencyChoice: '', frequencyText: '', haematuriaChoice: '', haematuriaText: '', weakChoice: '', weakText: '', strainingChoice: '', strainingText: '', urineChoice: '', urineText: '', urethalChoice: '', urethalText: '', pvChoice: '', pvText: '', nocturiaChoice: '', nocturiaText: '', genitalChoice: '', genitalText: '', lossChoice: '', lossText: '', erectileChoice: '', erectileText: '', gusNotes: '',
            dysaruniaChoice: '', dysaruniaText: '', dysmemorrhoeaChoice: '', dysmemorrhoeaText: '', imbChoice: '', imbText: '', contactChoice: '', contactText: '', menorrhagiaChoice: '', menorrhagiaText: '', lapChoice: '', lapText: '', backacheChoice: '', backackeText: '', subfertilityChoice: '', subfertilityText: '', hotChoice: '', hotText: '', lnmpChoice: '', lnmpText: '', gynaeNotes: '',
            orthopneaChoice: '', orthopneaText: '', pndChoice: '', pndText: '', easyChoice: '', easyText: '', palpitationsChoice: '', palpitationsText: '', synacopeChoice: '', synacopeText: '', legSwellingChoice: '', legSwellingText: '', chestPaincvsChoice: '', chestPaincvsText: '', calfSwellingChoice: '', calfSwellingText: '', pinkFrothChoice: '', pinkFrothText: '', gelatinousChoice: '', gelatinousText: '', cvsNotes: '',
            headacheChoice: '', headacheText: '', paraesthesiaChoice: '', paraesthesiaText: '', dizzinessChoice: '', dizzinessText: '', backackeChoice: '', backacheText: '', photophibiaChoice: '', photophibiaText: '', neckstiffnessChoice: '', neckstiffnessText: '', fittingChoice: '', fittingText: '', paresisChoice: '', paresisText: '', paralysisChoice: '', paralysisText: '', insomniaChoice: '', insomniaText: '', hypersomniaChoice: '', hypersomniaText: '', abnChoice: '', abnText: '', cnsNotes: '',
            feverChoice: '', feverText: '', sweatingChoice: '', sweatingText: '', chillsChoice: '', chillsText: '', rigorsChoice: '', rigorsText: '', fatigueChoice: '', fatigueText: '', myalgiaChoice: '', myalgiaText: '', constitutionalNotes: '',
            bleedingChoice: '', bleedingText: '', bruisingChoice: '', bruisingText: '', swellingChoice: '', swellingText: '', deformityChoice: '', deformityText: '', mssNotes: '',
            rashChoice: '', rashText: '', itchynessChoice: '', itchynessText: '', blistersChoice: '', blistersText: '', lumpsChoice: '', lumpsText: '', ulcersChoice: '', ulcersText: '', swellingdemaChoice: '', swellingdemaText: '', dematologyNotes: '',
           /* Extra History */ 
        painText: '', durationText: '', timingText: '', aggravatedByText: '', relievedByText: '', headacheLeftChoice: '', headacheLeftText: '', headacheRightChoice: '', headacheRightText: '', holocranialChoice: '', holocranialText: '', frontalChoice: '', frontalText: '', temporalChoice: '', temporalText: '', occipitalChoice: '', occipitalText: '', periorbitalChoice: '', periorbitalText: '', confusionChoice: '', confusionText: '', 
        changeinCharacterChoice: '', changeinCharacterText: '', weaknessChoice: '', weaknessText: '', hemiparesisLeftChoice: '', hemiparesisLeftText: '', hemiparesisRightChoice: '', hemiparesisRightText: '', weaknessHemiplegiaChoice: '', weaknessHemiplegiaText: '', hemiplegiaLeftChoice: '', hemiplegiaLeftText: '', hemiplegiaRightChoice: '', hemiplegiaRightText: '', paraparesisChoice: '', paraparesisText: '', paraplegiaChoice: '', paraplegiaText: '', quadriparesisChoice: '', quadriparesisText: '', 
        quadriplegiaChoice: '', quadriplegiaText: '', monoparesisChoice: '', monoparesisText: '', monoparesisLeftChoice: '', monoparesisLeftText: '', monoparesisRightChoice: '', monoparesisRightText: '', monoparesisUpperLimbChoice: '', monoparesisUpperLimbText: '', monoparesisLowerLimbChoice: '', monoparesisLowerLimbText: '', seizuresChoice: '', seizuresText: '', seizuresAuraChoice: '', seizuresAuraText: '', auraVisualChoice: '', auraVisualText: '', auraOlfactoryChoice: '', auraOlfactoryText: '', auraAutonomicChoice: '', 
        auraAutonomicText: '', thoracicChoice: '', thoracicText: '', lumbarChoice: '', lumbarText: '', lowerBackacheChoice: '', lowerBackacheText: '', mechanicalChoice: '', mechanicalText: '', sciaticaChoice: '', sciaticaText: '', sciaticaLeftChoice: '', sciaticaLeftText: '', sciaticaRightChoice: '', sciaticaRightText: '', cervicalPainChoice: '', cervicalPainText: '', interScapularPainChoice: '', interScapularPainText: '', paraesthesiaUpperLimbChoice: '', 
        paraesthesiaUpperLimbText: '', paraesthesiaLowerLimbChoice: '', paraesthesiaLowerLimbText: '', lossOfBalanceChoice: '', lossOfBalanceText: '', neurogenicClaudicationChoice: '', neurogenicClaudicationText: '', radiculapathyChoice: '', radiculapathyText: '', radiculapathyLeftChoice: '', radiculapathyLeftText: '', radiculapathyLeftC2Choice: '', radiculapathyLeftC2Text: '', radiculapathyLeftC3Choice: '', radiculapathyLeftC3Text: '', radiculapathyLeftC4Choice: '', radiculapathyLeftC4Text: '', radiculapathyLeftC5Choice: '', 
        radiculapathyLeftC5Text: '', radiculapathyLeftC6Choice: '', radiculapathyLeftC6Text: '', radiculapathyLeftC7Choice: '', radiculapathyLeftC7Text: '', radiculapathyLeftC8Choice: '', radiculapathyLeftC8Text: '', radiculapathyLeftT1Choice: '', radiculapathyLeftT1Text: '', radiculapathyLeftT2Choice: '', radiculapathyLeftT2Text: '', radiculapathyLeftT3Choice: '', radiculapathyLeftT3Text: '', radiculapathyLeftT4Choice: '', radiculapathyLeftT4Text: '', radiculapathyLeftT5Choice: '', radiculapathyLeftT5Text: '', radiculapathyLeftT6Choice: '', 
        radiculapathyLeftT6Text: '', radiculapathyLeftT7Choice: '', radiculapathyLeftT7Text: '', radiculapathyLeftT8Choice: '', radiculapathyLeftT8Text: '', radiculapathyLeftT9Choice: '', radiculapathyLeftT9Text: '', radiculapathyLeftT10Choice: '', radiculapathyLeftT10Text: '', radiculapathyLeftT11Choice: '', radiculapathyLeftT11Text: '', radiculapathyLeftT12Choice: '', radiculapathyLeftT12Text: '', radiculapathyLeftL1Choice: '', radiculapathyLeftL1Text: '', radiculapathyLeftL2Choice: '', radiculapathyLeftL2Text: '', radiculapathyLeftL3Choice: '', 
        radiculapathyLeftL3Text: '', radiculapathyLeftL4Choice: '', radiculapathyLeftL4Text: '', radiculapathyLeftL5Choice: '', radiculapathyLeftL5Text: '', radiculapathyLeftS1Choice: '', radiculapathyLeftS1Text: '', radiculapathyLeftS3Choice: '', radiculapathyLeftS3Text: '', radiculapathyLeftS45Choice: '', radiculapathyLeftS45Text: '', radiculapathyRightChoice: '', radiculapathyRightText: '', radiculapathyRightC2Choice: '', radiculapathyRightC2Text: '', radiculapathyRightC3Choice: '', radiculapathyRightC3Text: '', radiculapathyRightC4Choice: '', 
        radiculapathyRightC4Text: '', radiculapathyRightC5Choice: '', radiculapathyRightC5Text: '', radiculapathyRightC6Choice: '', radiculapathyRightC6Text: '', radiculapathyRightC7Choice: '', radiculapathyRightC7Text: '', radiculapathyRightC8Choice: '', radiculapathyRightC8Text: '', radiculapathyRightT1Choice: '', radiculapathyRightT1Text: '', radiculapathyRightT2Choice: '', radiculapathyRightT2Text: '', radiculapathyRightT3Choice: '', radiculapathyRightT3Text: '', radiculapathyRightT4Choice: '', radiculapathyRightT4Text: '', 
        radiculapathyRightT5Choice: '', radiculapathyRightT5Text: '', radiculapathyRightT6Choice: '', radiculapathyRightT6Text: '', radiculapathyRightT7Choice: '', radiculapathyRightT7Text: '', radiculapathyRightT8Choice: '', radiculapathyRightT8Text: '', radiculapathyRightT9Choice: '', radiculapathyRightT9Text: '', radiculapathyRightT10Choice: '', radiculapathyRightT10Text: '', radiculapathyRightT11Choice: '', radiculapathyRightT11Text: '', radiculapathyRightT12Choice: '', radiculapathyRightT12Text: '', radiculapathyRightL1Choice: '', 
        radiculapathyRightL1Text: '', radiculapathyRightL2Choice: '', radiculapathyRightL2Text: '', radiculapathyRightL3Choice: '', radiculapathyRightL3Text: '', radiculapathyRightL4Choice: '', radiculapathyRightL4Text: '', radiculapathyRightL5Choice: '', radiculapathyRightL5Text: '', radiculapathyRightS1Choice: '', radiculapathyRightS1Text: '', radiculapathyRightS3Choice: '', radiculapathyRightS3Text: '', radiculapathyRightS45Choice: '', radiculapathyRightS45Text: '',
        /*EXAMINATION */
        eyeText: '' ,motorText: '' ,verbalText: '' ,gcsText: '' ,dementiaChoice: '' ,dementiaText: '' ,tandemChoice: '' ,tandemText: '' ,dysdiadochokinesiaChoice: '' ,dysdiadochokinesiaText: '' ,rombergChoice: '' ,rombergText: '' ,rombergPositiveChoice: '' ,rombergPostiveText: '' ,rombergNegativeChoice: '' ,rombergNegativeText: '' ,ataxiaChoice: '' ,ataxiaText: '' ,ataxiaTruncalChoice: '' ,ataxiaTruncalText: '' ,ataxiaAppendicularChoice: '' ,ataxiaAppendicularText: '' ,ataxiaGlobalChoice: '' ,ataxiaGlobalText: '' ,nystagmusChoice: '' ,
        nystagmusText: '' ,dysphasiaChoice: '' ,dysphasiaText: '' ,dysphasiaExpressiveChoice: '' ,dysphasiaExpressiveText: '' ,dysphasiaReceptiveChoice: '' ,dysphasiaReceptiveText: '' ,dysphasiaGlobalChoice: '' ,dysphasiaGlobalText: '' ,abuliaChoice: '' ,abuliaText: '' ,astereognosiaChoice: '' ,astereognosiaText: '' ,akineticChoice: '' ,akineticText: '' ,hemianopsiaChoice: '' ,hemianopsiaText: '' ,hemianopsiaRightChoice: '' ,hemianopsiaRightText: '' ,hemianopsiaLeftChoice: '' ,hemianopsiaLeftText: '' ,hemianopsiaBitemporalChoice: '' ,
        hemianopsiaBitemporalText: '' ,hemianopsiaTemporalChoice: '' ,hemianopsiaTemporalText: '' ,hemianopsiaNasalChoice: '' ,hemianopsiaNasalText: '' ,cranialChoice: '' ,cranialText: '' ,cranialLeftChoice: '' ,cranialLeftText: '' ,olfactoryLeftChoice: '' ,olfactoryLeftText: '' ,opticLeftChoice: '' ,opticLeftText: '' ,oculomotorLeftChoice: '' ,oculomotorLeftText: '' ,trochlearLeftChoice: '' ,trochlearLeftText: '' ,trigeminalLeftChoice: '' ,trigeminalLeftText: '' ,abducentLeftChoice: '' ,abducentLeftText: '' ,facialLeftChoice: '' ,
        facialLeftText: '' ,vestibulocochlearLeftChoice: '' ,vestibulocochlearLeftText: '' ,glossopharyngealLeftChoice: '' ,glossopharyngealLeftText: '' ,vagusLeftChoice: '' ,vagusLeftText: '' ,accessoryLeftChoice: '' ,accessoryLeftText: '' ,hypoglossalLeftChoice: '' ,hypoglossalLeftText: '' ,cranialRightChoice: '' ,cranialRightText: '' ,olfactoryRightChoice: '' ,olfactoryRightText: '' ,opticRightChoice: '' ,opticRightText: '' ,oculomotorRightChoice: '' ,oculomotorRightText: '' ,trochlearRightChoice: '' ,trochlearRightText: '' ,
        trigeminalRightChoice: '' ,trigeminalRightText: '' ,abducentRightChoice: '' ,abducentRightText: '' ,facialRightChoice: '' ,facialRightText: '' ,vestibulocochlearRightChoice: '' ,vestibulocochlearRightText: '' ,glossopharyngealRightChoice: '' ,glossopharyngealRightText: '' ,vagusRightChoice: '' ,vagusRightText: '' ,accessoryRightChoice: '' ,accessoryRightText: '' ,hypoglossalRightChoice: '' ,hypoglossalRightText: '' ,spineRangeChoice: '' ,spineRangeText: '' ,spineRangeCervicalChoice: '' ,spineRangeCervicalText: '' ,
        spineRangeCervicalFlexionChoice: '' ,spineRangeCervicalFlexionText: '' ,spineRangeCervicalFlexionReducedChoice: '' ,spineRangeCervicalFlexionReducedText: '' ,spineRangeCervicalFlexionPainfulChoice: '' ,spineRangeCervicalFlexionPainfulText: '' ,spineRangeCervicalExtensionChoice: '' ,spineRangeCervicalExtensionText: '' ,spineRangeCervicalExtensionReducedChoice: '' ,spineRangeCervicalExtensionReducedText: '' ,spineRangeCervicalExtensionPainfulChoice: '' ,spineRangeCervicalExtensionPainfulText: '' ,spineRangeCervicalLateralBendingChoice: '' ,
        spineRangeCervicalLateralBendingText: '' ,spineRangeCervicalLateralBendingReducedChoice: '' ,spineRangeCervicalLateralBendingReducedText: '' ,spineRangeCervicalLateralBendingPainfulChoice: '' ,spineRangeCervicalLateralBendingPainfulText: '' ,spineRangeCervicalRotationChoice: '' ,spineRangeCervicalRotationText: '' ,spineRangeCervicalRotationReducedChoice: '' ,spineRangeCervicalRotationReducedText: '' ,spineRangeCervicalRotationPainfulChoice: '' ,spineRangeCervicalRotationPainfulText: '' ,spineRangeThoracicChoice: '' ,
        spineRangeThoracicText: '' ,spineRangeThoracicFlexionChoice: '' ,spineRangeThoracicFlexionText: '' ,spineRangeThoracicFlexionReducedChoice: '' ,spineRangeThoracicFlexionReducedText: '' ,spineRangeThoracicFlexionPainfulChoice: '' ,spineRangeThoracicFlexionPainfulText: '' ,spineRangeThoracicExtensionChoice: '' ,spineRangeThoracicExtensionText: '' ,spineRangeThoracicExtensionReducedChoice: '' ,spineRangeThoracicExtensionReducedText: '' ,spineRangeThoracicExtensionPainfulChoice: '' ,spineRangeThoracicExtensionPainfulText: '' ,
        spineRangeThoracicLateralBendingChoice: '' ,spineRangeThoracicLateralBendingText: '' ,spineRangeThoracicLateralBendingReducedChoice: '' ,spineRangeThoracicLateralBendingReducedText: '' ,spineRangeThoracicLateralBendingPainfulChoice: '' ,spineRangeThoracicLateralBendingPainfulText: '' ,spineRangeThoracicRotationChoice: '' ,spineRangeThoracicRotationText: '' ,spineRangeThoracicRotationReducedChoice: '' ,spineRangeThoracicRotationReducedText: '' ,spineRangeThoracicRotationPainfulChoice: '' ,spineRangeThoracicRotationPainfulText: '' ,
        spineRangeLumbarChoice: '' ,spineRangeLumbarText: '' ,spineRangeLumbarFlexionChoice: '' ,spineRangeLumbarFlexionText: '' ,spineRangeLumbarFlexionReducedChoice: '' ,spineRangeLumbarFlexionReducedText: '' ,spineRangeLumbarFlexionPainfulChoice: '' ,spineRangeLumbarFlexionPainfulText: '' ,spineRangeLumbarExtensionChoice: '' ,spineRangeLumbarExtensionText: '' ,spineRangeLumbarExtensionReducedChoice: '' ,spineRangeLumbarExtensionReducedText: '' ,spineRangeLumbarExtensionPainfulChoice: '' ,spineRangeLumbarExtensionPainfulText: '' ,
        spineRangeLumbarLateralBendingChoice: '' ,spineRangeLumbarLateralBendingText: '' ,spineRangeLumbarLateralBendingReducedChoice: '' ,spineRangeLumbarLateralBendingReducedText: '' ,spineRangeLumbarLateralBendingPainfulChoice: '' ,spineRangeLumbarLateralBendingPainfulText: '' ,spineRangeLumbarRotationChoice: '' ,spineRangeLumbarRotationText: '' ,spineRangeLumbarRotationReducedChoice: '' ,spineRangeLumbarRotationReducedText: '' ,spineRangeLumbarRotationPainfulChoice: '' ,spineRangeLumbarRotationPainfulText: '' ,spineTendernessChoice: '' ,
        spineTendernessText: '' ,spineTendernessCervicalChoice: '' ,spineTendernessCervicalText: '' ,spineTendernessThoracicChoicespineTendernessThoracicText: '' ,spineTendernessThoracolumbarChoice: '' ,spineTendernessThoracolumbarText: '' ,spineTendernessLumbarChoice: '' ,spineTendernessLumbarText: '' ,spineTendernessLumbosacralChoice: '' ,spineTendernessLumbosacralText: '' ,spineTendernessSacroiliacChoice: '' ,spineTendernessSacroiliacText: '' ,trendelenbergChoice: '' ,trendelenbergText: '' ,trendelenbergLeftChoice: '' ,trendelenbergLeftText: '' ,
        trendelenbergLeftPresentChoice: '' ,trendelenbergLeftPresentText: '' ,trendelenbergLeftNormalChoice: '' ,trendelenbergLeftNormalText: '' ,trendelenbergRightChoice: '' ,trendelenbergRightText: '' ,trendelenbergRightPresentChoice: '' ,trendelenbergRightPresentText: '' ,trendelenbergRightNormalChoice: '' ,trendelenbergRightNormalText: '' ,standOnTipToeChoice: '' ,standOnTipToeText: '' ,standOnTipToeLeftChoice: '' ,standOnTipToeLeftText: '' ,standOnTipToeLeftWeakChoice: '' ,standOnTipToeLeftWeakText: '' ,standOnTipToeLeftNormalChoice: '' ,
        standOnTipToeLeftNormalText: '' ,standOnTipToeRightChoice: '' ,standOnTipToeRightText: '' ,standOnTipToeRightWeakChoice: '' ,standOnTipToeRightWeakText: '' ,standOnTipToeRightNormalChoice: '' ,standOnTipToeRightNormalText: '' ,spurlingTestChoice: '' ,spurlingTestText: '' ,spurlingTestLeftChoice: '' ,spurlingTestLeftText: '' ,spurlingTestLeftPresentChoice: '' ,spurlingTestLeftPresentText: '' ,spurlingTestLeftAbsentChoice: '' ,spurlingTestLeftAbsentText: '' ,spurlingTestRightChoice: '' ,spurlingTestRightText: '' ,spurlingTestRightPresentChoice: '' ,
        spurlingTestRightPresentText: '' ,spurlingTestRightAbsentChoice: '' ,spurlingTestRightAbsentText: '' ,abductionReliefChoice: '' ,abductionReliefText: '' ,abductionReliefLeftChoice: '' ,abductionReliefLeftText: '' ,abductionReliefLeftPresentChoice: '' ,abductionReliefLeftPresentText: '' ,abductionReliefLeftAbsentChoice: '' ,abductionReliefLeftAbsentText: '' ,abductionReliefRightChoice: '' ,abductionReliefRightText: '' ,abductionReliefRightPresentChoice: '' ,abductionReliefRightPresentText: '' ,abductionReliefRightAbsentChoice: '' ,
        abductionReliefRightAbsentText: '' ,straightLegRaisingChoice: '' ,straightLegRaisingText: '' ,straightLegRaisingLeftChoice: '' ,straightLegRaisingLeftText: '' ,straightLegRaisingLeftPresentChoice: '' ,straightLegRaisingLeftPresentText: '' ,straightLegRaisingLeftAbsentChoice: '' ,straightLegRaisingLeftAbsentText: '' ,straightLegRaisingRightChoice: '' ,straightLegRaisingRightText: '' ,straightLegRaisingRightPresentChoice: '' ,straightLegRaisingRightPresentText: '' ,straightLegRaisingRightAbsentChoice: '' ,straightLegRaisingRightAbsentText: '' ,
        reverseStraightLegChoice: '' ,reverseStraightLegText: '' ,reverseStraightLegLeftChoice: '' ,reverseStraightLegLeftText: '' ,reverseStraightLegLeftPresentChoice: '' ,reverseStraightLegLeftPresentText: '' ,reverseStraightLegLeftAbsentChoice: '' ,reverseStraightLegLeftAbsentText: '' ,reverseStraightLegRightChoice: '' ,reverseStraightLegRightText: '' ,reverseStraightLegRightPresentChoice: '' ,reverseStraightLegRightPresentText: '' ,reverseStraightLegRightAbsentChoice: '' ,reverseStraightLegRightAbsentText: '' ,toneChoice: '' ,toneText: '' ,
        toneLeftUpperLimbChoice: '' ,toneLeftUpperLimbText: '' ,toneLeftUpperLimbHypertoniaChoice: '' ,toneLeftUpperLimbHypertoniaText: '' ,toneLeftUpperLimbHypotoniaChoice: '' ,toneLeftUpperLimbHypotoniaText: '' ,toneLeftUpperLimbAtoniaChoice: '' ,toneLeftUpperLimbAtoniaText: '' ,toneRightUpperLimbChoice: '' ,toneRightUpperLimbText: '' ,toneRightUpperLimbHypertoniaChoice: '' ,toneRightUpperLimbHypertoniaText: '' ,toneRightUpperLimbHypotoniaChoice: '' ,toneRightUpperLimbHypotoniaText: '' ,toneRightUpperLimbAtoniaChoice: '' ,toneRightUpperLimbAtoniaText: '' ,
        toneLeftLowerLimbChoice: '' ,toneLeftLowerLimbText: '' ,toneLeftLowerLimbHypertoniaChoice: '' ,toneLeftLowerLimbHypertoniaText: '' ,toneLeftLowerLimbHypotoniaChoice: '' ,toneLeftLowerLimbHypotoniaText: '' ,toneLeftLowerLimbAtoniaChoice: '' ,toneLeftLowerLimbAtoniaText: '' ,toneRightLowerLimbChoice: '' ,toneRightLowerLimbText: '' ,toneRightLowerLimbHypertoniaChoice: '' ,toneRightLowerLimbHypertoniaText: '' ,toneRightLowerLimbHypotoniaChoice: '' ,toneRightLowerLimbHypotoniaText: '' ,toneRightLowerLimbAtoniaChoice: '' ,toneRightLowerLimbAtoniaText: '' ,
        toneGlobalChoice: '' ,toneGlobalText: '' ,toneGlobalHypertoniaChoice: '' ,toneGlobalHypertoniaText: '' ,toneGlobalHypotoniaChoice: '' ,toneGlobalHypotoniaText: '' ,toneGlobalAbsentChoice: '' ,toneGlobalAbsentText: '' ,powerChoice: '' ,powerText: '' ,powerLeftUpperLimbChoice: '' ,powerLeftUpperLimbText: '' ,powerLeftUpperLimbC5Choice: '' ,powerLeftUpperLimbC5Text: '' ,powerLeftUpperLimbC6Choice: '' ,powerLeftUpperLimbC6Text: '' ,powerLeftUpperLimbC7Choice: '' ,powerLeftUpperLimbC7Text: '' ,powerLeftUpperLimbC8Choice: '' ,powerLeftUpperLimbC8Text: '' ,
        powerLeftUpperLimbT1Choice: '' ,powerLeftUpperLimbT1Text: '' ,powerRightUpperLimbChoice: '' ,powerRightUpperLimbText: '' ,powerRightUpperLimbC5Choice: '' ,powerRightUpperLimbC5Text: '' ,powerRightUpperLimbC6Choice: '' ,powerRightUpperLimbC6Text: '' ,powerRightUpperLimbC7Choice: '' ,powerRightUpperLimbC7Text: '' ,powerRightUpperLimbC8Choice: '' ,powerRightUpperLimbC8Text: '' ,powerRightUpperLimbT1Choice: '' ,powerRightUpperLimbT1Text: '' ,powerLeftLowerLimbChoice: '' ,powerLeftLowerLimbText: '' ,powerLeftLowerLimbC5Choice: '' ,powerLeftLowerLimbC5Text: '' ,
        powerLeftLowerLimbC6Choice: '' ,powerLeftLowerLimbC6Text: '' ,powerLeftLowerLimbC7Choice: '' ,powerLeftLowerLimbC7Text: '' ,powerLeftLowerLimbC8Choice: '' ,powerLeftLowerLimbC8Text: '' ,powerLeftLowerLimbT1Choice: '' ,powerLeftLowerLimbT1Text: '' ,powerRightLowerLimbChoice: '' ,powerRightLowerLimbText: '' ,powerRightLowerLimbC5Choice: '' ,powerRightLowerLimbC5Text: '' ,powerRightLowerLimbC6Choice: '' ,powerRightLowerLimbC6Text: '' ,powerRightLowerLimbC7Choice: '' ,powerRightLowerLimbC7Text: '' ,powerRightLowerLimbC8Choice: '' ,powerRightLowerLimbC8Text: '' ,
        powerRightLowerLimbT1Choice: '' ,powerRightLowerLimbT1Text: '' ,wastingChoice: '' ,wastingText: '' ,wastingLeftUpperLimbChoice: '' ,wastingLeftUpperLimbText: '' ,wastingRightUpperLimbChoice: '' ,wastingRightUpperLimbText: '' ,wastingLeftLowerLimbChoice: '' ,wastingLeftLowerLimbText: '' ,wastingRightLowerLimbChoice: '' ,wastingRightLowerLimbText: '' ,fasciculationsChoice: '' ,fasciculationsText: '' ,fasciculationsLeftUpperLimbChoice: '' ,fasciculationsLeftUpperLimbText: '' ,fasciculationsRightUpperLimbChoice: '' ,fasciculationsRightUpperLimbText: '' ,
        fasciculationsLeftLowerLimbChoice: '' ,fasciculationsLeftLowerLimbText: '' ,fasciculationsRightLowerLimbChoice: '' ,fasciculationsRightLowerLimbText: '' ,deepTendonChoice: '' ,deepTendonText: '' ,deepTendonLeftBicepsChoice: '' ,deepTendonLeftBicepsText: '' ,deepTendonLeftBicepsHyperChoice: '' ,deepTendonLeftBicepsHyperText: '' ,deepTendonLeftBicepsHypoChoice: '' ,deepTendonLeftBicepsHypoText: '' ,deepTendonLeftBicepsAChoice: '' ,deepTendonLeftBicepsAText: '' ,deepTendonRightBicepsChoice: '' ,deepTendonRightBicepsText: '' ,deepTendonRightBicepsHyperChoice: '' ,
        deepTendonRightBicepsHyperText: '' ,deepTendonRightBicepsHypoChoice: '' ,deepTendonRightBicepsHypoText: '' ,deepTendonRightBicepsAChoice: '' ,deepTendonRightBicepsAText: '' ,deepTendonLeftTricepsChoice: '' ,deepTendonLeftTricepsText: '' ,deepTendonLeftTricepsHyperChoice: '' ,deepTendonLeftTricepsHyperText: '' ,deepTendonLeftTricepsHypoChoice: '' ,deepTendonLeftTricepsHypoText: '' ,deepTendonLeftTricepsAChoice: '' ,deepTendonLeftTricepsAText: '' ,deepTendonRightTricepsChoice: '' ,deepTendonRightTricepsText: '' ,deepTendonRightTricepsHyperChoice: '' ,
        deepTendonRightTricepsHyperText: '' ,deepTendonRightTricepsHypoChoice: '' ,deepTendonRightTricepsHypoText: '' ,deepTendonRightTricepsAChoice: '' ,deepTendonRightTricepsAText: '' ,deepTendonInvertedRadialChoice: '' ,deepTendonInvertedRadialText: '' ,deepTendonInvertedRadialLeftChoice: '' ,deepTendonInvertedRadialLeftText: '' ,deepTendonInvertedRadialRightChoice: '' ,deepTendonInvertedRadialRightText: '' ,deepTendonLeftKneeChoice: '' ,deepTendonLeftKneeText: '' ,deepTendonLeftKneeHyperChoice: '' ,deepTendonLeftKneeHyperText: '' ,deepTendonLeftKneeHypoChoice: '' ,
        deepTendonLeftKneeHypoText: '' ,deepTendonLeftKneeAChoice: '' ,deepTendonLeftKneeAText: '' ,deepTendonLeftKneePendularChoice: '' ,deepTendonLeftKneePendularText: '' ,deepTendonRightKneeChoice: '' ,deepTendonRightKneeText: '' ,deepTendonRightKneeHyperChoice: '' ,deepTendonRightKneeHyperText: '' ,deepTendonRightKneeHypoChoice: '' ,deepTendonRightKneeHypoText: '' ,deepTendonRightKneeAChoice: '' ,deepTendonRightKneeAText: '' ,deepTendonRightKneePendularChoice: '' ,deepTendonRightKneePendularText: '' ,deepTendonLeftAnkleChoice: '' ,deepTendonLeftAnkleText: '' ,
        deepTendonLeftAnkleHyperChoice: '' ,deepTendonLeftAnkleHyperText: '' ,deepTendonLeftAnkleHypoChoice: '' ,deepTendonLeftAnkleHypoText: '' ,deepTendonLeftAnkleAChoice: '' ,deepTendonLeftAnkleAText: '' ,deepTendonRightAnkleChoice: '' ,deepTendonRightAnkleText: '' ,deepTendonRightAnkleHyperChoice: '' ,deepTendonRightAnkleHyperText: '' ,deepTendonRightAnkleHypoChoice: '' ,deepTendonRightAnkleHypoText: '' ,deepTendonRightAnkleAChoice: '' ,deepTendonRightAnkleAText: '' ,hoffmansChoice: '' ,hoffmansText: '' ,hoffmansLeftChoice: '' ,hoffmansLeftText: '' ,
        hoffmansRightChoice: '' ,hoffmansRightText: '' ,ankleClonusChoice: '' ,ankleClonusText: '' ,ankleClonusLeftChoice: '' ,ankleClonusLeftText: '' ,ankleClonusRightChoice: '' ,ankleClonusRightText: '' ,babinskiChoice: '' ,babinskiText: '' ,babinskiLeftChoice: '' ,babinskiLeftText: '' ,babinskiLeftUpGoingChoice: '' ,babinskiLeftUpGoingText: '' ,babinskiLeftDownGoingChoice: '' ,babinskiLeftDownGoingText: '' ,babinskiLeftEquivocalChoice: '' ,babinskiLeftEquivocalText: '' ,babinskiRightChoice: '' ,babinskiRightText: '' ,babinskiRightUpGoingChoice: '' ,babinskiRightUpGoingText: '' ,
        babinskiRightDownGoingChoice: '' ,babinskiRightDownGoingText: '' ,babinskiRightEquivocalChoice: '' ,babinskiRightEquivocalText: '' ,bulbocavernosusChoice: '' ,bulbocavernosusText: '' ,bulbocavernosusPresentChoice: '' ,bulbocavernosusPresentText: '' ,bulbocavernosusAbsentChoice: '' ,bulbocavernosusAbsentText: '' ,beevorsChoice: '' ,beevorsText: '' ,beevorsPresentChoice: '' ,beevorsPresentText: '' ,beevorsAbsentChoice: '' ,beevorsAbsentText: '' ,rombergTestChoice: '' ,rombergTestText: '' ,rombergTestAbsentChoice: '' ,rombergTestAbsentText: '' ,rombergTestPresentChoice: '' ,
        rombergTestPresentText: '' ,rombergTestPresentLeftChoice: '' ,rombergTestPresentLeftText: '' ,rombergTestPresentRightChoice: '' ,rombergTestPresentRightText: '' ,motorLevelChoice: '' ,motorLevelText: '' ,motorLevelLeftChoice: '' ,motorLevelLeftText: '' ,motorLevelLeftC5Choice: '' ,motorLevelLeftC5Text: '' ,motorLevelLeftC6Choice: '' ,motorLevelLeftC6Text: '' ,motorLevelLeftC7Choice: '' ,motorLevelLeftC7Text: '' ,motorLevelLeftC8Choice: '' ,motorLevelLeftC8Text: '' ,motorLevelLeftT1Choice: '' ,motorLevelLeftT1Text: '' ,motorLevelLeftL2Choice: '' ,motorLevelLeftL2Text: '' ,
        motorLevelLeftL3Choice: '' ,motorLevelLeftL3Text: '' ,motorLevelLeftL4Choice: '' ,motorLevelLeftL4Text: '' ,motorLevelLeftL5Choice: '' ,motorLevelLeftL5Text: '' ,motorLevelLeftS1Choice: '' ,motorLevelLeftS1Text: '' ,motorLevelRightChoice: '' ,motorLevelRightText: '' ,motorLevelRightC5Choice: '' ,motorLevelRightC5Text: '' ,motorLevelRightC6Choice: '' ,motorLevelRightC6Text: '' ,motorLevelRightC7Choice: '' ,motorLevelRightC7Text: '' ,motorLevelRightC8Choice: '' ,motorLevelRightC8Text: '' ,motorLevelRightT1Choice: '' ,motorLevelRightT1Text: '' ,motorLevelRightL2Choice: '' ,
        motorLevelRightL2Text: '' ,motorLevelRightL3Choice: '' ,motorLevelRightL3Text: '' ,motorLevelRightL4Choice: '' ,motorLevelRightL4Text: '' ,motorLevelRightL5Choice: '' ,motorLevelRightL5Text: '' ,motorLevelRightS1Choice: '' ,motorLevelRightS1Text: '' ,sensoryLevelChoice: '' ,sensoryLevelText: '' ,sensoryLevelLeftChoice: '' ,sensoryLevelLeftText: '' ,sensoryLevelLeftC2Choice: '' ,sensoryLevelLeftC2Text: '' ,sensoryLevelLeftC3Choice: '' ,sensoryLevelLeftC3Text: '' ,sensoryLevelLeftC4Choice: '' ,sensoryLevelLeftC4Text: '' ,sensoryLevelLeftC5Choice: '' ,sensoryLevelLeftC5Text: '' ,
        sensoryLevelLeftC6Choice: '' ,sensoryLevelLeftC6Text: '' ,sensoryLevelLeftC7Choice: '' ,sensoryLevelLeftC7Text: '' ,sensoryLevelLeftC8Choice: '' ,sensoryLevelLeftC8Text: '' ,sensoryLevelLeftT1Choice: '' ,sensoryLevelLeftT1Text: '' ,sensoryLevelLeftT2Choice: '' ,sensoryLevelLeftT2Text: '' ,sensoryLevelLeftT3Choice: '' ,sensoryLevelLeftT3Text: '' ,sensoryLevelLeftT4Choice: '' ,sensoryLevelLeftT4Text: '' ,sensoryLevelLeftT5Choice: '' ,sensoryLevelLeftT5Text: '' ,sensoryLevelLeftT6Choice: '' ,sensoryLevelLeftT6Text: '' ,sensoryLevelLeftT7Choice: '' ,sensoryLevelLeftT7Text: '' ,
        sensoryLevelLeftT8Choice: '' ,sensoryLevelLeftT8Text: '' ,sensoryLevelLeftT9Choice: '' ,sensoryLevelLeftT9Text: '' ,sensoryLevelLeftT10Choice: '' ,sensoryLevelLeftT10Text: '' ,sensoryLevelLeftT11Choice: '' ,sensoryLevelLeftT11Text: '' ,sensoryLevelLeftT12Choice: '' ,sensoryLevelLeftT12Text: '' ,sensoryLevelLeftL1Choice: '' ,sensoryLevelLeftL1Text: '' ,sensoryLevelLeftL2Choice: '' ,sensoryLevelLeftL2Text: '' ,sensoryLevelLeftL3Choice: '' ,sensoryLevelLeftL3Text: '' ,sensoryLevelLeftL4Choice: '' ,sensoryLevelLeftL4Text: '' ,sensoryLevelLeftL5Choice: '' ,sensoryLevelLeftL5Text: '' ,
        sensoryLevelLeftS1Choice: '' ,sensoryLevelLeftS1Text: '' ,sensoryLevelLeftS3Choice: '' ,sensoryLevelLeftS3Text: '' ,sensoryLevelLeftS45Choice: '' ,sensoryLevelLeftS45Text: '' ,sensoryLevelRightChoice: '' ,sensoryLevelRightText: '' ,sensoryLevelRightC2Choice: '' ,sensoryLevelRightC2Text: '' ,sensoryLevelRightC3Choice: '' ,sensoryLevelRightC3Text: '' ,sensoryLevelRightC4Choice: '' ,sensoryLevelRightC4Text: '' ,sensoryLevelRightC5Choice: '' ,sensoryLevelRightC5Text: '' ,sensoryLevelRightC6Choice: '' ,sensoryLevelRightC6Text: '' ,sensoryLevelRightC7Choice: '' ,
        sensoryLevelRightC7Text: '' ,sensoryLevelRightC8Choice: '' ,sensoryLevelRightC8Text: '' ,sensoryLevelRightT1Choice: '' ,sensoryLevelRightT1Text: '' ,sensoryLevelRightT2Choice: '' ,sensoryLevelRightT2Text: '' ,sensoryLevelRightT3Choice: '' ,sensoryLevelRightT3Text: '' ,sensoryLevelRightT4Choice: '' ,sensoryLevelRightT4Text: '' ,sensoryLevelRightT5Choice: '' ,sensoryLevelRightT5Text: '' ,sensoryLevelRightT6Choice: '' ,sensoryLevelRightT6Text: '' ,sensoryLevelRightT7Choice: '' ,sensoryLevelRightT7Text: '' ,sensoryLevelRightT8Choice: '' ,sensoryLevelRightT8Text: '' ,
        sensoryLevelRightT9Choice: '' ,sensoryLevelRightT9Text: '' ,sensoryLevelRightT10Choice: '' ,sensoryLevelRightT10Text: '' ,sensoryLevelRightT11Choice: '' ,sensoryLevelRightT11Text: '' ,sensoryLevelRightT12Choice: '' ,sensoryLevelRightT12Text: '' ,sensoryLevelRightL1Choice: '' ,sensoryLevelRightL1Text: '' ,sensoryLevelRightL2Choice: '' ,sensoryLevelRightL2Text: '' ,sensoryLevelRightL3Choice: '' ,sensoryLevelRightL3Text: '' ,sensoryLevelRightL4Choice: '' ,sensoryLevelRightL4Text: '' ,sensoryLevelRightL5Choice: '' ,sensoryLevelRightL5Text: '' ,sensoryLevelRightS1Choice: '' ,
        sensoryLevelRightS1Text: '' ,sensoryLevelRightS3Choice: '' ,sensoryLevelRightS3Text: '' ,sensoryLevelRightS45Choice: '' ,sensoryLevelRightS45Text: '' ,neurologicalLevelChoice: '' ,neurologicalLevelText: '' ,neurologicalLevelC2Choice: '' ,neurologicalLevelC2Text: '' ,neurologicalLevelC3Choice: '' ,neurologicalLevelC3Text: '' ,neurologicalLevelC4Choice: '' ,neurologicalLevelC4Text: '' ,neurologicalLevelC5Choice: '' ,neurologicalLevelC5Text: '' ,neurologicalLevelC6Choice: '' ,neurologicalLevelC6Text: '' ,neurologicalLevelC7Choice: '' ,neurologicalLevelC7Text: '' ,
        neurologicalLevelC8Choice: '' ,neurologicalLevelC8Text: '' ,neurologicalLevelT1Choice: '' ,neurologicalLevelT1Text: '' ,neurologicalLevelT2Choice: '' ,neurologicalLevelT2Text: '' ,neurologicalLevelT3Text: '' ,neurologicalLevelT4Choice: '' ,neurologicalLevelT4Text: '' ,neurologicalLevelT5Choice: '' ,neurologicalLevelT5Text: '' ,neurologicalLevelT6Choice: '' ,neurologicalLevelT6Text: '' ,neurologicalLevelT7Choice: '' ,neurologicalLevelT7Text: '' ,neurologicalLevelT8Choice: '' ,neurologicalLevelT8Text: '' ,neurologicalLevelT9Choice: '' ,neurologicalLevelT9Text: '' ,
        neurologicalLevelT10Choice: '' ,neurologicalLevelT10Text: '' ,neurologicalLevelT11Choice: '' ,neurologicalLevelT11Text: '' ,neurologicalLevelT12Choice: '' ,neurologicalLevelT12Text: '' ,neurologicalLevelL1Choice: '' ,neurologicalLevelL1Text: '' ,neurologicalLevelL2Choice: '' ,neurologicalLevelL2Text: '' ,neurologicalLevelL3Choice: '' ,neurologicalLevelL3Text: '' ,neurologicalLevelL4Choice: '' ,neurologicalLevelL4Text: '' ,neurologicalLevelL5Choice: '' ,neurologicalLevelL5Text: '' ,neurologicalLevelS1Choice: '' ,neurologicalLevelS1Text: '' ,neurologicalLevelS3Choice: '' ,
        neurologicalLevelS3Text: '' ,neurologicalLevelS45Choice: '' ,neurologicalLevelS45Text: '' ,
        hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
            historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: true, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false,
            diagnosisTab: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false
        })
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurosurgery/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for(var j in res.data.vitalColors)
                    {
                    res.data.vitalColors[j].temperature = res.data.vitalColors[j].temperature === 0?"":res.data.vitalColors[j].temperature
                    res.data.vitalColors[j].pulse = res.data.vitalColors[j].pulse === 0?"":res.data.vitalColors[j].pulse
                    res.data.vitalColors[j].glucometer = res.data.vitalColors[j].glucometer === 0?"":res.data.vitalColors[j].glucometer
                    res.data.vitalColors[j].spO2 = res.data.vitalColors[j].spO2 === 0?"":res.data.vitalColors[j].spO2
                    res.data.vitalColors[j].diastolic = res.data.vitalColors[j].diastolic === 0?"":res.data.vitalColors[j].diastolic
                    res.data.vitalColors[j].systolic = res.data.vitalColors[j].systolic === 0?"":res.data.vitalColors[j].systolic
                    res.data.vitalColors[j].respRate = res.data.vitalColors[j].respRate === 0?"":res.data.vitalColors[j].respRate
                    }
                    res.data.weight = res.data.weight === 0?"":res.data.weight
                    res.data.height = res.data.height === 0?"":res.data.height
                    res.data.headCircumference = res.data.headCircumference === 0?"":res.data.headCircumference
                    res.data.muac = res.data.muac === 0?"":res.data.muac
                    res.data.bmi = res.data.bmi === 0?"":res.data.bmi
                    res.data.waistHeightRatio = res.data.waistHeightRatio === 0?"":res.data.waistHeightRatio
                this.setState({
                    showHeightGraph: res.data.showHeightGraph, showWeightGraph: res.data.showWeightGraph, id: res.data.id, referringDoctorName: res.data.referringDoctorName, companyId: res.data.companyId, patientId: res.data.patientId, patientDiagnosis: res.data.patientDiagnosis, diagnosisText: res.data.diagnosisText, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, rehabilitations: res.data.rehabilitations, referrals: res.data.referrals, treatments: res.data.treatments, drugs: res.data.drugs, frequencys: res.data.frequencys, routes: res.data.routes, durations: res.data.durations, formulations: res.data.formulations, services: res.data.services, investigations: res.data.investigations, findings: res.data.findings, referralLetters: res.data.referralLetters, msg: msg,appointmentTimes: res.data.appointmentTimes,
                    feedbackHistory: res.data.feedback.history, feedbackExamination: res.data.feedback.examination, feedbackReviewConsult: res.data.feedback.reviewConsult, feedbackInvestigations: res.data.feedback.investigations, feedbackDiagnosis: res.data.feedback.diagnosis, feedbackPrescriptions: res.data.feedback.prescriptions, feedbackDoctor: res.data.feedback.doctor, dateLists: res.data.feedback.dateLists,
            /* HISTORY  */  presentingComplaintList: res.data.history.presentingComplaintList, historyOfPresentingComplaints: res.data.history.historyOfPresentingComplaints, pastMedicalHistory: res.data.history.pastMedicalHistory, currentMedication: res.data.history.currentMedication, allergyHistory: res.data.history.allergyHistory,
                    coughChoice: res.data.history.coughChoice, coughText: res.data.history.coughText, coryzaChoice: res.data.history.coryzaChoice, coryzaText: res.data.history.coryzaText, sobChoice: res.data.history.sobChoice, sobText: res.data.history.sobText, sneazingChoice: res.data.history.sneazingChoice, sneazingText: res.data.history.sneazingText, phelgmChoice: res.data.history.phelgmChoice, phelgmText: res.data.history.phelgmText, sputumChoice: res.data.history.sputumChoice, sputumText: res.data.history.sputumText, haemoptysisChoice: res.data.history.haemoptysisChoice, haemoptysisText: res.data.history.haemoptysisText, wheezingChoice: res.data.history.wheezingChoice, wheezingText: res.data.history.wheezingText, nasalChoice: res.data.history.nasalChoice, nasalText: res.data.history.nasalText, soreChoice: res.data.history.soreChoice, soreText: res.data.history.soreText, chestPainChoice: res.data.history.chestPainChoice, chestPainText: res.data.history.chestPainText, respitoryNotes: res.data.history.respitoryNotes,
                    nauseaChoice: res.data.history.nauseaChoice, nauseaText: res.data.history.nauseaText, vomitingChoice: res.data.history.vomitingChoice, vomitingText: res.data.history.vomitingText, diarrhoeaChoice: res.data.history.diarrhoeaChoice, diarrhoeaText: res.data.history.diarrhoeaText, abdominialChoice: res.data.history.abdominialChoice, abdominialText: res.data.history.abdominialText, flatulanceChoice: res.data.history.flatulanceChoice, flatulanceText: res.data.history.flatulanceText, flatulentChoice: res.data.history.flatulentChoice, flatulentText: res.data.history.flatulentText, waterChoice: res.data.history.waterChoice, waterText: res.data.history.waterText, constipationChoice: res.data.history.constipationChoice, constipationText: res.data.history.constipationText, prChoice: res.data.history.prChoice, prText: res.data.history.prText, wtChoice: res.data.history.wtChoice, wtText: res.data.history.wtText, melenaChoice: res.data.history.melenaChoice, melenaText: res.data.history.melenaText, jaundiceChoice: res.data.history.jaundiceChoice, jaundiceText: res.data.history.jaundiceText, loaChoice: res.data.history.loaChoice, loaText: res.data.history.loaText, lowChoice: res.data.history.lowChoice, lowText: res.data.history.lowText, gitNotes: res.data.history.gitNotes,
                    dysuriaChoice: res.data.history.dysuriaChoice, dysuriaText: res.data.history.dysuriaText, frequencyChoice: res.data.history.frequencyChoice, frequencyText: res.data.history.frequencyText, haematuriaChoice: res.data.history.haematuriaChoice, haematuriaText: res.data.history.haematuriaText, weakChoice: res.data.history.weakChoice, weakText: res.data.history.weakText, strainingChoice: res.data.history.strainingChoice, strainingText: res.data.history.strainingText, urineChoice: res.data.history.urineChoice, urineText: res.data.history.urineText, urethalChoice: res.data.history.urethalChoice, urethalText: res.data.history.urethalText, pvChoice: res.data.history.pvChoice, pvText: res.data.history.pvText, nocturiaChoice: res.data.history.nocturiaChoice, nocturiaText: res.data.history.nocturiaText, genitalChoice: res.data.history.genitalChoice, genitalText: res.data.history.genitalText, lossChoice: res.data.history.lossChoice, lossText: res.data.history.lossText, erectileChoice: res.data.history.erectileChoice, erectileText: res.data.history.erectileText, gusNotes: res.data.history.gusNotes,
                    dysaruniaChoice: res.data.history.dysaruniaChoice, dysaruniaText: res.data.history.dysaruniaText, dysmemorrhoeaChoice: res.data.history.dysmemorrhoeaChoice, dysmemorrhoeaText: res.data.history.dysmemorrhoeaText, imbChoice: res.data.history.imbChoice, imbText: res.data.history.imbText, contactChoice: res.data.history.contactChoice, contactText: res.data.history.contactText, menorrhagiaChoice: res.data.history.menorrhagiaChoice, menorrhagiaText: res.data.history.menorrhagiaText, lapChoice: res.data.history.lapChoice, lapText: res.data.history.lapText, backacheChoice: res.data.history.backacheChoice, backackeText: res.data.history.backackeText, subfertilityChoice: res.data.history.subfertilityChoice, subfertilityText: res.data.history.subfertilityText, hotChoice: res.data.history.hotChoice, hotText: res.data.history.hotText, lnmpChoice: res.data.history.lnmpChoice, lnmpText: res.data.history.lnmpText, gynaeNotes: res.data.history.gynaeNotes,
                    orthopneaChoice: res.data.history.orthopneaChoice, orthopneaText: res.data.history.orthopneaText, pndChoice: res.data.history.pndChoice, pndText: res.data.history.pndText, easyChoice: res.data.history.easyChoice, easyText: res.data.history.easyText, palpitationsChoice: res.data.history.palpitationsChoice, palpitationsText: res.data.history.palpitationsText, synacopeChoice: res.data.history.synacopeChoice, synacopeText: res.data.history.synacopeText, legSwellingChoice: res.data.history.legSwellingChoice, legSwellingText: res.data.history.legSwellingText, chestPaincvsChoice: res.data.history.chestPaincvsChoice, chestPaincvsText: res.data.history.chestPaincvsText, calfSwellingChoice: res.data.history.calfSwellingChoice, calfSwellingText: res.data.history.calfSwellingText, pinkFrothChoice: res.data.history.pinkFrothChoice, pinkFrothText: res.data.history.pinkFrothText, gelatinousChoice: res.data.history.gelatinousChoice, gelatinousText: res.data.history.gelatinousText, cvsNotes: res.data.history.cvsNotes,
                    headacheChoice: res.data.history.headacheChoice, headacheText: res.data.history.headacheText, paraesthesiaChoice: res.data.history.paraesthesiaChoice, paraesthesiaText: res.data.history.paraesthesiaText, dizzinessChoice: res.data.history.dizzinessChoice, dizzinessText: res.data.history.dizzinessText, backackeChoice: res.data.history.backackeChoice, backacheText: res.data.history.backacheText, photophibiaChoice: res.data.history.photophibiaChoice, photophibiaText: res.data.history.photophibiaText, neckstiffnessChoice: res.data.history.neckstiffnessChoice, neckstiffnessText: res.data.history.neckstiffnessText, fittingChoice: res.data.history.fittingChoice, fittingText: res.data.history.fittingText, paresisChoice: res.data.history.paresisChoice, paresisText: res.data.history.paresisText, paralysisChoice: res.data.history.paralysisChoice, paralysisText: res.data.history.paralysisText, insomniaChoice: res.data.history.insomniaChoice, insomniaText: res.data.history.insomniaText, hypersomniaChoice: res.data.history.hypersomniaChoice, hypersomniaText: res.data.history.hypersomniaText, abnChoice: res.data.history.abnChoice, abnText: res.data.history.abnText, cnsNotes: res.data.history.cnsNotes,
                    feverChoice: res.data.history.feverChoice, feverText: res.data.history.feverText, sweatingChoice: res.data.history.sweatingChoice, sweatingText: res.data.history.sweatingText, chillsChoice: res.data.history.chillsChoice, chillsText: res.data.history.chillsText, rigorsChoice: res.data.history.rigorsChoice, rigorsText: res.data.history.rigorsText, fatigueChoice: res.data.history.fatigueChoice, fatigueText: res.data.history.fatigueText, myalgiaChoice: res.data.history.myalgiaChoice, myalgiaText: res.data.history.myalgiaText, constitutionalNotes: res.data.history.constitutionalNotes,
                    bleedingChoice: res.data.history.bleedingChoice, bleedingText: res.data.history.bleedingText, bruisingChoice: res.data.history.bruisingChoice, bruisingText: res.data.history.bruisingText, swellingChoice: res.data.history.swellingChoice, swellingText: res.data.history.swellingText, deformityChoice: res.data.history.deformityChoice, deformityText: res.data.history.deformityText, mssNotes: res.data.history.mssNotes,
                    rashChoice: res.data.history.rashChoice, rashText: res.data.history.rashText, itchynessChoice: res.data.history.itchynessChoice, itchynessText: res.data.history.itchynessText, blistersChoice: res.data.history.blistersChoice, blistersText: res.data.history.blistersText, lumpsChoice: res.data.history.lumpsChoice, lumpsText: res.data.history.lumpsText, ulcersChoice: res.data.history.ulcersChoice, ulcersText: res.data.history.ulcersText, swellingdemaChoice: res.data.history.swellingdemaChoice, swellingdemaText: res.data.history.swellingdemaText, dematologyNotes: res.data.history.dematologyNotes,
                     /* Extra History */ 
                    painText: res.data.history.painText, durationText: res.data.history.durationText, timingText: res.data.history.timingText, aggravatedByText: res.data.history.aggravatedByText, relievedByText: res.data.history.relievedByText, headacheLeftChoice: res.data.history.headacheLeftChoice, headacheLeftText: res.data.history.headacheLeftText, headacheRightChoice: res.data.history.headacheRightChoice, headacheRightText: res.data.history.headacheRightText, holocranialChoice: res.data.history.holocranialChoice, holocranialText: res.data.history.holocranialText, frontalChoice: res.data.history.frontalChoice, frontalText: res.data.history.frontalText, temporalChoice: res.data.history.temporalChoice, temporalText: res.data.history.temporalText, occipitalChoice: res.data.history.occipitalChoice, occipitalText: res.data.history.occipitalText, periorbitalChoice: res.data.history.periorbitalChoice, periorbitalText: res.data.history.periorbitalText, confusionChoice: res.data.history.confusionChoice, confusionText: res.data.history.confusionText, 
                    changeinCharacterChoice: res.data.history.changeinCharacterChoice, changeinCharacterText: res.data.history.changeinCharacterText, weaknessChoice: res.data.history.weaknessChoice, weaknessText: res.data.history.weaknessText, hemiparesisLeftChoice: res.data.history.hemiparesisLeftChoice, hemiparesisLeftText: res.data.history.hemiparesisLeftText, hemiparesisRightChoice: res.data.history.hemiparesisRightChoice, hemiparesisRightText: res.data.history.hemiparesisRightText, weaknessHemiplegiaChoice: res.data.history.weaknessHemiplegiaChoice, weaknessHemiplegiaText: res.data.history.weaknessHemiplegiaText, hemiplegiaLeftChoice: res.data.history.hemiplegiaLeftChoice, hemiplegiaLeftText: res.data.history.hemiplegiaLeftText, hemiplegiaRightChoice: res.data.history.hemiplegiaRightChoice, hemiplegiaRightText: res.data.history.hemiplegiaRightText, paraparesisChoice: res.data.history.paraparesisChoice, paraparesisText: res.data.history.paraparesisText, paraplegiaChoice: res.data.history.paraplegiaChoice, paraplegiaText: res.data.history.paraplegiaText, quadriparesisChoice: res.data.history.quadriparesisChoice, quadriparesisText: res.data.history.quadriparesisText, 
                    quadriplegiaChoice: res.data.history.quadriplegiaChoice, quadriplegiaText: res.data.history.quadriplegiaText, monoparesisChoice: res.data.history.monoparesisChoice, monoparesisText: res.data.history.monoparesisText, monoparesisLeftChoice: res.data.history.monoparesisLeftChoice, monoparesisLeftText: res.data.history.monoparesisLeftText, monoparesisRightChoice: res.data.history.monoparesisRightChoice, monoparesisRightText: res.data.history.monoparesisRightText, monoparesisUpperLimbChoice: res.data.history.monoparesisUpperLimbChoice, monoparesisUpperLimbText: res.data.history.monoparesisUpperLimbText, monoparesisLowerLimbChoice: res.data.history.monoparesisLowerLimbChoice, monoparesisLowerLimbText: res.data.history.monoparesisLowerLimbText, seizuresChoice: res.data.history.seizuresChoice, seizuresText: res.data.history.seizuresText, seizuresAuraChoice: res.data.history.seizuresAuraChoice, seizuresAuraText: res.data.history.seizuresAuraText, auraVisualChoice: res.data.history.auraVisualChoice, auraVisualText: res.data.history.auraVisualText, auraOlfactoryChoice: res.data.history.auraOlfactoryChoice, auraOlfactoryText: res.data.history.auraOlfactoryText, auraAutonomicChoice: res.data.history.auraAutonomicChoice, 
                    auraAutonomicText: res.data.history.auraAutonomicText, thoracicChoice: res.data.history.thoracicChoice, thoracicText: res.data.history.thoracicText, lumbarChoice: res.data.history.lumbarChoice, lumbarText: res.data.history.lumbarText, lowerBackacheChoice: res.data.history.lowerBackacheChoice, lowerBackacheText: res.data.history.lowerBackacheText, mechanicalChoice: res.data.history.mechanicalChoice, mechanicalText: res.data.history.mechanicalText, sciaticaChoice: res.data.history.sciaticaChoice, sciaticaText: res.data.history.sciaticaText, sciaticaLeftChoice: res.data.history.sciaticaLeftChoice, sciaticaLeftText: res.data.history.sciaticaLeftText, sciaticaRightChoice: res.data.history.sciaticaRightChoice, sciaticaRightText: res.data.history.sciaticaRightText, cervicalPainChoice: res.data.history.cervicalPainChoice, cervicalPainText: res.data.history.cervicalPainText, interScapularPainChoice: res.data.history.interScapularPainChoice, interScapularPainText: res.data.history.interScapularPainText, paraesthesiaUpperLimbChoice: res.data.history.paraesthesiaUpperLimbChoice, 
                    paraesthesiaUpperLimbText: res.data.history.paraesthesiaUpperLimbText, paraesthesiaLowerLimbChoice: res.data.history.paraesthesiaLowerLimbChoice, paraesthesiaLowerLimbText: res.data.history.paraesthesiaLowerLimbText, lossOfBalanceChoice: res.data.history.lossOfBalanceChoice, lossOfBalanceText: res.data.history.lossOfBalanceText, neurogenicClaudicationChoice: res.data.history.neurogenicClaudicationChoice, neurogenicClaudicationText: res.data.history.neurogenicClaudicationText, radiculapathyChoice: res.data.history.radiculapathyChoice, radiculapathyText: res.data.history.radiculapathyText, radiculapathyLeftChoice: res.data.history.radiculapathyLeftChoice, radiculapathyLeftText: res.data.history.radiculapathyLeftText, radiculapathyLeftC2Choice: res.data.history.radiculapathyLeftC2Choice, radiculapathyLeftC2Text: res.data.history.radiculapathyLeftC2Text, radiculapathyLeftC3Choice: res.data.history.radiculapathyLeftC3Choice, radiculapathyLeftC3Text: res.data.history.radiculapathyLeftC3Text, radiculapathyLeftC4Choice: res.data.history.radiculapathyLeftC4Choice, radiculapathyLeftC4Text: res.data.history.radiculapathyLeftC4Text, radiculapathyLeftC5Choice: res.data.history.radiculapathyLeftC5Choice, 
                    radiculapathyLeftC5Text: res.data.history.radiculapathyLeftC5Text, radiculapathyLeftC6Choice: res.data.history.radiculapathyLeftC6Choice, radiculapathyLeftC6Text: res.data.history.radiculapathyLeftC6Text, radiculapathyLeftC7Choice: res.data.history.radiculapathyLeftC7Choice, radiculapathyLeftC7Text: res.data.history.radiculapathyLeftC7Text, radiculapathyLeftC8Choice: res.data.history.radiculapathyLeftC8Choice, radiculapathyLeftC8Text: res.data.history.radiculapathyLeftC8Text, radiculapathyLeftT1Choice: res.data.history.radiculapathyLeftT1Choice, radiculapathyLeftT1Text: res.data.history.radiculapathyLeftT1Text, radiculapathyLeftT2Choice: res.data.history.radiculapathyLeftT2Choice, radiculapathyLeftT2Text: res.data.history.radiculapathyLeftT2Text, radiculapathyLeftT3Choice: res.data.history.radiculapathyLeftT3Choice, radiculapathyLeftT3Text: res.data.history.radiculapathyLeftT3Text, radiculapathyLeftT4Choice: res.data.history.radiculapathyLeftT4Choice, radiculapathyLeftT4Text: res.data.history.radiculapathyLeftT4Text, radiculapathyLeftT5Choice: res.data.history.radiculapathyLeftT5Choice, radiculapathyLeftT5Text: res.data.history.radiculapathyLeftT5Text, radiculapathyLeftT6Choice: res.data.history.radiculapathyLeftT6Choice, 
                    radiculapathyLeftT6Text: res.data.history.radiculapathyLeftT6Text, radiculapathyLeftT7Choice: res.data.history.radiculapathyLeftT7Choice, radiculapathyLeftT7Text: res.data.history.radiculapathyLeftT7Text, radiculapathyLeftT8Choice: res.data.history.radiculapathyLeftT8Choice, radiculapathyLeftT8Text: res.data.history.radiculapathyLeftT8Text, radiculapathyLeftT9Choice: res.data.history.radiculapathyLeftT9Choice, radiculapathyLeftT9Text: res.data.history.radiculapathyLeftT9Text, radiculapathyLeftT10Choice: res.data.history.radiculapathyLeftT10Choice, radiculapathyLeftT10Text: res.data.history.radiculapathyLeftT10Text, radiculapathyLeftT11Choice: res.data.history.radiculapathyLeftT11Choice, radiculapathyLeftT11Text: res.data.history.radiculapathyLeftT11Text, radiculapathyLeftT12Choice: res.data.history.radiculapathyLeftT12Choice, radiculapathyLeftT12Text: res.data.history.radiculapathyLeftT12Text, radiculapathyLeftL1Choice: res.data.history.radiculapathyLeftL1Choice, radiculapathyLeftL1Text: res.data.history.radiculapathyLeftL1Text, radiculapathyLeftL2Choice: res.data.history.radiculapathyLeftL2Choice, radiculapathyLeftL2Text: res.data.history.radiculapathyLeftL2Text, radiculapathyLeftL3Choice: res.data.history.radiculapathyLeftL3Choice, 
                    radiculapathyLeftL3Text: res.data.history.radiculapathyLeftL3Text, radiculapathyLeftL4Choice: res.data.history.radiculapathyLeftL4Choice, radiculapathyLeftL4Text: res.data.history.radiculapathyLeftL4Text, radiculapathyLeftL5Choice: res.data.history.radiculapathyLeftL5Choice, radiculapathyLeftL5Text: res.data.history.radiculapathyLeftL5Text, radiculapathyLeftS1Choice: res.data.history.radiculapathyLeftS1Choice, radiculapathyLeftS1Text: res.data.history.radiculapathyLeftS1Text, radiculapathyLeftS3Choice: res.data.history.radiculapathyLeftS3Choice, radiculapathyLeftS3Text: res.data.history.radiculapathyLeftS3Text, radiculapathyLeftS45Choice: res.data.history.radiculapathyLeftS45Choice, radiculapathyLeftS45Text: res.data.history.radiculapathyLeftS45Text, radiculapathyRightChoice: res.data.history.radiculapathyRightChoice, radiculapathyRightText: res.data.history.radiculapathyRightText, radiculapathyRightC2Choice: res.data.history.radiculapathyRightC2Choice, radiculapathyRightC2Text: res.data.history.radiculapathyRightC2Text, radiculapathyRightC3Choice: res.data.history.radiculapathyRightC3Choice, radiculapathyRightC3Text: res.data.history.radiculapathyRightC3Text, radiculapathyRightC4Choice: res.data.history.radiculapathyRightC4Choice, 
                    radiculapathyRightC4Text: res.data.history.radiculapathyRightC4Text, radiculapathyRightC5Choice: res.data.history.radiculapathyRightC5Choice, radiculapathyRightC5Text: res.data.history.radiculapathyRightC5Text, radiculapathyRightC6Choice: res.data.history.radiculapathyRightC6Choice, radiculapathyRightC6Text: res.data.history.radiculapathyRightC6Text, radiculapathyRightC7Choice: res.data.history.radiculapathyRightC7Choice, radiculapathyRightC7Text: res.data.history.radiculapathyRightC7Text, radiculapathyRightC8Choice: res.data.history.radiculapathyRightC8Choice, radiculapathyRightC8Text: res.data.history.radiculapathyRightC8Text, radiculapathyRightT1Choice: res.data.history.radiculapathyRightT1Choice, radiculapathyRightT1Text: res.data.history.radiculapathyRightT1Text, radiculapathyRightT2Choice: res.data.history.radiculapathyRightT2Choice, radiculapathyRightT2Text: res.data.history.radiculapathyRightT2Text, radiculapathyRightT3Choice: res.data.history.radiculapathyRightT3Choice, radiculapathyRightT3Text: res.data.history.radiculapathyRightT3Text, radiculapathyRightT4Choice: res.data.history.radiculapathyRightT4Choice, radiculapathyRightT4Text: res.data.history.radiculapathyRightT4Text, 
                    radiculapathyRightT5Choice: res.data.history.radiculapathyRightT5Choice, radiculapathyRightT5Text: res.data.history.radiculapathyRightT5Text, radiculapathyRightT6Choice: res.data.history.radiculapathyRightT6Choice, radiculapathyRightT6Text: res.data.history.radiculapathyRightT6Text, radiculapathyRightT7Choice: res.data.history.radiculapathyRightT7Choice, radiculapathyRightT7Text: res.data.history.radiculapathyRightT7Text, radiculapathyRightT8Choice: res.data.history.radiculapathyRightT8Choice, radiculapathyRightT8Text: res.data.history.radiculapathyRightT8Text, radiculapathyRightT9Choice: res.data.history.radiculapathyRightT9Choice, radiculapathyRightT9Text: res.data.history.radiculapathyRightT9Text, radiculapathyRightT10Choice: res.data.history.radiculapathyRightT10Choice, radiculapathyRightT10Text: res.data.history.radiculapathyRightT10Text, radiculapathyRightT11Choice: res.data.history.radiculapathyRightT11Choice, radiculapathyRightT11Text: res.data.history.radiculapathyRightT11Text, radiculapathyRightT12Choice: res.data.history.radiculapathyRightT12Choice, radiculapathyRightT12Text: res.data.history.radiculapathyRightT12Text, radiculapathyRightL1Choice: res.data.history.radiculapathyRightL1Choice, 
                    radiculapathyRightL1Text: res.data.history.radiculapathyRightL1Text, radiculapathyRightL2Choice: res.data.history.radiculapathyRightL2Choice, radiculapathyRightL2Text: res.data.history.radiculapathyRightL2Text, radiculapathyRightL3Choice: res.data.history.radiculapathyRightL3Choice, radiculapathyRightL3Text: res.data.history.radiculapathyRightL3Text, radiculapathyRightL4Choice: res.data.history.radiculapathyRightL4Choice, radiculapathyRightL4Text: res.data.history.radiculapathyRightL4Text, radiculapathyRightL5Choice: res.data.history.radiculapathyRightL5Choice, radiculapathyRightL5Text: res.data.history.radiculapathyRightL5Text, radiculapathyRightS1Choice: res.data.history.radiculapathyRightS1Choice, radiculapathyRightS1Text: res.data.history.radiculapathyRightS1Text, radiculapathyRightS3Choice: res.data.history.radiculapathyRightS3Choice, radiculapathyRightS3Text: res.data.history.radiculapathyRightS3Text, radiculapathyRightS45Choice: res.data.history.radiculapathyRightS45Choice, radiculapathyRightS45Text: res.data.history.radiculapathyRightS45Text,
                    /*EXAMINATION */
                    eyeText: res.data.examination.eyeText ,motorText: res.data.examination.motorText ,verbalText: res.data.examination.verbalText ,gcsText: res.data.examination.gcsText ,dementiaChoice: res.data.examination.dementiaChoice ,dementiaText: res.data.examination.dementiaText ,tandemChoice: res.data.examination.tandemChoice ,tandemText: res.data.examination.tandemText ,dysdiadochokinesiaChoice: res.data.examination.dysdiadochokinesiaChoice ,dysdiadochokinesiaText: res.data.examination.dysdiadochokinesiaText ,rombergChoice: res.data.examination.rombergChoice ,rombergText: res.data.examination.rombergText ,rombergPositiveChoice: res.data.examination.rombergPositiveChoice ,rombergPostiveText: res.data.examination.rombergPostiveText ,rombergNegativeChoice: res.data.examination.rombergNegativeChoice ,rombergNegativeText: res.data.examination.rombergNegativeText ,ataxiaChoice: res.data.examination.ataxiaChoice ,ataxiaText: res.data.examination.ataxiaText ,ataxiaTruncalChoice: res.data.examination.ataxiaTruncalChoice ,ataxiaTruncalText: res.data.examination.ataxiaTruncalText ,ataxiaAppendicularChoice: res.data.examination.ataxiaAppendicularChoice ,ataxiaAppendicularText: res.data.examination.ataxiaAppendicularText ,ataxiaGlobalChoice: res.data.examination.ataxiaGlobalChoice ,ataxiaGlobalText: res.data.examination.ataxiaGlobalText ,nystagmusChoice: res.data.examination.nystagmusChoice ,
                    nystagmusText: res.data.examination.nystagmusText ,dysphasiaChoice: res.data.examination.dysphasiaChoice ,dysphasiaText: res.data.examination.dysphasiaText ,dysphasiaExpressiveChoice: res.data.examination.dysphasiaExpressiveChoice ,dysphasiaExpressiveText: res.data.examination.dysphasiaExpressiveText ,dysphasiaReceptiveChoice: res.data.examination.dysphasiaReceptiveChoice ,dysphasiaReceptiveText: res.data.examination.dysphasiaReceptiveText ,dysphasiaGlobalChoice: res.data.examination.dysphasiaGlobalChoice ,dysphasiaGlobalText: res.data.examination.dysphasiaGlobalText ,abuliaChoice: res.data.examination.abuliaChoice ,abuliaText: res.data.examination.abuliaText ,astereognosiaChoice: res.data.examination.astereognosiaChoice ,astereognosiaText: res.data.examination.astereognosiaText ,akineticChoice: res.data.examination.akineticChoice ,akineticText: res.data.examination.akineticText ,hemianopsiaChoice: res.data.examination.hemianopsiaChoice ,hemianopsiaText: res.data.examination.hemianopsiaText ,hemianopsiaRightChoice: res.data.examination.hemianopsiaRightChoice ,hemianopsiaRightText: res.data.examination.hemianopsiaRightText ,hemianopsiaLeftChoice: res.data.examination.hemianopsiaLeftChoice ,hemianopsiaLeftText: res.data.examination.hemianopsiaLeftText ,hemianopsiaBitemporalChoice: res.data.examination.hemianopsiaBitemporalChoice ,
                    hemianopsiaBitemporalText: res.data.examination.hemianopsiaBitemporalText ,hemianopsiaTemporalChoice: res.data.examination.hemianopsiaTemporalChoice ,hemianopsiaTemporalText: res.data.examination.hemianopsiaTemporalText ,hemianopsiaNasalChoice: res.data.examination.hemianopsiaNasalChoice ,hemianopsiaNasalText: res.data.examination.hemianopsiaNasalText ,cranialChoice: res.data.examination.cranialChoice ,cranialText: res.data.examination.cranialText ,cranialLeftChoice: res.data.examination.cranialLeftChoice ,cranialLeftText: res.data.examination.cranialLeftText ,olfactoryLeftChoice: res.data.examination.olfactoryLeftChoice ,olfactoryLeftText: res.data.examination.olfactoryLeftText ,opticLeftChoice: res.data.examination.opticLeftChoice ,opticLeftText: res.data.examination.opticLeftText ,oculomotorLeftChoice: res.data.examination.oculomotorLeftChoice ,oculomotorLeftText: res.data.examination.oculomotorLeftText ,trochlearLeftChoice: res.data.examination.trochlearLeftChoice ,trochlearLeftText: res.data.examination.trochlearLeftText ,trigeminalLeftChoice: res.data.examination.trigeminalLeftChoice ,trigeminalLeftText: res.data.examination.trigeminalLeftText ,abducentLeftChoice: res.data.examination.abducentLeftChoice ,abducentLeftText: res.data.examination.abducentLeftText ,facialLeftChoice: res.data.examination.facialLeftChoice ,
                    facialLeftText: res.data.examination.facialLeftText ,vestibulocochlearLeftChoice: res.data.examination.vestibulocochlearLeftChoice ,vestibulocochlearLeftText: res.data.examination.vestibulocochlearLeftText ,glossopharyngealLeftChoice: res.data.examination.glossopharyngealLeftChoice ,glossopharyngealLeftText: res.data.examination.glossopharyngealLeftText ,vagusLeftChoice: res.data.examination.vagusLeftChoice ,vagusLeftText: res.data.examination.vagusLeftText ,accessoryLeftChoice: res.data.examination.accessoryLeftChoice ,accessoryLeftText: res.data.examination.accessoryLeftText ,hypoglossalLeftChoice: res.data.examination.hypoglossalLeftChoice ,hypoglossalLeftText: res.data.examination.hypoglossalLeftText ,cranialRightChoice: res.data.examination.cranialRightChoice ,cranialRightText: res.data.examination.cranialRightText ,olfactoryRightChoice: res.data.examination.olfactoryRightChoice ,olfactoryRightText: res.data.examination.olfactoryRightText ,opticRightChoice: res.data.examination.opticRightChoice ,opticRightText: res.data.examination.opticRightText ,oculomotorRightChoice: res.data.examination.oculomotorRightChoice ,oculomotorRightText: res.data.examination.oculomotorRightText ,trochlearRightChoice: res.data.examination.trochlearRightChoice ,trochlearRightText: res.data.examination.trochlearRightText ,
                    trigeminalRightChoice: res.data.examination.trigeminalRightChoice ,trigeminalRightText: res.data.examination.trigeminalRightText ,abducentRightChoice: res.data.examination.abducentRightChoice ,abducentRightText: res.data.examination.abducentRightText ,facialRightChoice: res.data.examination.facialRightChoice ,facialRightText: res.data.examination.facialRightText ,vestibulocochlearRightChoice: res.data.examination.vestibulocochlearRightChoice ,vestibulocochlearRightText: res.data.examination.vestibulocochlearRightText ,glossopharyngealRightChoice: res.data.examination.glossopharyngealRightChoice ,glossopharyngealRightText: res.data.examination.glossopharyngealRightText ,vagusRightChoice: res.data.examination.vagusRightChoice ,vagusRightText: res.data.examination.vagusRightText ,accessoryRightChoice: res.data.examination.accessoryRightChoice ,accessoryRightText: res.data.examination.accessoryRightText ,hypoglossalRightChoice: res.data.examination.hypoglossalRightChoice ,hypoglossalRightText: res.data.examination.hypoglossalRightText ,spineRangeChoice: res.data.examination.spineRangeChoice ,spineRangeText: res.data.examination.spineRangeText ,spineRangeCervicalChoice: res.data.examination.spineRangeCervicalChoice ,spineRangeCervicalText: res.data.examination.spineRangeCervicalText ,
                    spineRangeCervicalFlexionChoice: res.data.examination.spineRangeCervicalFlexionChoice ,spineRangeCervicalFlexionText: res.data.examination.spineRangeCervicalFlexionText ,spineRangeCervicalFlexionReducedChoice: res.data.examination.spineRangeCervicalFlexionReducedChoice ,spineRangeCervicalFlexionReducedText: res.data.examination.spineRangeCervicalFlexionReducedText ,spineRangeCervicalFlexionPainfulChoice: res.data.examination.spineRangeCervicalFlexionPainfulChoice ,spineRangeCervicalFlexionPainfulText: res.data.examination.spineRangeCervicalFlexionPainfulText ,spineRangeCervicalExtensionChoice: res.data.examination.spineRangeCervicalExtensionChoice ,spineRangeCervicalExtensionText: res.data.examination.spineRangeCervicalExtensionText ,spineRangeCervicalExtensionReducedChoice: res.data.examination.spineRangeCervicalExtensionReducedChoice ,spineRangeCervicalExtensionReducedText: res.data.examination.spineRangeCervicalExtensionReducedText ,spineRangeCervicalExtensionPainfulChoice: res.data.examination.spineRangeCervicalExtensionPainfulChoice ,spineRangeCervicalExtensionPainfulText: res.data.examination.spineRangeCervicalExtensionPainfulText ,spineRangeCervicalLateralBendingChoice: res.data.examination.spineRangeCervicalLateralBendingChoice ,
                    spineRangeCervicalLateralBendingText: res.data.examination.spineRangeCervicalLateralBendingText ,spineRangeCervicalLateralBendingReducedChoice: res.data.examination.spineRangeCervicalLateralBendingReducedChoice ,spineRangeCervicalLateralBendingReducedText: res.data.examination.spineRangeCervicalLateralBendingReducedText ,spineRangeCervicalLateralBendingPainfulChoice: res.data.examination.spineRangeCervicalLateralBendingPainfulChoice ,spineRangeCervicalLateralBendingPainfulText: res.data.examination.spineRangeCervicalLateralBendingPainfulText ,spineRangeCervicalRotationChoice: res.data.examination.spineRangeCervicalRotationChoice ,spineRangeCervicalRotationText: res.data.examination.spineRangeCervicalRotationText ,spineRangeCervicalRotationReducedChoice: res.data.examination.spineRangeCervicalRotationReducedChoice ,spineRangeCervicalRotationReducedText: res.data.examination.spineRangeCervicalRotationReducedText ,spineRangeCervicalRotationPainfulChoice: res.data.examination.spineRangeCervicalRotationPainfulChoice ,spineRangeCervicalRotationPainfulText: res.data.examination.spineRangeCervicalRotationPainfulText ,spineRangeThoracicChoice: res.data.examination.spineRangeThoracicChoice ,
                    spineRangeThoracicText: res.data.examination.spineRangeThoracicText ,spineRangeThoracicFlexionChoice: res.data.examination.spineRangeThoracicFlexionChoice ,spineRangeThoracicFlexionText: res.data.examination.spineRangeThoracicFlexionText ,spineRangeThoracicFlexionReducedChoice: res.data.examination.spineRangeThoracicFlexionReducedChoice ,spineRangeThoracicFlexionReducedText: res.data.examination.spineRangeThoracicFlexionReducedText ,spineRangeThoracicFlexionPainfulChoice: res.data.examination.spineRangeThoracicFlexionPainfulChoice ,spineRangeThoracicFlexionPainfulText: res.data.examination.spineRangeThoracicFlexionPainfulText ,spineRangeThoracicExtensionChoice: res.data.examination.spineRangeThoracicExtensionChoice ,spineRangeThoracicExtensionText: res.data.examination.spineRangeThoracicExtensionText ,spineRangeThoracicExtensionReducedChoice: res.data.examination.spineRangeThoracicExtensionReducedChoice ,spineRangeThoracicExtensionReducedText: res.data.examination.spineRangeThoracicExtensionReducedText ,spineRangeThoracicExtensionPainfulChoice: res.data.examination.spineRangeThoracicExtensionPainfulChoice ,spineRangeThoracicExtensionPainfulText: res.data.examination.spineRangeThoracicExtensionPainfulText ,
                    spineRangeThoracicLateralBendingChoice: res.data.examination.spineRangeThoracicLateralBendingChoice ,spineRangeThoracicLateralBendingText: res.data.examination.spineRangeThoracicLateralBendingText ,spineRangeThoracicLateralBendingReducedChoice: res.data.examination.spineRangeThoracicLateralBendingReducedChoice ,spineRangeThoracicLateralBendingReducedText: res.data.examination.spineRangeThoracicLateralBendingReducedText ,spineRangeThoracicLateralBendingPainfulChoice: res.data.examination.spineRangeThoracicLateralBendingPainfulChoice ,spineRangeThoracicLateralBendingPainfulText: res.data.examination.spineRangeThoracicLateralBendingPainfulText ,spineRangeThoracicRotationChoice: res.data.examination.spineRangeThoracicRotationChoice ,spineRangeThoracicRotationText: res.data.examination.spineRangeThoracicRotationText ,spineRangeThoracicRotationReducedChoice: res.data.examination.spineRangeThoracicRotationReducedChoice ,spineRangeThoracicRotationReducedText: res.data.examination.spineRangeThoracicRotationReducedText ,spineRangeThoracicRotationPainfulChoice: res.data.examination.spineRangeThoracicRotationPainfulChoice ,spineRangeThoracicRotationPainfulText: res.data.examination.spineRangeThoracicRotationPainfulText ,
                    spineRangeLumbarChoice: res.data.examination.spineRangeLumbarChoice ,spineRangeLumbarText: res.data.examination.spineRangeLumbarText ,spineRangeLumbarFlexionChoice: res.data.examination.spineRangeLumbarFlexionChoice ,spineRangeLumbarFlexionText: res.data.examination.spineRangeLumbarFlexionText ,spineRangeLumbarFlexionReducedChoice: res.data.examination.spineRangeLumbarFlexionReducedChoice ,spineRangeLumbarFlexionReducedText: res.data.examination.spineRangeLumbarFlexionReducedText ,spineRangeLumbarFlexionPainfulChoice: res.data.examination.spineRangeLumbarFlexionPainfulChoice ,spineRangeLumbarFlexionPainfulText: res.data.examination.spineRangeLumbarFlexionPainfulText ,spineRangeLumbarExtensionChoice: res.data.examination.spineRangeLumbarExtensionChoice ,spineRangeLumbarExtensionText: res.data.examination.spineRangeLumbarExtensionText ,spineRangeLumbarExtensionReducedChoice: res.data.examination.spineRangeLumbarExtensionReducedChoice ,spineRangeLumbarExtensionReducedText: res.data.examination.spineRangeLumbarExtensionReducedText ,spineRangeLumbarExtensionPainfulChoice: res.data.examination.spineRangeLumbarExtensionPainfulChoice ,spineRangeLumbarExtensionPainfulText: res.data.examination.spineRangeLumbarExtensionPainfulText ,
                    spineRangeLumbarLateralBendingChoice: res.data.examination.spineRangeLumbarLateralBendingChoice ,spineRangeLumbarLateralBendingText: res.data.examination.spineRangeLumbarLateralBendingText ,spineRangeLumbarLateralBendingReducedChoice: res.data.examination.spineRangeLumbarLateralBendingReducedChoice ,spineRangeLumbarLateralBendingReducedText: res.data.examination.spineRangeLumbarLateralBendingReducedText ,spineRangeLumbarLateralBendingPainfulChoice: res.data.examination.spineRangeLumbarLateralBendingPainfulChoice ,spineRangeLumbarLateralBendingPainfulText: res.data.examination.spineRangeLumbarLateralBendingPainfulText ,spineRangeLumbarRotationChoice: res.data.examination.spineRangeLumbarRotationChoice ,spineRangeLumbarRotationText: res.data.examination.spineRangeLumbarRotationText ,spineRangeLumbarRotationReducedChoice: res.data.examination.spineRangeLumbarRotationReducedChoice ,spineRangeLumbarRotationReducedText: res.data.examination.spineRangeLumbarRotationReducedText ,spineRangeLumbarRotationPainfulChoice: res.data.examination.spineRangeLumbarRotationPainfulChoice ,spineRangeLumbarRotationPainfulText: res.data.examination.spineRangeLumbarRotationPainfulText ,spineTendernessChoice: res.data.examination.spineTendernessChoice ,
                    spineTendernessText: res.data.examination.spineTendernessText ,spineTendernessCervicalChoice: res.data.examination.spineTendernessCervicalChoice ,spineTendernessCervicalText: res.data.examination.spineTendernessCervicalText ,spineTendernessThoracicChoicespineTendernessThoracicText: res.data.examination.spineTendernessThoracicChoicespineTendernessThoracicText ,spineTendernessThoracolumbarChoice: res.data.examination.spineTendernessThoracolumbarChoice ,spineTendernessThoracolumbarText: res.data.examination.spineTendernessThoracolumbarText ,spineTendernessLumbarChoice: res.data.examination.spineTendernessLumbarChoice ,spineTendernessLumbarText: res.data.examination.spineTendernessLumbarText ,spineTendernessLumbosacralChoice: res.data.examination.spineTendernessLumbosacralChoice ,spineTendernessLumbosacralText: res.data.examination.spineTendernessLumbosacralText ,spineTendernessSacroiliacChoice: res.data.examination.spineTendernessSacroiliacChoice ,spineTendernessSacroiliacText: res.data.examination.spineTendernessSacroiliacText ,trendelenbergChoice: res.data.examination.trendelenbergChoice ,trendelenbergText: res.data.examination.trendelenbergText ,trendelenbergLeftChoice: res.data.examination.trendelenbergLeftChoice ,trendelenbergLeftText: res.data.examination.trendelenbergLeftText ,
                    trendelenbergLeftPresentChoice: res.data.examination.trendelenbergLeftPresentChoice ,trendelenbergLeftPresentText: res.data.examination.trendelenbergLeftPresentText ,trendelenbergLeftNormalChoice: res.data.examination.trendelenbergLeftNormalChoice ,trendelenbergLeftNormalText: res.data.examination.trendelenbergLeftNormalText ,trendelenbergRightChoice: res.data.examination.trendelenbergRightChoice ,trendelenbergRightText: res.data.examination.trendelenbergRightText ,trendelenbergRightPresentChoice: res.data.examination.trendelenbergRightPresentChoice ,trendelenbergRightPresentText: res.data.examination.trendelenbergRightPresentText ,trendelenbergRightNormalChoice: res.data.examination.trendelenbergRightNormalChoice ,trendelenbergRightNormalText: res.data.examination.trendelenbergRightNormalText ,standOnTipToeChoice: res.data.examination.standOnTipToeChoice ,standOnTipToeText: res.data.examination.standOnTipToeText ,standOnTipToeLeftChoice: res.data.examination.standOnTipToeLeftChoice ,standOnTipToeLeftText: res.data.examination.standOnTipToeLeftText ,standOnTipToeLeftWeakChoice: res.data.examination.standOnTipToeLeftWeakChoice ,standOnTipToeLeftWeakText: res.data.examination.standOnTipToeLeftWeakText ,standOnTipToeLeftNormalChoice: res.data.examination.standOnTipToeLeftNormalChoice ,
                    standOnTipToeLeftNormalText: res.data.examination.standOnTipToeLeftNormalText ,standOnTipToeRightChoice: res.data.examination.standOnTipToeRightChoice ,standOnTipToeRightText: res.data.examination.standOnTipToeRightText ,standOnTipToeRightWeakChoice: res.data.examination.standOnTipToeRightWeakChoice ,standOnTipToeRightWeakText: res.data.examination.standOnTipToeRightWeakText ,standOnTipToeRightNormalChoice: res.data.examination.standOnTipToeRightNormalChoice ,standOnTipToeRightNormalText: res.data.examination.standOnTipToeRightNormalText ,spurlingTestChoice: res.data.examination.spurlingTestChoice ,spurlingTestText: res.data.examination.spurlingTestText ,spurlingTestLeftChoice: res.data.examination.spurlingTestLeftChoice ,spurlingTestLeftText: res.data.examination.spurlingTestLeftText ,spurlingTestLeftPresentChoice: res.data.examination.spurlingTestLeftPresentChoice ,spurlingTestLeftPresentText: res.data.examination.spurlingTestLeftPresentText ,spurlingTestLeftAbsentChoice: res.data.examination.spurlingTestLeftAbsentChoice ,spurlingTestLeftAbsentText: res.data.examination.spurlingTestLeftAbsentText ,spurlingTestRightChoice: res.data.examination.spurlingTestRightChoice ,spurlingTestRightText: res.data.examination.spurlingTestRightText ,spurlingTestRightPresentChoice: res.data.examination.spurlingTestRightPresentChoice ,
                    spurlingTestRightPresentText: res.data.examination.spurlingTestRightPresentText ,spurlingTestRightAbsentChoice: res.data.examination.spurlingTestRightAbsentChoice ,spurlingTestRightAbsentText: res.data.examination.spurlingTestRightAbsentText ,abductionReliefChoice: res.data.examination.abductionReliefChoice ,abductionReliefText: res.data.examination.abductionReliefText ,abductionReliefLeftChoice: res.data.examination.abductionReliefLeftChoice ,abductionReliefLeftText: res.data.examination.abductionReliefLeftText ,abductionReliefLeftPresentChoice: res.data.examination.abductionReliefLeftPresentChoice ,abductionReliefLeftPresentText: res.data.examination.abductionReliefLeftPresentText ,abductionReliefLeftAbsentChoice: res.data.examination.abductionReliefLeftAbsentChoice ,abductionReliefLeftAbsentText: res.data.examination.abductionReliefLeftAbsentText ,abductionReliefRightChoice: res.data.examination.abductionReliefRightChoice ,abductionReliefRightText: res.data.examination.abductionReliefRightText ,abductionReliefRightPresentChoice: res.data.examination.abductionReliefRightPresentChoice ,abductionReliefRightPresentText: res.data.examination.abductionReliefRightPresentText ,abductionReliefRightAbsentChoice: res.data.examination.abductionReliefRightAbsentChoice ,
                    abductionReliefRightAbsentText: res.data.examination.abductionReliefRightAbsentText ,straightLegRaisingChoice: res.data.examination.straightLegRaisingChoice ,straightLegRaisingText: res.data.examination.straightLegRaisingText ,straightLegRaisingLeftChoice: res.data.examination.straightLegRaisingLeftChoice ,straightLegRaisingLeftText: res.data.examination.straightLegRaisingLeftText ,straightLegRaisingLeftPresentChoice: res.data.examination.straightLegRaisingLeftPresentChoice ,straightLegRaisingLeftPresentText: res.data.examination.straightLegRaisingLeftPresentText ,straightLegRaisingLeftAbsentChoice: res.data.examination.straightLegRaisingLeftAbsentChoice ,straightLegRaisingLeftAbsentText: res.data.examination.straightLegRaisingLeftAbsentText ,straightLegRaisingRightChoice: res.data.examination.straightLegRaisingRightChoice ,straightLegRaisingRightText: res.data.examination.straightLegRaisingRightText ,straightLegRaisingRightPresentChoice: res.data.examination.straightLegRaisingRightPresentChoice ,straightLegRaisingRightPresentText: res.data.examination.straightLegRaisingRightPresentText ,straightLegRaisingRightAbsentChoice: res.data.examination.straightLegRaisingRightAbsentChoice ,straightLegRaisingRightAbsentText: res.data.examination.straightLegRaisingRightAbsentText ,
                    reverseStraightLegChoice: res.data.examination.reverseStraightLegChoice ,reverseStraightLegText: res.data.examination.reverseStraightLegText ,reverseStraightLegLeftChoice: res.data.examination.reverseStraightLegLeftChoice ,reverseStraightLegLeftText: res.data.examination.reverseStraightLegLeftText ,reverseStraightLegLeftPresentChoice: res.data.examination.reverseStraightLegLeftPresentChoice ,reverseStraightLegLeftPresentText: res.data.examination.reverseStraightLegLeftPresentText ,reverseStraightLegLeftAbsentChoice: res.data.examination.reverseStraightLegLeftAbsentChoice ,reverseStraightLegLeftAbsentText: res.data.examination.reverseStraightLegLeftAbsentText ,reverseStraightLegRightChoice: res.data.examination.reverseStraightLegRightChoice ,reverseStraightLegRightText: res.data.examination.reverseStraightLegRightText ,reverseStraightLegRightPresentChoice: res.data.examination.reverseStraightLegRightPresentChoice ,reverseStraightLegRightPresentText: res.data.examination.reverseStraightLegRightPresentText ,reverseStraightLegRightAbsentChoice: res.data.examination.reverseStraightLegRightAbsentChoice ,reverseStraightLegRightAbsentText: res.data.examination.reverseStraightLegRightAbsentText ,toneChoice: res.data.examination.toneChoice ,toneText: res.data.examination.toneText ,
                    toneLeftUpperLimbChoice: res.data.examination.toneLeftUpperLimbChoice ,toneLeftUpperLimbText: res.data.examination.toneLeftUpperLimbText ,toneLeftUpperLimbHypertoniaChoice: res.data.examination.toneLeftUpperLimbHypertoniaChoice ,toneLeftUpperLimbHypertoniaText: res.data.examination.toneLeftUpperLimbHypertoniaText ,toneLeftUpperLimbHypotoniaChoice: res.data.examination.toneLeftUpperLimbHypotoniaChoice ,toneLeftUpperLimbHypotoniaText: res.data.examination.toneLeftUpperLimbHypotoniaText ,toneLeftUpperLimbAtoniaChoice: res.data.examination.toneLeftUpperLimbAtoniaChoice ,toneLeftUpperLimbAtoniaText: res.data.examination.toneLeftUpperLimbAtoniaText ,toneRightUpperLimbChoice: res.data.examination.toneRightUpperLimbChoice ,toneRightUpperLimbText: res.data.examination.toneRightUpperLimbText ,toneRightUpperLimbHypertoniaChoice: res.data.examination.toneRightUpperLimbHypertoniaChoice ,toneRightUpperLimbHypertoniaText: res.data.examination.toneRightUpperLimbHypertoniaText ,toneRightUpperLimbHypotoniaChoice: res.data.examination.toneRightUpperLimbHypotoniaChoice ,toneRightUpperLimbHypotoniaText: res.data.examination.toneRightUpperLimbHypotoniaText ,toneRightUpperLimbAtoniaChoice: res.data.examination.toneRightUpperLimbAtoniaChoice ,toneRightUpperLimbAtoniaText: res.data.examination.toneRightUpperLimbAtoniaText ,
                    toneLeftLowerLimbChoice: res.data.examination.toneLeftLowerLimbChoice ,toneLeftLowerLimbText: res.data.examination.toneLeftLowerLimbText ,toneLeftLowerLimbHypertoniaChoice: res.data.examination.toneLeftLowerLimbHypertoniaChoice ,toneLeftLowerLimbHypertoniaText: res.data.examination.toneLeftLowerLimbHypertoniaText ,toneLeftLowerLimbHypotoniaChoice: res.data.examination.toneLeftLowerLimbHypotoniaChoice ,toneLeftLowerLimbHypotoniaText: res.data.examination.toneLeftLowerLimbHypotoniaText ,toneLeftLowerLimbAtoniaChoice: res.data.examination.toneLeftLowerLimbAtoniaChoice ,toneLeftLowerLimbAtoniaText: res.data.examination.toneLeftLowerLimbAtoniaText ,toneRightLowerLimbChoice: res.data.examination.toneRightLowerLimbChoice ,toneRightLowerLimbText: res.data.examination.toneRightLowerLimbText ,toneRightLowerLimbHypertoniaChoice: res.data.examination.toneRightLowerLimbHypertoniaChoice ,toneRightLowerLimbHypertoniaText: res.data.examination.toneRightLowerLimbHypertoniaText ,toneRightLowerLimbHypotoniaChoice: res.data.examination.toneRightLowerLimbHypotoniaChoice ,toneRightLowerLimbHypotoniaText: res.data.examination.toneRightLowerLimbHypotoniaText ,toneRightLowerLimbAtoniaChoice: res.data.examination.toneRightLowerLimbAtoniaChoice ,toneRightLowerLimbAtoniaText: res.data.examination.toneRightLowerLimbAtoniaText ,
                    toneGlobalChoice: res.data.examination.toneGlobalChoice ,toneGlobalText: res.data.examination.toneGlobalText ,toneGlobalHypertoniaChoice: res.data.examination.toneGlobalHypertoniaChoice ,toneGlobalHypertoniaText: res.data.examination.toneGlobalHypertoniaText ,toneGlobalHypotoniaChoice: res.data.examination.toneGlobalHypotoniaChoice ,toneGlobalHypotoniaText: res.data.examination.toneGlobalHypotoniaText ,toneGlobalAbsentChoice: res.data.examination.toneGlobalAbsentChoice ,toneGlobalAbsentText: res.data.examination.toneGlobalAbsentText ,powerChoice: res.data.examination.powerChoice ,powerText: res.data.examination.powerText ,powerLeftUpperLimbChoice: res.data.examination.powerLeftUpperLimbChoice ,powerLeftUpperLimbText: res.data.examination.powerLeftUpperLimbText ,powerLeftUpperLimbC5Choice: res.data.examination.powerLeftUpperLimbC5Choice ,powerLeftUpperLimbC5Text: res.data.examination.powerLeftUpperLimbC5Text ,powerLeftUpperLimbC6Choice: res.data.examination.powerLeftUpperLimbC6Choice ,powerLeftUpperLimbC6Text: res.data.examination.powerLeftUpperLimbC6Text ,powerLeftUpperLimbC7Choice: res.data.examination.powerLeftUpperLimbC7Choice ,powerLeftUpperLimbC7Text: res.data.examination.powerLeftUpperLimbC7Text ,powerLeftUpperLimbC8Choice: res.data.examination.powerLeftUpperLimbC8Choice ,powerLeftUpperLimbC8Text: res.data.examination.powerLeftUpperLimbC8Text ,
                    powerLeftUpperLimbT1Choice: res.data.examination.powerLeftUpperLimbT1Choice ,powerLeftUpperLimbT1Text: res.data.examination.powerLeftUpperLimbT1Text ,powerRightUpperLimbChoice: res.data.examination.powerRightUpperLimbChoice ,powerRightUpperLimbText: res.data.examination.powerRightUpperLimbText ,powerRightUpperLimbC5Choice: res.data.examination.powerRightUpperLimbC5Choice ,powerRightUpperLimbC5Text: res.data.examination.powerRightUpperLimbC5Text ,powerRightUpperLimbC6Choice: res.data.examination.powerRightUpperLimbC6Choice ,powerRightUpperLimbC6Text: res.data.examination.powerRightUpperLimbC6Text ,powerRightUpperLimbC7Choice: res.data.examination.powerRightUpperLimbC7Choice ,powerRightUpperLimbC7Text: res.data.examination.powerRightUpperLimbC7Text ,powerRightUpperLimbC8Choice: res.data.examination.powerRightUpperLimbC8Choice ,powerRightUpperLimbC8Text: res.data.examination.powerRightUpperLimbC8Text ,powerRightUpperLimbT1Choice: res.data.examination.powerRightUpperLimbT1Choice ,powerRightUpperLimbT1Text: res.data.examination.powerRightUpperLimbT1Text ,powerLeftLowerLimbChoice: res.data.examination.powerLeftLowerLimbChoice ,powerLeftLowerLimbText: res.data.examination.powerLeftLowerLimbText ,powerLeftLowerLimbC5Choice: res.data.examination.powerLeftLowerLimbC5Choice ,powerLeftLowerLimbC5Text: res.data.examination.powerLeftLowerLimbC5Text ,
                    powerLeftLowerLimbC6Choice: res.data.examination.powerLeftLowerLimbC6Choice ,powerLeftLowerLimbC6Text: res.data.examination.powerLeftLowerLimbC6Text ,powerLeftLowerLimbC7Choice: res.data.examination.powerLeftLowerLimbC7Choice ,powerLeftLowerLimbC7Text: res.data.examination.powerLeftLowerLimbC7Text ,powerLeftLowerLimbC8Choice: res.data.examination.powerLeftLowerLimbC8Choice ,powerLeftLowerLimbC8Text: res.data.examination.powerLeftLowerLimbC8Text ,powerLeftLowerLimbT1Choice: res.data.examination.powerLeftLowerLimbT1Choice ,powerLeftLowerLimbT1Text: res.data.examination.powerLeftLowerLimbT1Text ,powerRightLowerLimbChoice: res.data.examination.powerRightLowerLimbChoice ,powerRightLowerLimbText: res.data.examination.powerRightLowerLimbText ,powerRightLowerLimbC5Choice: res.data.examination.powerRightLowerLimbC5Choice ,powerRightLowerLimbC5Text: res.data.examination.powerRightLowerLimbC5Text ,powerRightLowerLimbC6Choice: res.data.examination.powerRightLowerLimbC6Choice ,powerRightLowerLimbC6Text: res.data.examination.powerRightLowerLimbC6Text ,powerRightLowerLimbC7Choice: res.data.examination.powerRightLowerLimbC7Choice ,powerRightLowerLimbC7Text: res.data.examination.powerRightLowerLimbC7Text ,powerRightLowerLimbC8Choice: res.data.examination.powerRightLowerLimbC8Choice ,powerRightLowerLimbC8Text: res.data.examination.powerRightLowerLimbC8Text ,
                    powerRightLowerLimbT1Choice: res.data.examination.powerRightLowerLimbT1Choice ,powerRightLowerLimbT1Text: res.data.examination.powerRightLowerLimbT1Text ,wastingChoice: res.data.examination.wastingChoice ,wastingText: res.data.examination.wastingText ,wastingLeftUpperLimbChoice: res.data.examination.wastingLeftUpperLimbChoice ,wastingLeftUpperLimbText: res.data.examination.wastingLeftUpperLimbText ,wastingRightUpperLimbChoice: res.data.examination.wastingRightUpperLimbChoice ,wastingRightUpperLimbText: res.data.examination.wastingRightUpperLimbText ,wastingLeftLowerLimbChoice: res.data.examination.wastingLeftLowerLimbChoice ,wastingLeftLowerLimbText: res.data.examination.wastingLeftLowerLimbText ,wastingRightLowerLimbChoice: res.data.examination.wastingRightLowerLimbChoice ,wastingRightLowerLimbText: res.data.examination.wastingRightLowerLimbText ,fasciculationsChoice: res.data.examination.fasciculationsChoice ,fasciculationsText: res.data.examination.fasciculationsText ,fasciculationsLeftUpperLimbChoice: res.data.examination.fasciculationsLeftUpperLimbChoice ,fasciculationsLeftUpperLimbText: res.data.examination.fasciculationsLeftUpperLimbText ,fasciculationsRightUpperLimbChoice: res.data.examination.fasciculationsRightUpperLimbChoice ,fasciculationsRightUpperLimbText: res.data.examination.fasciculationsRightUpperLimbText ,
                    fasciculationsLeftLowerLimbChoice: res.data.examination.fasciculationsLeftLowerLimbChoice ,fasciculationsLeftLowerLimbText: res.data.examination.fasciculationsLeftLowerLimbText ,fasciculationsRightLowerLimbChoice: res.data.examination.fasciculationsRightLowerLimbChoice ,fasciculationsRightLowerLimbText: res.data.examination.fasciculationsRightLowerLimbText ,deepTendonChoice: res.data.examination.deepTendonChoice ,deepTendonText: res.data.examination.deepTendonText ,deepTendonLeftBicepsChoice: res.data.examination.deepTendonLeftBicepsChoice ,deepTendonLeftBicepsText: res.data.examination.deepTendonLeftBicepsText ,deepTendonLeftBicepsHyperChoice: res.data.examination.deepTendonLeftBicepsHyperChoice ,deepTendonLeftBicepsHyperText: res.data.examination.deepTendonLeftBicepsHyperText ,deepTendonLeftBicepsHypoChoice: res.data.examination.deepTendonLeftBicepsHypoChoice ,deepTendonLeftBicepsHypoText: res.data.examination.deepTendonLeftBicepsHypoText ,deepTendonLeftBicepsAChoice: res.data.examination.deepTendonLeftBicepsAChoice ,deepTendonLeftBicepsAText: res.data.examination.deepTendonLeftBicepsAText ,deepTendonRightBicepsChoice: res.data.examination.deepTendonRightBicepsChoice ,deepTendonRightBicepsText: res.data.examination.deepTendonRightBicepsText ,deepTendonRightBicepsHyperChoice: res.data.examination.deepTendonRightBicepsHyperChoice ,
                    deepTendonRightBicepsHyperText: res.data.examination.deepTendonRightBicepsHyperText ,deepTendonRightBicepsHypoChoice: res.data.examination.deepTendonRightBicepsHypoChoice ,deepTendonRightBicepsHypoText: res.data.examination.deepTendonRightBicepsHypoText ,deepTendonRightBicepsAChoice: res.data.examination.deepTendonRightBicepsAChoice ,deepTendonRightBicepsAText: res.data.examination.deepTendonRightBicepsAText ,deepTendonLeftTricepsChoice: res.data.examination.deepTendonLeftTricepsChoice ,deepTendonLeftTricepsText: res.data.examination.deepTendonLeftTricepsText ,deepTendonLeftTricepsHyperChoice: res.data.examination.deepTendonLeftTricepsHyperChoice ,deepTendonLeftTricepsHyperText: res.data.examination.deepTendonLeftTricepsHyperText ,deepTendonLeftTricepsHypoChoice: res.data.examination.deepTendonLeftTricepsHypoChoice ,deepTendonLeftTricepsHypoText: res.data.examination.deepTendonLeftTricepsHypoText ,deepTendonLeftTricepsAChoice: res.data.examination.deepTendonLeftTricepsAChoice ,deepTendonLeftTricepsAText: res.data.examination.deepTendonLeftTricepsAText ,deepTendonRightTricepsChoice: res.data.examination.deepTendonRightTricepsChoice ,deepTendonRightTricepsText: res.data.examination.deepTendonRightTricepsText ,deepTendonRightTricepsHyperChoice: res.data.examination.deepTendonRightTricepsHyperChoice ,
                    deepTendonRightTricepsHyperText: res.data.examination.deepTendonRightTricepsHyperText ,deepTendonRightTricepsHypoChoice: res.data.examination.deepTendonRightTricepsHypoChoice ,deepTendonRightTricepsHypoText: res.data.examination.deepTendonRightTricepsHypoText ,deepTendonRightTricepsAChoice: res.data.examination.deepTendonRightTricepsAChoice ,deepTendonRightTricepsAText: res.data.examination.deepTendonRightTricepsAText ,deepTendonInvertedRadialChoice: res.data.examination.deepTendonInvertedRadialChoice ,deepTendonInvertedRadialText: res.data.examination.deepTendonInvertedRadialText ,deepTendonInvertedRadialLeftChoice: res.data.examination.deepTendonInvertedRadialLeftChoice ,deepTendonInvertedRadialLeftText: res.data.examination.deepTendonInvertedRadialLeftText ,deepTendonInvertedRadialRightChoice: res.data.examination.deepTendonInvertedRadialRightChoice ,deepTendonInvertedRadialRightText: res.data.examination.deepTendonInvertedRadialRightText ,deepTendonLeftKneeChoice: res.data.examination.deepTendonLeftKneeChoice ,deepTendonLeftKneeText: res.data.examination.deepTendonLeftKneeText ,deepTendonLeftKneeHyperChoice: res.data.examination.deepTendonLeftKneeHyperChoice ,deepTendonLeftKneeHyperText: res.data.examination.deepTendonLeftKneeHyperText ,deepTendonLeftKneeHypoChoice: res.data.examination.deepTendonLeftKneeHypoChoice ,
                    deepTendonLeftKneeHypoText: res.data.examination.deepTendonLeftKneeHypoText ,deepTendonLeftKneeAChoice: res.data.examination.deepTendonLeftKneeAChoice ,deepTendonLeftKneeAText: res.data.examination.deepTendonLeftKneeAText ,deepTendonLeftKneePendularChoice: res.data.examination.deepTendonLeftKneePendularChoice ,deepTendonLeftKneePendularText: res.data.examination.deepTendonLeftKneePendularText ,deepTendonRightKneeChoice: res.data.examination.deepTendonRightKneeChoice ,deepTendonRightKneeText: res.data.examination.deepTendonRightKneeText ,deepTendonRightKneeHyperChoice: res.data.examination.deepTendonRightKneeHyperChoice ,deepTendonRightKneeHyperText: res.data.examination.deepTendonRightKneeHyperText ,deepTendonRightKneeHypoChoice: res.data.examination.deepTendonRightKneeHypoChoice ,deepTendonRightKneeHypoText: res.data.examination.deepTendonRightKneeHypoText ,deepTendonRightKneeAChoice: res.data.examination.deepTendonRightKneeAChoice ,deepTendonRightKneeAText: res.data.examination.deepTendonRightKneeAText ,deepTendonRightKneePendularChoice: res.data.examination.deepTendonRightKneePendularChoice ,deepTendonRightKneePendularText: res.data.examination.deepTendonRightKneePendularText ,deepTendonLeftAnkleChoice: res.data.examination.deepTendonLeftAnkleChoice ,deepTendonLeftAnkleText: res.data.examination.deepTendonLeftAnkleText ,
                    deepTendonLeftAnkleHyperChoice: res.data.examination.deepTendonLeftAnkleHyperChoice ,deepTendonLeftAnkleHyperText: res.data.examination.deepTendonLeftAnkleHyperText ,deepTendonLeftAnkleHypoChoice: res.data.examination.deepTendonLeftAnkleHypoChoice ,deepTendonLeftAnkleHypoText: res.data.examination.deepTendonLeftAnkleHypoText ,deepTendonLeftAnkleAChoice: res.data.examination.deepTendonLeftAnkleAChoice ,deepTendonLeftAnkleAText: res.data.examination.deepTendonLeftAnkleAText ,deepTendonRightAnkleChoice: res.data.examination.deepTendonRightAnkleChoice ,deepTendonRightAnkleText: res.data.examination.deepTendonRightAnkleText ,deepTendonRightAnkleHyperChoice: res.data.examination.deepTendonRightAnkleHyperChoice ,deepTendonRightAnkleHyperText: res.data.examination.deepTendonRightAnkleHyperText ,deepTendonRightAnkleHypoChoice: res.data.examination.deepTendonRightAnkleHypoChoice ,deepTendonRightAnkleHypoText: res.data.examination.deepTendonRightAnkleHypoText ,deepTendonRightAnkleAChoice: res.data.examination.deepTendonRightAnkleAChoice ,deepTendonRightAnkleAText: res.data.examination.deepTendonRightAnkleAText ,hoffmansChoice: res.data.examination.hoffmansChoice ,hoffmansText: res.data.examination.hoffmansText ,hoffmansLeftChoice: res.data.examination.hoffmansLeftChoice ,hoffmansLeftText: res.data.examination.hoffmansLeftText ,
                    hoffmansRightChoice: res.data.examination.hoffmansRightChoice ,hoffmansRightText: res.data.examination.hoffmansRightText ,ankleClonusChoice: res.data.examination.ankleClonusChoice ,ankleClonusText: res.data.examination.ankleClonusText ,ankleClonusLeftChoice: res.data.examination.ankleClonusLeftChoice ,ankleClonusLeftText: res.data.examination.ankleClonusLeftText ,ankleClonusRightChoice: res.data.examination.ankleClonusRightChoice ,ankleClonusRightText: res.data.examination.ankleClonusRightText ,babinskiChoice: res.data.examination.babinskiChoice ,babinskiText: res.data.examination.babinskiText ,babinskiLeftChoice: res.data.examination.babinskiLeftChoice ,babinskiLeftText: res.data.examination.babinskiLeftText ,babinskiLeftUpGoingChoice: res.data.examination.babinskiLeftUpGoingChoice ,babinskiLeftUpGoingText: res.data.examination.babinskiLeftUpGoingText ,babinskiLeftDownGoingChoice: res.data.examination.babinskiLeftDownGoingChoice ,babinskiLeftDownGoingText: res.data.examination.babinskiLeftDownGoingText ,babinskiLeftEquivocalChoice: res.data.examination.babinskiLeftEquivocalChoice ,babinskiLeftEquivocalText: res.data.examination.babinskiLeftEquivocalText ,babinskiRightChoice: res.data.examination.babinskiRightChoice ,babinskiRightText: res.data.examination.babinskiRightText ,babinskiRightUpGoingChoice: res.data.examination.babinskiRightUpGoingChoice ,babinskiRightUpGoingText: res.data.examination.babinskiRightUpGoingText ,
                    babinskiRightDownGoingChoice: res.data.examination.babinskiRightDownGoingChoice ,babinskiRightDownGoingText: res.data.examination.babinskiRightDownGoingText ,babinskiRightEquivocalChoice: res.data.examination.babinskiRightEquivocalChoice ,babinskiRightEquivocalText: res.data.examination.babinskiRightEquivocalText ,bulbocavernosusChoice: res.data.examination.bulbocavernosusChoice ,bulbocavernosusText: res.data.examination.bulbocavernosusText ,bulbocavernosusPresentChoice: res.data.examination.bulbocavernosusPresentChoice ,bulbocavernosusPresentText: res.data.examination.bulbocavernosusPresentText ,bulbocavernosusAbsentChoice: res.data.examination.bulbocavernosusAbsentChoice ,bulbocavernosusAbsentText: res.data.examination.bulbocavernosusAbsentText ,beevorsChoice: res.data.examination.beevorsChoice ,beevorsText: res.data.examination.beevorsText ,beevorsPresentChoice: res.data.examination.beevorsPresentChoice ,beevorsPresentText: res.data.examination.beevorsPresentText ,beevorsAbsentChoice: res.data.examination.beevorsAbsentChoice ,beevorsAbsentText: res.data.examination.beevorsAbsentText ,rombergTestChoice: res.data.examination.rombergTestChoice ,rombergTestText: res.data.examination.rombergTestText ,rombergTestAbsentChoice: res.data.examination.rombergTestAbsentChoice ,rombergTestAbsentText: res.data.examination.rombergTestAbsentText ,rombergTestPresentChoice: res.data.examination.rombergTestPresentChoice ,
                    rombergTestPresentText: res.data.examination.rombergTestPresentText ,rombergTestPresentLeftChoice: res.data.examination.rombergTestPresentLeftChoice ,rombergTestPresentLeftText: res.data.examination.rombergTestPresentLeftText ,rombergTestPresentRightChoice: res.data.examination.rombergTestPresentRightChoice ,rombergTestPresentRightText: res.data.examination.rombergTestPresentRightText ,motorLevelChoice: res.data.examination.motorLevelChoice ,motorLevelText: res.data.examination.motorLevelText ,motorLevelLeftChoice: res.data.examination.motorLevelLeftChoice ,motorLevelLeftText: res.data.examination.motorLevelLeftText ,motorLevelLeftC5Choice: res.data.examination.motorLevelLeftC5Choice ,motorLevelLeftC5Text: res.data.examination.motorLevelLeftC5Text ,motorLevelLeftC6Choice: res.data.examination.motorLevelLeftC6Choice ,motorLevelLeftC6Text: res.data.examination.motorLevelLeftC6Text ,motorLevelLeftC7Choice: res.data.examination.motorLevelLeftC7Choice ,motorLevelLeftC7Text: res.data.examination.motorLevelLeftC7Text ,motorLevelLeftC8Choice: res.data.examination.motorLevelLeftC8Choice ,motorLevelLeftC8Text: res.data.examination.motorLevelLeftC8Text ,motorLevelLeftT1Choice: res.data.examination.motorLevelLeftT1Choice ,motorLevelLeftT1Text: res.data.examination.motorLevelLeftT1Text ,motorLevelLeftL2Choice: res.data.examination.motorLevelLeftL2Choice ,motorLevelLeftL2Text: res.data.examination.motorLevelLeftL2Text ,
                    motorLevelLeftL3Choice: res.data.examination.motorLevelLeftL3Choice ,motorLevelLeftL3Text: res.data.examination.motorLevelLeftL3Text ,motorLevelLeftL4Choice: res.data.examination.motorLevelLeftL4Choice ,motorLevelLeftL4Text: res.data.examination.motorLevelLeftL4Text ,motorLevelLeftL5Choice: res.data.examination.motorLevelLeftL5Choice ,motorLevelLeftL5Text: res.data.examination.motorLevelLeftL5Text ,motorLevelLeftS1Choice: res.data.examination.motorLevelLeftS1Choice ,motorLevelLeftS1Text: res.data.examination.motorLevelLeftS1Text ,motorLevelRightChoice: res.data.examination.motorLevelRightChoice ,motorLevelRightText: res.data.examination.motorLevelRightText ,motorLevelRightC5Choice: res.data.examination.motorLevelRightC5Choice ,motorLevelRightC5Text: res.data.examination.motorLevelRightC5Text ,motorLevelRightC6Choice: res.data.examination.motorLevelRightC6Choice ,motorLevelRightC6Text: res.data.examination.motorLevelRightC6Text ,motorLevelRightC7Choice: res.data.examination.motorLevelRightC7Choice ,motorLevelRightC7Text: res.data.examination.motorLevelRightC7Text ,motorLevelRightC8Choice: res.data.examination.motorLevelRightC8Choice ,motorLevelRightC8Text: res.data.examination.motorLevelRightC8Text ,motorLevelRightT1Choice: res.data.examination.motorLevelRightT1Choice ,motorLevelRightT1Text: res.data.examination.motorLevelRightT1Text ,motorLevelRightL2Choice: res.data.examination.motorLevelRightL2Choice ,
                    motorLevelRightL2Text: res.data.examination.motorLevelRightL2Text ,motorLevelRightL3Choice: res.data.examination.motorLevelRightL3Choice ,motorLevelRightL3Text: res.data.examination.motorLevelRightL3Text ,motorLevelRightL4Choice: res.data.examination.motorLevelRightL4Choice ,motorLevelRightL4Text: res.data.examination.motorLevelRightL4Text ,motorLevelRightL5Choice: res.data.examination.motorLevelRightL5Choice ,motorLevelRightL5Text: res.data.examination.motorLevelRightL5Text ,motorLevelRightS1Choice: res.data.examination.motorLevelRightS1Choice ,motorLevelRightS1Text: res.data.examination.motorLevelRightS1Text ,sensoryLevelChoice: res.data.examination.sensoryLevelChoice ,sensoryLevelText: res.data.examination.sensoryLevelText ,sensoryLevelLeftChoice: res.data.examination.sensoryLevelLeftChoice ,sensoryLevelLeftText: res.data.examination.sensoryLevelLeftText ,sensoryLevelLeftC2Choice: res.data.examination.sensoryLevelLeftC2Choice ,sensoryLevelLeftC2Text: res.data.examination.sensoryLevelLeftC2Text ,sensoryLevelLeftC3Choice: res.data.examination.sensoryLevelLeftC3Choice ,sensoryLevelLeftC3Text: res.data.examination.sensoryLevelLeftC3Text ,sensoryLevelLeftC4Choice: res.data.examination.sensoryLevelLeftC4Choice ,sensoryLevelLeftC4Text: res.data.examination.sensoryLevelLeftC4Text ,sensoryLevelLeftC5Choice: res.data.examination.sensoryLevelLeftC5Choice ,sensoryLevelLeftC5Text: res.data.examination.sensoryLevelLeftC5Text ,
                    sensoryLevelLeftC6Choice: res.data.examination.sensoryLevelLeftC6Choice ,sensoryLevelLeftC6Text: res.data.examination.sensoryLevelLeftC6Text ,sensoryLevelLeftC7Choice: res.data.examination.sensoryLevelLeftC7Choice ,sensoryLevelLeftC7Text: res.data.examination.sensoryLevelLeftC7Text ,sensoryLevelLeftC8Choice: res.data.examination.sensoryLevelLeftC8Choice ,sensoryLevelLeftC8Text: res.data.examination.sensoryLevelLeftC8Text ,sensoryLevelLeftT1Choice: res.data.examination.sensoryLevelLeftT1Choice ,sensoryLevelLeftT1Text: res.data.examination.sensoryLevelLeftT1Text ,sensoryLevelLeftT2Choice: res.data.examination.sensoryLevelLeftT2Choice ,sensoryLevelLeftT2Text: res.data.examination.sensoryLevelLeftT2Text ,sensoryLevelLeftT3Choice: res.data.examination.sensoryLevelLeftT3Choice ,sensoryLevelLeftT3Text: res.data.examination.sensoryLevelLeftT3Text ,sensoryLevelLeftT4Choice: res.data.examination.sensoryLevelLeftT4Choice ,sensoryLevelLeftT4Text: res.data.examination.sensoryLevelLeftT4Text ,sensoryLevelLeftT5Choice: res.data.examination.sensoryLevelLeftT5Choice ,sensoryLevelLeftT5Text: res.data.examination.sensoryLevelLeftT5Text ,sensoryLevelLeftT6Choice: res.data.examination.sensoryLevelLeftT6Choice ,sensoryLevelLeftT6Text: res.data.examination.sensoryLevelLeftT6Text ,sensoryLevelLeftT7Choice: res.data.examination.sensoryLevelLeftT7Choice ,sensoryLevelLeftT7Text: res.data.examination.sensoryLevelLeftT7Text ,
                    sensoryLevelLeftT8Choice: res.data.examination.sensoryLevelLeftT8Choice ,sensoryLevelLeftT8Text: res.data.examination.sensoryLevelLeftT8Text ,sensoryLevelLeftT9Choice: res.data.examination.sensoryLevelLeftT9Choice ,sensoryLevelLeftT9Text: res.data.examination.sensoryLevelLeftT9Text ,sensoryLevelLeftT10Choice: res.data.examination.sensoryLevelLeftT10Choice ,sensoryLevelLeftT10Text: res.data.examination.sensoryLevelLeftT10Text ,sensoryLevelLeftT11Choice: res.data.examination.sensoryLevelLeftT11Choice ,sensoryLevelLeftT11Text: res.data.examination.sensoryLevelLeftT11Text ,sensoryLevelLeftT12Choice: res.data.examination.sensoryLevelLeftT12Choice ,sensoryLevelLeftT12Text: res.data.examination.sensoryLevelLeftT12Text ,sensoryLevelLeftL1Choice: res.data.examination.sensoryLevelLeftL1Choice ,sensoryLevelLeftL1Text: res.data.examination.sensoryLevelLeftL1Text ,sensoryLevelLeftL2Choice: res.data.examination.sensoryLevelLeftL2Choice ,sensoryLevelLeftL2Text: res.data.examination.sensoryLevelLeftL2Text ,sensoryLevelLeftL3Choice: res.data.examination.sensoryLevelLeftL3Choice ,sensoryLevelLeftL3Text: res.data.examination.sensoryLevelLeftL3Text ,sensoryLevelLeftL4Choice: res.data.examination.sensoryLevelLeftL4Choice ,sensoryLevelLeftL4Text: res.data.examination.sensoryLevelLeftL4Text ,sensoryLevelLeftL5Choice: res.data.examination.sensoryLevelLeftL5Choice ,sensoryLevelLeftL5Text: res.data.examination.sensoryLevelLeftL5Text ,
                    sensoryLevelLeftS1Choice: res.data.examination.sensoryLevelLeftS1Choice ,sensoryLevelLeftS1Text: res.data.examination.sensoryLevelLeftS1Text ,sensoryLevelLeftS3Choice: res.data.examination.sensoryLevelLeftS3Choice ,sensoryLevelLeftS3Text: res.data.examination.sensoryLevelLeftS3Text ,sensoryLevelLeftS45Choice: res.data.examination.sensoryLevelLeftS45Choice ,sensoryLevelLeftS45Text: res.data.examination.sensoryLevelLeftS45Text ,sensoryLevelRightChoice: res.data.examination.sensoryLevelRightChoice ,sensoryLevelRightText: res.data.examination.sensoryLevelRightText ,sensoryLevelRightC2Choice: res.data.examination.sensoryLevelRightC2Choice ,sensoryLevelRightC2Text: res.data.examination.sensoryLevelRightC2Text ,sensoryLevelRightC3Choice: res.data.examination.sensoryLevelRightC3Choice ,sensoryLevelRightC3Text: res.data.examination.sensoryLevelRightC3Text ,sensoryLevelRightC4Choice: res.data.examination.sensoryLevelRightC4Choice ,sensoryLevelRightC4Text: res.data.examination.sensoryLevelRightC4Text ,sensoryLevelRightC5Choice: res.data.examination.sensoryLevelRightC5Choice ,sensoryLevelRightC5Text: res.data.examination.sensoryLevelRightC5Text ,sensoryLevelRightC6Choice: res.data.examination.sensoryLevelRightC6Choice ,sensoryLevelRightC6Text: res.data.examination.sensoryLevelRightC6Text ,sensoryLevelRightC7Choice: res.data.examination.sensoryLevelRightC7Choice ,
                    sensoryLevelRightC7Text: res.data.examination.sensoryLevelRightC7Text ,sensoryLevelRightC8Choice: res.data.examination.sensoryLevelRightC8Choice ,sensoryLevelRightC8Text: res.data.examination.sensoryLevelRightC8Text ,sensoryLevelRightT1Choice: res.data.examination.sensoryLevelRightT1Choice ,sensoryLevelRightT1Text: res.data.examination.sensoryLevelRightT1Text ,sensoryLevelRightT2Choice: res.data.examination.sensoryLevelRightT2Choice ,sensoryLevelRightT2Text: res.data.examination.sensoryLevelRightT2Text ,sensoryLevelRightT3Choice: res.data.examination.sensoryLevelRightT3Choice ,sensoryLevelRightT3Text: res.data.examination.sensoryLevelRightT3Text ,sensoryLevelRightT4Choice: res.data.examination.sensoryLevelRightT4Choice ,sensoryLevelRightT4Text: res.data.examination.sensoryLevelRightT4Text ,sensoryLevelRightT5Choice: res.data.examination.sensoryLevelRightT5Choice ,sensoryLevelRightT5Text: res.data.examination.sensoryLevelRightT5Text ,sensoryLevelRightT6Choice: res.data.examination.sensoryLevelRightT6Choice ,sensoryLevelRightT6Text: res.data.examination.sensoryLevelRightT6Text ,sensoryLevelRightT7Choice: res.data.examination.sensoryLevelRightT7Choice ,sensoryLevelRightT7Text: res.data.examination.sensoryLevelRightT7Text ,sensoryLevelRightT8Choice: res.data.examination.sensoryLevelRightT8Choice ,sensoryLevelRightT8Text: res.data.examination.sensoryLevelRightT8Text ,
                    sensoryLevelRightT9Choice: res.data.examination.sensoryLevelRightT9Choice ,sensoryLevelRightT9Text: res.data.examination.sensoryLevelRightT9Text ,sensoryLevelRightT10Choice: res.data.examination.sensoryLevelRightT10Choice ,sensoryLevelRightT10Text: res.data.examination.sensoryLevelRightT10Text ,sensoryLevelRightT11Choice: res.data.examination.sensoryLevelRightT11Choice ,sensoryLevelRightT11Text: res.data.examination.sensoryLevelRightT11Text ,sensoryLevelRightT12Choice: res.data.examination.sensoryLevelRightT12Choice ,sensoryLevelRightT12Text: res.data.examination.sensoryLevelRightT12Text ,sensoryLevelRightL1Choice: res.data.examination.sensoryLevelRightL1Choice ,sensoryLevelRightL1Text: res.data.examination.sensoryLevelRightL1Text ,sensoryLevelRightL2Choice: res.data.examination.sensoryLevelRightL2Choice ,sensoryLevelRightL2Text: res.data.examination.sensoryLevelRightL2Text ,sensoryLevelRightL3Choice: res.data.examination.sensoryLevelRightL3Choice ,sensoryLevelRightL3Text: res.data.examination.sensoryLevelRightL3Text ,sensoryLevelRightL4Choice: res.data.examination.sensoryLevelRightL4Choice ,sensoryLevelRightL4Text: res.data.examination.sensoryLevelRightL4Text ,sensoryLevelRightL5Choice: res.data.examination.sensoryLevelRightL5Choice ,sensoryLevelRightL5Text: res.data.examination.sensoryLevelRightL5Text ,sensoryLevelRightS1Choice: res.data.examination.sensoryLevelRightS1Choice ,
                    sensoryLevelRightS1Text: res.data.examination.sensoryLevelRightS1Text ,sensoryLevelRightS3Choice: res.data.examination.sensoryLevelRightS3Choice ,sensoryLevelRightS3Text: res.data.examination.sensoryLevelRightS3Text ,sensoryLevelRightS45Choice: res.data.examination.sensoryLevelRightS45Choice ,sensoryLevelRightS45Text: res.data.examination.sensoryLevelRightS45Text ,neurologicalLevelChoice: res.data.examination.neurologicalLevelChoice ,neurologicalLevelText: res.data.examination.neurologicalLevelText ,neurologicalLevelC2Choice: res.data.examination.neurologicalLevelC2Choice ,neurologicalLevelC2Text: res.data.examination.neurologicalLevelC2Text ,neurologicalLevelC3Choice: res.data.examination.neurologicalLevelC3Choice ,neurologicalLevelC3Text: res.data.examination.neurologicalLevelC3Text ,neurologicalLevelC4Choice: res.data.examination.neurologicalLevelC4Choice ,neurologicalLevelC4Text: res.data.examination.neurologicalLevelC4Text ,neurologicalLevelC5Choice: res.data.examination.neurologicalLevelC5Choice ,neurologicalLevelC5Text: res.data.examination.neurologicalLevelC5Text ,neurologicalLevelC6Choice: res.data.examination.neurologicalLevelC6Choice ,neurologicalLevelC6Text: res.data.examination.neurologicalLevelC6Text ,neurologicalLevelC7Choice: res.data.examination.neurologicalLevelC7Choice ,neurologicalLevelC7Text: res.data.examination.neurologicalLevelC7Text ,
                    neurologicalLevelC8Choice: res.data.examination.neurologicalLevelC8Choice ,neurologicalLevelC8Text: res.data.examination.neurologicalLevelC8Text ,neurologicalLevelT1Choice: res.data.examination.neurologicalLevelT1Choice ,neurologicalLevelT1Text: res.data.examination.neurologicalLevelT1Text ,neurologicalLevelT2Choice: res.data.examination.neurologicalLevelT2Choice ,neurologicalLevelT2Text: res.data.examination.neurologicalLevelT2Text ,neurologicalLevelT3Text: res.data.examination.neurologicalLevelT3Text ,neurologicalLevelT4Choice: res.data.examination.neurologicalLevelT4Choice ,neurologicalLevelT4Text: res.data.examination.neurologicalLevelT4Text ,neurologicalLevelT5Choice: res.data.examination.neurologicalLevelT5Choice ,neurologicalLevelT5Text: res.data.examination.neurologicalLevelT5Text ,neurologicalLevelT6Choice: res.data.examination.neurologicalLevelT6Choice ,neurologicalLevelT6Text: res.data.examination.neurologicalLevelT6Text ,neurologicalLevelT7Choice: res.data.examination.neurologicalLevelT7Choice ,neurologicalLevelT7Text: res.data.examination.neurologicalLevelT7Text ,neurologicalLevelT8Choice: res.data.examination.neurologicalLevelT8Choice ,neurologicalLevelT8Text: res.data.examination.neurologicalLevelT8Text ,neurologicalLevelT9Choice: res.data.examination.neurologicalLevelT9Choice ,neurologicalLevelT9Text: res.data.examination.neurologicalLevelT9Text ,
                    neurologicalLevelT10Choice: res.data.examination.neurologicalLevelT10Choice ,neurologicalLevelT10Text: res.data.examination.neurologicalLevelT10Text ,neurologicalLevelT11Choice: res.data.examination.neurologicalLevelT11Choice ,neurologicalLevelT11Text: res.data.examination.neurologicalLevelT11Text ,neurologicalLevelT12Choice: res.data.examination.neurologicalLevelT12Choice ,neurologicalLevelT12Text: res.data.examination.neurologicalLevelT12Text ,neurologicalLevelL1Choice: res.data.examination.neurologicalLevelL1Choice ,neurologicalLevelL1Text: res.data.examination.neurologicalLevelL1Text ,neurologicalLevelL2Choice: res.data.examination.neurologicalLevelL2Choice ,neurologicalLevelL2Text: res.data.examination.neurologicalLevelL2Text ,neurologicalLevelL3Choice: res.data.examination.neurologicalLevelL3Choice ,neurologicalLevelL3Text: res.data.examination.neurologicalLevelL3Text ,neurologicalLevelL4Choice: res.data.examination.neurologicalLevelL4Choice ,neurologicalLevelL4Text: res.data.examination.neurologicalLevelL4Text ,neurologicalLevelL5Choice: res.data.examination.neurologicalLevelL5Choice ,neurologicalLevelL5Text: res.data.examination.neurologicalLevelL5Text ,neurologicalLevelS1Choice: res.data.examination.neurologicalLevelS1Choice ,neurologicalLevelS1Text: res.data.examination.neurologicalLevelS1Text ,neurologicalLevelS3Choice: res.data.examination.neurologicalLevelS3Choice ,
                    neurologicalLevelS3Text: res.data.examination.neurologicalLevelS3Text ,neurologicalLevelS45Choice: res.data.examination.neurologicalLevelS45Choice ,neurologicalLevelS45Text: res.data.examination.neurologicalLevelS45Text , findingsEx: res.data.examination.findings, examinationOther: res.data.examination.other,
                    hBA1C: res.data.hBA1C, cholesterol: res.data.cholesterol, otherName: res.data.otherName, otherValue: res.data.otherValue, allergies: res.data.allergies, results: res.data.results,
                    fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors, notes: res.data.notes, oldPatient: res.data.oldPatient, planData: res.data.planData, chronicData: res.data.chronicData, historyVisits: res.data.historyVisits
                });
                setTimeout(() => { this.setLoader(false)  }, 2000);
            }, err => {
                console.log(".....")
                this.setState({ error: err + '' })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {
            patientId: this.state.patientId, visitId: this.state.visitId, history: {
                presentingComplaintList: this.state.presentingComplaintList, pastMedicalHistory: this.state.pastMedicalHistory, currentMedication: this.state.currentMedication, allergyHistory: this.state.allergyHistory, historyOfPresentingComplaints: this.state.historyOfPresentingComplaints,
                coughChoice: this.state.coughChoice, coughText: this.state.coughText, coryzaChoice: this.state.coryzaChoice, coryzaText: this.state.coryzaText, sobChoice: this.state.sobChoice, sobText: this.state.sobText, sneazingChoice: this.state.sneazingChoice, sneazingText: this.state.sneazingText, phelgmChoice: this.state.phelgmChoice, phelgmText: this.state.phelgmText, sputumChoice: this.state.sputumChoice, sputumText: this.state.sputumText, haemoptysisChoice: this.state.haemoptysisChoice, haemoptysisText: this.state.haemoptysisText, wheezingChoice: this.state.wheezingChoice, wheezingText: this.state.wheezingText, nasalChoice: this.state.nasalChoice, nasalText: this.state.nasalText, soreChoice: this.state.soreChoice, soreText: this.state.soreText, chestPainChoice: this.state.chestPainChoice, chestPainText: this.state.chestPainText, respitoryNotes: this.state.respitoryNotes,
                nauseaChoice: this.state.nauseaChoice, nauseaText: this.state.nauseaText, vomitingChoice: this.state.vomitingChoice, vomitingText: this.state.vomitingText, diarrhoeaChoice: this.state.diarrhoeaChoice, diarrhoeaText: this.state.diarrhoeaText, abdominialChoice: this.state.abdominialChoice, abdominialText: this.state.abdominialText, flatulanceChoice: this.state.flatulanceChoice, flatulanceText: this.state.flatulanceText, flatulentChoice: this.state.flatulentChoice, flatulentText: this.state.flatulentText, waterChoice: this.state.waterChoice, waterText: this.state.waterText, constipationChoice: this.state.constipationChoice, constipationText: this.state.constipationText, prChoice: this.state.prChoice, prText: this.state.prText, wtChoice: this.state.wtChoice, wtText: this.state.wtText, melenaChoice: this.state.melenaChoice, melenaText: this.state.melenaText, jaundiceChoice: this.state.jaundiceChoice, jaundiceText: this.state.jaundiceText, loaChoice: this.state.loaChoice, loaText: this.state.loaText, lowChoice: this.state.lowChoice, lowText: this.state.lowText, gitNotes: this.state.gitNotes,
                dysuriaChoice: this.state.dysuriaChoice, dysuriaText: this.state.dysuriaText, frequencyChoice: this.state.frequencyChoice, frequencyText: this.state.frequencyText, haematuriaChoice: this.state.haematuriaChoice, haematuriaText: this.state.haematuriaText, weakChoice: this.state.weakChoice, weakText: this.state.weakText, strainingChoice: this.state.strainingChoice, strainingText: this.state.strainingText, urineChoice: this.state.urineChoice, urineText: this.state.urineText, urethalChoice: this.state.urethalChoice, urethalText: this.state.urethalText, pvChoice: this.state.pvChoice, pvText: this.state.pvText, nocturiaChoice: this.state.nocturiaChoice, nocturiaText: this.state.nocturiaText, genitalChoice: this.state.genitalChoice, genitalText: this.state.genitalText, lossChoice: this.state.lossChoice, lossText: this.state.lossText, erectileChoice: this.state.erectileChoice, erectileText: this.state.erectileText, gusNotes: this.state.gusNotes,
                dysaruniaChoice: this.state.dysaruniaChoice, dysaruniaText: this.state.dysaruniaText, dysmemorrhoeaChoice: this.state.dysmemorrhoeaChoice, dysmemorrhoeaText: this.state.dysmemorrhoeaText, imbChoice: this.state.imbChoice, imbText: this.state.imbText, contactChoice: this.state.contactChoice, contactText: this.state.contactText, menorrhagiaChoice: this.state.menorrhagiaChoice, menorrhagiaText: this.state.menorrhagiaText, lapChoice: this.state.lapChoice, lapText: this.state.lapText, backacheChoice: this.state.backacheChoice, backackeText: this.state.backackeText, subfertilityChoice: this.state.subfertilityChoice, subfertilityText: this.state.subfertilityText, hotChoice: this.state.hotChoice, hotText: this.state.hotText, lnmpChoice: this.state.lnmpChoice, lnmpText: this.state.lnmpText, gynaeNotes: this.state.gynaeNotes,
                orthopneaChoice: this.state.orthopneaChoice, orthopneaText: this.state.orthopneaText, pndChoice: this.state.pndChoice, pndText: this.state.pndText, easyChoice: this.state.easyChoice, easyText: this.state.easyText, palpitationsChoice: this.state.palpitationsChoice, palpitationsText: this.state.palpitationsText, synacopeChoice: this.state.synacopeChoice, synacopeText: this.state.synacopeText, legSwellingChoice: this.state.legSwellingChoice, legSwellingText: this.state.legSwellingText, chestPaincvsChoice: this.state.chestPaincvsChoice, chestPaincvsText: this.state.chestPaincvsText, calfSwellingChoice: this.state.calfSwellingChoice, calfSwellingText: this.state.calfSwellingText, pinkFrothChoice: this.state.pinkFrothChoice, pinkFrothText: this.state.pinkFrothText, gelatinousChoice: this.state.gelatinousChoice, gelatinousText: this.state.gelatinousText, cvsNotes: this.state.cvsNotes,
                headacheChoice: this.state.headacheChoice, headacheText: this.state.headacheText, paraesthesiaChoice: this.state.paraesthesiaChoice, paraesthesiaText: this.state.paraesthesiaText, dizzinessChoice: this.state.dizzinessChoice, dizzinessText: this.state.dizzinessText, backackeChoice: this.state.backackeChoice, backacheText: this.state.backacheText, photophibiaChoice: this.state.photophibiaChoice, photophibiaText: this.state.photophibiaText, neckstiffnessChoice: this.state.neckstiffnessChoice, neckstiffnessText: this.state.neckstiffnessText, fittingChoice: this.state.fittingChoice, fittingText: this.state.fittingText, paresisChoice: this.state.paresisChoice, paresisText: this.state.paresisText, paralysisChoice: this.state.paralysisChoice, paralysisText: this.state.paralysisText, insomniaChoice: this.state.insomniaChoice, insomniaText: this.state.insomniaText, hypersomniaChoice: this.state.hypersomniaChoice, hypersomniaText: this.state.hypersomniaText, abnChoice: this.state.abnChoice, abnText: this.state.abnText, cnsNotes: this.state.cnsNotes,
                feverChoice: this.state.feverChoice, feverText: this.state.feverText, sweatingChoice: this.state.sweatingChoice, sweatingText: this.state.sweatingText, chillsChoice: this.state.chillsChoice, chillsText: this.state.chillsText, rigorsChoice: this.state.rigorsChoice, rigorsText: this.state.rigorsText, fatigueChoice: this.state.fatigueChoice, fatigueText: this.state.fatigueText, myalgiaChoice: this.state.myalgiaChoice, myalgiaText: this.state.myalgiaText, constitutionalNotes: this.state.constitutionalNotes,
                bleedingChoice: this.state.bleedingChoice, bleedingText: this.state.bleedingText, bruisingChoice: this.state.bruisingChoice, bruisingText: this.state.bruisingText, swellingChoice: this.state.swellingChoice, swellingText: this.state.swellingText, deformityChoice: this.state.deformityChoice, deformityText: this.state.deformityText, mssNotes: this.state.mssNotes,
                rashChoice: this.state.rashChoice, rashText: this.state.rashText, itchynessChoice: this.state.itchynessChoice, itchynessText: this.state.itchynessText, blistersChoice: this.state.blistersChoice, blistersText: this.state.blistersText, lumpsChoice: this.state.lumpsChoice, lumpsText: this.state.lumpsText, ulcersChoice: this.state.ulcersChoice, ulcersText: this.state.ulcersText, swellingdemaChoice: this.state.swellingdemaChoice, swellingdemaText: this.state.swellingdemaText, dematologyNotes: this.state.dematologyNotes,
                 /* Extra History */ 
                 painText: this.state.painText, durationText: this.state.durationText, timingText: this.state.timingText, aggravatedByText: this.state.aggravatedByText, relievedByText: this.state.relievedByText, headacheLeftChoice: this.state.headacheLeftChoice, headacheLeftText: this.state.headacheLeftText, headacheRightChoice: this.state.headacheRightChoice, headacheRightText: this.state.headacheRightText, holocranialChoice: this.state.holocranialChoice, holocranialText: this.state.holocranialText, frontalChoice: this.state.frontalChoice, frontalText: this.state.frontalText, temporalChoice: this.state.temporalChoice, temporalText: this.state.temporalText, occipitalChoice: this.state.occipitalChoice, occipitalText: this.state.occipitalText, periorbitalChoice: this.state.periorbitalChoice, periorbitalText: this.state.periorbitalText, confusionChoice: this.state.confusionChoice, confusionText: this.state.confusionText, 
                 changeinCharacterChoice: this.state.changeinCharacterChoice, changeinCharacterText: this.state.changeinCharacterText, weaknessChoice: this.state.weaknessChoice, weaknessText: this.state.weaknessText, hemiparesisLeftChoice: this.state.hemiparesisLeftChoice, hemiparesisLeftText: this.state.hemiparesisLeftText, hemiparesisRightChoice: this.state.hemiparesisRightChoice, hemiparesisRightText: this.state.hemiparesisRightText, weaknessHemiplegiaChoice: this.state.weaknessHemiplegiaChoice, weaknessHemiplegiaText: this.state.weaknessHemiplegiaText, hemiplegiaLeftChoice: this.state.hemiplegiaLeftChoice, hemiplegiaLeftText: this.state.hemiplegiaLeftText, hemiplegiaRightChoice: this.state.hemiplegiaRightChoice, hemiplegiaRightText: this.state.hemiplegiaRightText, paraparesisChoice: this.state.paraparesisChoice, paraparesisText: this.state.paraparesisText, paraplegiaChoice: this.state.paraplegiaChoice, paraplegiaText: this.state.paraplegiaText, quadriparesisChoice: this.state.quadriparesisChoice, quadriparesisText: this.state.quadriparesisText, 
                 quadriplegiaChoice: this.state.quadriplegiaChoice, quadriplegiaText: this.state.quadriplegiaText, monoparesisChoice: this.state.monoparesisChoice, monoparesisText: this.state.monoparesisText, monoparesisLeftChoice: this.state.monoparesisLeftChoice, monoparesisLeftText: this.state.monoparesisLeftText, monoparesisRightChoice: this.state.monoparesisRightChoice, monoparesisRightText: this.state.monoparesisRightText, monoparesisUpperLimbChoice: this.state.monoparesisUpperLimbChoice, monoparesisUpperLimbText: this.state.monoparesisUpperLimbText, monoparesisLowerLimbChoice: this.state.monoparesisLowerLimbChoice, monoparesisLowerLimbText: this.state.monoparesisLowerLimbText, seizuresChoice: this.state.seizuresChoice, seizuresText: this.state.seizuresText, seizuresAuraChoice: this.state.seizuresAuraChoice, seizuresAuraText: this.state.seizuresAuraText, auraVisualChoice: this.state.auraVisualChoice, auraVisualText: this.state.auraVisualText, auraOlfactoryChoice: this.state.auraOlfactoryChoice, auraOlfactoryText: this.state.auraOlfactoryText, auraAutonomicChoice: this.state.auraAutonomicChoice, 
                 auraAutonomicText: this.state.auraAutonomicText, thoracicChoice: this.state.thoracicChoice, thoracicText: this.state.thoracicText, lumbarChoice: this.state.lumbarChoice, lumbarText: this.state.lumbarText, lowerBackacheChoice: this.state.lowerBackacheChoice, lowerBackacheText: this.state.lowerBackacheText, mechanicalChoice: this.state.mechanicalChoice, mechanicalText: this.state.mechanicalText, sciaticaChoice: this.state.sciaticaChoice, sciaticaText: this.state.sciaticaText, sciaticaLeftChoice: this.state.sciaticaLeftChoice, sciaticaLeftText: this.state.sciaticaLeftText, sciaticaRightChoice: this.state.sciaticaRightChoice, sciaticaRightText: this.state.sciaticaRightText, cervicalPainChoice: this.state.cervicalPainChoice, cervicalPainText: this.state.cervicalPainText, interScapularPainChoice: this.state.interScapularPainChoice, interScapularPainText: this.state.interScapularPainText, paraesthesiaUpperLimbChoice: this.state.paraesthesiaUpperLimbChoice, 
                 paraesthesiaUpperLimbText: this.state.paraesthesiaUpperLimbText, paraesthesiaLowerLimbChoice: this.state.paraesthesiaLowerLimbChoice, paraesthesiaLowerLimbText: this.state.paraesthesiaLowerLimbText, lossOfBalanceChoice: this.state.lossOfBalanceChoice, lossOfBalanceText: this.state.lossOfBalanceText, neurogenicClaudicationChoice: this.state.neurogenicClaudicationChoice, neurogenicClaudicationText: this.state.neurogenicClaudicationText, radiculapathyChoice: this.state.radiculapathyChoice, radiculapathyText: this.state.radiculapathyText, radiculapathyLeftChoice: this.state.radiculapathyLeftChoice, radiculapathyLeftText: this.state.radiculapathyLeftText, radiculapathyLeftC2Choice: this.state.radiculapathyLeftC2Choice, radiculapathyLeftC2Text: this.state.radiculapathyLeftC2Text, radiculapathyLeftC3Choice: this.state.radiculapathyLeftC3Choice, radiculapathyLeftC3Text: this.state.radiculapathyLeftC3Text, radiculapathyLeftC4Choice: this.state.radiculapathyLeftC4Choice, radiculapathyLeftC4Text: this.state.radiculapathyLeftC4Text, radiculapathyLeftC5Choice: this.state.radiculapathyLeftC5Choice, 
                 radiculapathyLeftC5Text: this.state.radiculapathyLeftC5Text, radiculapathyLeftC6Choice: this.state.radiculapathyLeftC6Choice, radiculapathyLeftC6Text: this.state.radiculapathyLeftC6Text, radiculapathyLeftC7Choice: this.state.radiculapathyLeftC7Choice, radiculapathyLeftC7Text: this.state.radiculapathyLeftC7Text, radiculapathyLeftC8Choice: this.state.radiculapathyLeftC8Choice, radiculapathyLeftC8Text: this.state.radiculapathyLeftC8Text, radiculapathyLeftT1Choice: this.state.radiculapathyLeftT1Choice, radiculapathyLeftT1Text: this.state.radiculapathyLeftT1Text, radiculapathyLeftT2Choice: this.state.radiculapathyLeftT2Choice, radiculapathyLeftT2Text: this.state.radiculapathyLeftT2Text, radiculapathyLeftT3Choice: this.state.radiculapathyLeftT3Choice, radiculapathyLeftT3Text: this.state.radiculapathyLeftT3Text, radiculapathyLeftT4Choice: this.state.radiculapathyLeftT4Choice, radiculapathyLeftT4Text: this.state.radiculapathyLeftT4Text, radiculapathyLeftT5Choice: this.state.radiculapathyLeftT5Choice, radiculapathyLeftT5Text: this.state.radiculapathyLeftT5Text, radiculapathyLeftT6Choice: this.state.radiculapathyLeftT6Choice, 
                 radiculapathyLeftT6Text: this.state.radiculapathyLeftT6Text, radiculapathyLeftT7Choice: this.state.radiculapathyLeftT7Choice, radiculapathyLeftT7Text: this.state.radiculapathyLeftT7Text, radiculapathyLeftT8Choice: this.state.radiculapathyLeftT8Choice, radiculapathyLeftT8Text: this.state.radiculapathyLeftT8Text, radiculapathyLeftT9Choice: this.state.radiculapathyLeftT9Choice, radiculapathyLeftT9Text: this.state.radiculapathyLeftT9Text, radiculapathyLeftT10Choice: this.state.radiculapathyLeftT10Choice, radiculapathyLeftT10Text: this.state.radiculapathyLeftT10Text, radiculapathyLeftT11Choice: this.state.radiculapathyLeftT11Choice, radiculapathyLeftT11Text: this.state.radiculapathyLeftT11Text, radiculapathyLeftT12Choice: this.state.radiculapathyLeftT12Choice, radiculapathyLeftT12Text: this.state.radiculapathyLeftT12Text, radiculapathyLeftL1Choice: this.state.radiculapathyLeftL1Choice, radiculapathyLeftL1Text: this.state.radiculapathyLeftL1Text, radiculapathyLeftL2Choice: this.state.radiculapathyLeftL2Choice, radiculapathyLeftL2Text: this.state.radiculapathyLeftL2Text, radiculapathyLeftL3Choice: this.state.radiculapathyLeftL3Choice, 
                 radiculapathyLeftL3Text: this.state.radiculapathyLeftL3Text, radiculapathyLeftL4Choice: this.state.radiculapathyLeftL4Choice, radiculapathyLeftL4Text: this.state.radiculapathyLeftL4Text, radiculapathyLeftL5Choice: this.state.radiculapathyLeftL5Choice, radiculapathyLeftL5Text: this.state.radiculapathyLeftL5Text, radiculapathyLeftS1Choice: this.state.radiculapathyLeftS1Choice, radiculapathyLeftS1Text: this.state.radiculapathyLeftS1Text, radiculapathyLeftS3Choice: this.state.radiculapathyLeftS3Choice, radiculapathyLeftS3Text: this.state.radiculapathyLeftS3Text, radiculapathyLeftS45Choice: this.state.radiculapathyLeftS45Choice, radiculapathyLeftS45Text: this.state.radiculapathyLeftS45Text, radiculapathyRightChoice: this.state.radiculapathyRightChoice, radiculapathyRightText: this.state.radiculapathyRightText, radiculapathyRightC2Choice: this.state.radiculapathyRightC2Choice, radiculapathyRightC2Text: this.state.radiculapathyRightC2Text, radiculapathyRightC3Choice: this.state.radiculapathyRightC3Choice, radiculapathyRightC3Text: this.state.radiculapathyRightC3Text, radiculapathyRightC4Choice: this.state.radiculapathyRightC4Choice, 
                 radiculapathyRightC4Text: this.state.radiculapathyRightC4Text, radiculapathyRightC5Choice: this.state.radiculapathyRightC5Choice, radiculapathyRightC5Text: this.state.radiculapathyRightC5Text, radiculapathyRightC6Choice: this.state.radiculapathyRightC6Choice, radiculapathyRightC6Text: this.state.radiculapathyRightC6Text, radiculapathyRightC7Choice: this.state.radiculapathyRightC7Choice, radiculapathyRightC7Text: this.state.radiculapathyRightC7Text, radiculapathyRightC8Choice: this.state.radiculapathyRightC8Choice, radiculapathyRightC8Text: this.state.radiculapathyRightC8Text, radiculapathyRightT1Choice: this.state.radiculapathyRightT1Choice, radiculapathyRightT1Text: this.state.radiculapathyRightT1Text, radiculapathyRightT2Choice: this.state.radiculapathyRightT2Choice, radiculapathyRightT2Text: this.state.radiculapathyRightT2Text, radiculapathyRightT3Choice: this.state.radiculapathyRightT3Choice, radiculapathyRightT3Text: this.state.radiculapathyRightT3Text, radiculapathyRightT4Choice: this.state.radiculapathyRightT4Choice, radiculapathyRightT4Text: this.state.radiculapathyRightT4Text, 
                 radiculapathyRightT5Choice: this.state.radiculapathyRightT5Choice, radiculapathyRightT5Text: this.state.radiculapathyRightT5Text, radiculapathyRightT6Choice: this.state.radiculapathyRightT6Choice, radiculapathyRightT6Text: this.state.radiculapathyRightT6Text, radiculapathyRightT7Choice: this.state.radiculapathyRightT7Choice, radiculapathyRightT7Text: this.state.radiculapathyRightT7Text, radiculapathyRightT8Choice: this.state.radiculapathyRightT8Choice, radiculapathyRightT8Text: this.state.radiculapathyRightT8Text, radiculapathyRightT9Choice: this.state.radiculapathyRightT9Choice, radiculapathyRightT9Text: this.state.radiculapathyRightT9Text, radiculapathyRightT10Choice: this.state.radiculapathyRightT10Choice, radiculapathyRightT10Text: this.state.radiculapathyRightT10Text, radiculapathyRightT11Choice: this.state.radiculapathyRightT11Choice, radiculapathyRightT11Text: this.state.radiculapathyRightT11Text, radiculapathyRightT12Choice: this.state.radiculapathyRightT12Choice, radiculapathyRightT12Text: this.state.radiculapathyRightT12Text, radiculapathyRightL1Choice: this.state.radiculapathyRightL1Choice, 
                 radiculapathyRightL1Text: this.state.radiculapathyRightL1Text, radiculapathyRightL2Choice: this.state.radiculapathyRightL2Choice, radiculapathyRightL2Text: this.state.radiculapathyRightL2Text, radiculapathyRightL3Choice: this.state.radiculapathyRightL3Choice, radiculapathyRightL3Text: this.state.radiculapathyRightL3Text, radiculapathyRightL4Choice: this.state.radiculapathyRightL4Choice, radiculapathyRightL4Text: this.state.radiculapathyRightL4Text, radiculapathyRightL5Choice: this.state.radiculapathyRightL5Choice, radiculapathyRightL5Text: this.state.radiculapathyRightL5Text, radiculapathyRightS1Choice: this.state.radiculapathyRightS1Choice, radiculapathyRightS1Text: this.state.radiculapathyRightS1Text, radiculapathyRightS3Choice: this.state.radiculapathyRightS3Choice, radiculapathyRightS3Text: this.state.radiculapathyRightS3Text, radiculapathyRightS45Choice: this.state.radiculapathyRightS45Choice, radiculapathyRightS45Text: this.state.radiculapathyRightS45Text,
            }
            , examination: {eyeText: this.state.eyeText ,motorText: this.state.motorText ,verbalText: this.state.verbalText ,gcsText: this.state.gcsText ,dementiaChoice: this.state.dementiaChoice ,dementiaText: this.state.dementiaText ,tandemChoice: this.state.tandemChoice ,tandemText: this.state.tandemText ,dysdiadochokinesiaChoice: this.state.dysdiadochokinesiaChoice ,dysdiadochokinesiaText: this.state.dysdiadochokinesiaText ,rombergChoice: this.state.rombergChoice ,rombergText: this.state.rombergText ,rombergPositiveChoice: this.state.rombergPositiveChoice ,rombergPostiveText: this.state.rombergPostiveText ,rombergNegativeChoice: this.state.rombergNegativeChoice ,rombergNegativeText: this.state.rombergNegativeText ,ataxiaChoice: this.state.ataxiaChoice ,ataxiaText: this.state.ataxiaText ,ataxiaTruncalChoice: this.state.ataxiaTruncalChoice ,ataxiaTruncalText: this.state.ataxiaTruncalText ,ataxiaAppendicularChoice: this.state.ataxiaAppendicularChoice ,ataxiaAppendicularText: this.state.ataxiaAppendicularText ,ataxiaGlobalChoice: this.state.ataxiaGlobalChoice ,ataxiaGlobalText: this.state.ataxiaGlobalText ,nystagmusChoice: this.state.nystagmusChoice ,
                nystagmusText: this.state.nystagmusText ,dysphasiaChoice: this.state.dysphasiaChoice ,dysphasiaText: this.state.dysphasiaText ,dysphasiaExpressiveChoice: this.state.dysphasiaExpressiveChoice ,dysphasiaExpressiveText: this.state.dysphasiaExpressiveText ,dysphasiaReceptiveChoice: this.state.dysphasiaReceptiveChoice ,dysphasiaReceptiveText: this.state.dysphasiaReceptiveText ,dysphasiaGlobalChoice: this.state.dysphasiaGlobalChoice ,dysphasiaGlobalText: this.state.dysphasiaGlobalText ,abuliaChoice: this.state.abuliaChoice ,abuliaText: this.state.abuliaText ,astereognosiaChoice: this.state.astereognosiaChoice ,astereognosiaText: this.state.astereognosiaText ,akineticChoice: this.state.akineticChoice ,akineticText: this.state.akineticText ,hemianopsiaChoice: this.state.hemianopsiaChoice ,hemianopsiaText: this.state.hemianopsiaText ,hemianopsiaRightChoice: this.state.hemianopsiaRightChoice ,hemianopsiaRightText: this.state.hemianopsiaRightText ,hemianopsiaLeftChoice: this.state.hemianopsiaLeftChoice ,hemianopsiaLeftText: this.state.hemianopsiaLeftText ,hemianopsiaBitemporalChoice: this.state.hemianopsiaBitemporalChoice ,
                hemianopsiaBitemporalText: this.state.hemianopsiaBitemporalText ,hemianopsiaTemporalChoice: this.state.hemianopsiaTemporalChoice ,hemianopsiaTemporalText: this.state.hemianopsiaTemporalText ,hemianopsiaNasalChoice: this.state.hemianopsiaNasalChoice ,hemianopsiaNasalText: this.state.hemianopsiaNasalText ,cranialChoice: this.state.cranialChoice ,cranialText: this.state.cranialText ,cranialLeftChoice: this.state.cranialLeftChoice ,cranialLeftText: this.state.cranialLeftText ,olfactoryLeftChoice: this.state.olfactoryLeftChoice ,olfactoryLeftText: this.state.olfactoryLeftText ,opticLeftChoice: this.state.opticLeftChoice ,opticLeftText: this.state.opticLeftText ,oculomotorLeftChoice: this.state.oculomotorLeftChoice ,oculomotorLeftText: this.state.oculomotorLeftText ,trochlearLeftChoice: this.state.trochlearLeftChoice ,trochlearLeftText: this.state.trochlearLeftText ,trigeminalLeftChoice: this.state.trigeminalLeftChoice ,trigeminalLeftText: this.state.trigeminalLeftText ,abducentLeftChoice: this.state.abducentLeftChoice ,abducentLeftText: this.state.abducentLeftText ,facialLeftChoice: this.state.facialLeftChoice ,
                facialLeftText: this.state.facialLeftText ,vestibulocochlearLeftChoice: this.state.vestibulocochlearLeftChoice ,vestibulocochlearLeftText: this.state.vestibulocochlearLeftText ,glossopharyngealLeftChoice: this.state.glossopharyngealLeftChoice ,glossopharyngealLeftText: this.state.glossopharyngealLeftText ,vagusLeftChoice: this.state.vagusLeftChoice ,vagusLeftText: this.state.vagusLeftText ,accessoryLeftChoice: this.state.accessoryLeftChoice ,accessoryLeftText: this.state.accessoryLeftText ,hypoglossalLeftChoice: this.state.hypoglossalLeftChoice ,hypoglossalLeftText: this.state.hypoglossalLeftText ,cranialRightChoice: this.state.cranialRightChoice ,cranialRightText: this.state.cranialRightText ,olfactoryRightChoice: this.state.olfactoryRightChoice ,olfactoryRightText: this.state.olfactoryRightText ,opticRightChoice: this.state.opticRightChoice ,opticRightText: this.state.opticRightText ,oculomotorRightChoice: this.state.oculomotorRightChoice ,oculomotorRightText: this.state.oculomotorRightText ,trochlearRightChoice: this.state.trochlearRightChoice ,trochlearRightText: this.state.trochlearRightText ,
                trigeminalRightChoice: this.state.trigeminalRightChoice ,trigeminalRightText: this.state.trigeminalRightText ,abducentRightChoice: this.state.abducentRightChoice ,abducentRightText: this.state.abducentRightText ,facialRightChoice: this.state.facialRightChoice ,facialRightText: this.state.facialRightText ,vestibulocochlearRightChoice: this.state.vestibulocochlearRightChoice ,vestibulocochlearRightText: this.state.vestibulocochlearRightText ,glossopharyngealRightChoice: this.state.glossopharyngealRightChoice ,glossopharyngealRightText: this.state.glossopharyngealRightText ,vagusRightChoice: this.state.vagusRightChoice ,vagusRightText: this.state.vagusRightText ,accessoryRightChoice: this.state.accessoryRightChoice ,accessoryRightText: this.state.accessoryRightText ,hypoglossalRightChoice: this.state.hypoglossalRightChoice ,hypoglossalRightText: this.state.hypoglossalRightText ,spineRangeChoice: this.state.spineRangeChoice ,spineRangeText: this.state.spineRangeText ,spineRangeCervicalChoice: this.state.spineRangeCervicalChoice ,spineRangeCervicalText: this.state.spineRangeCervicalText ,
                spineRangeCervicalFlexionChoice: this.state.spineRangeCervicalFlexionChoice ,spineRangeCervicalFlexionText: this.state.spineRangeCervicalFlexionText ,spineRangeCervicalFlexionReducedChoice: this.state.spineRangeCervicalFlexionReducedChoice ,spineRangeCervicalFlexionReducedText: this.state.spineRangeCervicalFlexionReducedText ,spineRangeCervicalFlexionPainfulChoice: this.state.spineRangeCervicalFlexionPainfulChoice ,spineRangeCervicalFlexionPainfulText: this.state.spineRangeCervicalFlexionPainfulText ,spineRangeCervicalExtensionChoice: this.state.spineRangeCervicalExtensionChoice ,spineRangeCervicalExtensionText: this.state.spineRangeCervicalExtensionText ,spineRangeCervicalExtensionReducedChoice: this.state.spineRangeCervicalExtensionReducedChoice ,spineRangeCervicalExtensionReducedText: this.state.spineRangeCervicalExtensionReducedText ,spineRangeCervicalExtensionPainfulChoice: this.state.spineRangeCervicalExtensionPainfulChoice ,spineRangeCervicalExtensionPainfulText: this.state.spineRangeCervicalExtensionPainfulText ,spineRangeCervicalLateralBendingChoice: this.state.spineRangeCervicalLateralBendingChoice ,
                spineRangeCervicalLateralBendingText: this.state.spineRangeCervicalLateralBendingText ,spineRangeCervicalLateralBendingReducedChoice: this.state.spineRangeCervicalLateralBendingReducedChoice ,spineRangeCervicalLateralBendingReducedText: this.state.spineRangeCervicalLateralBendingReducedText ,spineRangeCervicalLateralBendingPainfulChoice: this.state.spineRangeCervicalLateralBendingPainfulChoice ,spineRangeCervicalLateralBendingPainfulText: this.state.spineRangeCervicalLateralBendingPainfulText ,spineRangeCervicalRotationChoice: this.state.spineRangeCervicalRotationChoice ,spineRangeCervicalRotationText: this.state.spineRangeCervicalRotationText ,spineRangeCervicalRotationReducedChoice: this.state.spineRangeCervicalRotationReducedChoice ,spineRangeCervicalRotationReducedText: this.state.spineRangeCervicalRotationReducedText ,spineRangeCervicalRotationPainfulChoice: this.state.spineRangeCervicalRotationPainfulChoice ,spineRangeCervicalRotationPainfulText: this.state.spineRangeCervicalRotationPainfulText ,spineRangeThoracicChoice: this.state.spineRangeThoracicChoice ,
                spineRangeThoracicText: this.state.spineRangeThoracicText ,spineRangeThoracicFlexionChoice: this.state.spineRangeThoracicFlexionChoice ,spineRangeThoracicFlexionText: this.state.spineRangeThoracicFlexionText ,spineRangeThoracicFlexionReducedChoice: this.state.spineRangeThoracicFlexionReducedChoice ,spineRangeThoracicFlexionReducedText: this.state.spineRangeThoracicFlexionReducedText ,spineRangeThoracicFlexionPainfulChoice: this.state.spineRangeThoracicFlexionPainfulChoice ,spineRangeThoracicFlexionPainfulText: this.state.spineRangeThoracicFlexionPainfulText ,spineRangeThoracicExtensionChoice: this.state.spineRangeThoracicExtensionChoice ,spineRangeThoracicExtensionText: this.state.spineRangeThoracicExtensionText ,spineRangeThoracicExtensionReducedChoice: this.state.spineRangeThoracicExtensionReducedChoice ,spineRangeThoracicExtensionReducedText: this.state.spineRangeThoracicExtensionReducedText ,spineRangeThoracicExtensionPainfulChoice: this.state.spineRangeThoracicExtensionPainfulChoice ,spineRangeThoracicExtensionPainfulText: this.state.spineRangeThoracicExtensionPainfulText ,
                spineRangeThoracicLateralBendingChoice: this.state.spineRangeThoracicLateralBendingChoice ,spineRangeThoracicLateralBendingText: this.state.spineRangeThoracicLateralBendingText ,spineRangeThoracicLateralBendingReducedChoice: this.state.spineRangeThoracicLateralBendingReducedChoice ,spineRangeThoracicLateralBendingReducedText: this.state.spineRangeThoracicLateralBendingReducedText ,spineRangeThoracicLateralBendingPainfulChoice: this.state.spineRangeThoracicLateralBendingPainfulChoice ,spineRangeThoracicLateralBendingPainfulText: this.state.spineRangeThoracicLateralBendingPainfulText ,spineRangeThoracicRotationChoice: this.state.spineRangeThoracicRotationChoice ,spineRangeThoracicRotationText: this.state.spineRangeThoracicRotationText ,spineRangeThoracicRotationReducedChoice: this.state.spineRangeThoracicRotationReducedChoice ,spineRangeThoracicRotationReducedText: this.state.spineRangeThoracicRotationReducedText ,spineRangeThoracicRotationPainfulChoice: this.state.spineRangeThoracicRotationPainfulChoice ,spineRangeThoracicRotationPainfulText: this.state.spineRangeThoracicRotationPainfulText ,
                spineRangeLumbarChoice: this.state.spineRangeLumbarChoice ,spineRangeLumbarText: this.state.spineRangeLumbarText ,spineRangeLumbarFlexionChoice: this.state.spineRangeLumbarFlexionChoice ,spineRangeLumbarFlexionText: this.state.spineRangeLumbarFlexionText ,spineRangeLumbarFlexionReducedChoice: this.state.spineRangeLumbarFlexionReducedChoice ,spineRangeLumbarFlexionReducedText: this.state.spineRangeLumbarFlexionReducedText ,spineRangeLumbarFlexionPainfulChoice: this.state.spineRangeLumbarFlexionPainfulChoice ,spineRangeLumbarFlexionPainfulText: this.state.spineRangeLumbarFlexionPainfulText ,spineRangeLumbarExtensionChoice: this.state.spineRangeLumbarExtensionChoice ,spineRangeLumbarExtensionText: this.state.spineRangeLumbarExtensionText ,spineRangeLumbarExtensionReducedChoice: this.state.spineRangeLumbarExtensionReducedChoice ,spineRangeLumbarExtensionReducedText: this.state.spineRangeLumbarExtensionReducedText ,spineRangeLumbarExtensionPainfulChoice: this.state.spineRangeLumbarExtensionPainfulChoice ,spineRangeLumbarExtensionPainfulText: this.state.spineRangeLumbarExtensionPainfulText ,
                spineRangeLumbarLateralBendingChoice: this.state.spineRangeLumbarLateralBendingChoice ,spineRangeLumbarLateralBendingText: this.state.spineRangeLumbarLateralBendingText ,spineRangeLumbarLateralBendingReducedChoice: this.state.spineRangeLumbarLateralBendingReducedChoice ,spineRangeLumbarLateralBendingReducedText: this.state.spineRangeLumbarLateralBendingReducedText ,spineRangeLumbarLateralBendingPainfulChoice: this.state.spineRangeLumbarLateralBendingPainfulChoice ,spineRangeLumbarLateralBendingPainfulText: this.state.spineRangeLumbarLateralBendingPainfulText ,spineRangeLumbarRotationChoice: this.state.spineRangeLumbarRotationChoice ,spineRangeLumbarRotationText: this.state.spineRangeLumbarRotationText ,spineRangeLumbarRotationReducedChoice: this.state.spineRangeLumbarRotationReducedChoice ,spineRangeLumbarRotationReducedText: this.state.spineRangeLumbarRotationReducedText ,spineRangeLumbarRotationPainfulChoice: this.state.spineRangeLumbarRotationPainfulChoice ,spineRangeLumbarRotationPainfulText: this.state.spineRangeLumbarRotationPainfulText ,spineTendernessChoice: this.state.spineTendernessChoice ,
                spineTendernessText: this.state.spineTendernessText ,spineTendernessCervicalChoice: this.state.spineTendernessCervicalChoice ,spineTendernessCervicalText: this.state.spineTendernessCervicalText ,spineTendernessThoracicChoicespineTendernessThoracicText: this.state.spineTendernessThoracicChoicespineTendernessThoracicText ,spineTendernessThoracolumbarChoice: this.state.spineTendernessThoracolumbarChoice ,spineTendernessThoracolumbarText: this.state.spineTendernessThoracolumbarText ,spineTendernessLumbarChoice: this.state.spineTendernessLumbarChoice ,spineTendernessLumbarText: this.state.spineTendernessLumbarText ,spineTendernessLumbosacralChoice: this.state.spineTendernessLumbosacralChoice ,spineTendernessLumbosacralText: this.state.spineTendernessLumbosacralText ,spineTendernessSacroiliacChoice: this.state.spineTendernessSacroiliacChoice ,spineTendernessSacroiliacText: this.state.spineTendernessSacroiliacText ,trendelenbergChoice: this.state.trendelenbergChoice ,trendelenbergText: this.state.trendelenbergText ,trendelenbergLeftChoice: this.state.trendelenbergLeftChoice ,trendelenbergLeftText: this.state.trendelenbergLeftText ,
                trendelenbergLeftPresentChoice: this.state.trendelenbergLeftPresentChoice ,trendelenbergLeftPresentText: this.state.trendelenbergLeftPresentText ,trendelenbergLeftNormalChoice: this.state.trendelenbergLeftNormalChoice ,trendelenbergLeftNormalText: this.state.trendelenbergLeftNormalText ,trendelenbergRightChoice: this.state.trendelenbergRightChoice ,trendelenbergRightText: this.state.trendelenbergRightText ,trendelenbergRightPresentChoice: this.state.trendelenbergRightPresentChoice ,trendelenbergRightPresentText: this.state.trendelenbergRightPresentText ,trendelenbergRightNormalChoice: this.state.trendelenbergRightNormalChoice ,trendelenbergRightNormalText: this.state.trendelenbergRightNormalText ,standOnTipToeChoice: this.state.standOnTipToeChoice ,standOnTipToeText: this.state.standOnTipToeText ,standOnTipToeLeftChoice: this.state.standOnTipToeLeftChoice ,standOnTipToeLeftText: this.state.standOnTipToeLeftText ,standOnTipToeLeftWeakChoice: this.state.standOnTipToeLeftWeakChoice ,standOnTipToeLeftWeakText: this.state.standOnTipToeLeftWeakText ,standOnTipToeLeftNormalChoice: this.state.standOnTipToeLeftNormalChoice ,
                standOnTipToeLeftNormalText: this.state.standOnTipToeLeftNormalText ,standOnTipToeRightChoice: this.state.standOnTipToeRightChoice ,standOnTipToeRightText: this.state.standOnTipToeRightText ,standOnTipToeRightWeakChoice: this.state.standOnTipToeRightWeakChoice ,standOnTipToeRightWeakText: this.state.standOnTipToeRightWeakText ,standOnTipToeRightNormalChoice: this.state.standOnTipToeRightNormalChoice ,standOnTipToeRightNormalText: this.state.standOnTipToeRightNormalText ,spurlingTestChoice: this.state.spurlingTestChoice ,spurlingTestText: this.state.spurlingTestText ,spurlingTestLeftChoice: this.state.spurlingTestLeftChoice ,spurlingTestLeftText: this.state.spurlingTestLeftText ,spurlingTestLeftPresentChoice: this.state.spurlingTestLeftPresentChoice ,spurlingTestLeftPresentText: this.state.spurlingTestLeftPresentText ,spurlingTestLeftAbsentChoice: this.state.spurlingTestLeftAbsentChoice ,spurlingTestLeftAbsentText: this.state.spurlingTestLeftAbsentText ,spurlingTestRightChoice: this.state.spurlingTestRightChoice ,spurlingTestRightText: this.state.spurlingTestRightText ,spurlingTestRightPresentChoice: this.state.spurlingTestRightPresentChoice ,
                spurlingTestRightPresentText: this.state.spurlingTestRightPresentText ,spurlingTestRightAbsentChoice: this.state.spurlingTestRightAbsentChoice ,spurlingTestRightAbsentText: this.state.spurlingTestRightAbsentText ,abductionReliefChoice: this.state.abductionReliefChoice ,abductionReliefText: this.state.abductionReliefText ,abductionReliefLeftChoice: this.state.abductionReliefLeftChoice ,abductionReliefLeftText: this.state.abductionReliefLeftText ,abductionReliefLeftPresentChoice: this.state.abductionReliefLeftPresentChoice ,abductionReliefLeftPresentText: this.state.abductionReliefLeftPresentText ,abductionReliefLeftAbsentChoice: this.state.abductionReliefLeftAbsentChoice ,abductionReliefLeftAbsentText: this.state.abductionReliefLeftAbsentText ,abductionReliefRightChoice: this.state.abductionReliefRightChoice ,abductionReliefRightText: this.state.abductionReliefRightText ,abductionReliefRightPresentChoice: this.state.abductionReliefRightPresentChoice ,abductionReliefRightPresentText: this.state.abductionReliefRightPresentText ,abductionReliefRightAbsentChoice: this.state.abductionReliefRightAbsentChoice ,
                abductionReliefRightAbsentText: this.state.abductionReliefRightAbsentText ,straightLegRaisingChoice: this.state.straightLegRaisingChoice ,straightLegRaisingText: this.state.straightLegRaisingText ,straightLegRaisingLeftChoice: this.state.straightLegRaisingLeftChoice ,straightLegRaisingLeftText: this.state.straightLegRaisingLeftText ,straightLegRaisingLeftPresentChoice: this.state.straightLegRaisingLeftPresentChoice ,straightLegRaisingLeftPresentText: this.state.straightLegRaisingLeftPresentText ,straightLegRaisingLeftAbsentChoice: this.state.straightLegRaisingLeftAbsentChoice ,straightLegRaisingLeftAbsentText: this.state.straightLegRaisingLeftAbsentText ,straightLegRaisingRightChoice: this.state.straightLegRaisingRightChoice ,straightLegRaisingRightText: this.state.straightLegRaisingRightText ,straightLegRaisingRightPresentChoice: this.state.straightLegRaisingRightPresentChoice ,straightLegRaisingRightPresentText: this.state.straightLegRaisingRightPresentText ,straightLegRaisingRightAbsentChoice: this.state.straightLegRaisingRightAbsentChoice ,straightLegRaisingRightAbsentText: this.state.straightLegRaisingRightAbsentText ,
                reverseStraightLegChoice: this.state.reverseStraightLegChoice ,reverseStraightLegText: this.state.reverseStraightLegText ,reverseStraightLegLeftChoice: this.state.reverseStraightLegLeftChoice ,reverseStraightLegLeftText: this.state.reverseStraightLegLeftText ,reverseStraightLegLeftPresentChoice: this.state.reverseStraightLegLeftPresentChoice ,reverseStraightLegLeftPresentText: this.state.reverseStraightLegLeftPresentText ,reverseStraightLegLeftAbsentChoice: this.state.reverseStraightLegLeftAbsentChoice ,reverseStraightLegLeftAbsentText: this.state.reverseStraightLegLeftAbsentText ,reverseStraightLegRightChoice: this.state.reverseStraightLegRightChoice ,reverseStraightLegRightText: this.state.reverseStraightLegRightText ,reverseStraightLegRightPresentChoice: this.state.reverseStraightLegRightPresentChoice ,reverseStraightLegRightPresentText: this.state.reverseStraightLegRightPresentText ,reverseStraightLegRightAbsentChoice: this.state.reverseStraightLegRightAbsentChoice ,reverseStraightLegRightAbsentText: this.state.reverseStraightLegRightAbsentText ,toneChoice: this.state.toneChoice ,toneText: this.state.toneText ,
                toneLeftUpperLimbChoice: this.state.toneLeftUpperLimbChoice ,toneLeftUpperLimbText: this.state.toneLeftUpperLimbText ,toneLeftUpperLimbHypertoniaChoice: this.state.toneLeftUpperLimbHypertoniaChoice ,toneLeftUpperLimbHypertoniaText: this.state.toneLeftUpperLimbHypertoniaText ,toneLeftUpperLimbHypotoniaChoice: this.state.toneLeftUpperLimbHypotoniaChoice ,toneLeftUpperLimbHypotoniaText: this.state.toneLeftUpperLimbHypotoniaText ,toneLeftUpperLimbAtoniaChoice: this.state.toneLeftUpperLimbAtoniaChoice ,toneLeftUpperLimbAtoniaText: this.state.toneLeftUpperLimbAtoniaText ,toneRightUpperLimbChoice: this.state.toneRightUpperLimbChoice ,toneRightUpperLimbText: this.state.toneRightUpperLimbText ,toneRightUpperLimbHypertoniaChoice: this.state.toneRightUpperLimbHypertoniaChoice ,toneRightUpperLimbHypertoniaText: this.state.toneRightUpperLimbHypertoniaText ,toneRightUpperLimbHypotoniaChoice: this.state.toneRightUpperLimbHypotoniaChoice ,toneRightUpperLimbHypotoniaText: this.state.toneRightUpperLimbHypotoniaText ,toneRightUpperLimbAtoniaChoice: this.state.toneRightUpperLimbAtoniaChoice ,toneRightUpperLimbAtoniaText: this.state.toneRightUpperLimbAtoniaText ,
                toneLeftLowerLimbChoice: this.state.toneLeftLowerLimbChoice ,toneLeftLowerLimbText: this.state.toneLeftLowerLimbText ,toneLeftLowerLimbHypertoniaChoice: this.state.toneLeftLowerLimbHypertoniaChoice ,toneLeftLowerLimbHypertoniaText: this.state.toneLeftLowerLimbHypertoniaText ,toneLeftLowerLimbHypotoniaChoice: this.state.toneLeftLowerLimbHypotoniaChoice ,toneLeftLowerLimbHypotoniaText: this.state.toneLeftLowerLimbHypotoniaText ,toneLeftLowerLimbAtoniaChoice: this.state.toneLeftLowerLimbAtoniaChoice ,toneLeftLowerLimbAtoniaText: this.state.toneLeftLowerLimbAtoniaText ,toneRightLowerLimbChoice: this.state.toneRightLowerLimbChoice ,toneRightLowerLimbText: this.state.toneRightLowerLimbText ,toneRightLowerLimbHypertoniaChoice: this.state.toneRightLowerLimbHypertoniaChoice ,toneRightLowerLimbHypertoniaText: this.state.toneRightLowerLimbHypertoniaText ,toneRightLowerLimbHypotoniaChoice: this.state.toneRightLowerLimbHypotoniaChoice ,toneRightLowerLimbHypotoniaText: this.state.toneRightLowerLimbHypotoniaText ,toneRightLowerLimbAtoniaChoice: this.state.toneRightLowerLimbAtoniaChoice ,toneRightLowerLimbAtoniaText: this.state.toneRightLowerLimbAtoniaText ,
                toneGlobalChoice: this.state.toneGlobalChoice ,toneGlobalText: this.state.toneGlobalText ,toneGlobalHypertoniaChoice: this.state.toneGlobalHypertoniaChoice ,toneGlobalHypertoniaText: this.state.toneGlobalHypertoniaText ,toneGlobalHypotoniaChoice: this.state.toneGlobalHypotoniaChoice ,toneGlobalHypotoniaText: this.state.toneGlobalHypotoniaText ,toneGlobalAbsentChoice: this.state.toneGlobalAbsentChoice ,toneGlobalAbsentText: this.state.toneGlobalAbsentText ,powerChoice: this.state.powerChoice ,powerText: this.state.powerText ,powerLeftUpperLimbChoice: this.state.powerLeftUpperLimbChoice ,powerLeftUpperLimbText: this.state.powerLeftUpperLimbText ,powerLeftUpperLimbC5Choice: this.state.powerLeftUpperLimbC5Choice ,powerLeftUpperLimbC5Text: this.state.powerLeftUpperLimbC5Text ,powerLeftUpperLimbC6Choice: this.state.powerLeftUpperLimbC6Choice ,powerLeftUpperLimbC6Text: this.state.powerLeftUpperLimbC6Text ,powerLeftUpperLimbC7Choice: this.state.powerLeftUpperLimbC7Choice ,powerLeftUpperLimbC7Text: this.state.powerLeftUpperLimbC7Text ,powerLeftUpperLimbC8Choice: this.state.powerLeftUpperLimbC8Choice ,powerLeftUpperLimbC8Text: this.state.powerLeftUpperLimbC8Text ,
                powerLeftUpperLimbT1Choice: this.state.powerLeftUpperLimbT1Choice ,powerLeftUpperLimbT1Text: this.state.powerLeftUpperLimbT1Text ,powerRightUpperLimbChoice: this.state.powerRightUpperLimbChoice ,powerRightUpperLimbText: this.state.powerRightUpperLimbText ,powerRightUpperLimbC5Choice: this.state.powerRightUpperLimbC5Choice ,powerRightUpperLimbC5Text: this.state.powerRightUpperLimbC5Text ,powerRightUpperLimbC6Choice: this.state.powerRightUpperLimbC6Choice ,powerRightUpperLimbC6Text: this.state.powerRightUpperLimbC6Text ,powerRightUpperLimbC7Choice: this.state.powerRightUpperLimbC7Choice ,powerRightUpperLimbC7Text: this.state.powerRightUpperLimbC7Text ,powerRightUpperLimbC8Choice: this.state.powerRightUpperLimbC8Choice ,powerRightUpperLimbC8Text: this.state.powerRightUpperLimbC8Text ,powerRightUpperLimbT1Choice: this.state.powerRightUpperLimbT1Choice ,powerRightUpperLimbT1Text: this.state.powerRightUpperLimbT1Text ,powerLeftLowerLimbChoice: this.state.powerLeftLowerLimbChoice ,powerLeftLowerLimbText: this.state.powerLeftLowerLimbText ,powerLeftLowerLimbC5Choice: this.state.powerLeftLowerLimbC5Choice ,powerLeftLowerLimbC5Text: this.state.powerLeftLowerLimbC5Text ,
                powerLeftLowerLimbC6Choice: this.state.powerLeftLowerLimbC6Choice ,powerLeftLowerLimbC6Text: this.state.powerLeftLowerLimbC6Text ,powerLeftLowerLimbC7Choice: this.state.powerLeftLowerLimbC7Choice ,powerLeftLowerLimbC7Text: this.state.powerLeftLowerLimbC7Text ,powerLeftLowerLimbC8Choice: this.state.powerLeftLowerLimbC8Choice ,powerLeftLowerLimbC8Text: this.state.powerLeftLowerLimbC8Text ,powerLeftLowerLimbT1Choice: this.state.powerLeftLowerLimbT1Choice ,powerLeftLowerLimbT1Text: this.state.powerLeftLowerLimbT1Text ,powerRightLowerLimbChoice: this.state.powerRightLowerLimbChoice ,powerRightLowerLimbText: this.state.powerRightLowerLimbText ,powerRightLowerLimbC5Choice: this.state.powerRightLowerLimbC5Choice ,powerRightLowerLimbC5Text: this.state.powerRightLowerLimbC5Text ,powerRightLowerLimbC6Choice: this.state.powerRightLowerLimbC6Choice ,powerRightLowerLimbC6Text: this.state.powerRightLowerLimbC6Text ,powerRightLowerLimbC7Choice: this.state.powerRightLowerLimbC7Choice ,powerRightLowerLimbC7Text: this.state.powerRightLowerLimbC7Text ,powerRightLowerLimbC8Choice: this.state.powerRightLowerLimbC8Choice ,powerRightLowerLimbC8Text: this.state.powerRightLowerLimbC8Text ,
                powerRightLowerLimbT1Choice: this.state.powerRightLowerLimbT1Choice ,powerRightLowerLimbT1Text: this.state.powerRightLowerLimbT1Text ,wastingChoice: this.state.wastingChoice ,wastingText: this.state.wastingText ,wastingLeftUpperLimbChoice: this.state.wastingLeftUpperLimbChoice ,wastingLeftUpperLimbText: this.state.wastingLeftUpperLimbText ,wastingRightUpperLimbChoice: this.state.wastingRightUpperLimbChoice ,wastingRightUpperLimbText: this.state.wastingRightUpperLimbText ,wastingLeftLowerLimbChoice: this.state.wastingLeftLowerLimbChoice ,wastingLeftLowerLimbText: this.state.wastingLeftLowerLimbText ,wastingRightLowerLimbChoice: this.state.wastingRightLowerLimbChoice ,wastingRightLowerLimbText: this.state.wastingRightLowerLimbText ,fasciculationsChoice: this.state.fasciculationsChoice ,fasciculationsText: this.state.fasciculationsText ,fasciculationsLeftUpperLimbChoice: this.state.fasciculationsLeftUpperLimbChoice ,fasciculationsLeftUpperLimbText: this.state.fasciculationsLeftUpperLimbText ,fasciculationsRightUpperLimbChoice: this.state.fasciculationsRightUpperLimbChoice ,fasciculationsRightUpperLimbText: this.state.fasciculationsRightUpperLimbText ,
                fasciculationsLeftLowerLimbChoice: this.state.fasciculationsLeftLowerLimbChoice ,fasciculationsLeftLowerLimbText: this.state.fasciculationsLeftLowerLimbText ,fasciculationsRightLowerLimbChoice: this.state.fasciculationsRightLowerLimbChoice ,fasciculationsRightLowerLimbText: this.state.fasciculationsRightLowerLimbText ,deepTendonChoice: this.state.deepTendonChoice ,deepTendonText: this.state.deepTendonText ,deepTendonLeftBicepsChoice: this.state.deepTendonLeftBicepsChoice ,deepTendonLeftBicepsText: this.state.deepTendonLeftBicepsText ,deepTendonLeftBicepsHyperChoice: this.state.deepTendonLeftBicepsHyperChoice ,deepTendonLeftBicepsHyperText: this.state.deepTendonLeftBicepsHyperText ,deepTendonLeftBicepsHypoChoice: this.state.deepTendonLeftBicepsHypoChoice ,deepTendonLeftBicepsHypoText: this.state.deepTendonLeftBicepsHypoText ,deepTendonLeftBicepsAChoice: this.state.deepTendonLeftBicepsAChoice ,deepTendonLeftBicepsAText: this.state.deepTendonLeftBicepsAText ,deepTendonRightBicepsChoice: this.state.deepTendonRightBicepsChoice ,deepTendonRightBicepsText: this.state.deepTendonRightBicepsText ,deepTendonRightBicepsHyperChoice: this.state.deepTendonRightBicepsHyperChoice ,
                deepTendonRightBicepsHyperText: this.state.deepTendonRightBicepsHyperText ,deepTendonRightBicepsHypoChoice: this.state.deepTendonRightBicepsHypoChoice ,deepTendonRightBicepsHypoText: this.state.deepTendonRightBicepsHypoText ,deepTendonRightBicepsAChoice: this.state.deepTendonRightBicepsAChoice ,deepTendonRightBicepsAText: this.state.deepTendonRightBicepsAText ,deepTendonLeftTricepsChoice: this.state.deepTendonLeftTricepsChoice ,deepTendonLeftTricepsText: this.state.deepTendonLeftTricepsText ,deepTendonLeftTricepsHyperChoice: this.state.deepTendonLeftTricepsHyperChoice ,deepTendonLeftTricepsHyperText: this.state.deepTendonLeftTricepsHyperText ,deepTendonLeftTricepsHypoChoice: this.state.deepTendonLeftTricepsHypoChoice ,deepTendonLeftTricepsHypoText: this.state.deepTendonLeftTricepsHypoText ,deepTendonLeftTricepsAChoice: this.state.deepTendonLeftTricepsAChoice ,deepTendonLeftTricepsAText: this.state.deepTendonLeftTricepsAText ,deepTendonRightTricepsChoice: this.state.deepTendonRightTricepsChoice ,deepTendonRightTricepsText: this.state.deepTendonRightTricepsText ,deepTendonRightTricepsHyperChoice: this.state.deepTendonRightTricepsHyperChoice ,
                deepTendonRightTricepsHyperText: this.state.deepTendonRightTricepsHyperText ,deepTendonRightTricepsHypoChoice: this.state.deepTendonRightTricepsHypoChoice ,deepTendonRightTricepsHypoText: this.state.deepTendonRightTricepsHypoText ,deepTendonRightTricepsAChoice: this.state.deepTendonRightTricepsAChoice ,deepTendonRightTricepsAText: this.state.deepTendonRightTricepsAText ,deepTendonInvertedRadialChoice: this.state.deepTendonInvertedRadialChoice ,deepTendonInvertedRadialText: this.state.deepTendonInvertedRadialText ,deepTendonInvertedRadialLeftChoice: this.state.deepTendonInvertedRadialLeftChoice ,deepTendonInvertedRadialLeftText: this.state.deepTendonInvertedRadialLeftText ,deepTendonInvertedRadialRightChoice: this.state.deepTendonInvertedRadialRightChoice ,deepTendonInvertedRadialRightText: this.state.deepTendonInvertedRadialRightText ,deepTendonLeftKneeChoice: this.state.deepTendonLeftKneeChoice ,deepTendonLeftKneeText: this.state.deepTendonLeftKneeText ,deepTendonLeftKneeHyperChoice: this.state.deepTendonLeftKneeHyperChoice ,deepTendonLeftKneeHyperText: this.state.deepTendonLeftKneeHyperText ,deepTendonLeftKneeHypoChoice: this.state.deepTendonLeftKneeHypoChoice ,
                deepTendonLeftKneeHypoText: this.state.deepTendonLeftKneeHypoText ,deepTendonLeftKneeAChoice: this.state.deepTendonLeftKneeAChoice ,deepTendonLeftKneeAText: this.state.deepTendonLeftKneeAText ,deepTendonLeftKneePendularChoice: this.state.deepTendonLeftKneePendularChoice ,deepTendonLeftKneePendularText: this.state.deepTendonLeftKneePendularText ,deepTendonRightKneeChoice: this.state.deepTendonRightKneeChoice ,deepTendonRightKneeText: this.state.deepTendonRightKneeText ,deepTendonRightKneeHyperChoice: this.state.deepTendonRightKneeHyperChoice ,deepTendonRightKneeHyperText: this.state.deepTendonRightKneeHyperText ,deepTendonRightKneeHypoChoice: this.state.deepTendonRightKneeHypoChoice ,deepTendonRightKneeHypoText: this.state.deepTendonRightKneeHypoText ,deepTendonRightKneeAChoice: this.state.deepTendonRightKneeAChoice ,deepTendonRightKneeAText: this.state.deepTendonRightKneeAText ,deepTendonRightKneePendularChoice: this.state.deepTendonRightKneePendularChoice ,deepTendonRightKneePendularText: this.state.deepTendonRightKneePendularText ,deepTendonLeftAnkleChoice: this.state.deepTendonLeftAnkleChoice ,deepTendonLeftAnkleText: this.state.deepTendonLeftAnkleText ,
                deepTendonLeftAnkleHyperChoice: this.state.deepTendonLeftAnkleHyperChoice ,deepTendonLeftAnkleHyperText: this.state.deepTendonLeftAnkleHyperText ,deepTendonLeftAnkleHypoChoice: this.state.deepTendonLeftAnkleHypoChoice ,deepTendonLeftAnkleHypoText: this.state.deepTendonLeftAnkleHypoText ,deepTendonLeftAnkleAChoice: this.state.deepTendonLeftAnkleAChoice ,deepTendonLeftAnkleAText: this.state.deepTendonLeftAnkleAText ,deepTendonRightAnkleChoice: this.state.deepTendonRightAnkleChoice ,deepTendonRightAnkleText: this.state.deepTendonRightAnkleText ,deepTendonRightAnkleHyperChoice: this.state.deepTendonRightAnkleHyperChoice ,deepTendonRightAnkleHyperText: this.state.deepTendonRightAnkleHyperText ,deepTendonRightAnkleHypoChoice: this.state.deepTendonRightAnkleHypoChoice ,deepTendonRightAnkleHypoText: this.state.deepTendonRightAnkleHypoText ,deepTendonRightAnkleAChoice: this.state.deepTendonRightAnkleAChoice ,deepTendonRightAnkleAText: this.state.deepTendonRightAnkleAText ,hoffmansChoice: this.state.hoffmansChoice ,hoffmansText: this.state.hoffmansText ,hoffmansLeftChoice: this.state.hoffmansLeftChoice ,hoffmansLeftText: this.state.hoffmansLeftText ,
                hoffmansRightChoice: this.state.hoffmansRightChoice ,hoffmansRightText: this.state.hoffmansRightText ,ankleClonusChoice: this.state.ankleClonusChoice ,ankleClonusText: this.state.ankleClonusText ,ankleClonusLeftChoice: this.state.ankleClonusLeftChoice ,ankleClonusLeftText: this.state.ankleClonusLeftText ,ankleClonusRightChoice: this.state.ankleClonusRightChoice ,ankleClonusRightText: this.state.ankleClonusRightText ,babinskiChoice: this.state.babinskiChoice ,babinskiText: this.state.babinskiText ,babinskiLeftChoice: this.state.babinskiLeftChoice ,babinskiLeftText: this.state.babinskiLeftText ,babinskiLeftUpGoingChoice: this.state.babinskiLeftUpGoingChoice ,babinskiLeftUpGoingText: this.state.babinskiLeftUpGoingText ,babinskiLeftDownGoingChoice: this.state.babinskiLeftDownGoingChoice ,babinskiLeftDownGoingText: this.state.babinskiLeftDownGoingText ,babinskiLeftEquivocalChoice: this.state.babinskiLeftEquivocalChoice ,babinskiLeftEquivocalText: this.state.babinskiLeftEquivocalText ,babinskiRightChoice: this.state.babinskiRightChoice ,babinskiRightText: this.state.babinskiRightText ,babinskiRightUpGoingChoice: this.state.babinskiRightUpGoingChoice ,babinskiRightUpGoingText: this.state.babinskiRightUpGoingText ,
                babinskiRightDownGoingChoice: this.state.babinskiRightDownGoingChoice ,babinskiRightDownGoingText: this.state.babinskiRightDownGoingText ,babinskiRightEquivocalChoice: this.state.babinskiRightEquivocalChoice ,babinskiRightEquivocalText: this.state.babinskiRightEquivocalText ,bulbocavernosusChoice: this.state.bulbocavernosusChoice ,bulbocavernosusText: this.state.bulbocavernosusText ,bulbocavernosusPresentChoice: this.state.bulbocavernosusPresentChoice ,bulbocavernosusPresentText: this.state.bulbocavernosusPresentText ,bulbocavernosusAbsentChoice: this.state.bulbocavernosusAbsentChoice ,bulbocavernosusAbsentText: this.state.bulbocavernosusAbsentText ,beevorsChoice: this.state.beevorsChoice ,beevorsText: this.state.beevorsText ,beevorsPresentChoice: this.state.beevorsPresentChoice ,beevorsPresentText: this.state.beevorsPresentText ,beevorsAbsentChoice: this.state.beevorsAbsentChoice ,beevorsAbsentText: this.state.beevorsAbsentText ,rombergTestChoice: this.state.rombergTestChoice ,rombergTestText: this.state.rombergTestText ,rombergTestAbsentChoice: this.state.rombergTestAbsentChoice ,rombergTestAbsentText: this.state.rombergTestAbsentText ,rombergTestPresentChoice: this.state.rombergTestPresentChoice ,
                rombergTestPresentText: this.state.rombergTestPresentText ,rombergTestPresentLeftChoice: this.state.rombergTestPresentLeftChoice ,rombergTestPresentLeftText: this.state.rombergTestPresentLeftText ,rombergTestPresentRightChoice: this.state.rombergTestPresentRightChoice ,rombergTestPresentRightText: this.state.rombergTestPresentRightText ,motorLevelChoice: this.state.motorLevelChoice ,motorLevelText: this.state.motorLevelText ,motorLevelLeftChoice: this.state.motorLevelLeftChoice ,motorLevelLeftText: this.state.motorLevelLeftText ,motorLevelLeftC5Choice: this.state.motorLevelLeftC5Choice ,motorLevelLeftC5Text: this.state.motorLevelLeftC5Text ,motorLevelLeftC6Choice: this.state.motorLevelLeftC6Choice ,motorLevelLeftC6Text: this.state.motorLevelLeftC6Text ,motorLevelLeftC7Choice: this.state.motorLevelLeftC7Choice ,motorLevelLeftC7Text: this.state.motorLevelLeftC7Text ,motorLevelLeftC8Choice: this.state.motorLevelLeftC8Choice ,motorLevelLeftC8Text: this.state.motorLevelLeftC8Text ,motorLevelLeftT1Choice: this.state.motorLevelLeftT1Choice ,motorLevelLeftT1Text: this.state.motorLevelLeftT1Text ,motorLevelLeftL2Choice: this.state.motorLevelLeftL2Choice ,motorLevelLeftL2Text: this.state.motorLevelLeftL2Text ,
                motorLevelLeftL3Choice: this.state.motorLevelLeftL3Choice ,motorLevelLeftL3Text: this.state.motorLevelLeftL3Text ,motorLevelLeftL4Choice: this.state.motorLevelLeftL4Choice ,motorLevelLeftL4Text: this.state.motorLevelLeftL4Text ,motorLevelLeftL5Choice: this.state.motorLevelLeftL5Choice ,motorLevelLeftL5Text: this.state.motorLevelLeftL5Text ,motorLevelLeftS1Choice: this.state.motorLevelLeftS1Choice ,motorLevelLeftS1Text: this.state.motorLevelLeftS1Text ,motorLevelRightChoice: this.state.motorLevelRightChoice ,motorLevelRightText: this.state.motorLevelRightText ,motorLevelRightC5Choice: this.state.motorLevelRightC5Choice ,motorLevelRightC5Text: this.state.motorLevelRightC5Text ,motorLevelRightC6Choice: this.state.motorLevelRightC6Choice ,motorLevelRightC6Text: this.state.motorLevelRightC6Text ,motorLevelRightC7Choice: this.state.motorLevelRightC7Choice ,motorLevelRightC7Text: this.state.motorLevelRightC7Text ,motorLevelRightC8Choice: this.state.motorLevelRightC8Choice ,motorLevelRightC8Text: this.state.motorLevelRightC8Text ,motorLevelRightT1Choice: this.state.motorLevelRightT1Choice ,motorLevelRightT1Text: this.state.motorLevelRightT1Text ,motorLevelRightL2Choice: this.state.motorLevelRightL2Choice ,
                motorLevelRightL2Text: this.state.motorLevelRightL2Text ,motorLevelRightL3Choice: this.state.motorLevelRightL3Choice ,motorLevelRightL3Text: this.state.motorLevelRightL3Text ,motorLevelRightL4Choice: this.state.motorLevelRightL4Choice ,motorLevelRightL4Text: this.state.motorLevelRightL4Text ,motorLevelRightL5Choice: this.state.motorLevelRightL5Choice ,motorLevelRightL5Text: this.state.motorLevelRightL5Text ,motorLevelRightS1Choice: this.state.motorLevelRightS1Choice ,motorLevelRightS1Text: this.state.motorLevelRightS1Text ,sensoryLevelChoice: this.state.sensoryLevelChoice ,sensoryLevelText: this.state.sensoryLevelText ,sensoryLevelLeftChoice: this.state.sensoryLevelLeftChoice ,sensoryLevelLeftText: this.state.sensoryLevelLeftText ,sensoryLevelLeftC2Choice: this.state.sensoryLevelLeftC2Choice ,sensoryLevelLeftC2Text: this.state.sensoryLevelLeftC2Text ,sensoryLevelLeftC3Choice: this.state.sensoryLevelLeftC3Choice ,sensoryLevelLeftC3Text: this.state.sensoryLevelLeftC3Text ,sensoryLevelLeftC4Choice: this.state.sensoryLevelLeftC4Choice ,sensoryLevelLeftC4Text: this.state.sensoryLevelLeftC4Text ,sensoryLevelLeftC5Choice: this.state.sensoryLevelLeftC5Choice ,sensoryLevelLeftC5Text: this.state.sensoryLevelLeftC5Text ,
                sensoryLevelLeftC6Choice: this.state.sensoryLevelLeftC6Choice ,sensoryLevelLeftC6Text: this.state.sensoryLevelLeftC6Text ,sensoryLevelLeftC7Choice: this.state.sensoryLevelLeftC7Choice ,sensoryLevelLeftC7Text: this.state.sensoryLevelLeftC7Text ,sensoryLevelLeftC8Choice: this.state.sensoryLevelLeftC8Choice ,sensoryLevelLeftC8Text: this.state.sensoryLevelLeftC8Text ,sensoryLevelLeftT1Choice: this.state.sensoryLevelLeftT1Choice ,sensoryLevelLeftT1Text: this.state.sensoryLevelLeftT1Text ,sensoryLevelLeftT2Choice: this.state.sensoryLevelLeftT2Choice ,sensoryLevelLeftT2Text: this.state.sensoryLevelLeftT2Text ,sensoryLevelLeftT3Choice: this.state.sensoryLevelLeftT3Choice ,sensoryLevelLeftT3Text: this.state.sensoryLevelLeftT3Text ,sensoryLevelLeftT4Choice: this.state.sensoryLevelLeftT4Choice ,sensoryLevelLeftT4Text: this.state.sensoryLevelLeftT4Text ,sensoryLevelLeftT5Choice: this.state.sensoryLevelLeftT5Choice ,sensoryLevelLeftT5Text: this.state.sensoryLevelLeftT5Text ,sensoryLevelLeftT6Choice: this.state.sensoryLevelLeftT6Choice ,sensoryLevelLeftT6Text: this.state.sensoryLevelLeftT6Text ,sensoryLevelLeftT7Choice: this.state.sensoryLevelLeftT7Choice ,sensoryLevelLeftT7Text: this.state.sensoryLevelLeftT7Text ,
                sensoryLevelLeftT8Choice: this.state.sensoryLevelLeftT8Choice ,sensoryLevelLeftT8Text: this.state.sensoryLevelLeftT8Text ,sensoryLevelLeftT9Choice: this.state.sensoryLevelLeftT9Choice ,sensoryLevelLeftT9Text: this.state.sensoryLevelLeftT9Text ,sensoryLevelLeftT10Choice: this.state.sensoryLevelLeftT10Choice ,sensoryLevelLeftT10Text: this.state.sensoryLevelLeftT10Text ,sensoryLevelLeftT11Choice: this.state.sensoryLevelLeftT11Choice ,sensoryLevelLeftT11Text: this.state.sensoryLevelLeftT11Text ,sensoryLevelLeftT12Choice: this.state.sensoryLevelLeftT12Choice ,sensoryLevelLeftT12Text: this.state.sensoryLevelLeftT12Text ,sensoryLevelLeftL1Choice: this.state.sensoryLevelLeftL1Choice ,sensoryLevelLeftL1Text: this.state.sensoryLevelLeftL1Text ,sensoryLevelLeftL2Choice: this.state.sensoryLevelLeftL2Choice ,sensoryLevelLeftL2Text: this.state.sensoryLevelLeftL2Text ,sensoryLevelLeftL3Choice: this.state.sensoryLevelLeftL3Choice ,sensoryLevelLeftL3Text: this.state.sensoryLevelLeftL3Text ,sensoryLevelLeftL4Choice: this.state.sensoryLevelLeftL4Choice ,sensoryLevelLeftL4Text: this.state.sensoryLevelLeftL4Text ,sensoryLevelLeftL5Choice: this.state.sensoryLevelLeftL5Choice ,sensoryLevelLeftL5Text: this.state.sensoryLevelLeftL5Text ,
                sensoryLevelLeftS1Choice: this.state.sensoryLevelLeftS1Choice ,sensoryLevelLeftS1Text: this.state.sensoryLevelLeftS1Text ,sensoryLevelLeftS3Choice: this.state.sensoryLevelLeftS3Choice ,sensoryLevelLeftS3Text: this.state.sensoryLevelLeftS3Text ,sensoryLevelLeftS45Choice: this.state.sensoryLevelLeftS45Choice ,sensoryLevelLeftS45Text: this.state.sensoryLevelLeftS45Text ,sensoryLevelRightChoice: this.state.sensoryLevelRightChoice ,sensoryLevelRightText: this.state.sensoryLevelRightText ,sensoryLevelRightC2Choice: this.state.sensoryLevelRightC2Choice ,sensoryLevelRightC2Text: this.state.sensoryLevelRightC2Text ,sensoryLevelRightC3Choice: this.state.sensoryLevelRightC3Choice ,sensoryLevelRightC3Text: this.state.sensoryLevelRightC3Text ,sensoryLevelRightC4Choice: this.state.sensoryLevelRightC4Choice ,sensoryLevelRightC4Text: this.state.sensoryLevelRightC4Text ,sensoryLevelRightC5Choice: this.state.sensoryLevelRightC5Choice ,sensoryLevelRightC5Text: this.state.sensoryLevelRightC5Text ,sensoryLevelRightC6Choice: this.state.sensoryLevelRightC6Choice ,sensoryLevelRightC6Text: this.state.sensoryLevelRightC6Text ,sensoryLevelRightC7Choice: this.state.sensoryLevelRightC7Choice ,
                sensoryLevelRightC7Text: this.state.sensoryLevelRightC7Text ,sensoryLevelRightC8Choice: this.state.sensoryLevelRightC8Choice ,sensoryLevelRightC8Text: this.state.sensoryLevelRightC8Text ,sensoryLevelRightT1Choice: this.state.sensoryLevelRightT1Choice ,sensoryLevelRightT1Text: this.state.sensoryLevelRightT1Text ,sensoryLevelRightT2Choice: this.state.sensoryLevelRightT2Choice ,sensoryLevelRightT2Text: this.state.sensoryLevelRightT2Text ,sensoryLevelRightT3Choice: this.state.sensoryLevelRightT3Choice ,sensoryLevelRightT3Text: this.state.sensoryLevelRightT3Text ,sensoryLevelRightT4Choice: this.state.sensoryLevelRightT4Choice ,sensoryLevelRightT4Text: this.state.sensoryLevelRightT4Text ,sensoryLevelRightT5Choice: this.state.sensoryLevelRightT5Choice ,sensoryLevelRightT5Text: this.state.sensoryLevelRightT5Text ,sensoryLevelRightT6Choice: this.state.sensoryLevelRightT6Choice ,sensoryLevelRightT6Text: this.state.sensoryLevelRightT6Text ,sensoryLevelRightT7Choice: this.state.sensoryLevelRightT7Choice ,sensoryLevelRightT7Text: this.state.sensoryLevelRightT7Text ,sensoryLevelRightT8Choice: this.state.sensoryLevelRightT8Choice ,sensoryLevelRightT8Text: this.state.sensoryLevelRightT8Text ,
                sensoryLevelRightT9Choice: this.state.sensoryLevelRightT9Choice ,sensoryLevelRightT9Text: this.state.sensoryLevelRightT9Text ,sensoryLevelRightT10Choice: this.state.sensoryLevelRightT10Choice ,sensoryLevelRightT10Text: this.state.sensoryLevelRightT10Text ,sensoryLevelRightT11Choice: this.state.sensoryLevelRightT11Choice ,sensoryLevelRightT11Text: this.state.sensoryLevelRightT11Text ,sensoryLevelRightT12Choice: this.state.sensoryLevelRightT12Choice ,sensoryLevelRightT12Text: this.state.sensoryLevelRightT12Text ,sensoryLevelRightL1Choice: this.state.sensoryLevelRightL1Choice ,sensoryLevelRightL1Text: this.state.sensoryLevelRightL1Text ,sensoryLevelRightL2Choice: this.state.sensoryLevelRightL2Choice ,sensoryLevelRightL2Text: this.state.sensoryLevelRightL2Text ,sensoryLevelRightL3Choice: this.state.sensoryLevelRightL3Choice ,sensoryLevelRightL3Text: this.state.sensoryLevelRightL3Text ,sensoryLevelRightL4Choice: this.state.sensoryLevelRightL4Choice ,sensoryLevelRightL4Text: this.state.sensoryLevelRightL4Text ,sensoryLevelRightL5Choice: this.state.sensoryLevelRightL5Choice ,sensoryLevelRightL5Text: this.state.sensoryLevelRightL5Text ,sensoryLevelRightS1Choice: this.state.sensoryLevelRightS1Choice ,
                sensoryLevelRightS1Text: this.state.sensoryLevelRightS1Text ,sensoryLevelRightS3Choice: this.state.sensoryLevelRightS3Choice ,sensoryLevelRightS3Text: this.state.sensoryLevelRightS3Text ,sensoryLevelRightS45Choice: this.state.sensoryLevelRightS45Choice ,sensoryLevelRightS45Text: this.state.sensoryLevelRightS45Text ,neurologicalLevelChoice: this.state.neurologicalLevelChoice ,neurologicalLevelText: this.state.neurologicalLevelText ,neurologicalLevelC2Choice: this.state.neurologicalLevelC2Choice ,neurologicalLevelC2Text: this.state.neurologicalLevelC2Text ,neurologicalLevelC3Choice: this.state.neurologicalLevelC3Choice ,neurologicalLevelC3Text: this.state.neurologicalLevelC3Text ,neurologicalLevelC4Choice: this.state.neurologicalLevelC4Choice ,neurologicalLevelC4Text: this.state.neurologicalLevelC4Text ,neurologicalLevelC5Choice: this.state.neurologicalLevelC5Choice ,neurologicalLevelC5Text: this.state.neurologicalLevelC5Text ,neurologicalLevelC6Choice: this.state.neurologicalLevelC6Choice ,neurologicalLevelC6Text: this.state.neurologicalLevelC6Text ,neurologicalLevelC7Choice: this.state.neurologicalLevelC7Choice ,neurologicalLevelC7Text: this.state.neurologicalLevelC7Text ,
                neurologicalLevelC8Choice: this.state.neurologicalLevelC8Choice ,neurologicalLevelC8Text: this.state.neurologicalLevelC8Text ,neurologicalLevelT1Choice: this.state.neurologicalLevelT1Choice ,neurologicalLevelT1Text: this.state.neurologicalLevelT1Text ,neurologicalLevelT2Choice: this.state.neurologicalLevelT2Choice ,neurologicalLevelT2Text: this.state.neurologicalLevelT2Text ,neurologicalLevelT3Text: this.state.neurologicalLevelT3Text ,neurologicalLevelT4Choice: this.state.neurologicalLevelT4Choice ,neurologicalLevelT4Text: this.state.neurologicalLevelT4Text ,neurologicalLevelT5Choice: this.state.neurologicalLevelT5Choice ,neurologicalLevelT5Text: this.state.neurologicalLevelT5Text ,neurologicalLevelT6Choice: this.state.neurologicalLevelT6Choice ,neurologicalLevelT6Text: this.state.neurologicalLevelT6Text ,neurologicalLevelT7Choice: this.state.neurologicalLevelT7Choice ,neurologicalLevelT7Text: this.state.neurologicalLevelT7Text ,neurologicalLevelT8Choice: this.state.neurologicalLevelT8Choice ,neurologicalLevelT8Text: this.state.neurologicalLevelT8Text ,neurologicalLevelT9Choice: this.state.neurologicalLevelT9Choice ,neurologicalLevelT9Text: this.state.neurologicalLevelT9Text ,
                neurologicalLevelT10Choice: this.state.neurologicalLevelT10Choice ,neurologicalLevelT10Text: this.state.neurologicalLevelT10Text ,neurologicalLevelT11Choice: this.state.neurologicalLevelT11Choice ,neurologicalLevelT11Text: this.state.neurologicalLevelT11Text ,neurologicalLevelT12Choice: this.state.neurologicalLevelT12Choice ,neurologicalLevelT12Text: this.state.neurologicalLevelT12Text ,neurologicalLevelL1Choice: this.state.neurologicalLevelL1Choice ,neurologicalLevelL1Text: this.state.neurologicalLevelL1Text ,neurologicalLevelL2Choice: this.state.neurologicalLevelL2Choice ,neurologicalLevelL2Text: this.state.neurologicalLevelL2Text ,neurologicalLevelL3Choice: this.state.neurologicalLevelL3Choice ,neurologicalLevelL3Text: this.state.neurologicalLevelL3Text ,neurologicalLevelL4Choice: this.state.neurologicalLevelL4Choice ,neurologicalLevelL4Text: this.state.neurologicalLevelL4Text ,neurologicalLevelL5Choice: this.state.neurologicalLevelL5Choice ,neurologicalLevelL5Text: this.state.neurologicalLevelL5Text ,neurologicalLevelS1Choice: this.state.neurologicalLevelS1Choice ,neurologicalLevelS1Text: this.state.neurologicalLevelS1Text ,neurologicalLevelS3Choice: this.state.neurologicalLevelS3Choice ,
                neurologicalLevelS3Text: this.state.neurologicalLevelS3Text ,neurologicalLevelS45Choice: this.state.neurologicalLevelS45Choice ,neurologicalLevelS45Text: this.state.neurologicalLevelS45Text, findings: this.state.findingsEx, other: this.state.examinationOther }
                ,feedback: { doctor: this.state.feedbackDoctor, history: this.state.feedbackHistory, examination: this.state.feedbackExamination, reviewConsult: this.state.feedbackReviewConsult, investigations: this.state.feedbackInvestigations, diagnosis: this.state.feedbackDiagnosis, prescriptions: this.state.feedbackPrescriptions, dateLists: this.state.dateLists}
            , patientDiagnosis: this.state.patientDiagnosis, planData: this.state.planData, chronicData: this.state.chronicData, diagnosisText: this.state.diagnosisText, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, rehabilitations: this.state.rehabilitations, referrals: this.state.referrals, treatments: this.state.treatments, investigations: this.state.investigations, findings: this.state.findings
        }
        localStorage.setItem('nuero_data', JSON.stringify(data))
        axios.post(localStorage.getItem('host') + '/patientmanagement/neurosurgerysave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.getList()
                document.getElementById('topDiv').scrollIntoView()
                this.openNeurosurgeryAction(this.state.visitId,  res.data )
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    getFromLocalStorage()
     {
      var data = JSON.parse(localStorage.getItem('nuero_data'));  
      this.setState({
        patientDiagnosis: data.patientDiagnosis, diagnosisText: data.diagnosisText, reviews: data.reviews, medicalCertificates: data.medicalCertificates, rehabilitations: data.rehabilitations, referrals: data.referrals, treatments: data.treatments, investigations: data.investigations, findings: data.findings, 
        feedbackHistory: data.feedback.history, feedbackExamination: data.feedback.examination, feedbackReviewConsult: data.feedback.reviewConsult, feedbackInvestigations: data.feedback.investigations, feedbackDiagnosis: data.feedback.diagnosis, feedbackPrescriptions: data.feedback.prescriptions, feedbackDoctor: data.feedback.doctor, dateLists: data.feedback.dateLists,
/* HISTORY  */  presentingComplaintList: data.history.presentingComplaintList, historyOfPresentingComplaints: data.history.historyOfPresentingComplaints, pastMedicalHistory: data.history.pastMedicalHistory, currentMedication: data.history.currentMedication, allergyHistory: data.history.allergyHistory,
        coughChoice: data.history.coughChoice, coughText: data.history.coughText, coryzaChoice: data.history.coryzaChoice, coryzaText: data.history.coryzaText, sobChoice: data.history.sobChoice, sobText: data.history.sobText, sneazingChoice: data.history.sneazingChoice, sneazingText: data.history.sneazingText, phelgmChoice: data.history.phelgmChoice, phelgmText: data.history.phelgmText, sputumChoice: data.history.sputumChoice, sputumText: data.history.sputumText, haemoptysisChoice: data.history.haemoptysisChoice, haemoptysisText: data.history.haemoptysisText, wheezingChoice: data.history.wheezingChoice, wheezingText: data.history.wheezingText, nasalChoice: data.history.nasalChoice, nasalText: data.history.nasalText, soreChoice: data.history.soreChoice, soreText: data.history.soreText, chestPainChoice: data.history.chestPainChoice, chestPainText: data.history.chestPainText, respitoryNotes: data.history.respitoryNotes,
        nauseaChoice: data.history.nauseaChoice, nauseaText: data.history.nauseaText, vomitingChoice: data.history.vomitingChoice, vomitingText: data.history.vomitingText, diarrhoeaChoice: data.history.diarrhoeaChoice, diarrhoeaText: data.history.diarrhoeaText, abdominialChoice: data.history.abdominialChoice, abdominialText: data.history.abdominialText, flatulanceChoice: data.history.flatulanceChoice, flatulanceText: data.history.flatulanceText, flatulentChoice: data.history.flatulentChoice, flatulentText: data.history.flatulentText, waterChoice: data.history.waterChoice, waterText: data.history.waterText, constipationChoice: data.history.constipationChoice, constipationText: data.history.constipationText, prChoice: data.history.prChoice, prText: data.history.prText, wtChoice: data.history.wtChoice, wtText: data.history.wtText, melenaChoice: data.history.melenaChoice, melenaText: data.history.melenaText, jaundiceChoice: data.history.jaundiceChoice, jaundiceText: data.history.jaundiceText, loaChoice: data.history.loaChoice, loaText: data.history.loaText, lowChoice: data.history.lowChoice, lowText: data.history.lowText, gitNotes: data.history.gitNotes,
        dysuriaChoice: data.history.dysuriaChoice, dysuriaText: data.history.dysuriaText, frequencyChoice: data.history.frequencyChoice, frequencyText: data.history.frequencyText, haematuriaChoice: data.history.haematuriaChoice, haematuriaText: data.history.haematuriaText, weakChoice: data.history.weakChoice, weakText: data.history.weakText, strainingChoice: data.history.strainingChoice, strainingText: data.history.strainingText, urineChoice: data.history.urineChoice, urineText: data.history.urineText, urethalChoice: data.history.urethalChoice, urethalText: data.history.urethalText, pvChoice: data.history.pvChoice, pvText: data.history.pvText, nocturiaChoice: data.history.nocturiaChoice, nocturiaText: data.history.nocturiaText, genitalChoice: data.history.genitalChoice, genitalText: data.history.genitalText, lossChoice: data.history.lossChoice, lossText: data.history.lossText, erectileChoice: data.history.erectileChoice, erectileText: data.history.erectileText, gusNotes: data.history.gusNotes,
        dysaruniaChoice: data.history.dysaruniaChoice, dysaruniaText: data.history.dysaruniaText, dysmemorrhoeaChoice: data.history.dysmemorrhoeaChoice, dysmemorrhoeaText: data.history.dysmemorrhoeaText, imbChoice: data.history.imbChoice, imbText: data.history.imbText, contactChoice: data.history.contactChoice, contactText: data.history.contactText, menorrhagiaChoice: data.history.menorrhagiaChoice, menorrhagiaText: data.history.menorrhagiaText, lapChoice: data.history.lapChoice, lapText: data.history.lapText, backacheChoice: data.history.backacheChoice, backackeText: data.history.backackeText, subfertilityChoice: data.history.subfertilityChoice, subfertilityText: data.history.subfertilityText, hotChoice: data.history.hotChoice, hotText: data.history.hotText, lnmpChoice: data.history.lnmpChoice, lnmpText: data.history.lnmpText, gynaeNotes: data.history.gynaeNotes,
        orthopneaChoice: data.history.orthopneaChoice, orthopneaText: data.history.orthopneaText, pndChoice: data.history.pndChoice, pndText: data.history.pndText, easyChoice: data.history.easyChoice, easyText: data.history.easyText, palpitationsChoice: data.history.palpitationsChoice, palpitationsText: data.history.palpitationsText, synacopeChoice: data.history.synacopeChoice, synacopeText: data.history.synacopeText, legSwellingChoice: data.history.legSwellingChoice, legSwellingText: data.history.legSwellingText, chestPaincvsChoice: data.history.chestPaincvsChoice, chestPaincvsText: data.history.chestPaincvsText, calfSwellingChoice: data.history.calfSwellingChoice, calfSwellingText: data.history.calfSwellingText, pinkFrothChoice: data.history.pinkFrothChoice, pinkFrothText: data.history.pinkFrothText, gelatinousChoice: data.history.gelatinousChoice, gelatinousText: data.history.gelatinousText, cvsNotes: data.history.cvsNotes,
        headacheChoice: data.history.headacheChoice, headacheText: data.history.headacheText, paraesthesiaChoice: data.history.paraesthesiaChoice, paraesthesiaText: data.history.paraesthesiaText, dizzinessChoice: data.history.dizzinessChoice, dizzinessText: data.history.dizzinessText, backackeChoice: data.history.backackeChoice, backacheText: data.history.backacheText, photophibiaChoice: data.history.photophibiaChoice, photophibiaText: data.history.photophibiaText, neckstiffnessChoice: data.history.neckstiffnessChoice, neckstiffnessText: data.history.neckstiffnessText, fittingChoice: data.history.fittingChoice, fittingText: data.history.fittingText, paresisChoice: data.history.paresisChoice, paresisText: data.history.paresisText, paralysisChoice: data.history.paralysisChoice, paralysisText: data.history.paralysisText, insomniaChoice: data.history.insomniaChoice, insomniaText: data.history.insomniaText, hypersomniaChoice: data.history.hypersomniaChoice, hypersomniaText: data.history.hypersomniaText, abnChoice: data.history.abnChoice, abnText: data.history.abnText, cnsNotes: data.history.cnsNotes,
        feverChoice: data.history.feverChoice, feverText: data.history.feverText, sweatingChoice: data.history.sweatingChoice, sweatingText: data.history.sweatingText, chillsChoice: data.history.chillsChoice, chillsText: data.history.chillsText, rigorsChoice: data.history.rigorsChoice, rigorsText: data.history.rigorsText, fatigueChoice: data.history.fatigueChoice, fatigueText: data.history.fatigueText, myalgiaChoice: data.history.myalgiaChoice, myalgiaText: data.history.myalgiaText, constitutionalNotes: data.history.constitutionalNotes,
        bleedingChoice: data.history.bleedingChoice, bleedingText: data.history.bleedingText, bruisingChoice: data.history.bruisingChoice, bruisingText: data.history.bruisingText, swellingChoice: data.history.swellingChoice, swellingText: data.history.swellingText, deformityChoice: data.history.deformityChoice, deformityText: data.history.deformityText, mssNotes: data.history.mssNotes,
        rashChoice: data.history.rashChoice, rashText: data.history.rashText, itchynessChoice: data.history.itchynessChoice, itchynessText: data.history.itchynessText, blistersChoice: data.history.blistersChoice, blistersText: data.history.blistersText, lumpsChoice: data.history.lumpsChoice, lumpsText: data.history.lumpsText, ulcersChoice: data.history.ulcersChoice, ulcersText: data.history.ulcersText, swellingdemaChoice: data.history.swellingdemaChoice, swellingdemaText: data.history.swellingdemaText, dematologyNotes: data.history.dematologyNotes,
         /* Extra History */ 
        painText: data.history.painText, durationText: data.history.durationText, timingText: data.history.timingText, aggravatedByText: data.history.aggravatedByText, relievedByText: data.history.relievedByText, headacheLeftChoice: data.history.headacheLeftChoice, headacheLeftText: data.history.headacheLeftText, headacheRightChoice: data.history.headacheRightChoice, headacheRightText: data.history.headacheRightText, holocranialChoice: data.history.holocranialChoice, holocranialText: data.history.holocranialText, frontalChoice: data.history.frontalChoice, frontalText: data.history.frontalText, temporalChoice: data.history.temporalChoice, temporalText: data.history.temporalText, occipitalChoice: data.history.occipitalChoice, occipitalText: data.history.occipitalText, periorbitalChoice: data.history.periorbitalChoice, periorbitalText: data.history.periorbitalText, confusionChoice: data.history.confusionChoice, confusionText: data.history.confusionText, 
        changeinCharacterChoice: data.history.changeinCharacterChoice, changeinCharacterText: data.history.changeinCharacterText, weaknessChoice: data.history.weaknessChoice, weaknessText: data.history.weaknessText, hemiparesisLeftChoice: data.history.hemiparesisLeftChoice, hemiparesisLeftText: data.history.hemiparesisLeftText, hemiparesisRightChoice: data.history.hemiparesisRightChoice, hemiparesisRightText: data.history.hemiparesisRightText, weaknessHemiplegiaChoice: data.history.weaknessHemiplegiaChoice, weaknessHemiplegiaText: data.history.weaknessHemiplegiaText, hemiplegiaLeftChoice: data.history.hemiplegiaLeftChoice, hemiplegiaLeftText: data.history.hemiplegiaLeftText, hemiplegiaRightChoice: data.history.hemiplegiaRightChoice, hemiplegiaRightText: data.history.hemiplegiaRightText, paraparesisChoice: data.history.paraparesisChoice, paraparesisText: data.history.paraparesisText, paraplegiaChoice: data.history.paraplegiaChoice, paraplegiaText: data.history.paraplegiaText, quadriparesisChoice: data.history.quadriparesisChoice, quadriparesisText: data.history.quadriparesisText, 
        quadriplegiaChoice: data.history.quadriplegiaChoice, quadriplegiaText: data.history.quadriplegiaText, monoparesisChoice: data.history.monoparesisChoice, monoparesisText: data.history.monoparesisText, monoparesisLeftChoice: data.history.monoparesisLeftChoice, monoparesisLeftText: data.history.monoparesisLeftText, monoparesisRightChoice: data.history.monoparesisRightChoice, monoparesisRightText: data.history.monoparesisRightText, monoparesisUpperLimbChoice: data.history.monoparesisUpperLimbChoice, monoparesisUpperLimbText: data.history.monoparesisUpperLimbText, monoparesisLowerLimbChoice: data.history.monoparesisLowerLimbChoice, monoparesisLowerLimbText: data.history.monoparesisLowerLimbText, seizuresChoice: data.history.seizuresChoice, seizuresText: data.history.seizuresText, seizuresAuraChoice: data.history.seizuresAuraChoice, seizuresAuraText: data.history.seizuresAuraText, auraVisualChoice: data.history.auraVisualChoice, auraVisualText: data.history.auraVisualText, auraOlfactoryChoice: data.history.auraOlfactoryChoice, auraOlfactoryText: data.history.auraOlfactoryText, auraAutonomicChoice: data.history.auraAutonomicChoice, 
        auraAutonomicText: data.history.auraAutonomicText, thoracicChoice: data.history.thoracicChoice, thoracicText: data.history.thoracicText, lumbarChoice: data.history.lumbarChoice, lumbarText: data.history.lumbarText, lowerBackacheChoice: data.history.lowerBackacheChoice, lowerBackacheText: data.history.lowerBackacheText, mechanicalChoice: data.history.mechanicalChoice, mechanicalText: data.history.mechanicalText, sciaticaChoice: data.history.sciaticaChoice, sciaticaText: data.history.sciaticaText, sciaticaLeftChoice: data.history.sciaticaLeftChoice, sciaticaLeftText: data.history.sciaticaLeftText, sciaticaRightChoice: data.history.sciaticaRightChoice, sciaticaRightText: data.history.sciaticaRightText, cervicalPainChoice: data.history.cervicalPainChoice, cervicalPainText: data.history.cervicalPainText, interScapularPainChoice: data.history.interScapularPainChoice, interScapularPainText: data.history.interScapularPainText, paraesthesiaUpperLimbChoice: data.history.paraesthesiaUpperLimbChoice, 
        paraesthesiaUpperLimbText: data.history.paraesthesiaUpperLimbText, paraesthesiaLowerLimbChoice: data.history.paraesthesiaLowerLimbChoice, paraesthesiaLowerLimbText: data.history.paraesthesiaLowerLimbText, lossOfBalanceChoice: data.history.lossOfBalanceChoice, lossOfBalanceText: data.history.lossOfBalanceText, neurogenicClaudicationChoice: data.history.neurogenicClaudicationChoice, neurogenicClaudicationText: data.history.neurogenicClaudicationText, radiculapathyChoice: data.history.radiculapathyChoice, radiculapathyText: data.history.radiculapathyText, radiculapathyLeftChoice: data.history.radiculapathyLeftChoice, radiculapathyLeftText: data.history.radiculapathyLeftText, radiculapathyLeftC2Choice: data.history.radiculapathyLeftC2Choice, radiculapathyLeftC2Text: data.history.radiculapathyLeftC2Text, radiculapathyLeftC3Choice: data.history.radiculapathyLeftC3Choice, radiculapathyLeftC3Text: data.history.radiculapathyLeftC3Text, radiculapathyLeftC4Choice: data.history.radiculapathyLeftC4Choice, radiculapathyLeftC4Text: data.history.radiculapathyLeftC4Text, radiculapathyLeftC5Choice: data.history.radiculapathyLeftC5Choice, 
        radiculapathyLeftC5Text: data.history.radiculapathyLeftC5Text, radiculapathyLeftC6Choice: data.history.radiculapathyLeftC6Choice, radiculapathyLeftC6Text: data.history.radiculapathyLeftC6Text, radiculapathyLeftC7Choice: data.history.radiculapathyLeftC7Choice, radiculapathyLeftC7Text: data.history.radiculapathyLeftC7Text, radiculapathyLeftC8Choice: data.history.radiculapathyLeftC8Choice, radiculapathyLeftC8Text: data.history.radiculapathyLeftC8Text, radiculapathyLeftT1Choice: data.history.radiculapathyLeftT1Choice, radiculapathyLeftT1Text: data.history.radiculapathyLeftT1Text, radiculapathyLeftT2Choice: data.history.radiculapathyLeftT2Choice, radiculapathyLeftT2Text: data.history.radiculapathyLeftT2Text, radiculapathyLeftT3Choice: data.history.radiculapathyLeftT3Choice, radiculapathyLeftT3Text: data.history.radiculapathyLeftT3Text, radiculapathyLeftT4Choice: data.history.radiculapathyLeftT4Choice, radiculapathyLeftT4Text: data.history.radiculapathyLeftT4Text, radiculapathyLeftT5Choice: data.history.radiculapathyLeftT5Choice, radiculapathyLeftT5Text: data.history.radiculapathyLeftT5Text, radiculapathyLeftT6Choice: data.history.radiculapathyLeftT6Choice, 
        radiculapathyLeftT6Text: data.history.radiculapathyLeftT6Text, radiculapathyLeftT7Choice: data.history.radiculapathyLeftT7Choice, radiculapathyLeftT7Text: data.history.radiculapathyLeftT7Text, radiculapathyLeftT8Choice: data.history.radiculapathyLeftT8Choice, radiculapathyLeftT8Text: data.history.radiculapathyLeftT8Text, radiculapathyLeftT9Choice: data.history.radiculapathyLeftT9Choice, radiculapathyLeftT9Text: data.history.radiculapathyLeftT9Text, radiculapathyLeftT10Choice: data.history.radiculapathyLeftT10Choice, radiculapathyLeftT10Text: data.history.radiculapathyLeftT10Text, radiculapathyLeftT11Choice: data.history.radiculapathyLeftT11Choice, radiculapathyLeftT11Text: data.history.radiculapathyLeftT11Text, radiculapathyLeftT12Choice: data.history.radiculapathyLeftT12Choice, radiculapathyLeftT12Text: data.history.radiculapathyLeftT12Text, radiculapathyLeftL1Choice: data.history.radiculapathyLeftL1Choice, radiculapathyLeftL1Text: data.history.radiculapathyLeftL1Text, radiculapathyLeftL2Choice: data.history.radiculapathyLeftL2Choice, radiculapathyLeftL2Text: data.history.radiculapathyLeftL2Text, radiculapathyLeftL3Choice: data.history.radiculapathyLeftL3Choice, 
        radiculapathyLeftL3Text: data.history.radiculapathyLeftL3Text, radiculapathyLeftL4Choice: data.history.radiculapathyLeftL4Choice, radiculapathyLeftL4Text: data.history.radiculapathyLeftL4Text, radiculapathyLeftL5Choice: data.history.radiculapathyLeftL5Choice, radiculapathyLeftL5Text: data.history.radiculapathyLeftL5Text, radiculapathyLeftS1Choice: data.history.radiculapathyLeftS1Choice, radiculapathyLeftS1Text: data.history.radiculapathyLeftS1Text, radiculapathyLeftS3Choice: data.history.radiculapathyLeftS3Choice, radiculapathyLeftS3Text: data.history.radiculapathyLeftS3Text, radiculapathyLeftS45Choice: data.history.radiculapathyLeftS45Choice, radiculapathyLeftS45Text: data.history.radiculapathyLeftS45Text, radiculapathyRightChoice: data.history.radiculapathyRightChoice, radiculapathyRightText: data.history.radiculapathyRightText, radiculapathyRightC2Choice: data.history.radiculapathyRightC2Choice, radiculapathyRightC2Text: data.history.radiculapathyRightC2Text, radiculapathyRightC3Choice: data.history.radiculapathyRightC3Choice, radiculapathyRightC3Text: data.history.radiculapathyRightC3Text, radiculapathyRightC4Choice: data.history.radiculapathyRightC4Choice, 
        radiculapathyRightC4Text: data.history.radiculapathyRightC4Text, radiculapathyRightC5Choice: data.history.radiculapathyRightC5Choice, radiculapathyRightC5Text: data.history.radiculapathyRightC5Text, radiculapathyRightC6Choice: data.history.radiculapathyRightC6Choice, radiculapathyRightC6Text: data.history.radiculapathyRightC6Text, radiculapathyRightC7Choice: data.history.radiculapathyRightC7Choice, radiculapathyRightC7Text: data.history.radiculapathyRightC7Text, radiculapathyRightC8Choice: data.history.radiculapathyRightC8Choice, radiculapathyRightC8Text: data.history.radiculapathyRightC8Text, radiculapathyRightT1Choice: data.history.radiculapathyRightT1Choice, radiculapathyRightT1Text: data.history.radiculapathyRightT1Text, radiculapathyRightT2Choice: data.history.radiculapathyRightT2Choice, radiculapathyRightT2Text: data.history.radiculapathyRightT2Text, radiculapathyRightT3Choice: data.history.radiculapathyRightT3Choice, radiculapathyRightT3Text: data.history.radiculapathyRightT3Text, radiculapathyRightT4Choice: data.history.radiculapathyRightT4Choice, radiculapathyRightT4Text: data.history.radiculapathyRightT4Text, 
        radiculapathyRightT5Choice: data.history.radiculapathyRightT5Choice, radiculapathyRightT5Text: data.history.radiculapathyRightT5Text, radiculapathyRightT6Choice: data.history.radiculapathyRightT6Choice, radiculapathyRightT6Text: data.history.radiculapathyRightT6Text, radiculapathyRightT7Choice: data.history.radiculapathyRightT7Choice, radiculapathyRightT7Text: data.history.radiculapathyRightT7Text, radiculapathyRightT8Choice: data.history.radiculapathyRightT8Choice, radiculapathyRightT8Text: data.history.radiculapathyRightT8Text, radiculapathyRightT9Choice: data.history.radiculapathyRightT9Choice, radiculapathyRightT9Text: data.history.radiculapathyRightT9Text, radiculapathyRightT10Choice: data.history.radiculapathyRightT10Choice, radiculapathyRightT10Text: data.history.radiculapathyRightT10Text, radiculapathyRightT11Choice: data.history.radiculapathyRightT11Choice, radiculapathyRightT11Text: data.history.radiculapathyRightT11Text, radiculapathyRightT12Choice: data.history.radiculapathyRightT12Choice, radiculapathyRightT12Text: data.history.radiculapathyRightT12Text, radiculapathyRightL1Choice: data.history.radiculapathyRightL1Choice, 
        radiculapathyRightL1Text: data.history.radiculapathyRightL1Text, radiculapathyRightL2Choice: data.history.radiculapathyRightL2Choice, radiculapathyRightL2Text: data.history.radiculapathyRightL2Text, radiculapathyRightL3Choice: data.history.radiculapathyRightL3Choice, radiculapathyRightL3Text: data.history.radiculapathyRightL3Text, radiculapathyRightL4Choice: data.history.radiculapathyRightL4Choice, radiculapathyRightL4Text: data.history.radiculapathyRightL4Text, radiculapathyRightL5Choice: data.history.radiculapathyRightL5Choice, radiculapathyRightL5Text: data.history.radiculapathyRightL5Text, radiculapathyRightS1Choice: data.history.radiculapathyRightS1Choice, radiculapathyRightS1Text: data.history.radiculapathyRightS1Text, radiculapathyRightS3Choice: data.history.radiculapathyRightS3Choice, radiculapathyRightS3Text: data.history.radiculapathyRightS3Text, radiculapathyRightS45Choice: data.history.radiculapathyRightS45Choice, radiculapathyRightS45Text: data.history.radiculapathyRightS45Text,
        /*EXAMINATION */
        eyeText: data.examination.eyeText ,motorText: data.examination.motorText ,verbalText: data.examination.verbalText ,gcsText: data.examination.gcsText ,dementiaChoice: data.examination.dementiaChoice ,dementiaText: data.examination.dementiaText ,tandemChoice: data.examination.tandemChoice ,tandemText: data.examination.tandemText ,dysdiadochokinesiaChoice: data.examination.dysdiadochokinesiaChoice ,dysdiadochokinesiaText: data.examination.dysdiadochokinesiaText ,rombergChoice: data.examination.rombergChoice ,rombergText: data.examination.rombergText ,rombergPositiveChoice: data.examination.rombergPositiveChoice ,rombergPostiveText: data.examination.rombergPostiveText ,rombergNegativeChoice: data.examination.rombergNegativeChoice ,rombergNegativeText: data.examination.rombergNegativeText ,ataxiaChoice: data.examination.ataxiaChoice ,ataxiaText: data.examination.ataxiaText ,ataxiaTruncalChoice: data.examination.ataxiaTruncalChoice ,ataxiaTruncalText: data.examination.ataxiaTruncalText ,ataxiaAppendicularChoice: data.examination.ataxiaAppendicularChoice ,ataxiaAppendicularText: data.examination.ataxiaAppendicularText ,ataxiaGlobalChoice: data.examination.ataxiaGlobalChoice ,ataxiaGlobalText: data.examination.ataxiaGlobalText ,nystagmusChoice: data.examination.nystagmusChoice ,
        nystagmusText: data.examination.nystagmusText ,dysphasiaChoice: data.examination.dysphasiaChoice ,dysphasiaText: data.examination.dysphasiaText ,dysphasiaExpressiveChoice: data.examination.dysphasiaExpressiveChoice ,dysphasiaExpressiveText: data.examination.dysphasiaExpressiveText ,dysphasiaReceptiveChoice: data.examination.dysphasiaReceptiveChoice ,dysphasiaReceptiveText: data.examination.dysphasiaReceptiveText ,dysphasiaGlobalChoice: data.examination.dysphasiaGlobalChoice ,dysphasiaGlobalText: data.examination.dysphasiaGlobalText ,abuliaChoice: data.examination.abuliaChoice ,abuliaText: data.examination.abuliaText ,astereognosiaChoice: data.examination.astereognosiaChoice ,astereognosiaText: data.examination.astereognosiaText ,akineticChoice: data.examination.akineticChoice ,akineticText: data.examination.akineticText ,hemianopsiaChoice: data.examination.hemianopsiaChoice ,hemianopsiaText: data.examination.hemianopsiaText ,hemianopsiaRightChoice: data.examination.hemianopsiaRightChoice ,hemianopsiaRightText: data.examination.hemianopsiaRightText ,hemianopsiaLeftChoice: data.examination.hemianopsiaLeftChoice ,hemianopsiaLeftText: data.examination.hemianopsiaLeftText ,hemianopsiaBitemporalChoice: data.examination.hemianopsiaBitemporalChoice ,
        hemianopsiaBitemporalText: data.examination.hemianopsiaBitemporalText ,hemianopsiaTemporalChoice: data.examination.hemianopsiaTemporalChoice ,hemianopsiaTemporalText: data.examination.hemianopsiaTemporalText ,hemianopsiaNasalChoice: data.examination.hemianopsiaNasalChoice ,hemianopsiaNasalText: data.examination.hemianopsiaNasalText ,cranialChoice: data.examination.cranialChoice ,cranialText: data.examination.cranialText ,cranialLeftChoice: data.examination.cranialLeftChoice ,cranialLeftText: data.examination.cranialLeftText ,olfactoryLeftChoice: data.examination.olfactoryLeftChoice ,olfactoryLeftText: data.examination.olfactoryLeftText ,opticLeftChoice: data.examination.opticLeftChoice ,opticLeftText: data.examination.opticLeftText ,oculomotorLeftChoice: data.examination.oculomotorLeftChoice ,oculomotorLeftText: data.examination.oculomotorLeftText ,trochlearLeftChoice: data.examination.trochlearLeftChoice ,trochlearLeftText: data.examination.trochlearLeftText ,trigeminalLeftChoice: data.examination.trigeminalLeftChoice ,trigeminalLeftText: data.examination.trigeminalLeftText ,abducentLeftChoice: data.examination.abducentLeftChoice ,abducentLeftText: data.examination.abducentLeftText ,facialLeftChoice: data.examination.facialLeftChoice ,
        facialLeftText: data.examination.facialLeftText ,vestibulocochlearLeftChoice: data.examination.vestibulocochlearLeftChoice ,vestibulocochlearLeftText: data.examination.vestibulocochlearLeftText ,glossopharyngealLeftChoice: data.examination.glossopharyngealLeftChoice ,glossopharyngealLeftText: data.examination.glossopharyngealLeftText ,vagusLeftChoice: data.examination.vagusLeftChoice ,vagusLeftText: data.examination.vagusLeftText ,accessoryLeftChoice: data.examination.accessoryLeftChoice ,accessoryLeftText: data.examination.accessoryLeftText ,hypoglossalLeftChoice: data.examination.hypoglossalLeftChoice ,hypoglossalLeftText: data.examination.hypoglossalLeftText ,cranialRightChoice: data.examination.cranialRightChoice ,cranialRightText: data.examination.cranialRightText ,olfactoryRightChoice: data.examination.olfactoryRightChoice ,olfactoryRightText: data.examination.olfactoryRightText ,opticRightChoice: data.examination.opticRightChoice ,opticRightText: data.examination.opticRightText ,oculomotorRightChoice: data.examination.oculomotorRightChoice ,oculomotorRightText: data.examination.oculomotorRightText ,trochlearRightChoice: data.examination.trochlearRightChoice ,trochlearRightText: data.examination.trochlearRightText ,
        trigeminalRightChoice: data.examination.trigeminalRightChoice ,trigeminalRightText: data.examination.trigeminalRightText ,abducentRightChoice: data.examination.abducentRightChoice ,abducentRightText: data.examination.abducentRightText ,facialRightChoice: data.examination.facialRightChoice ,facialRightText: data.examination.facialRightText ,vestibulocochlearRightChoice: data.examination.vestibulocochlearRightChoice ,vestibulocochlearRightText: data.examination.vestibulocochlearRightText ,glossopharyngealRightChoice: data.examination.glossopharyngealRightChoice ,glossopharyngealRightText: data.examination.glossopharyngealRightText ,vagusRightChoice: data.examination.vagusRightChoice ,vagusRightText: data.examination.vagusRightText ,accessoryRightChoice: data.examination.accessoryRightChoice ,accessoryRightText: data.examination.accessoryRightText ,hypoglossalRightChoice: data.examination.hypoglossalRightChoice ,hypoglossalRightText: data.examination.hypoglossalRightText ,spineRangeChoice: data.examination.spineRangeChoice ,spineRangeText: data.examination.spineRangeText ,spineRangeCervicalChoice: data.examination.spineRangeCervicalChoice ,spineRangeCervicalText: data.examination.spineRangeCervicalText ,
        spineRangeCervicalFlexionChoice: data.examination.spineRangeCervicalFlexionChoice ,spineRangeCervicalFlexionText: data.examination.spineRangeCervicalFlexionText ,spineRangeCervicalFlexionReducedChoice: data.examination.spineRangeCervicalFlexionReducedChoice ,spineRangeCervicalFlexionReducedText: data.examination.spineRangeCervicalFlexionReducedText ,spineRangeCervicalFlexionPainfulChoice: data.examination.spineRangeCervicalFlexionPainfulChoice ,spineRangeCervicalFlexionPainfulText: data.examination.spineRangeCervicalFlexionPainfulText ,spineRangeCervicalExtensionChoice: data.examination.spineRangeCervicalExtensionChoice ,spineRangeCervicalExtensionText: data.examination.spineRangeCervicalExtensionText ,spineRangeCervicalExtensionReducedChoice: data.examination.spineRangeCervicalExtensionReducedChoice ,spineRangeCervicalExtensionReducedText: data.examination.spineRangeCervicalExtensionReducedText ,spineRangeCervicalExtensionPainfulChoice: data.examination.spineRangeCervicalExtensionPainfulChoice ,spineRangeCervicalExtensionPainfulText: data.examination.spineRangeCervicalExtensionPainfulText ,spineRangeCervicalLateralBendingChoice: data.examination.spineRangeCervicalLateralBendingChoice ,
        spineRangeCervicalLateralBendingText: data.examination.spineRangeCervicalLateralBendingText ,spineRangeCervicalLateralBendingReducedChoice: data.examination.spineRangeCervicalLateralBendingReducedChoice ,spineRangeCervicalLateralBendingReducedText: data.examination.spineRangeCervicalLateralBendingReducedText ,spineRangeCervicalLateralBendingPainfulChoice: data.examination.spineRangeCervicalLateralBendingPainfulChoice ,spineRangeCervicalLateralBendingPainfulText: data.examination.spineRangeCervicalLateralBendingPainfulText ,spineRangeCervicalRotationChoice: data.examination.spineRangeCervicalRotationChoice ,spineRangeCervicalRotationText: data.examination.spineRangeCervicalRotationText ,spineRangeCervicalRotationReducedChoice: data.examination.spineRangeCervicalRotationReducedChoice ,spineRangeCervicalRotationReducedText: data.examination.spineRangeCervicalRotationReducedText ,spineRangeCervicalRotationPainfulChoice: data.examination.spineRangeCervicalRotationPainfulChoice ,spineRangeCervicalRotationPainfulText: data.examination.spineRangeCervicalRotationPainfulText ,spineRangeThoracicChoice: data.examination.spineRangeThoracicChoice ,
        spineRangeThoracicText: data.examination.spineRangeThoracicText ,spineRangeThoracicFlexionChoice: data.examination.spineRangeThoracicFlexionChoice ,spineRangeThoracicFlexionText: data.examination.spineRangeThoracicFlexionText ,spineRangeThoracicFlexionReducedChoice: data.examination.spineRangeThoracicFlexionReducedChoice ,spineRangeThoracicFlexionReducedText: data.examination.spineRangeThoracicFlexionReducedText ,spineRangeThoracicFlexionPainfulChoice: data.examination.spineRangeThoracicFlexionPainfulChoice ,spineRangeThoracicFlexionPainfulText: data.examination.spineRangeThoracicFlexionPainfulText ,spineRangeThoracicExtensionChoice: data.examination.spineRangeThoracicExtensionChoice ,spineRangeThoracicExtensionText: data.examination.spineRangeThoracicExtensionText ,spineRangeThoracicExtensionReducedChoice: data.examination.spineRangeThoracicExtensionReducedChoice ,spineRangeThoracicExtensionReducedText: data.examination.spineRangeThoracicExtensionReducedText ,spineRangeThoracicExtensionPainfulChoice: data.examination.spineRangeThoracicExtensionPainfulChoice ,spineRangeThoracicExtensionPainfulText: data.examination.spineRangeThoracicExtensionPainfulText ,
        spineRangeThoracicLateralBendingChoice: data.examination.spineRangeThoracicLateralBendingChoice ,spineRangeThoracicLateralBendingText: data.examination.spineRangeThoracicLateralBendingText ,spineRangeThoracicLateralBendingReducedChoice: data.examination.spineRangeThoracicLateralBendingReducedChoice ,spineRangeThoracicLateralBendingReducedText: data.examination.spineRangeThoracicLateralBendingReducedText ,spineRangeThoracicLateralBendingPainfulChoice: data.examination.spineRangeThoracicLateralBendingPainfulChoice ,spineRangeThoracicLateralBendingPainfulText: data.examination.spineRangeThoracicLateralBendingPainfulText ,spineRangeThoracicRotationChoice: data.examination.spineRangeThoracicRotationChoice ,spineRangeThoracicRotationText: data.examination.spineRangeThoracicRotationText ,spineRangeThoracicRotationReducedChoice: data.examination.spineRangeThoracicRotationReducedChoice ,spineRangeThoracicRotationReducedText: data.examination.spineRangeThoracicRotationReducedText ,spineRangeThoracicRotationPainfulChoice: data.examination.spineRangeThoracicRotationPainfulChoice ,spineRangeThoracicRotationPainfulText: data.examination.spineRangeThoracicRotationPainfulText ,
        spineRangeLumbarChoice: data.examination.spineRangeLumbarChoice ,spineRangeLumbarText: data.examination.spineRangeLumbarText ,spineRangeLumbarFlexionChoice: data.examination.spineRangeLumbarFlexionChoice ,spineRangeLumbarFlexionText: data.examination.spineRangeLumbarFlexionText ,spineRangeLumbarFlexionReducedChoice: data.examination.spineRangeLumbarFlexionReducedChoice ,spineRangeLumbarFlexionReducedText: data.examination.spineRangeLumbarFlexionReducedText ,spineRangeLumbarFlexionPainfulChoice: data.examination.spineRangeLumbarFlexionPainfulChoice ,spineRangeLumbarFlexionPainfulText: data.examination.spineRangeLumbarFlexionPainfulText ,spineRangeLumbarExtensionChoice: data.examination.spineRangeLumbarExtensionChoice ,spineRangeLumbarExtensionText: data.examination.spineRangeLumbarExtensionText ,spineRangeLumbarExtensionReducedChoice: data.examination.spineRangeLumbarExtensionReducedChoice ,spineRangeLumbarExtensionReducedText: data.examination.spineRangeLumbarExtensionReducedText ,spineRangeLumbarExtensionPainfulChoice: data.examination.spineRangeLumbarExtensionPainfulChoice ,spineRangeLumbarExtensionPainfulText: data.examination.spineRangeLumbarExtensionPainfulText ,
        spineRangeLumbarLateralBendingChoice: data.examination.spineRangeLumbarLateralBendingChoice ,spineRangeLumbarLateralBendingText: data.examination.spineRangeLumbarLateralBendingText ,spineRangeLumbarLateralBendingReducedChoice: data.examination.spineRangeLumbarLateralBendingReducedChoice ,spineRangeLumbarLateralBendingReducedText: data.examination.spineRangeLumbarLateralBendingReducedText ,spineRangeLumbarLateralBendingPainfulChoice: data.examination.spineRangeLumbarLateralBendingPainfulChoice ,spineRangeLumbarLateralBendingPainfulText: data.examination.spineRangeLumbarLateralBendingPainfulText ,spineRangeLumbarRotationChoice: data.examination.spineRangeLumbarRotationChoice ,spineRangeLumbarRotationText: data.examination.spineRangeLumbarRotationText ,spineRangeLumbarRotationReducedChoice: data.examination.spineRangeLumbarRotationReducedChoice ,spineRangeLumbarRotationReducedText: data.examination.spineRangeLumbarRotationReducedText ,spineRangeLumbarRotationPainfulChoice: data.examination.spineRangeLumbarRotationPainfulChoice ,spineRangeLumbarRotationPainfulText: data.examination.spineRangeLumbarRotationPainfulText ,spineTendernessChoice: data.examination.spineTendernessChoice ,
        spineTendernessText: data.examination.spineTendernessText ,spineTendernessCervicalChoice: data.examination.spineTendernessCervicalChoice ,spineTendernessCervicalText: data.examination.spineTendernessCervicalText ,spineTendernessThoracicChoicespineTendernessThoracicText: data.examination.spineTendernessThoracicChoicespineTendernessThoracicText ,spineTendernessThoracolumbarChoice: data.examination.spineTendernessThoracolumbarChoice ,spineTendernessThoracolumbarText: data.examination.spineTendernessThoracolumbarText ,spineTendernessLumbarChoice: data.examination.spineTendernessLumbarChoice ,spineTendernessLumbarText: data.examination.spineTendernessLumbarText ,spineTendernessLumbosacralChoice: data.examination.spineTendernessLumbosacralChoice ,spineTendernessLumbosacralText: data.examination.spineTendernessLumbosacralText ,spineTendernessSacroiliacChoice: data.examination.spineTendernessSacroiliacChoice ,spineTendernessSacroiliacText: data.examination.spineTendernessSacroiliacText ,trendelenbergChoice: data.examination.trendelenbergChoice ,trendelenbergText: data.examination.trendelenbergText ,trendelenbergLeftChoice: data.examination.trendelenbergLeftChoice ,trendelenbergLeftText: data.examination.trendelenbergLeftText ,
        trendelenbergLeftPresentChoice: data.examination.trendelenbergLeftPresentChoice ,trendelenbergLeftPresentText: data.examination.trendelenbergLeftPresentText ,trendelenbergLeftNormalChoice: data.examination.trendelenbergLeftNormalChoice ,trendelenbergLeftNormalText: data.examination.trendelenbergLeftNormalText ,trendelenbergRightChoice: data.examination.trendelenbergRightChoice ,trendelenbergRightText: data.examination.trendelenbergRightText ,trendelenbergRightPresentChoice: data.examination.trendelenbergRightPresentChoice ,trendelenbergRightPresentText: data.examination.trendelenbergRightPresentText ,trendelenbergRightNormalChoice: data.examination.trendelenbergRightNormalChoice ,trendelenbergRightNormalText: data.examination.trendelenbergRightNormalText ,standOnTipToeChoice: data.examination.standOnTipToeChoice ,standOnTipToeText: data.examination.standOnTipToeText ,standOnTipToeLeftChoice: data.examination.standOnTipToeLeftChoice ,standOnTipToeLeftText: data.examination.standOnTipToeLeftText ,standOnTipToeLeftWeakChoice: data.examination.standOnTipToeLeftWeakChoice ,standOnTipToeLeftWeakText: data.examination.standOnTipToeLeftWeakText ,standOnTipToeLeftNormalChoice: data.examination.standOnTipToeLeftNormalChoice ,
        standOnTipToeLeftNormalText: data.examination.standOnTipToeLeftNormalText ,standOnTipToeRightChoice: data.examination.standOnTipToeRightChoice ,standOnTipToeRightText: data.examination.standOnTipToeRightText ,standOnTipToeRightWeakChoice: data.examination.standOnTipToeRightWeakChoice ,standOnTipToeRightWeakText: data.examination.standOnTipToeRightWeakText ,standOnTipToeRightNormalChoice: data.examination.standOnTipToeRightNormalChoice ,standOnTipToeRightNormalText: data.examination.standOnTipToeRightNormalText ,spurlingTestChoice: data.examination.spurlingTestChoice ,spurlingTestText: data.examination.spurlingTestText ,spurlingTestLeftChoice: data.examination.spurlingTestLeftChoice ,spurlingTestLeftText: data.examination.spurlingTestLeftText ,spurlingTestLeftPresentChoice: data.examination.spurlingTestLeftPresentChoice ,spurlingTestLeftPresentText: data.examination.spurlingTestLeftPresentText ,spurlingTestLeftAbsentChoice: data.examination.spurlingTestLeftAbsentChoice ,spurlingTestLeftAbsentText: data.examination.spurlingTestLeftAbsentText ,spurlingTestRightChoice: data.examination.spurlingTestRightChoice ,spurlingTestRightText: data.examination.spurlingTestRightText ,spurlingTestRightPresentChoice: data.examination.spurlingTestRightPresentChoice ,
        spurlingTestRightPresentText: data.examination.spurlingTestRightPresentText ,spurlingTestRightAbsentChoice: data.examination.spurlingTestRightAbsentChoice ,spurlingTestRightAbsentText: data.examination.spurlingTestRightAbsentText ,abductionReliefChoice: data.examination.abductionReliefChoice ,abductionReliefText: data.examination.abductionReliefText ,abductionReliefLeftChoice: data.examination.abductionReliefLeftChoice ,abductionReliefLeftText: data.examination.abductionReliefLeftText ,abductionReliefLeftPresentChoice: data.examination.abductionReliefLeftPresentChoice ,abductionReliefLeftPresentText: data.examination.abductionReliefLeftPresentText ,abductionReliefLeftAbsentChoice: data.examination.abductionReliefLeftAbsentChoice ,abductionReliefLeftAbsentText: data.examination.abductionReliefLeftAbsentText ,abductionReliefRightChoice: data.examination.abductionReliefRightChoice ,abductionReliefRightText: data.examination.abductionReliefRightText ,abductionReliefRightPresentChoice: data.examination.abductionReliefRightPresentChoice ,abductionReliefRightPresentText: data.examination.abductionReliefRightPresentText ,abductionReliefRightAbsentChoice: data.examination.abductionReliefRightAbsentChoice ,
        abductionReliefRightAbsentText: data.examination.abductionReliefRightAbsentText ,straightLegRaisingChoice: data.examination.straightLegRaisingChoice ,straightLegRaisingText: data.examination.straightLegRaisingText ,straightLegRaisingLeftChoice: data.examination.straightLegRaisingLeftChoice ,straightLegRaisingLeftText: data.examination.straightLegRaisingLeftText ,straightLegRaisingLeftPresentChoice: data.examination.straightLegRaisingLeftPresentChoice ,straightLegRaisingLeftPresentText: data.examination.straightLegRaisingLeftPresentText ,straightLegRaisingLeftAbsentChoice: data.examination.straightLegRaisingLeftAbsentChoice ,straightLegRaisingLeftAbsentText: data.examination.straightLegRaisingLeftAbsentText ,straightLegRaisingRightChoice: data.examination.straightLegRaisingRightChoice ,straightLegRaisingRightText: data.examination.straightLegRaisingRightText ,straightLegRaisingRightPresentChoice: data.examination.straightLegRaisingRightPresentChoice ,straightLegRaisingRightPresentText: data.examination.straightLegRaisingRightPresentText ,straightLegRaisingRightAbsentChoice: data.examination.straightLegRaisingRightAbsentChoice ,straightLegRaisingRightAbsentText: data.examination.straightLegRaisingRightAbsentText ,
        reverseStraightLegChoice: data.examination.reverseStraightLegChoice ,reverseStraightLegText: data.examination.reverseStraightLegText ,reverseStraightLegLeftChoice: data.examination.reverseStraightLegLeftChoice ,reverseStraightLegLeftText: data.examination.reverseStraightLegLeftText ,reverseStraightLegLeftPresentChoice: data.examination.reverseStraightLegLeftPresentChoice ,reverseStraightLegLeftPresentText: data.examination.reverseStraightLegLeftPresentText ,reverseStraightLegLeftAbsentChoice: data.examination.reverseStraightLegLeftAbsentChoice ,reverseStraightLegLeftAbsentText: data.examination.reverseStraightLegLeftAbsentText ,reverseStraightLegRightChoice: data.examination.reverseStraightLegRightChoice ,reverseStraightLegRightText: data.examination.reverseStraightLegRightText ,reverseStraightLegRightPresentChoice: data.examination.reverseStraightLegRightPresentChoice ,reverseStraightLegRightPresentText: data.examination.reverseStraightLegRightPresentText ,reverseStraightLegRightAbsentChoice: data.examination.reverseStraightLegRightAbsentChoice ,reverseStraightLegRightAbsentText: data.examination.reverseStraightLegRightAbsentText ,toneChoice: data.examination.toneChoice ,toneText: data.examination.toneText ,
        toneLeftUpperLimbChoice: data.examination.toneLeftUpperLimbChoice ,toneLeftUpperLimbText: data.examination.toneLeftUpperLimbText ,toneLeftUpperLimbHypertoniaChoice: data.examination.toneLeftUpperLimbHypertoniaChoice ,toneLeftUpperLimbHypertoniaText: data.examination.toneLeftUpperLimbHypertoniaText ,toneLeftUpperLimbHypotoniaChoice: data.examination.toneLeftUpperLimbHypotoniaChoice ,toneLeftUpperLimbHypotoniaText: data.examination.toneLeftUpperLimbHypotoniaText ,toneLeftUpperLimbAtoniaChoice: data.examination.toneLeftUpperLimbAtoniaChoice ,toneLeftUpperLimbAtoniaText: data.examination.toneLeftUpperLimbAtoniaText ,toneRightUpperLimbChoice: data.examination.toneRightUpperLimbChoice ,toneRightUpperLimbText: data.examination.toneRightUpperLimbText ,toneRightUpperLimbHypertoniaChoice: data.examination.toneRightUpperLimbHypertoniaChoice ,toneRightUpperLimbHypertoniaText: data.examination.toneRightUpperLimbHypertoniaText ,toneRightUpperLimbHypotoniaChoice: data.examination.toneRightUpperLimbHypotoniaChoice ,toneRightUpperLimbHypotoniaText: data.examination.toneRightUpperLimbHypotoniaText ,toneRightUpperLimbAtoniaChoice: data.examination.toneRightUpperLimbAtoniaChoice ,toneRightUpperLimbAtoniaText: data.examination.toneRightUpperLimbAtoniaText ,
        toneLeftLowerLimbChoice: data.examination.toneLeftLowerLimbChoice ,toneLeftLowerLimbText: data.examination.toneLeftLowerLimbText ,toneLeftLowerLimbHypertoniaChoice: data.examination.toneLeftLowerLimbHypertoniaChoice ,toneLeftLowerLimbHypertoniaText: data.examination.toneLeftLowerLimbHypertoniaText ,toneLeftLowerLimbHypotoniaChoice: data.examination.toneLeftLowerLimbHypotoniaChoice ,toneLeftLowerLimbHypotoniaText: data.examination.toneLeftLowerLimbHypotoniaText ,toneLeftLowerLimbAtoniaChoice: data.examination.toneLeftLowerLimbAtoniaChoice ,toneLeftLowerLimbAtoniaText: data.examination.toneLeftLowerLimbAtoniaText ,toneRightLowerLimbChoice: data.examination.toneRightLowerLimbChoice ,toneRightLowerLimbText: data.examination.toneRightLowerLimbText ,toneRightLowerLimbHypertoniaChoice: data.examination.toneRightLowerLimbHypertoniaChoice ,toneRightLowerLimbHypertoniaText: data.examination.toneRightLowerLimbHypertoniaText ,toneRightLowerLimbHypotoniaChoice: data.examination.toneRightLowerLimbHypotoniaChoice ,toneRightLowerLimbHypotoniaText: data.examination.toneRightLowerLimbHypotoniaText ,toneRightLowerLimbAtoniaChoice: data.examination.toneRightLowerLimbAtoniaChoice ,toneRightLowerLimbAtoniaText: data.examination.toneRightLowerLimbAtoniaText ,
        toneGlobalChoice: data.examination.toneGlobalChoice ,toneGlobalText: data.examination.toneGlobalText ,toneGlobalHypertoniaChoice: data.examination.toneGlobalHypertoniaChoice ,toneGlobalHypertoniaText: data.examination.toneGlobalHypertoniaText ,toneGlobalHypotoniaChoice: data.examination.toneGlobalHypotoniaChoice ,toneGlobalHypotoniaText: data.examination.toneGlobalHypotoniaText ,toneGlobalAbsentChoice: data.examination.toneGlobalAbsentChoice ,toneGlobalAbsentText: data.examination.toneGlobalAbsentText ,powerChoice: data.examination.powerChoice ,powerText: data.examination.powerText ,powerLeftUpperLimbChoice: data.examination.powerLeftUpperLimbChoice ,powerLeftUpperLimbText: data.examination.powerLeftUpperLimbText ,powerLeftUpperLimbC5Choice: data.examination.powerLeftUpperLimbC5Choice ,powerLeftUpperLimbC5Text: data.examination.powerLeftUpperLimbC5Text ,powerLeftUpperLimbC6Choice: data.examination.powerLeftUpperLimbC6Choice ,powerLeftUpperLimbC6Text: data.examination.powerLeftUpperLimbC6Text ,powerLeftUpperLimbC7Choice: data.examination.powerLeftUpperLimbC7Choice ,powerLeftUpperLimbC7Text: data.examination.powerLeftUpperLimbC7Text ,powerLeftUpperLimbC8Choice: data.examination.powerLeftUpperLimbC8Choice ,powerLeftUpperLimbC8Text: data.examination.powerLeftUpperLimbC8Text ,
        powerLeftUpperLimbT1Choice: data.examination.powerLeftUpperLimbT1Choice ,powerLeftUpperLimbT1Text: data.examination.powerLeftUpperLimbT1Text ,powerRightUpperLimbChoice: data.examination.powerRightUpperLimbChoice ,powerRightUpperLimbText: data.examination.powerRightUpperLimbText ,powerRightUpperLimbC5Choice: data.examination.powerRightUpperLimbC5Choice ,powerRightUpperLimbC5Text: data.examination.powerRightUpperLimbC5Text ,powerRightUpperLimbC6Choice: data.examination.powerRightUpperLimbC6Choice ,powerRightUpperLimbC6Text: data.examination.powerRightUpperLimbC6Text ,powerRightUpperLimbC7Choice: data.examination.powerRightUpperLimbC7Choice ,powerRightUpperLimbC7Text: data.examination.powerRightUpperLimbC7Text ,powerRightUpperLimbC8Choice: data.examination.powerRightUpperLimbC8Choice ,powerRightUpperLimbC8Text: data.examination.powerRightUpperLimbC8Text ,powerRightUpperLimbT1Choice: data.examination.powerRightUpperLimbT1Choice ,powerRightUpperLimbT1Text: data.examination.powerRightUpperLimbT1Text ,powerLeftLowerLimbChoice: data.examination.powerLeftLowerLimbChoice ,powerLeftLowerLimbText: data.examination.powerLeftLowerLimbText ,powerLeftLowerLimbC5Choice: data.examination.powerLeftLowerLimbC5Choice ,powerLeftLowerLimbC5Text: data.examination.powerLeftLowerLimbC5Text ,
        powerLeftLowerLimbC6Choice: data.examination.powerLeftLowerLimbC6Choice ,powerLeftLowerLimbC6Text: data.examination.powerLeftLowerLimbC6Text ,powerLeftLowerLimbC7Choice: data.examination.powerLeftLowerLimbC7Choice ,powerLeftLowerLimbC7Text: data.examination.powerLeftLowerLimbC7Text ,powerLeftLowerLimbC8Choice: data.examination.powerLeftLowerLimbC8Choice ,powerLeftLowerLimbC8Text: data.examination.powerLeftLowerLimbC8Text ,powerLeftLowerLimbT1Choice: data.examination.powerLeftLowerLimbT1Choice ,powerLeftLowerLimbT1Text: data.examination.powerLeftLowerLimbT1Text ,powerRightLowerLimbChoice: data.examination.powerRightLowerLimbChoice ,powerRightLowerLimbText: data.examination.powerRightLowerLimbText ,powerRightLowerLimbC5Choice: data.examination.powerRightLowerLimbC5Choice ,powerRightLowerLimbC5Text: data.examination.powerRightLowerLimbC5Text ,powerRightLowerLimbC6Choice: data.examination.powerRightLowerLimbC6Choice ,powerRightLowerLimbC6Text: data.examination.powerRightLowerLimbC6Text ,powerRightLowerLimbC7Choice: data.examination.powerRightLowerLimbC7Choice ,powerRightLowerLimbC7Text: data.examination.powerRightLowerLimbC7Text ,powerRightLowerLimbC8Choice: data.examination.powerRightLowerLimbC8Choice ,powerRightLowerLimbC8Text: data.examination.powerRightLowerLimbC8Text ,
        powerRightLowerLimbT1Choice: data.examination.powerRightLowerLimbT1Choice ,powerRightLowerLimbT1Text: data.examination.powerRightLowerLimbT1Text ,wastingChoice: data.examination.wastingChoice ,wastingText: data.examination.wastingText ,wastingLeftUpperLimbChoice: data.examination.wastingLeftUpperLimbChoice ,wastingLeftUpperLimbText: data.examination.wastingLeftUpperLimbText ,wastingRightUpperLimbChoice: data.examination.wastingRightUpperLimbChoice ,wastingRightUpperLimbText: data.examination.wastingRightUpperLimbText ,wastingLeftLowerLimbChoice: data.examination.wastingLeftLowerLimbChoice ,wastingLeftLowerLimbText: data.examination.wastingLeftLowerLimbText ,wastingRightLowerLimbChoice: data.examination.wastingRightLowerLimbChoice ,wastingRightLowerLimbText: data.examination.wastingRightLowerLimbText ,fasciculationsChoice: data.examination.fasciculationsChoice ,fasciculationsText: data.examination.fasciculationsText ,fasciculationsLeftUpperLimbChoice: data.examination.fasciculationsLeftUpperLimbChoice ,fasciculationsLeftUpperLimbText: data.examination.fasciculationsLeftUpperLimbText ,fasciculationsRightUpperLimbChoice: data.examination.fasciculationsRightUpperLimbChoice ,fasciculationsRightUpperLimbText: data.examination.fasciculationsRightUpperLimbText ,
        fasciculationsLeftLowerLimbChoice: data.examination.fasciculationsLeftLowerLimbChoice ,fasciculationsLeftLowerLimbText: data.examination.fasciculationsLeftLowerLimbText ,fasciculationsRightLowerLimbChoice: data.examination.fasciculationsRightLowerLimbChoice ,fasciculationsRightLowerLimbText: data.examination.fasciculationsRightLowerLimbText ,deepTendonChoice: data.examination.deepTendonChoice ,deepTendonText: data.examination.deepTendonText ,deepTendonLeftBicepsChoice: data.examination.deepTendonLeftBicepsChoice ,deepTendonLeftBicepsText: data.examination.deepTendonLeftBicepsText ,deepTendonLeftBicepsHyperChoice: data.examination.deepTendonLeftBicepsHyperChoice ,deepTendonLeftBicepsHyperText: data.examination.deepTendonLeftBicepsHyperText ,deepTendonLeftBicepsHypoChoice: data.examination.deepTendonLeftBicepsHypoChoice ,deepTendonLeftBicepsHypoText: data.examination.deepTendonLeftBicepsHypoText ,deepTendonLeftBicepsAChoice: data.examination.deepTendonLeftBicepsAChoice ,deepTendonLeftBicepsAText: data.examination.deepTendonLeftBicepsAText ,deepTendonRightBicepsChoice: data.examination.deepTendonRightBicepsChoice ,deepTendonRightBicepsText: data.examination.deepTendonRightBicepsText ,deepTendonRightBicepsHyperChoice: data.examination.deepTendonRightBicepsHyperChoice ,
        deepTendonRightBicepsHyperText: data.examination.deepTendonRightBicepsHyperText ,deepTendonRightBicepsHypoChoice: data.examination.deepTendonRightBicepsHypoChoice ,deepTendonRightBicepsHypoText: data.examination.deepTendonRightBicepsHypoText ,deepTendonRightBicepsAChoice: data.examination.deepTendonRightBicepsAChoice ,deepTendonRightBicepsAText: data.examination.deepTendonRightBicepsAText ,deepTendonLeftTricepsChoice: data.examination.deepTendonLeftTricepsChoice ,deepTendonLeftTricepsText: data.examination.deepTendonLeftTricepsText ,deepTendonLeftTricepsHyperChoice: data.examination.deepTendonLeftTricepsHyperChoice ,deepTendonLeftTricepsHyperText: data.examination.deepTendonLeftTricepsHyperText ,deepTendonLeftTricepsHypoChoice: data.examination.deepTendonLeftTricepsHypoChoice ,deepTendonLeftTricepsHypoText: data.examination.deepTendonLeftTricepsHypoText ,deepTendonLeftTricepsAChoice: data.examination.deepTendonLeftTricepsAChoice ,deepTendonLeftTricepsAText: data.examination.deepTendonLeftTricepsAText ,deepTendonRightTricepsChoice: data.examination.deepTendonRightTricepsChoice ,deepTendonRightTricepsText: data.examination.deepTendonRightTricepsText ,deepTendonRightTricepsHyperChoice: data.examination.deepTendonRightTricepsHyperChoice ,
        deepTendonRightTricepsHyperText: data.examination.deepTendonRightTricepsHyperText ,deepTendonRightTricepsHypoChoice: data.examination.deepTendonRightTricepsHypoChoice ,deepTendonRightTricepsHypoText: data.examination.deepTendonRightTricepsHypoText ,deepTendonRightTricepsAChoice: data.examination.deepTendonRightTricepsAChoice ,deepTendonRightTricepsAText: data.examination.deepTendonRightTricepsAText ,deepTendonInvertedRadialChoice: data.examination.deepTendonInvertedRadialChoice ,deepTendonInvertedRadialText: data.examination.deepTendonInvertedRadialText ,deepTendonInvertedRadialLeftChoice: data.examination.deepTendonInvertedRadialLeftChoice ,deepTendonInvertedRadialLeftText: data.examination.deepTendonInvertedRadialLeftText ,deepTendonInvertedRadialRightChoice: data.examination.deepTendonInvertedRadialRightChoice ,deepTendonInvertedRadialRightText: data.examination.deepTendonInvertedRadialRightText ,deepTendonLeftKneeChoice: data.examination.deepTendonLeftKneeChoice ,deepTendonLeftKneeText: data.examination.deepTendonLeftKneeText ,deepTendonLeftKneeHyperChoice: data.examination.deepTendonLeftKneeHyperChoice ,deepTendonLeftKneeHyperText: data.examination.deepTendonLeftKneeHyperText ,deepTendonLeftKneeHypoChoice: data.examination.deepTendonLeftKneeHypoChoice ,
        deepTendonLeftKneeHypoText: data.examination.deepTendonLeftKneeHypoText ,deepTendonLeftKneeAChoice: data.examination.deepTendonLeftKneeAChoice ,deepTendonLeftKneeAText: data.examination.deepTendonLeftKneeAText ,deepTendonLeftKneePendularChoice: data.examination.deepTendonLeftKneePendularChoice ,deepTendonLeftKneePendularText: data.examination.deepTendonLeftKneePendularText ,deepTendonRightKneeChoice: data.examination.deepTendonRightKneeChoice ,deepTendonRightKneeText: data.examination.deepTendonRightKneeText ,deepTendonRightKneeHyperChoice: data.examination.deepTendonRightKneeHyperChoice ,deepTendonRightKneeHyperText: data.examination.deepTendonRightKneeHyperText ,deepTendonRightKneeHypoChoice: data.examination.deepTendonRightKneeHypoChoice ,deepTendonRightKneeHypoText: data.examination.deepTendonRightKneeHypoText ,deepTendonRightKneeAChoice: data.examination.deepTendonRightKneeAChoice ,deepTendonRightKneeAText: data.examination.deepTendonRightKneeAText ,deepTendonRightKneePendularChoice: data.examination.deepTendonRightKneePendularChoice ,deepTendonRightKneePendularText: data.examination.deepTendonRightKneePendularText ,deepTendonLeftAnkleChoice: data.examination.deepTendonLeftAnkleChoice ,deepTendonLeftAnkleText: data.examination.deepTendonLeftAnkleText ,
        deepTendonLeftAnkleHyperChoice: data.examination.deepTendonLeftAnkleHyperChoice ,deepTendonLeftAnkleHyperText: data.examination.deepTendonLeftAnkleHyperText ,deepTendonLeftAnkleHypoChoice: data.examination.deepTendonLeftAnkleHypoChoice ,deepTendonLeftAnkleHypoText: data.examination.deepTendonLeftAnkleHypoText ,deepTendonLeftAnkleAChoice: data.examination.deepTendonLeftAnkleAChoice ,deepTendonLeftAnkleAText: data.examination.deepTendonLeftAnkleAText ,deepTendonRightAnkleChoice: data.examination.deepTendonRightAnkleChoice ,deepTendonRightAnkleText: data.examination.deepTendonRightAnkleText ,deepTendonRightAnkleHyperChoice: data.examination.deepTendonRightAnkleHyperChoice ,deepTendonRightAnkleHyperText: data.examination.deepTendonRightAnkleHyperText ,deepTendonRightAnkleHypoChoice: data.examination.deepTendonRightAnkleHypoChoice ,deepTendonRightAnkleHypoText: data.examination.deepTendonRightAnkleHypoText ,deepTendonRightAnkleAChoice: data.examination.deepTendonRightAnkleAChoice ,deepTendonRightAnkleAText: data.examination.deepTendonRightAnkleAText ,hoffmansChoice: data.examination.hoffmansChoice ,hoffmansText: data.examination.hoffmansText ,hoffmansLeftChoice: data.examination.hoffmansLeftChoice ,hoffmansLeftText: data.examination.hoffmansLeftText ,
        hoffmansRightChoice: data.examination.hoffmansRightChoice ,hoffmansRightText: data.examination.hoffmansRightText ,ankleClonusChoice: data.examination.ankleClonusChoice ,ankleClonusText: data.examination.ankleClonusText ,ankleClonusLeftChoice: data.examination.ankleClonusLeftChoice ,ankleClonusLeftText: data.examination.ankleClonusLeftText ,ankleClonusRightChoice: data.examination.ankleClonusRightChoice ,ankleClonusRightText: data.examination.ankleClonusRightText ,babinskiChoice: data.examination.babinskiChoice ,babinskiText: data.examination.babinskiText ,babinskiLeftChoice: data.examination.babinskiLeftChoice ,babinskiLeftText: data.examination.babinskiLeftText ,babinskiLeftUpGoingChoice: data.examination.babinskiLeftUpGoingChoice ,babinskiLeftUpGoingText: data.examination.babinskiLeftUpGoingText ,babinskiLeftDownGoingChoice: data.examination.babinskiLeftDownGoingChoice ,babinskiLeftDownGoingText: data.examination.babinskiLeftDownGoingText ,babinskiLeftEquivocalChoice: data.examination.babinskiLeftEquivocalChoice ,babinskiLeftEquivocalText: data.examination.babinskiLeftEquivocalText ,babinskiRightChoice: data.examination.babinskiRightChoice ,babinskiRightText: data.examination.babinskiRightText ,babinskiRightUpGoingChoice: data.examination.babinskiRightUpGoingChoice ,babinskiRightUpGoingText: data.examination.babinskiRightUpGoingText ,
        babinskiRightDownGoingChoice: data.examination.babinskiRightDownGoingChoice ,babinskiRightDownGoingText: data.examination.babinskiRightDownGoingText ,babinskiRightEquivocalChoice: data.examination.babinskiRightEquivocalChoice ,babinskiRightEquivocalText: data.examination.babinskiRightEquivocalText ,bulbocavernosusChoice: data.examination.bulbocavernosusChoice ,bulbocavernosusText: data.examination.bulbocavernosusText ,bulbocavernosusPresentChoice: data.examination.bulbocavernosusPresentChoice ,bulbocavernosusPresentText: data.examination.bulbocavernosusPresentText ,bulbocavernosusAbsentChoice: data.examination.bulbocavernosusAbsentChoice ,bulbocavernosusAbsentText: data.examination.bulbocavernosusAbsentText ,beevorsChoice: data.examination.beevorsChoice ,beevorsText: data.examination.beevorsText ,beevorsPresentChoice: data.examination.beevorsPresentChoice ,beevorsPresentText: data.examination.beevorsPresentText ,beevorsAbsentChoice: data.examination.beevorsAbsentChoice ,beevorsAbsentText: data.examination.beevorsAbsentText ,rombergTestChoice: data.examination.rombergTestChoice ,rombergTestText: data.examination.rombergTestText ,rombergTestAbsentChoice: data.examination.rombergTestAbsentChoice ,rombergTestAbsentText: data.examination.rombergTestAbsentText ,rombergTestPresentChoice: data.examination.rombergTestPresentChoice ,
        rombergTestPresentText: data.examination.rombergTestPresentText ,rombergTestPresentLeftChoice: data.examination.rombergTestPresentLeftChoice ,rombergTestPresentLeftText: data.examination.rombergTestPresentLeftText ,rombergTestPresentRightChoice: data.examination.rombergTestPresentRightChoice ,rombergTestPresentRightText: data.examination.rombergTestPresentRightText ,motorLevelChoice: data.examination.motorLevelChoice ,motorLevelText: data.examination.motorLevelText ,motorLevelLeftChoice: data.examination.motorLevelLeftChoice ,motorLevelLeftText: data.examination.motorLevelLeftText ,motorLevelLeftC5Choice: data.examination.motorLevelLeftC5Choice ,motorLevelLeftC5Text: data.examination.motorLevelLeftC5Text ,motorLevelLeftC6Choice: data.examination.motorLevelLeftC6Choice ,motorLevelLeftC6Text: data.examination.motorLevelLeftC6Text ,motorLevelLeftC7Choice: data.examination.motorLevelLeftC7Choice ,motorLevelLeftC7Text: data.examination.motorLevelLeftC7Text ,motorLevelLeftC8Choice: data.examination.motorLevelLeftC8Choice ,motorLevelLeftC8Text: data.examination.motorLevelLeftC8Text ,motorLevelLeftT1Choice: data.examination.motorLevelLeftT1Choice ,motorLevelLeftT1Text: data.examination.motorLevelLeftT1Text ,motorLevelLeftL2Choice: data.examination.motorLevelLeftL2Choice ,motorLevelLeftL2Text: data.examination.motorLevelLeftL2Text ,
        motorLevelLeftL3Choice: data.examination.motorLevelLeftL3Choice ,motorLevelLeftL3Text: data.examination.motorLevelLeftL3Text ,motorLevelLeftL4Choice: data.examination.motorLevelLeftL4Choice ,motorLevelLeftL4Text: data.examination.motorLevelLeftL4Text ,motorLevelLeftL5Choice: data.examination.motorLevelLeftL5Choice ,motorLevelLeftL5Text: data.examination.motorLevelLeftL5Text ,motorLevelLeftS1Choice: data.examination.motorLevelLeftS1Choice ,motorLevelLeftS1Text: data.examination.motorLevelLeftS1Text ,motorLevelRightChoice: data.examination.motorLevelRightChoice ,motorLevelRightText: data.examination.motorLevelRightText ,motorLevelRightC5Choice: data.examination.motorLevelRightC5Choice ,motorLevelRightC5Text: data.examination.motorLevelRightC5Text ,motorLevelRightC6Choice: data.examination.motorLevelRightC6Choice ,motorLevelRightC6Text: data.examination.motorLevelRightC6Text ,motorLevelRightC7Choice: data.examination.motorLevelRightC7Choice ,motorLevelRightC7Text: data.examination.motorLevelRightC7Text ,motorLevelRightC8Choice: data.examination.motorLevelRightC8Choice ,motorLevelRightC8Text: data.examination.motorLevelRightC8Text ,motorLevelRightT1Choice: data.examination.motorLevelRightT1Choice ,motorLevelRightT1Text: data.examination.motorLevelRightT1Text ,motorLevelRightL2Choice: data.examination.motorLevelRightL2Choice ,
        motorLevelRightL2Text: data.examination.motorLevelRightL2Text ,motorLevelRightL3Choice: data.examination.motorLevelRightL3Choice ,motorLevelRightL3Text: data.examination.motorLevelRightL3Text ,motorLevelRightL4Choice: data.examination.motorLevelRightL4Choice ,motorLevelRightL4Text: data.examination.motorLevelRightL4Text ,motorLevelRightL5Choice: data.examination.motorLevelRightL5Choice ,motorLevelRightL5Text: data.examination.motorLevelRightL5Text ,motorLevelRightS1Choice: data.examination.motorLevelRightS1Choice ,motorLevelRightS1Text: data.examination.motorLevelRightS1Text ,sensoryLevelChoice: data.examination.sensoryLevelChoice ,sensoryLevelText: data.examination.sensoryLevelText ,sensoryLevelLeftChoice: data.examination.sensoryLevelLeftChoice ,sensoryLevelLeftText: data.examination.sensoryLevelLeftText ,sensoryLevelLeftC2Choice: data.examination.sensoryLevelLeftC2Choice ,sensoryLevelLeftC2Text: data.examination.sensoryLevelLeftC2Text ,sensoryLevelLeftC3Choice: data.examination.sensoryLevelLeftC3Choice ,sensoryLevelLeftC3Text: data.examination.sensoryLevelLeftC3Text ,sensoryLevelLeftC4Choice: data.examination.sensoryLevelLeftC4Choice ,sensoryLevelLeftC4Text: data.examination.sensoryLevelLeftC4Text ,sensoryLevelLeftC5Choice: data.examination.sensoryLevelLeftC5Choice ,sensoryLevelLeftC5Text: data.examination.sensoryLevelLeftC5Text ,
        sensoryLevelLeftC6Choice: data.examination.sensoryLevelLeftC6Choice ,sensoryLevelLeftC6Text: data.examination.sensoryLevelLeftC6Text ,sensoryLevelLeftC7Choice: data.examination.sensoryLevelLeftC7Choice ,sensoryLevelLeftC7Text: data.examination.sensoryLevelLeftC7Text ,sensoryLevelLeftC8Choice: data.examination.sensoryLevelLeftC8Choice ,sensoryLevelLeftC8Text: data.examination.sensoryLevelLeftC8Text ,sensoryLevelLeftT1Choice: data.examination.sensoryLevelLeftT1Choice ,sensoryLevelLeftT1Text: data.examination.sensoryLevelLeftT1Text ,sensoryLevelLeftT2Choice: data.examination.sensoryLevelLeftT2Choice ,sensoryLevelLeftT2Text: data.examination.sensoryLevelLeftT2Text ,sensoryLevelLeftT3Choice: data.examination.sensoryLevelLeftT3Choice ,sensoryLevelLeftT3Text: data.examination.sensoryLevelLeftT3Text ,sensoryLevelLeftT4Choice: data.examination.sensoryLevelLeftT4Choice ,sensoryLevelLeftT4Text: data.examination.sensoryLevelLeftT4Text ,sensoryLevelLeftT5Choice: data.examination.sensoryLevelLeftT5Choice ,sensoryLevelLeftT5Text: data.examination.sensoryLevelLeftT5Text ,sensoryLevelLeftT6Choice: data.examination.sensoryLevelLeftT6Choice ,sensoryLevelLeftT6Text: data.examination.sensoryLevelLeftT6Text ,sensoryLevelLeftT7Choice: data.examination.sensoryLevelLeftT7Choice ,sensoryLevelLeftT7Text: data.examination.sensoryLevelLeftT7Text ,
        sensoryLevelLeftT8Choice: data.examination.sensoryLevelLeftT8Choice ,sensoryLevelLeftT8Text: data.examination.sensoryLevelLeftT8Text ,sensoryLevelLeftT9Choice: data.examination.sensoryLevelLeftT9Choice ,sensoryLevelLeftT9Text: data.examination.sensoryLevelLeftT9Text ,sensoryLevelLeftT10Choice: data.examination.sensoryLevelLeftT10Choice ,sensoryLevelLeftT10Text: data.examination.sensoryLevelLeftT10Text ,sensoryLevelLeftT11Choice: data.examination.sensoryLevelLeftT11Choice ,sensoryLevelLeftT11Text: data.examination.sensoryLevelLeftT11Text ,sensoryLevelLeftT12Choice: data.examination.sensoryLevelLeftT12Choice ,sensoryLevelLeftT12Text: data.examination.sensoryLevelLeftT12Text ,sensoryLevelLeftL1Choice: data.examination.sensoryLevelLeftL1Choice ,sensoryLevelLeftL1Text: data.examination.sensoryLevelLeftL1Text ,sensoryLevelLeftL2Choice: data.examination.sensoryLevelLeftL2Choice ,sensoryLevelLeftL2Text: data.examination.sensoryLevelLeftL2Text ,sensoryLevelLeftL3Choice: data.examination.sensoryLevelLeftL3Choice ,sensoryLevelLeftL3Text: data.examination.sensoryLevelLeftL3Text ,sensoryLevelLeftL4Choice: data.examination.sensoryLevelLeftL4Choice ,sensoryLevelLeftL4Text: data.examination.sensoryLevelLeftL4Text ,sensoryLevelLeftL5Choice: data.examination.sensoryLevelLeftL5Choice ,sensoryLevelLeftL5Text: data.examination.sensoryLevelLeftL5Text ,
        sensoryLevelLeftS1Choice: data.examination.sensoryLevelLeftS1Choice ,sensoryLevelLeftS1Text: data.examination.sensoryLevelLeftS1Text ,sensoryLevelLeftS3Choice: data.examination.sensoryLevelLeftS3Choice ,sensoryLevelLeftS3Text: data.examination.sensoryLevelLeftS3Text ,sensoryLevelLeftS45Choice: data.examination.sensoryLevelLeftS45Choice ,sensoryLevelLeftS45Text: data.examination.sensoryLevelLeftS45Text ,sensoryLevelRightChoice: data.examination.sensoryLevelRightChoice ,sensoryLevelRightText: data.examination.sensoryLevelRightText ,sensoryLevelRightC2Choice: data.examination.sensoryLevelRightC2Choice ,sensoryLevelRightC2Text: data.examination.sensoryLevelRightC2Text ,sensoryLevelRightC3Choice: data.examination.sensoryLevelRightC3Choice ,sensoryLevelRightC3Text: data.examination.sensoryLevelRightC3Text ,sensoryLevelRightC4Choice: data.examination.sensoryLevelRightC4Choice ,sensoryLevelRightC4Text: data.examination.sensoryLevelRightC4Text ,sensoryLevelRightC5Choice: data.examination.sensoryLevelRightC5Choice ,sensoryLevelRightC5Text: data.examination.sensoryLevelRightC5Text ,sensoryLevelRightC6Choice: data.examination.sensoryLevelRightC6Choice ,sensoryLevelRightC6Text: data.examination.sensoryLevelRightC6Text ,sensoryLevelRightC7Choice: data.examination.sensoryLevelRightC7Choice ,
        sensoryLevelRightC7Text: data.examination.sensoryLevelRightC7Text ,sensoryLevelRightC8Choice: data.examination.sensoryLevelRightC8Choice ,sensoryLevelRightC8Text: data.examination.sensoryLevelRightC8Text ,sensoryLevelRightT1Choice: data.examination.sensoryLevelRightT1Choice ,sensoryLevelRightT1Text: data.examination.sensoryLevelRightT1Text ,sensoryLevelRightT2Choice: data.examination.sensoryLevelRightT2Choice ,sensoryLevelRightT2Text: data.examination.sensoryLevelRightT2Text ,sensoryLevelRightT3Choice: data.examination.sensoryLevelRightT3Choice ,sensoryLevelRightT3Text: data.examination.sensoryLevelRightT3Text ,sensoryLevelRightT4Choice: data.examination.sensoryLevelRightT4Choice ,sensoryLevelRightT4Text: data.examination.sensoryLevelRightT4Text ,sensoryLevelRightT5Choice: data.examination.sensoryLevelRightT5Choice ,sensoryLevelRightT5Text: data.examination.sensoryLevelRightT5Text ,sensoryLevelRightT6Choice: data.examination.sensoryLevelRightT6Choice ,sensoryLevelRightT6Text: data.examination.sensoryLevelRightT6Text ,sensoryLevelRightT7Choice: data.examination.sensoryLevelRightT7Choice ,sensoryLevelRightT7Text: data.examination.sensoryLevelRightT7Text ,sensoryLevelRightT8Choice: data.examination.sensoryLevelRightT8Choice ,sensoryLevelRightT8Text: data.examination.sensoryLevelRightT8Text ,
        sensoryLevelRightT9Choice: data.examination.sensoryLevelRightT9Choice ,sensoryLevelRightT9Text: data.examination.sensoryLevelRightT9Text ,sensoryLevelRightT10Choice: data.examination.sensoryLevelRightT10Choice ,sensoryLevelRightT10Text: data.examination.sensoryLevelRightT10Text ,sensoryLevelRightT11Choice: data.examination.sensoryLevelRightT11Choice ,sensoryLevelRightT11Text: data.examination.sensoryLevelRightT11Text ,sensoryLevelRightT12Choice: data.examination.sensoryLevelRightT12Choice ,sensoryLevelRightT12Text: data.examination.sensoryLevelRightT12Text ,sensoryLevelRightL1Choice: data.examination.sensoryLevelRightL1Choice ,sensoryLevelRightL1Text: data.examination.sensoryLevelRightL1Text ,sensoryLevelRightL2Choice: data.examination.sensoryLevelRightL2Choice ,sensoryLevelRightL2Text: data.examination.sensoryLevelRightL2Text ,sensoryLevelRightL3Choice: data.examination.sensoryLevelRightL3Choice ,sensoryLevelRightL3Text: data.examination.sensoryLevelRightL3Text ,sensoryLevelRightL4Choice: data.examination.sensoryLevelRightL4Choice ,sensoryLevelRightL4Text: data.examination.sensoryLevelRightL4Text ,sensoryLevelRightL5Choice: data.examination.sensoryLevelRightL5Choice ,sensoryLevelRightL5Text: data.examination.sensoryLevelRightL5Text ,sensoryLevelRightS1Choice: data.examination.sensoryLevelRightS1Choice ,
        sensoryLevelRightS1Text: data.examination.sensoryLevelRightS1Text ,sensoryLevelRightS3Choice: data.examination.sensoryLevelRightS3Choice ,sensoryLevelRightS3Text: data.examination.sensoryLevelRightS3Text ,sensoryLevelRightS45Choice: data.examination.sensoryLevelRightS45Choice ,sensoryLevelRightS45Text: data.examination.sensoryLevelRightS45Text ,neurologicalLevelChoice: data.examination.neurologicalLevelChoice ,neurologicalLevelText: data.examination.neurologicalLevelText ,neurologicalLevelC2Choice: data.examination.neurologicalLevelC2Choice ,neurologicalLevelC2Text: data.examination.neurologicalLevelC2Text ,neurologicalLevelC3Choice: data.examination.neurologicalLevelC3Choice ,neurologicalLevelC3Text: data.examination.neurologicalLevelC3Text ,neurologicalLevelC4Choice: data.examination.neurologicalLevelC4Choice ,neurologicalLevelC4Text: data.examination.neurologicalLevelC4Text ,neurologicalLevelC5Choice: data.examination.neurologicalLevelC5Choice ,neurologicalLevelC5Text: data.examination.neurologicalLevelC5Text ,neurologicalLevelC6Choice: data.examination.neurologicalLevelC6Choice ,neurologicalLevelC6Text: data.examination.neurologicalLevelC6Text ,neurologicalLevelC7Choice: data.examination.neurologicalLevelC7Choice ,neurologicalLevelC7Text: data.examination.neurologicalLevelC7Text ,
        neurologicalLevelC8Choice: data.examination.neurologicalLevelC8Choice ,neurologicalLevelC8Text: data.examination.neurologicalLevelC8Text ,neurologicalLevelT1Choice: data.examination.neurologicalLevelT1Choice ,neurologicalLevelT1Text: data.examination.neurologicalLevelT1Text ,neurologicalLevelT2Choice: data.examination.neurologicalLevelT2Choice ,neurologicalLevelT2Text: data.examination.neurologicalLevelT2Text ,neurologicalLevelT3Text: data.examination.neurologicalLevelT3Text ,neurologicalLevelT4Choice: data.examination.neurologicalLevelT4Choice ,neurologicalLevelT4Text: data.examination.neurologicalLevelT4Text ,neurologicalLevelT5Choice: data.examination.neurologicalLevelT5Choice ,neurologicalLevelT5Text: data.examination.neurologicalLevelT5Text ,neurologicalLevelT6Choice: data.examination.neurologicalLevelT6Choice ,neurologicalLevelT6Text: data.examination.neurologicalLevelT6Text ,neurologicalLevelT7Choice: data.examination.neurologicalLevelT7Choice ,neurologicalLevelT7Text: data.examination.neurologicalLevelT7Text ,neurologicalLevelT8Choice: data.examination.neurologicalLevelT8Choice ,neurologicalLevelT8Text: data.examination.neurologicalLevelT8Text ,neurologicalLevelT9Choice: data.examination.neurologicalLevelT9Choice ,neurologicalLevelT9Text: data.examination.neurologicalLevelT9Text ,
        neurologicalLevelT10Choice: data.examination.neurologicalLevelT10Choice ,neurologicalLevelT10Text: data.examination.neurologicalLevelT10Text ,neurologicalLevelT11Choice: data.examination.neurologicalLevelT11Choice ,neurologicalLevelT11Text: data.examination.neurologicalLevelT11Text ,neurologicalLevelT12Choice: data.examination.neurologicalLevelT12Choice ,neurologicalLevelT12Text: data.examination.neurologicalLevelT12Text ,neurologicalLevelL1Choice: data.examination.neurologicalLevelL1Choice ,neurologicalLevelL1Text: data.examination.neurologicalLevelL1Text ,neurologicalLevelL2Choice: data.examination.neurologicalLevelL2Choice ,neurologicalLevelL2Text: data.examination.neurologicalLevelL2Text ,neurologicalLevelL3Choice: data.examination.neurologicalLevelL3Choice ,neurologicalLevelL3Text: data.examination.neurologicalLevelL3Text ,neurologicalLevelL4Choice: data.examination.neurologicalLevelL4Choice ,neurologicalLevelL4Text: data.examination.neurologicalLevelL4Text ,neurologicalLevelL5Choice: data.examination.neurologicalLevelL5Choice ,neurologicalLevelL5Text: data.examination.neurologicalLevelL5Text ,neurologicalLevelS1Choice: data.examination.neurologicalLevelS1Choice ,neurologicalLevelS1Text: data.examination.neurologicalLevelS1Text ,neurologicalLevelS3Choice: data.examination.neurologicalLevelS3Choice ,
        neurologicalLevelS3Text: data.examination.neurologicalLevelS3Text ,neurologicalLevelS45Choice: data.examination.neurologicalLevelS45Choice ,neurologicalLevelS45Text: data.examination.neurologicalLevelS45Text , findingsEx: data.examination.findings, examinationOther: data.examination.other,
        allergies: data.allergies,  planData: data.planData, chronicData: data.chronicData
    });
     }
    closePopup() {
        document.getElementById('neurosurgeryAction').style = 'display:none'
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurosurgerysummaryview/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurosurgerysummaryviewconsolidated/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showTreatmentRecord(patientId) {

        this.setState({ treatmentRecords: [] })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurosurgerytreatmentlistview/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ treatmentRecords: res.data });
                document.getElementById('treatmentRecord').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }

    treatmentClosePopup() {
        document.getElementById('treatmentRecord').style = 'display:none'
    }
    viewResults() {
        document.getElementById('resultPopup').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension });
        document.getElementById('showFileNurseAction').style = 'display:block'

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    showOldNurse(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                //   this.setState({ htmlText: res.data, popupName: 'Nurse History'});
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    toggleTabs(name) {
        switch (name) {
            case "diagnosis":
                this.setState({ diagnosisTab: true, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false, feedbackLetter: false })
                break
            case "examination":
                this.setState({ diagnosisTab: false, history: false, examination: true, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "investigation":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: true, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "treatment":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: true, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "feedbackLetter":
                    this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: true})
                    break
            case "rehabilitation":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: true, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "referral":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: true, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "medicalCertificate":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: true, review: false, plan: false, graphical: false, feedbackLetter: false })
                break
            case "review":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: true, plan: false, graphical: false, feedbackLetter: false })
                break
            case "plan":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: true, graphical: false, feedbackLetter: false })
                break
            case "graphical":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: true, feedbackLetter: false })
                break
            default:
                this.setState({ diagnosisTab: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false, feedbackLetter: false })
                break

        }
    }
    addReviewTable(reviewDate, reviewTime) {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: reviewDate, reviewTime: reviewTime, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }

    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addRehabilitationTable() {
        let list = this.state.rehabilitations.slice();
        list.push({ id: 0, type: '', remarks: '', activeStatus: 1 });
        this.setState({ rehabilitations: list });
    }
    addInvestigationTable() {
        let list = this.state.investigations.slice();
        list.push({ id: 0, name: '', activeStatus: 1 });
        this.setState({ investigations: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addTreatmentTable() {
        let list = this.state.treatments.slice();
        list.push({ id: 0, dosage: '', drugName: '', formulationName: '', frequencyName: '', routeName: 'po', durationName: '', repeats: '', activeStatus: 1 });
        this.setState({ treatments: list });
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
    deleteRehabilitation(index) {
        let list = this.state.rehabilitations.slice();
        list[index].activeStatus = 0
        this.setState({ rehabilitations: list });
    }
    deleteInvestigation(index) {
        let list = this.state.investigations.slice();
        list[index].activeStatus = 0
        this.setState({ investigations: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteTreatment(index) {
        let list = this.state.treatments.slice();
        list[index].activeStatus = 0
        this.setState({ treatments: list });
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setRehabilitation(index, name, value) {
        let list = this.state.rehabilitations.slice();
        switch (name) {
            case "type":
                list[index].type = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ rehabilitations: list });
    }
    setInvestigation(index, value) {
        let list = this.state.investigations.slice();
        for (var i in this.state.services) {
            if (this.state.services[i].value === value) {
                list[index].name = value
                list[index].speciality = this.state.services[i].speciality
            }
        }
        this.setState({ investigations: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setTreatment(index, name, value) {
        let list = this.state.treatments.slice();
        switch (name) {
            case "dosage":
                list[index].dosage = value
                break;
            case "drugName":
                list[index].drugName = value
                break;
            case "formulationName":
                list[index].formulationName = value
                break;
            case "frequencyName":
                list[index].frequencyName = value
                break;
            case "routeName":
                list[index].routeName = value
                break;
            case "durationName":
                list[index].durationName = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ treatments: list });
    }

    toggleHistoryTabs(name) {
        switch (name) {
            case "historyOfPresentingComplaintsT":
                this.setState({ historyOfPresentingComplaintsT: true, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "systemicReviewT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: true, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "socialHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: true, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "familyHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: true, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "commonSymptomsT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: true, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "git":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: true, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "gus":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: true, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "gynae":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: true, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "cvs":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: true, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "cns":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: true, constitutional: false, mss: false, dermatology: false })
                break
            case "constitutional":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: true, mss: false, dermatology: false })
                break
            case "mss":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: true, dermatology: false })
                break
            case "dermatology":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: true })
                break
            case "respiratory":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: true, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "pastMedicalHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: true, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "currentMedicationT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: true, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            case "allergyHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: true, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break
            default:
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: true, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false })
                break

        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    setFeedbackItem(index, value)
     {
        let list = this.state.dateLists.slice();
        list[index].checked = value
        this.setState({ dateLists: list }); 
     }
    updateAfterSave(visitId, results) {
        if (results.length > 0) {
            this.setState({ results: results })
        }
        else {
            axios.get(localStorage.getItem('host') + '/patientmanagement/neurosurgerynursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors });
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }
    }
    openGraphAction(type) {
        this.setState({ line: { labels: [], datasets: [], }, lineName: type === "HEIGHT" ? "Height Graph" : "Weight Graph" })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/getgraphdata/' + this.state.patientId + '/' + type, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ line: res.data });
                document.getElementById('graphPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
            }
        )
    }
    graphClosePopup() {
        this.setState({ line: { labels: [], datasets: [], }, lineName: '' });
        document.getElementById('graphPopup').style = 'display:none'
    }
    showHideFromCheckBox(checked, id) {
        document.getElementById(id).style = 'display:' + (checked === true ? 'block' : 'none')
    }
    insertPresentingComplaintList()
    {
        let list = this.state.presentingComplaintList.slice();
        list.push({complaint: '', duration: ''})
        this.setState({ presentingComplaintList: list });  
    }
    setPresentingComplaintList(index, name, value) {
        let list = this.state.presentingComplaintList.slice();
        switch (name) {
            case "complaint":
                list[index].complaint = value
                break;
            default:
                list[index].duration = value
                break;
        }
        this.setState({ presentingComplaintList: list });
    }
    deletePresentingComplaint(i)
    {
    let list = this.state.presentingComplaintList.slice();   
    list.splice(i, 1);
    this.setState({ presentingComplaintList: list }); 
    }
    setLoader(loader)
      {
       this.setState({loader: loader})
      }
      getReviewTime(reviewTime)
         {
          var time = ''
          for(var j in this.state.appointmentTimes)
          {
           if(Number(reviewTime) === Number(this.state.appointmentTimes[j].id))
              {
               time = this.state.appointmentTimes[j].name
              }
          }
          return time
         }   
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="neurosurgeryAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Neurosurgery Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="treatmentRecord" class="page-popup page-popup-overlay"><div class="card" >
                    <div class="card-header   bg-info">Treatment Record</div>
                    <div class="card-body" >
                        {this.state.treatmentRecords.map((item, index) => (
                            <div class="row" key={index} >
                                <div class="col-xs-12 col-md-12 col-lg-12">
                                    <div class="card border-success">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col"><span className="fw-bold">Date:</span> {item.treatmentDateTime}</div>
                                                <div className="col"><span className="fw-bold"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped" >
                                        <tr>
                                            <th>Drug</th>
                                            <th>Dosage</th>
                                            <th>Frequency</th>
                                            <th>Route</th>
                                            <th>Duration</th>
                                            <th>Formulation</th>
                                            <th>Repeats</th>
                                        </tr>
                                        <tbody>
                                            {item.treatments.map((treatment, i) => (
                                                <tr key={i} >
                                                    <td>{treatment.drugName}</td>
                                                    <td>{treatment.dosage}</td>
                                                    <td>{treatment.frequencyName}</td>
                                                    <td>{treatment.routeName}</td>
                                                    <td> {treatment.durationName}</td>
                                                    <td>{treatment.formulationName}</td>
                                                    <td>{treatment.repeats}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        <div class="col" align="center" ><input class="btn btn-primary" type='button' onClick={() => this.treatmentClosePopup()} value="Close" /></div>
                    </div>
                </div>
                </div>
                <div id="resultPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>Treatment Date</th>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                        <td>{item.visitDate}</td>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                        <iframe title="File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'NEURO', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        <div class="row">
                            <div class="col">
                                <ul class="nav nav-pills  d-flex flex-column" id="pills-tab" role="tablist">
                                    <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                                    <li className="nav-item"><span className={this.state.examination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examination") }}>Examination</span></li>
                                    <li className="nav-item"><span className={this.state.investigation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("investigation") }}>Investigations</span></li>
                                    <li className="nav-item"><span className={this.state.diagnosisTab ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("diagnosis") }}>Diagnosis</span></li>
                                    <li className="nav-item"><span className={this.state.treatment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatment") }}>Prescription</span></li>
                                    <li className="nav-item"><span className={this.state.feedbackLetter ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("feedbackLetter") }}>Feedback Letter</span></li>
                                    <li className="nav-item"><span className={this.state.rehabilitation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("rehabilitation") }}>Rehabilitation</span></li>
                                    <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                                    <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                                    <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                                    <li className="nav-item"><span className={this.state.graphical ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("graphical") }}>Graphical</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.results.length > 0 && (<li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>)}
                                    <li><span className="link" onClick={() => this.showTreatmentRecord(this.state.patientId)}>View Prescription Records</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Referring Doctor:</span> {this.state.referringDoctorName}</div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Bp</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>SpO2 (%)</th>
                                        <th>Resp Rate</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitalColors.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.vitalTime}</td>
                                                <td style={{ color: item.temperatureColor }}>{item.temperature}</td>
                                                <td><span style={{ color: item.systolicColor }}>{item.systolic}</span>/<span style={{ color: item.diastolicColor }}>{item.diastolic}</span></td>
                                                <td style={{ color: item.pulseColor }}>{item.pulse}</td>
                                                <td style={{ color: item.glucometerColor }}>{item.glucometer}</td>
                                                <td style={{ color: item.spO2Color }}>{item.spO2}</td>
                                                <td style={{ color: item.respRateColor }}>{item.respRate}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><div className="input-group">
                                        <span className="input-group-text">Problems</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea> </div>
                                    </div>
                                    <div className="col"><div className="input-group">
                                        <span className="input-group-text">Chronic Conditions</span><textarea className="form-control" value={this.state.chronicData} onChange={e => { this.setState({ chronicData: e.target.value }) }} ></textarea> </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Plan</span>
                                        <textarea className="form-control" value={this.state.planData} onChange={e => this.setState({ planData: e.target.value })} ></textarea></div></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                            <div className="col" align="right"><button onClick={() => this.props.openNurseAction(this.state.visitId)} className="btn btn-primary">Edit Nurse Details</button></div>
                            <div className="col" align="left"><button onClick={() => this.props.getResultAction(this.state.visitId)} className="btn btn-primary">Upload Results</button></div>
                          { localStorage.getItem('nuero_data') !== null && localStorage.getItem('nuero_data') !== "" && localStorage.getItem('nuero_data').length > 5 &&( <div className="col" align="left"><button onClick={() => this.getFromLocalStorage()} className="btn btn-primary">Retrive From Temp</button></div>)}
                        </div>
                        <br />
                        <div className="tab-content" id="myTabContent">
                            <div className={this.state.diagnosisTab ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Diagnosis
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xs-12 col-md-12 col-lg-12">
                                                <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col"><div className="input-group">
                                                <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                                <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                            </div>
                                            </div>
                                            </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                     <option value="">Choose Diagnosis</option>
                                        {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                        <table className="table">
                                            <tr>
                                                <th>Description</th>
                                                <th>Active Status</th>
                                            </tr>
                                            {this.state.patientDiagnosis.map((item, index) => (item.activeStatus === 1 && (
                                                <tr>
                                                    <td>{item.description}</td>
                                                    <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                </tr>)))}
                                        </table>
                                    </div></div>
                            </div>
                            <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        History
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.presentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaintsT") }}>PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.historyOfPresentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("historyOfPresentingComplaintsT") }}>HISTORY OF PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.systemicReviewT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("systemicReviewT") }}>SYSTEMIC REVIEW</span></li>
                                            <li className="nav-item"><span className={this.state.pastMedicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pastMedicalHistoryT") }}>PAST MEDICAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.allergyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("allergyHistoryT") }}>DRUG AND ALLERGY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.socialHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("socialHistoryT") }}>SOCIAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.familyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("familyHistoryT") }}>FAMILY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.commonSymptomsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("commonSymptomsT") }}>COMMON SYMPTOMS</span></li>
                                            <li className="nav-item"><span className={this.state.respiratory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("respiratory") }}>RESPIRATORY</span></li>
                                            <li className="nav-item"><span className={this.state.git ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("git") }}>GIT</span></li>
                                            <li className="nav-item"><span className={this.state.gus ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("gus") }}>GUS</span></li>
                                            <li className="nav-item"><span className={this.state.gynae ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("gynae") }}>GYNAE/OBS</span></li>
                                            <li className="nav-item"><span className={this.state.cvs ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("cvs") }}>CVS</span></li>
                                            <li className="nav-item"><span className={this.state.cns ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("cns") }}>CNS</span></li>
                                            <li className="nav-item"><span className={this.state.constitutional ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("constitutional") }}>CONSTITUTIONAL</span></li>
                                            <li className="nav-item"><span className={this.state.mss ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("mss") }}>MSS</span></li>
                                            <li className="nav-item"><span className={this.state.dermatology ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("dermatology") }}>DERMATOLOGY</span></li>
                                        </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.historyOfPresentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.historyOfPresentingComplaints} onChange={e => { this.setState({ historyOfPresentingComplaints: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.pastMedicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.pastMedicalHistory} onChange={e => { this.setState({ pastMedicalHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.presentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <table class="table" >
                                                    <tr>
                                                        <th>Complaint</th>
                                                        <th>Duration</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tbody>
                                                        {this.state.presentingComplaintList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td><input type="text" className="form-control" value={item.complaint} onChange={e => { this.setPresentingComplaintList(index, 'complaint', e.target.value); }} /></td>
                                                                <td><input type="text" className="form-control" value={item.duration} onChange={e => { this.setPresentingComplaintList(index, 'duration', e.target.value); }} /></td>
                                                                <td><span className="link-red" onClick={() => this.deletePresentingComplaint(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div class="row">
                                                    <div class="col-lg-12 form-inline"><input type="button" onClick={() => this.insertPresentingComplaintList()} class="btn btn-success" value="Insert New Line" /></div>
                                                </div>
                                            </div>
                                            <div className={this.state.systemicReviewT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.systemicReview} onChange={e => { this.setState({ systemicReview: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.socialHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.socialHistory} onChange={e => { this.setState({ socialHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.familyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.familyHistory} onChange={e => { this.setState({ familyHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.commonSymptomsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain ?/10 </span><input type="text" value={this.state.painText} onChange={e => this.setState({ painText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Frequency </span> <input type="text" value={this.state.frequencyText} onChange={e => this.setState({ frequencyText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Duration </span> <input type="text" value={this.state.durationText} onChange={e => this.setState({ durationText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Timing </span><input type="text" value={this.state.timingText} onChange={e => this.setState({ timingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Aggravated By</span> <input type="text" value={this.state.aggravatedByText} onChange={e => this.setState({ aggravatedByText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Relieved By </span><input type="text" value={this.state.relievedByText} onChange={e => this.setState({ relievedByText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheChoice === "1"} onChange={e => this.setState({ headacheChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Headache </span> <input type="text" value={this.state.headacheText} onChange={e => this.setState({ headacheText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.headacheChoice === "1" ? 'block' : 'none' }} ><div class="row">   <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheLeftChoice === "1"} onChange={e => this.setState({ headacheLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Headache Left </span> <input type="text" value={this.state.headacheLeftText} onChange={e => this.setState({ headacheLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheRightChoice === "1"} onChange={e => this.setState({ headacheRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right </span><input type="text" value={this.state.headacheRightText} onChange={e => this.setState({ headacheRightText: e.target.value })} className="form-control" /></div></div>

                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.holocranialChoice === "1"} onChange={e => this.setState({ holocranialChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Holocranial</span>  <input type="text" value={this.state.holocranialText} onChange={e => this.setState({ holocranialText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.frontalChoice === "1"} onChange={e => this.setState({ frontalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Frontal</span> <input type="text" value={this.state.frontalText} onChange={e => this.setState({ frontalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.temporalChoice === "1"} onChange={e => this.setState({ temporalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Temporal</span> <input type="text" value={this.state.temporalText} onChange={e => this.setState({ temporalText: e.target.value })} className="form-control" /></div></div>

                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.occipitalChoice === "1"} onChange={e => this.setState({ occipitalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Occipital</span> <input type="text" value={this.state.occipitalText} onChange={e => this.setState({ occipitalText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.periorbitalChoice === "1"} onChange={e => this.setState({ periorbitalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Periorbital</span>  <input type="text" value={this.state.periorbitalText} onChange={e => this.setState({ periorbitalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.confusionChoice === "1"} onChange={e => this.setState({ confusionChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Confusion </span> <input type="text" value={this.state.confusionText} onChange={e => this.setState({ confusionText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vomitingChoice === "1"} onChange={e => this.setState({ vomitingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Vomiting  </span> <input type="text" value={this.state.vomitingText} onChange={e => this.setState({ vomitingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.changeinCharacterChoice === "1"} onChange={e => this.setState({ changeinCharacterChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Change in Character </span><input type="text" value={this.state.changeinCharacterText} onChange={e => this.setState({ changeinCharacterText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.weaknessChoice === "1"} onChange={e => this.setState({ weaknessChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Weakness</span> <input type="text" value={this.state.weaknessText} onChange={e => this.setState({ weaknessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.weaknessChoice === "1" ? 'block' : 'none' }}>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiparesisLeftChoice === "1"} onChange={e => this.setState({ hemiparesisLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hemiparesis Left</span> <input type="text" value={this.state.hemiparesisLeftText} onChange={e => this.setState({ hemiparesisLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiparesisRightChoice === "1"} onChange={e => this.setState({ hemiparesisRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hemiparesis Right</span> <input type="text" value={this.state.hemiparesisRightText} onChange={e => this.setState({ hemiparesisRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.weaknessHemiplegiaChoice === "1"} onChange={e => this.setState({ weaknessHemiplegiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Weakness Hemiplegia</span>  <input type="text" value={this.state.weaknessHemiplegiaText} onChange={e => this.setState({ weaknessHemiplegiaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiplegiaLeftChoice === "1"} onChange={e => this.setState({ hemiplegiaLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hemiplegia Left</span>  <input type="text" value={this.state.hemiplegiaLeftText} onChange={e => this.setState({ hemiplegiaLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiplegiaRightChoice === "1"} onChange={e => this.setState({ hemiplegiaRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hemiplegia Right </span>  <input type="text" value={this.state.hemiplegiaRightText} onChange={e => this.setState({ hemiplegiaRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraparesisChoice === "1"} onChange={e => this.setState({ paraparesisChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Paraparesis</span>  <input type="text" value={this.state.paraparesisText} onChange={e => this.setState({ paraparesisText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraplegiaChoice === "1"} onChange={e => this.setState({ paraplegiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paraplegia</span>   <input type="text" value={this.state.paraplegiaText} onChange={e => this.setState({ paraplegiaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.quadriparesisChoice === "1"} onChange={e => this.setState({ quadriparesisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Quadriparesis </span> <input type="text" value={this.state.quadriparesisText} onChange={e => this.setState({ quadriparesisText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.quadriplegiaChoice === "1"} onChange={e => this.setState({ quadriplegiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Quadriplegia </span> <input type="text" value={this.state.quadriplegiaText} onChange={e => this.setState({ quadriplegiaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisChoice === "1"} onChange={e => this.setState({ monoparesisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis </span>  <input type="text" value={this.state.monoparesisText} onChange={e => this.setState({ monoparesisText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisLeftChoice === "1"} onChange={e => this.setState({ monoparesisLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Monoparesis Left</span>  <input type="text" value={this.state.monoparesisLeftText} onChange={e => this.setState({ monoparesisLeftText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisRightChoice === "1"} onChange={e => this.setState({ monoparesisRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis Right</span>  <input type="text" value={this.state.monoparesisRightText} onChange={e => this.setState({ monoparesisRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisUpperLimbChoice === "1"} onChange={e => this.setState({ monoparesisUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis Upper Limb</span>  <input type="text" value={this.state.monoparesisUpperLimbText} onChange={e => this.setState({ monoparesisUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisLowerLimbChoice === "1"} onChange={e => this.setState({ monoparesisLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis Lower Limb</span>  <input type="text" value={this.state.monoparesisLowerLimbText} onChange={e => this.setState({ monoparesisLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.seizuresChoice === "1"} onChange={e => this.setState({ seizuresChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Seizures </span> <input type="text" value={this.state.seizuresText} onChange={e => this.setState({ seizuresText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.seizuresChoice === "1" ? 'block' : 'none' }} > <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.seizuresAuraChoice === "1"} onChange={e => this.setState({ seizuresAuraChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Seizures Aura </span> <input type="text" value={this.state.seizuresAuraText} onChange={e => this.setState({ seizuresAuraText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.auraVisualChoice === "1"} onChange={e => this.setState({ auraVisualChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Aura Visual </span>  <input type="text" value={this.state.auraVisualText} onChange={e => this.setState({ auraVisualText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.auraOlfactoryChoice === "1"} onChange={e => this.setState({ auraOlfactoryChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Aura Olfactory</span> <input type="text" value={this.state.auraOlfactoryText} onChange={e => this.setState({ auraOlfactoryText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.auraAutonomicChoice === "1"} onChange={e => this.setState({ auraAutonomicChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Aura Autonomic </span>  <input type="text" value={this.state.auraAutonomicText} onChange={e => this.setState({ auraAutonomicText: e.target.value })} className="form-control" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.backacheChoice === "1"} onChange={e => this.setState({ backacheChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache </span> <input type="text" value={this.state.backacheText} onChange={e => this.setState({ backacheText: e.target.value })} className="form-control" /></div> </div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.backacheChoice === "1" ? 'block' : 'none' }} > <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.thoracicChoice === "1"} onChange={e => this.setState({ thoracicChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thoracic </span> <input type="text" value={this.state.thoracicText} onChange={e => this.setState({ thoracicText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lumbarChoice === "1"} onChange={e => this.setState({ lumbarChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lumbar </span>  <input type="text" value={this.state.lumbarText} onChange={e => this.setState({ lumbarText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lowerBackacheChoice === "1"} onChange={e => this.setState({ lowerBackacheChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Lower Backache</span> <input type="text" value={this.state.lowerBackacheText} onChange={e => this.setState({ lowerBackacheText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.mechanicalChoice === "1"} onChange={e => this.setState({ mechanicalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Mechanical </span>  <input type="text" value={this.state.mechanicalText} onChange={e => this.setState({ mechanicalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sciaticaChoice === "1"} onChange={e => this.setState({ sciaticaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sciatica </span> <input type="text" value={this.state.sciaticaText} onChange={e => this.setState({ sciaticaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.sciaticaChoice === "1" ? 'block' : 'none' }} > <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sciaticaLeftChoice === "1"} onChange={e => this.setState({ sciaticaLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left </span> <input type="text" value={this.state.sciaticaLeftText} onChange={e => this.setState({ sciaticaLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sciaticaRightChoice === "1"} onChange={e => this.setState({ sciaticaRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right </span>  <input type="text" value={this.state.sciaticaRightText} onChange={e => this.setState({ sciaticaRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.cervicalPainChoice === "1"} onChange={e => this.setState({ cervicalPainChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Cervical Pain </span>  <input type="text" value={this.state.cervicalPainText} onChange={e => this.setState({ cervicalPainText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.interScapularPainChoice === "1"} onChange={e => this.setState({ interScapularPainChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Inter Scapular Pain </span>  <input type="text" value={this.state.interScapularPainText} onChange={e => this.setState({ interScapularPainText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaChoice === "1"} onChange={e => this.setState({ paraesthesiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Paraesthesia </span> <input type="text" value={this.state.paraesthesiaText} onChange={e => this.setState({ paraesthesiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.paraesthesiaChoice === "1" ? 'block' : 'none' }}> <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaUpperLimbChoice === "1"} onChange={e => this.setState({ paraesthesiaUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Upper Limb </span> <input type="text" value={this.state.paraesthesiaUpperLimbText} onChange={e => this.setState({ paraesthesiaUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaLowerLimbChoice === "1"} onChange={e => this.setState({ paraesthesiaLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lower Limb </span>  <input type="text" value={this.state.paraesthesiaLowerLimbText} onChange={e => this.setState({ paraesthesiaLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lossOfBalanceChoice === "1"} onChange={e => this.setState({ lossOfBalanceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Loss of balance </span>  <input type="text" value={this.state.lossOfBalanceText} onChange={e => this.setState({ lossOfBalanceText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurogenicClaudicationChoice === "1"} onChange={e => this.setState({ neurogenicClaudicationChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Neurogenic Claudication </span>  <input type="text" value={this.state.neurogenicClaudicationText} onChange={e => this.setState({ neurogenicClaudicationText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyChoice === "1"} onChange={e => this.setState({ radiculapathyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Radiculopathy </span> <input type="text" value={this.state.radiculapathyText} onChange={e => this.setState({ radiculapathyText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.radiculapathyChoice === "1" ? 'block' : 'none' }}> <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftChoice === "1"} onChange={e => this.setState({ radiculapathyLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.radiculapathyLeftText} onChange={e => this.setState({ radiculapathyLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="card border-warning" style={{ display: this.state.radiculapathyLeftChoice === "1" ? 'block' : 'none' }}>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC2Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C2 </span> <input type="text" value={this.state.radiculapathyLeftC2Text} onChange={e => this.setState({ radiculapathyLeftC2Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C3 </span> <input type="text" value={this.state.radiculapathyLeftC3Text} onChange={e => this.setState({ radiculapathyLeftC3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC4Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C4 </span> <input type="text" value={this.state.radiculapathyLeftC4Text} onChange={e => this.setState({ radiculapathyLeftC4Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC5Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5 </span> <input type="text" value={this.state.radiculapathyLeftC5Text} onChange={e => this.setState({ radiculapathyLeftC5Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC6Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C6 </span> <input type="text" value={this.state.radiculapathyLeftC6Text} onChange={e => this.setState({ radiculapathyLeftC6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC7Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C7 </span> <input type="text" value={this.state.radiculapathyLeftC7Text} onChange={e => this.setState({ radiculapathyLeftC7Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC8Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 </span> <input type="text" value={this.state.radiculapathyLeftC8Text} onChange={e => this.setState({ radiculapathyLeftC8Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT1Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T1 </span> <input type="text" value={this.state.radiculapathyLeftT1Text} onChange={e => this.setState({ radiculapathyLeftT1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT2Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T2 </span> <input type="text" value={this.state.radiculapathyLeftT2Text} onChange={e => this.setState({ radiculapathyLeftT2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T3 </span> <input type="text" value={this.state.radiculapathyLeftT3Text} onChange={e => this.setState({ radiculapathyLeftT3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT4Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T4 </span> <input type="text" value={this.state.radiculapathyLeftT4Text} onChange={e => this.setState({ radiculapathyLeftT4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT5Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T5 </span> <input type="text" value={this.state.radiculapathyLeftT5Text} onChange={e => this.setState({ radiculapathyLeftT5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT6Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T6 </span> <input type="text" value={this.state.radiculapathyLeftT6Text} onChange={e => this.setState({ radiculapathyLeftT6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT7Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T7 </span> <input type="text" value={this.state.radiculapathyLeftT7Text} onChange={e => this.setState({ radiculapathyLeftT7Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT8Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T8 </span> <input type="text" value={this.state.radiculapathyLeftT8Text} onChange={e => this.setState({ radiculapathyLeftT8Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT9Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT9Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T9 </span> <input type="text" value={this.state.radiculapathyLeftT9Text} onChange={e => this.setState({ radiculapathyLeftT9Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT10Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT10Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T10 </span> <input type="text" value={this.state.radiculapathyLeftT10Text} onChange={e => this.setState({ radiculapathyLeftT10Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT11Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT11Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T11 </span> <input type="text" value={this.state.radiculapathyLeftT11Text} onChange={e => this.setState({ radiculapathyLeftT11Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT12Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT12Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T12 </span> <input type="text" value={this.state.radiculapathyLeftT12Text} onChange={e => this.setState({ radiculapathyLeftT12Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL1Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L1 </span> <input type="text" value={this.state.radiculapathyLeftL1Text} onChange={e => this.setState({ radiculapathyLeftL1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL2Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L2 </span> <input type="text" value={this.state.radiculapathyLeftL2Text} onChange={e => this.setState({ radiculapathyLeftL2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3 </span> <input type="text" value={this.state.radiculapathyLeftL3Text} onChange={e => this.setState({ radiculapathyLeftL3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL4Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L4 </span> <input type="text" value={this.state.radiculapathyLeftL4Text} onChange={e => this.setState({ radiculapathyLeftL4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL5Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L5 </span> <input type="text" value={this.state.radiculapathyLeftL5Text} onChange={e => this.setState({ radiculapathyLeftL5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftS1Choice === "1"} onChange={e => this.setState({ radiculapathyLeftS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1 </span> <input type="text" value={this.state.radiculapathyLeftS1Text} onChange={e => this.setState({ radiculapathyLeftS1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftS3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftS3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S3 </span> <input type="text" value={this.state.radiculapathyLeftS3Text} onChange={e => this.setState({ radiculapathyLeftS3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftS45Choice === "1"} onChange={e => this.setState({ radiculapathyLeftS45Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S4/5 </span> <input type="text" value={this.state.radiculapathyLeftS45Text} onChange={e => this.setState({ radiculapathyLeftS45Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightChoice === "1"} onChange={e => this.setState({ radiculapathyRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right </span>  <input type="text" value={this.state.radiculapathyRightText} onChange={e => this.setState({ radiculapathyRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.radiculapathyRightChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC2Choice === "1"} onChange={e => this.setState({ radiculapathyRightC2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C2 </span> <input type="text" value={this.state.radiculapathyRightC2Text} onChange={e => this.setState({ radiculapathyRightC2Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC3Choice === "1"} onChange={e => this.setState({ radiculapathyRightC3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C3 </span> <input type="text" value={this.state.radiculapathyRightC3Text} onChange={e => this.setState({ radiculapathyRightC3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC4Choice === "1"} onChange={e => this.setState({ radiculapathyRightC4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C4 </span> <input type="text" value={this.state.radiculapathyRightC4Text} onChange={e => this.setState({ radiculapathyRightC4Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC5Choice === "1"} onChange={e => this.setState({ radiculapathyRightC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5 </span> <input type="text" value={this.state.radiculapathyRightC5Text} onChange={e => this.setState({ radiculapathyRightC5Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC6Choice === "1"} onChange={e => this.setState({ radiculapathyRightC6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C6 </span> <input type="text" value={this.state.radiculapathyRightC6Text} onChange={e => this.setState({ radiculapathyRightC6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC7Choice === "1"} onChange={e => this.setState({ radiculapathyRightC7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C7 </span> <input type="text" value={this.state.radiculapathyRightC7Text} onChange={e => this.setState({ radiculapathyRightC7Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC8Choice === "1"} onChange={e => this.setState({ radiculapathyRightC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 </span> <input type="text" value={this.state.radiculapathyRightC8Text} onChange={e => this.setState({ radiculapathyRightC8Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT1Choice === "1"} onChange={e => this.setState({ radiculapathyRightT1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T1 </span> <input type="text" value={this.state.radiculapathyRightT1Text} onChange={e => this.setState({ radiculapathyRightT1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT2Choice === "1"} onChange={e => this.setState({ radiculapathyRightT2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T2 </span> <input type="text" value={this.state.radiculapathyRightT2Text} onChange={e => this.setState({ radiculapathyRightT2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT3Choice === "1"} onChange={e => this.setState({ radiculapathyRightT3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T3 </span> <input type="text" value={this.state.radiculapathyRightT3Text} onChange={e => this.setState({ radiculapathyRightT3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT4Choice === "1"} onChange={e => this.setState({ radiculapathyRightT4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T4 </span> <input type="text" value={this.state.radiculapathyRightT4Text} onChange={e => this.setState({ radiculapathyRightT4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT5Choice === "1"} onChange={e => this.setState({ radiculapathyRightT5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T5 </span> <input type="text" value={this.state.radiculapathyRightT5Text} onChange={e => this.setState({ radiculapathyRightT5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT6Choice === "1"} onChange={e => this.setState({ radiculapathyRightT6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T6 </span> <input type="text" value={this.state.radiculapathyRightT6Text} onChange={e => this.setState({ radiculapathyRightT6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT7Choice === "1"} onChange={e => this.setState({ radiculapathyRightT7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T7 </span> <input type="text" value={this.state.radiculapathyRightT7Text} onChange={e => this.setState({ radiculapathyRightT7Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT8Choice === "1"} onChange={e => this.setState({ radiculapathyRightT8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T8 </span> <input type="text" value={this.state.radiculapathyRightT8Text} onChange={e => this.setState({ radiculapathyRightT8Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT9Choice === "1"} onChange={e => this.setState({ radiculapathyRightT9Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T9 </span> <input type="text" value={this.state.radiculapathyRightT9Text} onChange={e => this.setState({ radiculapathyRightT9Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT10Choice === "1"} onChange={e => this.setState({ radiculapathyRightT10Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T10 </span> <input type="text" value={this.state.radiculapathyRightT10Text} onChange={e => this.setState({ radiculapathyRightT10Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT11Choice === "1"} onChange={e => this.setState({ radiculapathyRightT11Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T11 </span> <input type="text" value={this.state.radiculapathyRightT11Text} onChange={e => this.setState({ radiculapathyRightT11Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT12Choice === "1"} onChange={e => this.setState({ radiculapathyRightT12Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T12 </span> <input type="text" value={this.state.radiculapathyRightT12Text} onChange={e => this.setState({ radiculapathyRightT12Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL1Choice === "1"} onChange={e => this.setState({ radiculapathyRightL1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L1 </span> <input type="text" value={this.state.radiculapathyRightL1Text} onChange={e => this.setState({ radiculapathyRightL1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL2Choice === "1"} onChange={e => this.setState({ radiculapathyRightL2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L2 </span> <input type="text" value={this.state.radiculapathyRightL2Text} onChange={e => this.setState({ radiculapathyRightL2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL3Choice === "1"} onChange={e => this.setState({ radiculapathyRightL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3 </span> <input type="text" value={this.state.radiculapathyRightL3Text} onChange={e => this.setState({ radiculapathyRightL3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL4Choice === "1"} onChange={e => this.setState({ radiculapathyRightL4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L4 </span> <input type="text" value={this.state.radiculapathyRightL4Text} onChange={e => this.setState({ radiculapathyRightL4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL5Choice === "1"} onChange={e => this.setState({ radiculapathyRightL5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L5 </span> <input type="text" value={this.state.radiculapathyRightL5Text} onChange={e => this.setState({ radiculapathyRightL5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightS1Choice === "1"} onChange={e => this.setState({ radiculapathyRightS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1 </span> <input type="text" value={this.state.radiculapathyRightS1Text} onChange={e => this.setState({ radiculapathyRightS1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightS3Choice === "1"} onChange={e => this.setState({ radiculapathyRightS3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S3 </span> <input type="text" value={this.state.radiculapathyRightS3Text} onChange={e => this.setState({ radiculapathyRightS3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightS45Choice === "1"} onChange={e => this.setState({ radiculapathyRightS45Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S4/5 </span> <input type="text" value={this.state.radiculapathyRightS45Text} onChange={e => this.setState({ radiculapathyRightS45Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.allergyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.allergyHistory} onChange={e => { this.setState({ allergyHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.respiratory ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.coughChoice === "1"} onChange={e => this.setState({ coughChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Cough </span><input type="text" value={this.state.coughText} onChange={e => this.setState({ coughText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.coryzaChoice === "1"} onChange={e => this.setState({ coryzaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Coryza </span> <input type="text" value={this.state.coryzaText} onChange={e => this.setState({ coryzaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sobChoice === "1"} onChange={e => this.setState({ sobChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> SOB </span> <input type="text" value={this.state.sobText} onChange={e => this.setState({ sobText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sneazingChoice === "1"} onChange={e => this.setState({ sneazingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sneezing </span><input type="text" value={this.state.sneazingText} onChange={e => this.setState({ sneazingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.phelgmChoice === "1"} onChange={e => this.setState({ phelgmChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Phelgm</span> <input type="text" value={this.state.phelgmText} onChange={e => this.setState({ phelgmText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sputumChoice === "1"} onChange={e => this.setState({ sputumChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Sputum </span><input type="text" value={this.state.sputumText} onChange={e => this.setState({ sputumText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.haemoptysisChoice === "1"} onChange={e => this.setState({ haemoptysisChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Haemoptysis </span> <input type="text" value={this.state.haemoptysisText} onChange={e => this.setState({ haemoptysisText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wheezingChoice === "1"} onChange={e => this.setState({ wheezingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Wheezing </span><input type="text" value={this.state.wheezingText} onChange={e => this.setState({ wheezingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.nasalChoice === "1"} onChange={e => this.setState({ nasalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Nasal Congestion</span>  <input type="text" value={this.state.nasalText} onChange={e => this.setState({ nasalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.soreChoice === "1"} onChange={e => this.setState({ soreChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sore Throat</span> <input type="text" value={this.state.soreText} onChange={e => this.setState({ soreText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.chestPainChoice === "1"} onChange={e => this.setState({ chestPainChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Chest Pain</span> <input type="text" value={this.state.chestPainText} onChange={e => this.setState({ chestPainText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other</span> <textarea value={this.state.respitoryNotes} onChange={e => this.setState({ respitoryNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.git ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.nauseaChoice === "1"} onChange={e => this.setState({ nauseaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Nausea </span> <input type="text" value={this.state.nauseaText} onChange={e => this.setState({ nauseaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vomitingChoice === "1"} onChange={e => this.setState({ vomitingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Vomiting  </span> <input type="text" value={this.state.vomitingText} onChange={e => this.setState({ vomitingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.diarrhoeaChoice === "1"} onChange={e => this.setState({ diarrhoeaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Diarrhoea</span>  <input type="text" value={this.state.diarrhoeaText} onChange={e => this.setState({ diarrhoeaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abdominialChoice === "1"} onChange={e => this.setState({ abdominialChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Abdominial Pain </span>  <input type="text" value={this.state.abdominialText} onChange={e => this.setState({ abdominialText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.flatulanceChoice === "1"} onChange={e => this.setState({ flatulanceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Flatulance Lower </span> <input type="text" value={this.state.flatulanceText} onChange={e => this.setState({ flatulanceText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.flatulentChoice === "1"} onChange={e => this.setState({ flatulentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Flatulent Dyspepsia</span>  <input type="text" value={this.state.flatulentText} onChange={e => this.setState({ flatulentText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.waterChoice === "1"} onChange={e => this.setState({ waterChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Water Brush</span>  <input type="text" value={this.state.waterText} onChange={e => this.setState({ waterText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.constipationChoice === "1"} onChange={e => this.setState({ constipationChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Constipation</span>   <input type="text" value={this.state.constipationText} onChange={e => this.setState({ constipationText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.prChoice === "1"} onChange={e => this.setState({ prChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pr Bleeding </span> <input type="text" value={this.state.prText} onChange={e => this.setState({ prText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wtChoice === "1"} onChange={e => this.setState({ wtChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Wt Gain </span> <input type="text" value={this.state.wtText} onChange={e => this.setState({ wtText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.melenaChoice === "1"} onChange={e => this.setState({ melenaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Melena </span>  <input type="text" value={this.state.melenaText} onChange={e => this.setState({ melenaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.jaundiceChoice === "1"} onChange={e => this.setState({ jaundiceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Jaundice</span>  <input type="text" value={this.state.jaundiceText} onChange={e => this.setState({ jaundiceText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.loaChoice === "1"} onChange={e => this.setState({ loaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LOA</span>  <input type="text" value={this.state.loaText} onChange={e => this.setState({ loaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lowChoice === "1"} onChange={e => this.setState({ lowChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LOW</span>  <input type="text" value={this.state.lowText} onChange={e => this.setState({ lowText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span> <textarea value={this.state.gitNotes} onChange={e => this.setState({ gitNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.gus ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysuriaChoice === "1"} onChange={e => this.setState({ dysuriaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dysuria </span><input type="text" value={this.state.dysuriaText} onChange={e => this.setState({ dysuriaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.frequencyChoice === "1"} onChange={e => this.setState({ frequencyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Frequency </span> <input type="text" value={this.state.frequencyText} onChange={e => this.setState({ frequencyText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.haematuriaChoice === "1"} onChange={e => this.setState({ haematuriaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Haematuria </span> <input type="text" value={this.state.haematuriaText} onChange={e => this.setState({ haematuriaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.weakChoice === "1"} onChange={e => this.setState({ weakChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Weak Stream </span>  <input type="text" value={this.state.weakText} onChange={e => this.setState({ weakText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.strainingChoice === "1"} onChange={e => this.setState({ strainingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Straining </span> <input type="text" value={this.state.strainingText} onChange={e => this.setState({ strainingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.urineChoice === "1"} onChange={e => this.setState({ urineChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Urine Retention </span>  <input type="text" value={this.state.urineText} onChange={e => this.setState({ urineText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.urethalChoice === "1"} onChange={e => this.setState({ urethalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Urethral D/C  </span> <input type="text" value={this.state.urethalText} onChange={e => this.setState({ urethalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.pvChoice === "1"} onChange={e => this.setState({ pvChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">PV D/C  </span><input type="text" value={this.state.pvText} onChange={e => this.setState({ pvText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.nocturiaChoice === "1"} onChange={e => this.setState({ nocturiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Nocturia  </span> <input type="text" value={this.state.nocturiaText} onChange={e => this.setState({ nocturiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.genitalChoice === "1"} onChange={e => this.setState({ genitalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Genital Sores </span> <input type="text" value={this.state.genitalText} onChange={e => this.setState({ genitalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lossChoice === "1"} onChange={e => this.setState({ lossChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Loss Of Libido </span>  <input type="text" value={this.state.lossText} onChange={e => this.setState({ lossText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.erectileChoice === "1"} onChange={e => this.setState({ erectileChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Erectille Dysfunction </span> <input type="text" value={this.state.erectileText} onChange={e => this.setState({ erectileText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other  </span><textarea value={this.state.gusNotes} onChange={e => this.setState({ gusNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.gynae ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysaruniaChoice === "1"} onChange={e => this.setState({ dysaruniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dysarunia </span><input type="text" value={this.state.dysaruniaText} onChange={e => this.setState({ dysaruniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysmemorrhoeaChoice === "1"} onChange={e => this.setState({ dysmemorrhoeaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Dysmemorrhoea </span><input type="text" value={this.state.dysmemorrhoeaText} onChange={e => this.setState({ dysmemorrhoeaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.imbChoice === "1"} onChange={e => this.setState({ imbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">IMB  </span><input type="text" value={this.state.imbText} onChange={e => this.setState({ imbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.contactChoice === "1"} onChange={e => this.setState({ contactChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Contact Bleeding  </span><input type="text" value={this.state.contactText} onChange={e => this.setState({ contactText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.menorrhagiaChoice === "1"} onChange={e => this.setState({ menorrhagiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Menorrhagia </span> <input type="text" value={this.state.menorrhagiaText} onChange={e => this.setState({ menorrhagiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lapChoice === "1"} onChange={e => this.setState({ lapChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LAP  </span> <input type="text" value={this.state.lapText} onChange={e => this.setState({ lapText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.backacheChoice === "1"} onChange={e => this.setState({ backacheChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache  </span> <input type="text" value={this.state.backackeText} onChange={e => this.setState({ backackeText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.subfertilityChoice === "1"} onChange={e => this.setState({ subfertilityChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Subfertility </span> <input type="text" value={this.state.subfertilityText} onChange={e => this.setState({ subfertilityText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hotChoice === "1"} onChange={e => this.setState({ hotChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hot Flashes  </span><input type="text" value={this.state.hotText} onChange={e => this.setState({ hotText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lnmpChoice === "1"} onChange={e => this.setState({ lnmpChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LNMP </span>  <input type="text" value={this.state.lnmpText} onChange={e => this.setState({ lnmpText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.gynaeNotes} onChange={e => this.setState({ gynaeNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.cvs ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.orthopneaChoice === "1"} onChange={e => this.setState({ orthopneaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Orthopnea</span> <input type="text" value={this.state.orthopneaText} onChange={e => this.setState({ orthopneaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.pndChoice === "1"} onChange={e => this.setState({ pndChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">PND </span><input type="text" value={this.state.pndText} onChange={e => this.setState({ pndText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.easyChoice === "1"} onChange={e => this.setState({ easyChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Easy Fatigability</span> <input type="text" value={this.state.easyText} onChange={e => this.setState({ easyText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.palpitationsChoice === "1"} onChange={e => this.setState({ palpitationsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Palpitations</span>  <input type="text" value={this.state.palpitationsText} onChange={e => this.setState({ palpitationsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.synacopeChoice === "1"} onChange={e => this.setState({ synacopeChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Syncope</span>  <input type="text" value={this.state.synacopeText} onChange={e => this.setState({ synacopeText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.legSwellingChoice === "1"} onChange={e => this.setState({ legSwellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Leg Swelling</span> <input type="text" value={this.state.legSwellingText} onChange={e => this.setState({ legSwellingText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.chestPaincvsChoice === "1"} onChange={e => this.setState({ chestPaincvsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Central Chest Pain </span><input type="text" value={this.state.chestPaincvsText} onChange={e => this.setState({ chestPaincvsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.calfSwellingChoice === "1"} onChange={e => this.setState({ calfSwellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Calf Swelling</span> <input type="text" value={this.state.calfSwellingText} onChange={e => this.setState({ calfSwellingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.pinkFrothChoice === "1"} onChange={e => this.setState({ pinkFrothChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pink Froth</span> <input type="text" value={this.state.pinkFrothText} onChange={e => this.setState({ pinkFrothText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.gelatinousChoice === "1"} onChange={e => this.setState({ gelatinousChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Gelatinous Sputum</span> <input type="text" value={this.state.gelatinousText} onChange={e => this.setState({ gelatinousText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other</span>  <textarea value={this.state.cvsNotes} onChange={e => this.setState({ cvsNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.cns ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheChoice === "1"} onChange={e => this.setState({ headacheChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Headache</span><input type="text" value={this.state.headacheText} onChange={e => this.setState({ headacheText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaChoice === "1"} onChange={e => this.setState({ paraesthesiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paraesthesia</span><input type="text" value={this.state.paraesthesiaText} onChange={e => this.setState({ paraesthesiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dizzinessChoice === "1"} onChange={e => this.setState({ dizzinessChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dizziness</span><input type="text" value={this.state.dizzinessText} onChange={e => this.setState({ dizzinessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.backackeChoice === "1"} onChange={e => this.setState({ backackeChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache</span><input type="text" value={this.state.backacheText} onChange={e => this.setState({ backacheText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.photophibiaChoice === "1"} onChange={e => this.setState({ photophibiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Photophobia</span><input type="text" value={this.state.photophibiaText} onChange={e => this.setState({ photophibiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neckstiffnessChoice === "1"} onChange={e => this.setState({ neckstiffnessChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Neck Stiffness</span>  <input type="text" value={this.state.neckstiffnessText} onChange={e => this.setState({ neckstiffnessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fittingChoice === "1"} onChange={e => this.setState({ fittingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Fitting</span><input type="text" value={this.state.fittingText} onChange={e => this.setState({ fittingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paresisChoice === "1"} onChange={e => this.setState({ paresisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paresis </span><input type="text" value={this.state.paresisText} onChange={e => this.setState({ paresisText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paralysisChoice === "1"} onChange={e => this.setState({ paralysisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paralysis </span><input type="text" value={this.state.paralysisText} onChange={e => this.setState({ paralysisText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.insomniaChoice === "1"} onChange={e => this.setState({ insomniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Insomnia</span> <input type="text" value={this.state.insomniaText} onChange={e => this.setState({ insomniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hypersomniaChoice === "1"} onChange={e => this.setState({ hypersomniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hypersomnia</span><input type="text" value={this.state.hypersomniaText} onChange={e => this.setState({ hypersomniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abnChoice === "1"} onChange={e => this.setState({ abnChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> AbN Gait</span> <input type="text" value={this.state.abnText} onChange={e => this.setState({ abnText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.cnsNotes} onChange={e => this.setState({ cnsNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.constitutional ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.feverChoice === "1"} onChange={e => this.setState({ feverChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Fever</span><input type="text" value={this.state.feverText} onChange={e => this.setState({ feverText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sweatingChoice === "1"} onChange={e => this.setState({ sweatingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sweating</span><input type="text" value={this.state.sweatingText} onChange={e => this.setState({ sweatingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.chillsChoice === "1"} onChange={e => this.setState({ chillsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Chills</span><input type="text" value={this.state.chillsText} onChange={e => this.setState({ chillsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rigorsChoice === "1"} onChange={e => this.setState({ rigorsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Rigors </span> <input type="text" value={this.state.rigorsText} onChange={e => this.setState({ rigorsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fatigueChoice === "1"} onChange={e => this.setState({ fatigueChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Fatigue </span><input type="text" value={this.state.fatigueText} onChange={e => this.setState({ fatigueText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.myalgiaChoice === "1"} onChange={e => this.setState({ myalgiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Myalgia</span> <input type="text" value={this.state.myalgiaText} onChange={e => this.setState({ myalgiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.constitutionalNotes} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.mss ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.bleedingChoice === "1"} onChange={e => this.setState({ bleedingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Bleeding</span> <input type="text" value={this.state.bleedingText} onChange={e => this.setState({ bleedingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.bruisingChoice === "1"} onChange={e => this.setState({ bruisingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Bruising</span> <input type="text" value={this.state.bruisingText} onChange={e => this.setState({ bruisingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.swellingChoice === "1"} onChange={e => this.setState({ swellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Swelling</span> <input type="text" value={this.state.swellingText} onChange={e => this.setState({ swellingText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deformityChoice === "1"} onChange={e => this.setState({ deformityChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Deformity</span><input type="text" value={this.state.deformityText} onChange={e => this.setState({ deformityText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span> <textarea value={this.state.mssNotes} onChange={e => this.setState({ mssNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.dermatology ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rashChoice === "1"} onChange={e => this.setState({ rashChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Rash </span><input type="text" value={this.state.rashText} onChange={e => this.setState({ rashText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.itchynessChoice === "1"} onChange={e => this.setState({ itchynessChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Itchyness</span> <input type="text" value={this.state.itchynessText} onChange={e => this.setState({ itchynessText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.blistersChoice === "1"} onChange={e => this.setState({ blistersChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Blisters</span> <input type="text" value={this.state.blistersText} onChange={e => this.setState({ blistersText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lumpsChoice === "1"} onChange={e => this.setState({ lumpsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Lumps</span>  <input type="text" value={this.state.lumpsText} onChange={e => this.setState({ lumpsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ulcersChoice === "1"} onChange={e => this.setState({ ulcersChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Ulcers </span><input type="text" value={this.state.ulcersText} onChange={e => this.setState({ ulcersText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.swellingdemaChoice === "1"} onChange={e => this.setState({ swellingdemaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Swelling</span> <input type="text" value={this.state.swellingdemaText} onChange={e => this.setState({ swellingdemaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.dematologyNotes} onChange={e => this.setState({ dematologyNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.examination ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Physical and General Examination
                                    </div>
                                    <div className="card-body">
                                        <div class="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text"> EYE </span><input type="text" value={this.state.eyeText} onChange={e => { this.setState({ dematologyNotes: e.target.value }); this.calcGcs(e.target.value) }} class="form-control" /><span className="input-group-text"> /4 </span></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text"> MOTOR</span><input type="text" value={this.state.motorText} onChange={e => { this.setState({ dematologyNotes: e.target.value }); this.calcGcs(e.target.value) }} class="form-control" /><span className="input-group-text"> /6 </span></div> </div>
                                            <div className="col"><div className="input-group"><span className="input-group-text"> VERBAL</span> <input type="text" value={this.state.verbalText} onChange={e => { this.setState({ dematologyNotes: e.target.value }); this.calcGcs(e.target.value) }} class="form-control" /><span className="input-group-text"> /5 </span></div></div>
                                        </div>
                                        <div class="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text"> GCS</span> <input type="text" value={this.state.gcsText} onChange={e => this.setState({ gcsText: e.target.value })} class="form-control" /><span className="input-group-text"> /15 </span></div></div>
                                        </div>
                                        <div class="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dementiaChoice === "1"} onChange={e => this.setState({ dementiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">DEMENTIA</span> <input type="text" value={this.state.dementiaText} onChange={e => this.setState({ dementiaText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.tandemChoice === "1"} onChange={e => this.setState({ tandemChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> TANDEM WALKING</span><input type="text" value={this.state.tandemText} onChange={e => this.setState({ tandemText: e.target.value })} className="form-control" /></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysdiadochokinesiaChoice === "1"} onChange={e => this.setState({ dysdiadochokinesiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> DYSDIADOCHOKINESIA </span><input type="text" value={this.state.dysdiadochokinesiaText} onChange={e => this.setState({ dysdiadochokinesiaText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergChoice === "1"} onChange={e => this.setState({ rombergChoice: e.target.checked === true ? "1" : "0" })} onclick="showHideFromCheckBox(this, 'rombergSub')" /><span className="input-group-text"> ROMBERG TEST</span><input type="text" value={this.state.rombergText} onChange={e => this.setState({ rombergText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.rombergChoice === "1" ? 'block' : 'none' }}>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergPositiveChoice === "1"} onChange={e => this.setState({ rombergPositiveChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TEST POSITIVE </span><input type="text" value={this.state.rombergPostiveText} onChange={e => this.setState({ rombergPostiveText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergNegativeChoice === "1"} onChange={e => this.setState({ rombergNegativeChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TEST NEGATIVE </span><input type="text" value={this.state.rombergNegativeText} onChange={e => this.setState({ rombergNegativeText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ataxiaChoice === "1"} onChange={e => this.setState({ ataxiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">ATAXIA</span><input type="text" value={this.state.ataxiaText} onChange={e => this.setState({ ataxiaText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.ataxiaChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ataxiaTruncalChoice === "1"} onChange={e => this.setState({ ataxiaTruncalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TRUNCAL</span> <input type="text" value={this.state.ataxiaTruncalText} onChange={e => this.setState({ ataxiaTruncalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ataxiaAppendicularChoice === "1"} onChange={e => this.setState({ ataxiaAppendicularChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> APPENDICULAR</span><input type="text" value={this.state.ataxiaAppendicularText} onChange={e => this.setState({ ataxiaAppendicularText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ataxiaGlobalChoice === "1"} onChange={e => this.setState({ ataxiaGlobalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">GLOBAL</span><input type="text" value={this.state.ataxiaGlobalText} onChange={e => this.setState({ ataxiaGlobalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.nystagmusChoice === "1"} onChange={e => this.setState({ nystagmusChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">NYSTAGMUS</span> <input type="text" value={this.state.nystagmusText} onChange={e => this.setState({ nystagmusText: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysphasiaChoice === "1"} onChange={e => this.setState({ dysphasiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> DYSPHASIA</span><input type="text" value={this.state.dysphasiaText} onChange={e => this.setState({ dysphasiaText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.dysphasiaChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysphasiaExpressiveChoice === "1"} onChange={e => this.setState({ dysphasiaExpressiveChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">EXPRESSIVE</span><input type="text" value={this.state.dysphasiaExpressiveText} onChange={e => this.setState({ dysphasiaExpressiveText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysphasiaReceptiveChoice === "1"} onChange={e => this.setState({ dysphasiaReceptiveChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">RECEPTIVE</span> <input type="text" value={this.state.dysphasiaReceptiveText} onChange={e => this.setState({ dysphasiaReceptiveText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysphasiaGlobalChoice === "1"} onChange={e => this.setState({ dysphasiaGlobalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> GLOBAL</span><input type="text" value={this.state.dysphasiaGlobalText} onChange={e => this.setState({ dysphasiaGlobalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abuliaChoice === "1"} onChange={e => this.setState({ abuliaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">ABULIA</span><input type="text" value={this.state.abuliaText} onChange={e => this.setState({ abuliaText: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.astereognosiaChoice === "1"} onChange={e => this.setState({ astereognosiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">ASTEREOGNOSIA</span> <input type="text" value={this.state.astereognosiaText} onChange={e => this.setState({ astereognosiaText: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.akineticChoice === "1"} onChange={e => this.setState({ akineticChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">AKINETIC MUTISM</span><input type="text" value={this.state.akineticText} onChange={e => this.setState({ akineticText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemianopsiaChoice === "1"} onChange={e => this.setState({ hemianopsiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">HEMIANOPSIA</span><input type="text" value={this.state.hemianopsiaText} onChange={e => this.setState({ hemianopsiaText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.hemianopsiaChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemianopsiaRightChoice === "1"} onChange={e => this.setState({ hemianopsiaRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">RIGHT</span> <input type="text" value={this.state.hemianopsiaRightText} onChange={e => this.setState({ hemianopsiaRightText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemianopsiaLeftChoice === "1"} onChange={e => this.setState({ hemianopsiaLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">LEFT</span><input type="text" value={this.state.hemianopsiaLeftText} onChange={e => this.setState({ hemianopsiaLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemianopsiaBitemporalChoice === "1"} onChange={e => this.setState({ hemianopsiaBitemporalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">BITEMPORAL</span><input type="text" value={this.state.hemianopsiaBitemporalText} onChange={e => this.setState({ hemianopsiaBitemporalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemianopsiaTemporalChoice === "1"} onChange={e => this.setState({ hemianopsiaTemporalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TEMPORAL</span> <input type="text" value={this.state.hemianopsiaTemporalText} onChange={e => this.setState({ hemianopsiaTemporalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemianopsiaNasalChoice === "1"} onChange={e => this.setState({ hemianopsiaNasalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">NASAL</span><input type="text" value={this.state.hemianopsiaNasalText} onChange={e => this.setState({ hemianopsiaNasalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.cranialChoice === "1"} onChange={e => this.setState({ cranialChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">CRANIAL NERVES</span><input type="text" value={this.state.cranialText} onChange={e => this.setState({ cranialText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.cranialChoice === "1" ? 'block' : 'none' }}>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.cranialLeftChoice === "1"} onChange={e => this.setState({ cranialLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">LEFT</span><input type="text" value={this.state.cranialLeftText} onChange={e => this.setState({ cranialLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.cranialLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.olfactoryLeftChoice === "1"} onChange={e => this.setState({ olfactoryLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">OLFACTORY</span><input type="text" value={this.state.olfactoryLeftText} onChange={e => this.setState({ olfactoryLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.opticLeftChoice === "1"} onChange={e => this.setState({ opticLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">OPTIC</span> <input type="text" value={this.state.opticLeftText} onChange={e => this.setState({ opticLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.oculomotorLeftChoice === "1"} onChange={e => this.setState({ oculomotorLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">OCULOMOTOR</span><input type="text" value={this.state.oculomotorLeftText} onChange={e => this.setState({ oculomotorLeftText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trochlearLeftChoice === "1"} onChange={e => this.setState({ trochlearLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TROCHLEAR</span><input type="text" value={this.state.trochlearLeftText} onChange={e => this.setState({ trochlearLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trigeminalLeftChoice === "1"} onChange={e => this.setState({ trigeminalLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TRIGEMINAL</span> <input type="text" value={this.state.trigeminalLeftText} onChange={e => this.setState({ trigeminalLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abducentLeftChoice === "1"} onChange={e => this.setState({ abducentLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">ABDUCENT</span><input type="text" value={this.state.abducentLeftText} onChange={e => this.setState({ abducentLeftText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.facialLeftChoice === "1"} onChange={e => this.setState({ facialLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">FACIAL</span><input type="text" value={this.state.facialLeftText} onChange={e => this.setState({ facialLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vestibulocochlearLeftChoice === "1"} onChange={e => this.setState({ vestibulocochlearLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">VESTIBULOCOCHLEAR</span> <input type="text" value={this.state.vestibulocochlearLeftText} onChange={e => this.setState({ vestibulocochlearLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.glossopharyngealLeftChoice === "1"} onChange={e => this.setState({ glossopharyngealLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">GLOSSOPHARYNGEAL</span><input type="text" value={this.state.glossopharyngealLeftText} onChange={e => this.setState({ glossopharyngealLeftText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vagusLeftChoice === "1"} onChange={e => this.setState({ vagusLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">VAGUS</span><input type="text" value={this.state.vagusLeftText} onChange={e => this.setState({ vagusLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.accessoryLeftChoice === "1"} onChange={e => this.setState({ accessoryLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">ACCESSORY</span> <input type="text" value={this.state.accessoryLeftText} onChange={e => this.setState({ accessoryLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hypoglossalLeftChoice === "1"} onChange={e => this.setState({ hypoglossalLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">HYPOGLOSSAL</span><input type="text" value={this.state.hypoglossalLeftText} onChange={e => this.setState({ hypoglossalLeftText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.cranialRightChoice === "1"} onChange={e => this.setState({ cranialRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">RIGHT</span><input type="text" value={this.state.cranialRightText} onChange={e => this.setState({ cranialRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.cranialRightChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.olfactoryRightChoice === "1"} onChange={e => this.setState({ olfactoryRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">OLFACTORY</span><input type="text" value={this.state.olfactoryRightText} onChange={e => this.setState({ olfactoryRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.opticRightChoice === "1"} onChange={e => this.setState({ opticRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">OPTIC</span> <input type="text" value={this.state.opticRightText} onChange={e => this.setState({ opticRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.oculomotorRightChoice === "1"} onChange={e => this.setState({ oculomotorRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">OCULOMOTOR</span><input type="text" value={this.state.oculomotorRightText} onChange={e => this.setState({ oculomotorRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trochlearRightChoice === "1"} onChange={e => this.setState({ trochlearRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TROCHLEAR</span><input type="text" value={this.state.trochlearRightText} onChange={e => this.setState({ trochlearRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trigeminalRightChoice === "1"} onChange={e => this.setState({ trigeminalRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TRIGEMINAL</span> <input type="text" value={this.state.trigeminalRightText} onChange={e => this.setState({ trigeminalRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abducentRightChoice === "1"} onChange={e => this.setState({ abducentRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">ABDUCENT</span><input type="text" value={this.state.abducentRightText} onChange={e => this.setState({ abducentRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.facialRightChoice === "1"} onChange={e => this.setState({ facialRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">FACIAL</span><input type="text" value={this.state.facialRightText} onChange={e => this.setState({ facialRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vestibulocochlearRightChoice === "1"} onChange={e => this.setState({ vestibulocochlearRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">VESTIBULOCOCHLEAR</span> <input type="text" value={this.state.vestibulocochlearRightText} onChange={e => this.setState({ vestibulocochlearRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.glossopharyngealRightChoice === "1"} onChange={e => this.setState({ glossopharyngealRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">GLOSSOPHARYNGEAL</span><input type="text" value={this.state.glossopharyngealRightText} onChange={e => this.setState({ glossopharyngealRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vagusRightChoice === "1"} onChange={e => this.setState({ vagusRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">VAGUS</span><input type="text" value={this.state.vagusRightText} onChange={e => this.setState({ vagusRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.accessoryRightChoice === "1"} onChange={e => this.setState({ accessoryRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">ACCESSORY</span> <input type="text" value={this.state.accessoryRightText} onChange={e => this.setState({ accessoryRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hypoglossalRightChoice === "1"} onChange={e => this.setState({ hypoglossalRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">HYPOGLOSSAL</span><input type="text" value={this.state.hypoglossalRightText} onChange={e => this.setState({ hypoglossalRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeChoice === "1"} onChange={e => this.setState({ spineRangeChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">SPINE RANGE</span><input type="text" value={this.state.spineRangeText} onChange={e => this.setState({ spineRangeText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.spineRangeChoice === "1" ? 'block' : 'none' }}>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalChoice === "1"} onChange={e => this.setState({ spineRangeCervicalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Cervical</span><input type="text" value={this.state.spineRangeCervicalText} onChange={e => this.setState({ spineRangeCervicalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.spineRangeCervicalChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalFlexionChoice === "1"} onChange={e => this.setState({ spineRangeCervicalFlexionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Flexion</span> <input type="text" value={this.state.spineRangeCervicalFlexionText} onChange={e => this.setState({ spineRangeCervicalFlexionText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeCervicalFlexionChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalFlexionReducedChoice === "1"} onChange={e => this.setState({ spineRangeCervicalFlexionReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeCervicalFlexionReducedText} onChange={e => this.setState({ spineRangeCervicalFlexionReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalFlexionPainfulChoice === "1"} onChange={e => this.setState({ spineRangeCervicalFlexionPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeCervicalFlexionPainfulText} onChange={e => this.setState({ spineRangeCervicalFlexionPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalExtensionChoice === "1"} onChange={e => this.setState({ spineRangeCervicalExtensionChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Extension</span><input type="text" value={this.state.spineRangeCervicalExtensionText} onChange={e => this.setState({ spineRangeCervicalExtensionText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeCervicalExtensionChoice === "1" ? 'block' : 'none' }}>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalExtensionReducedChoice === "1"} onChange={e => this.setState({ spineRangeCervicalExtensionReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeCervicalExtensionReducedText} onChange={e => this.setState({ spineRangeCervicalExtensionReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalExtensionPainfulChoice === "1"} onChange={e => this.setState({ spineRangeCervicalExtensionPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeCervicalExtensionPainfulText} onChange={e => this.setState({ spineRangeCervicalExtensionPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalLateralBendingChoice === "1"} onChange={e => this.setState({ spineRangeCervicalLateralBendingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lateral bending</span><input type="text" value={this.state.spineRangeCervicalLateralBendingText} onChange={e => this.setState({ spineRangeCervicalLateralBendingText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeCervicalLateralBendingChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalLateralBendingReducedChoice === "1"} onChange={e => this.setState({ spineRangeCervicalLateralBendingReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeCervicalLateralBendingReducedText} onChange={e => this.setState({ spineRangeCervicalLateralBendingReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalLateralBendingPainfulChoice === "1"} onChange={e => this.setState({ spineRangeCervicalLateralBendingPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeCervicalLateralBendingPainfulText} onChange={e => this.setState({ spineRangeCervicalLateralBendingPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalRotationChoice === "1"} onChange={e => this.setState({ spineRangeCervicalRotationChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Rotation</span><input type="text" value={this.state.spineRangeCervicalRotationText} onChange={e => this.setState({ spineRangeCervicalRotationText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeCervicalRotationChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalRotationReducedChoice === "1"} onChange={e => this.setState({ spineRangeCervicalRotationReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeCervicalRotationReducedText} onChange={e => this.setState({ spineRangeCervicalRotationReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeCervicalRotationPainfulChoice === "1"} onChange={e => this.setState({ spineRangeCervicalRotationPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeCervicalRotationPainfulText} onChange={e => this.setState({ spineRangeCervicalRotationPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicChoice === "1"} onChange={e => this.setState({ spineRangeThoracicChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thoracic</span><input type="text" value={this.state.spineRangeThoracicText} onChange={e => this.setState({ spineRangeThoracicText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.spineRangeThoracicChoice === "1" ? 'block' : 'none' }}>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicFlexionChoice === "1"} onChange={e => this.setState({ spineRangeThoracicFlexionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Flexion</span> <input type="text" value={this.state.spineRangeThoracicFlexionText} onChange={e => this.setState({ spineRangeThoracicFlexionText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeThoracicFlexionChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicFlexionReducedChoice === "1"} onChange={e => this.setState({ spineRangeThoracicFlexionReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeThoracicFlexionReducedText} onChange={e => this.setState({ spineRangeThoracicFlexionReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicFlexionPainfulChoice === "1"} onChange={e => this.setState({ spineRangeThoracicFlexionPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeThoracicFlexionPainfulText} onChange={e => this.setState({ spineRangeThoracicFlexionPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicExtensionChoice === "1"} onChange={e => this.setState({ spineRangeThoracicExtensionChoice: e.target.checked === true ? "1" : "0" })} onclick="showHideFromCheckBox(this, 'thoracicExtensionSub')" /><span className="input-group-text"> Extension</span><input type="text" value={this.state.spineRangeThoracicExtensionText} onChange={e => this.setState({ spineRangeThoracicExtensionText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.radiculapathyRightChoice === "1" ? 'block' : 'none' }} id="thoracicExtensionSub">
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicExtensionReducedChoice === "1"} onChange={e => this.setState({ spineRangeThoracicExtensionReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeThoracicExtensionReducedText} onChange={e => this.setState({ spineRangeThoracicExtensionReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicExtensionPainfulChoice === "1"} onChange={e => this.setState({ spineRangeThoracicExtensionPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeThoracicExtensionPainfulText} onChange={e => this.setState({ spineRangeThoracicExtensionPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicLateralBendingChoice === "1"} onChange={e => this.setState({ spineRangeThoracicLateralBendingChoice: e.target.checked === true ? "1" : "0" })} onclick="showHideFromCheckBox(this, 'thoracicLateralBendingSub')" /> <span className="input-group-text">Lateral bending</span><input type="text" value={this.state.spineRangeThoracicLateralBendingText} onChange={e => this.setState({ spineRangeThoracicLateralBendingText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.radiculapathyRightChoice === "1" ? 'block' : 'none' }} id="thoracicLateralBendingSub">
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicLateralBendingReducedChoice === "1"} onChange={e => this.setState({ spineRangeThoracicLateralBendingReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeThoracicLateralBendingReducedText} onChange={e => this.setState({ spineRangeThoracicLateralBendingReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicLateralBendingPainfulChoice === "1"} onChange={e => this.setState({ spineRangeThoracicLateralBendingPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeThoracicLateralBendingPainfulText} onChange={e => this.setState({ spineRangeThoracicLateralBendingPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicRotationChoice === "1"} onChange={e => this.setState({ spineRangeThoracicRotationChoice: e.target.checked === true ? "1" : "0" })} onclick="showHideFromCheckBox(this, 'thoracicRotationSub')" /> <span className="input-group-text">Rotation</span><input type="text" value={this.state.spineRangeThoracicRotationText} onChange={e => this.setState({ spineRangeThoracicRotationText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.radiculapathyRightChoice === "1" ? 'block' : 'none' }} id="thoracicRotationSub">
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicRotationReducedChoice === "1"} onChange={e => this.setState({ spineRangeThoracicRotationReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeThoracicRotationReducedText} onChange={e => this.setState({ spineRangeThoracicRotationReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeThoracicRotationPainfulChoice === "1"} onChange={e => this.setState({ spineRangeThoracicRotationPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeThoracicRotationPainfulText} onChange={e => this.setState({ spineRangeThoracicRotationPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarChoice === "1"} onChange={e => this.setState({ spineRangeLumbarChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lumbar</span><input type="text" value={this.state.spineRangeLumbarText} onChange={e => this.setState({ spineRangeLumbarText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.spineRangeLumbarChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarFlexionChoice === "1"} onChange={e => this.setState({ spineRangeLumbarFlexionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Flexion</span> <input type="text" value={this.state.spineRangeLumbarFlexionText} onChange={e => this.setState({ spineRangeLumbarFlexionText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeLumbarFlexionChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarFlexionReducedChoice === "1"} onChange={e => this.setState({ spineRangeLumbarFlexionReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeLumbarFlexionReducedText} onChange={e => this.setState({ spineRangeLumbarFlexionReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarFlexionPainfulChoice === "1"} onChange={e => this.setState({ spineRangeLumbarFlexionPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeLumbarFlexionPainfulText} onChange={e => this.setState({ spineRangeLumbarFlexionPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarExtensionChoice === "1"} onChange={e => this.setState({ spineRangeLumbarExtensionChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Extension</span><input type="text" value={this.state.spineRangeLumbarExtensionText} onChange={e => this.setState({ spineRangeLumbarExtensionText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeLumbarExtensionChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarExtensionReducedChoice === "1"} onChange={e => this.setState({ spineRangeLumbarExtensionReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeLumbarExtensionReducedText} onChange={e => this.setState({ spineRangeLumbarExtensionReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarExtensionPainfulChoice === "1"} onChange={e => this.setState({ spineRangeLumbarExtensionPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeLumbarExtensionPainfulText} onChange={e => this.setState({ spineRangeLumbarExtensionPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarLateralBendingChoice === "1"} onChange={e => this.setState({ spineRangeLumbarLateralBendingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lateral bending</span><input type="text" value={this.state.spineRangeLumbarLateralBendingText} onChange={e => this.setState({ spineRangeLumbarLateralBendingText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeLumbarLateralBendingChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarLateralBendingReducedChoice === "1"} onChange={e => this.setState({ spineRangeLumbarLateralBendingReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeLumbarLateralBendingReducedText} onChange={e => this.setState({ spineRangeLumbarLateralBendingReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarLateralBendingPainfulChoice === "1"} onChange={e => this.setState({ spineRangeLumbarLateralBendingPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeLumbarLateralBendingPainfulText} onChange={e => this.setState({ spineRangeLumbarLateralBendingPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarRotationChoice === "1"} onChange={e => this.setState({ spineRangeLumbarRotationChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Rotation</span><input type="text" value={this.state.spineRangeLumbarRotationText} onChange={e => this.setState({ spineRangeLumbarRotationText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.spineRangeLumbarRotationChoice === "1" ? 'block' : 'none' }}>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarRotationReducedChoice === "1"} onChange={e => this.setState({ spineRangeLumbarRotationReducedChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Reduced</span> <input type="text" value={this.state.spineRangeLumbarRotationReducedText} onChange={e => this.setState({ spineRangeLumbarRotationReducedText: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineRangeLumbarRotationPainfulChoice === "1"} onChange={e => this.setState({ spineRangeLumbarRotationPainfulChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Painful</span><input type="text" value={this.state.spineRangeLumbarRotationPainfulText} onChange={e => this.setState({ spineRangeLumbarRotationPainfulText: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineTendernessChoice === "1"} onChange={e => this.setState({ spineTendernessChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">SPINE TENDERNESS</span><input type="text" value={this.state.spineTendernessText} onChange={e => this.setState({ spineTendernessText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.spineTendernessChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineTendernessCervicalChoice === "1"} onChange={e => this.setState({ spineTendernessCervicalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Cervical</span> <input type="text" value={this.state.spineTendernessCervicalText} onChange={e => this.setState({ spineTendernessCervicalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineTendernessThoracicChoice === "1"} onChange={e => this.setState({ spineTendernessThoracicChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Thoracic</span><input type="text" value={this.state.spineTendernessThoracicText} onChange={e => this.setState({ spineTendernessThoracicText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineTendernessThoracolumbarChoice === "1"} onChange={e => this.setState({ spineTendernessThoracolumbarChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thoracolumbar Junction </span><input type="text" value={this.state.spineTendernessThoracolumbarText} onChange={e => this.setState({ spineTendernessThoracolumbarText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineTendernessLumbarChoice === "1"} onChange={e => this.setState({ spineTendernessLumbarChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lumbar</span> <input type="text" value={this.state.spineTendernessLumbarText} onChange={e => this.setState({ spineTendernessLumbarText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineTendernessLumbosacralChoice === "1"} onChange={e => this.setState({ spineTendernessLumbosacralChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Lumbosacral Junction</span><input type="text" value={this.state.spineTendernessLumbosacralText} onChange={e => this.setState({ spineTendernessLumbosacralText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spineTendernessSacroiliacChoice === "1"} onChange={e => this.setState({ spineTendernessSacroiliacChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Sacroiliac Joint</span><input type="text" value={this.state.spineTendernessSacroiliacText} onChange={e => this.setState({ spineTendernessSacroiliacText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trendelenbergChoice === "1"} onChange={e => this.setState({ trendelenbergChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TRENDELENBERG TEST</span><input type="text" value={this.state.trendelenbergText} onChange={e => this.setState({ trendelenbergText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.trendelenbergChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trendelenbergLeftChoice === "1"} onChange={e => this.setState({ trendelenbergLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.trendelenbergLeftText} onChange={e => this.setState({ trendelenbergLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.trendelenbergLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trendelenbergLeftPresentChoice === "1"} onChange={e => this.setState({ trendelenbergLeftPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.trendelenbergLeftPresentText} onChange={e => this.setState({ trendelenbergLeftPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trendelenbergLeftNormalChoice === "1"} onChange={e => this.setState({ trendelenbergLeftNormalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Normal</span><input type="text" value={this.state.trendelenbergLeftNormalText} onChange={e => this.setState({ trendelenbergLeftNormalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trendelenbergRightChoice === "1"} onChange={e => this.setState({ trendelenbergRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.trendelenbergRightText} onChange={e => this.setState({ trendelenbergRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.trendelenbergRightChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trendelenbergRightPresentChoice === "1"} onChange={e => this.setState({ trendelenbergRightPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.trendelenbergRightPresentText} onChange={e => this.setState({ trendelenbergRightPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.trendelenbergRightNormalChoice === "1"} onChange={e => this.setState({ trendelenbergRightNormalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Normal</span><input type="text" value={this.state.trendelenbergRightNormalText} onChange={e => this.setState({ trendelenbergRightNormalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.standOnTipToeChoice === "1"} onChange={e => this.setState({ standOnTipToeChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">STAND ON TIP TOE</span><input type="text" value={this.state.standOnTipToeText} onChange={e => this.setState({ standOnTipToeText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.standOnTipToeChoice === "1" ? 'block' : 'none' }}>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.standOnTipToeLeftChoice === "1"} onChange={e => this.setState({ standOnTipToeLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.standOnTipToeLeftText} onChange={e => this.setState({ standOnTipToeLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.standOnTipToeLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.standOnTipToeLeftWeakChoice === "1"} onChange={e => this.setState({ standOnTipToeLeftWeakChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Weak</span> <input type="text" value={this.state.standOnTipToeLeftWeakText} onChange={e => this.setState({ standOnTipToeLeftWeakText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.standOnTipToeLeftNormalChoice === "1"} onChange={e => this.setState({ standOnTipToeLeftNormalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Normal</span><input type="text" value={this.state.standOnTipToeLeftNormalText} onChange={e => this.setState({ standOnTipToeLeftNormalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.standOnTipToeRightChoice === "1"} onChange={e => this.setState({ standOnTipToeRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.standOnTipToeRightText} onChange={e => this.setState({ standOnTipToeRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.standOnTipToeRightChoice === "1" ? 'block' : 'none' }}>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.standOnTipToeRightWeakChoice === "1"} onChange={e => this.setState({ standOnTipToeRightWeakChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Weak</span> <input type="text" value={this.state.standOnTipToeRightWeakText} onChange={e => this.setState({ standOnTipToeRightWeakText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.standOnTipToeRightNormalChoice === "1"} onChange={e => this.setState({ standOnTipToeRightNormalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Normal</span><input type="text" value={this.state.standOnTipToeRightNormalText} onChange={e => this.setState({ standOnTipToeRightNormalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spurlingTestChoice === "1"} onChange={e => this.setState({ spurlingTestChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">SPURLING TEST</span><input type="text" value={this.state.spurlingTestText} onChange={e => this.setState({ spurlingTestText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.spurlingTestChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spurlingTestLeftChoice === "1"} onChange={e => this.setState({ spurlingTestLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.spurlingTestLeftText} onChange={e => this.setState({ spurlingTestLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.spurlingTestLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spurlingTestLeftPresentChoice === "1"} onChange={e => this.setState({ spurlingTestLeftPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.spurlingTestLeftPresentText} onChange={e => this.setState({ spurlingTestLeftPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spurlingTestLeftAbsentChoice === "1"} onChange={e => this.setState({ spurlingTestLeftAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.spurlingTestLeftAbsentText} onChange={e => this.setState({ spurlingTestLeftAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spurlingTestRightChoice === "1"} onChange={e => this.setState({ spurlingTestRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.spurlingTestRightText} onChange={e => this.setState({ spurlingTestRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.spurlingTestRightChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spurlingTestRightPresentChoice === "1"} onChange={e => this.setState({ spurlingTestRightPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.spurlingTestRightPresentText} onChange={e => this.setState({ spurlingTestRightPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.spurlingTestRightAbsentChoice === "1"} onChange={e => this.setState({ spurlingTestRightAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.spurlingTestRightAbsentText} onChange={e => this.setState({ spurlingTestRightAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abductionReliefChoice === "1"} onChange={e => this.setState({ abductionReliefChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">UPPER LIMB SHOULDER ABDUCTION RELIEF OF UPPER LIMB RADICULAR PAIN</span><input type="text" value={this.state.abductionReliefText} onChange={e => this.setState({ abductionReliefText: e.target.value })} class="form-control" /></div> </div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.abductionReliefChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abductionReliefLeftChoice === "1"} onChange={e => this.setState({ abductionReliefLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.abductionReliefLeftText} onChange={e => this.setState({ abductionReliefLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.abductionReliefLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abductionReliefLeftPresentChoice === "1"} onChange={e => this.setState({ abductionReliefLeftPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.abductionReliefLeftPresentText} onChange={e => this.setState({ abductionReliefLeftPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abductionReliefLeftAbsentChoice === "1"} onChange={e => this.setState({ abductionReliefLeftAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.abductionReliefLeftAbsentText} onChange={e => this.setState({ abductionReliefLeftAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abductionReliefRightChoice === "1"} onChange={e => this.setState({ abductionReliefRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.abductionReliefRightText} onChange={e => this.setState({ abductionReliefRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.abductionReliefRightChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abductionReliefRightPresentChoice === "1"} onChange={e => this.setState({ abductionReliefRightPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.abductionReliefRightPresentText} onChange={e => this.setState({ abductionReliefRightPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abductionReliefRightAbsentChoice === "1"} onChange={e => this.setState({ abductionReliefRightAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.abductionReliefRightAbsentText} onChange={e => this.setState({ abductionReliefRightAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.straightLegRaisingChoice === "1"} onChange={e => this.setState({ straightLegRaisingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">STRAIGHT LEG RAISING TEST</span><input type="text" value={this.state.straightLegRaisingText} onChange={e => this.setState({ straightLegRaisingText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.straightLegRaisingChoice === "1" ? 'block' : 'none' }}>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.straightLegRaisingLeftChoice === "1"} onChange={e => this.setState({ straightLegRaisingLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.straightLegRaisingLeftText} onChange={e => this.setState({ straightLegRaisingLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.straightLegRaisingLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.straightLegRaisingLeftPresentChoice === "1"} onChange={e => this.setState({ straightLegRaisingLeftPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.straightLegRaisingLeftPresentText} onChange={e => this.setState({ straightLegRaisingLeftPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.straightLegRaisingLeftAbsentChoice === "1"} onChange={e => this.setState({ straightLegRaisingLeftAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.straightLegRaisingLeftAbsentText} onChange={e => this.setState({ straightLegRaisingLeftAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.straightLegRaisingRightChoice === "1"} onChange={e => this.setState({ straightLegRaisingRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.straightLegRaisingRightText} onChange={e => this.setState({ straightLegRaisingRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.straightLegRaisingRightChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.straightLegRaisingRightPresentChoice === "1"} onChange={e => this.setState({ straightLegRaisingRightPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.straightLegRaisingRightPresentText} onChange={e => this.setState({ straightLegRaisingRightPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.straightLegRaisingRightAbsentChoice === "1"} onChange={e => this.setState({ straightLegRaisingRightAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.straightLegRaisingRightAbsentText} onChange={e => this.setState({ straightLegRaisingRightAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.reverseStraightLegChoice === "1"} onChange={e => this.setState({ reverseStraightLegChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">REVERSE STRAIGHT LEG RAISING TEST</span><input type="text" value={this.state.reverseStraightLegText} onChange={e => this.setState({ genitalText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.reverseStraightLegChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.reverseStraightLegLeftChoice === "1"} onChange={e => this.setState({ reverseStraightLegLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.reverseStraightLegLeftText} onChange={e => this.setState({ reverseStraightLegLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.reverseStraightLegLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.reverseStraightLegLeftPresentChoice === "1"} onChange={e => this.setState({ reverseStraightLegLeftPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.reverseStraightLegLeftPresentText} onChange={e => this.setState({ reverseStraightLegLeftPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.reverseStraightLegLeftAbsentChoice === "1"} onChange={e => this.setState({ reverseStraightLegLeftAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.reverseStraightLegLeftAbsentText} onChange={e => this.setState({ reverseStraightLegLeftAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.reverseStraightLegRightChoice === "1"} onChange={e => this.setState({ reverseStraightLegRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.reverseStraightLegRightText} onChange={e => this.setState({ reverseStraightLegRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.reverseStraightLegRightChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.reverseStraightLegRightPresentChoice === "1"} onChange={e => this.setState({ reverseStraightLegRightPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present</span> <input type="text" value={this.state.reverseStraightLegRightPresentText} onChange={e => this.setState({ reverseStraightLegRightPresentText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.reverseStraightLegRightAbsentChoice === "1"} onChange={e => this.setState({ reverseStraightLegRightAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.reverseStraightLegRightAbsentText} onChange={e => this.setState({ reverseStraightLegRightAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneChoice === "1"} onChange={e => this.setState({ toneChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">TONE</span><input type="text" value={this.state.toneText} onChange={e => this.setState({ toneText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.toneChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftUpperLimbChoice === "1"} onChange={e => this.setState({ toneLeftUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Left upper limb</span><input type="text" value={this.state.toneLeftUpperLimbText} onChange={e => this.setState({ toneLeftUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.toneLeftUpperLimbChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftUpperLimbHypertoniaChoice === "1"} onChange={e => this.setState({ toneLeftUpperLimbHypertoniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertonia</span> <input type="text" value={this.state.toneLeftUpperLimbHypertoniaText} onChange={e => this.setState({ toneLeftUpperLimbHypertoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftUpperLimbHypotoniaChoice === "1"} onChange={e => this.setState({ toneLeftUpperLimbHypotoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hypotonia</span><input type="text" value={this.state.toneLeftUpperLimbHypotoniaText} onChange={e => this.setState({ toneLeftUpperLimbHypotoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftUpperLimbAtoniaChoice === "1"} onChange={e => this.setState({ toneLeftUpperLimbAtoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Atonia</span><input type="text" value={this.state.toneLeftUpperLimbAtoniaText} onChange={e => this.setState({ toneLeftUpperLimbAtoniaText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightUpperLimbChoice === "1"} onChange={e => this.setState({ toneRightUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right upper limb</span> <input type="text" value={this.state.toneRightUpperLimbText} onChange={e => this.setState({ toneRightUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.toneRightUpperLimbChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightUpperLimbHypertoniaChoice === "1"} onChange={e => this.setState({ toneRightUpperLimbHypertoniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertonia</span> <input type="text" value={this.state.toneRightUpperLimbHypertoniaText} onChange={e => this.setState({ toneRightUpperLimbHypertoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightUpperLimbHypotoniaChoice === "1"} onChange={e => this.setState({ toneRightUpperLimbHypotoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hypotonia</span><input type="text" value={this.state.toneRightUpperLimbHypotoniaText} onChange={e => this.setState({ toneRightUpperLimbHypotoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightUpperLimbAtoniaChoice === "1"} onChange={e => this.setState({ toneRightUpperLimbAtoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Atonia</span><input type="text" value={this.state.toneRightUpperLimbAtoniaText} onChange={e => this.setState({ toneRightUpperLimbAtoniaText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftLowerLimbChoice === "1"} onChange={e => this.setState({ toneLeftLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Left lower limb</span><input type="text" value={this.state.toneLeftLowerLimbText} onChange={e => this.setState({ toneLeftLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.toneLeftLowerLimbChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftLowerLimbHypertoniaChoice === "1"} onChange={e => this.setState({ toneLeftLowerLimbHypertoniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertonia</span> <input type="text" value={this.state.toneLeftLowerLimbHypertoniaText} onChange={e => this.setState({ toneLeftLowerLimbHypertoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftLowerLimbHypotoniaChoice === "1"} onChange={e => this.setState({ toneLeftLowerLimbHypotoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hypotonia</span><input type="text" value={this.state.toneLeftLowerLimbHypotoniaText} onChange={e => this.setState({ toneLeftLowerLimbHypotoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneLeftLowerLimbAtoniaChoice === "1"} onChange={e => this.setState({ toneLeftLowerLimbAtoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Atonia</span><input type="text" value={this.state.toneLeftLowerLimbAtoniaText} onChange={e => this.setState({ toneLeftLowerLimbAtoniaText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightLowerLimbChoice === "1"} onChange={e => this.setState({ toneRightLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right lower limb</span><input type="text" value={this.state.toneRightLowerLimbText} onChange={e => this.setState({ toneRightLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.toneRightLowerLimbChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightLowerLimbHypertoniaChoice === "1"} onChange={e => this.setState({ toneRightLowerLimbHypertoniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertonia</span> <input type="text" value={this.state.toneRightLowerLimbHypertoniaText} onChange={e => this.setState({ toneRightLowerLimbHypertoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightLowerLimbHypotoniaChoice === "1"} onChange={e => this.setState({ toneRightLowerLimbHypotoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hypotonia</span><input type="text" value={this.state.toneRightLowerLimbHypotoniaText} onChange={e => this.setState({ toneRightLowerLimbHypotoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneRightLowerLimbAtoniaChoice === "1"} onChange={e => this.setState({ toneRightLowerLimbAtoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Atonia</span><input type="text" value={this.state.toneRightLowerLimbAtoniaText} onChange={e => this.setState({ toneRightLowerLimbAtoniaText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneGlobalChoice === "1"} onChange={e => this.setState({ toneGlobalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Global</span><input type="text" value={this.state.toneGlobalText} onChange={e => this.setState({ toneGlobalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.toneGlobalChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneGlobalHypertoniaChoice === "1"} onChange={e => this.setState({ toneGlobalHypertoniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertonia</span> <input type="text" value={this.state.toneGlobalHypertoniaText} onChange={e => this.setState({ toneGlobalHypertoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneGlobalHypotoniaChoice === "1"} onChange={e => this.setState({ toneGlobalHypotoniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hypotonia</span><input type="text" value={this.state.toneGlobalHypotoniaText} onChange={e => this.setState({ toneGlobalHypotoniaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.toneGlobalAbsentChoice === "1"} onChange={e => this.setState({ toneGlobalAbsentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Absent</span><input type="text" value={this.state.toneGlobalAbsentText} onChange={e => this.setState({ toneGlobalAbsentText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerChoice === "1"} onChange={e => this.setState({ powerChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">POWER</span><input type="text" value={this.state.powerText} onChange={e => this.setState({ powerText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.powerChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftUpperLimbChoice === "1"} onChange={e => this.setState({ powerLeftUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Left upper limb</span><input type="text" value={this.state.powerLeftUpperLimbText} onChange={e => this.setState({ powerLeftUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.powerLeftUpperLimbChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftUpperLimbC5Choice === "1"} onChange={e => this.setState({ powerLeftUpperLimbC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5 ?/5</span> <input type="text" value={this.state.powerLeftUpperLimbC5Text} onChange={e => this.setState({ powerLeftUpperLimbC5Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftUpperLimbC6Choice === "1"} onChange={e => this.setState({ powerLeftUpperLimbC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6 ?/5</span><input type="text" value={this.state.powerLeftUpperLimbC6Text} onChange={e => this.setState({ powerLeftUpperLimbC6Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftUpperLimbC7Choice === "1"} onChange={e => this.setState({ powerLeftUpperLimbC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7 ?/5</span><input type="text" value={this.state.powerLeftUpperLimbC7Text} onChange={e => this.setState({ powerLeftUpperLimbC7Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftUpperLimbC8Choice === "1"} onChange={e => this.setState({ powerLeftUpperLimbC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 ?/5</span> <input type="text" value={this.state.powerLeftUpperLimbC8Text} onChange={e => this.setState({ powerLeftUpperLimbC8Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftUpperLimbT1Choice === "1"} onChange={e => this.setState({ powerLeftUpperLimbT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1 ?/5</span><input type="text" value={this.state.powerLeftUpperLimbT1Text} onChange={e => this.setState({ powerLeftUpperLimbT1Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightUpperLimbChoice === "1"} onChange={e => this.setState({ powerRightUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right upper limb</span> <input type="text" value={this.state.powerRightUpperLimbText} onChange={e => this.setState({ powerRightUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.powerRightUpperLimbChoice === "1" ? 'block' : 'none' }}>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightUpperLimbC5Choice === "1"} onChange={e => this.setState({ powerRightUpperLimbC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5 ?/5</span> <input type="text" value={this.state.powerRightUpperLimbC5Text} onChange={e => this.setState({ powerRightUpperLimbC5Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightUpperLimbC6Choice === "1"} onChange={e => this.setState({ powerRightUpperLimbC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6 ?/5</span><input type="text" value={this.state.powerRightUpperLimbC6Text} onChange={e => this.setState({ powerRightUpperLimbC6Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightUpperLimbC7Choice === "1"} onChange={e => this.setState({ powerRightUpperLimbC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7 ?/5</span><input type="text" value={this.state.powerRightUpperLimbC7Text} onChange={e => this.setState({ powerRightUpperLimbC7Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightUpperLimbC8Choice === "1"} onChange={e => this.setState({ powerRightUpperLimbC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 ?/5</span> <input type="text" value={this.state.powerRightUpperLimbC8Text} onChange={e => this.setState({ powerRightUpperLimbC8Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightUpperLimbT1Choice === "1"} onChange={e => this.setState({ powerRightUpperLimbT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1 ?/5</span><input type="text" value={this.state.powerRightUpperLimbT1Text} onChange={e => this.setState({ powerRightUpperLimbT1Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftLowerLimbChoice === "1"} onChange={e => this.setState({ powerLeftLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Left Lower limb</span><input type="text" value={this.state.powerLeftLowerLimbText} onChange={e => this.setState({ powerLeftLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.powerLeftLowerLimbChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftLowerLimbC5Choice === "1"} onChange={e => this.setState({ powerLeftLowerLimbC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5 ?/5</span> <input type="text" value={this.state.powerLeftLowerLimbC5Text} onChange={e => this.setState({ powerLeftLowerLimbC5Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftLowerLimbC6Choice === "1"} onChange={e => this.setState({ powerLeftLowerLimbC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6 ?/5</span><input type="text" value={this.state.powerLeftLowerLimbC6Text} onChange={e => this.setState({ powerLeftLowerLimbC6Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftLowerLimbC7Choice === "1"} onChange={e => this.setState({ powerLeftLowerLimbC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7 ?/5</span><input type="text" value={this.state.powerLeftLowerLimbC7Text} onChange={e => this.setState({ powerLeftLowerLimbC7Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftLowerLimbC8Choice === "1"} onChange={e => this.setState({ powerLeftLowerLimbC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 ?/5</span> <input type="text" value={this.state.powerLeftLowerLimbC8Text} onChange={e => this.setState({ powerLeftLowerLimbC8Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerLeftLowerLimbT1Choice === "1"} onChange={e => this.setState({ powerLeftLowerLimbT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1 ?/5</span><input type="text" value={this.state.powerLeftLowerLimbT1Text} onChange={e => this.setState({ powerLeftLowerLimbT1Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightLowerLimbChoice === "1"} onChange={e => this.setState({ powerRightLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right Lower limb</span> <input type="text" value={this.state.powerRightLowerLimbText} onChange={e => this.setState({ powerRightLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.powerRightLowerLimbChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightLowerLimbC5Choice === "1"} onChange={e => this.setState({ powerRightLowerLimbC5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C5 ?/5</span><input type="text" value={this.state.powerRightLowerLimbC5Text} onChange={e => this.setState({ powerRightLowerLimbC5Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightLowerLimbC6Choice === "1"} onChange={e => this.setState({ powerRightLowerLimbC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6 ?/5</span><input type="text" value={this.state.powerRightLowerLimbC6Text} onChange={e => this.setState({ powerRightLowerLimbC6Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightLowerLimbC7Choice === "1"} onChange={e => this.setState({ powerRightLowerLimbC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7 ?/5</span><input type="text" value={this.state.powerRightLowerLimbC7Text} onChange={e => this.setState({ powerRightLowerLimbC7Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightLowerLimbC8Choice === "1"} onChange={e => this.setState({ powerRightLowerLimbC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 ?/5</span> <input type="text" value={this.state.powerRightLowerLimbC8Text} onChange={e => this.setState({ powerRightLowerLimbC8Text: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.powerRightLowerLimbT1Choice === "1"} onChange={e => this.setState({ powerRightLowerLimbT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1 ?/5</span><input type="text" value={this.state.powerRightLowerLimbT1Text} onChange={e => this.setState({ powerRightLowerLimbT1Text: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wastingChoice === "1"} onChange={e => this.setState({ wastingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">WASTING</span><input type="text" value={this.state.wastingText} onChange={e => this.setState({ wastingText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.wastingChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wastingLeftUpperLimbChoice === "1"} onChange={e => this.setState({ wastingLeftUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Left upper limb</span><input type="text" value={this.state.wastingLeftUpperLimbText} onChange={e => this.setState({ wastingLeftUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wastingRightUpperLimbChoice === "1"} onChange={e => this.setState({ wastingRightUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right upper limb</span> <input type="text" value={this.state.wastingRightUpperLimbText} onChange={e => this.setState({ wastingRightUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wastingLeftLowerLimbChoice === "1"} onChange={e => this.setState({ wastingLeftLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left lower limb</span> <input type="text" value={this.state.wastingLeftLowerLimbText} onChange={e => this.setState({ wastingLeftLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wastingRightLowerLimbChoice === "1"} onChange={e => this.setState({ wastingRightLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right lower limb</span><input type="text" value={this.state.wastingRightLowerLimbText} onChange={e => this.setState({ wastingRightLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fasciculationsChoice === "1"} onChange={e => this.setState({ fasciculationsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">FASCICULATIONS</span><input type="text" value={this.state.fasciculationsText} onChange={e => this.setState({ fasciculationsText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.fasciculationsChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fasciculationsLeftUpperLimbChoice === "1"} onChange={e => this.setState({ fasciculationsLeftUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Left upper limb</span><input type="text" value={this.state.fasciculationsLeftUpperLimbText} onChange={e => this.setState({ fasciculationsLeftUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fasciculationsRightUpperLimbChoice === "1"} onChange={e => this.setState({ fasciculationsRightUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right upper limb</span> <input type="text" value={this.state.fasciculationsRightUpperLimbText} onChange={e => this.setState({ fasciculationsRightUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fasciculationsLeftLowerLimbChoice === "1"} onChange={e => this.setState({ fasciculationsLeftLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left lower limb</span> <input type="text" value={this.state.fasciculationsLeftLowerLimbText} onChange={e => this.setState({ fasciculationsLeftLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fasciculationsRightLowerLimbChoice === "1"} onChange={e => this.setState({ fasciculationsRightLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right lower limb</span><input type="text" value={this.state.fasciculationsRightLowerLimbText} onChange={e => this.setState({ fasciculationsRightLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonChoice === "1"} onChange={e => this.setState({ deepTendonChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">DEEP TENDON REFLEXES</span><input type="text" value={this.state.deepTendonText} onChange={e => this.setState({ genitalText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.deepTendonChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftBicepsChoice === "1"} onChange={e => this.setState({ deepTendonLeftBicepsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Left Biceps</span><input type="text" value={this.state.deepTendonLeftBicepsText} onChange={e => this.setState({ deepTendonLeftBicepsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonLeftBicepsChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftBicepsHyperChoice === "1"} onChange={e => this.setState({ deepTendonLeftBicepsHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonLeftBicepsHyperText} onChange={e => this.setState({ deepTendonLeftBicepsHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftBicepsHypoChoice === "1"} onChange={e => this.setState({ deepTendonLeftBicepsHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonLeftBicepsHypoText} onChange={e => this.setState({ deepTendonLeftBicepsHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftBicepsAChoice === "1"} onChange={e => this.setState({ deepTendonLeftBicepsAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonLeftBicepsAText} onChange={e => this.setState({ deepTendonLeftBicepsAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightBicepsChoice === "1"} onChange={e => this.setState({ deepTendonRightBicepsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Right Biceps</span><input type="text" value={this.state.deepTendonRightBicepsText} onChange={e => this.setState({ deepTendonRightBicepsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonRightBicepsChoice === "1" ? 'block' : 'none' }}>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightBicepsHyperChoice === "1"} onChange={e => this.setState({ deepTendonRightBicepsHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonRightBicepsHyperText} onChange={e => this.setState({ deepTendonRightBicepsHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightBicepsHypoChoice === "1"} onChange={e => this.setState({ deepTendonRightBicepsHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonRightBicepsHypoText} onChange={e => this.setState({ deepTendonRightBicepsHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightBicepsAChoice === "1"} onChange={e => this.setState({ deepTendonRightBicepsAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonRightBicepsAText} onChange={e => this.setState({ deepTendonRightBicepsAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftTricepsChoice === "1"} onChange={e => this.setState({ deepTendonLeftTricepsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Left Triceps</span><input type="text" value={this.state.deepTendonLeftTricepsText} onChange={e => this.setState({ deepTendonLeftTricepsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonLeftTricepsChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftTricepsHyperChoice === "1"} onChange={e => this.setState({ deepTendonLeftTricepsHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonLeftTricepsHyperText} onChange={e => this.setState({ deepTendonLeftTricepsHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftTricepsHypoChoice === "1"} onChange={e => this.setState({ deepTendonLeftTricepsHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonLeftTricepsHypoText} onChange={e => this.setState({ deepTendonLeftTricepsHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftTricepsAChoice === "1"} onChange={e => this.setState({ deepTendonLeftTricepsAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonLeftTricepsAText} onChange={e => this.setState({ deepTendonLeftTricepsAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightTricepsChoice === "1"} onChange={e => this.setState({ deepTendonRightTricepsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Right Triceps</span><input type="text" value={this.state.deepTendonRightTricepsText} onChange={e => this.setState({ deepTendonRightTricepsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonRightTricepsChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightTricepsHyperChoice === "1"} onChange={e => this.setState({ deepTendonRightTricepsHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonRightTricepsHyperText} onChange={e => this.setState({ deepTendonRightTricepsHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightTricepsHypoChoice === "1"} onChange={e => this.setState({ deepTendonRightTricepsHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonRightTricepsHypoText} onChange={e => this.setState({ deepTendonRightTricepsHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightTricepsAChoice === "1"} onChange={e => this.setState({ deepTendonRightTricepsAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonRightTricepsAText} onChange={e => this.setState({ deepTendonRightTricepsAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonInvertedRadialChoice === "1"} onChange={e => this.setState({ deepTendonInvertedRadialChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Inverted radial </span><input type="text" value={this.state.deepTendonInvertedRadialText} onChange={e => this.setState({ deepTendonInvertedRadialText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonInvertedRadialChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonInvertedRadialLeftChoice === "1"} onChange={e => this.setState({ deepTendonInvertedRadialLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.deepTendonInvertedRadialLeftText} onChange={e => this.setState({ deepTendonInvertedRadialLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonInvertedRadialRightChoice === "1"} onChange={e => this.setState({ deepTendonInvertedRadialRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Right</span><input type="text" value={this.state.deepTendonInvertedRadialRightText} onChange={e => this.setState({ deepTendonInvertedRadialRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftKneeChoice === "1"} onChange={e => this.setState({ deepTendonLeftKneeChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Left Knee</span><input type="text" value={this.state.deepTendonLeftKneeText} onChange={e => this.setState({ deepTendonLeftKneeText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonLeftKneeChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftKneeHyperChoice === "1"} onChange={e => this.setState({ deepTendonLeftKneeHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonLeftKneeHyperText} onChange={e => this.setState({ deepTendonLeftKneeHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftKneeHypoChoice === "1"} onChange={e => this.setState({ deepTendonLeftKneeHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonLeftKneeHypoText} onChange={e => this.setState({ deepTendonLeftKneeHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftKneeAChoice === "1"} onChange={e => this.setState({ deepTendonLeftKneeAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonLeftKneeAText} onChange={e => this.setState({ deepTendonLeftKneeAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftKneePendularChoice === "1"} onChange={e => this.setState({ deepTendonLeftKneePendularChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Pendular</span><input type="text" value={this.state.deepTendonLeftKneePendularText} onChange={e => this.setState({ deepTendonLeftKneePendularText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightKneeChoice === "1"} onChange={e => this.setState({ deepTendonRightKneeChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Right Knee</span><input type="text" value={this.state.deepTendonRightKneeText} onChange={e => this.setState({ deepTendonRightKneeText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonRightKneeChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightKneeHyperChoice === "1"} onChange={e => this.setState({ deepTendonRightKneeHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonRightKneeHyperText} onChange={e => this.setState({ deepTendonRightKneeHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightKneeHypoChoice === "1"} onChange={e => this.setState({ deepTendonRightKneeHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonRightKneeHypoText} onChange={e => this.setState({ deepTendonRightKneeHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightKneeAChoice === "1"} onChange={e => this.setState({ deepTendonRightKneeAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonRightKneeAText} onChange={e => this.setState({ deepTendonRightKneeAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightKneePendularChoice === "1"} onChange={e => this.setState({ deepTendonRightKneePendularChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Pendular</span><input type="text" value={this.state.deepTendonRightKneePendularText} onChange={e => this.setState({ deepTendonRightKneePendularText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftAnkleChoice === "1"} onChange={e => this.setState({ deepTendonLeftAnkleChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Left Ankle</span><input type="text" value={this.state.deepTendonLeftAnkleText} onChange={e => this.setState({ deepTendonLeftAnkleText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonLeftAnkleChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftAnkleHyperChoice === "1"} onChange={e => this.setState({ deepTendonLeftAnkleHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonLeftAnkleHyperText} onChange={e => this.setState({ deepTendonLeftAnkleHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftAnkleHypoChoice === "1"} onChange={e => this.setState({ deepTendonLeftAnkleHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonLeftAnkleHypoText} onChange={e => this.setState({ deepTendonLeftAnkleHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonLeftAnkleAChoice === "1"} onChange={e => this.setState({ deepTendonLeftAnkleAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonLeftAnkleAText} onChange={e => this.setState({ deepTendonLeftAnkleAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightAnkleChoice === "1"} onChange={e => this.setState({ deepTendonRightAnkleChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Right Ankle</span><input type="text" value={this.state.deepTendonRightAnkleText} onChange={e => this.setState({ deepTendonRightAnkleText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.deepTendonRightAnkleChoice === "1" ? 'block' : 'none' }}>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightAnkleHyperChoice === "1"} onChange={e => this.setState({ deepTendonRightAnkleHyperChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hyper-reflexia</span> <input type="text" value={this.state.deepTendonRightAnkleHyperText} onChange={e => this.setState({ deepTendonRightAnkleHyperText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightAnkleHypoChoice === "1"} onChange={e => this.setState({ deepTendonRightAnkleHypoChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hyporeflexia</span><input type="text" value={this.state.deepTendonRightAnkleHypoText} onChange={e => this.setState({ deepTendonRightAnkleHypoText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deepTendonRightAnkleAChoice === "1"} onChange={e => this.setState({ deepTendonRightAnkleAChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Areflexia</span><input type="text" value={this.state.deepTendonRightAnkleAText} onChange={e => this.setState({ deepTendonRightAnkleAText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hoffmansChoice === "1"} onChange={e => this.setState({ hoffmansChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">HOFFMAN'S</span><input type="text" value={this.state.hoffmansText} onChange={e => this.setState({ hoffmansText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.hoffmansChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hoffmansLeftChoice === "1"} onChange={e => this.setState({ hoffmansLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">LEFT </span><input type="text" value={this.state.hoffmansLeftText} onChange={e => this.setState({ hoffmansLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hoffmansRightChoice === "1"} onChange={e => this.setState({ hoffmansRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right</span><input type="text" value={this.state.hoffmansRightText} onChange={e => this.setState({ hoffmansRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ankleClonusChoice === "1"} onChange={e => this.setState({ ankleClonusChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">ANKLE CLONUS</span><input type="text" value={this.state.ankleClonusText} onChange={e => this.setState({ ankleClonusText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.ankleClonusChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ankleClonusLeftChoice === "1"} onChange={e => this.setState({ ankleClonusLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left </span><input type="text" value={this.state.ankleClonusLeftText} onChange={e => this.setState({ ankleClonusLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ankleClonusRightChoice === "1"} onChange={e => this.setState({ ankleClonusRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right</span><input type="text" value={this.state.ankleClonusRightText} onChange={e => this.setState({ ankleClonusRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiChoice === "1"} onChange={e => this.setState({ babinskiChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">BABINSKI</span><input type="text" value={this.state.babinskiText} onChange={e => this.setState({ babinskiText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.babinskiChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiLeftChoice === "1"} onChange={e => this.setState({ babinskiLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.babinskiLeftText} onChange={e => this.setState({ babinskiLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.babinskiLeftChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiLeftUpGoingChoice === "1"} onChange={e => this.setState({ babinskiLeftUpGoingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Up going</span> <input type="text" value={this.state.babinskiLeftUpGoingText} onChange={e => this.setState({ babinskiLeftUpGoingText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiLeftDownGoingChoice === "1"} onChange={e => this.setState({ babinskiLeftDownGoingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Down going</span><input type="text" value={this.state.babinskiLeftDownGoingText} onChange={e => this.setState({ babinskiLeftDownGoingText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiLeftEquivocalChoice === "1"} onChange={e => this.setState({ babinskiLeftEquivocalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Equivocal</span><input type="text" value={this.state.babinskiLeftEquivocalText} onChange={e => this.setState({ babinskiLeftEquivocalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiRightChoice === "1"} onChange={e => this.setState({ babinskiRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.babinskiRightText} onChange={e => this.setState({ babinskiRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.babinskiRightChoice === "1" ? 'block' : 'none' }} >
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiRightUpGoingChoice === "1"} onChange={e => this.setState({ babinskiRightUpGoingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Up going</span> <input type="text" value={this.state.babinskiRightUpGoingText} onChange={e => this.setState({ babinskiRightUpGoingText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiRightDownGoingChoice === "1"} onChange={e => this.setState({ babinskiRightDownGoingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Down going</span><input type="text" value={this.state.babinskiRightDownGoingText} onChange={e => this.setState({ babinskiRightDownGoingText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.babinskiRightEquivocalChoice === "1"} onChange={e => this.setState({ babinskiRightEquivocalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Equivocal</span><input type="text" value={this.state.babinskiRightEquivocalText} onChange={e => this.setState({ babinskiRightEquivocalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.bulbocavernosusChoice === "1"} onChange={e => this.setState({ bulbocavernosusChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">BULBOCAVERNOSUS</span><input type="text" value={this.state.bulbocavernosusText} onChange={e => this.setState({ bulbocavernosusText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div class="card border-warning" style={{ display: this.state.bulbocavernosusChoice === "1" ? 'block' : 'none' }}>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.bulbocavernosusPresentChoice === "1"} onChange={e => this.setState({ bulbocavernosusPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present </span><input type="text" value={this.state.bulbocavernosusPresentText} onChange={e => this.setState({ bulbocavernosusPresentText: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.bulbocavernosusAbsentChoice === "1"} onChange={e => this.setState({ bulbocavernosusAbsentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Absent</span><input type="text" value={this.state.bulbocavernosusAbsentText} onChange={e => this.setState({ bulbocavernosusAbsentText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.beevorsChoice === "1"} onChange={e => this.setState({ beevorsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">BEEVOR'S</span><input type="text" value={this.state.beevorsText} onChange={e => this.setState({ beevorsText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div class="card border-warning" style={{ display: this.state.beevorsChoice === "1" ? 'block' : 'none' }} >
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.beevorsPresentChoice === "1"} onChange={e => this.setState({ beevorsPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present </span><input type="text" value={this.state.beevorsPresentText} onChange={e => this.setState({ beevorsPresentText: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.beevorsAbsentChoice === "1"} onChange={e => this.setState({ beevorsAbsentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Absent</span><input type="text" value={this.state.beevorsAbsentText} onChange={e => this.setState({ beevorsAbsentText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergTestChoice === "1"} onChange={e => this.setState({ rombergTestChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">ROMBERG TEST</span><input type="text" value={this.state.rombergTestText} onChange={e => this.setState({ rombergTestText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div class="card border-warning" style={{ display: this.state.rombergTestChoice === "1" ? 'block' : 'none' }} >
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergTestAbsentChoice === "1"} onChange={e => this.setState({ rombergTestAbsentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Absent</span><input type="text" value={this.state.rombergTestAbsentText} onChange={e => this.setState({ rombergTestAbsentText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergTestPresentChoice === "1"} onChange={e => this.setState({ rombergTestPresentChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Present </span><input type="text" value={this.state.rombergTestPresentText} onChange={e => this.setState({ rombergTestPresentText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.rombergTestPresentChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergTestPresentLeftChoice === "1"} onChange={e => this.setState({ rombergTestPresentLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left side fall </span><input type="text" value={this.state.rombergTestPresentLeftText} onChange={e => this.setState({ rombergTestPresentLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rombergTestPresentRightChoice === "1"} onChange={e => this.setState({ rombergTestPresentRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right side fall</span><input type="text" value={this.state.rombergTestPresentRightText} onChange={e => this.setState({ rombergTestPresentRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelChoice === "1"} onChange={e => this.setState({ motorLevelChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">MOTOR LEVEL</span><input type="text" value={this.state.motorLevelText} onChange={e => this.setState({ motorLevelText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div class="card border-warning" style={{ display: this.state.motorLevelChoice === "1" ? 'block' : 'none' }} >
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftChoice === "1"} onChange={e => this.setState({ motorLevelLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.motorLevelLeftText} onChange={e => this.setState({ motorLevelLeftText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.motorLevelLeftChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftC5Choice === "1"} onChange={e => this.setState({ motorLevelLeftC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5</span> <input type="text" value={this.state.motorLevelLeftC5Text} onChange={e => this.setState({ motorLevelLeftC5Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftC6Choice === "1"} onChange={e => this.setState({ motorLevelLeftC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6</span><input type="text" value={this.state.motorLevelLeftC6Text} onChange={e => this.setState({ motorLevelLeftC6Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftC7Choice === "1"} onChange={e => this.setState({ motorLevelLeftC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7</span><input type="text" value={this.state.motorLevelLeftC7Text} onChange={e => this.setState({ motorLevelLeftC7Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftC8Choice === "1"} onChange={e => this.setState({ motorLevelLeftC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8</span> <input type="text" value={this.state.motorLevelLeftC8Text} onChange={e => this.setState({ motorLevelLeftC8Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftT1Choice === "1"} onChange={e => this.setState({ motorLevelLeftT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1</span><input type="text" value={this.state.motorLevelLeftT1Text} onChange={e => this.setState({ motorLevelLeftT1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftL2Choice === "1"} onChange={e => this.setState({ motorLevelLeftL2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L2</span><input type="text" value={this.state.motorLevelLeftL2Text} onChange={e => this.setState({ motorLevelLeftL2Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftL3Choice === "1"} onChange={e => this.setState({ motorLevelLeftL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3</span> <input type="text" value={this.state.motorLevelLeftL3Text} onChange={e => this.setState({ motorLevelLeftL3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftL4Choice === "1"} onChange={e => this.setState({ motorLevelLeftL4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L4</span><input type="text" value={this.state.motorLevelLeftL4Text} onChange={e => this.setState({ motorLevelLeftL4Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftL5Choice === "1"} onChange={e => this.setState({ motorLevelLeftL5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L5</span><input type="text" value={this.state.motorLevelLeftL5Text} onChange={e => this.setState({ motorLevelLeftL5Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelLeftS1Choice === "1"} onChange={e => this.setState({ motorLevelLeftS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1</span> <input type="text" value={this.state.motorLevelLeftS1Text} onChange={e => this.setState({ motorLevelLeftS1Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightChoice === "1"} onChange={e => this.setState({ motorLevelRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.motorLevelRightText} onChange={e => this.setState({ motorLevelRightText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.motorLevelRightChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightC5Choice === "1"} onChange={e => this.setState({ motorLevelRightC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5</span> <input type="text" value={this.state.motorLevelRightC5Text} onChange={e => this.setState({ motorLevelRightC5Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightC6Choice === "1"} onChange={e => this.setState({ motorLevelRightC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6</span><input type="text" value={this.state.motorLevelRightC6Text} onChange={e => this.setState({ motorLevelRightC6Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightC7Choice === "1"} onChange={e => this.setState({ motorLevelRightC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7</span><input type="text" value={this.state.motorLevelRightC7Text} onChange={e => this.setState({ motorLevelRightC7Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightC8Choice === "1"} onChange={e => this.setState({ motorLevelRightC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8</span> <input type="text" value={this.state.motorLevelRightC8Text} onChange={e => this.setState({ motorLevelRightC8Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightT1Choice === "1"} onChange={e => this.setState({ motorLevelRightT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1</span><input type="text" value={this.state.motorLevelRightT1Text} onChange={e => this.setState({ motorLevelRightT1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightL2Choice === "1"} onChange={e => this.setState({ motorLevelRightL2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L2</span><input type="text" value={this.state.motorLevelRightL2Text} onChange={e => this.setState({ motorLevelRightL2Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightL3Choice === "1"} onChange={e => this.setState({ motorLevelRightL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3</span> <input type="text" value={this.state.motorLevelRightL3Text} onChange={e => this.setState({ motorLevelRightL3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightL4Choice === "1"} onChange={e => this.setState({ motorLevelRightL4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L4</span><input type="text" value={this.state.motorLevelRightL4Text} onChange={e => this.setState({ motorLevelRightL4Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightL5Choice === "1"} onChange={e => this.setState({ motorLevelRightL5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L5</span><input type="text" value={this.state.motorLevelRightL5Text} onChange={e => this.setState({ motorLevelRightL5Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.motorLevelRightS1Choice === "1"} onChange={e => this.setState({ motorLevelRightS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1</span> <input type="text" value={this.state.motorLevelRightS1Text} onChange={e => this.setState({ motorLevelRightS1Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelChoice === "1"} onChange={e => this.setState({ sensoryLevelChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">SENSORY LEVEL</span><input type="text" value={this.state.sensoryLevelText} onChange={e => this.setState({ sensoryLevelText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div class="card border-warning" style={{ display: this.state.sensoryLevelChoice === "1" ? 'block' : 'none' }}>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftChoice === "1"} onChange={e => this.setState({ sensoryLevelLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.sensoryLevelLeftText} onChange={e => this.setState({ sensoryLevelLeftText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.sensoryLevelLeftChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftC2Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftC2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C2</span> <input type="text" value={this.state.sensoryLevelLeftC2Text} onChange={e => this.setState({ sensoryLevelLeftC2Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftC3Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftC3Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C3</span><input type="text" value={this.state.sensoryLevelLeftC3Text} onChange={e => this.setState({ sensoryLevelLeftC3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftC4Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftC4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C4</span><input type="text" value={this.state.sensoryLevelLeftC4Text} onChange={e => this.setState({ sensoryLevelLeftC4Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftC5Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5</span> <input type="text" value={this.state.sensoryLevelLeftC5Text} onChange={e => this.setState({ sensoryLevelLeftC5Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftC6Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6</span><input type="text" value={this.state.sensoryLevelLeftC6Text} onChange={e => this.setState({ sensoryLevelLeftC6Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftC7Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7</span><input type="text" value={this.state.sensoryLevelLeftC7Text} onChange={e => this.setState({ sensoryLevelLeftC7Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftC8Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8</span> <input type="text" value={this.state.sensoryLevelLeftC8Text} onChange={e => this.setState({ sensoryLevelLeftC8Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT1Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1</span><input type="text" value={this.state.sensoryLevelLeftT1Text} onChange={e => this.setState({ sensoryLevelLeftT1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT2Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T2</span><input type="text" value={this.state.sensoryLevelLeftT2Text} onChange={e => this.setState({ sensoryLevelLeftT2Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT3Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T3</span> <input type="text" value={this.state.sensoryLevelLeftT3Text} onChange={e => this.setState({ sensoryLevelLeftT3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT4Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T4</span><input type="text" value={this.state.sensoryLevelLeftT4Text} onChange={e => this.setState({ sensoryLevelLeftT4Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT5Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T5</span><input type="text" value={this.state.sensoryLevelLeftT5Text} onChange={e => this.setState({ sensoryLevelLeftT5Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT6Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T6</span> <input type="text" value={this.state.sensoryLevelLeftT6Text} onChange={e => this.setState({ sensoryLevelLeftT6Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT7Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T7</span><input type="text" value={this.state.sensoryLevelLeftT7Text} onChange={e => this.setState({ sensoryLevelLeftT7Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT8Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT8Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T8</span><input type="text" value={this.state.sensoryLevelLeftT8Text} onChange={e => this.setState({ sensoryLevelLeftT8Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT9Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT9Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T9</span> <input type="text" value={this.state.sensoryLevelLeftT9Text} onChange={e => this.setState({ sensoryLevelLeftT9Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT10Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT10Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T10</span><input type="text" value={this.state.sensoryLevelLeftT10Text} onChange={e => this.setState({ sensoryLevelLeftT10Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT11Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT11Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T11</span><input type="text" value={this.state.sensoryLevelLeftT11Text} onChange={e => this.setState({ sensoryLevelLeftT11Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftT12Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftT12Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T12</span> <input type="text" value={this.state.sensoryLevelLeftT12Text} onChange={e => this.setState({ sensoryLevelLeftT12Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftL1Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftL1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L1</span><input type="text" value={this.state.sensoryLevelLeftL1Text} onChange={e => this.setState({ sensoryLevelLeftL1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftL2Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftL2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L2</span><input type="text" value={this.state.sensoryLevelLeftL2Text} onChange={e => this.setState({ sensoryLevelLeftL2Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftL3Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3</span> <input type="text" value={this.state.sensoryLevelLeftL3Text} onChange={e => this.setState({ sensoryLevelLeftL3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftL4Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftL4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L4</span><input type="text" value={this.state.sensoryLevelLeftL4Text} onChange={e => this.setState({ sensoryLevelLeftL4Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftL5Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftL5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L5</span><input type="text" value={this.state.sensoryLevelLeftL5Text} onChange={e => this.setState({ sensoryLevelLeftL5Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftS1Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1</span> <input type="text" value={this.state.sensoryLevelLeftS1Text} onChange={e => this.setState({ sensoryLevelLeftS1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftS3Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftS3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S3</span> <input type="text" value={this.state.sensoryLevelLeftS3Text} onChange={e => this.setState({ sensoryLevelLeftS3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelLeftS45Choice === "1"} onChange={e => this.setState({ sensoryLevelLeftS45Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S4/5</span> <input type="text" value={this.state.sensoryLevelLeftS45Text} onChange={e => this.setState({ sensoryLevelLeftS45Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightChoice === "1"} onChange={e => this.setState({ sensoryLevelRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Right</span><input type="text" value={this.state.sensoryLevelRightText} onChange={e => this.setState({ sensoryLevelRightText: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="card border-warning" style={{ display: this.state.sensoryLevelRightChoice === "1" ? 'block' : 'none' }} >
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightC2Choice === "1"} onChange={e => this.setState({ sensoryLevelRightC2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C2</span> <input type="text" value={this.state.sensoryLevelRightC2Text} onChange={e => this.setState({ sensoryLevelRightC2Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightC3Choice === "1"} onChange={e => this.setState({ sensoryLevelRightC3Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C3</span><input type="text" value={this.state.sensoryLevelRightC3Text} onChange={e => this.setState({ sensoryLevelRightC3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightC4Choice === "1"} onChange={e => this.setState({ sensoryLevelRightC4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C4</span><input type="text" value={this.state.sensoryLevelRightC4Text} onChange={e => this.setState({ sensoryLevelRightC4Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightC5Choice === "1"} onChange={e => this.setState({ sensoryLevelRightC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5</span> <input type="text" value={this.state.sensoryLevelRightC5Text} onChange={e => this.setState({ sensoryLevelRightC5Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightC6Choice === "1"} onChange={e => this.setState({ sensoryLevelRightC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6</span><input type="text" value={this.state.sensoryLevelRightC6Text} onChange={e => this.setState({ sensoryLevelRightC6Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightC7Choice === "1"} onChange={e => this.setState({ sensoryLevelRightC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7</span><input type="text" value={this.state.sensoryLevelRightC7Text} onChange={e => this.setState({ sensoryLevelRightC7Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightC8Choice === "1"} onChange={e => this.setState({ sensoryLevelRightC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8</span> <input type="text" value={this.state.sensoryLevelRightC8Text} onChange={e => this.setState({ sensoryLevelRightC8Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT1Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1</span><input type="text" value={this.state.sensoryLevelRightT1Text} onChange={e => this.setState({ sensoryLevelRightT1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT2Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T2</span><input type="text" value={this.state.sensoryLevelRightT2Text} onChange={e => this.setState({ sensoryLevelRightT2Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT3Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T3</span> <input type="text" value={this.state.sensoryLevelRightT3Text} onChange={e => this.setState({ sensoryLevelRightT3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT4Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T4</span><input type="text" value={this.state.sensoryLevelRightT4Text} onChange={e => this.setState({ sensoryLevelRightT4Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT5Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T5</span><input type="text" value={this.state.sensoryLevelRightT5Text} onChange={e => this.setState({ sensoryLevelRightT5Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT6Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T6</span> <input type="text" value={this.state.sensoryLevelRightT6Text} onChange={e => this.setState({ sensoryLevelRightT6Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT7Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T7</span><input type="text" value={this.state.sensoryLevelRightT7Text} onChange={e => this.setState({ sensoryLevelRightT7Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT8Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT8Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T8</span><input type="text" value={this.state.sensoryLevelRightT8Text} onChange={e => this.setState({ sensoryLevelRightT8Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT9Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT9Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T9</span> <input type="text" value={this.state.sensoryLevelRightT9Text} onChange={e => this.setState({ sensoryLevelRightT9Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT10Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT10Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T10</span><input type="text" value={this.state.sensoryLevelRightT10Text} onChange={e => this.setState({ sensoryLevelRightT10Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT11Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT11Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T11</span><input type="text" value={this.state.sensoryLevelRightT11Text} onChange={e => this.setState({ sensoryLevelRightT11Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightT12Choice === "1"} onChange={e => this.setState({ sensoryLevelRightT12Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T12</span> <input type="text" value={this.state.sensoryLevelRightT12Text} onChange={e => this.setState({ sensoryLevelRightT12Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightL1Choice === "1"} onChange={e => this.setState({ sensoryLevelRightL1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L1</span><input type="text" value={this.state.sensoryLevelRightL1Text} onChange={e => this.setState({ sensoryLevelRightL1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightL2Choice === "1"} onChange={e => this.setState({ sensoryLevelRightL2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L2</span><input type="text" value={this.state.sensoryLevelRightL2Text} onChange={e => this.setState({ sensoryLevelRightL2Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightL3Choice === "1"} onChange={e => this.setState({ sensoryLevelRightL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3</span> <input type="text" value={this.state.sensoryLevelRightL3Text} onChange={e => this.setState({ sensoryLevelRightL3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightL4Choice === "1"} onChange={e => this.setState({ sensoryLevelRightL4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L4</span><input type="text" value={this.state.sensoryLevelRightL4Text} onChange={e => this.setState({ sensoryLevelRightL4Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightL5Choice === "1"} onChange={e => this.setState({ sensoryLevelRightL5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L5</span><input type="text" value={this.state.sensoryLevelRightL5Text} onChange={e => this.setState({ sensoryLevelRightL5Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightS1Choice === "1"} onChange={e => this.setState({ sensoryLevelRightS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1</span> <input type="text" value={this.state.sensoryLevelRightS1Text} onChange={e => this.setState({ sensoryLevelRightS1Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightS3Choice === "1"} onChange={e => this.setState({ sensoryLevelRightS3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S3</span> <input type="text" value={this.state.sensoryLevelRightS3Text} onChange={e => this.setState({ sensoryLevelRightS3Text: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sensoryLevelRightS45Choice === "1"} onChange={e => this.setState({ sensoryLevelRightS45Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S4/5</span> <input type="text" value={this.state.sensoryLevelRightS45Text} onChange={e => this.setState({ sensoryLevelRightS45Text: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelChoice === "1"} onChange={e => this.setState({ neurologicalLevelChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">NEUROLOGICAL LEVEL</span><input type="text" value={this.state.neurologicalLevelText} onChange={e => this.setState({ genitalText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div class="card border-warning" style={{ display: this.state.neurologicalLevelChoice === "1" ? 'block' : 'none' }} >
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelC2Choice === "1"} onChange={e => this.setState({ neurologicalLevelC2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C2</span> <input type="text" value={this.state.neurologicalLevelC2Text} onChange={e => this.setState({ neurologicalLevelC2Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelC3Choice === "1"} onChange={e => this.setState({ neurologicalLevelC3Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C3</span><input type="text" value={this.state.neurologicalLevelC3Text} onChange={e => this.setState({ neurologicalLevelC3Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelC4Choice === "1"} onChange={e => this.setState({ neurologicalLevelC4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C4</span><input type="text" value={this.state.neurologicalLevelC4Text} onChange={e => this.setState({ neurologicalLevelC4Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelC5Choice === "1"} onChange={e => this.setState({ neurologicalLevelC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5</span> <input type="text" value={this.state.neurologicalLevelC5Text} onChange={e => this.setState({ neurologicalLevelC5Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelC6Choice === "1"} onChange={e => this.setState({ neurologicalLevelC6Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C6</span><input type="text" value={this.state.neurologicalLevelC6Text} onChange={e => this.setState({ neurologicalLevelC6Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelC7Choice === "1"} onChange={e => this.setState({ neurologicalLevelC7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">C7</span><input type="text" value={this.state.neurologicalLevelC7Text} onChange={e => this.setState({ neurologicalLevelC7Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelC8Choice === "1"} onChange={e => this.setState({ neurologicalLevelC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8</span> <input type="text" value={this.state.neurologicalLevelC8Text} onChange={e => this.setState({ neurologicalLevelC8Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT1Choice === "1"} onChange={e => this.setState({ neurologicalLevelT1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T1</span><input type="text" value={this.state.neurologicalLevelT1Text} onChange={e => this.setState({ neurologicalLevelT1Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT2Choice === "1"} onChange={e => this.setState({ neurologicalLevelT2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T2</span><input type="text" value={this.state.neurologicalLevelT2Text} onChange={e => this.setState({ neurologicalLevelT2Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT3Choice === "1"} onChange={e => this.setState({ neurologicalLevelT3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T3</span> <input type="text" value={this.state.neurologicalLevelT3Text} onChange={e => this.setState({ neurologicalLevelT3Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT4Choice === "1"} onChange={e => this.setState({ neurologicalLevelT4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T4</span><input type="text" value={this.state.neurologicalLevelT4Text} onChange={e => this.setState({ neurologicalLevelT4Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT5Choice === "1"} onChange={e => this.setState({ neurologicalLevelT5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T5</span><input type="text" value={this.state.neurologicalLevelT5Text} onChange={e => this.setState({ neurologicalLevelT5Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT6Choice === "1"} onChange={e => this.setState({ neurologicalLevelT6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T6</span> <input type="text" value={this.state.neurologicalLevelT6Text} onChange={e => this.setState({ neurologicalLevelT6Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT7Choice === "1"} onChange={e => this.setState({ neurologicalLevelT7Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T7</span><input type="text" value={this.state.neurologicalLevelT7Text} onChange={e => this.setState({ neurologicalLevelT7Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT8Choice === "1"} onChange={e => this.setState({ neurologicalLevelT8Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T8</span><input type="text" value={this.state.neurologicalLevelT8Text} onChange={e => this.setState({ neurologicalLevelT8Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT9Choice === "1"} onChange={e => this.setState({ neurologicalLevelT9Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T9</span> <input type="text" value={this.state.neurologicalLevelT9Text} onChange={e => this.setState({ neurologicalLevelT9Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT10Choice === "1"} onChange={e => this.setState({ neurologicalLevelT10Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T10</span><input type="text" value={this.state.neurologicalLevelT10Text} onChange={e => this.setState({ neurologicalLevelT10Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT11Choice === "1"} onChange={e => this.setState({ neurologicalLevelT11Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">T11</span><input type="text" value={this.state.neurologicalLevelT11Text} onChange={e => this.setState({ neurologicalLevelT11Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelT12Choice === "1"} onChange={e => this.setState({ neurologicalLevelT12Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T12</span> <input type="text" value={this.state.neurologicalLevelT12Text} onChange={e => this.setState({ neurologicalLevelT12Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelL1Choice === "1"} onChange={e => this.setState({ neurologicalLevelL1Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L1</span><input type="text" value={this.state.neurologicalLevelL1Text} onChange={e => this.setState({ neurologicalLevelL1Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelL2Choice === "1"} onChange={e => this.setState({ neurologicalLevelL2Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L2</span><input type="text" value={this.state.neurologicalLevelL2Text} onChange={e => this.setState({ neurologicalLevelL2Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelL3Choice === "1"} onChange={e => this.setState({ neurologicalLevelL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3</span> <input type="text" value={this.state.neurologicalLevelL3Text} onChange={e => this.setState({ neurologicalLevelL3Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelL4Choice === "1"} onChange={e => this.setState({ neurologicalLevelL4Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L4</span><input type="text" value={this.state.neurologicalLevelL4Text} onChange={e => this.setState({ neurologicalLevelL4Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelL5Choice === "1"} onChange={e => this.setState({ neurologicalLevelL5Choice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">L5</span><input type="text" value={this.state.neurologicalLevelL5Text} onChange={e => this.setState({ neurologicalLevelL5Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                            <div class="row">
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelS1Choice === "1"} onChange={e => this.setState({ neurologicalLevelS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1</span> <input type="text" value={this.state.neurologicalLevelS1Text} onChange={e => this.setState({ neurologicalLevelS1Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelS3Choice === "1"} onChange={e => this.setState({ neurologicalLevelS3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S3</span> <input type="text" value={this.state.neurologicalLevelS3Text} onChange={e => this.setState({ neurologicalLevelS3Text: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurologicalLevelS45Choice === "1"} onChange={e => this.setState({ neurologicalLevelS45Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S4/5</span> <input type="text" value={this.state.neurologicalLevelS45Text} onChange={e => this.setState({ neurologicalLevelS45Text: e.target.value })} className="form-control" /></div></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><div className="input-group"><span className="input-group-text">Findings </span><textarea value={this.state.findingsEx} onChange={e => this.setState({ findingsEx: e.target.value })} className="form-control" rows="5" ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.examinationOther} onChange={e => this.setState({ examinationOther: e.target.value })} className="form-control" rows="5" ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Upload Findings [15MB]</span> <input type="file" accept=".pdf" onChange={e => { this.setState({ file: e.target.files[0] }); this.doValidate(); }} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Findings Text </span> <textarea name="resultText" onChange={e => { this.setState({ resultText: e.target.value }); this.doValidate(); }} className="form-control" ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            {this.state.showSubmit === true ? (<div className="col" align="center"><input type="button" onClick={() => this.save()} value="Save" className="btn btn-success" /></div>) : (<div></div>)}
                                        </div>
                                        <br />
                                        <table className="table table-striped">
                                            <tr>
                                                <th>Action</th>
                                                <th>File</th>
                                                <th>Findings</th>
                                            </tr>
                                            <tbody>
                                                {this.state.findings.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span className="link-red" onClick={() => this.deleteResult(item.id, item.fileStorageId, item.fileExtension)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                        {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                                        <td>{item.remarks}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></div>
                                    </div>
                            </div>
                            <div className={this.state.feedbackLetter ? "tab-pane fade show active" : "tab-pane fade"} >
  <br />
    <div class="card card-blue"> 
        <div class="card-header  text-dark"> 
            Feedback Letter
        </div> 
        <div class="card-body"> 
           <div class="row">
                        <div class="col-lg-4 form-inline"><label>Dr Name </label><input type="text"  class="form-control" value={this.state.feedbackDoctor} onChange={e => { this.setState({feedbackDoctor: e.target.value}) }}  /></div>
                    </div>
            <table class="table table-striped">
                <tr>
                    <th>Date</th>
                    <th>Select</th>
                </tr>
                <tbody>
                {this.state.dateLists.map((item, index) =>  (
                <tr key={index}>
                    <td>{item.date}</td>
                    <td><input type="checkbox"  class="form-check-input" checked={item.checked === "1"} onChange={e => { this.setFeedbackItem(index, e.target.value)  }} value="1" /></td>
                </tr>
                  ))}
                  </tbody>
            </table>
            <div class="row">
                        <div class="col-lg-4 form-inline"><label>History </label><input type="checkbox"  class="form-check-input" checked={this.state.feedbackHistory === "1"} onChange={e => { this.setState({feedbackHistory: e.target.value})  }} value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Examination </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackExamination === "1"} onChange={e => { this.setState({feedbackExamination: e.target.value})}}  value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Review Consult </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackReviewConsult === "1"} onChange={e => { this.setState({feedbackReviewConsult: e.target.value}) }} value="1" /></div>
                    </div> 
            <div class="row">
                        <div class="col-lg-4 form-inline"><label>Investigations </label><input type="checkbox"  class="form-check-input" checked={this.state.feedbackInvestigations === "1"} onChange={e => { this.setState({feedbackInvestigations: e.target.value}) }} value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Diagnosis </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackDiagnosis === "1"} onChange={e => { this.setState({feedbackDiagnosis: e.target.value})}} value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Prescriptions </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackPrescriptions === "1"} onChange={e => { this.setState({feedbackPrescriptions: e.target.value}) }} value="1" /></div>
                    </div>
        </div>
    </div>
    
</div> 
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                       
                                                    <td>{item.reviewDate}</td>
                                                    <td> {this.getReviewTime(item.reviewTime)} </td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.props.showCalenderReview()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                                                                                                                                        <td><input type="text" className="form-control" value={item.startDate} onChange={e => { this.setMedicalCertificate(index, "startDate", e.target.value ) }} /> 
                                                                                                                                                                              <MyDatePicker  value={item.startDate} maxDate={item.endDate} onChange={(d)=> {  this.setMedicalCertificate(index, "startDate", d);  }} />
                                                                                                                                                                        </td>
                                                                                                                                                                        <td><input type="text" className="form-control" value={item.endDate} onChange={e => { this.setMedicalCertificate(index, "endDate", e.target.value ) }} /> 
                                                                                                                                                                             <MyDatePicker  value={item.endDate} minDate={item.startDate} onChange={(d)=> {  this.setMedicalCertificate(index, "endDate",  d);  }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.rehabilitation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Rehabilitation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.rehabilitations.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.type} onChange={e => { this.setRehabilitation(index, 'type', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            <option value="PHYSIO THERAPY">PHYSIO_THERAPY</option>
                                                            <option value="OCCUPATIONAL THERAPY">OCCUPATIONAL_THERAPY</option>
                                                        </select></td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setRehabilitation(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteRehabilitation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addRehabilitationTable()} className="btn btn-primary" >Insert New Rehabilitation Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'drugList' + index} className="form-control" value={item.drugName} onChange={e => { this.setTreatment(index, 'drugName', e.target.value); }} />
                                                            <datalist id={'drugList' + index}>
                                                                {this.state.drugs.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.dosage} onChange={e => { this.setTreatment(index, 'dosage', e.target.value); }} /></td>
                                                        <td><input type="text" list={'frequencyList' + index} className="form-control" value={item.frequencyName} onChange={e => { this.setTreatment(index, 'frequencyName', e.target.value); }} />
                                                            <datalist id={'frequencyList' + index}>
                                                                {this.state.frequencys.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'routeList' + index} className="form-control" value={item.routeName} onChange={e => { this.setTreatment(index, 'routeName', e.target.value); }} />
                                                            <datalist id={'routeList' + index}>
                                                                {this.state.routes.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'durationList' + index} className="form-control" value={item.durationName} onChange={e => { this.setTreatment(index, 'durationName', e.target.value); }} />
                                                            <datalist id={'durationList' + index}>
                                                                {this.state.durations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'formulationList' + index} className="form-control" value={item.formulationName} onChange={e => { this.setTreatment(index, 'formulationName', e.target.value); }} />
                                                            <datalist id={'formulationList' + index}>
                                                                {this.state.formulations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.repeats} onChange={e => { this.setTreatment(index, 'repeats', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteTreatment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addTreatmentTable()} className="btn btn-primary" >Insert New Treatment Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.investigation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Investigation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.investigations.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><Select className="form-control" value={this.state.services.filter(({ value }) => value === item.name)} onChange={e => { this.setInvestigation(index, e.value); }} options={this.state.services} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteInvestigation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addInvestigationTable()} className="btn btn-primary" >Insert New Investigation Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.graphical ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Graphical
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">HBA1C</span><input type="number" step="any" value={this.state.hBA1C} onChange={e => this.setState({ hBA1C: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Cholesterol</span><input type="number" step="any" value={this.state.cholesterol} onChange={e => this.setState({ cholesterol: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Name</span><input type="text" step="any" value={this.state.otherName} onChange={e => this.setState({ otherName: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Value</span><input type="number" step="any" value={this.state.otherValue} onChange={e => this.setState({ otherValue: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="card border-success">
                            <div className="card-body">
                                <div><span className="fw-bold">Weight (Kg):</span> {this.state.weight}</div>
                                <div><span className="fw-bold">Height (cm):</span> {this.state.height}</div>
                                <div><span className="fw-bold">Waist (cm):</span> {this.state.waist}</div>
                                <div><span className="fw-bold">BMI:</span> {this.state.bmi}</div>
                                <div><span className="fw-bold">Waist Height Ratio:</span> {this.state.waistHeightRatio}</div>
                                <div><span className="fw-bold">Notes:</span> <span >{this.state.nurseNotes}</span></div>
                            </div>
                        </div>

                        {this.state.allergies && (<div className="card card-red">
                            <div className="card-header text-dark">
                                Allergies
                            </div>
                            <div className="card-body">
                                <span>{this.state.allergies}</span>
                            </div>
                        </div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        {this.state.referralLetters.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Referral Letters</div>
                            <div className="card-body">
                                <table >
                                    {this.state.referralLetters.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'REFERRAL_LETTERS')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                </div>


            </div >
        </div >
        </div >)

    }
}      