import React from 'react';
import axios from "axios";
import '../../App.css';
import MyDatePicker from '../mydatepicker';

const now = new Date();
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
let date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate())
export default class PatientQuickAction extends React.Component {
    constructor(props) {
        super(props);
        this.getPatient = this.getPatient.bind(this);
    }
    state = { showSubmit: false, msg: '', error: '', action: 'SAVEONLY',
        patientDetails: true, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false,
        firstname: '', firstnameError: '', salutationId: '', salutationError: '', idNumber: '', middlename: '', email: '',
        surname: '', surnameError: '', passport: '', gender: '', genderError: '', dateOfBirth: null, dateOfBirthError: '', employer: '', employerError: '', fileNumber: '', salutations: [], genders: [],
        address1: '', address1Error: '', address2: '', address2Error: '', town: '', townError: '', landline: '', towns: [],
        patientMobiles: [{ id: 0, countryCodeId: '', mobileNumber: '', activeStatus: 1 }], countrys: [],
        nextOfKinName: '', nextOfKinNameError: '', nextOfKinRelationshipId: '', nextOfKinRelationshipIdError: '', nextOfKinMobile: '', nextOfKinMobileError: '', relationships: [],
        patientInsurances: [], insurances: [], suffixs: [], dependents: [], sourceofVisits: [], sourceofVisit: '', remarks: ''
    }
    componentDidMount() {
      //  this.getPatient() 
    }
    toggleTabs(name) {
        switch (name) {
            case "contactDetails":
                this.setState({ patientDetails: false, contactDetails: true, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false })
                break
            case "insuranceDetails":
                this.setState({ patientDetails: false, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: true, dependentDetails: false, statisticalDetails: false })
                break
            default:
                this.setState({ patientDetails: true, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false })
                break

        }
    }
    closePopup() {
        document.querySelector('#patientquickaction').style = 'display:none'
    }
    setPatientValue(name, value) {
        switch (name) {
            case "salutationId":
                this.setState({ salutationId: value })
                console.log(value)
                break;
            case "firstname":
                this.setState({ firstname: (value).toLocaleUpperCase() })
                break;
            case "idNumber":
                this.setState({ idNumber: (value).toLocaleUpperCase() })
                break;
            case "middlename":
                this.setState({ middlename: (value).toLocaleUpperCase() })
                break;
            case "surname":
                this.setState({ surname: (value).toLocaleUpperCase() })
                break;
            case "passport":
                this.setState({ passport: (value).toLocaleUpperCase() })
                break;
            case "gender":
                this.setState({ gender: value })
                break;
            case "dateOfBirth": 
                this.setState({ dateOfBirth: this.processDate(value)})
                break;
            case "employer":
                this.setState({ employer: (value).toLocaleUpperCase() })
                break;
            case "fileNumber":
                this.setState({ fileNumber: value })
                break;
            case "address1":
                this.setState({ address1: (value).toLocaleUpperCase() })
                break;
            case "address2":
                this.setState({ address2: (value).toLocaleUpperCase() })
                break;
            case "town":
                this.setState({ town: (value).toLocaleUpperCase() })
                break;
            case "email":
                this.setState({ email: value })
                break;
            case "landline":
                this.setState({ landline: value })
                break;
            case "nextOfKinName":
                this.setState({ nextOfKinName: (value).toLocaleUpperCase() })
                break;
            case "nextOfKinRelationshipId":
                this.setState({ nextOfKinRelationshipId: value })
                break;
            case "nextOfKinMobile":
                this.setState({ nextOfKinMobile: value })
                break;
                default:
                    console.log("..............")
                    break

        }
        this.doValidate()
    }
    doValidate() {
        setTimeout(() => {
            this.validatePatient();
        }, 1000);
    }
    validatePatient() {
       
        var error = false;
        var patientDetails = 0;
        var insuranceDetails = 0;
        if (this.state.firstname === null) {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            patientDetails++;
            error = true
        }
        else if (this.state.firstname === "") {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            patientDetails++;
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.firstname)))
            {
            this.setState({ firstnameError: "Firstname should contain characters only and no spaces"})
            patientDetails++;
            error = true
            }
        else {
            this.setState({ firstnameError: "" })
        }

        if (this.state.surname === null) {
            this.setState({ surnameError: "Surname cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.surname === "") {
            this.setState({ surnameError: "Surname cannot be empty" })
            patientDetails++;
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.surname)))
            {
            this.setState({ surnameError: "Surname should contain characters only and no spaces"})
            patientDetails++;
            error = true
            }
        else {
            this.setState({ surnameError: "" })
        }

        if (this.state.salutationId === 0) {
            this.setState({ salutationError: "Salutation cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.salutationId === "") {
            this.setState({ salutationError: "Salutation cannot be empty" })
            patientDetails++;
            error = true
        }
        else {
            this.setState({ salutationError: "" })
        }
        if (this.state.gender === null) {
            this.setState({ genderError: "Gender cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.gender === "") {
            this.setState({ genderError: "Gender cannot be empty" })
            patientDetails++;
            error = true
        }
        else {
            this.setState({ genderError: "" })
        } console.log(this.state.dateOfBirth)
        if (this.state.dateOfBirth === null) {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.dateOfBirth === "") {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            patientDetails++;
            error = true
        }
        else if(!(/^(\d{4})-(\d{2})-(\d{2})$/.test(this.state.dateOfBirth)))
            {
            this.setState({ dateOfBirthError: "Date Of Birth wrong format"})
            patientDetails++;
            error = true
            }
        else {
            this.setState({ dateOfBirthError: "" })
        }
        if (this.state.employer === null) {
            this.setState({ employerError: "Employer cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.employer === "") {
            this.setState({ employerError: "Employer cannot be empty" })
            patientDetails++;
            error = true
        }
        else {
            this.setState({ employerError: "" })
        }
        var patientInsurances = this.state.patientInsurances;
        for (var index in patientInsurances) {
            if (patientInsurances[index].insuranceId === "") {
                patientInsurances[index].insuranceIdError = "Insurance cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].insuranceIdError = "";
            }
            if (patientInsurances[index].relationshipId === "" || patientInsurances[index].relationshipId === "0" || patientInsurances[index].relationshipId === 0) {
                patientInsurances[index].relationshipIdError = "Relationship to Member cannot be empty"
                insuranceDetails++
                error = true
            } //pattern-custom="^[7]\d{8}$"  data-pattern-error="Please numbers only should not start with zero like 772747211"
            else {
                patientInsurances[index].relationshipIdError = "";
            }
            if (patientInsurances[index].suffixId === "" || patientInsurances[index].suffixId === "0" || patientInsurances[index].suffixId === 0) {
                patientInsurances[index].suffixIdError = "Suffix cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].suffixIdError = "";
            }
            if (patientInsurances[index].memberName === "") {
                patientInsurances[index].memberNameError = "Member Number cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].memberNameError = "";
            }
            if (patientInsurances[index].memberNumber === "") {
                patientInsurances[index].memberNumberError = "Member Name cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].memberNumberError = "";
            }
        }
        console.log(" error"+error)
        this.setState({ patientInsurances: patientInsurances })
        if(error === true)
           {
            var where =  (patientDetails > 0?" [Patient] ": "")
            +""+(insuranceDetails > 0?" [Insurance] ": "")
            this.setState({ error: "One or more of the mandatory field(s) is not filled in "+where, showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }   
    }
    setPatientmobileValue(index, name, value) {
        let list = this.state.patientMobiles.slice();

        switch (name) {
            case "countryCodeId":
                list[index].countryCodeId = value;
                break;
            case "mobileNumber":
                list[index].mobileNumber = value;
                break;
            default:
                console.log('....')
                break;
        }
        this.setState({ patientMobiles: list });
        this.validatePatient()        
    }
    setPatientInsuranceValue(index, name, value) {
        let list = this.state.patientInsurances.slice();

        switch (name) {
            case "insuranceId":
                list[index].insuranceId = value;
                break;
            case "relationshipId":
                list[index].relationshipId = value;
                break;
            case "suffixId":
                list[index].suffixId = value;
                break;
            case "memberName":
                list[index].memberName = (value).toLocaleUpperCase();
                break;
            case "memberNumber":
                list[index].memberNumber = value;
                break;
            default:
                console.log('....')
                break;
        }
        this.setState({ patientInsurances: list });
        this.validatePatient()        
    }
    setDependentValue(index, name, value) {
        let list = this.state.dependents.slice();

        switch (name) {
            case "salutationId":
                list[index].salutationId = value;
                break;
            case "surname":
                list[index].surname = (value).toLocaleUpperCase();
                break;
            case "firstname":
                list[index].firstname = (value).toLocaleUpperCase();
                break;
            case "gender":
                list[index].gender = value;
                break;
            case "dateOfBirth":
                list[index].dateOfBirth = this.processDate(value);
                break;
            case "relationshipId":
                list[index].relationshipId = value;
                break;
            case "insuranceId":
                list[index].insuranceId = value;
                break;
            case "memberNumber":
                list[index].memberNumber = value;
                break;
            case "suffixId":
                list[index].suffixId = value;
                break;
            default:
                console.log('....')
                break;
        }
        this.setState({ dependents: list });
        this.validatePatient()        
    }
addMobileTable()
{   console.log("....")
    let list = this.state.patientMobiles.slice();
    list.push({ id: 0, countryCodeId: '', countryCodeIdError: "Country Code cannot be empty", mobileNumber: '', mobileNumberError: "Mobile Number cannot be empty", activeStatus: 1 });
    this.setState({ patientMobiles: list });
}
addInsuranceTable()
{
    let list = this.state.patientInsurances.slice();
    list.push({ id: 0, insuranceId: '', insuranceIdError: "Insurance cannot be empty", relationshipId: '', relationshipIdError: "Relationship to Member cannot be empty", suffixId: '', suffixIdError: "Suffix cannot be empty", memberNumber: '', memberNumberError: "Member Number cannot be empty", memberName: '', memberNameError: "Member Name cannot be empty", activeStatus: 1 });
    this.setState({ patientInsurances: list });
    //console.log('....... LIST '+JSON.stringify(list))
    //console.log('....... STATE LIST '+JSON.stringify(this.state.patientInsurances))
}
insertDependantLine()
{
    let list = this.state.dependents.slice();
    list.push({ id: 0, salutationId: '', salutationIdError: "Salutation cannot be empty", surname: '', surnameError: "Surname to Member cannot be empty", firstname: '', firstnameError: "Firstname cannot be empty", gender: '', genderError: "Gender cannot be empty", dateOfBirth: '', dateOfBirthError: "Date Of Birth cannot be empty", relationshipId: '', relationshipIdError: "Relationship cannot be empty", insuranceId: '', insuranceIdError: "Insurance cannot be empty", suffixId: '', suffixIdError: "Suffix cannot be empty", memberNumber: '', memberNumberError: "Member Name cannot be empty", activeStatus: 1 });
    this.setState({ dependents: list });
}
removePatientInsuranceEntry(index)
{
    let list = this.state.patientInsurances.slice();
    list.splice(index, 1)
    this.setState({ patientInsurances: list });
    this.doValidate()
}
removePatientDependentEntry(index)
{
    let list = this.state.dependents.slice();
    list.splice(index, 1)
    this.setState({ dependents: list });
    this.doValidate()
}
getPatient(id) {
    this.setState({id: id, firstname: '', firstnameError: '', salutationId: '', salutationError: '', idNumber: '', middlename: '',
    surname: '', surnameError: '', passport: '', gender: '', genderError: '', dateOfBirth: null, dateOfBirthError: '', employer: '', employerError: '', fileNumber: '', salutations: [], genders: [],
    address1: '', address1Error: '', address2: '', address2Error: '', town: '', townError: '', landline: '', towns: [],
    patientMobiles: [{ id: 0, countryCodeId: '', mobileNumber: '', activeStatus: 1 }], countrys: [],
    nextOfKinName: '', nextOfKinNameError: '', nextOfKinRelationshipId: '', nextOfKinRelationshipIdError: '', nextOfKinMobile: '', nextOfKinMobileError: '', relationships: [],
    patientInsurances: [], insurances: [], suffixs: [], dependents: [], sourceofVisits: [], sourceofVisit: '', remarks: ''
})
    axios.get(localStorage.getItem('host') + '/patient/getpatient/'+id+"/"+localStorage.getItem("companyId"), {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({id: res.data.id, firstname: res.data.firstname, salutationId: res.data.salutationId,  idNumber: res.data.idNumber, middlename: res.data.middlename,
            surname: res.data.surname, passport: res.data.passport, gender: res.data.gender, dateOfBirth: res.data.dateOfBirth, employer: res.data.employer, fileNumber: res.data.fileNumber, salutations: res.data.salutations, genders: res.data.genders,
            address1: res.data.address1, address2: res.data.address2, town: res.data.town, landline: res.data.landline, towns: res.data.towns,
            patientMobiles: res.data.patientMobiles, countrys: res.data.countrys, email: res.data.email,
            nextOfKinName: res.data.nextOfKinName, nextOfKinRelationshipId: res.data.nextOfKinRelationshipId, nextOfKinMobile: res.data.nextOfKinMobile, relationships: res.data.relationships,
            patientInsurances: res.data.patientInsurances, insurances: res.data.insurances, suffixs: res.data.suffixs, dependents: res.data.dependents, sourceofVisits: res.data.sourceofVisits, sourceofVisit: res.data.sourceofVisit, remarks: res.data.remarks});
            
            this.props.setLoader(false)              
            this.validatePatient();
        }, err => {
             this.setState({error: err+""})
                this.props.setLoader(false)
        }
    ) 
}
save() {
    this.props.setLoader(true)
    var data = {id: this.state.id, firstname: this.state.firstname, salutationId: this.state.salutationId,  idNumber: this.state.idNumber, middlename: this.state.middlename,
        surname: this.state.surname, passport: this.state.passport, gender: this.state.gender, dateOfBirth: this.state.dateOfBirth, employer: this.state.employer, fileNumber: this.state.fileNumber, 
        address1: this.state.address1, address2: this.state.address2, town: this.state.town, landline: this.state.landline, email: this.state.email, 
        patientMobiles: this.state.patientMobiles, 
        nextOfKinName: this.state.nextOfKinName, nextOfKinRelationshipId: this.state.nextOfKinRelationshipId, nextOfKinMobile: this.state.nextOfKinMobile,
        patientInsurances: this.state.patientInsurances, dependents: this.state.dependents, sourceofVisit: this.state.sourceofVisit, remarks: this.state.remarks}
    axios.post(localStorage.getItem('host') + '/patient/patientsave', data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.props.setLoader(false)
            console.log(res.data)
            if(res.data.error)
              {
                this.setState({ error: res.data.error });  
              }
              else
              {
                this.props.actionSavePatient(res.data.id, res.data.msg, this.state.action)  
                this.closePopup();
              }
        }, err => {
             this.setState({error: err+""})
            this.props.setLoader(false)
        }
    )
}
processDate(date)
{
  const now = new Date(date);
  const todayY = now.getFullYear();
  const todayM = checkZero(now.getMonth() + 1);
  const todayD = checkZero(now.getDate());
  var date1 = todayY + '-' + todayM + '-' + todayD;
  console.log(date1)
 return date1;
  

}
render() { //<input value={firstname}  onChange={this.handleChange} />
    let divMsg = ''
    if (this.state.msg) {
        divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
    } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
    if (this.state.error) {
        divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
    }
    return (<div className="page-popup page-popup-overlay" id="patientquickaction">
            <div className="card">
                <div className="card-header bg-info">
                    Action
                </div>
                <div className="card-body">
                    {divMsg}
                    <div className="row">
                        <div className="col-md-9">
                            <div className="tab-content pt-2 pl-1" id="pills-tabContent" v-align="top">
                                <div className={this.state.patientDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Personal Details
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Salutation</span>
                                                    <select className={this.state.salutationError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.salutationId} onChange={e => { this.setPatientValue('salutationId', e.target.value); }} >
                                                        <option value="">Choose Salutation</option>
                                                        {this.state.salutations.map((salutation, index) => (
                                                            <option key={index} value={salutation.id} >{salutation.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.salutationError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Firstname</span>
                                                    <input type="text" className={this.state.firstnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.firstname} onChange={e => { this.setPatientValue('firstname', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.firstnameError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group">
                                                    <span className="input-group-text">National ID</span>
                                                    <input type="text" className="form-control" value={this.state.idNumber} onChange={e => { this.setPatientValue('idNumber', e.target.value); }} />
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"><div className="input-group">
                                                    <span className="input-group-text"> Middlenames</span>
                                                    <input type="text" className="form-control" value={this.state.middlename} onChange={e => { this.setPatientValue('middlename', e.target.value); }} />
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Surname</span>
                                                    <input type="text" className={this.state.surnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.surname} onChange={e => { this.setPatientValue('surname', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.surnameError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group">
                                                    <span className="input-group-text">Passport</span>
                                                    <input type="text" className="form-control" value={this.state.passport} onChange={e => { this.setPatientValue('passport', e.target.value); }} />
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Gender</span>
                                                    <select className={this.state.genderError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.gender} onChange={e => { this.setPatientValue('gender', e.target.value); }} >
                                                        <option value="">Choose Gender</option>
                                                        {this.state.genders.map((gender, index) => (
                                                            <option key={index} value={gender} >{gender}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{this.state.genderError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Date of Birth (yyyy-mm-dd)</span>
                                                      <input type="text" className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.dateOfBirth} onChange={e => { this.setState({ dateOfBirth: e.target.value }); this.doValidate() }} /> 
                                                                                          <MyDatePicker  value={this.state.dateOfBirth} maxDate={ date1} onChange={(d)=> {  this.setState({dateOfBirth: d}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.dateOfBirthError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Employer</span>
                                                    <input type="text" className={this.state.employerError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.employer} onChange={e => { this.setPatientValue('employer', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.employerError}</div>
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"><div className="input-group">
                                                    <span className="input-group-text">File Number</span>
                                                    <input type="text" className="form-control" value={this.state.fileNumber} onChange={e => { this.setPatientValue('fileNumber', e.target.value); }} />
                                                    <div className="invalid-feedback"></div>
                                                </div></div>
                                            </div>
                                        </div></div></div>
                                <div className={this.state.insuranceDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Insurance Details
                                        </div>
                                        <div className="card-body">
                                            <button onClick={() => this.addInsuranceTable()} className="btn btn-success" ><i className="bi bi-plus-square"></i></button>
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Insurance</th>
                                                    <th>Relationship To Member</th>
                                                    <th>Suffix</th>
                                                    <th>Member Number</th>
                                                    <th>Member Name</th>
                                                    <th>Active</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                    {this.state.patientInsurances.map((patientInsurance, index) => (patientInsurance.activeStatus === 1 && (
                                                        <tr v-for="(patientInsurance, index) in patientInsurances">
                                                            <td><div className="input-group has-validation"><select className={patientInsurance.insuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.insuranceId} onChange={e => { this.setPatientInsuranceValue(index, 'insuranceId', e.target.value); }} >
                                                                <option value="" >Choose Insurance</option>
                                                                {this.state.insurances.map((insurances, index) => (
                                                                    <option key={index} value={insurances.id} >{insurances.name}</option>
                                                                ))}
                                                            </select>
                                                                <div className="invalid-feedback">{patientInsurance.insuranceIdError}</div></div></td>
                                                            <td><div className="input-group has-validation"><select className={patientInsurance.relationshipIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.relationshipId} onChange={e => { this.setPatientInsuranceValue(index, 'relationshipId', e.target.value); }} >
                                                            <option value="" >Choose Relationship</option>
                                                                {this.state.relationships.map((relationship, index) => (
                                                                    <option key={index} value={relationship.id} >{relationship.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{patientInsurance.relationshipIdError}</div></div></td>
                                                            <td><div className="input-group has-validation"><select className={patientInsurance.suffixIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.suffixId} onChange={e => { this.setPatientInsuranceValue(index, 'suffixId', e.target.value); }} >
                                                                {this.state.suffixs.map((suffix, index) => (
                                                                    <option key={index} value={suffix.id} >{suffix.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback"></div></div></td>
                                                            <td><div className="input-group has-validation"><input type="text" className={patientInsurance.memberNumberError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.memberNumber} onChange={e => { this.setPatientInsuranceValue(index, 'memberNumber', e.target.value); }}  />
                                                                <div className="invalid-feedback">{patientInsurance.memberNumberError}</div></div></td>
                                                            <td><div className="input-group has-validation"><input type="text" className={patientInsurance.memberNameError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.memberName} onChange={e => { this.setPatientInsuranceValue(index, 'memberName', e.target.value); }}  />
                                                                <div className="invalid-feedback">{patientInsurance.memberNameError}</div></div></td>
                                                            <td><select className="form-control" value={patientInsurance.activeStatus} onChange={e => { this.setPatientInsuranceValue(index, 'activeStatus', e.target.value); }} >
                                                                <option value="0">No</option>
                                                                <option value="1">Yes</option>
                                                            </select></td>
                                                            {patientInsurance.id === 0 ? (<td><input type="button" className="btn btn-danger" onClick={() => this.removePatientInsuranceEntry(index)} value="Remove" /></td>) : (<td></td>)}
                                                        </tr>
                                                    )))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <button className={this.state.patientDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("patientDetails") }} data-bs-target="#patientDetails" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Patient Details</button>
                                </li>
                                <li className="nav-item">
                                    <button className={this.state.insuranceDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("insuranceDetails") }} data-bs-target="#insuranceDetails" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Insurance Details</button>
                                </li>
                            </ul>
                            { this.state.id > 0 && this.state.patientInsurances.length > 0 && (
                            <div className="row">
                                            <div className="col"><input type="button" onClick={() => this.props.getMemberLookuPi(this.state.id, 0)} className="btn btn-success" value="Member Lookup" /></div>
                                            <div className="col"><input type="button" onClick={() => this.props.getEnrol(this.state.id, 0)} className="btn btn-success" value="Enrol" /></div>
                                            <div className="col"><input type="button" value="Presign Bio" className="btn btn-success" onClick={() => this.props.getPresignPi(this.state.id, 0)} /></div>
                                        </div>
                            )}
                        </div>
                    </div>
                    <div className="card card-blue">
                        <div className="card-header">Action</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col"><div className="input-group"> <span className="input-group-text">Save Only</span><input type="radio"  value="SAVEONLY" checked={this.state.action === "SAVEONLY"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                                <div className="col"><div className="input-group"> <span className="input-group-text">Visit</span><input type="radio"  value="VISIT" checked={this.state.action === "VISIT"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                                <div className="col"><div className="input-group"> <span className="input-group-text">Appointment</span><input type="radio"  value="APPOINTMENT" checked={this.state.action === "APPOINTMENT"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                                <div className="col"><div className="input-group"> <span className="input-group-text">Advanced Receipt</span><input type="radio"  value="ADVANCEDRECEIPT" checked={this.state.action === "ADVANCEDRECEIPT"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" align="right">{this.state.showSubmit === true ?(<input type="button" className="btn btn-primary" onClick={() => { this.save() }} value="Save" />):(<span></span>)}</div>
                        <div className="col"><input type="button" className="btn btn-primary"  onClick={() => this.closePopup()} value="Close" /></div>
                    </div>
                </div></div>
    </div >
    );
}
}