import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDateTimePicker from "../mydatetimepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class ScriptBillAction extends Component {
    
    state = { barcode: '',billId: '', companyId: '',locationId: '', speciality: '', plocationId: '', rate: 0, institutionId: '', fileData: '', billCategory: '', billCategoryName: '', locations: [], services: [], options:[], billItems: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', managementLists: [], visitId: 0, msg: '', error: '' , productItemLocationId: ''
    , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', treatments: [], procedure: ''
    , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
    , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: '', dosage: '' , cipherStrings: []
    ,cashOnlyPcp: 0 , cashOnlyDs: 0, cashOnly: 0, showButton: true}
    componentDidMount() {

    }
    openAction(visitId) {
        this.setState({barcode: '', billId: '', speciality: '', companyId: '', locationId: '', rate: 0, institutionId: '', billCategory: '', billCategoryName: '', fileData: '', locations: [], options:[], services: [], billItems: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', managementLists: [], visitId: visitId, msg: '', error: '', productItemLocationId: ''
        , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', treatments: [], procedure: ''
        , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
        , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: '', dosage: '' , cipherStrings: []
        ,cashOnlyPcp: 0, cashOnlyDs: 0, cashOnly: 0, showButton: true})
        axios.get(localStorage.getItem('host') + '/is/scriptbill/' + visitId+"/"+localStorage.getItem("companyId")+'/'+localStorage.getItem('roleId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var options = [];
                for(var i in res.data.services)
                    {
                        options.push({value: res.data.services[i].id, label: res.data.services[i].name})
                    }  
                let billCategory = res.data.billCategory
                if(res.data.billCategory.includes('INSU - '))
                   {
                    billCategory = 'INSURANCE'
                   }
                if(res.data.billCategory.includes('CONTRACT - '))
                   {
                    billCategory = 'SPECIAL_CONTRACT'
                   }
                this.setState({ billId: res.data.billId, speciality: res.data.speciality, companyId: res.data.companyId, rate: res.data.rate, institutionId: res.data.institutionId, billItems: res.data.billItems, billCategory: billCategory, billCategoryName: res.data.billCategory,locations: res.data.locations, services: res.data.services, options: options, fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, managementLists: res.data.managementLists
                    , cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable, drugAllergy: res.data.drugAllergy, otherAllergy: res.data.otherAllergy, treatments: res.data.treatments
                    , cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid
                    , cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing, authorisationNumber: res.data.authorisationNumber, authorisationAmount: res.data.authorisationAmount , cipherStrings: res.data.cipherStrings
                    
                   });
                this.props.setLoader(false)
                this.doValidate();
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.setState({showButton: false})
        this.props.setLoader(true)
        var billItems = this.state.billItems
        for(var i in this.state.billItems)
           {
            billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
            billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
           }
        var data = { visitId: this.state.visitId, drugAllergy: this.state.drugAllergy, otherAllergy: this.state.otherAllergy, treatments: this.state.treatments            
            , billItems: billItems, billId: this.state.billId, speciality: this.state.speciality
            , currencyId: this.state.currencyId, cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
            , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing, authorisationNumber: this.state.authorisationNumber, authorisationAmount: this.state.authorisationAmount
            }
        axios.post(localStorage.getItem('host') + '/is/scriptbillsave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data, showButton: true }); 
                document.getElementById('topDiv').scrollIntoView()
                this.props.updateAfterSave(this.state.visitId, [])
            }, err => {
                console.log(".....")
                this.setState({ error: err , showButton: true})
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('scriptbillAction').style = 'display:none'
        this.props.getList();
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    } 
    setDosage()
    {
     var value = this.state.dosage.toLocaleUpperCase()  
     for(var i in this.state.cipherStrings)
        { console.log('....'+this.state.cipherStrings[i].code+' -> '+this.state.cipherStrings[i].desc)
        value = value.replace(this.state.cipherStrings[i].code, this.state.cipherStrings[i].desc)
        }
      this.setState({dosage: value})
    }   
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
  getMargin(unitCost, margin, type)
          {
           let val = 0
           if(type === 'MIN')
               {
                 if(unitCost < 0)
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));  
                   }  
                else
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));    
                   val = val < 0?0:val
                   }   
               }
           else{
               if(unitCost < 0)
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));  
                   val = val > 0?0:val
                   }  
                else
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));    
                   }   
               
               }    
   
          return val;    
          }    
    setBillItem(name, index, value)   
       {
        var list = this.state.billItems;   
       switch(name)
          {    
             case "billStartDate":
                list[index].billStartDate = value
                break;
            case "billEndDate":
                list[index].billEndDate = value
                break;   
            case "quantity":
                if(list[index].billItemType === 'PRODUCT')
                {
                  if(Number(list[index].quantity) > Number(value))
                     {
                        list[index].quantity = value  
                     }
                }
                else
                  {
                    list[index].quantity = value
                    this.calcQuantity(index, list[index].quantity)
                  }
                break;   
            case "unitCost":
                list[index].unitCost = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "cashOnly":
                list[index].cashOnly = Number(value)
                this.calcQuantity(index, list[index].quantity)
                break;     
            case "authoAmount":
                list[index].authoAmount = value 
                this.calcQuantity(index, list[index].quantity)
                break;  
             default: 
                     console.log("....")
                     break;   
          }
      this.setState({ billItems: list })    
      this.doValidate() 
       }
       calcBill(list, authorisationNumber, authorisationAmount)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            var authoAmount = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                authoAmount += Number(list[index].authoAmount);
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            if (authorisationNumber !== null && authorisationNumber !== '' && authorisationAmount > 0)
            {
                cashPayable += Number(insurancePayable) - Number(authorisationAmount);
                insurancePayable = authorisationAmount;
            }
            if(authoAmount > 0)
             {
                shortfallPayable = Number(insurancePayable) - Number(authoAmount);
                insurancePayable = authoAmount;
             }
            var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
            var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
            var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
            var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
                , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
        }
        calcQuantity(index, value)
        {
            var list = this.state.billItems;  
            list[index].cost = Number(list[index].unitCost) * Number(value);
            list[index].totalCost = list[index].cost;
            this.setState({ billItems: list })  
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
        }
          hideEntry(index)
           {
              let list = this.state.billItems.slice();
              list[index].activeStatus = 0
              this.setState({ billItems: list }); 
              this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
           } 
           getProductItems() {
               this.setState({ productItems: [] });
               this.props.setLoader(true)
               var data = {companyId:  localStorage.getItem("companyId"), locationId: this.state.locationId, institutionId: this.state.institutionId, name: this.state.name, rate: this.state.rate, billCategory: this.state.billCategory}
               axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitems', data, {
                   headers: {
                       'Authorization': `Bearer ${localStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                    var options = [];
                    for(var i in res.data)
                        {
                            options.push({value: res.data[i].productItemLocationId, label: res.data[i].name+" ["+res.data[i].quantity+"] - "+res.data[i].batchNumber+" - "+res.data[i].expiryDate})
                        } 
                    this.setState({productItems: res.data, productOptions: options});
                    this.props.setLoader(false)
                   }, err => {
                       console.log(".....")
                       this.setState({error: err+""})
                       this.props.setLoader(false)
                   }
               )
           }
        addProductItem()
           {
            if(this.state.productItemLocationId.value > 0)
               {  console.log('......... product location id '+this.state.productItemLocationId.value) 
                const now = new Date();
                var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                let list = this.state.billItems.slice();
                var s = '';
                for (var i in this.state.productItems) {
                    if (this.state.productItems[i].productItemLocationId === Number(this.state.productItemLocationId.value)) {
                        s = this.state.productItems[i];
                        s.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: s.cashOnly;
                    }
                }
             if(this.state.quantity <= s.quantity)
                 {   
                list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:s.type
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: this.state.quantity, unitCost: s.sellingPrice, min: this.getMargin(s.sellingPrice, s.margin, 'MIN'), max: this.getMargin(s.sellingPrice, s.margin, 'MAX'), cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId, instruction: this.state.dosage});
                this.setState({ billItems: list, cashOnlyDs: 0});
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                this.doValidate()
                 }
               else
                  {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
                  }  
               }
           } 
           doValidate() {
               setTimeout(() => {
                   this.validate();
               }, 1000);
           }
           validate() {
       
               var error = false;
               var billItems = this.state.billItems;
               if (billItems === null) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else if (billItems.length === 0) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else {
                  
                   
                   for(var i in billItems)
                   {
                    billItems[i].unitCostError =  "" 
                    if(billItems[i].allowEdit)
                      {
                       if (billItems[i].unitCost === null) {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost === "") {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost >= 0 && billItems[i].unitCost > billItems[i].max) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                        error = true
                    }
                    else if (billItems[i].unitCost >= 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost > 0) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than 0"
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                       else {
                           billItems[i].unitCostError =  "" 
                       }
       
                       console.log(billItems[i].unitCostError)
                      }
                 this.setState({ billItemsError: "", billItems: billItems })   
                   }
               }
               console.log(" error" + error)
               if(this.state.allowSave === false)
                 {
                   this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
                 }
               else  if (error === true) {
                   this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
               }
               else {
                   this.setState({ error: "", showSubmit: true })
               }
           } 
           doProductItemFromBarCode(val) {
               this.props.setLoader(true)
               setTimeout(() => {
                   if(this.state.barcode === val && this.state.barcode !== "")
                     {
                      this.setProductItemFromBarCode();
                     }
               }, 2000);
           }
           setProductItemFromBarCode()
                 {
                   const now = new Date();
                    var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                    var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, barcode: this.state.barcode, rate: this.state.rate, billCategory: this.state.billCategory}
                    axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitemsbarcode', data, {
                       headers: {
                           'Authorization': `Bearer ${localStorage.getItem('token')}`
                       }
                   }).then(
                       res => {
                           this.setState({barcode: ""})
                           if(res.data.quantity > 0)
                             {
                           var quantity = 0;
                           var index = 0;
                           var list = this.state.billItems;
                           for(var i in list)
                              {
                               if(list[i].productItemLocationId === res.data.productItemLocationId)
                                   {
                                    quantity++;
                                    index = i;
                                   }
                              }
                           if(quantity === 0)
                              {
                                res.data.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: res.data.cashOnly;
                                list.push({id: 0, serviceId: 0, name: res.data.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:res.data.type
                                , tariffCode: res.data.tariffCode, billStartDate: date1, billEndDate: date1, quantity: 1, unitCost: res.data.sellingPrice, min: this.getMargin(res.data.sellingPrice, res.data.margin, 'MIN'), max: this.getMargin(res.data.sellingPrice, res.data.margin, 'MAX'), cost: res.data.sellingPrice , totalCost: res.data.sellingPrice, cashOnly: res.data.cashOnly, allowEdit: res.data.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: res.data.authoAmount, wac: 0.0, lastReceiptCost: res.data.buyingPrice, activeStatus: 1, productItemId: res.data.id, productItemLocationId: res.data.productItemLocationId, locationId: this.state.locationId});
                                this.setState({ billItems: list , cashOnlyDs: 0});
                                 this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                               this.doValidate()
                              }   
                           else if(quantity <= res.data.quantity)
                           {   
                           list[index].quantity = list[index].quantity + 1
                           list[index].cost =  list[index].quantity * list[index].unitCost
                           list[index].totalCost =  list[index].quantity * list[index].unitCost
                          this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                          this.doValidate()
                           }
                         else
                            {                              
                              document.getElementById('topDiv').scrollIntoView()
                              this.setState({error:"Entered quantity cannot be greater than available quantity of "+res.data.quantity})  
                            }
                          
                             } 
                          else
                             {
                                document.getElementById('topDiv').scrollIntoView()
                              this.setState({error: "Product was not found."}) 
                             }   
                             this.props.setLoader(false)
                       }, err => {
                           console.log(".....")
                           this.setState({error: err+"", barcode: ""})
                           document.getElementById('topDiv').scrollIntoView()
                           this.props.setLoader(false)
                       }
                   )
                   
                 } 
           processDateTime(date)
             {
               const now = new Date(date);
               const todayY = now.getFullYear();
               const todayM = checkZero(now.getMonth() + 1);
               const todayD = checkZero(now.getDate());
               var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
               console.log(date1)
              return date1;
             }    
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="scriptbillAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">ScriptBill Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Category:</span> {this.state.billCategoryName}</div>
                                </div>
                            </div>
                        </div>
                        <div id="110"></div>
                                            <br />
                                            <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><button className="btn btn-success" onClick={() => this.getProductItems()} ><i class="bi bi-search"></i></button></div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Barcode</span><input type="text" className="form-control" readOnly={this.state.locationId === ''?"readOnly":""} value={this.state.barcode} onChange={e => { this.setState({barcode: e.target.value}); this.doProductItemFromBarCode(e.target.value) }} /></div></div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemLocationId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemLocationId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Dosage</span>
                                                 <input type="text" className="form-control" value={this.state.dosage} onChange={e => { this.setState({dosage: e.target.value}) }} />
                                                 <button  onClick={() => this.setDosage()}  className="btn btn-success" ><i class="bi bi-magic"></i></button></div>
                                            </div>
                                             <div className="col">{ this.state.productItemLocationId !== "" && this.state.quantity > 0 && (<input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" />)}</div>

                                        </div>
                                        </div></div>
                                    <div className="card  card-blue">
                                    <div className="card-header">Prescription</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) =>(
                                                    <tr key={index}>
                                                        <td>{item.drugName} </td>
                                                        <td>{item.dosage}</td>
                                                        <td>{item.frequencyName}</td>
                                                        <td>{item.routeName} </td>
                                                        <td>{item.durationName}</td>
                                                        <td>{item.formulationName} </td>
                                                        <td>{item.repeats}</td>
                                                         </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div></div>
                                            <br />
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Line</th>
                                                    <th>Name</th>
                                                    <th>Tariff</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Cost</th>
                                                    <th>Autho Amount</th>
                                                    <th>Total Cost</th>
                                                    <th>Cash Only</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                {this.state.billItems.map((billItem, index) => (
                                                    billItem.activeStatus === 1 &&(<tr >
                                                        <td >{index + 1}</td>
                                                        <td >{billItem.name}</td>
                                                        <td>{billItem.tariffCode}</td>
                                                        <td> <input type="text" className="form-control" value={billItem.billStartDate} onChange={e => {this.setBillItem("billStartDate", index, e.target.value ) }} /> 
                                                                                                                <MyDateTimePicker  value={billItem.billStartDate} maxDate={billItem.billEndDate} onChange={(d)=> {  this.setBillItem("billStartDate", index, d);  }} /></td>
                                                                                                               <td> <input type="text" className="form-control" value={billItem.billEndDate} onChange={e => { this.setBillItem("billEndDate", index,  e.target.value ) }} /> 
                                                                                                                <MyDateTimePicker  value={billItem.billEndDate} minDate={billItem.billStartDate} onChange={(d)=> {  this.setBillItem("billEndDate", index,  d);  }} />
                                                                                                                </td>
                                                                                                          {billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.billItemType === "PRODUCT"?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                                        {billItem.allowEdit === 1 ?(<td><div className="input-group has-validation"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                                        <td>{this.currencyFormat(billItem.cost)}</td>
                                                        <td>{billItem.authoAmount}</td>
                                                        <td>{this.currencyFormat(billItem.totalCost)}</td>
                                                        <td><select className="form-control" value={billItem.cashOnly} onChange={e => { this.setBillItem("cashOnly",index, e.target.value) }}  >
                                                        <option value="1" >Yes</option>
                                                            <option value="0" >No</option>
                                                        </select></td>
                                                        <td><span className="link-red" onClick={() => this.hideEntry(index)}><i className="bi bi-trash-fill"></i></span></td>
                                                    </tr>)
                                                ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>    
                                         <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Number</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationNumber: e.target.value }); this.calcBill(this.state.billItems, e.target.value, this.state.authorisationAmount);}} value={ this.state.authorisationNumber} /></div></div>
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Amount</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationAmount: e.target.value }); this.calcBill(this.state.billItems, this.state.authorisationNumber, e.target.value);}} value={ this.state.authorisationAmount} /></div></div>
                                                </div>            
                                         
                                            <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Paid</th>
                                                    <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPaid)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Owing</th>
                                                    <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.totalOwing)}</td>
                                                </tr></tbody>

                                            </table>
                                            </div>
                                            </div>
                <div className="row">
                    <div className="col" align="right">{ this.state.showButton && <button onClick={() => this.save()} className="btn btn-primary">Save</button>}</div>
                    <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
                                            </div>
                </div>)

    }
}      