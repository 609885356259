import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import HospitalBillAction from "./hospitalbill";
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class CasualtyNurseBillList extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.updateAfterSave = this.updateAfterSave.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.hospitalBillActionRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
                this.startAutoList();
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
   startAutoList()
   {
    setInterval(() => {
        this.getListSilent();
    }, 1000 * 20)
   } 
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/patientmanagement/nurselist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getListSilent() {
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/patientmanagement/nurselist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openHospitalBillAction(visitId)
    {
    this.hospitalBillActionRef.current.openHospitalBillAction(visitId)
    document.getElementById('hospitalBillAction').style = 'display:block'   
    } 
     updateAfterSave(visitId, results)
     {

     }  
     processDate(date)
       {
         const now = new Date(date);
         const todayY = now.getFullYear();
         const todayM = checkZero(now.getMonth() + 1);
         const todayD = checkZero(now.getDate());
         var date1 = todayY + '-' + todayM + '-' + todayD;
         console.log(date1)
        return date1;
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                     
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                      <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                          <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                         <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                          <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                          <div className="col">    <button className="btn btn-success" onClick={() => this.getList()}  ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Date</th>
                                    <th>Bill Category</th>
                                    <th>Consultant</th>
                                    <th>Visit Type</th>
                                    <th>Status</th>
                                    <th>Nurse Name</th>
                                    <th>Bed</th> 
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} style={{backgroundColor:item.color}}>
                                        <td >
                                            <span className="link" onClick={() => this.openHospitalBillAction(item.id)} title="Edit"><i className="bi bi-pencil-square"></i></span>
                                            <span className="link" onClick={() => this.getPrintOut(item.id, 'HOSPITALNURSE', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                               </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.treatmentDateTime}</td>
                                        <td>{item.billCategory}</td>
                                        <td>{item.consultant}</td>
                                        <td>{item.visitType}</td>
                                        <td>{item.visitStatus}</td>                                        
                                        <td>{item.nurseName}</td>
                                        <td>{item.bed}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <HospitalBillAction ref={this.hospitalBillActionRef}  setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} getList={this.getList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    