import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PaginationEle from "../pagination";


export default class AppointmentTime extends Component {
    state = { id: 0, name: '', activeStatus: 1, list: [] ,columns: [
                
        {
            label: 'Action',
            field: 'action'
        },
        {
            label: 'Name',
            field: 'name'
        },
        {
            label: 'Active Status',
            field: 'stringStatus'
        }
    ]}
        constructor(props) {
            super(props);
           this.paginationEleRef = React.createRef()
        }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', activeStatus: 1 })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/appointmenttimeaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, name: this.state.name, companyId: localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/appointmenttimeform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/appointmenttime/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Cluster" id={res.data[index].id}  onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.paginationEleRef.current.update(res.data)
            }, err => {
                console.log(".....")
            }
        )
    }

    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <PaginationEle ref={this.paginationEleRef} titles={this.state.columns}  />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    