import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import SettlementAction from './settlementaction'
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class Settlement extends Component {
    constructor(props) {
        super(props);
        this.setLoader = this.setLoader.bind(this)
        this.setMsg = this.setMsg.bind(this)
        this.getList = this.getList.bind(this)
        this.settlementActionRef = React.createRef()
        this.allocateAdvancedRceiptRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ clusterCompanys: res.data });
                this.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
            }
        )
    }
    openSettlementAction() {
        this.setState({ loader: true })
        this.settlementActionRef.current.openSettlementAction(this.state.clusterCompanys)
        document.getElementById('settlementAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname,  startDate: this.state.startDate, endDate:this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/bill/settlementlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    }
    cancelSettlement(item) {
        this.setState({ loader: true })
        axios.get(localStorage.getItem('host') + '/bill/settlementcancel/' + item.id+'/'+item.cashPaid+'/'+item.shortfallPaid, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
            }
        )
    }
    setLoader(loader) {
        this.setState({ loader: loader })
    }
    setMsg(msg) {
        this.setState({ msg: msg })
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if (this.state.loader) {
            loaderDiv = (<div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        {divMsg}

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                    <div class="row">
                    <div class="col"><button onClick={() => this.openSettlementAction()} class="btn btn-success">Add New</button></div>
                </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                      <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                                                                                          <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                                                         <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                                                                                          <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                                                                  <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Fullname</th>
                                    <th>Number</th>
                                    <th>Date</th>
                                    <th>Done By</th>
                                    <th>Currency</th>
                                    <th>Payable</th>
                                    <th>Owing</th>
                                    <th>Paid</th>
                                    <th>Settlement Amount</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.list.map((item, index) => (
                                        <tr key={index} >
                                            <td >
                                              <span className="link-red" onClick={() => this.cancelSettlement(item)} title="Cancel Settlement" ><i className="bi bi-x-square-fill"></i></span>
                                               </td>
                                            <td>{item.fullname}</td>
                                            <td>{item.number}</td>
                                            <td>{item.billingStartDate}</td>
                                            <td>{item.doneBy}</td>
                                            <td>{item.currencyName}</td>
                                            {item.cashPaid > 0 ? (<td align="right">{this.currencyFormat(item.cashPayable)}</td>) : (<td align="right">{this.currencyFormat(item.shortfallPayable)}</td>)}
                                            {item.cashPaid > 0 ? (<td align="right">{this.currencyFormat(item.cashOwing)}</td>) : (<td align="right">{this.currencyFormat(item.shortfallOwing)}</td>)}
                                            {item.cashPaid > 0 ? (<td align="right">{this.currencyFormat(item.cashPaid)}</td>) : (<td align="right">{this.currencyFormat(item.shortfallPaid)}</td>)}
                                            <td align="right">{this.currencyFormat(item.settlementAmount)}</td>
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <SettlementAction ref={this.settlementActionRef} setLoader={this.setLoader} setMsg={this.setMsg} getList={this.getList} />
                        {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    