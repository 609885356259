import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDateTimePicker from "../mydatetimepicker";

const now = new Date();
var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class JobOrderAction extends Component {
    constructor(props) {
        super(props);
        //this.setPatientValue = this.setPatientValue.bind(this);
        this.editorRef = React.createRef()
    }
    state = {jobOrder: '', locationId: 0, productItems: [], productOptions: [], name: '', productItemId: 0, id: 0, visitId: 0, companyId: 0, patientId: 0, billId: 0, billCategory: '', report: '', risStatus: '', rejectReason: '', rejectedBy: '', risTemplates: [], fullname: '', gender: '', dateOfBirth: '', age: '', jobOrderStatusType: '', msg: '', error: ''
    , clusterCompanys: [], billCategorys: [], patientInsurances: [], institutions: [], referringConsultants: [], services: [], billItems: [], diagnosisList: [], patientDiagnosis: [], currencys: [], locations: []
    , patientInsuranceId: '', patientInsuranceIdError: '', specialContractId: '', specialContractIdError: '', currencyId: '', currencyIdError: '', billingStartDateTime: date1, billingStartDateTimeError: '', referringConsultantId: ''
    , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0
    , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
    , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0
    , visitDetails: true, billDetails: false, diagnosisDetails: false
    , optomPrescriptionDetails: true, billingDetails: false, jobOrderStatusDetails: false
    ,tint: '', bifocalType: '', prismsRight: '', prismsLeft: '', segmentsRight: '', segmentsLeft: '', opticalCentresD: '', sphRight: '', cylRight: '', axisRight: '', addRight: '', sphLeft: '', cylLeft: '', axisLeft: '', addLeft: '', specialInstructions: '', data: ''}
    componentDidMount () {
        window.scrollTo(0, 0);
      }
    openStatusAction(jobOrder, msg) {
        this.setState({jobOrder: jobOrder, id: jobOrder.id, visitId: jobOrder.visitId, companyId: jobOrder.companyId, patientId: jobOrder.patientId, billId: jobOrder.billId, fullname: jobOrder.fullname, gender: jobOrder.gender, dateOfBirth: jobOrder.dateOfBirth, age: jobOrder.stringAge, jobOrderStatusType: jobOrder.jobOrderStatusType, msg: msg, error: '',
     /* PRESCRIPTION  */     tint: jobOrder.optomPrescription.tint, bifocalType: jobOrder.optomPrescription.bifocalType, prismsRight: jobOrder.optomPrescription.prismsRight, prismsLeft: jobOrder.optomPrescription.prismsLeft, segmentsRight: jobOrder.optomPrescription.segmentsRight, segmentsLeft: jobOrder.optomPrescription.segmentsLeft, opticalCentresD: jobOrder.optomPrescription.opticalCentresD, sphRight: jobOrder.optomPrescription.sphRight, cylRight: jobOrder.optomPrescription.cylRight, axisRight: jobOrder.optomPrescription.axisRight, addRight: jobOrder.optomPrescription.addRight, sphLeft: jobOrder.optomPrescription.sphLeft, cylLeft: jobOrder.optomPrescription.cylLeft, axisLeft: jobOrder.optomPrescription.axisLeft, addLeft: jobOrder.optomPrescription.addLeft, specialInstructions: jobOrder.optomPrescription.specialInstructions, data: jobOrder.optomPrescription.data})
        if(jobOrder.jobOrderStatusType === "BILL")
           {
            axios.get(localStorage.getItem('host') + '/is/getjobordersell/' + jobOrder.billId+'/'+jobOrder.patientId+'/'+jobOrder.companyId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({
                    speciality: res.data.speciality, clusterCompanys: res.data.clusterCompanys, billCategorys: res.data.billCategorys, patientInsurances: res.data.patientInsurances, referringConsultants: res.data.referringConsultants, billItems: res.data.billItems, patientDiagnosis: res.data.patientDiagnosis, currencys: res.data.currencys
                    , billCategory: res.data.billCategory, patientInsuranceId: res.data.patientInsuranceId, specialContractId: res.data.specialContractId, currencyId: res.data.currencyId, billingStartDateTime: res.data.billingStartDateTime, referringConsultantId: res.data.referringConsultantId, locations: res.data.locations
                    //,health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages, diagnosisText: res.data.diagnosisText
                    , cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable
                    , cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid
                    , cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing
                    });
                    if(res.data.billCategory === "CASH")
                       {   
                        console.log("CASH get Services")   
                        setTimeout(() => { this.getServices(0, res.data.rate)}, 1000);
                       }
                    else if(res.data.billCategory === "SPECIAL_CONTRACT")
                       {
                        setTimeout(() => { this.getServices(res.data.specialContractId, res.data.rate)}, 1000);
                       }  
                    else
                       {
                        for (let j in res.data.patientInsurances) {
                            if (res.data.patientInsurances[j].id === res.data.patientInsuranceId) {     
                                console.log("Insurance get Services")
                        setTimeout(() => { this.getServices(res.data.patientInsurances[j].institutionId, res.data.rate)}, 1000);
                            }
                        }
                       }    
                    this.props.setLoader(false)
                    this.doValidate();
                    window.scrollTo(0, 0);
                }, err => {
                    console.log(".....")
                    this.setState({ error: err })
                    window.scrollTo(0, 0);
                    this.props.setLoader(false)
                }
            )

           }
        else if(jobOrder.jobOrderStatusType === "DISPATCH")
           {

           }
        else
           {   
        axios.get(localStorage.getItem('host') + '/is/getjobordernext/' + jobOrder.jobOrderStatusId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({
                    nextId: res.data.nextId, nextAltId: res.data.nextAltId, nextName: res.data.nextName, nextAltName: res.data.nextAltName});
                this.props.setLoader(false)
                window.scrollTo(0, 0);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
    }
    }
    saveNext(jobOrderStatusId) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/is/updatejobordernext/'+this.state.id+'/'+jobOrderStatusId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                window.scrollTo(0, 0);
                this.props.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('jobOrderAction').style = 'display:none';
        window.scrollTo(0, 0);
    }
    setVisit(name, value) {
        switch (name) {
            case "companyId":
                for (var i in this.state.clusterCompanys) {
                    if (Number(this.state.clusterCompanys[i].id) === Number(value)) {
                        var companyId = this.state.clusterCompanys[i].id;
                        var users = this.state.clusterCompanys[i].users;
                        var patientInsurances = this.state.clusterCompanys[i].patientInsurances;
                        var institutions = this.state.clusterCompanys[i].institutions;
                        var currencys = this.state.clusterCompanys[i].currencys;
                        var speciality = this.state.clusterCompanys[i].speciality;
                        var locations = this.state.clusterCompanys[i].locations;
                        this.setState({ companyId: companyId, users: users, patientInsurances: patientInsurances, institutions: institutions, currencys: currencys, speciality: speciality, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0, billCategory: '', referringConsultantId: 0 , locations: locations})
                    }
                }
                break;
            case "billCategory":
                this.setState({ billCategory: value, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0 })
                break;
            case "consultantId":
                this.setState({ consultantId: value })
                break;
            case "patientInsuranceId":
                for (var j in this.state.patientInsurances) {
                    if (this.state.patientInsurances[j].id === Number(value)) {
                        var patientInsuranceId = this.state.patientInsurances[j].id;
                        var institutionId = this.state.patientInsurances[j].institutionId;
                        var currencyId = this.state.patientInsurances[j].currencyId;
                        var rate = this.state.patientInsurances[j].rate;
                        this.setState({ patientInsuranceId: patientInsuranceId, institutionId: institutionId, currencyId: currencyId, rate: rate })
                        this.getServices( institutionId, rate)
                    }
                }
                break;
            case "specialContractId":
                for (var v in this.state.institutions) {
                    if (this.state.institutions[v].id === Number(value)) {
                        var specialContractId = this.state.institutions[v].id;
                        institutionId = this.state.institutions[v].id;
                        currencyId = this.state.institutions[v].currencyId;
                        rate = this.state.institutions[v].rate;
                        this.setState({ specialContractId: specialContractId, institutionId: institutionId, currencyId: currencyId, rate: rate })
                        this.getServices( institutionId, rate)
                    }
                }
                break;
            case "currencyId":
                for (var b in this.state.currencys) {
                    if (this.state.currencys[b].id === Number(value)) {
                         currencyId = this.state.currencys[b].id;
                         institutionId = 0;
                         rate = this.state.currencys[b].rate;
                        this.setState({ currencyId: currencyId, institutionId: 0, rate: rate })
                        this.getServices( 0, rate)
                    }
                }
                break;
            case "visitType":
                this.setState({ visitType: value })
                break;
            case "referringConsultantId":
                this.setState({ referringConsultantId: value })
                break;
            case "wardId":
                for (var n in this.state.wards) {
                    if (this.state.wards[n].id === Number(value)) {
                        var wardId = this.state.wards[n].id;
                        var beds = this.state.wards[n].beds;
                        this.setState({ wardId: wardId, beds: beds })
                    }
                }
                break;
             default: 
                     console.log("....")
                     break;   
        }
    }

    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }

        if (this.state.billCategory === null) {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else if (this.state.billCategory === "") {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else {
            this.setState({ billCategoryError: "" })
        }


        if (this.state.consultantId === null) {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantId === 0) {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantId === "") {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else {
            this.setState({ consultantIdError: "" })
        }
        if (this.state.billCategory === 'CASH') {
            if (this.state.currencyId === null) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === 0) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === "") {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else {
                this.setState({ currencyIdError: "" })
            }
        }
        if (this.state.billCategory === 'INSURANCE') {
            if (this.state.patientInsuranceId === null) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === 0) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === "") {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else {
                this.setState({ patientInsuranceIdError: "" })
            }
        }
        if (this.state.billCategory === 'SPECIAL_CONTRACT') {
            if (this.state.specialContractId === null) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === 0) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === "") {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else {
                this.setState({ specialContractIdError: "" })
            }
        }
            if (this.state.referringConsultantId === null) {
                this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
                error = true
            }
            else if (this.state.referringConsultantId === 0) {
                this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
                error = true
            }
            else if (this.state.referringConsultantId === "") {
                this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
                error = true
            }
            else {
                this.setState({ referringConsultantIdError: "" })
            }
            var billItems = this.state.billItems;
        if (billItems === null) {
            this.setState({ billItemsError: "Bill Items cannot be empty" })
            error = true
        }
        else if (billItems.length === 0) {
            this.setState({ billItemsError: "Bill Items cannot be empty" })
            error = true
        }
        else {
           
            
            for(var i in billItems)
            {
             if(billItems[i].allowEdit)
               {
                if (billItems[i].unitCost === null) {
                    billItems[i].unitCostError =  "Unit Cost cannot be empty"
                    error = true
                }
                else if (billItems[i].unitCost === "") {
                    billItems[i].unitCostError =  "Unit Cost cannot be empty"
                    error = true
                }
                else if (billItems[i].unitCost > billItems[i].max) {
                    billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                    error = true
                }
                else if (billItems[i].unitCost < billItems[i].min) {
                    billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                    error = true
                }
                else {
                    billItems[i].unitCostError =  "" 
                }


               }
          this.setState({ billItemsError: "", billItems: billItems })   
            }
        }
        console.log(" error" + error)
        if(this.state.allowSave === false)
          {
            this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
          }
        else  if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    getServices(institutionId, rate)
      {
       this.setState({services: [], options: []})   
       console.log('..... i am '+institutionId+'/'+rate+'/'+this.state.companyId)
       if(institutionId >= 0 && rate > 0 && this.state.companyId > 0)
          { console.log('..... i am ')
          axios.get(localStorage.getItem('host') + '/patient/servicecostlist/' + this.state.companyId + '/' + this.state.billCategory + '/' + institutionId+'/'+rate, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    var options = [];
                    for(var i in res.data)
                        {
                            options.push({value: res.data[i].id, label: res.data[i].name})
                        } 
                    this.setState({services: res.data, options: options});
                }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                }
            )
    
          }
      }   
    addServices()
       {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
        if(this.state.serviceCostId.value > 0)
           {  console.log('......... service cost id '+this.state.serviceCostId.value) 
            const now = new Date();
            var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
            let list = this.state.billItems.slice();
            var s = '';
            for (var i in this.state.services) {
                if (this.state.services[i].id === Number(this.state.serviceCostId.value)) {
                    s = this.state.services[i];
                }
            }
            list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
            , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: (Number(s.fixedCost) - this.getMargin(s)), max: (Number(s.fixedCost) + this.getMargin(s)), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                for (var index in s.services)
                {
                    var ss = s.services[index];
                    list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                        , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, min: (Number(ss.fixedCost) - this.getMargin(ss)), max: (Number(ss.fixedCost) + this.getMargin(ss)), cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                }
            this.setState({ billItems: list });
            this.calcBill(list)
            this.doValidate()
           }
       }  
    getMargin(service)
       {
           return Math.ceil(service.fixedCost * (service.margin / 100));
       }   
    setBillItem(name, index, value)   
       {
        var list = this.state.billItems;   
       switch(name)
          {    
             case "billStartDate":
                list[index].billStartDate = value
                break;
            case "billEndDate":
                list[index].billEndDate = value
                break;   
            case "quantity":
                list[index].quantity = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "unitCost":
                list[index].unitCost = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "instruction":
                list[index].instruction = value
                break;     
            case "authoAmount":
                list[index].authoAmount = value 
                this.calcQuantity(index, list[index].quantity)
                break;  
             default: 
                     console.log("....")
                     break;   
          }
      this.setState({ billItems: list })    
      this.doValidate() 
       }
    calcBill(list)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            var authoAmount = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                authoAmount += Number(list[index].authoAmount);
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            if (this.state.authorisationNumber !== null && this.state.authorisationNumber !== '' && this.state.authorisationAmount > 0)
            {
                shortfallPayable = Number(insurancePayable) - Number(this.state.authorisationAmount);
                insurancePayable = this.state.authorisationAmount;
            }
            if(authoAmount > 0)
             {
                shortfallPayable = Number(insurancePayable) - Number(authoAmount);
                insurancePayable = authoAmount;
             }
            var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
            var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
            var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
            var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
                , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
        }
    close() {
        document.getElementById('visitAction').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    calcQuantity(index, value)
    {
        var list = this.state.billItems;  
        list[index].cost = Number(list[index].unitCost) * Number(value);
        list[index].totalCost = list[index].cost;
        this.setState({ billItems: list })  
        this.calcBill(list);
    }
    toggleTabs(name) {
        switch (name) {
            case "billDetails":
                this.setState({ visitDetails: false, billDetails: true, diagnosisDetails: false })
                break
            case "diagnosisDetails":
                this.setState({ visitDetails: false, billDetails: false, diagnosisDetails: true, searchDiagnosis: '', diagnosisList: [] })
                break
            default:
                this.setState({ visitDetails: true, billDetails: false, diagnosisDetails: false })
                break

        }
    }
    toggleMainTabs(name) {
        switch (name) {
            case "billingDetails":
                this.setState({ optomPrescriptionDetails: false, billingDetails: true, jobOrderStatusDetails: false})
                break
            case "jobOrderStatusDetails":
                this.setState({ optomPrescriptionDetails: false, billingDetails: false, jobOrderStatusDetails: true})
                break
            default:
                this.setState({optomPrescriptionDetails: true, billingDetails: false, jobOrderStatusDetails: false})
                break

        }
    }
    saveSell()
      {
          this.props.setLoader(true)
          var billItems = this.state.billItems
          for(var i in this.state.billItems)
             {
              billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
              billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
             }
          var data = {id: this.state.billId, jobOrderId: this.state.id, patientId: this.state.patientId, billItems: billItems, patientDiagnosis: this.state.patientDiagnosis
              , companyId: this.state.companyId, billCategory: this.state.billCategory, rate: this.state.rate
              , patientInsuranceId: this.state.patientInsuranceId, specialContractId: this.state.specialContractId, billingStartDateTime: this.state.billingStartDateTime, referringConsultantId: this.state.referringConsultantId
              , diagnosisText: this.state.diagnosisText, speciality: this.state.speciality
              , currencyId: this.state.currencyId, cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
              , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing}
          axios.post(localStorage.getItem('host') + '/is/savejobordersell/'+localStorage.getItem('userId'), data, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          }).then(
              res => {
                  this.props.setLoader(false)
                  console.log(res.data)
                  if(res.data.error)
                    {
                      this.setState({ error: res.data.error });  
                      window.scrollTo(0, 0)
                    }
                    else
                    {
                     var jobOrder = this.state.jobOrder
                     jobOrder.billId = res.data.id
                     window.scrollTo(0, 0)
                     this.openStatusAction(jobOrder, res.data.msg);
                    }
              }, err => {
                   this.setState({error: err+""})
                   window.scrollTo(0, 0)
                  this.props.setLoader(false)
              }
          )   
      }
      hideEntry(index)
       {
          let list = this.state.billItems.slice();
          list[index].activeStatus = 0
          this.setState({ billItems: list }); 
          this.calcBill(list);
       } 
       getProductItems() {
           this.setState({ productItems: [] });
           this.props.setLoader(true)
           var data = {companyId:  this.state.companyId, locationId: this.state.locationId, name: this.state.name, rate: this.state.rate, billCategory: this.state.billCategory}
           axios.post(localStorage.getItem('host') + '/master/getproductitems', data, {
               headers: {
                   'Authorization': `Bearer ${localStorage.getItem('token')}`
               }
           }).then(
               res => {
                var options = [];
                for(var i in res.data)
                    {
                        options.push({value: res.data[i].id, label: res.data[i].name+" ["+res.data[i].quantity+"]"})
                    } 
                this.setState({productItems: res.data, productOptions: options});
                this.props.setLoader(false)
               }, err => {
                   console.log(".....")
                   this.setState({error: err+""})
                   this.props.setLoader(false)
               }
           )
       }
    addProductItem()
       {
        if(this.state.productItemId.value > 0)
           {  console.log('......... service cost id '+this.state.productItemId.value) 
            const now = new Date();
            var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
            let list = this.state.billItems.slice();
            var s = '';
            for (var i in this.state.productItems) {
                if (this.state.productItems[i].id === Number(this.state.productItemId.value)) {
                    s = this.state.productItems[i];
                }
            }
         if(this.state.quantity <= s.quantity)
             {   
            list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'PRODUCT', productType: s.type
            , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: this.state.quantity, unitCost: s.sellingPrice, min: (Number(s.sellingPrice) - this.getMargin(s)), max: (Number(s.sellingPrice) + this.getMargin(s)), cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id});
            this.setState({ billItems: list });
            this.calcBill(list)
            this.doValidate()
             }
           else
              {
                window.scrollTo({top: 0, behavior: 'smooth'})
                this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
              }  
           }
       }   
       processDateTime(date)
         {
           const now = new Date(date);
           const todayY = now.getFullYear();
           const todayM = checkZero(now.getMonth() + 1);
           const todayD = checkZero(now.getDate());
           var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
           console.log(date1)
          return date1;
         }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="jobOrderAction" className="page-popup page-popup-overlay"   >
            <div className="card" >
            <div className="card-header  bg-info">Job Order Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <img title="File View" alt={this.state.contentType} src={'data:'+this.state.contentType+';base64,' + this.state.fileData}  style={{ width: 100 + '%' }} />
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                 </div>
                                 <div className="row">   
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                    <div className="col"><span className="fw-bold">Rejected By:</span> {this.state.rejectedBy}</div>
                                    <div className="col"><span className="fw-bold">Reject Reason:</span> {this.state.rejectReason}</div>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className={this.state.optomPrescriptionDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("optomPrescriptionDetails") }} href={false} >Optom Prescription</a>
                            </li>
                            {this.state.jobOrderStatusType === "BILL" ? (  <li className="nav-item">
                                <a className={this.state.billingDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("billingDetails") }} href={false}>Billing</a>
                            </li>):
                            (<li className="nav-item">
                                <a className={this.state.jobOrderStatusDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("jobOrderStatusDetails") }} href={false}>Job Order Status</a>
                            </li>)}
                        </ul>
                        <div className="tab-content pt-2 pl-1" id="pills-tabContent">
                                <div className={this.state.optomPrescriptionDetails ? "tab-pane fade show active" : "tab-pane fade"}>
 <table className="table table-striped">
                                    <tr>
            <td></td>
            <th>SPH</th>
            <th>CYL</th>
            <th>AXIS</th>
            <th>ADD</th>
        </tr>        
        <tbody>
        <tr>
            <th>R</th>
            <td>{this.state.sphRight}</td>
            <td>{this.state.cylRight}</td>
            <td>{this.state.axisRight}</td>
            <td>{this.state.addRight}</td>
        </tr>
        <tr>
            <th>L</th>
            <td>{this.state.sphLeft}</td>
            <td>{this.state.cylLeft}</td>
            <td>{this.state.axisLeft}</td>
            <td>{this.state.addLeft}</td>
        </tr>
        <tr>
            <td></td>
            <th>Prisms</th>
            <th>Segments</th>
            <td></td>
            <td></td>
        </tr>       
        <tr>
            <th>R</th>
            <td>{this.state.prismsRight}</td>
            <td>{this.state.segmentsRight}</td>  
            <th>Tint</th>   
            <td>{this.state.tint}</td>  
        </tr> 
        <tr>
            <th>L</th>
            <td>{this.state.prismsLeft}</td>
            <td>{this.state.segmentsLeft}</td>   
            <th>Bifocal Type</th>
            <td>{this.state.bifocalType}</td>   
        </tr> 
        <tr>
            <td>Optical Centres D</td>
            <td colspan="4">{this.state.opticalCentresD}</td>
        </tr>
        <tr>
            <td>Special Instructions</td>
            <td colspan="4">{this.state.specialInstructions}</td>
        </tr>
        </tbody>
    </table>
                                    </div>
                        {this.state.jobOrderStatusType === "BILL" ?( <div className={this.state.billingDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className={this.state.visitDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("visitDetails") }} href={false} >Job Order Sell Details</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.billDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("billDetails") }} href={false}>Bill Details</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.diagnosisDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("diagnosisDetails") }} href={false}>Diagnosis</a>
                            </li>
                        </ul>
                        <div className="row">
                             <div className="col" align="right"><button onClick={() => this.saveSell()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                        </div>
                        <div className="tab-content pt-2 pl-1" id="pills-tabContent">
                                <div className={this.state.visitDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setVisit("companyId", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Company</option>
                                                        {this.state.clusterCompanys.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.companyName}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.companyIdError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Bill Category</span><select className={this.state.billCategoryError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.billCategory} onChange={e => { this.setVisit("billCategory", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Bill Category</option>
                                                        {this.state.billCategorys.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.billCategoryError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                <span className="input-group-text">Referring Doctor</span> <select className={this.state.referringConsultantIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.referringConsultantId} onChange={e => { this.setVisit("referringConsultantId", e.target.value); this.doValidate() }} >
                                                        <option value="" >Choose Referring Doctor</option>
                                                        {this.state.referringConsultants.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.referringConsultantIdError}</div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">{this.state.billCategory === "INSURANCE" ? (<div className="input-group has-validation">
                                                        <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.patientInsuranceId} onChange={e => { this.setVisit("patientInsuranceId", e.target.value); this.doValidate() }}>
                                                            <option value="0">Choose {this.state.billCategory}</option>
                                                            {this.state.patientInsurances.map((item, index) => (
                                                                <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                            ))}
                                                        </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "SPECIAL_CONTRACT" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span> <select className={this.state.specialContractIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.specialContractId} onChange={e => { this.setVisit("specialContractId", e.target.value); this.doValidate() }} >
                                                                <option value="0">Choose Special Contract</option>
                                                                {this.state.institutions.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.specialContractIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "CASH" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.currencyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.currencyId} onChange={e => { this.setVisit("currencyId", e.target.value); this.doValidate() }}>
                                                                <option value="0">Choose {this.state.billCategory}</option>
                                                                {this.state.currencys.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.currencyIdError}</div></div>) : (<span></span>)}
                                                    </div>
                                                    <div className="col" align="right"></div>
                                                    <div className="col" align="right"><div className="input-group"><span className="input-group-text">Treatment Date</span>
                                                       <input type="text" className={this.state.billingStartDateTimeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.receiptDateTime} onChange={e => { this.setState({ receiptDateTime: e.target.value }) }} /> 
                                                        <MyDateTimePicker  value={this.state.billingStartDateTime} maxDate={ date1} onChange={(d)=> {  this.setState({billingStartDateTime: d}); }} />
                                                        <div className="invalid-feedback">{this.state.billingStartDateTimeError}</div> </div></div></div>
                                            </div>
                                            </div>

                                <div className={this.state.billDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                      <div className="card"><div className="card-body">
                                            <div className="row">
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Service</span>
                                                    <Select
                                                    value={this.state.serviceCostId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ serviceCostId: e })}
                                                    options={this.state.options}
                                                /></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" onChange={e => this.setState({ cashOnly: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left"><input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" /></div>
                                            </div>
                                            </div></div>
                                            <br />
                                            <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><input type="button" className="btn btn-success" onClick={() => this.getProductItems()} value="Search" /></div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" /></div>
                                        </div>
                                        </div></div>
                                            <br />
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Line</th>
                                                    <th>Service</th>
                                                    <th>Tariff Code</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Cost</th>
                                                    <th>Teeth</th>
                                                    <th>Autho Amount</th>
                                                    <th>Total Cost</th>
                                                    <th>Active Status</th>
                                                </tr>
                                                <tbody>
                                                {this.state.billItems.map((billItem, index) => (
                                                    billItem.activeStatus === 1?(<tr >
                                                        <td >{index + 1}</td>
                                                        <td >{billItem.name}</td>
                                                        <td>{billItem.tariffCode}</td>
                                                        <td> <input type="text" className="form-control" value={billItem.billStartDate} onChange={e => {this.setBillItem("billStartDate", index, e.target.value ) }} /> 
                                                                                                                                                                      <MyDateTimePicker  value={billItem.billStartDate} maxDate={billItem.billEndDate} onChange={(d)=> {  this.setBillItem("billStartDate", index, d);  }} /></td>
                                                                                                                                                                     <td> <input type="text" className="form-control" value={billItem.billEndDate} onChange={e => { this.setBillItem("billEndDate", index,  e.target.value ) }} /> 
                                                                                                                                                                      <MyDateTimePicker  value={billItem.billEndDate} minDate={billItem.billStartDate} onChange={(d)=> {  this.setBillItem("billEndDate", index,  d);  }} />
                                                                                                                                                                      </td>   {billItem.serviceType === 'MULTIPLE_QUANTITY'?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                                        {billItem.allowEdit === 1?(<td><div className="input-group has-validation"><input type="text" className={this.state.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                                        <td>{this.currencyFormat(billItem.cost)}</td>
                                                        <td><input type="text" className="form-control" value={billItem.instruction} onChange={e => this.setBillItem("instruction", index, e.target.value)} /></td>
                                                        <td><input type="text" className="form-control" value={billItem.authoAmount} onChange={e => this.setBillItem("authoAmount", index, e.target.value)} /></td>
                                                        <td>{this.currencyFormat(billItem.totalCost)}</td>
                                                        {billItem.serviceId > 0?(<td><input type="button" className="btn btn-danger" value="Delete" onClick={() => this.hideEntry(index)} /></td>):(<td></td>)}
                                                    </tr>):(
                                                        <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    )
                                                ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>
                                            <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Paid</th>
                                                    <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPaid)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Owing</th>
                                                    <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.totalOwing)}</td>
                                                </tr></tbody>

                                            </table>
                                            </div>
                                <div className={this.state.diagnosisDetails ? "tab-pane fade show active" : "tab-pane fade"}></div>
                                </div>
                        </div>)
                        :( <div className={this.state.jobOrderStatusDetails ? "tab-pane fade show active" : "tab-pane fade"}><div className="row">
                             <div className="col" align="right"><button onClick={() => this.saveNext(this.state.nextId)} className="btn btn-primary">{this.state.nextName}</button></div>
                          <div className="col" align="center">{ this.state.nextAltId > 0 && (<button onClick={() => this.saveNext(this.state.nextAltId)} className="btn btn-primary">{this.state.nextAltName}</button>)}</div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                        </div></div>)}
                        </div>
                        <br />
                        
                            
                               
            </div >  </div>
            </div >
        </div >
        </div >)

    }
}      