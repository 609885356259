import React, { Component } from "react";
import '../../App.css';

export default class Help extends Component {
    state={fileName: ''}

openHelp(fileName)
{
this.setState({fileName})
  document.getElementById('helpAction').style = 'display:block'
}

closePopup() {
        document.getElementById('helpAction').style = 'display:none'
    }
render() {
    return (<div id="helpAction" className="page-popup page-popup-overlay" >
    <div className="card" >
        <div className="card-header bg-info">Help Instructions</div>
        <div className="card-body" >
            <div className="row"> 
                <div className="col-lg-12">
                <iframe title="PDF View" src={'/file/'+this.state.fileName} type="application/pdf" style={{width: 100+'%', height: 500+'px'}}></iframe></div>
            </div>
            <div className="row">
            <div className="col-lg-12" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
            </div>
        </div>
    </div>
</div>)

}
}    