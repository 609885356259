import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PaginationEle from "../pagination";


export default class JobOrderStatus extends Component {
    state = { id: 0, roleId: '', nextId: '', nextAlt: '', name: '', jobOrderStatusType: '', activeStatus: 1, list: [], roles: [], msg: '', error: '',columns: [

               
        {
            label: 'Action',
            field: 'action'
        },
        {
            label: 'Name',
            field: 'name'
        },{
            label: 'Type',
            field: 'jobOrderStatusType'
        },
        {
            label: 'Active Status',
            field: 'stringStatus'
        }
    ]}
        constructor(props) {
            super(props);
           this.paginationEleRef = React.createRef()
        }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, roleId: '', nextId: '', nextAlt: '', name: '', jobOrderStatusType: '', activeStatus: 1})
            axios.get(localStorage.getItem('host') + '/master/joborderstatusaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, roleId: res.data.roleId, nextId: res.data.nextId, nextAlt: res.data.nextAlt, name: res.data.name, jobOrderStatusType: res.data.jobOrderStatusType, activeStatus: res.data.activeStatus, roles: res.data.roles });
                }, err => {
                    console.log(".....")
                }
            )
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, roleId: this.state.roleId, nextId: this.state.nextId, nextAlt: this.state.nextAlt, name: this.state.name, jobOrderStatusType: this.state.jobOrderStatusType, activeStatus: this.state.activeStatus, companyId: localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/joborderstatusform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {               
                console.log(res.data)
                if(res.data.error)
                   {
                    this.setState({ error: res.data.error });
                   }
                else
                   {
                    this.setState({ msg: res.data.msg });
                    this.getList();
                    this.closePopup();
                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                   }   
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/joborderstatus/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit"  id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.paginationEleRef.current.update(res.data)
            }, err => {
                console.log(".....")
            }
        )
    }

    render() {
       
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let divError = ''
        if (this.state.error) {
            divError = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                          <PaginationEle ref={this.paginationEleRef} titles={this.state.columns}  />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                                        {divError}
                                        <div className="row">
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} />
                                               </div>
                                            </div>
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Type</span>
                                                <input type="text" className="form-control" value={this.state.jobOrderStatusType} onChange={e => { this.setState({ jobOrderStatusType: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Role</span>
                                                <select className="form-control" value={this.state.roleId} onChange={e => { this.setState({ roleId: e.target.value }) }}  >
                                                   <option value="0">Choose Role</option>
                                                    {this.state.roles.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        <div className="col">
                                                <div className="input-group"><span className="input-group-text">Next</span>
                                                <select className="form-control" value={this.state.nextId} onChange={e => { this.setState({ nextId: e.target.value }) }}  >
                                                   <option value="0">Choose Next</option>
                                                    {this.state.list.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Next Alt</span>
                                                <select className="form-control" value={this.state.nextAltId} onChange={e => { this.setState({ nextAltId: e.target.value }) }}  >
                                                   <option value="0">Choose Next</option>
                                                    {this.state.list.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    